import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_PROJECTS_LIST = 'UPDATE_PROJECTS_LIST'
const UPDATE_PROJECT = 'UPDATE_PROJECT'
const DELETE_PROJECT = 'DELETE_PROJECT'
const UPDATE_SCHEDULES_LIST = 'UPDATE_SCHEDULES_LIST'
const PROJECT_IS_CHANGING = 'PROJECT_IS_CHANGING'
const UPDATE_PROJECT_EMPLOYEES_INDEXED = 'UPDATE_PROJECT_EMPLOYEES_INDEXED'

const state = {
  projectsIndex: {},
  projectIsChanging: false,
  employeesByProjectId: {}
}

const getters = {
  projects: state => Object.values(state.projectsIndex).sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  }),
  projectsIndexed: state => state.projectsIndex,
  projectsCount: state => {
    return Object.values(state.projectsIndex).length
  },
  projectById: (state) => (id) => {
    return state.projectsIndex[id]
  },
  projectIsChanging: state => state.projectIsChanging,
  employeesProjectIndexed: state => state.employeesByProjectId
}

const mutations = {
  [UPDATE_PROJECT_EMPLOYEES_INDEXED] (state, payload) {
    state.employeesByProjectId[payload.project_id] = payload.employees
  },
  [UPDATE_PROJECTS_LIST] (state, payload) {
    state.projectsIndex = {}
    const values = {}
    payload.forEach(item => {
      values[item.id] = item
    })
    state.projectsIndex = values
  },
  [UPDATE_PROJECT] (state, project) {
    state.projectsIndex[project.id] = project
  },
  [DELETE_PROJECT] (state, projectId) {
    delete state.projectsIndex[projectId]
  },
  [PROJECT_IS_CHANGING] (state, payload) {
    state.projectIsChanging = payload
  }
}

const actions = {
  clearProjectsCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getProjectsByCompany ({ commit, state }, companyId) {
    const promiseKey = '/companies/' + companyId + '/projects'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.projectsIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/projects').then(projects => {
        commit(UPDATE_PROJECTS_LIST, projects)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(projects)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  // just alias for above, kept for backward compatability
  getProjects ({ commit, dispatch, rootState }) {
    const companyId = rootState.company.companyId
    return dispatch('getProjectsByCompany', companyId)
  },

  getProjectsWithTrashedScheduleUsers ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get(`/companies/${id}/projects?with_trashed_users=1`).then(projects => {
        commit(UPDATE_PROJECTS_LIST, projects)
        resolve(projects)
      })
    })
  },

  getProject ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + id).then(project => {
        commit(UPDATE_PROJECT, project)
        resolve(project)
      })
    })
  },

  createProject ({ commit }, projectData) {
    return new Promise((resolve, reject) => {
      HTTP.post('/projects', projectData).then(project => {
        commit(UPDATE_PROJECT, project)
        resolve(project)
      }).catch(err => {
        reject(err)
      })
    })
  },

  editProject ({ commit }, [id, projectData]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/projects/' + id, projectData).then(project => {
        commit(UPDATE_PROJECT, project)
        resolve(project)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteProject ({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/projects/' + projectId).then(response => {
        commit(DELETE_PROJECT, projectId)
        commit(UPDATE_SCHEDULES_LIST, [])
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  // OTHER
  getProjectUsers ({ commit }, projectId) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + projectId + '/users').then(projectUsers => {
        if (projectUsers) {
          commit(UPDATE_PROJECT_EMPLOYEES_INDEXED, { project_id: projectId, employees: projectUsers })
          resolve(projectUsers)
        }
      })
    })
  },

  getEmployeeByProject ({ commit }, [projectId, employeeId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/employees/${employeeId}`).then(companyUsers => {
        resolve(companyUsers)
      }).catch(err => {
        reject(err)
      })
    })
  },

  setWorkdays ({ commit }, [id, templateData]) {
    return new Promise(resolve => {
      HTTP.patch('/projects/' + id + '/workdays', templateData).then(project => {
        resolve(project)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
