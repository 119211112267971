import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

export const UPDATE_TEMPLATES_LIST = 'UPDATE_TEMPLATES_LIST'
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const UPDATE_ALL_TEMPLATES_LIST = 'UPDATE_ALL_TEMPLATES_LIST'

const state = {
  templatesIndex: {},
  allTemplatesIndex: {}
}

const getters = {
  templates: state => Object.values(state.templatesIndex),
  allTemplates: state => Object.values(state.allTemplatesIndex),
  templatesIndexed: state => state.templatesIndex,
  templatesCount: state => {
    return Object.values(state.templatesIndex).length
  },
  hasTemplate: (state, getters) => {
    return getters.templatesCount > 0
  },
  templateById: (state) => (id) => {
    return state.templatesIndex[id]
  },
  templatesByScheduleId: (state, getters) => (id) => {
    return getters.templates.filter(item => {
      return item.schedule_id === id
    })
  }
}

const mutations = {
  [UPDATE_ALL_TEMPLATES_LIST] (state, payload) {
    const allTemplatesIndex = {}
    payload.forEach(item => {
      allTemplatesIndex[item.id] = item
    })
    state.allTemplatesIndex = allTemplatesIndex
  },
  [UPDATE_TEMPLATES_LIST] (state, payload) {
    Object.values(state.templatesIndex).forEach((item) => {
      delete state.templatesIndex[item.id]
    })
    payload.forEach(item => {
      state.templatesIndex[item.id] = item
    })
  },
  [UPDATE_TEMPLATE] (state, template) {
    state.templatesIndex[template.id] = template
  },
  [DELETE_TEMPLATE] (state, templateId) {
    const template = state.templatesIndex[templateId]
    template.is_active = false
    template.is_hide = true
    state.templatesIndex[template.id] = template
  }
}

const actions = {
  clearTemplatesCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getTemplatesByCompany ({ commit, rootState }) {
    const companyId = rootState.company.companyId
    const promiseKey = '/companies/' + companyId + '/templates'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.allTemplatesIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/templates').then(templates => {
        commit(UPDATE_ALL_TEMPLATES_LIST, templates)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(templates)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getProjectTemplates ({ commit }, [projectId, type]) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + projectId + '/templates').then(templates => {
        if (templates) {
          resolve(templates)
        }
      })
    })
  },

  getTemplate ({ commit }, [scheduleId, templateId]) {
    return new Promise(resolve => {
      HTTP.get(`/schedules/${scheduleId}/templates/${templateId}`).then(template => {
        commit(UPDATE_TEMPLATE, template)
        resolve(template)
      })
    })
  },

  getScheduleTemplates ({ commit }, scheduleId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/schedules/${scheduleId}/templates`).then(templates => {
        resolve(templates)
      }).catch(err => {
        reject(err)
      })
    })
  },

  saveTemplate ({ commit, rootState, dispatch }, [scheduleId, templateData]) {
    return new Promise(resolve => {
      HTTP.post(`/schedules/${scheduleId}/templates`, templateData).then(template => {
        if (rootState.companySchedule.currentProjectId && rootState.companySchedule.schedulesByProject.includes(template.schedule_id)) {
          const scheduleTemplateId = [template.schedule_id, template.id].join('__sp__')
          const isDeleted = !template.is_active // !!template.pivot.deleted_at
          const scheduleTemplateName = [rootState.companySchedule.schedulesInfo[template.schedule_id], template.name, template.color, isDeleted]
          dispatch('updateTemplateByProject', {
            id: scheduleTemplateId,
            name: scheduleTemplateName
          })
        }
        commit(UPDATE_TEMPLATE, template)
        resolve(template)
      })
    })
  },

  editTemplate ({ commit }, [scheduleId, templateId, templateData]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/schedules/${scheduleId}/templates/${templateId}`, templateData).then(template => {
        commit(UPDATE_TEMPLATE, template)
        resolve(template)
      }).catch(err => {
        reject(err)
      })
    })
  },

  removeTemplate ({ commit, rootState, dispatch }, [scheduleId, templateId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/schedules/${scheduleId}/templates/${templateId}`).then(response => {
        commit(DELETE_TEMPLATE, templateId)
        dispatch('clearSchedulesCache')
        promises = {}
        lastSuccessful = {}
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
