<template>
  <el-container style="height: auto; max-height: 600px; overflow-y: auto;">
    <el-timeline>
      <el-timeline-item v-for="(item, index) in timeline"
                        :key="index"
                        :color="item.color"
                        :timestamp="formatStringToDateTime(item.date)"
                        placement="top"
      >
        <div class="font-weight-bold">
          {{$t(item.type)}}
        </div>
        <div v-if="item.type === 'activity'">
          {{item.name}}
        </div>
        <div>
          {{item.action}}
        </div>
      </el-timeline-item>
    </el-timeline>
  </el-container>
</template>
<script>
import mixinStatusColors from '@/mixins/mixinStatusColors'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'TimesheetLogComponent',
  mixins: [mixinStatusColors, momentMixin],
  data () {
    return {
      timeline: []
    }
  },
  props: { shift: Object },
  created () {
    const events = []
    this.shift.attendances.forEach(attendance => {
      events.push({
        type: 'attendance',
        name: this.$t(attendance.type),
        action: this.$t(attendance.type),
        date: attendance.updated_at,
        color: this.statusColors[attendance.type === 'shift_start' || attendance.type === 'break_start' ? 'started' : 'finished']
      })
    })
    this.shift.activities.forEach(activity => {
      activity.logs.forEach(action => {
        events.push({
          type: 'activity',
          name: activity.title,
          action: this.$t(action.status.title),
          date: action.updated_at,
          color: action.status.color
        })
      })
    })
    this.timeline = events.sort((a, b) => {
      if (moment(a.date).isAfter(moment(b.date))) {
        return 1
      }
      if (moment(a.date).isBefore(moment(b.date))) {
        return -1
      }
      return 0
    })
  },
  methods: {
    formatStringToDateTime (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
