<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text active" aria-current="page">
            {{this.$i18n?.t("Shifts templates")}}
          </li>
        </ol>
      </nav>
      <div class="btn-group ml-auto">
        <router-link :disabled="!schedules.length > 0" :to="`/c/${companyId}/template`" class="btn btn-info btn-sm btn-circle shadow-sm">{{ $t('Add template') }}</router-link>
      </div>
    </div>
    <div class="card card-small">
      <div class="card-body table-responsive">
        <div class="row my-2">
          <div class="col-auto">
            <div class="font-weight-bold mb-1">
              {{$t('SCHEDULE_FILTER')}}
            </div>
            <ui-select
              name="schedule_select"
              id="schedule_select"
              v-model="filterSchedule"
              value="all"
              class="w-100"
              :options="scheduleChoices"
            />
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <div class="font-weight-bold mb-1">
              {{$t('TEMPLATE_SEARCH')}}
            </div>
            <ui-text-input
              name="Search"
              v-model="filterTemplate"
              :placeholder="$t('Search')"
              autofocus
              :is-search="true"
            />
          </div>
        </div>
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="text-left">{{ $t("SCHEDULE_NAME") }}</th>
            <th class="text-left">{{ $t("Template name") }}</th>
            <th class="text-left">{{ $t("Color") }}</th>
            <th class="text-left">{{ $t("Time") }}</th>
            <th class="text-left">{{ $t("Night shift") }} <ui-hint :content="$t('TEMPLATES_NIGHT_SHIFT_HINT')" /></th>
            <th class="text-left">{{ $t("Break") }}</th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(template) in templates" :key="template.id">
              <template v-if="!template.is_hide">
                <td>
                  {{schedulesIndexed[template.schedule_id].name}}
                </td>
                <td class="align-middle">{{ template.name }}</td>
                <td class="align-middle">
                  <span class="project-color" v-bind:style="{ backgroundColor: template.color }"></span>
                </td>
                <td class="align-middle">
                  {{ timeToLocale(template.time_from) }} - {{ timeToLocale(template.time_to) }}
                  <small>
                    (
                    <template v-if="shiftTimeDiff(template.time_from, template.time_to, 'hours') !== '00'">
                      {{ shiftTimeDiff(template.time_from, template.time_to, 'hours') }} {{ $t('h.') }}
                    </template>
                    <template v-if="shiftTimeDiff(template.time_from, template.time_to, 'min') !== '00'">
                      {{ shiftTimeDiff(template.time_from, template.time_to, 'min') }} {{ $t('m.') }}
                    </template>
                    )
                  </small>
                </td>
                <td class="align-middle">
                  <template v-if="template.night_shift">
                    {{ $t('Yes') }}
                  </template>
                  <template v-else>
                    {{ $t('No') }}
                  </template>
                </td>
                <td class="align-middle">
                  <template v-if="template.break_time">
                    <b> {{ template.break_time }} {{ $t('minutes') }}</b>
                  </template>
                  <template v-else>
                    <b> {{$t('None')}}</b>
                  </template>
                </td>
                <td class="align-middle">
                  <router-link class="mr-2" :to="`/c/${companyId}/schedule/${template.schedule_id}/templates/${template.id}`">
                    <i class="icon-edit btn-icon"></i>
                  </router-link>
                  <i class="icon-trash text-danger btn-icon" @click="removeTemplate(template)"></i>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'

export default {
  name: 'Templates',
  mixins: [momentMixin],
  data () {
    return {
      filterSchedule: 'all',
      filterTemplate: ''
    }
  },
  created () {
    this.$store.dispatch('getTemplatesByCompany')
    this.$store.commit('filterScheduleTemplate', this.filterSchedule)
  },

  computed: {
    ...mapGetters({ projects: 'projectsIndexed' }),
    companyId () {
      return this.$store.getters.companyId
    },
    schedulesIndexed () {
      return this.$store.getters.schedulesIndexed
    },
    schedules () {
      return this.$store.getters.schedules
    },
    templates () {
      let templates = []
      if (this.isEmployeeHasPermission('create project')) {
        templates = this.$store.getters.templates
      } else {
        const employeeId = this.$store.getters.company.employee_id
        templates = this.$store.getters.templates
        const projects = this.$store.getters.projects.filter(project => project.managers && project.managers.includes(employeeId))
        const schedulesIds = []
        this.$store.getters.schedules.forEach(schedule => {
          const val = false
          projects.forEach(project => {
            if (schedule.project_id === project.id) schedulesIds.push(schedule.id)
          })
          return val
        })
        templates = templates.filter(template => schedulesIds.includes(template.schedule_id))
      }

      if (templates) {
        if (this.filterSchedule && this.filterSchedule !== 'all') {
          templates = templates.filter(item => +item.schedule_id === +this.filterSchedule)
        }

        if (this.filterTemplate && this.filterTemplate !== '') {
          const needle = this.filterTemplate.toString().toLowerCase()
          templates = templates.filter(template => {
            if (needle === '') {
              return true
            }
            return (template.name.toLowerCase()).indexOf(needle) > -1
          })
        }

        templates.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
      }

      return templates
    },
    scheduleChoices () {
      let schedules = this.$store.getters.schedules
      if (!this.isEmployeeHasPermission('create project')) {
        schedules = schedules.filter(schedule => this.projects[schedule.project_id] && this.projects[schedule.project_id].role === 'manager')
      }

      if (schedules) {
        schedules.sort((a, b) => {
          if (!b.name) {
            console.log('no name on schedule', [a, b, schedules])
            return 1
          }
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
      }

      const choices = [
        {
          id: 'all',
          name: this.$t('SHOW_ALL')
        }
      ]

      schedules.forEach(schedule => {
        choices.push({
          id: schedule.id,
          name: schedule.name
        })
      })

      return choices
    }
  },

  methods: {
    splitTypeTimeTable (typeTimeTable, index) {
      if (typeTimeTable) {
        const splitArray = typeTimeTable.split('/')
        return splitArray[index]
      }
    },
    shiftTimeDiff (timeFrom, timeTo, type) {
      const diff = moment.utc(moment(timeTo, 'HH:mm').diff(moment(timeFrom, 'HH:mm'))).format('HH:mm')
      const diffArray = diff.split(':')
      if (type === 'hours') {
        return diffArray[0]
      } else if (type === 'min') {
        return diffArray[1]
      }
    },
    removeTemplate (template) {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        const name = template.name
        this.$store.dispatch('removeTemplate', [template.schedule_id, template.id]).then((response) => {
          this.toastSuccess([this.$i18n?.t('Template shift'), name, this.$i18n?.t('was successfully deleted.')].join(' '))
        }).catch(error => {
          this.toastError(this.$i18n?.t(error.response.data.message))
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
