<template>
  <div>
    <div id="vacationsAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">{{ $t('VACATION_SETTINGS_HEADER') }} <ui-hint v-if="moduleInfo" :content="moduleInfo"/></h5>
        </div>
        <div id="collapseVacationsSettings" class="collapse show" aria-labelledby="headingOne" data-parent="#vacationsAccordion">
          <div class="card-body">
            <modal-component
              :id="'vacationsSettingsModal'"
              @closeModal="closeModal"
              :modalClass="'modal-dummy'"
              :modalBodyClass="'dummy-popup'"
              :hideFooter="true"
              :hideHeader="true">
              <template #body>
                <div>
                  <dummy-payment-required
                    @activate="init"
                    :dummyInfo="dummyInfo"
                    :isModal="true"
                  >
                  </dummy-payment-required>
                </div>
              </template>
            </modal-component>
            <div class="form-row mt-3">
              <div
                class="col-auto"
                @click="handleActivation"
                :data-toggle="!isModuleActive ? 'modal' : null"
                :data-target="!isModuleActive ? '#vacationsSettingsModal' : null"
              >
                <div class="form-row">
                  <div class="col-lg-12 mb-2 text-primary text-uppercase font-weight-bold">
                    {{ $t('VACATION_OVERUSE_HANDLER') }}
                    <ui-hint :content="$t('VACATION_OVERUSE_HANDLER_HINT')"/>
                  </div>
                  <div class="col-lg-12 mt-2">
                    <ui-radio
                      :name="'block'"
                      :set-value="'block'"
                      v-model="settings.vacation_overuse_request_handler"
                      :label="$t('BLOCK_VACATION_REQUEST')"
                      :hint="$t('BLOCK_VACATION_REQUEST_HINT')"
                      :disabled="!isModuleActive"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-radio
                      name="show_warning"
                      set-value="show_warning"
                      v-model="settings.vacation_overuse_request_handler"
                      :label="$t('SHOW_WARNING_TO_MANAGER')"
                      :hint="$t('SHOW_WARNING_TO_MANAGER_HINT')"
                      :disabled="!isModuleActive"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mixinDummy from '@/mixins/mixinDummy'
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import ModalComponent from '@/components/CommonComponents/ModalComponent.vue'

export default {
  components: { ModalComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  name: 'SettingsVacations',
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      companyModules: [],
      module: null,
      settings: {
        vacation_overuse_request_handler: 'show_warning'
      },
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  // watch: {
  //   ModulesByCompany () {
  //     if (this.ModulesByCompany.length > 0) {
  //       console.log(1)
  //       console.log(this.ModulesByCompany)
  //       this.init()
  //     }
  //   }
  // },
  methods: {
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 26) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            const settings = {
              vacation_overuse_request_handler: this.module.settings.vacation_overuse_request_handler
                ? this.module.settings.vacation_overuse_request_handler
                : 'show_warning'
            }
            this.settings = settings
          }
        }
      })
      // модуль timeOff активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 27]).then(response => {
        if (response.active === true) {
          this.isModuleActive = true
        }
      }).catch(err => {
        if (err.response.data.module) {
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
          // console.log(this.dummyInfo)
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    submitForm () {
      if (this.isModuleActive) {
        this.$store.dispatch('updateModuleSettings', [
          this.$store.getters.companyId,
          this.module.id,
          {
            settings: this.settings
          }
        ]).then(async () => {
          await this.$store.dispatch('clearCompanyStore')
          await this.$store.dispatch('getCompany', this.$store.getters.companyId)
          this.$emit('success')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
