<template>
  <modal-component :id="'ImportFileModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Import Employees')}}</h5>
    </template>
    <template #body>
      <div class="font-weight-bold mb-2">
        {{ $t('Your must import the following data: first name, last name, phone number, e-mail.') }}
      </div>
      <img width="100%" height="100%" src="../../../../public/static/images/App/example_import_users.png"/>
      <div class="font-weight-bold my-2">
        {{ $t('Need an example?') }} <a :href="`${publicPath}static/files/import_example.xlsx`">{{ $t('Download our template') }}</a>
      </div>
      <ui-file-upload
        name='file'
        :upload="upload"
        :auto-upload="false"
        :multiple="true"
        :limit="1"
        @file="getFile"
      />
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm"  data-dismiss="modal" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('Import a file')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import momentMixin from '../../../mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'ImportFileModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin],
  data () {
    return {
      publicPath: process.env.BASE_URL,
      file: '',
      fileList: [],
      upload: {
        url: process.env.ROOT_API + 'import_file/',
        headers: {
          Authorization: 'api-key ' + localStorage.getItem('api_key')
        }
      }
    }
  },
  computed: {
    unconfirmedProceed () {
      return localStorage.getItem('unconfirmedProceed')
    }
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('closeImportFileModal')
    },
    getFile (data) {
      this.file = data.file
    },
    save () {
      if (this.file) {
        const formData = new FormData()
        formData.append('company_id', this.$store.getters.companyId)
        formData.append('preview', true)
        formData.append('file', this.file)
        this.$store.dispatch('importEmployees', formData).then(response => {
          this.closeModal()
          this.$eventBus.emit('LoadedFile', { file: this.file, file_preload: response })
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      } else {
        this.toastError(this.$t('Chose a file!'))
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
