<template>
  <div class="row">
    <template v-for="(app, index) in filteredDevApps" :key="index">
      <application-item :application="app" :is-enabled="checkEnabled(app.id)" />
    </template>
  </div>
</template>
<script>
import errorMixin from '@/mixins/mixinApiErrors'
import ApplicationItem from '@/components/AppMarketplace/components/ApplicationItem.vue'

export default {
  name: 'DeveloperAppMarketplace',
  components: {
    ApplicationItem
  },
  mixins: [
    errorMixin
  ],
  data () {
    return {
      devApps: [],
      companyApps: [],
      filteredDevApps: []
    }
  },
  props: {
    filter: [String, Number]
  },
  created () {
    this.$store.dispatch('getAppCategories')
    this.$store.dispatch('getCompanyApps').then(response => {
      this.companyApps = response
    })
    this.getDevApps()
  },
  computed: {
    appCategories () {
      return [{ id: 'all', name: this.$i18n?.t('All') }].concat(this.$store.getters.appCategories)
    }
  },
  watch: {
    filter () {
      this.changeCategory()
    }
  },
  methods: {
    getDevApps () {
      this.$store.dispatch('getDevApps').then(response => {
        this.devApps = response
        this.filteredDevApps = response
      })
    },
    checkEnabled (appId) {
      return !!this.companyApps.find(item => item.developer_app_id === appId && item.enabled === true)
    },
    changeCategory () {
      console.log(12, this.filter)
      if (this.filter === 'all') {
        this.filteredDevApps = this.devApps
      } else {
        this.filteredDevApps = this.devApps.filter(item => item.category_id === this.filter)
      }
    }
  }
}
</script>

<style scoped>

</style>
