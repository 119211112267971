<template>
  <div>
    <div v-if="isEmployeeHasPermission('change-employee-availability') && !employeeId" class="row my-2">
      <div class="col text-left">
        <span class="title-text">
          {{ $t("AVAILABILITY_MAIN_TITLE") }}
          <ui-hint :content="$t('AVAILABILITY_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="col-auto text-right">
        <ui-select
          name="selectedEmployeeId"
          v-model="selectedEmployeeId"
          :clearable="false"
          :placeholder="$t('Select')"
          :options="employees"
          key-name="id"
          label-name="full_name"
          value-name="id"
          style="min-width: 200px"
        />
      </div>
    </div>
    <AvailabilityModal
      v-if="availabilityModalShow"
      :eventId="eventId"
      :selectedDate="selectedDate"
      :selectedEmployeeId="selectedEmployeeId"
      @refetch="refetch"
      @closeClickAvailabilityModal="() => { this.availabilityModalShow = false }"
      @onUpdate="onUpdate"
      @onDelete="onDelete"
    />
    <el-dialog
      :title="$t('UPDATE_AVAILABILITY_TITLE')"
      :modal="false"
      width="300px"
      v-model="dialogUpdateVisible">
      <div>
        <el-radio v-model="updateType" label="single">{{$t('UPDATE_CURRENT')}}</el-radio>
      </div>
      <div>
        <el-radio v-model="updateType" label="future">{{$t('UPDATE_FROM_CURRENT')}}</el-radio>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogUpdateVisible = false">{{$t('Cancel')}}</el-button>
          <el-button type="primary" @click="confirmUpdate">{{$t('Confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('DELETE_AVAILABILITY_TITLE')"
      :modal="false"
      width="300px"
      v-model="dialogDeleteVisible">
      <div>
        <el-radio v-model="updateType" label="single">{{$t('DELETE_CURRENT')}}</el-radio>
      </div>
      <div>
        <el-radio v-model="updateType" label="future">{{$t('DELETE_FROM_CURRENT')}}</el-radio>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDeleteVisible = false">{{$t('Cancel')}}</el-button>
          <el-button type="primary" @click="confirmDelete">{{$t('Confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
    <div id="availability_modal" data-toggle="modal" data-target="#ClickAvailabilityModal" />
    <div class="form-body">
      <full-calendar ref="availabilityCalendar" :options="calendarOptions">
        <template v-slot:eventContent='arg'>
          <AvailabilityEvent :event="arg.event" />
        </template>
      </full-calendar>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import FullCalendar from '@fullcalendar/vue3'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import listViewPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import interactionPlugin from '@fullcalendar/interaction'
import heLocale from '@fullcalendar/core/locales/he'
import ruLocale from '@fullcalendar/core/locales/ru'
import deLocale from '@fullcalendar/core/locales/de'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import moment from 'moment-timezone'
import AvailabilityModal from '@/components/CommonComponents/Availability/AvailabilityModal'
import AvailabilityEvent from '@/components/CommonComponents/Availability/AvailabilityEvent'

export default {
  name: 'Availability',
  components: { AvailabilityEvent, AvailabilityModal, FullCalendar },
  mixins: [momentMixin, mixinFCWeek, mixinWindowWidthWatch],
  data () {
    return {
      calendarApi: null,
      view: null,
      calendarOptions: {
        plugins: [dayGridPlugin, rrulePlugin, bootstrapPlugin, listViewPlugin, interactionPlugin],
        themeSystem: 'bootstrap',
        initialView: 'dayGridMonth',
        selectable: true,
        locale: this.$store.getters.locale,
        locales: [heLocale, ruLocale, deLocale, plLocale, esLocale, elLocale],
        nextDayThreshold: '24:00:00',
        buttonText: {
          prev: '<',
          next: '>'
        },
        views: {
          dayGridMonth: {
            fixedWeekCount: false,
            displayEventTime: true,
            displayEventEnd: true
          },
          listMonth: {
            displayEventTime: true,
            displayEventEnd: true,
            contentHeight: 380
          }
        },
        eventTimeFormat: { // формат времени, которое отображается на событии
          hour: 'numeric',
          hour12: this.is12hourFormat,
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        },
        eventClassNames: ['rounded', 'p-1'],
        eventDisplay: 'block',
        events: (fetchInfo, successCallback, failureCallback) => {
          let dateStart, dateEnd
          dateStart = moment.utc(fetchInfo.startStr).format(this.backendDateTimeFormat)
          dateEnd = moment.utc(fetchInfo.endStr).format(this.backendDateTimeFormat)
          const params = '?start=' + dateStart + '&' + 'end=' + dateEnd
          this.$store.dispatch('getAvailability', [this.selectedEmployeeId, params]).catch(e => {
            this.toastError(this.$i18n?.t('Employee not found'))
          })
          this.successCallback = successCallback
        },
        dateClick: (info) => {
          // console.log(2, info.date)
          this.selectedDate = info.date
          this.eventId = null
          this.availabilityModalShow = true
          this.$nextTick(() => {
            document.getElementById('availability_modal').click()
          })
        },
        eventClick: (eventClickInfo) => {
          // console.log(1, moment(eventClickInfo.event.start).format(this.backendDateTimeFormat))
          this.selectedDate = moment(eventClickInfo.event.start).format(this.backendDateTimeFormat)
          this.eventId = +eventClickInfo.event.id
          this.availabilityModalShow = true
          this.$nextTick(() => {
            document.getElementById('availability_modal').click()
          })
        },
        windowResize: (info) => {
          if (this.windowWidth >= 576 && info.view.type !== 'dayGridMonth') {
            this.calendarApi.changeView('dayGridMonth')
          }
          if (this.windowWidth < 576 && info.view.type !== 'listMonth') {
            this.calendarApi.changeView('listMonth')
          }
        }
      },
      availabilityModalShow: false,
      selectedDate: null,
      selectedEmployeeId: null,
      successCallback: null,
      eventId: null,
      dialogUpdateVisible: false,
      updateType: 'single',
      updatePayload: null,
      dialogDeleteVisible: false
    }
  },
  props: {
    employeeId: Number
  },
  created () {
    if (this.employeeId) {
      this.selectedEmployeeId = this.employeeId
    } else {
      this.selectedEmployeeId = this.currentEmployeeId
    }
    this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId)
  },
  mounted () {
    this.calendarApi = this.$refs.availabilityCalendar.getApi()
    this.view = this.calendarApi.view
    this.calendarApi.setOption('direction', this.direction)
    this.calendarApi.setOption('firstDay', this.firstDay)
    this.calendarApi.setOption('initialView', this.viewType)
    // console.log(this.calendarApi.getEvents())
  },
  computed: {
    firstDay () {
      return this.fcWeek[this.apiWeek[this.$store.getters.company.salary.week_start]]
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    viewType () {
      return this.windowWidth >= 576 ? 'dayGridMonth' : 'listMonth'
    },
    currentEmployeeId () {
      return this.$store.getters.company.employee_id
    },
    availability () {
      return this.$store.getters.availability
    },
    employees () {
      return this.$store.getters.employees
    }
  },
  watch: {
    availability () {
      this.successCallback(this.setEvents())
    },
    selectedEmployeeId () {
      if (this.calendarApi && this.selectedEmployeeId) {
        this.calendarApi.refetchEvents()
      }
    }
  },
  methods: {
    refetch () {
      this.calendarApi.refetchEvents()
    },
    onUpdate (payload) {
      this.updatePayload = payload
      this.dialogUpdateVisible = true
    },
    onDelete () {
      this.dialogDeleteVisible = true
    },
    confirmUpdate () {
      this.updatePayload.update_type = this.updateType
      this.updatePayload.selected_date = moment(this.selectedDate).format(this.backendDateTimeFormat)
      // console.log(123, this.updatePayload)
      this.updateAvailability(this.updatePayload)
      this.dialogUpdateVisible = false
    },
    confirmDelete () {
      this.dialogDeleteVisible = false
      this.$confirm(this.$t('This will permanently delete the event. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteAvailability', [
          this.selectedEmployeeId,
          this.eventId,
          {
            selected_date: moment(this.selectedDate).format(this.backendDateTimeFormat),
            delete_type: this.updateType
          }
        ]).then(() => {
          this.calendarApi.refetchEvents()
        }).catch(e => {
          this.toastError(this.$i18n?.t(e.message))
        })
      }).catch(() => {})
    },
    updateAvailability (payload) {
      this.$store.dispatch('updateAvailability', [this.selectedEmployeeId, this.eventId, payload]).then(() => {
        this.calendarApi.refetchEvents()
      }).catch(e => {
        this.toastError(this.$i18n?.t(e.message))
      })
    },
    setEvents () {
      const payload = []
      this.availability.forEach(item => {
        const event = {
          id: item.id,
          title: item.note ? item.note : '',
          color: item.color,
          duration: moment.utc(moment.duration(item.duration, 'minutes').asMilliseconds()).format('HH:mm'),
          rrule: {
            dtstart: item.dtstart,
            until: item.until,
            freq: item.freq,
            interval: item.interval,
            count: item.is_recurring ? null : 1,
            byhour: item.byhour,
            byminute: item.byminute,
            bysecond: 0,
            byweekday: item.byweekday
          },
          // exdate: ['2022-08-04 08:00:00']
          exdate: item.exdate
        }
        // event.duration = moment.utc(moment.duration(item.duration, 'minutes').asMilliseconds()).format('HH:mm')
        // console.log(event)
        payload.push(event)
      })
      return payload
    }
  }
}
</script>

<style scoped>

</style>
