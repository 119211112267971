<template>
  <div class="row">
    <div class="col">
      <GoogleMap
        mapId="locationMApId"
        ref="googleMap"
        :api-key="googleApiKey"
        style="width: 100%; height: 800px"
        :center="center"
        :zoom="zoom"
      >
        <Circle
          v-for="circle in circles"
          :options="circle"
          :key="circle.id"
        />
        <Polygon
          v-for="polygon in polygons"
          :options="polygon"
          :key="polygon.id"
        />
        <Polyline
          v-for="(polyline, index) in polylines"
          :options="polyline"
          :key="'poly_' + index"
        />
        <MarkerCluster>
          <AdvancedMarker
            :ref="el => this.addListener(el, marker)"
            v-for="(marker, index) in markers"
            :key="marker.id + '__' + index"
            :options="marker.options"
            :pin-options="marker.pinOptions"
          >
            <InfoWindow v-model="idInfoWindow[marker.id]">
              <TooltipComponent :infoContent="marker.data"/>
            </InfoWindow>
          </AdvancedMarker>
        </MarkerCluster>
      </GoogleMap>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import TooltipComponent from '@/components/LocationControl/Components/TooltipComponent'
import { AdvancedMarker, GoogleMap, InfoWindow, MarkerCluster, Circle, Polygon, Polyline } from 'vue3-google-map'

export default {
  name: 'MapComponent',
  components: { MarkerCluster, InfoWindow, AdvancedMarker, TooltipComponent, GoogleMap, Circle, Polygon, Polyline },
  mixins: [momentMixin],
  data () {
    return {
      center: { lat: 0, lng: 0 },
      googleApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      zoom: 18,
      polylines: [],
      markers: [],
      circles: [],
      polygons: [],
      idInfoWindow: {}
    }
  },
  props: { showTooltips: Boolean },
  created () {
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
    })
    this.$store.dispatch('getLocations', this.companyId).then(response => {
    }).catch(() => {
      // TODO: missing catch
    })
  },
  mounted () {
    this.init()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      profile: 'Profile'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    },
    displayFilters () {
      return this.$store.getters.displayFilters
    },
    employeesList () {
      return this.$store.getters.employeesGeolocation
    },
    locations () {
      return this.$store.getters.locations.filter(l => !l.hide)
    }
  },
  watch: {
    locations () {
      const circles = []
      const polygons = []
      this.locations.forEach(location => {
        if (location.use_type === 'circle') {
          circles.push({
            center: {
              lat: +location.latitude,
              lng: +location.longitude
            },
            radius: location.allowed_radius,
            strokeColor: ' #2c2c2c ',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#2c2c2c',
            fillOpacity: 0.35
          })
        }
        if (location.geometry && location.geometry.coordinates && location.use_type === 'polygon') {
          const polygon = []
          location.geometry.coordinates[0].forEach(point => {
            polygon.push({
              lng: +point[0],
              lat: +point[1]
            })
          })
          polygons.push({
            paths: polygon,
            strokeColor: '#2c2c2c',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#2c2c2c',
            fillOpacity: 0.35
          })
        }
      })
      this.polygons = polygons
      this.circles = circles
    },
    employeesList () {
      this.init()
    },
    displayFilters () {
      this.init()
    },
    showTooltips () {
      this.markers.forEach(marker => {
        this.idInfoWindow[marker.id] = this.showTooltips
      })
    }
  },
  methods: {
    addListener (el, marker) {
      const idInfoWindow = this.idInfoWindow
      if (el && el.marker) {
        el.marker.content.addEventListener('mouseenter', function () {
          idInfoWindow[marker.id] = true
        })
        el.marker.content.addEventListener('mouseleave', function () {
          idInfoWindow[marker.id] = false
        })
      }
    },
    init () {
      if (this.displayFilters.only_last_point) {
        this.handlePoints()
      } else {
        this.handleRoutes()
      }
    },
    handlePoints () {
      const locations = []
      const employeesList = this.displayFilters.employees_filter.length > 0
        ? this.employeesList.filter(employee => this.displayFilters.employees_filter.includes(employee.id))
        : this.employeesList
      employeesList.forEach(employee => {
        const index = employee.locations.length - 1
        locations.push({
          id: employee.locations[index].id,
          shift_id: employee.locations[index].shift_id,
          latitude: employee.locations[index].latitude,
          longitude: employee.locations[index].longitude,
          recorded_time: employee.locations[index].recorded_time,
          color: employee.color,
          name: employee.full_name,
          marker_label: (employee.last_name[0] + ' ' + employee.first_name[0]).toUpperCase()
        })
      })
      this.updateMarkers(locations)
    },
    handleRoutes () {
      const locations = []
      const locationsIndexed = {}
      const employeesList = this.displayFilters.employees_filter.length > 0 ? this.employeesList.filter(employee => this.displayFilters.employees_filter.includes(employee.id)) : this.employeesList
      employeesList.forEach(employee => {
        employee.locations.forEach(location => {
          if (!locationsIndexed[location.shift_id]) {
            locationsIndexed[location.shift_id] = []
          }
          locations.push({
            id: location.id,
            latitude: location.latitude,
            longitude: location.longitude,
            recorded_time: location.recorded_time,
            name: employee.full_name,
            marker_label: (employee.last_name[0] + ' ' + employee.first_name[0]).toUpperCase(),
            color: employee.color,
            shift_id: location.shift_id
          })
          locationsIndexed[location.shift_id].push({
            lat: +location.latitude,
            lng: +location.longitude,
            color: employee.color
          })
        })
      })
      this.updateMarkers(locations)
      this.updatePolyline(locationsIndexed)
    },
    updatePolyline (locations) {
      const lines = []
      Object.values(locations).forEach(path => {
        let lineSymbol
        if (this.$refs.googleMap.api) {
          lineSymbol = {
            path: this.$refs.googleMap.api.SymbolPath.FORWARD_CLOSED_ARROW
          }
        }
        lines.push({
          path: path,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 2,
          icons: [{
            icon: lineSymbol,
            repeat: '100px'
          }]
        })
      })
      this.polylines = lines
    },
    updateMarkers (locations) {
      const markers = []
      const points = []
      locations.forEach(location => {
        markers.push(this.createMarker(location))
        points.push({
          lat: +location.latitude,
          lng: +location.longitude
        })
      })
      this.fitToMap(points)
      this.markers = markers
    },
    createMarker (data) {
      this.idInfoWindow[data.shift_id] = this.showTooltips
      return {
        id: data.id + '__' + data.shift_id,
        options: {
          position: { lat: +data.latitude, lng: +data.longitude }
        },
        pinOptions: {
          background: data.color,
          borderColor: data.color
        },
        data: {
          time: this.fromZoneToZone(data.recorded_time, 'UTC', this.tz).format(this.localeDateTimeFormat),
          name: data.name,
          marker_label: data.marker_label
        }
      }
    },
    fitToMap (points) {
      if (points.length > 0) {
        const bounds = this.$refs.googleMap.map?.getBounds()
        if (bounds) {
          for (let n = 0; n < points.length; n++) {
            bounds.extend(points[n])
          }
          this.$refs.googleMap.map?.fitBounds(bounds)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
