<template>
  <div>
    <div id="attendanceAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">{{ $t('ATTENDANCE_HEADER') }}
            <ui-hint v-if="moduleInfo" :content="moduleInfo"/>
          </h5>
        </div>

        <div id="collapseAttendanceSettings" class="collapse show" aria-labelledby="headingOne"
             data-parent="#attendanceAccordion">
          <div class="card-body">
            <modal-component
              :id="'AttendanceSettingsModal'"
              @closeModal="closeModal"
              :modalClass="'modal-dummy'"
              :modalBodyClass="'dummy-popup'"
              :hideFooter="true"
              :hideHeader="true"
              >
              <template #body>
                <dummy-payment-required
                  @activate="activate"
                  :dummyInfo="dummyInfo"
                  :isModal="true">
                </dummy-payment-required>
              </template>
            </modal-component>
            <Form ref="observer" as="div" class="form-row">
              <div class="col-lg-12">
                <el-divider content-position="left">
                <span class="text-primary text-uppercase font-weight-bold">
                  {{ $t('MAIN_SETTINGS') }}
                </span>
                </el-divider>
              </div>
              <div class="col-auto" @click="handleActivation('attendance')"
                   :data-toggle="!isModuleActive ? 'modal' : null"
                   :data-target="!isModuleActive ? '#AttendanceSettingsModal' : null">
                <div class="p-0 mb-1 col-lg-12 font-weight-bold">
                  {{ $t('WEB_INTERFACE_SETTINGS') }}
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_web_enabled"
                    v-model="settings.interfaces.web.enabled"
                    :disabled="!isModuleActive"
                    :label="$t('IS_WEB_INTERFACE_ENABLE')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_web_check_ip"
                    v-model="settings.interfaces.web.check_ip"
                    :disabled="!isModuleActive || !settings.interfaces.web.enabled"
                    :label="$t('WEB_INTERFACE_CHECK_IP')"
                    :hint="$t('WEB_INTERFACE_CHECK_IP_HINT')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12 pl-2">
                  <ui-checkbox
                    name="settings_interfaces_web_skip_check_breaks"
                    v-model="settings.interfaces.web.skip_check_breaks"
                    :disabled="!isModuleActive || !settings.interfaces.web.check_ip"
                    :label="$t('WEB_INTERFACE_SKIP_CHECK_BREAKS')"
                    :hint="$t('WEB_INTERFACE_SKIP_CHECK_BREAKS_HINT')"
                  />
                </div>
                <div class="p-0 mb-1 col-lg-12 font-weight-bold">
                  {{ $t('MOBILE_INTERFACE_SETTINGS') }}
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_mobile_enabled"
                    v-model="settings.interfaces.mobile.enabled"
                    :disabled="!isModuleActive"
                    :label="$t('IS_MOBILE_INTERFACE_ENABLE')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_mobile_check_ip"
                    v-model="settings.interfaces.mobile.check_ip"
                    :disabled="!isModuleActive || !settings.interfaces.mobile.enabled"
                    :label="$t('MOBILE_INTERFACE_CHECK_IP')"
                    :hint="$t('MOBILE_INTERFACE_CHECK_IP_HINT')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_mobile_check_gps"
                    v-model="settings.interfaces.mobile.check_gps"
                    :disabled="!isModuleActive || !settings.interfaces.mobile.enabled"
                    :label="$t('MOBILE_INTERFACE_CHECK_GPS')"
                    :hint="$t('MOBILE_INTERFACE_CHECK_GPS_HINT')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12 pl-2">
                  <ui-checkbox
                    name="settings_interfaces_mobile_skip_check_breaks"
                    v-model="settings.interfaces.mobile.skip_check_breaks"
                    :disabled="!isModuleActive || !(settings.interfaces.mobile.check_ip || settings.interfaces.mobile.check_gps)"
                    :label="$t('MOBILE_INTERFACE_SKIP_CHECK_BREAKS')"
                    :hint="$t('MOBILE_INTERFACE_SKIP_CHECK_BREAKS_HINT')"
                  />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('SHIFT_ACTION_SETTINGS') }}
                    </span>
                    </el-divider>
                  </div>
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces._validations_change_event_time"
                    v-model="settings.interfaces.validations.change_event_time"
                    :disabled="!isModuleActive"
                    :label="$t('ATTENDANCE_CHANGE_EVENTS_TIME')"
                    :hint="$t('ATTENDANCE_CHANGE_EVENTS_TIME_HINT')"
                  />
                </div>
                <div class="mb-1 pl-2 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_validations_dont_change_shift_end_time"
                    v-model="settings.interfaces.validations.dont_change_shift_end_time"
                    :disabled="!isModuleActive || !settings.interfaces.validations.change_event_time"
                    :label="$t('ATTENDANCE_DONT_CHANGE_SHIFT_END_TIME')"
                    :hint="$t('ATTENDANCE_DONT_CHANGE_SHIFT_END_TIME_HINT')"
                  />
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_validations_update_shift_lateness"
                    v-model="settings.interfaces.validations.update_shift_lateness"
                    :disabled="!isModuleActive"
                    :label="$t('ATTENDANCE_UPDATE_SHIFT_LATENESS')"
                    :hint="$t('ATTENDANCE_UPDATE_SHIFT_LATENESS_HINT')"
                  />
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="settings_interfaces_validations_update_shift_early"
                    v-model="settings.interfaces.validations.update_shift_early"
                    :disabled="!isModuleActive"
                    :label="$t('ATTENDANCE_UPDATE_SHIFT_EARLIER')"
                    :hint="$t('ATTENDANCE_UPDATE_SHIFT_EARLIER_HINT')"
                  />
                </div>
                <div class="mb-1 p-0 col-lg-12">
                  <ui-checkbox
                    name="allows_reopen_shift"
                    v-model="settings.interfaces.validations.allows_reopen_shift"
                    :disabled="!isModuleActive"
                    :label="$t('ALLOWS_REOPEN_SHIFT')"
                    :hint="$t('ALLOWS_REOPEN_SHIFT_HINT')"
                  />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('EMPLOYEE_TIME_CONTROL_SETTINGS') }}
                    </span>
                    </el-divider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 p-0">
                    <div class="mb-1 col-lg-12 font-weight-bold">
                      {{ $t('SHIFT_ATTENDANCE_SETTINGS') }}
                      <ui-hint :content="$t('SHIFT_ATTENDANCE_SETTINGS_HINT')"/>
                    </div>
                    <div class="mb-1 col-lg-12">
                      <ui-checkbox
                        name="settings_interfaces_validations_shift_time.enabled"
                        v-model="settings.interfaces.validations.shift_time.enabled"
                        :disabled="!isModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled)"
                        :label="$t('IS_SHIFT_SETTINGS_ENABLE')"
                      />
                    </div>
                    <div class="mx-2">
                      <div class="mb-1 col-lg-12">
                        <ui-checkbox
                          name="settings_interfaces_validations_shift_time.check_time_before"
                          v-model="settings.interfaces.validations.shift_time.check_time_before"
                          :disabled="!isModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.shift_time.enabled"
                          :label="$t('IS_CHECK_TIME_BEFORE_ENABLE')"
                        />
                      </div>
                      <div class="mb-1 col-12 col-md-6">
                        <ui-number-input
                          :disabled="!isModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.shift_time.enabled || !settings.interfaces.validations.shift_time.check_time_before"
                          name="shift_before_time"
                          :label="$t('BEFORE_TIME')"
                          :append="true"
                          :step="1"
                          :precision="0"
                          :min="0"
                          v-model="settings.interfaces.validations.shift_time.time_before"
                          :validation="{required: true, integer: true, min_value: 0}"
                        />
                      </div>
                      <div class="mb-1 col-lg-12 font-weight-bold">
                        {{ $t('IS_CHECK_TIME_AFTER_ENABLE') }}
                      </div>
                      <div class="mb-1 col-12 col-md-6">
                        <ui-number-input
                          :disabled="!isModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.shift_time.enabled"
                          name="shift_after_time"
                          :label="$t('AFTER_TIME')"
                          :append="true"
                          :step="1"
                          :precision="0"
                          :min="0"
                          v-model="settings.interfaces.validations.shift_time.time_after"
                          :validation="{required: true, integer: true, min_value: 0, max_value: 1440}"
                        />
                      </div>
                    </div>
                  </div>
                  <el-divider />
                  <div class="col-lg-12 font-weight-bold text-warning my-2">{{ $t('NEED_BREAKS_MODULE') }}</div>
                  <div class="col-lg-12 p-0" @click="handleActivation('breaks')"
                       :data-toggle="isModuleActive && !isBreakModuleActive ? 'modal' : null"
                       :data-target="isModuleActive && !isBreakModuleActive ? '#AttendanceSettingsModal' : null">
                    <div class="mb-1 col-lg-12 font-weight-bold">
                      {{ $t('BREAK_ATTENDANCE_SETTINGS') }}
                      <ui-hint :content="$t('BREAK_ATTENDANCE_SETTINGS_HINT')"/>
                    </div>
                    <div class="mb-1 col-lg-12">
                      <ui-checkbox
                        name="settings_interfaces_validations_break_time.enabled"
                        v-model="settings.interfaces.validations.break_time.enabled"
                        :disabled="!isModuleActive || !isBreakModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled)"
                        :label="$t('IS_BREAK_SETTINGS_ENABLE')"
                      />
                    </div>
                    <div class="mx-2">
                      <div class="mb-1 col-lg-12">
                        <ui-checkbox
                          name="settings_interfaces_validations_break_time_check_time_before"
                          v-model="settings.interfaces.validations.break_time.check_time_before"
                          :disabled="!isModuleActive || !isBreakModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.break_time.enabled"
                          :label="$t('IS_CHECK_TIME_BEFORE_ENABLE')"
                        />
                      </div>
                      <div class="mb-1 col-12 col-md-6">
                        <ui-number-input
                          :disabled="!isModuleActive || !isBreakModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.break_time.enabled || !settings.interfaces.validations.break_time.check_time_before"
                          name="break_before_time"
                          :label="$t('BEFORE_TIME')"
                          :append="true"
                          :step="1"
                          :precision="0"
                          :min="0"
                          v-model="settings.interfaces.validations.break_time.time_before"
                          :validation="{required: true, integer: true, min_value: 0}"
                        />
                      </div>
                      <div class="mb-1 col-lg-12 font-weight-bold">
                        {{ $t('IS_CHECK_TIME_AFTER_ENABLE') }}
                      </div>
                      <div class="mb-1 col-12 col-md-6">
                        <ui-number-input
                          :disabled="!isModuleActive || !isBreakModuleActive || (!settings.interfaces.web.enabled && !settings.interfaces.mobile.enabled) || !settings.interfaces.validations.break_time.enabled"
                          name="break_after_time"
                          :label="$t('AFTER_TIME')"
                          :append="true"
                          :step="1"
                          :precision="0"
                          :min="0"
                          v-model="settings.interfaces.validations.break_time.time_after"
                          :validation="{required: true, integer: true, min_value: 0, max_value: 1440}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('NOTIFY_LATE_MANAGER_SETTINGS') }}
                    </span>
                    </el-divider>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-1 col-lg-12 mt-2">
                    <ui-checkbox
                      name="settings_notify_late"
                      v-model="settings.notify_late"
                      :disabled="!isModuleActive"
                      :label="$t('NOTIFY_LATE_EMPLOYEES')"
                    />
                  </div>
                  <div class="mb-1 col-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive"
                      name="notify_late_time"
                      :append="true"
                      :label="$t('NOTIFY_LATE_EMPLOYEES_TIME')"
                      v-model="settings.notify_late_time"
                      :validation="{required: true, integer: true, min_value: 5, max_value: 1440}"
                      :precision="0"
                      :step="1"
                      :min="5"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="mb-1 col-lg-12 mt-2">
                    <ui-checkbox
                      name="settings_notify_early_leaving"
                      v-model="settings.notify_early_leaving"
                      :disabled="!isModuleActive"
                      :label="$t('NOTIFY_EARLY_LEAVING')"
                    />
                  </div>
                  <div class="mb-1 col-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive"
                      name="notify_early_leaving_time"
                      :append="true"
                      :label="$t('NOTIFY_EARLY_LEAVING_TIME')"
                      v-model="settings.notify_early_leaving_time"
                      :validation="{required: true, integer: true, min_value: 5, max_value: 1440}"
                      :precision="0"
                      :step="1"
                      :min="5"
                    />
                  </div>
                </div>
                <div class="col-lg-12 mt-2">
                  <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('NOTIFICATION_SETTINGS') }}
                        <ui-hint :content="$t('NOTIFICATION_SETTINGS_HINT')"/>
                      </span>
                  </el-divider>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 my-2">
                    <div>
                      <ui-checkbox
                        name="settings_notifications_is_notify_start_attendance"
                        v-model="settings.notifications.is_notify_start_attendance"
                        :disabled="!isModuleActive"
                        :label="$t('NOTIFY_START_ATTENDANCE') + ' ' + '(' + $t('min') + ')'"
                      />
                    </div>
                    <div>
                      <ui-number-input
                        :disabled="!isModuleActive || !settings.notifications.is_notify_start_attendance"
                        name="notify_start_attendance"
                        v-model="settings.notifications.notify_start_attendance_value"
                        :validation="{required: true, integer: true, min_value: 0, max_value: 10}"
                        :precision="0"
                        :step="1"
                        :min="0"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 my-2">
                    <div>
                      <ui-checkbox
                        name="settings_notifications_is_notify_stop_attendance"
                        v-model="settings.notifications.is_notify_stop_attendance"
                        :disabled="!isModuleActive"
                        :label="$t('NOTIFY_STOP_ATTENDANCE') + ' ' + '(' + $t('min') + ')'"
                      />
                    </div>
                    <div>
                      <ui-number-input
                        :disabled="!isModuleActive || !settings.notifications.is_notify_stop_attendance"
                        name="notify_stop_attendance"
                        v-model="settings.notifications.notify_stop_attendance_value"
                        :validation="{required: true, integer: true, min_value: 0, max_value: 10}"
                        :precision="0"
                        :step="1"
                        :min="0"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('BREAK_ABSENCE_SETTINGS') }}
                      </span>
                    </el-divider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div>
                      <ui-checkbox
                        name="settings_break_absence_auto_mark_breaks_as_absent"
                        v-model="settings.break_absence.auto_mark_breaks_as_absent"
                        :disabled="!isModuleActive"
                        :label="$t('AUTO_MARK_BREAKS_AS_ABSENT')"
                        :hint="$t('AUTO_MARK_BREAKS_AS_ABSENT_HINT')"
                      />
                    </div>
                    <!--                    <div>-->
                    <!--                      <ui-checkbox-->
                    <!--                        v-model="settings.break_absence.ignore_missed_breaks_in_reports"-->
                    <!--                        :disabled="!isModuleActive"-->
                    <!--                        :label="$t('IGNORE_MISSED_BREAKS_IN_REPORTS')"-->
                    <!--                      />-->
                    <!--                    </div>-->
                    <div>
                      <ui-checkbox
                        name="settings_break_absence_notify_about_break_absent"
                        v-model="settings.break_absence.notify_about_break_absent"
                        :disabled="!isModuleActive"
                        :label="$t('NOTIFY_ABOUT_BREAK_ABSENT')"
                        :hint="$t('NOTIFY_ABOUT_BREAK_ABSENT_HINT')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('UNSCHEDULED_SHIFT_SETTINGS') }}
                        <ui-hint :content="$t('UNSCHEDULED_SHIFT_SETTINGS_HINT')"/>
                      </span>
                    </el-divider>
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings_unscheduled_shift"
                      v-model="settings.unscheduled_shift"
                      :disabled="!isModuleActive"
                      :label="$t('unscheduled_shift') + ' ' + '(' + $t('min') + ')'"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive || !settings.unscheduled_shift"
                      name="unscheduled_shift_time"
                      :label="$t('UNSHEDULED_SHIFT_LENGTH_MINUTES')"
                      v-model="settings.unscheduled_shift_time"
                      :validation="{required: true, integer: true, min_value: 5, max_value: 1440}"
                      :precision="0"
                      :step="1"
                      :min="5"
                    />
                  </div>
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('AUTO_PENALTY_SETTINGS') }}
                      </span>
                    </el-divider>
                  </div>
                  <div @click="handleActivation('bonus')"
                       :data-toggle="isModuleActive && !isBonusModuleActive ? 'modal' : null"
                       :data-target="isModuleActive && !isBonusModuleActive ? '#AttendanceSettingsModal' : null">
                    <div class="mb-1 col-lg-12">
                      <ui-checkbox
                        name="settings_auto_penalty.is_enable"
                        v-model="settings.auto_penalty.is_enable"
                        :disabled="!isModuleActive || !isBonusModuleActive"
                        :label="$t('IS_AUTO_PENALTY_ENABLE')"
                      />
                    </div>
                    <div class="mx-2">
                      <div class="mb-2 col-lg-12">
                        <div>
                          <ui-radio
                            name="settings_auto_penalty_is_fixed_true"
                            :label="$t('FIXED_PENALTY')"
                            v-model="settings.auto_penalty.is_fixed"
                            :disabled="!isModuleActive || !isBonusModuleActive || !settings.auto_penalty.is_enable"
                            :set-value="true"
                          />
                        </div>
                        <div>
                          <ui-radio
                            name="settings_auto_penalty_is_fixed_false"
                            :label="$t('CALCULATED_PENALTY')"
                            v-model="settings.auto_penalty.is_fixed"
                            :disabled="!isModuleActive || !isBonusModuleActive || !settings.auto_penalty.is_enable"
                            :set-value="false"
                          />
                        </div>
                      </div>
                      <div class="mb-2 col-lg-12">
                        <ui-number-input
                          :disabled="!isModuleActive || !isBonusModuleActive || !settings.auto_penalty.is_enable"
                          name="penalty_amount"
                          :label="$t('PENALTY_AMOUNT')"
                          v-model="settings.auto_penalty.amount"
                          :validation="{required: true, decimal: 2, min_value: 0}"
                          :precision="2"
                          :step="0.1"
                          :min="0"
                        />
                      </div>
                      <div class="mb-1 col-lg-12">
                        <ui-number-input
                          :disabled="!isModuleActive || !isBonusModuleActive || !settings.auto_penalty.is_enable"
                          name="allowable_lateness_minutes"
                          :label="$t('ALLOWABLE_LATENESS_MINUTES')"
                          :hint="$t('ALLOWABLE_LATENESS_MINUTES_HINT')"
                          v-model="settings.auto_penalty.allowable_lateness_minutes"
                          :validation="{required: true, integer: true, min_value: 0}"
                          :precision="0"
                          :step="1"
                          :min="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsAttendance',
  components: { ModalComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      attendanceModuleData: null,
      dummyInfo: {},
      dummy: false,
      companyModules: [],
      module: null,
      isBreakModuleActive: false,
      breakModuleData: null,
      isBonusModuleActive: false,
      bonusModuleData: null,
      settings: {
        interfaces: {
          web: {
            enabled: true,
            check_ip: false,
            skip_check_breaks: false
          },
          mobile: {
            enabled: false,
            check_ip: false,
            check_gps: false,
            skip_check_breaks: false
          },
          validations: {
            change_event_time: false,
            dont_change_shift_end_time: false,
            update_shift_lateness: false,
            update_shift_early: false,
            allows_reopen_shift: false,
            shift_time: {
              enabled: true,
              check_time_before: true,
              time_before: 15,
              time_after: 15
            },
            break_time: {
              enabled: true,
              check_time_before: true,
              time_before: 15,
              time_after: 15
            }
          }
        },
        notifications: {
          is_notify_start_attendance: false,
          is_notify_stop_attendance: false,
          notify_start_attendance_value: 5,
          notify_stop_attendance_value: 5
        },
        unscheduled_shift: false,
        unscheduled_shift_time: 5,
        notify_late: true,
        notify_late_time: 15,
        notify_early_leaving: false,
        notify_early_leaving_time: 15,
        auto_penalty: {
          is_enable: false,
          is_fixed: true,
          amount: 0,
          allowable_lateness_minutes: 0
        },
        break_absence: {
          auto_mark_breaks_as_absent: false,
          notify_about_break_absent: false,
          ignore_missed_breaks_in_reports: true
        },
        is_geolocation_enable: false,
        geolocation_distance_filter: 10
      },
      moduleInfo: null
    }
  },
  props: {
    breaksIsActive: {
      type: Boolean
    }
  },
  created () {
    this.init()
  },
  watch: {
    breaksIsActive (val) {
      this.isBreakModuleActive = val
    }
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'attendance')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 24) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.settings = this.module.settings
            if (this.settings.notify_late === null || this.settings.notify_late === undefined) {
              this.settings.notify_late = true
            }
            if (!this.settings.notify_late_time) {
              this.settings.notify_late_time = 15
            }
            if (this.settings.notify_early_leaving === null || this.settings.notify_early_leaving === undefined) {
              this.settings.notify_early_leaving = false
            }
            if (!this.settings.notify_early_leaving_time) {
              this.settings.notify_early_leaving_time = 15
            }
            if (!this.settings.unscheduled_shift) {
              this.settings.unscheduled_shift = false
            }
            if (!this.settings.unscheduled_shift_time) {
              this.settings.unscheduled_shift_time = 15
            }
            if (!this.settings.auto_penalty) {
              this.settings.auto_penalty = {
                is_enable: false,
                is_fixed: true,
                amount: 0,
                allowable_lateness_minutes: 0
              }
            }
            if (!this.settings.is_geolocation_enable) {
              this.settings.is_geolocation_enable = false
            }
            if (!this.settings.geolocation_distance_filter) {
              this.settings.geolocation_distance_filter = 10
            }
            if (!this.settings.break_absence) {
              this.settings.break_absence = {
                auto_mark_breaks_as_absent: false,
                notify_about_break_absent: false,
                ignore_missed_breaks_in_reports: true
              }
            }
            if (!this.settings.notifications) {
              this.settings.notifications = {
                is_notify_start_attendance: false,
                is_notify_stop_attendance: false,
                notify_start_attendance_value: 5,
                notify_stop_attendance_value: 5
              }
            }
          }
        }
      })
      // модуль посещаемости активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 25]).then(settings => {
        this.isModuleActive = true
        // модуль перерывов активен?
        this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then(() => {
          this.isBreakModuleActive = true
        }).catch(err => {
          this.isBreakModuleActive = false
          this.breakModuleData = err.response.data
        })
        this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 23]).then(() => {
          this.isBonusModuleActive = true
        }).catch(err => {
          this.isBonusModuleActive = false
          this.bonusModuleData = err.response.data
        })
      }).catch(err => {
        if (err.response.data.module) {
          this.attendanceModuleData = err.response.data
          this.isModuleActive = false
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation (module) {
      if (module === 'attendance' && !this.isModuleActive) {
        this.dummyInfo = this.setupDummyData(this.attendanceModuleData)
      }
      if (module === 'breaks' && !this.isBreakModuleActive) {
        this.dummyInfo = this.setupDummyData(this.breakModuleData)
      }
      if (module === 'bonus' && !this.isBonusModuleActive) {
        this.dummyInfo = this.setupDummyData(this.bonusModuleData)
      }
      this.dummy = true
    },
    async submitForm () {
      try {
        if (this.isModuleActive) {
          const result = await this.$refs.observer.validate()
          if (result.valid) {
            this.settings.auto_penalty.amount = +this.settings.auto_penalty.amount
            this.settings.auto_penalty.allowable_lateness_minutes = +this.settings.auto_penalty.allowable_lateness_minutes
            this.settings.notifications.notify_stop_attendance_value = +this.settings.notifications.notify_stop_attendance_value
            this.settings.notifications.notify_start_attendance_value = +this.settings.notifications.notify_start_attendance_value

            await this.$store.dispatch('updateModuleSettings', [
              this.$store.getters.companyId,
              this.module.id,
              {
                settings: this.settings
              }
            ])

            this.$emit('success')
          }
        }
      } catch (error) {
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>

</style>
