<template>
  <Form ref="observer" v-slot="{ meta }" as="">
    <div class="row mt-1">
      <div class="col-lg-3 d-flex justify-content-center">
        <ui-date-picker
          name="date"
          v-model="payload.date"
          type="date"
          :clearable="false"
          :placeholder="$t('SELECT_MONTH')"
          @change="(val) => payload.date = val"
        />
      </div>
      <div class="col-lg-3 d-flex justify-content-center">
        <ui-number-input
          name="amount"
          v-model="payload.amount"
          :precision="0"
          :step="1"
          :validation="{required: true, integer: true, is_not: 0}"
          @change="(val) => payload.amount = val"
        />
      </div>
      <div class="col-lg-3 d-flex justify-content-center">
        <ui-textarea-input
          name="comment"
          v-model="payload.comment"
          :rows="2"
          @update:model-value="(val) => payload.comment = val"
        />
      </div>
      <div class="col-lg-3 d-flex justify-content-center">
        <div class="row">
          <div class="col">
            <el-button
              size="default"
              type="success"
              circle
              :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
              @click="save"
            >
              <i class="icon-check" />
            </el-button>
          </div>
          <div class="col">
            <el-button
              size="default"
              type="danger"
              icon="Delete"
              circle
              @click="deleteTransaction"
            />
          </div>
          <div class="col">
            <el-button
              size="default"
              type="warning"
              circle
              @click="cancel"
            >
              <i class="icon-x" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import moment from 'moment/moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'TransactionsEdit',
  mixins: [momentMixin],
  data () {
    return {
      payload: {
        id: null,
        date: moment(),
        amount: -1,
        comment: ''
      }
    }
  },
  props: {
    transaction: Object,
    employeeId: Number
  },
  created () {
    this.payload = {
      id: this.transaction.id,
      date: this.transaction.date,
      amount: this.transaction.amount,
      comment: this.transaction.comment
    }
  },
  methods: {
    convertToDate (val) {
      if (val) {
        return moment(val).locale(this.$store.getters.locale).format(this.localeDateFormat)
      }
      return null
    },
    async save () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        const payload = { ...this.payload }
        payload.date = moment(payload.date).format(this.backendDateFormat)
        this.$store.dispatch('updateVacationsTransactions', [
          this.$store.getters.companyId,
          this.employeeId,
          payload
        ]).then(() => {
          this.$eventBus.emit('reloadTransactions')
        })
          .catch(err => {
            this.toastError(this.$i18n?.t(err.response.data.message))
          })
      }
    },
    cancel () {
      this.$emit('cancelEdit')
    },
    deleteTransaction () {
      this.$confirm(this.$t('CONFIRM_DELETE_VACATION_TRANSACTION'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteVacationsTransactions', [
          this.$store.getters.companyId,
          this.employeeId,
          this.payload.id
        ]).then(() => {
          this.$eventBus.emit('reloadTransactions')
        })
          .catch(err => {
            this.toastError(this.$i18n?.t(err.response.data.message))
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
