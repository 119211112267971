<template>
  <div class="row mt-1" v-if="isEmployeeHasPermission('create-shift-admin') ||
   rolesByProjects[currentProject] === 'manager'">
    <div class="col-lg-12">
      <button type="button" class="btn btn-block text-center" :class="notify ? 'purple': 'btn-grey'" :disabled="!notify" @click="sendNotify">
        <span class="font-lg">{{ $t('Publish & notify employees') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import companyScheduleMixin from '@/mixins/mixinCompanySchedule'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Publish',
  mixins: [
    ToastMixin,
    companyScheduleMixin
  ],

  data: function () {
    return {
      notify: false
    }
  },

  props: {
    currentProject: Number
  },

  created () {
    this.checkNotify()
  },

  mounted () {
    this.$eventBus.on('sendNotify', this.sendNotify)
    this.$eventBus.on('checkNotify', this.checkNotify)
  },

  beforeUnmount () {
    this.$eventBus.off('sendNotify')
    this.$eventBus.off('checkNotify')
  },

  methods: {
    sendNotify () {
      this.$store.dispatch('sendNotify', this.currentProject).then((response) => {
        if ('status' in response && response.status === 'ok') {
          this.toastSuccess(this.$i18n?.t('Notifications were sent to all users whose schedules have been changed!'))
          this.notify = false
          localStorage.setItem('scheduleNotify', this.notify)
        }
      }).catch(() => {
        // TODO
      })
    }
  },

  watch: {
    currentProject: function () {
      // проверяем неотправленные сообщения при смене проекта
      if (this.isEmployeeHasPermission('get-schedule')) {
        this.checkNotify()
      }
    }
  }
}
</script>

<style scoped>
  .btn.btn-block {
    min-height: 65px;
    border-radius: 15px;
  }
  .btn.purple {
    color: #fff;
    background-color: #8E44AD;
    border-color: #8E44AD;
  }
  .btn.purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
  }
  .btn.btn-grey:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #5a6268;
    cursor: not-allowed;
  }
</style>
