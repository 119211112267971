<template>
  <Form ref="observe" as="div">
    <div class="row my-1">
      <div class="col">
        <ui-number-input
            :label="$t('Lead time (in hrs)')"
            v-model="task.lead_time"
            id="lead_time"
            name="lead_time"
            :precision="2"
            :step="0.1"
            :min="0"
            @change="handleChange"
            :validation="{decimal: true, required: true}"
        />
      </div>
      <div class="col">
        <ui-datetime-picker
            :label="$t('Scheduled time')"
            name="scheduled_time"
            :placeholder="$t('Pick a date time')"
            v-model="task.scheduled_time"
            @change="handleScheduledTimeChange"
            :clearable="true"
            :picker-options="{firstDayOfWeek: +$store.getters.company.salary.week_start + 1}"
        />
      </div>
      <div class="col-auto my-auto">
        <el-button @click="handleDelete" type="danger" icon="Delete" circle />
      </div>
    </div>
    <div class="row my-1">
      <div class="col">
        <ui-tags-select
            name="marks"
            :label="$t('TASK_MARK')"
            v-model="task.marks"
            class="w-100"
            :options="taskMarksList"
            :validation="{required: false}"
            :key-name="'id'"
            :value-name="'id'"
            :label-name="'name'"
            :placeholder="$t('Select')"
            :filterable="true"
            :allow-create="true"
            @change="handleChange"
        >
          <template v-slot:default="slotProps">
            <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
      </div>
    </div>
    <el-container class="d-block" style="max-height: 300px; overflow-y: auto; background-color: #ebe8e8">
      <template v-if="shifts.length > 0">
        <div v-for="item in shifts"
             v-bind:key="item.id"
             @click="() => handleChangeShift(item.id)"
             class="m-1 px-1 font-weight-bold bg-white">
          <ui-radio
            :name="item.id + '__shift_id'"
              :containerClass="'mt-2'"
              v-model="task.shift_id"
              :set-value="item.id"
          >
            <div class="text-wrap d-inline-block" :for="'chck_' + item.id">
              <div>{{ handleDate(item.time_from) }} - {{ handleDate(item.time_to) }}
                {{item.employee.full_name}}
                <el-tooltip v-if="item.calculatedLeadTime > item.duration" class="item" effect="light" :content="$t('WARNING_TASKS_DURATION')" placement="top">
                  <i class="icon-alert-circle text-danger font-weight-bold"></i>
                </el-tooltip>
              </div>
              <div class="mt-1">
                {{ $t('COUNT_TASKS') }} {{ item.tasksCount }}
                <template v-if="isEmployeeHasPermission('create-schedule')">
                  {{ $t('TOTAL_TASKS_TIME_HRS') }} {{ item.calculatedLeadTime / 60 }}
                </template>
              </div>
            </div>
          </ui-radio>
        </div>
      </template>
      <template v-else>
        <div class="text-center my-3">
          {{ $t('SELECTED_SHIFT_REQUIRED') }}
        </div>
      </template>
    </el-container>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'SubtaskItemCreateComponent',
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      shifts: [],
      task: {
        scheduled_time: null,
        lead_time: 0,
        shift_id: null,
        marks: []
      }
    }
  },
  props: { scheduleId: Number, subtaskIndex: Number, tz: String, clientId: Number, initItem: Object, parentShift: Number },
  created () {
    this.task = { ...this.initItem }
    this.handleShifts()
  },
  watch: {
    initItem () {
      this.task = { ...this.initItem }
    },
    'task.shift_id' (value, oldValue) {
      const index = this.shifts.findIndex(item => +item.id === +value)
      const oldIndex = this.shifts.findIndex(item => +item.id === +oldValue)
      // console.log(index, oldIndex, this.shifts)
      if (oldIndex >= 0) {
        this.shifts[oldIndex].calculatedLeadTime -= this.task.lead_time * 60
        this.shifts[oldIndex].tasksCount--
      }
      if (index >= 0) {
        this.shifts[index].calculatedLeadTime += this.task.lead_time * 60
        this.shifts[index].tasksCount++
      }
    },
    'task.lead_time' (value, oldValue) {
      if (this.task.shift_id) {
        const index = this.shifts.findIndex(item => +item.id === +this.task.shift_id).toString()
        this.shifts[index].calculatedLeadTime -= oldValue * 60
        this.shifts[index].calculatedLeadTime += value * 60
      }
      // костыль для обновления счетчиков на шифтах (свойства объекта не реактивные?!)
      // let tmp = this.task.shift_id
      // this.task.shift_id = null
      // this.task.shift_id = tmp
    }
  },
  computed: {
    taskMarksList () {
      const list = []
      if (this.$store.getters.taskMarks.length > 0) {
        this.$store.getters.taskMarks.forEach(item => {
          list.push({
            name: item.name,
            id: item.id,
            color: item.color
          })
        })
      }
      return list
    }
  },
  methods: {
    handleShifts () {
      this.shifts = []
      let date = null
      if (this.task.scheduled_time) {
        date = this.fromZoneToZone(this.task.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
      }
      this.$store.dispatch('getShiftsToAttach', [
        this.companyId,
        this.scheduleId,
        date,
        this.clientId,
        this.task.lead_time
      ]).then(response => {
        const shifts = {}
        response.forEach(shift => {
          shifts[shift.id] = shift
          let leadTime = 0
          shifts[shift.id].tasksCount = 0
          // console.log(1, this.parentShift, shift.id)
          if (this.parentShift === shift.id) {
            leadTime += +this.initItem.lead_time * 60
            shifts[shift.id].tasksCount++
          }
          shifts[shift.id].tasks.forEach(task => {
            if (+task.id === +this.taskId) {
              this.selectedShift = shift.id
            } else {
              leadTime += +task.lead_time * 60
              shifts[shift.id].tasksCount++
            }
          })
          shifts[shift.id].calculatedLeadTime = leadTime
        })

        this.shifts = Object.values(shifts)
        let shiftsWithClients = this.shifts.filter(item => item.distance >= 0)
        let shiftsWithoutClients = this.shifts.filter(item => item.distance < 0 || item.distance === undefined)
        shiftsWithClients = shiftsWithClients.sort((a, b) => {
          if (a.distance > b.distance) {
            return 1
          }
          if (a.distance < b.distance) {
            return -1
          }
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }

          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })

        shiftsWithoutClients = shiftsWithoutClients.sort((a, b) => {
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }
          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })
        this.shifts = shiftsWithClients.concat(shiftsWithoutClients)
        if (this.shifts.length > 0) {
          this.task.shift_id = this.shifts[0].id
        } else {
          this.task.shift_id = null
        }
        this.handleChange()
      })
    },
    handleScheduledTimeChange () {
      this.handleShifts()
    },
    handleChangeShift (id) {
      this.task.shift_id = id
      this.handleChange()
    },
    handleChange () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          this.$emit('childrenUpdated', this.task, this.subtaskIndex)
        }
      })
    },
    handleDelete () {
      this.$emit('childrenDeleted', this.subtaskIndex)
    },
    handleDate (time) {
      return this.fromZoneToZone(time, 'UTC', this.tz).format(this.localeDateTimeFormat)
    }
  }
}
</script>

<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
