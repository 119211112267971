export default {
  data () {
    return {
      colorsChoicesMarks: [
        '#bbbbbb',
        '#9dff98',
        '#ffc259',
        '#81e6ff',
        '#ffa6b7',
        '#c3ec20',
        '#9e16e5',
        '#fffc00',
        '#14314a',
        '#a3b6ff',
        '#7affd5',
        '#ff2800',
        '#210000',
        '#479d3b',
        '#0004b3',
        '#FF7000',
        '#FFC400',
        '#F6FF00',
        '#A9FF00',
        '#639600',
        '#30FF00',
        '#1FA700',
        '#77FF58',
        '#00FF41',
        '#00B22E',
        '#46FF76',
        '#00FFA1',
        '#00BE78',
        '#49FFBC',
        '#00FEFF',
        '#32CDC8',
        '#24938F',
        '#64D9D5',
        '#39C64F',
        '#288937',
        '#71D681',
        '#7AC13E',
        '#54852B',
        '#9BD16E',
        '#C5C23A',
        '#858327',
        '#D2D066',
        '#CF6530',
        '#924722',
        '#D67C4F',
        '#A46D5B',
        '#724C3F',
        '#B98E80',
        '#8E9E61',
        '#606B42',
        '#A2AF7D',
        '#609F63',
        '#477649',
        '#82B384',
        '#5BA49F',
        '#437975',
        '#7DB8B4'
      ]
    }
  }
}
