import moment from 'moment'

export default {
  computed: {
    selectedTemplates () {
      return this.$store.getters.selectedTemplates
    },
    loadShifts () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.shifts
    },
    loadTimeOff () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.timeOff
    },
    loadUnavailability () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.unavailability
    },
    loadAvailability () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.availability
    },
    loadBreaks () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.breaks
    },
    loadHolidays () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.holidays
    },
    loadDeletedUsers () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.deletedUsers
    },
    loadNotes () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.notes
    },
    loadActivity () {
      return this.$store.getters.displayEventsSettings && this.$store.getters.displayEventsSettings.activity
    },
    isFiltersLoaded () {
      return !(this.loadShifts === null || this.loadTimeOff === null || this.loadActivity === null || this.loadUnavailability === null || this.loadBreaks === null || this.loadHolidays === null || this.loadDeletedUsers === null)
    }
  },
  watch: {
    /** Отслеживание выбранных чекбоксов в боковом меню */
    selectedTemplates () {
      if (!this.userSwitcher.showAllUsers) this.userSwitcher.showAllUsers = true
      if (this.initFC) {
        this.doNotLoadShifts = !this.$store.getters.projectIsChanging
        this.calendarApi.refetchEvents()
      }
    },
    loadActivity (val) {
      if (val && this.isFiltersLoaded) {
        let dateStart, dateEnd
        dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
        const activityQuery = `?date_from=${dateStart}&date_to=${dateEnd}`
        this.$store.dispatch('getShiftActivities', [this.companyId, activityQuery]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateShiftActivitiesList', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    loadShifts (val) {
      if (val && this.isFiltersLoaded) {
        let dateStart, dateEnd
        dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
        let queryShifts = 'start=' + dateStart + '&end=' + dateEnd + '&include_free_board=True'
        if (!(this.isEmployeeHasPermission('get-shifts-admin') ||
          this.rolesByProjects[this.currentProject.id] === 'manager')) {
          queryShifts += '&employee_id=' + this.$store.getters.profileId
        }
        this.$store.dispatch('getShiftsByProjectId', [this.currentProject.id, queryShifts]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateShiftsList', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    loadTimeOff (val) {
      if (val && this.isFiltersLoaded) {
        const dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
        const queryRequests = '?start=' + dateStart + '&end=' + dateEnd
        this.$store.dispatch('getRequestsForCalendar', [this.companyId, queryRequests]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateFcRequests', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    loadNotes () {
      const dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
      const queryRequests = '?start=' + dateStart + '&end=' + dateEnd
      this.$store.dispatch('getNotesByCompany', [this.companyId, queryRequests])
    },
    loadUnavailability (val) {
      if (val && this.isFiltersLoaded) {
        const queryUnavailability = '?start=' + moment(this.fcEventsSelectedDates.startStr).format(this.backendDateTimeFormat) + '&end=' + moment(this.fcEventsSelectedDates.endStr).add(-1, 'minutes').format(this.backendDateTimeFormat)
        this.$store.dispatch('getCompanyAvailability', queryUnavailability).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.doNotLoadShifts = true
        this.shouldReload = true
      }
    },
    loadAvailability (val) {
      if (val && this.isFiltersLoaded) {
        const queryUnavailability = '?start=' + moment(this.fcEventsSelectedDates.startStr).format(this.backendDateTimeFormat) + '&end=' + moment(this.fcEventsSelectedDates.endStr).add(-1, 'minutes').format(this.backendDateTimeFormat)
        this.$store.dispatch('getCompanyAvailability', queryUnavailability).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.doNotLoadShifts = true
        this.shouldReload = true
      }
    },
    loadBreaks (val) {
      if (val && this.isFiltersLoaded) {
        const dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const queryBreaks = '?time_from=' + dateStart + '&time_to=' + this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        this.$store.dispatch('getBreaks', [this.currentProject.id, queryBreaks]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateBreaksList', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    loadDeletedUsers (val) {
      if (val && this.isFiltersLoaded) {
        const dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
        const queryDeletedUses = '?start=' + dateStart + '&end=' + dateEnd
        this.$store.dispatch('getDeletedUsers', [this.companyId, this.currentProject.id, queryDeletedUses]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateDeletedUsers', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    loadHolidays (val) {
      if (val && this.isFiltersLoaded) {
        const dateStart = this.fromZoneToZone(this.fcEventsSelectedDates.startStr, this.actualTZ, 'UTC').format(this.backendDateTimeFormat)
        const dateEnd = this.fromZoneToZone(this.fcEventsSelectedDates.endStr, this.actualTZ, 'UTC').add(-1, 'minutes').format(this.backendDateTimeFormat)
        const queryHolidays = '?start=' + dateStart + '&end=' + dateEnd
        this.$store.dispatch('getHolidays', [this.companyId, queryHolidays]).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      } else {
        this.$store.dispatch('updateHolidaysList', []).then(() => {
          this.doNotLoadShifts = true
          this.shouldReload = true
        })
      }
    },
    shouldReload (value) {
      if (value === true) {
        this.calendarApi && this.calendarApi.refetchEvents()
      }
    }
  },
  methods: {
    /**
     * templates filter (sidebar checkboxes)
     * @param events
     * @returns {*}
     */
    applyEventsTemplateFilter (events) { // фильтр по темплейтам
      const templatesIds = {}
      this.selectedTemplates.forEach(template => {
        if (template) {
          const id = template.split('__sp__')[1]
          templatesIds[id] = true
        }
      })
      const filteredBreaks = []
      events = events.filter(event => {
        if (event.isBreak) { // брейки
          // т.к. брейк не имеет инфы о темлпейте шифта, а нам нужно пересчитывать статистику по брейкам,
          // если снята галочка с темплейта на боковом фильтре, то занимаемся онанизмом
          // находим привязаный к брейку шифт, и если он не входит в список скрытых шифтов, пихаем брейк в отфильтрованые брейки,
          // и по отфильтрованым брейкам пересчитываем статистику
          if (this.shiftsById[event.shiftId] && templatesIds[this.shiftsById[event.shiftId].template_id.toString()]) {
            filteredBreaks.push(this.breaksById[event.id])
            return true
          }
          return false
        }
        if (!event.template) { // кастомные шифты
          return true
        }
        // обычные шифты
        return templatesIds[event.template.toString()]
      })
      this.filteredBreaks = filteredBreaks
      return events
    }
  }
}
