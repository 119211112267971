<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/projects`">
              <span class="breadcrumb-item">{{ $t('Projects')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t(" Project Double Account Settings") }}</span>
          </li>
        </ol>
      </nav>
    </div>
    <div class="errors p-2" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
    <div class="card card-body">
      <div class="row">
        <div class="col">
          <ui-text-input
            name="search"
            v-model="search"
            :placeholder="$t('Search')"
            :is-search="true"
          />
        </div>
        <div class="col">{{ $t('Select double account') }}</div>
        <div class="col my-auto">
          <Form ref="newAccount">
            <div class='row'>
              <div class='col mb-2'>
                <ui-text-input
                  :name="username"
                  v-model="username"
                  :validation="{required: true, max: 40}"
                  :placeholder="$t('Account name')"
                />
              </div>
              <div class='col-3 mb-2'>
                <button type="button" class="btn btn-primary rounded-pill shadow-sm" @click="createDoubleAccounts">{{ $t('Add') }}</button>
              </div>
            </div>
          </Form>
          </div>
        <div class="w-100"></div>
        <div class="col">
          <div class="row" v-for="(userAcc, index) in filteredUsersList" :key="index">
            <div class="col-6 pt-2 pb-2 pr-2">
              <div class="row">
                <div class='col'>
                  {{ userAcc.username }}
                </div>
                <div class="col-1">
                  <i
                    v-bind:class="[{'text-warning': checkFavoriteUser(userAcc.id) === true}, '']"
                    @click="checkFavoriteUser(userAcc.id) ? removeUser(userAcc.id) : addUser(userAcc.id)"
                    class="icon-unlock btn-icon">
                  </i>
                </div>
                <div class="col-1">
                  <i
                    :disabled="!checkFavoriteUser(userAcc.id)" @click="enabledDoubleAccount(userAcc.id)"
                    class="icon-edit btn-icon">
                  </i>
                </div>
              </div>
            </div>
            <div class='col-6 my-auto'>
              <div class="row">
                <div class="col ml-1">
                  <div v-if="checkFavoriteUser(userAcc.id) && (doubleAccountsUsers[userAcc.id] || checkEnabledDoubleAccount(userAcc.id))">
                    <ui-select
                      :disabled="!checkFavoriteUser(userAcc.id) || (checkFavoriteUser(userAcc.id) && (doubleAccountsUsers[userAcc.id] !== null && doubleAccountsUsers[userAcc.id] !== '' && doubleAccountsUsers[userAcc.id])) ? true : false"
                      v-model="doubleAccountsUsers[userAcc.id]"
                      :clearable="false"
                      :placeholder="$t('Select')"
                      :options="doubleAccounts"
                      :key-name="'key'"
                      :label-name="'username'"
                      :value-name="'id'"
                      @change="changeDoubleAcc(userAcc.id)"
                    />
                  </div>
                </div>
                <div class="col-1 my-auto">
                  <div class="row align-items-center">
                    <i
                      class="icon-trash btn-icon"
                      v-show="checkFavoriteUser(userAcc.id) && doubleAccountsUsers[userAcc.id]"
                      @click=deleteDoubleAccUser(userAcc.id)>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-4">
          <div class="pt-2 pb-2">
            <ui-text-input
              name="search"
              v-model="searchDoubleAcc"
              :placeholder="$t('Search')"
              :is-search="true"
            />
          </div>
          <div
            class="row"
            v-bind:class="[{'bg-warning': checkDoubleAcc(doubleAccount.id)}, '']"
            v-for="(doubleAccount, index) in filtereddoubleAccountsList"
            v-show="doubleAccount ? true : false"
            :key="index">
            <div class="col py-2 pr-2">
              {{ doubleAccount.username }}
            </div>
            <div class="col-1 my-auto">
                <i
                @click="changeDoubleAccName(doubleAccount.id, doubleAccount.username)"
                class="icon-edit btn-icon"
                ></i>
            </div>
            <div class="col-1 my-auto">
              <i
              class="icon-trash btn-icon"
              @click="deleteDoubleAcc(doubleAccount.id, doubleAccount.username)"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import errorMixin from '../../mixins/mixinApiErrors'

export default {
  name: 'DoubleAccounts',
  mixins: [errorMixin],
  data () {
    return {
      usersChoices: [],
      favoriteUsers: [],
      doubleAccounts: [],
      doubleAccountsUsers: {},
      enabledDoubleAcc: [],
      username: '',
      search: '',
      searchDoubleAcc: ''
    }
  },
  computed: {
    filteredUsersList () {
      return this.usersChoices.filter(user => {
        return user.username.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    filtereddoubleAccountsList () {
      return this.doubleAccounts.filter(user => {
        if (user.username) {
          return user.username.toLowerCase().includes(this.searchDoubleAcc.toLowerCase())
        }

        return false
      })
    }
  },
  mounted () {
    this.$store.dispatch('getProjectDoubleAccounts', this.$route.params.projectId).then((doubleAccounts) => {
      this.doubleAccounts = doubleAccounts
      this.doubleAccounts.sort((a, b) => {
        if (a.username.trim().toLowerCase() < b.username.trim().toLowerCase()) return -1
        if (a.username.trim().toLowerCase() > b.username.trim().toLowerCase()) return 1
        return 0
      })
      const doubleAccountsUsers = {}
      const enabledDoubleAcc = []
      Object.keys(doubleAccounts).forEach(function (id) {
        if (doubleAccounts[id].user_id) {
          doubleAccountsUsers[doubleAccounts[id].user_id] = doubleAccounts[id].id
          enabledDoubleAcc.push(doubleAccounts[id].user_id)
        }
      })
      this.enabledDoubleAcc = enabledDoubleAcc
      this.doubleAccountsUsers = doubleAccountsUsers
    })
    this.$store.dispatch('getProjectUsers', this.$route.params.projectId).then((projectUsers) => {
      this.usersChoices = projectUsers.users
      this.usersChoices.sort((a, b) => {
        if (a.username.trim().toLowerCase() < b.username.trim().toLowerCase()) return -1
        if (a.username.trim().toLowerCase() > b.username.trim().toLowerCase()) return 1
        return 0
      })
      for (const key in projectUsers.favorite_users) {
        this.favoriteUsers.push(projectUsers.favorite_users[key].id)
      }
    })
  },
  methods: {
    remove (array, element) {
      const index = array.indexOf(element)
      array.splice(index, 1)
    },
    removeFromdoubleAccountsUsers (accID) {
      for (const key in this.doubleAccountsUsers) {
        if (this.doubleAccountsUsers[key] === accID) {
          this.doubleAccountsUsers[key] = ''
        }
      }
    },
    checkFavoriteUser (userID) {
      return this.favoriteUsers.indexOf(userID) >= 0
    },
    changeDoubleAccName (accID, accName) {
      this.$prompt(this.$i18n?.t('New Double Account name'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /.{3,}/,
        inputValue: accName,
        inputErrorMessage: 'Invalid value'
      }).then(({ value }) => {
        this.$store.dispatch('patchDoubleAccount', [this.$route.params.projectId, accID, { name: value }]).then((doubleAccUsers) => {
          const doubleAccounts = this.doubleAccounts
          this.toastSuccess(this.$i18n?.t('Account name was successfully changed'))

          for (const key in doubleAccounts) {
            if (doubleAccounts[key].id === accID) {
              const doubleAcc = doubleAccounts[key]
              doubleAccounts[key].username = value
              this.doubleAccounts[doubleAcc] = doubleAccounts[key]
              return
            }
          }
        })
      }).catch(() => {})
    },
    enabledDoubleAccount (userID) {
      if (this.favoriteUsers.indexOf(userID) >= 0) {
        this.enabledDoubleAcc.push(userID)
      }
    },
    checkEnabledDoubleAccount (userID) {
      return this.enabledDoubleAcc.indexOf(userID) >= 0
    },
    deleteDoubleAcc (accID, accName) {
      this.$confirm(this.$i18n?.t('Confirm deletion Double Account'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteDoubleAccountsForUsers', [this.$route.params.projectId, accID]).then(() => {
          const doubleAccounts = this.doubleAccounts
          for (const key in doubleAccounts) {
            if (doubleAccounts[key].id === accID) {
              this.removeFromdoubleAccountsUsers(doubleAccounts[key].id)
              this.remove(doubleAccounts, doubleAccounts[key])
              this.doubleAccounts = doubleAccounts
              return
            }
          }
          this.toastSuccess(this.$i18n?.t('Successfully deleted!'))
        }).catch(() => {
          this.toastWarning(this.$i18n?.t('Something went wrong. Try again later or contact the administration.'))
        })
      }).catch(() => {})
    },
    deleteDoubleAccUser (userID) {
      const doubleAccountsUsers = this.doubleAccountsUsers
      this.$confirm(this.$i18n?.t('Confirm removing.'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('patchDoubleAccount', [this.$route.params.projectId, this.doubleAccountsUsers[userID], { employee_id: 0 }]).then((doubleAccUsers) => {
          doubleAccountsUsers[userID] = ''
          this.doubleAccountsUsers = doubleAccountsUsers
          this.toastSuccess(this.$i18n?.t('Double account has been removed.'))
        })
      }).catch(() => {})
    },
    addUser (userID) {
      this.$store.dispatch('setDoubleAccountsFavoriteUsers', [this.$route.params.projectId, { users: [userID] }]).then((favoriteUsers) => {
        this.favoriteUsers.push(userID)
        this.toastSuccess(this.$i18n?.t('Successfully added!'))
      })
    },
    removeUser (userID) {
      this.$confirm(this.$i18n?.t('Confirm removing.'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('setDoubleAccountsFavoriteUsers', [this.$route.params.projectId, { users: [userID] }]).then((favoriteUsers) => {
          this.remove(this.favoriteUsers, userID)
          if (this.doubleAccountsUsers[userID]) {
            this.$store.dispatch('patchDoubleAccount', [this.$route.params.projectId, this.doubleAccountsUsers[userID], { employee_id: userID }]).then((doubleAccUsers) => {
              this.doubleAccountsUsers[userID] = ''
            })
          }
          this.toastSuccess(this.$i18n?.t('Successfully deleted!'))
        })
      }).catch(() => {})
    },
    checkUserExist (selectID) {
      return this.projectUsers[selectID]
    },
    checkoubleAccUserExist (selectID) {
      return this.doubleAccountsUsers[selectID]
    },
    changeDoubleAcc (selectID) {
      this.$store.dispatch('patchDoubleAccount', [this.$route.params.projectId, this.doubleAccountsUsers[selectID], { employee_id: selectID }]).then((doubleAccUsers) => {
        this.toastSuccess(this.$i18n?.t('Successfully added!'))
      })
    },
    checkDoubleAcc (userID) {
      for (const key in this.doubleAccountsUsers) {
        if (userID === this.doubleAccountsUsers[key]) {
          return true
        }
      }
      return false
    },
    createDoubleAccounts () {
      this.$refs.newAccount.validate().then(isValid => {
        if (isValid) {
          // this.errors.clear()
          this.$store.dispatch('setProjectDoubleAccounts', [this.$route.params.projectId, { name: this.username }]).then((doubleAccount) => {
            this.doubleAccounts.push(doubleAccount)
            this.doubleAccounts.sort((a, b) => {
              if (a.username.trim().toLowerCase() < b.username.trim().toLowerCase()) return -1
              if (a.username.trim().toLowerCase() > b.username.trim().toLowerCase()) return 1
              return 0
            })
            this.username = ''
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
