<template>
  <Form ref="observer" as="div">
    <div class="row">
      <!--      title-->
      <div class="col form-group text-left">
        <ui-text-input
            :name="'activity_title'"
            v-model="template.title"
            :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
            :label="$t('ACTIVITY_TEMPLATE_LABEL')"
        />
      </div>
      <div class="col-auto form-group text-left">
        <!--      color-->
        <ui-color-picker
          name="color"
          v-model="template.color"
          :colors="colorsChoices"
          :label="$t('Choose a color:')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 form-group text-left">
        <!--      time_from-->
        <ui-time-picker
            :label="$t('Time from')"
            :name="'time_from'"
            :placeholder="$t('From')"
            :validation="{required: false}"
            :value-format="'HH:mm'"
            v-model="template.time_from"
            :clearable="true"
        />
      </div>
      <div class="col-lg-6 form-group text-left">
        <!--      time_to-->
        <ui-time-picker
            :label="$t('Time to')"
            :name="'time_to'"
            :placeholder="$t('To')"
            :validation="{required: false}"
            :value-format="'HH:mm'"
            v-model="template.time_to"
            :clearable="true"
        />
      </div>
    </div>
    <!--      project_id-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-select
            :label="$t('Project')"
            :name="'project'"
            v-model="template.project_id"
            :clearable="true"
            :placeholder="$t('Select')"
            :options="projects"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
            @change="onProjectChange"
        />
      </div>
    </div>
    <!--      schedule_id-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-select
            :label="$t('Schedule')"
            :name="'schedule'"
            v-model="template.schedule_id"
            :clearable="true"
            :placeholder="$t('Select')"
            :options="schedules"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
        />
      </div>
    </div>
    <!--      location_id-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-select
            :label="$t('Location')"
            :name="'location'"
            v-model="template.location_id"
            :clearable="true"
            :placeholder="$t('Select')"
            :options="locations"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
        />
      </div>
    </div>
    <!--      position_id-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-select
            :label="$t('Position')"
            :name="'position'"
            v-model="template.position_id"
            :clearable="true"
            :placeholder="$t('Select')"
            :options="positions"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
        />
      </div>
    </div>
    <!--      marks-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-multi-select
            name="Skills"
            :label="$t('Skills')"
            v-model="template.skills"
            :clearable="true"
            class="w-100"
            :options="skills"
            keyName="id"
            valueName="id"
            labelName="title"
        />
      </div>
    </div>
    <!--      marks-->
    <div class="row">
      <div class="col form-group text-left">
        <ui-multi-select
            name="marks"
            :label="$t('Marks')"
            v-model="template.marks"
            :clearable="true"
            class="w-100"
            :options="marks"
            keyName="id"
            valueName="id"
            labelName="title"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 p-1">
        <div class="text-center">
          <button
              class="btn btn-primary btn-sm rounded-pill shadow-sm"
              @click="create">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import mixinColors from '@/mixins/mixinColors'
import ToastMixin from '@/mixins/ToastMixin'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  mixins: [errorMixin, momentMixin, mixinColors, ToastMixin],
  name: 'ActivityTemplateForm',
  data () {
    return {
      template: {
        id: null,
        title: null,
        project_id: null,
        schedule_id: null,
        time_from: null,
        time_to: null,
        location_id: null,
        position_id: null,
        skills: [],
        marks: [],
        color: '#4e93e9'
      },
      pickerOptions: {
        start: '00:00',
        step: '00:05',
        end: '24:00'
      }
    }
  },
  props: { activityTemplateData: Object },
  async created () {
    this.template = { ...this.activityTemplateData }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    projects () {
      return this.$store.getters.projects.map(item => {
        return {
          id: +item.id,
          title: item.name
        }
      })
    },
    schedules () {
      const schedules = this.$store.getters.schedules
      if (this.template.project_id) {
        return schedules.filter(schedule => schedule.project_id === this.template.project_id).map(item => {
          return {
            id: +item.id,
            title: item.name
          }
        })
      }
      return schedules.map(item => {
        return {
          id: +item.id,
          title: item.name
        }
      })
    },
    locations () {
      return this.$store.getters.locations.filter(location => location.hide === 0).map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    positions () {
      return this.$store.getters.positions.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    skills () {
      return this.$store.getters.skills.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    marks () {
      const marks = this.$store.getters.companyMarks
      if (this.template.project_id) {
        return marks.filter(mark => mark.project_id === this.template.project_id).map(item => {
          return {
            id: item.id,
            title: item.name
          }
        })
      }
      return marks.map(item => {
        return {
          id: item.id,
          title: item.name
        }
      })
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeTaskModal')
    },
    onProjectChange () {
      this.template.schedule_id = null
      this.template.marks = []
    },
    create () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const payload = { ...this.template }
          payload.schedule_id = payload.schedule_id === '' ? null : payload.schedule_id
          payload.project_id = payload.project_id === '' ? null : payload.project_id
          if (payload.time_from) {
            payload.time_from = moment(payload.time_from, 'HH:mm:ss').format('HH:mm')
          }
          if (payload.time_to) {
            payload.time_to = moment(payload.time_to, 'HH:mm:ss').format('HH:mm')
          }
          if (this.template.id) {
            this.$store.dispatch('patchActivityTemplates', [this.companyId, payload]).then(() => {
              this.toastSuccess(this.$i18n?.t('ACTIVITY_TEMPLATE_UPDATED'))
              this.$emit('success')
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          } else {
            this.$store.dispatch('postActivityTemplates', [this.companyId, payload]).then(() => {
              this.toastSuccess(this.$i18n?.t('ACTIVITY_TEMPLATE_CREATED'))
              this.$emit('success')
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          }
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
