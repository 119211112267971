<template>
  <div class="card flex-fill shadow-none">
    <div class="card-header font-weight-bold">
      {{ $t("Transactions").toUpperCase() }}
    </div>
    <div class="card-body p-1 table-responsive">
      <table v-if="paginationConfig.paginated.length > 0" class="table table-hover">
        <tbody>
        <tr class="w-100" style="height: 67px" v-for="(transaction, key) in paginationConfig.paginated" :key="key">
          <td class="text-center">{{ createdDate(transaction) }}</td>
          <td class="text-center">{{ createdTime(transaction) }}</td>
          <td class="text-left">{{ transaction.amount >= 0 ? transaction.type === 'referral' ? $t("REFERRALS_REPLENISHMENT") : $t("Balance replenishment") : $t("Write off balance") }}</td>
          <td class="text-center">$ {{ parseFloat(transaction.amount).toFixed(2) }}</td>
          <td class="text-center">{{ transaction.comment }}</td>
        </tr>
        </tbody>
      </table>
      <div v-else class="text-center w-100 d-flex font-weight-bold">
        <div class="m-auto">{{ $t('Nothing to show') }}</div>
      </div>
    </div>
    <div v-show="paginationConfig.paginated.length > 0" class="card-footer bg-transparent pb-0">
      <pagination :rows="paginationConfig.rows" :customClasses="'justify-content-center'" :pageSize="pageSize" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../CommonComponents/Pagination/Pagination'
import paginationMixin from '../../../CommonComponents/Pagination/mixinPagination'
import momentMixin from '@/mixins/mixinMoment'
// import moment from 'moment'

export default {
  name: 'Transactions',
  mixins: [paginationMixin, momentMixin],
  components: {
    Pagination: Pagination
  },
  data () {
    return {
      pageSize: 5
    }
  },
  created () {
    this.paginationConfig.rows = [
      // {
      //   'amount': 10,
      //   'created_at': '2019-02-06 13:31:27.853792',
      //   'type': 'transaction',
      //   'company_id': 'c_9c352a'
      // },
      // {
      //   'amount': -5,
      //   'created_at': '2019-02-06 13:32:25.517913',
      //   'type': 'transaction',
      //   'company_id': 'c_9c352a'
      // }
    ]
    this.$store.dispatch('getTransactions').then(response => {
      if (response) {
        this.paginationConfig.rows = response
      }
    })
  },
  methods: {
    createdDate (transaction) {
      return this.fromZoneToZone(transaction.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateFormat)
    },
    createdTime (transaction) {
      return this.fromZoneToZone(transaction.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    }
  }
}
</script>

<style scoped>

</style>
