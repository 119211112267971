<template>
  <modal-component :id="'ClickExportTimeSheetModal'" :modalClass="'modal-xm'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <div class="row">
          <div class="col-lg-12">
            <div class="pb-2">
              <Form ref="observe">
                <ui-date-picker
                  :label="exportType === 'employee_working_absence' ? $t('Export week') : $t('Export date')"
                  name="date"
                  :picker-type="exportType === 'employee_working_absence' ? 'week' : 'month'"
                  :picker-format="exportType === 'employee_working_absence' ? localeDateFormatElementUi : localeMonthYearFormatExtendedUi"
                  v-model="dateValue"
                  :validation="{required: true}"
                  :clearable="true"
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          @click="exportReport"
          class="btn btn-success btn-circle shadow-sm"
        >
          {{ $t('Export') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'AddPromoModal',
  mixins: [errorMixin, momentMixin],
  components: {
    ModalComponent
  },

  data () {
    return {
      dateValue: ''
    }
  },

  props: {
    projects: Array,
    title: String,
    exportType: String,
    planned: Boolean,
    showUsers: String,
    positions: Array,
    marks: Array
  },

  created () {
    if (this.exportType === 'employee_working_absence') {
      this.dateValue = moment().startOf('week').format(this.backendDateTimeFormat)
    } else {
      this.dateValue = moment().startOf('month').format(this.backendDateTimeFormat)
    }
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    firstDay () {
      return +this.$store.getters.company.salary.week_start + 1
    },
    tz () {
      return this.$store.getters.profileTimeZone
    },
    dateFormat () {
      return this.localeMonthYearFormatUi
    }
  },

  watch: {
    dateValue (val) {
      console.log('watch dateValue', val)
    }
  },

  methods: {
    closeModal () {
      document.getElementById('ClickExportTimeSheetModal').click()
      this.$emit('closePromoModal')
    },

    exportReport () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          let payload = '?projects[]=' + this.projects.join('&projects[]=')
          if (this.exportType === 'employee_working_absence') {
            const dateFrom = this.fromZoneToZone(moment(this.dateValue), this.tz, 'UTC')
            const dateTo = this.fromZoneToZone(moment(this.dateValue), this.tz, 'UTC').add(7, 'days')

            payload += '&date_from=' + dateFrom.format(this.backendDateTimeFormat)
            payload += '&date_to=' + dateTo.format(this.backendDateTimeFormat)
          } else {
            payload += '&date=' + moment(this.dateValue, this.backendDateTimeFormat).format(this.backendMonthYearFormat)
          }

          payload += '&export_type=' + this.exportType
          payload += '&planned=' + (this.planned ? 1 : 0)
          payload += '&show_users=' + this.showUsers
          payload += '&positions[]=' + this.positions.join('&positions[]=')
          this.marks.forEach(element => {
            const markArr = element.split(':')
            payload += `&marks[${markArr[0]}][]=${markArr[1]}`
          })
          this.$store.dispatch('getTimeSheetReport', payload).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.$i18n?.t('export_time_sheet')}_${moment(this.toTimeZone(this.dateValue)).format(this.backendMonthYearFormat)}.xlsx`)
            document.body.appendChild(link)
            link.click()
            this.$notify({
              type: 'success',
              message: this.$t('Export completed'),
              position: this.notificationPosition
            })
            this.closeModal()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
