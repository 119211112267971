<template>
  <div>
    <h3 class="mb-2">{{ $t('Select schedule template') }}</h3>
    <p class="mb-5">{{ $t('SCHEDULE_TEMPLATE_STEP_DESCRIPTION') }}</p>
    <div class="row flex-nowrap schedule-template-content-wrapper">
      <el-radio-group
        v-model="selectedScheduleTemplate"
      >
        <el-radio-button
          v-for="template in scheduleTemplates"
          :label="template"
          :key="template"
        >
          <span class="template-text">{{$t(template)}}</span>
        </el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleTemplateStep',
  data () {
    return {
      selectedScheduleTemplate: '2/2',
      scheduleTemplates: ['2/2', '1/1', '2/2/3', 'Empty']
    }
  },
  watch: {
    selectedScheduleTemplate () {
      this.$emit('refreshScheduleTemplate', this.selectedScheduleTemplate)
    }
  }
}
</script>

<style scoped>
  .schedule-template-content-wrapper {
    max-width: 850px;
    width: 100%;
  }
  .el-radio-button :deep(input[value="2/2"]) + span {
    background-image: url("../../assets/images/2_2.png");
  }
  .el-radio-button :deep(input[value="1/1"]) + span {
    background-image: url("../../assets/images/1_1.png");
  }
  .el-radio-button :deep(input[value="2/2/3"]) + span {
    background-image: url("../../assets/images/2_2_3.png");
  }
  .el-radio-button :deep(input[value="Empty"]) + span {
    background-image: url("../../assets/images/empty.png");
  }
  .el-radio-button.is-active :deep(span) {
    outline: 2px solid #3398d7;
    filter: none !important
  }
  .el-radio-button :deep(span) {
    border-radius: 4px !important;
    border: 1px solid #DCDFE6;
    background-size: contain;
    width: 195px;
    height: 105px;
    background-repeat: no-repeat;
    position: relative;
    filter: grayscale(100%);
  }
  .template-text {
    color: black;
    position: absolute;
    top: -35px;
    left: 0;
    outline: none !important;
    border: none !important;
    font-weight: 700;
  }
  .el-radio-group {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
</style>
