<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('REQUEST_CREATE_SHIFT_TITLE') }}</div>
<!--      <div class="ml-md-4" style="line-height: 27px">-->
<!--        &nbsp;<span>{{ $t('Company') }}</span><span class="ml-2 font-weight-bold">{{request.company}}</span>-->
<!--      </div>-->
<!--      <div class="ml-md-4" style="line-height: 27px">-->
<!--        &nbsp;<span>{{ $t('Project') }}</span><span class="ml-2 font-weight-bold">{{request.project}}</span>-->
<!--      </div>-->
    </div>
    <div class="mt-2">
      <div class="d-flex align-items-center">
        {{ $t('User') }}
        <span class="visible-md-inline visible-lg-inline ml-1">
            <avatar
              class="rounded-circle d-inline-block font-weight-normal"
              :width="35"
              :borderRadius="50"
              :placeholder="false"
              :userId="request.messages[0].employee_id"
              :mini="true"
              :cache="true"
              :userName="request.messages[0].employee_name">
            </avatar>
          </span>
        <span class="ml-1 font-weight-bold">{{request.messages[0].employee_name}} </span>
        <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
        <span class="mx-1">{{ $t('KEY_USER_ASKS_TO_CREATE_SHIFT') }}</span>
        <template v-if="checkOvertime[0] && checkOvertime[0].is_overtime">
        <span class="text-warning small mx-2" v-if="checkOvertime[0].week_overtime">
          {{ $t('WEEK_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].week_overtime * 100) / 100 }}
        </span>
          <span class="text-warning small mx-2" v-if="checkOvertime[0].month_overtime">
          {{ $t('MONTH_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].month_overtime * 100) / 100 }}
        </span>
        </template>
      </div>
      <ShiftTitle :request="request" />
      <div class="mt-2 font-weight-bold">{{ $t('Time')}}</div>
      <div class="mt-2">
        <span>{{ $t('Shift begins at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.date_from).format(localeDateTimeFormat) }}</span>
      </div>
      <div class="mt-2">
        <span>{{ $t('Shift ends at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.date_to).format(localeDateTimeFormat) }}</span>
      </div>
    </div>
    <div class="row mt-2 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
import ShiftTitle from '@/components/Requests/ViewComponents/ShiftTitle'
export default {
  name: 'ChangeShift',
  props: ['request', 'checkOvertime', 'employee'],
  components: { ShiftTitle, Avatar },
  mixins: [momentMixin, requestMixin]
}
</script>

<style scoped>

</style>
