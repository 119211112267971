<template>
  <div id="dashboard-statistic" class="container-fluid p-0">
    <first-steps
      class="my-3"
      :schedules="schedules">
    </first-steps>
    <shifts-statistic
      :isAttendanceModuleOn="isAttendanceModuleOn"
    />
    <breaks-statistic
      v-if="isBreakModuleOn"
      :isAttendanceModuleOn="isAttendanceModuleOn"
      class="my-4" />
    <ActivityStatistic
      v-if="isActivityModuleOn"
    />
    <requests-statistic
      class="my-4">
    </requests-statistic>
    <project-statistic
      class="my-4">
    </project-statistic>
    <div class="row p-0 mx-1 my-4">
      <div class="col-lg-8 p-0 mb-3">
        <div class="card h-100 mr-1">
          <div class="card-body d-flex p-2">
            <div class="row mx-auto align-self-center w-100">
              <div class="col-lg-auto mx-auto d-flex">
                <div class="align-self-center" style="width: 100px; height: 100px; border-radius: 50%; display: inline-block; margin: 0 auto; overflow: hidden">
                  <img :src="news.image" class="img-fluid"/>
                </div>
              </div>
              <div class="col-lg d-flex mb-2">
                <div class="align-self-center">
                  <p class="font-weight-bold">{{news.title}}</p>
                  <p>{{news.text}}</p>
                </div>
              </div>
              <div class="position-absolute fixed-bottom text-center btn-bottom">
                <button @click="more(news.url)" class="btn btn-md btn-primary rounded-pill shadow-sm align-self-center">
                  {{ $t('Read more') }} <span :class="{'icon-chevron-right': lang !== 'he', 'icon-chevron-left': lang === 'he'}"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 p-0 mb-3">
        <div class="card h-100 mr-1">
          <div class="card-body d-flex px-0">
            <div v-if="hints.length > 0" class="row align-self-center w-100 mx-0 px-0 mb-2">
              <div class="col-auto my-auto">
                <span v-if="lang !== 'he'" @click="clickLeft" class="icon-chevron-left" style="font-size: 25px; cursor: pointer"></span>
                <span v-if="lang === 'he'" @click="clickLeft" class="icon-chevron-right" style="font-size: 25px; cursor: pointer"></span>
              </div>
              <div class="col text-center">
                {{hints[hintToShow].text}}
              </div>
              <div class="col-auto my-auto">
                <span v-if="lang !== 'he'" @click="clickRight" class="icon-chevron-right" style="font-size: 25px; cursor: pointer"></span>
                <span v-if="lang === 'he'" @click="clickRight" class="icon-chevron-left" style="font-size: 25px; cursor: pointer"></span>
              </div>
            </div>
            <div class="position-absolute fixed-bottom text-center btn-bottom">
              <button @click="more(hints[hintToShow].url)" class="btn btn-md btn-primary rounded-pill shadow-sm align-self-center">
                {{ $t('Read more') }} <span :class="{'icon-chevron-right': lang !== 'he', 'icon-chevron-left': lang === 'he'}"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirstSteps from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/FirstSteps'
import RequestsStatistic from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/RequestsStatistic'
import ProjectStatistic from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/ProjectStatistic'
import BreaksStatistic from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/BreaksStatistic'
import ShiftsStatistic from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/ShiftsStatistic'
import ActivityStatistic from '@/components/Dashboard/DashboardComponents/DashboardStatisticSubcomponents/ActivityStatistic'

export default {
  name: 'DashboardStatistic',
  components: {
    ActivityStatistic,
    ShiftsStatistic,
    BreaksStatistic,
    ProjectStatistic,
    RequestsStatistic,
    FirstSteps
  },
  data () {
    return {
      news: {},
      hints: [],
      hintToShow: 0,
      isBreakModuleOn: false,
      isAttendanceModuleOn: false,
      isActivityModuleOn: false
    }
  },
  props: {
    schedules: Array
  },
  created () {
    if (this.ModulesByCompany) {
      this.isBreakModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 23).length > 0
      this.isAttendanceModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 24).length > 0
      this.isActivityModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 28).length > 0
    }
    this.$store.dispatch('getNews').then(news => {
      this.news = news
      // console.log(news)
    })
    this.$store.dispatch('getHints').then(hints => {
      this.hints = hints
      // console.log(hints)
    })
  },
  computed: {
    lang () {
      return this.$store.getters.locale
    },
    ModulesByCompany () {
      return this.$store.getters.ModulesByCompany
    }
  },
  watch: {
    ModulesByCompany () {
      if (this.ModulesByCompany) {
        this.isBreakModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 23).length > 0
        this.isAttendanceModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 24).length > 0
        this.isActivityModuleOn = this.ModulesByCompany.filter(item => +item.module_id === 28).length > 0
      }
    }
  },
  methods: {
    more (url) {
      window.open(url)
    },
    clickLeft () {
      if (this.hintToShow === 0) {
        this.hintToShow = this.hints.length - 1
      } else {
        this.hintToShow--
      }
    },
    clickRight () {
      if (this.hintToShow === this.hints.length - 1) {
        this.hintToShow = 0
      } else {
        this.hintToShow++
      }
    }
  }
}
</script>

<style scoped>
  .btn-bottom {
    margin-bottom: -20px;
  }
  .fixed-left {
    position: fixed;
    left: 0;
    z-index: 1030;
  }
  .fixed-right {
    position: fixed;
    right: 0;
    z-index: 1030;
  }
</style>
