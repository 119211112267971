<template>
  <Form ref="observe">
    <div class="row my-1">
      <div class="col-2 my-1">
        <ui-select
          :name="index + '__action_time'"
          v-model="itemState.action_time"
          class="w-100"
          :label="$t('RULE_ACTION_TIME')"
          :options="actionTimeChoices"
          :validation="{required: true}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
        />
      </div>
      <div class="col-3 my-1">
        <ui-multi-select
          :name="index + '__action_objects'"
          v-model="itemState.action_objects"
          class="w-100"
          :label="$t('RULE_ACTION_OBJECT')"
          :options="actionObjectsChoices"
          :validation="{required: true}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
        />
      </div>
      <div class="col-3 my-1">
        <ui-select
          :name="index + '__rule'"
          ref="rule"
          v-model="itemState.rule"
          class="w-100"
          :label="$t('RULE_TYPE')"
          :options="ruleChoices"
          :validation="{required: true}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
          @change="changeRule"
        />
      </div>
      <div class="col-3 my-1">
        <ui-multi-select
          :name="index + '__RULE_OBJECT'"
          v-show="!['need_day_off', 'need_work_day'].includes(itemState.rule)"
          v-model="itemState.objects"
          class="w-100"
          :label="$t('RULE_OBJECT')"
          :options="objectsChoices"
          :validation="{required: !['need_day_off', 'need_work_day'].includes(itemState.rule)}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
        />
      </div>
      <div class="col-auto m-1 d-flex text-center">
        <i class="icon-trash btn-icon text-danger my-auto"
           @click="deleteItem()"
           role="button">
        </i>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Item',
  mixins: [errorMixin],
  data () {
    return {
      inputValue: '',
      actionTimeChoices: [
        {
          id: 'before',
          name: this.$t('Before')
        },
        {
          id: 'after',
          name: this.$t('After')
        }
      ],
      actionObjectsChoices: [
        {
          id: 'day_off',
          name: this.$t('Day off')
        }
      ],
      ruleChoices: [
        {
          id: 'forbidden',
          name: this.$t('Forbidden')
        },
        {
          id: 'can_only',
          name: this.$t('Can only')
        },
        {
          id: 'need_day_off',
          name: this.$t('Need day off')
        },
        {
          id: 'need_work_day',
          name: this.$t('Need work day')
        }
      ],
      objectsChoices: [
        {
          id: 'day_off',
          name: this.$t('Day off')
        }
      ],
      itemState: {}
    }
  },
  props: { item: Object, scheduleSettings: Object, index: Number },
  created () {
    this.itemState = this.item
    const templates = []
    // console.log('this.scheduleSettings', this.scheduleSettings)
    if (this.scheduleSettings.preloadedTemplates) {
      const templates = []
      this.scheduleSettings.preloadedTemplates.forEach((element) => {
        const templData = this.scheduleSettings.preloadedTemplatesData[element]
        templates.push({ id: templData.id, name: templData.name })
      })
      this.objectsChoices = this.objectsChoices.concat(templates)
      this.actionObjectsChoices = this.actionObjectsChoices.concat(templates)
    }

    if (this.scheduleSettings.templates) {
      this.scheduleSettings.templates.forEach((element, index) => {
        templates.push({ id: index + this.scheduleSettings.preloadedTemplates.length, name: element.name })
      })
      this.objectsChoices = this.objectsChoices.concat(templates)
      this.actionObjectsChoices = this.actionObjectsChoices.concat(templates)
    }
  },
  mounted () {
    this.$refs.observe.validate().then(result => {
      if (result.valid) {
        this.$emit('validateResult', { validate: true, index: this.index })
      } else {
        this.$emit('validateResult', { validate: false, index: this.index })
      }
    }).finally(() => {
      this.$refs.observe.resetForm()
    })
  },
  watch: {
    item () {
      this.itemState = this.item
    },
    itemState: {
      handler (val) {
        this.$nextTick(() => {
          this.$refs.observe.validate().then(result => {
            if (result.valid) {
              this.$emit('validateResult', { validate: true, index: this.index })
            } else {
              this.$emit('validateResult', { validate: false, index: this.index })
            }
          })
        })
      },
      deep: true
    },
    disabledActions (value) {
      // console.log('disabled value', value)
      // this.disabledActions = value
    }
  },
  methods: {
    deleteItem () {
      if (!this.disabledActions) {
        this.$emit('deleteItem')
      }
    },
    changeRule () {
      this.itemState.objects = []
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
