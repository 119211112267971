<template>
  <modal-component :id="'DoubleAccountsSetupModal'" :modalClass="'modal-lg'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Double account')}}</h5>
    </template>
    <template #body>
      <div>
        <ui-date-picker
          :label="$t('Select the shift date')"
          name="date_from"
          id="dateFrom"
          v-model="dateShift"
          :validation="{required: true}"
          :clearable="false"
          @change="shiftDate"
          :picker-format="localeDateFormatElementUi"
          :value-format="'yyyy-MM-dd'"
        />
        <div v-for="(item, key) in state" v-bind:key="key" class="row my-1">
          <div class="col-4">
            <ui-select
              name="type"
              :label="$t('Select user')"
              v-model="state[key].userId"
              @change="selectedUser(key, state[key].userId)"
              :options="selectUsers"
              :key-name="'id'"
              :label-name="'name'"
              :value-name="'id'"
              :clearable="true"
              :filterable="true"
              :disabled-option-callback="() => checkUsedUsers(user.id)"
            />
          </div>
          <div class="col-4">
            <label class="font-weight-bold">{{ $t('Select time')}}</label>
            <div class="row">
              <ui-time-picker
                :name="'time_from'"
                :placeholder="$t('From')"
                @change="handleTemplate(key)"
                :validation="{required: true}"
                :clearable="false"
                :value-format="localeTimeFormatElementUi"
                v-model="state[key].timeFrom"
              />
              <ui-time-picker
                :name="'time_to'"
                :placeholder="$t('To')"
                @change="handleTemplate(key)"
                :validation="{required: true}"
                :clearable="false"
                :value-format="localeTimeFormatElementUi"
                v-model="state[key].timeTo"
              />
            </div>
          </div>
          <div class="col-4">
            <ui-select
              name="type"
              :label="$t('Select a Double Account')"
              v-model="state[key].doubleAccount"
              :options="selectDoubleAccounts"
              :key-name="'id'"
              :label-name="'username'"
              :value-name="'id'"
              :clearable="true"
              :filterable="true"
              :disabled-option-callback="() => checkUsedDoubleAccounts(account.id)"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button @click="addRow" class="btn btn-outline-primary rounded-pill shadow-sm mx-1">
          {{ $t('Add more')}}
        </button>
        <button @click="save" class="btn btn-success rounded-pill shadow-sm">
          {{ $t('SAVE_WEB')}}
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import momentMixin from '../../../mixins/mixinMoment'
import moment from 'moment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'DoubleAccountsSetupModal',
  mixins: [momentMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      dateShift: moment().format(this.backendDateFormat),
      favoriteUsers: [],
      filteredUsers: {},
      selectUsers: [],
      state: [],
      timeZone: '',
      params: '',
      doubleAccounts: {},
      doubleAccountsByUsers: {},
      filteredDoubleAccounts: {},
      selectDoubleAccounts: []
    }
  },
  created () {
    this.dateShift = this.shiftAddDate.format(this.backendDateFormat)
    this.$store.dispatch('getProjectDoubleAccounts', this.currentProject).then((doubleAccounts) => {
      doubleAccounts.forEach(item => {
        this.doubleAccounts[item.id] = item
        this.doubleAccountsByUsers[item.user_id] = item.id
        this.filteredDoubleAccounts[item.id] = item
        this.selectDoubleAccounts.push(item)
        // console.log(self.doubleAccounts)
      })
    })
    this.$store.dispatch('getProject', this.currentProject).then((project) => {
      this.timeZone = project.time_zone
      this.$store.dispatch('getDoubleAccountsFavoriteUsers', this.currentProject).then((favoriteUsers) => {
        // console.log(favoriteUsers, this.preloadUsers)
        favoriteUsers.forEach(id => {
          if (this.preloadUsers[id]) {
            this.favoriteUsers[id] = this.preloadUsers[id]
          }
        })
        this.shiftDate()
      })
    })
  },
  mounted () {
    this.wipeData()
  },
  props: {
    currentProject: Number,
    preloadUsers: Object,
    shiftAddDate: Object
  },
  computed: {
    usedDoubleAccounts () {
      const accList = []
      for (const key in this.state) {
        if (this.state[key].doubleAccount) {
          accList.push(this.state[key].doubleAccount)
        }
      }
      return accList
    },
    usedUsersAccounts () {
      const usersList = []
      for (const key in this.state) {
        if (this.state[key].userId) {
          usersList.push(this.state[key].userId)
        }
      }
      return usersList
    }
  },
  methods: {
    checkUsedUsers (userID) {
      return this.usedUsersAccounts.indexOf(userID) >= 0
    },
    checkUsedDoubleAccounts (accountID) {
      return this.usedDoubleAccounts.indexOf(accountID) >= 0
    },
    closeModal () {
      this.$emit('closeDoubleAccountsSetupModal')
    },
    getFirstLastName (id) {
      return this.favoriteUsers[id].last_name + ' ' + this.favoriteUsers[id].first_name
    },
    getFavoritDoubleAccName (id) {
      let accName = ''
      if (this.doubleAccountsByUsers[id]) {
        accName += ' ( ' + this.doubleAccounts[this.doubleAccountsByUsers[id]].username.split(/\s+/).map(word => word[0].toUpperCase()).join('') + ' )'
      }
      return accName
    },
    getDoubleAccName (id) {
      let accName = ''
      if (typeof (this.filteredUsers[this.doubleAccounts[id].user_id]) !== 'undefined') {
        accName += ' ( ' + this.filteredUsers[this.doubleAccounts[id].user_id].name.split(/\s+/).map(word => word[0].toUpperCase()).join('') + ' )'
      }
      return accName
    },
    wipeData () {
      const state = []
      for (let i = 0; i < 7; i++) {
        state.push({
          userId: '',
          timeFrom: '',
          timeTo: '',
          doubleAccount: ''
        })
      }
      this.state = state
    },
    handleTemplate (key) {
      this.filteredUsers[this.state[key].userId].template_id = 0
    },
    shiftDate () {
      let params = this.params
      params += 'start=' + this.toSelectedTimeZone(this.dateShift + ' 00:00', this.timeZone).format(this.backendDateTimeFormat) + '&' + 'end=' + this.toSelectedTimeZone(this.dateShift + ' 23:59', this.timeZone).format(this.backendDateTimeFormat)
      this.$store.dispatch('getShiftsByProjectId', [this.currentProject, params]).then(shifts => {
        const tmpAcc = {}
        for (const key in this.doubleAccounts) {
          tmpAcc[key] = this.doubleAccounts[key]
        }
        this.selectUsers = []
        this.filteredUsers = {}
        if (shifts) {
          shifts.forEach(shift => {
            if (shift.employee_id in this.favoriteUsers) {
              if (shift.is_double_account && moment(shift.time_from).isSameOrAfter(moment(this.dateShift).startOf('day'))) {
                delete tmpAcc[shift.double_account_data.id_user]
              }
              if (!shift.is_double_account &&
                  moment(shift.time_from).isSameOrAfter(moment(this.dateShift).startOf('day')) &&
                  moment(shift.time_from).isBefore(moment(this.dateShift).add(1, 'day').startOf('day'))) {
                let user = this.filteredUsers[shift.employee_id]
                if (!user) {
                  user = {
                    id: shift.employee_id,
                    name: this.getFirstLastName(shift.employee_id),
                    timeFrom: this.toSelectedTimeZone(shift.time_from, this.timeZone).format(this.localeTimeFormat),
                    timeTo: this.toSelectedTimeZone(shift.time_to, this.timeZone).format(this.localeTimeFormat),
                    color: shift.color.toUpperCase(),
                    shift_name: shift.name,
                    schedule_id: shift.schedule_id,
                    is_double_account: shift.is_double_account,
                    double_account_data: shift.double_account_data,
                    template_id: shift.template_id
                  }
                  this.filteredUsers[shift.employee_id] = user
                }
              }
            }
          })
          for (const key in this.filteredUsers) {
            this.selectUsers.push(this.filteredUsers[key])
          }
          this.selectUsers.sort((a, b) => {
            if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
            if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
            return 0
          })
          this.filteredDoubleAccounts = {}
          this.filteredDoubleAccounts = tmpAcc
          this.selectDoubleAccounts = []
          for (const key in this.filteredDoubleAccounts) {
            this.selectDoubleAccounts.push(this.filteredDoubleAccounts[key])
          }
          // console.log(this.selectDoubleAccounts)
          this.selectDoubleAccounts.sort((a, b) => {
            if (a.username.trim().toLowerCase() < b.username.trim().toLowerCase()) return -1
            if (a.username.trim().toLowerCase() > b.username.trim().toLowerCase()) return 1
            return 0
          })
          this.wipeData()
        } else {
          this.$notify({
            title: this.$i18n?.t('Warning'),
            message: this.$i18n?.t('No shifts Found'),
            position: this.notificationPosition,
            type: 'warning'
          })
        }
      })
    },
    selectedUser (key, userId) {
      const user = this.filteredUsers[userId]
      const selectData = this.state[key]
      if (user) {
        const userDoubleAcc = this.doubleAccountsByUsers[userId]
        selectData.doubleAccount = userDoubleAcc && this.filteredDoubleAccounts[userDoubleAcc] ? this.filteredDoubleAccounts[userDoubleAcc].id : ''
        selectData.timeFrom = user.timeFrom
        selectData.timeTo = user.timeTo
      } else {
        selectData.doubleAccount = ''
        selectData.timeFrom = ''
        selectData.timeTo = ''
      }
      this.state[key] = selectData
    },
    save () {
      const promises = []
      this.state.forEach((item) => {
        if (item.doubleAccount !== '' && item.timeFrom !== '' && item.timeTo !== '' && item.userId !== '') {
          let dateStart, dateEnd
          dateStart = dateEnd = this.dateShift
          if (moment(item.timeFrom, this.localeTimeFormat).isAfter(moment(item.timeTo, this.localeTimeFormat))) {
            dateEnd = moment(dateStart, this.backendDateFormat).add(1, 'Day').format(this.backendDateFormat)
          }
          const end = dateEnd + ' ' + moment(item.timeTo, this.localeTimeFormat).format(this.backendTimeFormat)
          const start = dateStart + ' ' + moment(item.timeFrom, this.localeTimeFormat).format(this.backendTimeFormat)
          promises.push(this.$store.dispatch('createShift', {
            schedule_id: this.filteredUsers[item.userId].schedule_id,
            employee_id: item.userId,
            time_from: this.fromZoneToZone(start, this.timeZone, 'UTC').format(this.backendDateTimeFormat),
            time_to: this.fromZoneToZone(end, this.timeZone, 'UTC').format(this.backendDateTimeFormat),
            double_account_id: item.doubleAccount,
            color: this.filteredUsers[item.userId].color.toUpperCase(),
            name: this.filteredUsers[item.userId].name,
            template_id: this.filteredUsers[item.userId].template_id
          }).then((response) => {
            this.$eventBus.emit('addShiftsToUsers', [response])
            // delete this.filteredUsers[item.userId]
            delete this.filteredDoubleAccounts[item.doubleAccount]
            const index = this.selectDoubleAccounts.map(e => e.id).indexOf(item.doubleAccount)
            delete this.selectDoubleAccounts[index]
          }))
          Promise.all(promises).then(() => {
            this.selectDoubleAccounts = this.selectDoubleAccounts.filter(el => el)
            this.wipeData()
            this.$notify({
              title: this.$i18n?.t('Success'),
              message: this.$i18n?.t('Shifts updated'),
              position: this.notificationPosition,
              type: 'success'
            })
          })
        }
      })
      // this.closeModal()
    },
    addRow () {
      this.state.push({
        userId: '',
        timeFrom: '',
        timeTo: '',
        doubleAccount: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
