export default {
  data () {
    return {
      paginationConfig: {
        rows: [],
        paginated: [],
        pageSize: 25,
        currentPage: 1
      },
      pageSizeChoices: [
        {
          id: 25,
          name: '25'
        },
        {
          id: 50,
          name: '50'
        },
        {
          id: 100,
          name: '100'
        }
      ]
    }
  },

  methods: {
    updatePaginated (data) {
      this.paginationConfig.paginated = data.paginated
      this.paginationConfig.currentPage = data.currentPage
      this.paginationConfig.pageSize = data.pageSize
    }
  }
}
