<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage }"
    :rules="validation"
    as="div"
    :label="fieldLabel"
  >
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required" />
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
      <el-input
        :type="type"
        :id="inputId"
        :model-value="value"
        v-bind="field"
        :placeholder="placeholder"
        :disabled="!!disabled"
        :data-cy="dataCy ? dataCy : null"
        :validate-event="false"
      >
      <template v-if="prependIcon" #prepend>
        <i :class="prependIcon" class="btn-icon"></i>
      </template>
      </el-input>
    </el-form-item>
  </Field>
</template>

<script>
export default {
  name: 'text-input',
  emits: ['update:modelValue'],

  props: {
    name: String,
    type: { type: String, default: 'text' },
    apiErrors: Array,
    validation: [Object, String],
    value: String,
    modelValue: String,
    placeholder: String,
    disabled: Boolean,
    label: String,
    hint: String,
    dataCy: String,
    inputId: String,
    prependIcon: String,
    isSearch: { type: Boolean, default: false },
    fieldLabel: {
      type: String, default: null
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss">
.el-input__wrapper {
  border-radius: 10px;
}
</style>
