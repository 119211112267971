<template>
  <modal-component :id="'CopyShifts'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('_MODAL_COPY_SHIFTS_TITLE')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div">
          <div class="mb-2 font-weight-bold">
            {{ $t('Select the period than need to be copied') }}
            <ui-hint :content="$t('PERIOD_THAN_NEED_TO_BE_COPIED_HINTS')"/>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <ui-date-picker
                name="copy_from"
                :label="$t('Date from')"
                v-model="from"
                :validation="{required: true}"
              />
            </div>
            <div class="col-6">
              <ui-date-picker
                name="copy_to"
                :label="$t('Date to')"
                v-model="to"
                :validation="{required: true}"
                :disabled-date="disabledDates"
              />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-6">
              <ui-date-picker
                name="paste_from"
                :label="$t('Add copied shifts starting from')"
                :hint="$t('STARTING_FROM_HINT')"
                v-model="startFrom"
                :validation="{required: true}"
                :disabled-date="disabledDatesCopy"
              />
            </div>
            <div class="col-6">
              <ui-number-input
                :label="$t('REPEAT_NUMBER_TITLE')"
                :hint="$t('REPEAT_NUMBER_TITLE_HINT')"
                v-model="repeatNumber"
                name="repeat_number"
                :min="1"
                :precision="0"
                :step="1"
                :validation="{max_value: 10}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <ui-multi-select
                name="USERS_TO_CLONE"
                :label="$t('USERS_TO_CLONE')"
                :hint="$t('USERS_TO_CLONE_HINT')"
                v-model="usersToClone"
                :clearable="true"
                :filterable="true"
                class="w-100"
                :options="usersMultiplySelectChoices"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
          </div>
          <div class="font-weight-bold mb-2">{{ $t('CLONE_WITH_INDENT_TITLE') }}</div>
          <div class="row">
            <div class="col-12">
              <ui-radio
                name="CLONE_WITH_INDENT"
                :label="$t('CLONE_WITH_INDENT')"
                :hint="$t('CLONE_WITH_INDENT_HINTS')"
                v-model="cloneWithIndent"
                :set-value="true"
              />
              <ui-radio
                name="CLONE_WITHOUT_INDENT"
                :label="$t('CLONE_WITHOUT_INDENT')"
                :hint="$t('CLONE_WITHOUT_INDENT_HINTS')"
                v-model="cloneWithIndent"
                :set-value="false"
              />
            </div>
          </div>
          <div class="font-weight-bold my-2">{{ $t('CLONE_WITH_BREAKS_MARKS_TITLE') }}</div>
          <div class="row">
            <div class="col-6">
              <ui-checkbox
                name="CLONE_WITH_MARKS"
                v-model="cloneWithMarks"
              >
                {{ $t('CLONE_WITH_MARKS') }}
                <ui-hint :content="$t('CLONE_WITH_MARKS_HINT')" />
              </ui-checkbox>
            </div>
            <div class="col-6">
              <ui-checkbox
                name="CLONE_WITH_BREAKS"
                v-model="cloneWithBreaks"
              >
                {{ $t('CLONE_WITH_BREAKS') }}
                <ui-hint :content="$t('CLONE_WITH_BREAKS_HINT')" />
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ui-checkbox
                name="CLONE_WITH_LOCATION"
                v-model="cloneWithLocation"
              >
                {{ $t('CLONE_WITH_LOCATION') }}
                <ui-hint :content="$t('CLONE_WITH_LOCATION_HINT')" />
              </ui-checkbox>
            </div>
            <div class="col-6">
              <ui-checkbox
                name="CLONE_WITH_NOTE"
                v-model="cloneWithNote"
              >
                {{ $t('CLONE_WITH_NOTE') }}
                <ui-hint :content="$t('CLONE_WITH_NOTE_HINT')" />
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ui-checkbox
                name="CLONE_WITH_FREEBOARD"
                v-model="cloneWithFreeBoard"
              >
                {{ $t('CLONE_WITH_FREEBOARD') }}
                <ui-hint :content="$t('CLONE_WITH_FREEBOARD_HINT')" />
              </ui-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="font-weight-bold my-2">{{ $t('What to do with shift conflicts') }}</div>
              <ui-radio
                name="Allow_conflicts"
                :label="$t('Allow conflicts')"
                :hint="$t('ALLOW_CONFLICTS_HINTS')"
                v-model="mergeStrategy"
                :set-value="'keep_both'"
              />
              <div class="small ml-3 text-wrap mb-2">{{ $t('Copy shifts even if they conflict with existing shifts.') }}</div>
              <ui-radio
                name="Avoid_conflicts"
                :label="$t('Avoid conflicts')"
                :hint="$t('AVOID_CONFLICTS_HINTS')"
                v-model="mergeStrategy"
                :set-value="'prefer_old'"
              />
              <div class="small ml-3 text-wrap mb-2">{{ $t('Existing shifts won\'t be replaced with copied shifts.') }}</div>
              <ui-radio
                name="Overwrite_conflicts"
                :label="$t('Overwrite conflicts')"
                :hint="$t('OVERWRITE_CONFLICTS_HINTS')"
                v-model="mergeStrategy"
                :set-value="'prefer_new'"
              />
              <div class="small ml-3 text-wrap mb-2">{{ $t('Replace all shifts that conflict with copied shifts.') }}</div>
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="mx-auto text-center">
          <button class="btn btn-success rounded-pill shadow-sm" @click="copy">{{ $t('Copy') }}</button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin],
  name: 'CloneShiftsModal',
  data () {
    return {
      from: null,
      to: null,
      startFrom: null,
      mergeStrategy: 'keep_both',
      templatesItems: [],
      repeatNumber: 1,
      cloneWithIndent: true,
      cloneWithBreaks: false,
      cloneWithMarks: false,
      cloneWithLocation: false,
      cloneWithNote: false,
      cloneWithFreeBoard: false,
      usersMultiplySelectChoices: [],
      usersToClone: []
    }
  },
  props: {
    start: Date,
    end: Date,
    projectId: Number,
    schedules: Array,
    templates: Array,
    resources: Array,
    filter: Object,
    hideEmpty: Boolean
  },
  created () {
    this.init()
  },
  watch: {
    resources () {
      this.init()
    },
    from () {
      console.log(this.from)
      if (moment(this.from).isAfter(moment(this.to))) {
        this.to = this.from
      }
    },
    to () {
      if (moment(this.to).isSameOrAfter(moment(this.startFrom))) {
        this.startFrom = moment(this.to).add(1, 'day').format(this.backendDateTimeFormat)
      }
    }
  },
  methods: {
    disabledDates (date) {
      return moment(this.from, this.localeDateFormat).isAfter(moment(date))
    },
    disabledDatesCopy (date) {
      return moment(this.to, this.localeDateFormat).isSameOrAfter(moment(date))
    },
    init () {
      this.usersMultiplySelectChoices = []
      this.usersToClone = []
      const usersMultiplySelectChoices = []
      const sortedUsersResources = JSON.parse(JSON.stringify(this.resources))
      sortedUsersResources.forEach(value => {
        if (value.id !== 0 && value.id !== 'info_event' && value.id !== 'holidays' && !value.deleted) {
          usersMultiplySelectChoices.push({ id: value.id, name: value.nameWithIdentifier })
          this.usersToClone.push(value.id)
        }
      })
      this.usersMultiplySelectChoices = usersMultiplySelectChoices.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
      })
      this.templates.forEach(item => {
        this.templatesItems.push({
          scheduleId: +item.split('__sp__')[0],
          templateId: +item.split('__sp__')[1]
        })
      })
      this.from = this.start
      this.to = this.end
      this.startFrom = moment(this.to, this.localeDateFormat).add(1, 'day').format(this.backendDateTimeFormat)
    },
    closeModal () {
      this.$emit('CopyShifts')
    },
    changeFrom () {
      if (moment(this.from, this.localeDateFormat).isSameOrAfter(moment(this.to))) {
        this.to = moment(this.from, this.localeDateFormat).add(1, 'days').format(this.localeDateFormat)
        this.startFrom = moment(this.to, this.localeDateFormat).add(1, 'day').format(this.localeDateFormat)
      }
    },
    changeTo () {
      this.startFrom = moment(this.to, this.localeDateFormat).add(1, 'day').format(this.localeDateFormat)
    },
    convertTZ (datetime) {
      return this.fromZoneToZone(moment(datetime, this.localeDateFormat).format(this.backendDateTimeFormat), this.$store.getters.actualTZ, 'UTC')
    },
    copy () {
      this.$refs.observer.validate().then(value => {
        if (value) {
          // из-за перехода на летнее время нельзя в date_insert напрямую передавать this.startFrom
          // т.к. из-за этого может смещаться дата начала копирования
          const data = {
            repeat_number: this.repeatNumber,
            with_indent: this.cloneWithIndent,
            date_from: this.convertTZ(this.from).format(this.backendDateTimeFormat),
            date_to: this.convertTZ(this.to),
            merge_type: this.mergeStrategy,
            date_insert: this.convertTZ(this.startFrom).format(this.backendDateTimeFormat),
            schedules: this.schedules,
            templates_items: this.templatesItems,
            clone_with_breaks: this.cloneWithBreaks,
            clone_with_marks: this.cloneWithMarks,
            clone_with_location: this.cloneWithLocation,
            clone_with_note: this.cloneWithNote,
            clone_with_free_board: this.cloneWithFreeBoard,
            employees: this.usersToClone
          }
          const dateToUtcOffset = data.date_to.clone().tz(this.$store.getters.actualTZ).utcOffset()
          const dateToWithAddedDayUtcOffset = data.date_to.clone().add(1, 'day').tz(this.$store.getters.actualTZ).utcOffset()
          // просто добавить +1 день не выйдет, в таймзонах с DST в переходящий день
          // получим не верую дату с разницей в +-N часов перевода
          data.date_to = data.date_to.add(1, 'day').add(dateToUtcOffset - dateToWithAddedDayUtcOffset, 'minute').format(this.backendDateTimeFormat)

          this.$store.dispatch('copyShifts', [this.projectId, data]).then(response => {
            this.$notify({
              title: this.$i18n?.t('Success'),
              message: this.$t(response.message),
              type: 'success',
              position: this.notificationPosition
            })
            this.$eventBus.emit('refetchEvents')
            this.$eventBus.emit('reloadAllUsersSwitch')
            document.getElementById('CopyShifts').click()
            this.closeModal()
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style>
  .el-input-number {
    position: relative;
    display: inline-block;
    width: 180px;
    line-height: 39px!important;
  }
  .el-input-number--medium .el-input__inner {
    padding-left: 43px!important;
    padding-right: 43px!important;
  }
</style>
