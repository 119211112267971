<template>
  <modal-component :id="'ImportEmployeesModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="ImportEmployeesModalTitle">{{ $t('Import shifts')}}</h5>
    </template>
    <template #body>
      <div class="mt-3">
        <ui-file-upload
          ref="upload"
          name='file'
          :upload="upload"
          :auto-upload="false"
          :multiple="false"
          :limit="1"
          @file="getFile"
        />
      </div>
    </template>
    <template #footer>
      <div>
        <button
          type="button"
          class="btn btn-success rounded-pill shadow-sm"
          aria-label="Close"
          @click="save">
          <span aria-hidden="true">{{ $t('Import')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import mixinMoment from '@/mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'ImportEmployeesModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [mixinMoment],
  data () {
    return {
      file: '',
      size: null,
      fileList: [],
      upload: {
        url: process.env.VUE_APP_ROOT_API + `companies/${this.$store.getters.company.id}/load-statistic/upload/`,
        headers: {
          'Authorization': 'api-key ' + localStorage.getItem('api_key')
        }
      },
      fileLimit: 10240000
    }
  },
  props: {
    scheduleId: Number
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },
  methods: {
    closeModal () {
      this.$refs.upload.cleanFile()
      document.getElementById('modalCloseButton').click()
    },
    getFile (data) {
      this.file = data.file
      this.size = data.size
    },
    save () {
      if (this.file) {
        if (this.size / 1000 > this.fileLimit) {
          return
        }
        let formData = new FormData()
        formData.append('file', this.file)
        formData.append('schedule_id', this.scheduleId)
        this.$store.dispatch('importShifts', [this.companyId, formData]).then(response => {
          this.file = null
          this.size = null
          this.$notify({
            title: this.$i18n?.t('Success'),
            message: this.$i18n?.t('Upload completed!'),
            position: this.notificationPosition,
            customClass: this.notificationCustomClass,
            type: 'success'
          })
          this.closeModal()
        }).catch(() => {})
      } else {
        this.$notify({
          title: this.$i18n?.t('Error'),
          message: this.$i18n?.t('Chose a file!'),
          position: this.notificationPosition,
          customClass: this.notificationCustomClass,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
