<template>
  <modal-component :id="'ClickEventModal'" @closeModal="closeModal"  :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Shift actions')}}</h5>
    </template>
    <template #body>
      <div>
        <template v-if="warning">
          <div class="text-center">
            <h5>{{ $t('Are you sure?') }}</h5>
            <h6 class="text-muted">{{ $t('You want to take action towards a shift that has already started or ended.') }}</h6>
            <button class="btn btn-outline-primary mx-1" data-dismiss="modal" aria-label="Close" @click="closeModal">{{$t('Cancel')}}
            </button>
            <button class="btn btn-success mx-1" @click="warningToggle">{{$t('Ok')}}</button>
          </div>
        </template>
        <template v-else>
          <template v-if="isEmployeeHasPermission('create-shift-admin') ||
         rolesByProjects[currentProjectId] === 'manager'">
            <div class="col border rounded p-2"
                 @click="action=1"
                 :class="(action === 1) ? 'border-primary shadow-sm' : 'border-2'">
              <!--<transition name="expand">-->
              <div v-if="weekOvertime.is_overtime" class="mb-2">
                <el-alert
                  :title="overtimeTitle"
                  :closable="false"
                  center
                  type="warning">
                </el-alert>
              </div>
              <div class="p-1 text-primary" style="cursor: pointer">
                {{ $t('Assign this shift') }}
              </div>
              <template v-if="action === 1">
                <div v-if="shiftData.request_id" class="p-1 text-warning">
                  {{ $t('REQUEST_DECLINE_WARNING') }}
                </div>
                <template v-if="Object.keys(assignUsers).length > 0">
                  <Form ref="observe">
                    <ui-select
                      name="assignUser"
                      :label="$t('A list of users who can take this shift')"
                      v-model="assignUserId"
                      :clearable="false"
                      :filterable="false"
                      :validation="{required: true}"
                      class="w-100"
                      :options="sortUsers(assignUsers)"
                      :key-name="'id'"
                      :label-name="'label'"
                      :value-name="'id'"
                    />
                  </Form>
                  <div class="my-2 text-center">
                    <button class="btn btn-success rounded-pill shadow-sm"
                            :disabled="weekOvertime.is_overtime && weekOvertime.week_overtime_control_type === 'block_overtime'"
                            aria-label="Close"
                            @click="assignShift">
                      {{ $t('Assign') }}
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div class="text-primary text-center my-2">
                    {{ $t('This shift can not be assigned to any user because all workers are busy during its working hours.') }}
                  </div>
                </template>
              </template>
            </div>
            <div class="col border rounded p-2 mt-1"
                 @click="action=2"
                 :class="(action === 2) ? 'border-primary shadow-sm' : 'border-2'">
              <div class="p-1 text-primary" style="cursor: pointer">
                {{ $t('Delete this shift') }}
              </div>
              <template v-if="action === 2">
                <div v-if="shiftData.request_id" class="p-1 text-warning">
                  {{ $t('REQUEST_DECLINE_WARNING') }}
                </div>
                <div class="text-primary text-center my-2">
                  {{ $t('This shift will be deleted') }}
                </div>
                <div class="my-2 text-center">
                  <button
                    class="btn btn-success rounded-pill shadow-sm"
                    aria-label="Close"
                    @click="deleteShift">
                    {{ $t('Yes, I agree') }}
                  </button>
                </div>
              </template>
            </div>
            <div class="col border rounded p-2 mt-1"
                 @click="action=3"
                 :class="(action === 3) ? 'border-primary shadow-sm' : 'border-2'">
              <div class="p-1 text-primary" style="cursor: pointer">
                {{ $t('Shift logs') }}
              </div>
              <template v-if="action === 3">
                <el-timeline>
                  <el-timeline-item
                    v-for="(item, index) in logActivities"
                    :key="index"
                    :color="item['action'] === 'created' ? '#0bbd87' : ''"
                    :timestamp="formatStringToDateTime(item['created_at']) + ' (' + $t(item['description'] ? item['description'] : '') +  ')'"
                    placement="top">
                    <template v-if="item['action'] === 'created'">
                      <el-card>
                        <span>{{ $t('Shift created at') }} {{ formatStringToDateTime(item['created_at']) }} {{$t('by')}} {{ item['user_first_name'] }} {{ item['user_last_name'] }}</span>
                        <p>{{ formatStringToTime(item['time_from']) }} - {{ formatStringToTime(item['time_to']) }}</p>
                        <span>
                        <template v-if="item['employee_id'] === '0'">{{ $t('Free Board') }}</template>
                        <template v-else>{{ item['employee_first_name'] }} {{ item['employee_last_name'] }}</template>
                         {{ $t('on') }} {{ formatStringToDate(item['time_from']) }}</span>
                      </el-card>
                    </template>
                    <template v-if="item['action'] === 'updated' && item['employee_id'] !== item['old_employee_id']">
                      <el-card>
                        <span>{{ $t('Reassigned by') }} {{ item['user_first_name'] }} {{ item['user_last_name'] }} {{ $t('at') }} {{ formatStringToDateTime(item['created_at']) }}</span>
                        <p>{{ $t('to') }} <template v-if="item['employee_id'] === '0'">{{ $t('Free Board') }}</template>
                          <template v-else>{{ item['employee_first_name'] }} {{ item['employee_last_name'] }}</template>
                        </p>
                      </el-card>
                    </template>
                    <template v-if="item['action'] === 'updated' && (item['time_from'] !== item['old_time_from'] || item['time_to'] !== item['old_time_to'])">
                      <el-card>
                        <span>{{ $t('Time changed by') }} {{ item['user_first_name'] }} {{ item['user_last_name'] }} {{ $t('at') }} {{ formatStringToDateTime(item['created_at']) }}</span>
                        <p>{{ formatStringToTime(item['time_from']) }} - {{ formatStringToTime(item['time_to']) }}</p>
                        <p>{{ formatStringToDate(item['time_from']) }}</p>
                      </el-card>
                    </template>
                  </el-timeline-item>
                </el-timeline>
              </template>
            </div>
            <div class="col border rounded p-2 mt-1"
                 @click="action=4"
                 :class="(action === 4) ? 'border-primary shadow-sm' : 'border-2'">
              <div class="p-1 text-primary" style="cursor: pointer">
                {{ $t('SHIFT_EDIT') }}
              </div>
              <template v-if="action === 4">
                <ShiftFreeboardEditComponent
                  :shiftInfo="shiftData"
                  :locations="locations"
                  :closeModal="closeModal"
                  :stackedShifts="stackedShifts"
                />
              </template>
            </div>
          </template>
          <template v-else>
            <div class="col border rounded p-2 mt-1"
                 @click="action=1"
                 :class="(action === 1) ? 'border-primary shadow-sm' : 'border-2'">
              <div v-if="weekOvertime.is_overtime && weekOvertime.week_overtime_control_type === 'block_overtime'" class="mb-2">
                <el-alert
                  :title="$t('WEEK_OVERTIME_WARNING_FOR_USER')"
                  :closable="false"
                  center
                  type="warning">
                </el-alert>
              </div>
              <div class="p-1">
                {{ $t('Take this shift') }}
              </div>
              <template v-if="action === 1">
                <div class="text-primary text-center my-2">
                  <template v-if="canTakeShift && (!weekOvertime.is_overtime || weekOvertime.week_overtime_control_type !== 'block_overtime')">
                    {{ $t('You can take this shift') }}
                  </template>
                  <template v-else>
                    {{ $t('You can`t take this shift') }}
                  </template>
                </div>
                <div v-if="canTakeShift && (!weekOvertime.is_overtime || weekOvertime.week_overtime_control_type !== 'block_overtime')" class="my-2 text-center">
                  <button
                    class="btn btn-success rounded-pill shadow-sm"
                    aria-label="Close"
                    @click="takeShift">
                    {{ $t('Take this shift') }}
                  </button>
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
    </template>
    <template #footer>
      <div></div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import ShiftFreeboardEditComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftFreeboardEditComponent.vue'

export default {
  name: 'ClickFreeBoardEventModal',
  mixins: [
    momentMixin,
    ToastMixin
  ],
  components: {
    ShiftFreeboardEditComponent,
    ModalComponent: ModalComponent
  },

  data () {
    return {
      action: 1,
      assignUserId: null,
      warning: false,
      nodeEnv: process.env.NODE_ENV,
      logActivities: [],
      shiftData: {},
      stackedShifts: [],
      weekOvertime: {
        is_overtime: false,
        warning: null,
        week_overtime: 0,
        week_overtime_control_type: 'allow_overtime',
        week_quota: 40,
        week_working_hours: 0,
        month_overtime: 0,
        month_quota: 160,
        month_working_hours: 0
      },
      locations: []
    }
  },

  props: {
    shift: Object,
    assignUsers: Object,
    canTakeShift: Boolean
  },

  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId'
    }),
    overtimeTitle () {
      let message = ''
      if (this.weekOvertime.week_overtime) {
        message += this.$t('WEEK_OVERTIME_WARNING') + ' ' + this.$t('WEEK_WORKED_HRS') + ': ' + Math.round(this.weekOvertime.week_working_hours * 100) / 100 + ' ' + this.$t('WEEK_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertime.week_overtime * 100) / 100
      }
      if (this.weekOvertime.month_overtime) {
        message += ' ' + this.$t('MONTH_OVERTIME_WARNING') + ' ' + this.$t('MONTH_WORKED_HRS') + ': ' + Math.round(this.weekOvertime.month_working_hours * 100) / 100 + ' ' + this.$t('MONTH_OVERTIME_HRS') + ': ' + Math.round(this.weekOvertime.month_overtime * 100) / 100
      }
      return message
    },
    companyId () {
      return this.$store.getters.companyId
    }
  },

  created () {
    this.warning = this.shift.isInPast
    this.stackedShifts = this.shift.freeBoardStackedEvents.map(item => item.id)
    this.$store.dispatch('getShift', this.shift.shiftId).then(shift => {
      this.shiftData = shift
      this.$store.dispatch('getLocations', this.companyId).then(response => {
        this.locations = response
          .filter(item => +item.id === (this.shiftData.location && +this.shiftData.location.id) || !item.hide)
          .map(location => {
            return { id: location.id, name: location.title }
          })
        // console.log(this.locations)
      })
    })
  },

  mounted () {
    this.getShiftLogs()
  },

  watch: {
    shift: function () {
      this.getShiftLogs()
    },
    assignUserId () {
      this.updateOvertimeCheck()
    }
  },

  methods: {
    sortUsers () {
      const list = this.assignUsers
      const sortable = []

      for (const key in list) {
        sortable.push({
          id: key,
          label: list[key]
        })
      }

      sortable.sort(function (a, b) {
        if (a.label.trim().toLowerCase() < b.label.trim().toLowerCase()) return -1
        if (a.label.trim().toLowerCase() > b.label.trim().toLowerCase()) return 1
        return 0
      })
      return sortable
    },
    updateOvertimeCheck () {
      const query = `employee_id=${this.assignUserId}&time_from=${this.shiftData.time_from}&time_to=${this.shiftData.time_to}&shift_id=${+this.shiftData.id}&break_time=${+this.shiftData.break_time}`
      this.$store.dispatch('isWeekOvertime', [this.shiftData.schedule_id, query]).then(result => {
        this.weekOvertime = result
        // console.log(result)
      })
    },
    getShiftLogs () {
      this.$store.dispatch('getShiftLogs', this.shift.shiftId).then(logs => {
        this.logActivities = logs
      }).catch(() => {
      })
    },
    closeModal () {
      this.$emit('closeClickFreeBoardEventModal')
    },
    formatStringToTime (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeTimeFormat)
    },
    formatStringToDate (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeDateMonthFormat)
    },
    formatStringToDateTime (t) {
      return this.fromZoneToZone(t, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
    },
    warningToggle () {
      this.warning = !this.warning
    },
    deleteShift () {
      if (this.shiftData.request_id) {
        this.$store.dispatch('getRequest', [this.$store.getters.companyId, this.shiftData.request_id]).then(shiftRequest => {
          const data = {
            subtype: shiftRequest.subtype,
            status: 'declined',
            message: this.$i18n?.t('Canceled')
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftData.request_id, data]).then(() => {
            this.handleDelete()
          })
        })
      } else {
        this.handleDelete()
      }
    },
    handleDelete () {
      this.$store.dispatch('deleteShift', this.shift.shiftId).then(() => {
        this.$eventBus.emit('deleteFreeBoardShift', this.shift.shiftId)
        this.toastSuccess(this.$i18n?.t('Shift successfully deleted'))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((err) => {
        this.toastError(this.$i18n?.t(err.response.data.message))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
    },
    assignShift () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          if (this.weekOvertime.is_overtime && this.weekOvertime.week_overtime_control_type === 'confirm_overtime') {
            const body = document.getElementsByTagName('body')
            body[0].setAttribute('style', 'padding-right: 0px;')
            this.$confirm(this.$i18n?.t('CONFIRM_WEEK_OVERTIME'), this.$i18n?.t('Are you sure?'), {
              confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
              cancelButtonText: this.$i18n?.t('No, cancel it!!'),
              type: 'warning',
              center: true
            }).then((result) => {
              this.handleAssignShift()
            }).catch(() => {})
          } else {
            this.handleAssignShift()
          }
        }
      })
    },
    handleAssignShift () {
      // всегда админ или менеджер
      // console.log(this.shiftData)
      if (this.shiftData.request_id) {
        this.$store.dispatch('getRequest', [this.$store.getters.companyId, this.shiftData.request_id]).then(shiftRequest => {
          const data = {
            subtype: shiftRequest.subtype,
            status: 'declined',
            message: this.$i18n?.t('Canceled')
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftData.request_id, data]).then(() => {
            this.patchAssignedShift()
          })
        })
      } else {
        this.patchAssignedShift()
      }
    },
    patchAssignedShift () {
      const data = {
        employee_id: this.assignUserId
      }
      return this.$store.dispatch('patchShift', [this.shift.shiftId, data]).then((shift) => {
        this.$eventBus.emit('assignFreeBoardShift', shift)
        this.toastSuccess(`${this.$i18n?.t('The shift was successfully assigned to')} ${this.assignUsers[this.assignUserId]}`)
        if (this.weekOvertime.is_overtime) {
          this.toastWarning(this.$i18n?.t('WARNING_WEEK_OVERTIME'))
        }
        delete this.assignUsers[this.assignUserId]
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
    },
    takeShift () {
      // всегда обычный пользователь
      const body = document.getElementsByTagName('body')
      body[0].setAttribute('style', 'padding-right: 0px;')
      this.$confirm(this.$i18n?.t('Are you sure that you want to take this Free Board shift?'), this.$i18n?.t('Warning!'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        const formData = {
          subtype: 'move_to_user',
          shift_id: this.shift.shiftId
          // user_id: this.$store.getters.profileId
        }

        this.$store.dispatch('setRequest', [this.$store.getters.companyId, formData]).then((userRequest) => {
          if (userRequest.status === 'confirmed') {
            // this.$eventBus.$emit('assignShift', this.$store.getters.profileId)
            this.toastSuccess(this.$i18n?.t('You successfully took this Free Board shift'))
            this.$store.dispatch('updateShiftInList', userRequest.shift)
            this.$eventBus.emit('assignFreeBoardShift', userRequest.shift)
            document.getElementById('modalCloseButton').click()
            this.closeModal()
          } else {
            let message = this.$i18n?.t('You will be assigned this Free Board shift after a manager confirms your request.')
            if (!userRequest.shift_exchange_confirmation && userRequest.shiftWeekOvertime) {
              message = this.$i18n?.t('REQUEST_BECAUSE_OVERTIME')
            }
            this.toastSuccess(message)
            this.$eventBus$eventBus.emit('refetchEvents')
            document.getElementById('modalCloseButton').click()
            this.closeModal()
          }
        }).catch((error) => {
          this.toastError(this.$i18n?.t(error.response.data.message))
          document.getElementById('modalCloseButton').click()
          this.closeModal()
        })
      }).catch(() => {
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
