<template>
  <div>
    <div class="row my-1">
      <div class="col-10">
        <ui-text-input
          name="title"
          :label="$t('CHECKLIST_ITEM_TITLE')"
          v-model="breakItemState.title"
          :validation="{required: true}"
        />
      </div>
      <div class="col-2">
        <label class="control-label font-weight-bold">{{ $t('Billable') }}</label>
        <div class="mt-2">
          <ui-checkbox
            name="billable"
            v-model="breakItemState.billable"
          />
        </div>
      </div>
    </div>
    <div v-if="showDate" class="row my-1">
      <div class="col-5">
        <ui-datetime-picker
          :label="$t('START_TIME')"
          name="time_from"
          :placeholder="$t('From')"
          v-model="breakItemState.time_from"
          @change="dateChange('from', breakItemState)"
          :validation="{required: true}"
          :clearable="false"
          :picker-options="pickerOptionsFrom"
        />
      </div>
      <div class="col-5">
        <ui-datetime-picker
          :label="$t('END_TIME')"
          name="time_to"
          :placeholder="$t('To')"
          v-model="breakItemState.time_to"
          @change="dateChange('to', breakItemState)"
          :validation="{required: true}"
          :clearable="false"
          :picker-options="pickerOptionsTo"
        />
      </div>
      <div v-if="!breakItemState.id" class="col-2">
        <label class="control-label font-weight-bold">{{ $t('Delete') }}</label>
        <div class="mt-2">
          <i class="icon-trash btn-icon text-danger my-auto"
             @click="deleteItem()"
             role="button">
          </i>
        </div>
      </div>
      <template v-if="isRoleUser">
        <div class="col-12">
          <label class="control-label font-weight-bold">{{ $t('CHECKLIST_ITEM_TITLE') }}</label>
          <textarea type="text"
                    name="title"
                    class="form-control"
                    v-model="breakItemState.comment"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'BreakFormComponent',
  mixins: [momentMixin],
  data () {
    return {
      showDate: true,
      breakItemState: {}
    }
  },
  props: {
    breakItem: Object,
    shift: Object,
    isRoleUser: Boolean,
    itemKey: Number
  },
  created () {
    this.breakItemState = this.breakItem
    // console.log(this.breakItem)
  },
  computed: {
    pickerOptionsFrom () {
      const from = moment(this.shift.time_from).clone().startOf('day')
      const to = moment(this.shift.time_to).clone()
      return {
        firstDayOfWeek: this.$store.getters.company && this.$store.getters.company.salary ? +this.$store.getters.company.salary.week_start + 1 : null,
        disabledDate: (date) => {
          return moment(date).isBefore(from) ||
            moment(date).isAfter(to)
        }
      }
    },
    pickerOptionsTo () {
      // console.log(this.shift.time_from.format(this.backendDateTimeFormat))
      const from = moment(this.shift.time_from).clone().startOf('day')
      const to = moment(this.shift.time_to).clone()
      return {
        disabledDate: (date) => {
          return moment(date).isBefore(from) ||
            moment(date).isAfter(to)
        },
        firstDayOfWeek: this.$store.getters.company && this.$store.getters.company.salary ? +this.$store.getters.company.salary.week_start + 1 : null
      }
    }
  },
  methods: {
    deleteItem () {
      this.$emit('onDelete')
    },
    dateChange (val, breakItem) {
      this.showDate = false // костыль, иначе даты в датапикере сразу не обновляются
      if (val === 'from') {
        if (moment(breakItem.time_from).isAfter(moment(breakItem.time_to))) {
          breakItem.time_to = breakItem.time_from
        }
      }
      if (val === 'to') {
        if (moment(breakItem.time_to).isBefore(moment(breakItem.time_from))) {
          breakItem.time_from = breakItem.time_to
        }
      }
      this.showDate = true
    }
  }
}
</script>

<style scoped>

</style>
