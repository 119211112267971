<template>
  <div class="row">
    <div class="col py-2">
      <div class="card card-small mx-1">
        <div class="card-body">
          <div class="m-1">
            <ui-select
              :label="$t('Choose project')"
              v-model="project_id"
              :options="projects"
              name="project"
              :key-name="'id'"
              :label-name="'name'"
              :value-name="'id'"
            />
          </div>
          <div class="m-1">
            <div>
              <label class="control-label mb-1 p-0 font-weight-bold">{{ $t('Choose period') }}</label>
            </div>
            <div class="row">
              <div class="col">
                <ui-date-picker
                  name="period_from"
                  v-model="period[0]"
                  :clearable="false"
                  @change="changeFrom"
                />
              </div>
              <div class="col">
                <ui-date-picker
                  name="period_to"
                  v-model="period[1]"
                  :clearable="false"
                  @change="changeTo"
                />
              </div>
            </div>
          </div>
          <div class="my-2 mx-1">
            <el-button
              @click="updateGeolocationFilter"
              type="primary"
              class="w-100">
              {{$t('APPLY_FILTER')}}
            </el-button>
          </div>
        </div>
      </div>
      <div class="card card-small mt-2 mx-1">
        <div class="card-body">
          <div class="m-1">
            <ui-multi-select
              :label="$t('Choose employees')"
              v-model="employees"
              :options="employeesList"
              name="employees"
              :key-name="'id'"
              :label-name="'full_name'"
              :value-name="'id'"
            />
          </div>
          <div class="mx-1 my-2">
            <ui-switch
              name="point_or_route"
              :disabled="employees.length === 0"
              v-model="onlyLastPoint"
              @change="val => onlyLastPoint = val"
              :active-text="$t('SHOW_LAST_POINT')"
              :inactive-text="$t('SHOW_ROUTE')"
            />
          </div>
          <div class="my-2 mx-1">
            <el-button
              @click="applyEmployeeFilter"
              type="primary"
              class="w-100">
              {{$t('APPLY_FILTER')}}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'FilterComponent',
  mixins: [momentMixin],
  data () {
    return {
      project_id: null,
      employees: [],
      period: [],
      pickerOptions: {
        firstDayOfWeek: this.$store.getters.company && this.$store.getters.company.salary ? +this.$store.getters.company.salary.week_start + 1 : null
      },
      onlyLastPoint: true,
      intervalId: null
    }
  },
  props: { isAutoRefreshEnable: Boolean, clearInterval: Boolean },
  created () {
    if (!this.geolocationFilters.project_id) {
      this.setProjectId()
      const from = moment().startOf('day').format(this.backendDateTimeFormat)
      const to = moment().endOf('day').format(this.backendDateTimeFormat)
      this.period = [from, to]
    } else {
      this.project_id = this.geolocationFilters.project_id
      this.period = this.geolocationFilters.period
      this.employees = this.displayFilters.employees_filter
      this.onlyLastPoint = this.displayFilters.only_last_point
    }
  },
  computed: {
    projects () {
      const projectsList = this.$store.getters.projects.filter(project => this.isEmployeeHasPermission('create project') ||
        (project.managers && project.managers.includes(this.$store.getters.company.employee_id)))
      const options = []
      projectsList.forEach(project => {
        options.push({
          id: project.id,
          name: project.name
        })
      })
      return options
    },
    currentProjectId () {
      return this.$store.getters.currentProjectId
    },
    employeesList () {
      return this.$store.getters.employeesGeolocation
    },
    geolocationFilters () {
      return this.$store.getters.geolocationFilters
    },
    displayFilters () {
      return this.$store.getters.displayFilters
    },
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  watch: {
    clearInterval () {
      if (this.clearInterval) {
        // console.log(11)
        clearInterval(this.intervalId)
      }
    },
    isAutoRefreshEnable () {
      clearInterval(this.intervalId)
      if (this.isAutoRefreshEnable) {
        this.intervalId = setInterval(() => {
          this.updateLocations()
        }, 1000 * 30)
      }
    },
    geolocationFilters () {
      this.employees = []
      this.onlyLastPoint = true
      this.$store.dispatch('updateGeolocationEmployeeFilter', {
        employees: this.employees,
        onlyLastPoint: this.onlyLastPoint
      })
      this.updateLocations()
    },
    employees () {
      if (this.employees.length === 0) {
        this.onlyLastPoint = true
      }
    }
  },
  methods: {
    changeFrom () {
      if (moment(this.period[0]).isAfter(moment(this.period[1]))) {
        this.period = [this.period[0], this.period[0]]
      } else if (moment(this.period[0]).add(2, 'days').isBefore(moment(this.period[1]))) {
        this.period = [this.period[0], moment(this.period[0]).add(2, 'days').format(this.backendDateTimeFormat)]
      }
    },
    changeTo () {
      if (moment(this.period[0]).isAfter(moment(this.period[1]))) {
        this.period = [this.period[1], this.period[1]]
      } else if (moment(this.period[0]).add(2, 'days').isBefore(moment(this.period[1]))) {
        this.period = [moment(this.period[1]).add(-2, 'days').format(this.backendDateTimeFormat), this.period[1]]
      }
    },
    setProjectId () {
      if (this.$store.getters.currentProjectId > 0) {
        this.project_id = this.$store.getters.currentProjectId
      } else {
        this.project_id = this.projects[0]?.id
      }
    },
    updateGeolocationFilter () {
      this.$store.dispatch('updateGeolocationFilter', {
        project_id: this.project_id,
        period: [
          moment(this.period[0]).format(this.backendDateTimeFormat),
          moment(this.period[1]).format(this.backendDateTimeFormat)
        ]
      })
    },
    applyEmployeeFilter () {
      this.$store.dispatch('updateGeolocationEmployeeFilter', {
        employees: this.employees,
        onlyLastPoint: this.onlyLastPoint
      })
    },
    updateLocations () {
      if (this.project_id) {
        const from = this.fromZoneToZone(this.geolocationFilters.period[0], this.tz, 'UTC').format(this.backendDateTimeFormat)
        const to = this.fromZoneToZone(moment(this.geolocationFilters.period[1]).endOf('day').format(this.backendDateTimeFormat), this.tz, 'UTC').format(this.backendDateTimeFormat)
        const queryGeolocation = `?project_id=${this.project_id}&from=${from}&to=${to}`
        this.$store.dispatch('getEmployeesLocation', [
          this.$store.getters.companyId,
          queryGeolocation
        ]).catch(e => {})
      }
    }
  }
}
</script>

<style scoped>

</style>
