<template>
  <Form ref="observer" as="div">
    <div class="row">
      <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
        {{ $t("Main information") }}
      </div>
      <div class="col-lg-12 my-1">
        <ui-text-input
          id="title"
          name="title"
          class="w-50"
          :label="$t('Schedule')"
          :hint="$t('SCHEDULE_NAME_HINTS')"
          :placeholder="$t('Schedule')"
          v-model="mainInfo.title"
          :validation="{required: true, min: 3, max: 128, regex: /[?$<>{}*^=\p{L}\d]/u}"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-color-picker
          name="color"
          v-model="mainInfo.color"
          :colors="colorsChoices"
          :label="$t('Choose a color:')"
        />
      </div>
      <div class="col-12 my-1">
        <ui-select
          name="week_overtime_control_type"
          :label="$t('SELECT_WEEK_OVERTIME_CONTROL_TYPE_LABEL')"
          :hint="$t('SELECT_WEEK_OVERTIME_CONTROL_TYPE_TOOLTIP')"
          v-model="mainInfo.week_overtime_control_type"
          class="w-50"
          :options="weekOvertimeOptions"
          :key-name="'value'"
          :value-name="'value'"
          :label-name="'label'"
        />
      </div>
      <div class="col-12 my-1">
        <ui-checkbox
          name="shifts_visible_everyone"
          :disabled="true"
          v-if="scheduleState.project.shifts_visible_everyone"
          :hint="$t('key_use_project_settings')"
          v-model="scheduleState.project.shifts_visible_everyone"
          :label="$t('allow employees to see all shifts')"
        />
        <ui-checkbox
          name="visibleEveryone"
          v-if="!scheduleState.project.shifts_visible_everyone"
          :hint="$t('allow_employees_to_see_all_shifts_HINT')"
          v-model="mainInfo.visibleEveryone"
          :label=" $t('allow employees to see all shifts')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-select
          name="visible_everyone_type"
          v-model="mainInfo.visible_everyone_type"
          :disabled="!mainInfo.visibleEveryone || scheduleState.project.shifts_visible_everyone"
          class="w-25 mb-auto ml-3"
          :options="showUsersOptions"
          :key-name="'value'"
          :value-name="'value'"
          :label-name="'label'"
        />
      </div>
      <div class="col-lg-12 my-1">
<!--        {{schedule.project}}-->
        <ui-checkbox
          name="project_shifts_exchange"
          :disabled="true"
          v-if="scheduleState.project.shifts_exchange"
          :hint="$t('key_use_project_settings')"
          v-model="scheduleState.project.shifts_exchange"
          :label=" $t('Allow shift swaps between employees')"
        />
        <ui-checkbox
          name="shiftsExchange"
          v-if="!scheduleState.project.shifts_exchange"
          :hint="$t('swap_settings_hint')"
          v-model="mainInfo.shiftsExchange"
          :label=" $t('Allow shift swaps between employees')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="project_shifts_exchange_confirmation"
          v-if="scheduleState.project.shifts_exchange"
          :hint="$t('key_use_project_settings')"
          v-model="scheduleState.project.shifts_exchange_confirmation"
          data-cy="manager-confirmation"
          :disabled="true"
          class="mb-auto ml-3"
          :label=" $t('Shift swapping with manager confirmation')"
        />
        <ui-checkbox
          name="managerConfirmation"
          v-if="!scheduleState.project.shifts_exchange"
          :hint="$t('SWAP_CONFIRMATION_HINT')"
          v-model="mainInfo.managerConfirmation"
          data-cy="manager-confirmation"
          :disabled="!mainInfo.shiftsExchange"
          class="mb-auto ml-3"
          :label=" $t('Shift swapping with manager confirmation')"
        />
        <ui-checkbox
          name="shift_exchange_same_position_only"
          :disabled="scheduleState.project.shifts_exchange || !mainInfo.shiftsExchange"
          :hint="$t('SHIFT_EXCHANGE_SAME_POSITION_ONLY_HINT')"
          v-model="mainInfo.shift_exchange_same_position_only"
          data-cy="same-position-exchange"
          class="mb-auto ml-3"
          :label=" $t('SHIFT_EXCHANGE_SAME_POSITION_ONLY')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="putOnFreeBoard"
          :hint="$t('ALLOW_PUT_SHIFTS_ON_FREEBOARD_HINT')"
          v-model="mainInfo.putOnFreeBoard"
          class="mb-auto"
          :label=" $t('ALLOW_PUT_SHIFTS_ON_FREEBOARD')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="putOnFreeboardConfirmation"
          :hint="$t('PUT_ON_FREEBOARD_MANAGER_CONFIRMATION_HINT')"
          :disabled="!mainInfo.putOnFreeBoard"
          v-model="mainInfo.putOnFreeboardConfirmation"
          class="mb-auto ml-3"
          :label=" $t('PUT_ON_FREEBOARD_MANAGER_CONFIRMATION')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="allowShiftRequest"
          :hint="$t('ALLOW_SHIFT_REQUESTS_HINT')"
          v-model="mainInfo.allowShiftRequest"
          class="mb-auto"
          :label=" $t('ALLOW_SHIFT_REQUESTS')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="allowShiftRequestConfirmation"
          :hint="$t('ALLOW_SHIFT_REQUESTS_CONFIRMATION_HINT')"
          v-model="mainInfo.allowShiftRequestConfirmation"
          class="mb-auto ml-3"
          data-cy="manager-confirmation"
          :disabled="!mainInfo.allowShiftRequest"
          :label=" $t('ALLOW_SHIFT_REQUESTS_CONFIRMATION')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="allowBreakRequest"
          :hint="$t('ALLOW_BREAK_REQUESTS_HINT')"
          v-model="mainInfo.allowBreakRequest"
          class="mb-auto"
          :label=" $t('ALLOW_BREAK_REQUESTS')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="allowBreakRequestConfirmation"
          :hint="$t('ALLOW_BREAK_REQUESTS_CONFIRMATION_HINT')"
          v-model="mainInfo.allowBreakRequestConfirmation"
          class="mb-auto ml-3"
          data-cy="manager-confirmation"
          :disabled="!mainInfo.allowBreakRequest"
          :label=" $t('ALLOW_BREAK_REQUESTS_CONFIRMATION')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="allow_drop_to_employee"
          :hint="$t('ALLOW_DROP_TO_EMPLOYEE_HINT')"
          v-model="mainInfo.allow_drop_to_employee"
          class="mb-auto"
          :label=" $t('ALLOW_DROP_TO_EMPLOYEE')"
        />
      </div>
      <div class="col-lg-12">
        <ui-checkbox
          name="allow_drop_to_employee_confirmation"
          :hint="$t('ALLOW_DROP_TO_EMPLOYEE_CONFIRMATION_HINT')"
          v-model="mainInfo.allow_drop_to_employee_confirmation"
          class="mb-auto ml-3"
          data-cy="manager-confirmation"
          :disabled="!mainInfo.allow_drop_to_employee"
          :label=" $t('ALLOW_DROP_TO_EMPLOYEE_CONFIRMATION')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="shifts_take_freeboard"
          :hint="$t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD_HINT')"
          v-if="scheduleState.project.shifts_visible_everyone"
          v-model="scheduleState.project.shifts_take_freeboard"
          class="mb-auto"
          :disabled="true"
          :label=" $t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD')"
        />
        <ui-checkbox
          name="takeFromFreeboard"
          :hint="$t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD_HINT')"
          v-if="!scheduleState.project.shifts_visible_everyone"
          v-model="mainInfo.takeFromFreeboard"
          class="mb-auto"
          :label=" $t('ALLOW_TAKE_SHIFTS_FROM_FREEBOARD')"
        />
      </div>
      <div class="col-lg-12 my-1">
        <ui-checkbox
          name="shifts_take_freeboard_confirmation"
          :hint="$t('key_use_project_settings')"
          v-if="scheduleState.project.shifts_visible_everyone"
          v-model="scheduleState.project.shifts_take_freeboard_confirmation"
          class="mb-auto ml-3"
          :disabled="true"
          :label=" $t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION')"
        />
        <ui-checkbox
          name="takeFromFreeboardConfirmation"
          :hint="$t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION_HINT')"
          v-if="!scheduleState.project.shifts_visible_everyone"
          v-model="mainInfo.takeFromFreeboardConfirmation"
          class="mb-auto ml-3"
          :disabled="!mainInfo.takeFromFreeboard"
          :label=" $t('TAKE_FROM_FREEBOARD_MANAGER_CONFIRMATION')"
        />
      </div>
      <div class="col-12 my-1">
        <ui-checkbox
          name="allow_exchange_break_in_diff_templates"
          :hint="$t('key_allow_exchange_break_in_diff_templates')"
          v-model="mainInfo.allow_exchange_break_in_diff_templates"
          class="mb-auto"
          :label=" $t('allow_exchange_break_in_diff_templates')"
        />
      </div>
      <div class="col-12 my-1">
        <ui-checkbox
          name="allow_split_shift_by_employee"
          :hint="$t('key_allow_split_shift_by_employee')"
          v-model="mainInfo.allow_split_shift_by_employee"
          class="mb-auto"
          :label=" $t('allow_split_shift_by_employee')"
        />
      </div>
      <div class="col-lg-12 py-2">
        <button class="btn btn-success btn-circle shadow-sm m-1" @click="save">{{ $t('SAVE_WEB') }}</button>
        <router-link v-if="isEmployeeHasPermission('update-schedule-user')" class="btn btn-primary btn-circle shadow-sm m-1" :to="`/c/${companyId}/schedule/master/${scheduleState.scheduleId}/`">
          {{ $t('Set up') }}
        </router-link>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColors from '@/mixins/mixinColors'

export default {
  name: 'MainSettings',
  mixins: [errorMixin, mixinColors],

  data () {
    return {
      mainInfo: {
        title: '',
        color: '',
        checkAvailability: false,
        visibleEveryone: false,
        visible_everyone_type: 'all',
        week_overtime_control_type: 'allow_overtime',
        working_hours: true,
        shiftsExchange: false,
        managerConfirmation: false,
        shift_exchange_same_position_only: false,
        putOnFreeBoard: false,
        putOnFreeboardConfirmation: false,
        allowShiftRequest: false,
        allowShiftRequestConfirmation: false,
        allowBreakRequest: false,
        allowBreakRequestConfirmation: false,
        takeFromFreeboard: false,
        takeFromFreeboardConfirmation: false,
        bind_break: false,
        linkShifts: false,
        break_between_shifts: 0,
        minimum_breaks_in_row: 0,
        maximum_breaks_in_row: 0,
        minimum_break_between_shifts: 0,
        minimum_break_between_shifts_for_women: 0,
        minimum_break_in_row_per_week: 0,
        minimum_shift_duration: 0,
        maximum_shift_duration: 8,
        minimum_coverage: 95,
        maximum_coverage: 105,
        create_templates: true,
        scheduleDemand: [],
        allow_exchange_break_in_diff_templates: false,
        allow_split_shift_by_employee: false,
        allow_drop_to_employee: false,
        allow_drop_to_employee_confirmation: false
      },
      weekOvertimeOptions: [{
        value: 'confirm_overtime',
        label: this.$i18n?.t('CONFIRM_OVERTIME_LABEL')
      }, {
        value: 'allow_overtime',
        label: this.$i18n?.t('ALLOW_OVERTIME_LABEL')
      }, {
        value: 'block_overtime',
        label: this.$i18n?.t('BLOCK_OVERTIME_LABEL')
      }],
      showUsersOptions: [{
        value: 'all',
        label: this.$i18n?.t('KEY_SHOW_ALL')
      }, {
        value: 'with_same_position',
        label: this.$i18n?.t('KEY_SHOW_WITH_SAME_POSITIONS')
      }],
      scheduleState: {}
    }
  },

  props: {
    schedule: Object
  },

  computed: {
    allowExchange () {
      return this.mainInfo.shiftsExchange
    },
    putOnFreeBoard () {
      return this.mainInfo.putOnFreeBoard
    },
    project () {
      return this.$store.getters.projectById(this.scheduleState.project_id) || {}
    }
  },

  created () {
    this.scheduleState = this.schedule
    this.updateMainInfo(this.scheduleState)
  },

  watch: {
    putOnFreeBoard (newValue) {
      if (!newValue & !this.mainInfo.shiftsExchange) {
        this.mainInfo.managerConfirmation = false
      }
    },
    allowExchange (newValue) {
      if (!newValue & !this.mainInfo.putOnFreeBoard) {
        this.mainInfo.managerConfirmation = false
      }
    },
    scheduleState: {
      handler: function () {
        this.updateMainInfo()
      },
      deep: true
    }
  },

  methods: {
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const schedule = {
            name: this.mainInfo.title,
            color: this.mainInfo.color,
            availability: this.mainInfo.checkAvailability,
            visible_everyone: this.mainInfo.visibleEveryone,
            visible_everyone_type: this.mainInfo.visible_everyone_type,
            week_overtime_control_type: this.mainInfo.week_overtime_control_type,
            working_hours: this.mainInfo.working_hours,
            break_between_shifts: this.mainInfo.break_between_shifts,
            minimum_breaks_in_row: this.mainInfo.minimum_breaks_in_row,
            maximum_breaks_in_row: this.mainInfo.maximum_breaks_in_row,
            minimum_break_between_shifts: this.mainInfo.minimum_break_between_shifts,
            minimum_break_between_shifts_for_women: this.mainInfo.minimum_break_between_shifts_for_women,
            minimum_break_in_row_per_week: this.mainInfo.minimum_break_in_row_per_week,
            minimum_shift_duration: this.mainInfo.minimum_shift_duration,
            maximum_shift_duration: this.mainInfo.maximum_shift_duration,
            minimum_coverage: this.mainInfo.minimum_coverage,
            maximum_coverage: this.mainInfo.maximum_coverage,
            create_templates: this.mainInfo.create_templates,
            scheduleDemand: this.mainInfo.scheduleDemand,
            shift_exchange: this.mainInfo.shiftsExchange,
            shift_exchange_confirmation: this.mainInfo.managerConfirmation,
            shift_exchange_same_position_only: this.mainInfo.shift_exchange_same_position_only,
            allow_put_on_freeboard: this.mainInfo.putOnFreeBoard,
            allow_put_on_freeboard_confirmation: this.mainInfo.putOnFreeboardConfirmation,
            allow_shift_request: this.mainInfo.allowShiftRequest,
            allow_shift_request_confirmation: this.mainInfo.allowShiftRequestConfirmation,
            allow_break_request: this.mainInfo.allowBreakRequest,
            allow_break_request_confirmation: this.mainInfo.allowBreakRequestConfirmation,
            allow_split_shift_by_employee: this.mainInfo.allow_split_shift_by_employee,
            allow_take_from_freeboard: this.mainInfo.takeFromFreeboard,
            allow_take_from_freeboard_confirmation: this.mainInfo.takeFromFreeboardConfirmation,
            // bind_break: this.mainInfo.bind_break,
            link_shifts: this.mainInfo.linkShifts,
            allow_exchange_break_in_diff_templates: this.mainInfo.allow_exchange_break_in_diff_templates,
            allow_drop_to_employee: this.mainInfo.allow_drop_to_employee,
            allow_drop_to_employee_confirmation: this.mainInfo.allow_drop_to_employee_confirmation
          }
          this.$store.dispatch('patchSchedule', [this.scheduleState.scheduleId, schedule]).then((response) => {
            // console.log('y', response)
            this.toastSuccess(this.$i18n?.t('Schedule successfully edited'))
            this.$emit('updateMainInfo', response)
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },

    updateMainInfo () {
      // console.log(56, this.schedule)
      this.mainInfo = {
        title: this.scheduleState.mainInfo.title,
        color: this.scheduleState.mainInfo.color,
        checkAvailability: this.scheduleState.employees.checkAvailability,
        shiftsExchange: this.scheduleState.mainInfo.shiftsControl.shiftsExchange,
        visibleEveryone: this.scheduleState.mainInfo.shiftsControl.visibleEveryone,
        visible_everyone_type: this.scheduleState.mainInfo.shiftsControl.visible_everyone_type,
        managerConfirmation: this.scheduleState.mainInfo.shiftsControl.managerConfirmation,
        shift_exchange_same_position_only: this.scheduleState.mainInfo.shiftsControl.shift_exchange_same_position_only,
        week_overtime_control_type: this.scheduleState.mainInfo.shiftsControl.week_overtime_control_type,
        working_hours: this.scheduleState.mainInfo.shiftsControl.working_hours,
        break_between_shifts: this.scheduleState.mainInfo.shiftsControl.break_between_shifts,
        minimum_breaks_in_row: this.scheduleState.mainInfo.shiftsControl.minimum_breaks_in_row,
        maximum_breaks_in_row: this.scheduleState.mainInfo.shiftsControl.maximum_breaks_in_row,
        minimum_break_between_shifts: this.scheduleState.mainInfo.shiftsControl.minimum_break_between_shifts,
        minimum_break_between_shifts_for_women: this.scheduleState.mainInfo.shiftsControl.minimum_break_between_shifts_for_women,
        minimum_break_in_row_per_week: this.scheduleState.mainInfo.shiftsControl.minimum_break_in_row_per_week,
        minimum_shift_duration: this.scheduleState.mainInfo.shiftsControl.minimum_shift_duration,
        maximum_shift_duration: this.scheduleState.mainInfo.shiftsControl.maximum_shift_duration,
        minimum_coverage: this.scheduleState.mainInfo.shiftsControl.minimum_coverage,
        maximum_coverage: this.scheduleState.mainInfo.shiftsControl.maximum_coverage,
        create_templates: this.scheduleState.mainInfo.shiftsControl.create_templates,
        scheduleDemand: this.scheduleState.mainInfo.shiftsControl.scheduleDemand,
        putOnFreeBoard: this.scheduleState.mainInfo.shiftsControl.allow_put_on_freeboard,
        putOnFreeboardConfirmation: this.scheduleState.mainInfo.shiftsControl.allow_put_on_freeboard_confirmation,
        allowShiftRequest: this.scheduleState.mainInfo.shiftsControl.allow_shift_request,
        allowShiftRequestConfirmation: this.scheduleState.mainInfo.shiftsControl.allow_shift_request_confirmation,
        allowBreakRequest: this.scheduleState.mainInfo.shiftsControl.allow_break_request,
        allowBreakRequestConfirmation: this.scheduleState.mainInfo.shiftsControl.allow_break_request_confirmation,
        allow_split_shift_by_employee: this.scheduleState.mainInfo.shiftsControl.allow_split_shift_by_employee,
        takeFromFreeboard: this.scheduleState.mainInfo.shiftsControl.allow_take_from_freeboard,
        takeFromFreeboardConfirmation: this.scheduleState.mainInfo.shiftsControl.allow_take_from_freeboard_confirmation,
        // bind_break: this.schedule.mainInfo.shiftsControl.bind_break,
        linkShifts: this.scheduleState.mainInfo.shiftsControl.linkShifts,
        allow_exchange_break_in_diff_templates: this.scheduleState.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates,
        allow_drop_to_employee: this.scheduleState.mainInfo.shiftsControl.allow_drop_to_employee,
        allow_drop_to_employee_confirmation: this.scheduleState.mainInfo.shiftsControl.allow_drop_to_employee_confirmation
      }
    }
  }
}
</script>

<style scoped>

</style>
