<template>
  <div v-if="breaks.length > 0" class="mt-2">
    <div class="mb-2 font-weight-bold">
      {{$t('Breaks')}}:
    </div>
    <div v-for="shiftBreak in breaks" :key="shiftBreak.id">
<!--      <span class="project-color" :style="{ backgroundColor: shiftBreak.color }"></span>-->
      {{shiftBreak.break.title}}
      {{fromZoneToZone(shiftBreak.time_from, 'UTC', $store.getters.profileTimeZone).format(backendTimeFormat)}} -
      {{fromZoneToZone(shiftBreak.time_to, 'UTC', $store.getters.profileTimeZone).format(backendTimeFormat)}}
    </div>
  </div>
</template>

<script>
import momentMixin from '../../../mixins/mixinMoment'

export default {
  name: 'ShiftBreaks',
  mixins: [momentMixin],
  props: {
    breaks: Array
  }
}
</script>

<style scoped>
.project-color {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
