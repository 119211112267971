export default {
  methods: {
    noAvatar (user) {
      if (user) {
        const names = user.split(' ')
        let out = ''
        names.forEach(function (item) {
          out = out + item[0]
        })
        return out
      }
    },
    convertDate (date, time = false, full = false) {
      if (full === true) {
        if (time) {
          return this.toTimeZone(date).format(this.localeDateFormat) + ' ' + (time ? this.timeToTz(time) : '')
        }
        return this.toTimeZone(date).format(this.localeDateTimeFormat)
      }
      return this.toTimeZone(date).format(this.localeDateTimeFormat)
    }
  }
}
