<template>
  <Form ref="observer" v-slot="{ meta }" as="">
    <div class="row mt-1">
      <div class="col-lg-3">
        <ui-date-picker
          name="date_from"
          v-model="date_from"
          :picker-type="pickerType"
          :clearable="false"
          :picker-format='format'
          @change="(val) => date_from = val"
        />
      </div>
      <div class="col-lg-3">
        {{convertToDate(setting.date_to)}}
      </div>
      <div class="col-lg-3 d-flex justify-content-center">
        <ui-number-input
          name="year_limit"
          v-model="year_limit"
          :precision="0"
          :step="1"
          :min="0"
          :validation="{required: true, numeric: true, min_value: 0, max_value: 365}"
          @change="(val) => year_limit = val"
        />
      </div>
      <div class="col-lg-3 d-flex justify-content-center">
        <div class="row">
          <div class="col">
            <el-button
              size="default"
              type="success"
              circle
              :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
              @click="save"
            >
              <i class="icon-check" />
            </el-button>
          </div>
          <div class="col">
            <el-button
              size="default"
              type="danger"
              icon="Delete"
              circle
              @click="deleteSetting"
            />
          </div>
          <div class="col">
            <el-button
              size="default"
              type="warning"
              circle
              @click="cancel"
            >
              <i class="icon-x" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import moment from 'moment/moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'SettingsEdit',
  mixins: [momentMixin],
  data () {
    return {
      id: null,
      date_from: moment(),
      year_limit: 14
    }
  },
  props: {
    setting: Object,
    employeeId: Number,
    pickerType: String
  },
  created () {
    this.id = this.setting.id
    this.date_from = this.setting.date_from
    this.year_limit = this.setting.year_limit
  },
  computed: {
    format () {
      return this.pickerType === 'month' ? this.localeMonthYearFormatExtendedUi : this.localeDateFormatElementUi
    }
  },
  methods: {
    convertToDate (val) {
      if (val) {
        return moment(val).locale(this.$store.getters.locale).format(this.localeMonthYearFormatExtended)
      }
      return null
    },
    async save () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        const payload = {
          id: this.id,
          date_from: this.date_from,
          year_limit: this.year_limit
        }
        payload.date_from = moment(payload.date_from).format('yyyy-MM-DD')
        this.$store.dispatch('updateSetting', [
          this.$store.getters.companyId,
          this.employeeId,
          payload
        ]).then(() => {
          this.$eventBus.emit('reloadSettings')
        })
          .catch(err => {
            this.toastError(this.$i18n?.t(err.response.data.message))
          })
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    deleteSetting () {
      this.$confirm(this.$t('CONFIRM_DELETE_VACATION_SETTING'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteSetting', [
          this.$store.getters.companyId,
          this.employeeId,
          this.id
        ]).then(() => {
          this.$eventBus.emit('reloadSettings')
        })
          .catch(err => {
            this.toastError(this.$i18n?.t(err.response.data.message))
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
