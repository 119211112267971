import { HTTP } from '@/api/http_utils'

const UPDATE_BREAKS_LIST = 'UPDATE_BREAKS_LIST'
const UPDATE_BREAK = 'UPDATE_BREAK'
const DELETE_BREAK = 'DELETE_BREAK'

const state = {
  breaksIndexed: {}
}

// let promises = {}

const getters = {
  breaks: state => Object.values(state.breaksIndexed),
  breaksIndexed: state => state.breaksIndexed
}

const mutations = {
  [UPDATE_BREAKS_LIST] (state, breaks) {
    const breaksById = {}
    breaks.forEach(item => {
      breaksById['break__sp__' + item.id] = item
    })
    state.breaksIndexed = breaksById
  },
  [UPDATE_BREAK] (state, payload) {
    state.breaksIndexed['break__sp__' + payload.id] = payload
  },
  [DELETE_BREAK] (state, breakId) {
    delete state.breaksIndexed['break__sp__' + breakId]
  }
}

const actions = {
  updateBreaksList ({ commit }, breaks) {
    commit(UPDATE_BREAKS_LIST, breaks)
  },
  updateBreak ({ commit }, payload) {
    commit(UPDATE_BREAK, payload)
  },
  getBreaks ({ commit }, [projectId, queryBreaks]) {
    // эта срань глючит при переключении между вьюхами и вообще бессмысленная и бесполезная херня
    // const promiseKey = `/projects/${projectId}/breaks${queryBreaks}`
    //
    // if (promises[promiseKey]) {
    //   console.error('repeating requests breaks')
    //   return promises[promiseKey]
    // }
    // let promise = new Promise((resolve, reject) => {
    //   HTTP.get(`/projects/${projectId}/breaks${queryBreaks}`).then(response => {
    //     commit(UPDATE_BREAKS_LIST, response)
    //     resolve(response)
    //   }).catch(err => {
    //     reject(err)
    //   })
    // })
    //
    // promises[promiseKey] = promise
    //
    // return promise
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/breaks${queryBreaks}`).then(response => {
        commit(UPDATE_BREAKS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getBreaksByCompany ({ commit }, [companyId, queryBreaks]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/company/${companyId}/breaks${queryBreaks}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getBreaksToExchange ({ commit }, [projectId, breakId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/breaks/${breakId}/exchange`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  exchangeBreaks ({ commit }, [projectId, breakId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/projects/${projectId}/breaks/${breakId}/exchange`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  updateBreaks ({ commit, dispatch }, [projectId, breakId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/projects/${projectId}/breaks/${breakId}`, data).then(response => {
        commit(UPDATE_BREAK, response)
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteBreak ({ commit, dispatch }, [projectId, breakId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/projects/${projectId}/breaks/${breakId}`).then(response => {
        commit(DELETE_BREAK, breakId)
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  createBreaks ({ commit, dispatch }, [shiftId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/shifts/${shiftId}/breaks`, payload).then(response => {
        response.forEach(breakItem => {
          commit(UPDATE_BREAK, breakItem)
        })
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
