<template>
  <div class="row pt-5">
    <div class="col-lg-4"></div>
    <div class="col-lg-4 text-center">
      <div>
        <i class="icon-smile text-primary font-weight-bold"></i>
      </div>
      <div class="h5 mt-4">
        {{ $t("Your QuickBook has been successfully connected!") }}
      </div>
      <div class="mt-2 small">
        {{ $t("Redirect in 5 sec") }}
      </div>
      <div class="mt-3" v-if="quickBookCompanyApp">
        <router-link :to="'/c/'+ company + '/app-marketplace/' + quickBookCompanyApp.app_id">
            {{ $t('Return to Company settings') }}
        </router-link>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </div>
</template>

<script>

export default {
  name: 'QuickBookRedirectPage',
  data () {
    return {
      quickBookCompanyApp: null
    }
  },
  created () {
    const data = {
      code: this.$route.query.code,
      state: this.$route.query.state,
      realm_id: this.$route.query.realmId
    }
    this.$store.dispatch('getCompanyApps').then(response => {
      const quickBookCompanyApp = response.find(item => item.app_id === 1)
      this.quickBookCompanyApp = quickBookCompanyApp
      if (quickBookCompanyApp) {
        this.$store.dispatch('setCompanyAppAuth', [quickBookCompanyApp.id, data]).then(response => {
          setTimeout(() => this.$router.push(`/c/${this.company}/app-marketplace/${quickBookCompanyApp.app_id}`), 5000)
        })
      }
    })
  },
  computed: {
    company () {
      return this.$store.getters.companyId
    }
  }
}
</script>

<style scoped>

</style>
