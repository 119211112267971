<template>
  <Form ref="observer" as="div">
  <modal-component :id="'AddTemplate'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title">{{ $t('Add template')}}</h5>
    </template>
    <template #body>
      <div class="row">
        <div class="col">
          <ui-select
            name="schedule"
            :label="$t('SELECT_SCHEDULE_TITLE')"
            v-model="selectedSchedule"
            class="w-100"
            :options="scheduleChoices"
            :validation="{required: true}"
          />
        </div>
      </div>
      <div class="">
        <template-component
          v-if="template"
          :template="template"
          :selectedDays="selectedDays"
          :index="index"
          :customClass="'col-8'"
          :customClass2="'col-4'"
          :type="type"
          :validate="validate"
          :showAdvanced="showAdvanced"
          :dummyInfo="breakModuleStatus"
          :project="projectId"
          @validationResult="createTemplate"
          @validateTemplates="validateTemplates">
        </template-component>
      </div>
    </template>
    <template #footer>
      <div class="mx-auto text-right w-100">
        <button
          class="btn btn-success rounded-pill shadow-sm"
          @click="switchValidator"
        >
          {{ $t('Add') }}
        </button>
      </div>
    </template>
  </modal-component>
  </Form>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import TemplateComponent from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/TemplateComponent'
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  components: {
    TemplateComponent,
    ModalComponent: ModalComponent
  },
  mixins: [
    ToastMixin,
    momentMixin
  ],
  name: 'AddTemplateModal',
  data () {
    return {
      template: {
        id: null,
        name: '',
        time_from: '09:00',
        time_to: '18:00',
        break_time: 60,
        color: '#4e93e9',
        by_days: false,
        night_shift: false,
        allow_break: false,
        users: [],
        workplacesTotal: 1,
        breaks: [],
        workplaces: [
          1, 1, 1, 1, 1, 1, 1
        ],
        rate_per_shift: 0
      },
      selectedDays: [
        {
          long: this.$i18n?.t('Monday'),
          short: this.$i18n?.t('Mon'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 0
        },
        {
          long: this.$i18n?.t('Tuesday'),
          short: this.$i18n?.t('Tue'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 1
        },
        {
          long: this.$i18n?.t('Wednesday'),
          short: this.$i18n?.t('Wed'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 2
        },
        {
          long: this.$i18n?.t('Thursday'),
          short: this.$i18n?.t('Thu'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 3
        },
        {
          long: this.$i18n?.t('Friday'),
          short: this.$i18n?.t('Fri'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 4
        },
        {
          long: this.$i18n?.t('Saturday'),
          short: this.$i18n?.t('Sat'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 5
        },
        {
          long: this.$i18n?.t('Sunday'),
          short: this.$i18n?.t('Sun'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 6
        }
      ],
      index: 0,
      type: 'manually',
      validate: false,
      showAdvanced: true,
      selectedSchedule: null,
      scheduleChoices: [],
      breakModuleStatus: {
        active: true
      },
      load: false,
      validationErrors: 0
    }
  },
  computed: {
    ...mapGetters({ locale: 'locale' }),
    companySalary () {
      return this.$store.getters.company.salary
    }
  },
  props: {
    projectId: Number,
    schedules: Object
  },
  created () {
    this.template.rate_per_shift = this.companySalary.basic_shift_rate ? this.companySalary.basic_shift_rate : 0
    this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then(response => {
      this.breakModuleStatus = response
    }).catch((error) => {
      if (error.response.data.module) {
        // console.log(error.response.data.module, this.locale)
        this.breakModuleStatus = {
          title: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].name : error.response.data.module.name,
          trial: error.response.data.module.trial_period,
          price: +error.response.data.module.price,
          perUser: +error.response.data.module.user_price,
          isActive: error.response.data.module.is_active,
          id: +error.response.data.module.id,
          helpCenter: error.response.data.module.help_center,
          description: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].short_description : error.response.data.module.short_description
        }
      }
    })
    this.scheduleChoices = []
    Object.keys(this.schedules).forEach(scheduleId => {
      const selectOption = { id: +scheduleId, name: this.schedules[scheduleId].name }
      this.scheduleChoices.push(selectOption)
    })
    if (this.scheduleChoices.length > 0) {
      this.selectedSchedule = this.scheduleChoices[0].id
      this.type = this.schedules[this.scheduleChoices[0].id].schedule_type
    }
  },
  watch: {
    selectedSchedule () {
      this.updateScheduleType(this.selectedSchedule)
    }
  },
  methods: {
    updateScheduleType (scheduleId) {
      if (scheduleId) {
        this.type = this.schedules[scheduleId].schedule_type
      }
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('AddTemplate')
    },
    validateTemplates () {
      this.validationErrors++
    },
    switchValidator () {
      this.validationErrors = 0
      this.validate = !this.validate
    },
    createTemplate (val) {
      this.$refs.observer.validate().then(isValid => {
        if (isValid.valid && val && this.validationErrors === 0) {
          this.handleTemplate()
        }
        this.switchValidator()
      })
    },
    handleTemplate () {
      delete this.template.by_days
      delete this.template.workplacesTotal
      if (this.template.time_to === '24:00') {
        this.template.time_to = '00:00'
      }
      this.template.schedule_id = this.selectedSchedule
      this.template.break_time = +this.template.break_time
      this.$store.dispatch('saveTemplate', [this.template.schedule_id, this.template]).then(() => {
        this.$eventBus.emit('updateTemplate')
        this.closeModal()
      }).catch(error => {
        this.toastError(this.$i18n?.t(error.response.data.message))
        this.closeModal()
      })
    }
  }
}
</script>

<style scoped>

</style>
