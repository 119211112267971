<template>
  <div class="row pt-5">
    <div class="col-lg-4"></div>
    <div class="col-lg-4 text-center">
      <div>
        <i class="icon-smile text-primary font-weight-bold"></i>
      </div>
      <div class="h5 mt-4">
        {{ $t("Your payment is accepted for processing!") }}
      </div>
      <div class="mt-3">
        {{ $t("The request is going to be processed within 24 hours.") }}
      </div>
      <div class="mt-2 small">
        {{ $t("Redirect in 5 sec") }}
      </div>
      <div class="mt-3">
        <router-link :to="'/company/'+ company + '#balance'">
            {{ $t('Return to Company Balance') }}
        </router-link>
      </div>
    </div>
    <div class="col-lg-4"></div>
  </div>
</template>

<script>

export default {
  name: 'PayPalSuccessRedirectPage',
  created () {
    const data = {
      paymentId: this.$route.query.paymentId,
      token: this.$route.query.token,
      PayerID: this.$route.query.PayerID
    }
    this.$store.dispatch('setPayPalSuccess', [this.$store.getters.companyId, data]).then(response => {
      this.$store.dispatch('clearCompanyStore')
      this.$store.dispatch('getCompany', this.$store.getters.companyId)
    })
    setTimeout(() => this.$router.push({ path: '/c/' + this.company + '#balance' }), 5000)
  },
  computed: {
    company () {
      return this.$store.getters.companyId
    }
  }
}
</script>

<style scoped>

</style>
