<template>
  <div>
    <calculator-modal-component
      :templates="calculatorTemplates"
      :preloadedTemplates="calculatorPreloadedTemplates"
      :days="days"
      v-if="calculatorModal"
    />
    <!-- TABS MENU -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{'active': activeTab === 'template' }"
          id="template-tab"
          type="button"
          role="tab"
          @click="tabAction('template')"
          aria-controls="template"
          aria-selected="true">
            {{ $t('Templates') }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{'disabled': !['advanced', 'by_availability'].includes(type), 'show active': activeTab === 'demand' }"
          id="demand-tab"
          type="button"
          role="tab"
          @click="tabAction('demand')"
          aria-controls="demand"
          aria-selected="false">
            {{ $t('Load demand') }}
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <!-- TEMPLATE -->
      <div class="tab-pane fade" :class="{'show active': activeTab === 'template' }" id="template" role="tabpanel" aria-labelledby="template-tab">
        <div v-if="activeTab === 'template'" class="row mt-2" data-cy="schedule-settings">
          <div class="col-lg-12">
            <span class="text-primary text-uppercase font-weight-bold">{{ $t('Working days') }}</span>
            <ui-hint :content="$t('WORKING_DAYS_HINT')" />
          </div>
          <div class="col-auto dayz" v-for="(day, index) in days" :key="index">
            <ui-checkbox
              :name="index + '__dayz'"
              v-model="day.selected"
              :disabled="day.disabled"
              :container-class="'d-inline mx-2'"
            >
              {{ day.short }}
            </ui-checkbox>
          </div>
          <div class="col-lg-12">
            <span class="invalid-feedback mb-4" :style="{display: showErrors.days ? 'block' : 'none'}">{{ $t('Select at least one day') }}</span>
          </div>
          <div v-if="scheduleState.scheduleId" class="col-lg-4">
            <label class="control-label font-weight-bold d-flex justify-content-between align-items-end">
              <span>
                {{ $t('Select a shift template (if there is one)') }}
                <ui-hint :content="$t('SELECT_SHIFT_TEMPLATE_HINT')" />
              </span>
              <span v-if="type !== 'simple'" @click="selectAllTemplates()" class="small text-primary font-weight-bold" style="cursor: pointer">
                {{$t('SELECT_ALL_TEMPLATES')}}
              </span>
            </label>
            <ui-multi-select
              name="templates"
              id="schedule_pattern"
              :disabled="disableAddTemplates"
              v-model="preloadedTemplates.ids"
              :options="Object.values(preloadedTemplates.templates)"
              keyName="id"
              valueName="id"
              labelName="name"
            />
          </div>
          <div class="col-lg-4 d-flex align-items-center">
            <template v-if="type === 'by_availability' || type ==='advanced'">
              <button
                id="calculator"
                data-cy="open-calculator"
                @click="openCalculator"
                data-toggle="modal"
                data-target="#CalculatorModal"
                :disabled="disableAddTemplates || (templates.length + preloadedTemplates.ids.length) < 1"
                class="btn btn-outline-primary">
                {{$t('CALCULATOR_TITLE')}}
              </button>
              <ui-hint :content="$t('SELECT_AT_LEAST_ONE_TEMPLATE_HINT')" />
            </template>
          </div>
          <div class="col-lg-4"></div>
          <div class="col-lg-12">
            <div class="font-weight-bold mt-2">
              {{ $t('Add Shifts templates') }}
              <ui-hint :content="$t('ADD_SHIFTS_TEMPLATE_HINT')" />
            </div>
            <div class="row">
              <template v-if="isTemplateReady">
                <div v-for="(templateId, index) in preloadedTemplates.ids" class="col-lg-6 d-flex align-items-stretch my-2" :key="index + '_preloaded'">
                  <div class="card w-100">
                    <div class="card-header text-right bg-light p-0">
                      <button @click="removeTemplateById(templateId)" class="btn">
                        <i class="icon-x size-lg"></i>
                      </button>
                    </div>
                    <div class="card-body text-center">
                      <shift-template
                        :key="templateId"
                        :template="preloadedTemplates.templates[templateId]"
                        :selectedDays="days"
                        :index="templateId"
                        :type="type"
                        :validate="validate"
                        :patternType="patternType"
                        :showAdvanced="true"
                        :dummyInfo="breakModuleStatus"
                        @updateTemplateBreaks="updateTemplateBreaks"
                        @activateBreaks="activateBreaks"
                        @validateTemplates="validateTemplates">
                      </shift-template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="templates.length > 0">
                <div v-for="(template, index) in templates" class="col-lg-6 d-flex align-items-stretch my-2" :key="index + '_new'">
                  <div class="card w-100">
                    <div class="card-header text-right bg-light p-0">
                      <button @click="removeTemplate(index)" class="btn">
                        <i class="icon-x size-lg"></i>
                      </button>
                    </div>
                    <div class="card-body text-center">
                      <shift-template
                        :key="index"
                        :template="template"
                        :selectedDays="days"
                        :index="index"
                        :project="project"
                        :type="type"
                        :patternType="patternType"
                        :validate="validate"
                        :showAdvanced="true"
                        :dummyInfo="breakModuleStatus"
                        @activateBreaks="activateBreaks"
                        @validateTemplates="validateTemplates">
                      </shift-template>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="!disableAddTemplates" class="col-lg-6 d-flex align-items-stretch my-2" style="min-height: 295px">
                <div class="card w-100 h-100">
                  <div class="card-body text-center p-0">
                    <button
                      id="demo_master_create_template"
                      data-cy="add-template"
                      @click="addTemplate"
                      :disabled="disableAddTemplates"
                      class="btn bg-light w-100 h-100">
                      <i class="icon-plus text-primary" style="font-size: 48px"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DEMAND -->
      <div class="tab-pane fade" :class="{'show active': activeTab === 'demand' }" id="demand" role="tabpanel" aria-labelledby="demand-tab">
        <demand-component
          v-if="activeTab === 'demand'"
          :projectId="project"
          :schedule="scheduleState"
          :shiftsControl="shiftsControlState"
          :validate="validateDemand"
          @validationResult="setDemandValidationResult"
          />
      </div>
    </div>
    <!-- ACTION BUTTONS -->
    <div class="col-lg-12">
    <el-alert
      v-if="showErrors.templates && activeTab === 'template'"
      :title="$t('Select or create at least one template')"
      type="error"
      effect="dark"
      :closable="false"
      class="mb-2"
    />
    <el-alert
      v-if="showErrors.noDemand && activeTab === 'demand' && shiftsControlState.scheduleDemand.length === 0"
      :title="$t('No load demand on the schedule period')"
      type="error"
      effect="dark"
      :closable="false"
      class="mb-2"
    />
    <el-alert
      v-if="showErrors.templatesSimple"
      :title="$t('Only a single template can be used for this schedule type')"
      type="error"
      effect="dark"
      :closable="false"
      class="mb-2"
    />
    </div>
    <div class="col-lg-12 d-inline-flex py-2">
      <button
        id="demo_master_prev_step"
        class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
        @click="prev">
        {{ $t('Previous')}}
      </button>
      <button
        id="demo_master_next_step"
        :disabled="type === 'by_pattern' && ['2/2/alternation', 'day/night/48'].includes(patternType) && !disableAddTemplates"
        data-cy="next-step"
        class="btn btn-primary rounded-pill shadow-sm"
        @click="next">
        {{ $t('Next step')}}
      </button>
    </div>
  </div>
</template>

<script>
import colorMixin from '@/mixins/mixinColors'
import momentMixin from '@/mixins/mixinMoment'
import ShiftTemplate from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/TemplateComponent'
import DemandComponent from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/DemandComponent'
import CalculatorModalComponent from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/CalculatorModalComponent'
import { toRaw } from 'vue'
import isEqual from 'lodash/isEqual'

export default {
  name: 'ScheduleSettings',
  components: {
    CalculatorModalComponent,
    DemandComponent,
    ShiftTemplate: ShiftTemplate
  },
  mixins: [momentMixin, colorMixin],
  data () {
    return {
      days: [
        {
          long: this.$i18n?.t('Monday'),
          short: this.$i18n?.t('Mon'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 0
        },
        {
          long: this.$i18n?.t('Tuesday'),
          short: this.$i18n?.t('Tue'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 1
        },
        {
          long: this.$i18n?.t('Wednesday'),
          short: this.$i18n?.t('Wed'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 2
        },
        {
          long: this.$i18n?.t('Thursday'),
          short: this.$i18n?.t('Thu'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 3
        },
        {
          long: this.$i18n?.t('Friday'),
          short: this.$i18n?.t('Fri'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 4
        },
        {
          long: this.$i18n?.t('Saturday'),
          short: this.$i18n?.t('Sat'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 5
        },
        {
          long: this.$i18n?.t('Sunday'),
          short: this.$i18n?.t('Sun'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 6
        }
      ],
      validate: false, // props для компонента темплейта - команда на валидацию формы
      preloadedTemplates: {
        ids: [],
        templates: {}
      },
      activeTab: 'template',
      validateBreaks: false,
      validateDemand: false,
      demandValidationResult: 0,
      templates: [],
      isTemplateReady: false,
      templateErrors: 0,
      showErrors: {
        days: false,
        templates: false,
        templatesSimple: false,
        noDemand: false
      },
      weekStart: 0,
      breakModuleStatus: {
        active: true
      },
      calculatorModal: false,
      calculatorTemplates: [],
      calculatorPreloadedTemplates: {},
      tempShiftsControl: {},
      settingsState: {},
      scheduleState: {},
      shiftsControlState: {}
    }
  },
  props: {
    project: Number,
    settings: Object,
    type: String,
    patternType: String,
    shiftsControl: Object,
    schedule: Object
  },
  created () {
    this.scheduleState = this.schedule
    this.settingsState = this.settings
    this.shiftsControlState = this.shiftsControl
    this.tempDemand = JSON.parse(JSON.stringify(this.shiftsControlState.scheduleDemand))

    if (this.scheduleState.scheduleSettings.activeTab) {
      this.activeTab = this.scheduleState.scheduleSettings.activeTab
    }
    this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then(response => {
      this.breakModuleStatus = response
    }).catch((error) => {
      if (error.response.data.module) {
        this.breakModuleStatus = {
          title: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].name : error.response.data.module.name,
          trial: error.response.data.module.trial_period,
          price: +error.response.data.module.price,
          perUser: +error.response.data.module.user_price,
          isActive: error.response.data.module.is_active,
          id: +error.response.data.module.id,
          helpCenter: error.response.data.module.help_center,
          description: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].short_description : error.response.data.module.short_description
        }
      }
    })
    this.weekStart = this.companySalary.week_start || 0
    if (Object.keys(toRaw(this.settingsState)).length > 0) {
      if (this.type !== 'by_pattern') {
        this.days = []
        Object.keys(this.settingsState.days).forEach(key => {
          this.days.push(this.settingsState.days[key])
        })
      }
      // переставляем местами ворклейсы на каждый день, в зависимости от настроек дня начала недели
      const templates = []
      this.settingsState.templates.forEach(template => {
        const clonedTemplate = JSON.parse(JSON.stringify(template))
        const x = clonedTemplate.workplaces.splice(this.weekStart)
        clonedTemplate.workplaces = x.concat(clonedTemplate.workplaces)
        templates.push(clonedTemplate)
      })

      this.templates = templates
      this.preloadedTemplates.ids = this.settingsState.preloadedTemplates
    }
    // перестраиваем порядок дней недели, начиная с дня, который задан как день начала недели в настройках компании
    const weekPart = this.days.splice(this.weekStart)
    this.days = weekPart.concat(this.days)
    if (this.scheduleState.scheduleId) {
      this.$store.dispatch('getScheduleTemplates', this.scheduleState.scheduleId).then((templates) => {
        templates.forEach(template => {
          let workplaces = [1, 1, 1, 1, 1, 1, 1]
          let workplacesTotal = 1
          let breaks = []
          let byDays = false
          let allowBreak = false
          if (this.scheduleState.breaks) {
            breaks = this.scheduleState.breaks.filter(function (el) { return el.template_id === template.id })
          }

          if (this.settingsState.preloadedTemplatesData && this.settingsState.preloadedTemplatesData[template.id]) {
            workplaces = this.settingsState.preloadedTemplatesData[template.id].workplaces
            if ([...new Set(this.settingsState.preloadedTemplatesData[template.id].workplaces)].length > 1) byDays = true
            workplacesTotal = workplaces[0]
            allowBreak = this.scheduleState.allow_break
            this.settingsState.preloadedTemplatesData[template.id].by_days = byDays
            this.settingsState.preloadedTemplatesData[template.id].workplacesTotal = workplacesTotal
          }

          template.by_days = byDays
          template.allow_break = allowBreak
          template.pivot = {}
          template.breaks = breaks
          template.workplacesTotal = workplacesTotal
          if (!template.is_hide) {
            this.preloadedTemplates.templates[template.id] = template
          }
        })
        this.preloadedTemplates.ids.forEach(id => {
          if (!this.preloadedTemplates.templates[id]) {
            this.preloadedTemplates.ids.splice(this.preloadedTemplates.ids.indexOf(id), 1)
          }
        })
        this.isTemplateReady = true
      })
    }
  },
  computed: {
    companySalary () {
      return this.$store.state.company.company.salary || {}
    },
    locale () {
      return this.$store.getters.locale
    },
    templatesNumber () {
      return this.templates.length + this.preloadedTemplates.ids.length
    },
    daysNumber () {
      let selected = 0
      Object.keys(this.days).forEach(key => {
        if (this.days[key].selected) {
          selected++
        }
      })
      return selected
    },
    disableAddTemplates () {
      if ((this.type === 'simple' && this.templatesNumber > 0) || (this.type === 'by_pattern' && ['day/night/48', '2/2/alternation'].includes(this.patternType) && this.templatesNumber === 2)) {
        return true
      }
      return false
    }
  },
  watch: {
    schedule () {
      this.scheduleState = this.schedule
    },
    settings () {
      this.settingsState = this.settings
    },
    shiftsControl () {
      this.shiftsControlState = this.shiftsControl
    },
    templatesNumber (value) {
      if (value > 0) {
        this.showErrors.templates = false
      }
      if (this.type === 'simple' && value > 1) {
        this.showErrors.templatesSimple = true
      }
      if (this.type === 'simple' && value <= 1) {
        this.showErrors.templatesSimple = false
      }
    },
    daysNumber (value) {
      if (value > 0) {
        this.showErrors.days = false
      }
    }
  },
  methods: {
    setDemandValidationResult (result) {
      this.demandValidationResult = result
    },
    tabAction (tab) {
      // при переключении с таба настройки тесплейтов, проверяем что темплейты валидные
      if (this.activeTab === 'template') {
        if (this.templates.length > 0) {
          this.validate = true
          this.scheduleState.breaks = []
          // todo устанавливаем таймаут чтобы успели провалидироваться дочерние компоненты темплейтов и вернуть ответ родителю, может можно это сделать менее костыльно
          setTimeout(() => {
            if (this.isValid()) {
              this.validate = false
              this.hideErrors()
              this.templateErrors = 0
              this.activeTab = tab
            } else {
              this.activeTab = 'template'
              this.displayErrors()
            }
            this.validate = false
            this.templateErrors = 0
          }, 100)
        } else {
          this.activeTab = tab
        }
      } else {
        this.validateDemand = true
        setTimeout(() => {
          if (this.isValidDemand()) {
            this.validateDemand = false
            this.hideErrors()
            this.demandValidationResult = false
            this.activeTab = tab
          } else {
            this.activeTab = 'demand'
            this.displayErrors()
          }
          this.validateDemand = false
          this.demandValidationResult = false
        }, 100)
        // this.activeTab = tab
      }
    },
    openCalculator () {
      this.calculatorTemplates = []
      this.calculatorPreloadedTemplates = []
      const calculatorPreloadedTemplates = []
      this.calculatorModal = true
      this.calculatorTemplates = this.templates
      this.preloadedTemplates.ids.forEach(id => {
        calculatorPreloadedTemplates.push(this.preloadedTemplates.templates[id])
      })
      this.calculatorPreloadedTemplates = calculatorPreloadedTemplates
    },
    updateTemplateBreaks (template) {
      this.scheduleState.allow_break = template.allow_break
      this.scheduleState.breaks = this.scheduleState.breaks.concat(template.breaks)
    },
    selectAllTemplates () {
      this.preloadedTemplates.ids = []
      this.preloadedTemplates.ids = Object.keys(this.preloadedTemplates.templates).map(value => +value)
    },
    next () {
      // when moving to the next stage of the wizard, we validate the data regarding the tab we are on
      if (this.activeTab === 'template') {
        this.validate = true
        this.scheduleState.breaks = []
        // todo устанавливаем таймаут чтобы успели провалидироваться дочерние компоненты темплейтов и вернуть ответ родителю, может можно это сделать менее костыльно
        setTimeout(() => {
          if (this.isValid()) {
            this.validate = false
            this.hideErrors()
            this.templateErrors = 0
            this.$emit('next', this.getDataNavigate())
          } else {
            this.displayErrors()
          }
          this.validate = false
          this.templateErrors = 0
        }, 100)
      } else {
        this.validateDemand = true
        setTimeout(() => {
          if (!this.isValidDemand() || this.shiftsControlState.scheduleDemand.length === 0) {
            this.displayErrors()
          } else {
            this.validateDemand = false
            this.hideErrors()
            this.demandValidationResult = false
            const currentDemand = JSON.parse(JSON.stringify(this.shiftsControlState.scheduleDemand))
            if (!isEqual(this.tempDemand, currentDemand)) {
              this.shiftsControlState.needReloadTemplates = true
            } else {
              this.shiftsControlState.needReloadTemplates = false
            }

            this.$emit('next', this.getDataNavigate())
          }
          this.validateDemand = false
          this.demandValidationResult = false
        }, 100)
      }
    },
    isValid () {
      if (this.templateErrors > 0) {
        return false
      }
      if (this.daysNumber === 0) {
        return false
      }
      if (this.templatesNumber === 0) {
        return false
      }
      if (this.type === 'simple' && this.templatesNumber > 1) {
        return false
      }
      return true
    },
    isValidDemand () {
      return this.demandValidationResult
    },
    prev () {
      this.validate = false
      this.hideErrors()
      this.templateErrors = 0
      this.$emit('prev', this.getDataNavigate())
    },
    hideErrors () {
      this.showErrors.days = false
      this.showErrors.templates = false
      this.showErrors.templatesSimple = false
    },
    displayErrors () {
      if (this.templatesNumber === 0) {
        this.showErrors.templates = true
      }
      if (this.daysNumber === 0) {
        this.showErrors.days = true
      }
      if (this.type === 'simple' && this.templatesNumber > 1) {
        this.showErrors.templatesSimple = true
      }
      if (this.activeTab === 'demand' && this.shiftsControlState.scheduleDemand.length === 0) {
        this.showErrors.noDemand = true
      }
    },
    activateBreaks () {
      this.breakModuleStatus = { active: true }
    },
    validateTemplates () {
      this.templateErrors++
    },
    getDataNavigate () {
      // <-- start перестраиваем порядок дней и ворклейсов в новых и предзагруженых темплейтах, так чтобы понедельник соответствовал 0 индексу
      const days = {}
      this.days.forEach(day => {
        days[day.index] = day
      })
      const templates = []
      this.templates.forEach(template => {
        const clonedTemplate = JSON.parse(JSON.stringify(template))
        const x = clonedTemplate.workplaces.splice(7 - this.weekStart)
        clonedTemplate.workplaces = x.concat(clonedTemplate.workplaces)
        templates.push(clonedTemplate)
      })
      const preloadedTemplatesData = {}
      Object.keys(this.preloadedTemplates.templates).forEach(key => {
        const clonedTemplate = JSON.parse(JSON.stringify(this.preloadedTemplates.templates[key]))
        const x = clonedTemplate.workplaces.splice(7 - this.weekStart)
        clonedTemplate.workplaces = x.concat(clonedTemplate.workplaces)
        preloadedTemplatesData[key] = clonedTemplate
      })
      return {
        step: 'scheduleSettings',
        data: {
          days: days,
          templates: templates,
          preloadedTemplates: this.preloadedTemplates.ids,
          preloadedTemplatesData: preloadedTemplatesData,
          activeTab: this.activeTab
        }
      }
    },
    addTemplate () {
      const newTemplate = {
        id: null,
        name: '',
        break_time: 60,
        color: this.colorsChoices[Math.floor(Math.random() * this.colorsChoices.length)],
        by_days: false,
        night_shift: false,
        allow_break: false,
        workplacesTotal: 1,
        workplaces: [
          1, 1, 1, 1, 1, 1, 1
        ],
        breaks: [
          //
        ],
        users: [],
        rate_per_shift: this.companySalary.basic_shift_rate || 0
      }
      newTemplate.time_from = '09:00'
      newTemplate.time_to = '18:00'
      this.templates.push(newTemplate)
      this.isTemplateReady = true
    },
    getPreloadedTemplate (templateId) {
      return this.preloadedTemplates.templates[templateId]
    },
    removeTemplate (index) {
      this.templates.splice(parseInt(index), 1)
      const rules = []
      this.scheduleState.rules.forEach(element => {
        const updIndex = index + this.preloadedTemplates.ids
        if (!(element.objects.includes(updIndex) || element.action_objects.includes(updIndex))) {
          rules.push(element)
        }
      })
      this.scheduleState.rules = rules
    },
    removeTemplateById (id) {
      this.preloadedTemplates.ids.splice(this.preloadedTemplates.ids.indexOf(id), 1)
      const rules = []
      this.scheduleState.rules.forEach(element => {
        if (!(element.objects.includes(id) || element.action_objects.includes(id))) {
          rules.push(element)
        }
      })
      this.scheduleState.rules = rules
    }
  }
}
</script>

<style scoped>
.dayz :deep(.el-checkbox) {
  display: block !important;
}
.dayz :deep(.el-checkbox) > .el-checkbox__label {
  display: block !important;
  margin-left: 0px;
  padding-left: 0px;
}
.dayz :deep(.el-checkbox) > .el-checkbox__input {
  display: block !important;
}
</style>
