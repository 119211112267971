<template>
  <div v-if="projects.length > 0" class="row p-0 m-1">
    <div v-for="(project, i) in projects" v-bind:key="project.id" class="col-lg-4 p-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row">
            <div class="mb-1 col-auto card-title font-weight-bold">{{project.project_name}}</div>
            <div class="mb-1 col"></div>
            <div class="mb-1 col-auto" @click="setCurrentProject(project.project_id)">
              <router-link :to="`/c/${companyId}/p/${project.project_id}/schedule`">
                <span>
                  <i v-if="lang === 'he'" class="icon-chevron-left"></i>
                  {{$t('Project schedules')}}
                  <i v-if="lang !== 'he'" class="icon-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
          <div>
            <table class="table table-bordered">
              <thead>
                <tr class="text-center">
                  <th></th>
                  <th class="align-middle text-center">{{$t(weekDay(0)).toUpperCase()}} <br> {{today}}</th>
                  <th class="align-middle text-center">{{$t(weekDay(1)).toUpperCase()}} <br> {{tomorrow}}</th>
                  <th class="align-middle text-center">{{$t(weekDay(2)).toUpperCase()}} <br> {{afterTomorrow}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('Number of workers')}}</td>
                  <td class="text-center align-middle" v-for="(value, index) in project.users" v-bind:key="'users-' + i + '_' + index">
                    {{value}}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('Number of free shifts')}}</td>
                  <td class="text-center align-middle" v-for="(value, index) in project.free_board" v-bind:key="'shifts-' + i + '_' + index">
                    {{value}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ProjectStatistic',
  mixins: [momentMixin],
  data () {
    return {
      projects: [],
      from: ''
    }
  },
  created () {
    const currentStart = Moment().format(this.backendDateFormat)
    this.from = this.fromZoneToZone(currentStart, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
    this.$store.dispatch('getProjectsDashboard', [this.companyId, this.from]).then(result => {
      this.projects = result
    })
  },
  computed: {
    lang () {
      return this.$store.getters.locale
    },
    today () {
      return Moment().format(this.localeDateMonthFormat)
    },
    tomorrow () {
      return Moment().add(1, 'day').format(this.localeDateMonthFormat)
    },
    afterTomorrow () {
      return Moment().add(2, 'day').format(this.localeDateMonthFormat)
    }
  },
  methods: {
    setCurrentProject (projectId) {
      localStorage.setItem('currentProject', projectId)
    },
    weekDay (days) {
      return Moment.weekdaysMin(Moment().add(days, 'day').weekday())
    }
  }
}
</script>

<style scoped>

</style>
