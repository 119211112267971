import { HTTP } from '@/api/http_utils'

// let promises = {}
// let lastSuccessful = {}

export const COMPANY_MARKS = 'COMPANY_MARKS'
const UPDATE_PROJECTMARKS_LIST = 'UPDATE_PROJECTMARKS_LIST'
const UPDATE_PROJECTMARK = 'UPDATE_PROJECTMARK'
const DELETE_PROJECTMARK = 'DELETE_PROJECTMARK'

const state = {
  companyMarks: [],
  projectMarksIndex: {}
}

const getters = {
  companyMarks: state => state.companyMarks,

  projectMarks: state => Object.values(state.projectMarksIndex).sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  }),
  projectMarksCount: state => {
    return Object.values(state.projectMarksIndex).length
  },
  hasProjectMark: (state, getters) => {
    return getters.projectMarks > 0
  },
  projectMarkById: (state) => (id) => {
    return state.projectMarksIndex[id]
  }
}

const mutations = {
  [COMPANY_MARKS] (state, marks) {
    state.companyMarks = marks
  },
  [UPDATE_PROJECTMARKS_LIST] (state, payload) {
    Object.values(state.projectMarksIndex).forEach((item) => {
      delete state.projectMarksIndex[item.id]
    })
    payload.forEach(item => {
      state.projectMarksIndex[item.id] = item
    })
  },
  [UPDATE_PROJECTMARK] (state, projectmark) {
    state.projectMarksIndex[projectmark.id] = projectmark
  },
  [DELETE_PROJECTMARK] (state, projectmarkId) {
    delete state.projectMarksIndex[projectmarkId]
  }
}

const actions = {
  getMarksByCompany ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + companyId + '/marks').then(marks => {
        commit(COMPANY_MARKS, marks)
        resolve(marks)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getMarksByProject ({ commit }, [projectId, ignoreStore = false]) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + projectId + '/marks').then(marks => {
        if (!ignoreStore) {
          commit(UPDATE_PROJECTMARKS_LIST, marks)
        }
        resolve(marks)
      })
    })
  },

  createMark ({ commit }, [markData, projectId]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/projects/' + projectId + '/marks', markData).then(response => {
        commit(UPDATE_PROJECTMARK, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  editMarks ({ commit }, [markData, projectId, markId]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/projects/' + projectId + '/marks/' + markId, markData).then(response => {
        commit(UPDATE_PROJECTMARK, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteMark ({ commit }, [projectId, markId]) {
    return new Promise(resolve => {
      HTTP.delete('/projects/' + projectId + '/marks/' + markId).then(response => {
        commit(DELETE_PROJECTMARK, markId)
        resolve(response)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
