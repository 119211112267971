<template>
  <div>
    <div id="taskAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">{{ $t('TASK_SETTINGS_HEADER') }} <ui-hint v-if="moduleInfo" :content="moduleInfo"/></h5>
        </div>

        <div id="collapseTaskSettings" class="collapse show" aria-labelledby="headingOne" data-parent="#taskAccordion">
          <div class="card-body">
            <modal-component
              :id="'TaskSettingsModal'"
              @closeModal="closeModal"
              :modalClass="'modal-dummy'"
              :modalBodyClass="'dummy-popup'"
              :hideFooter="true"
              :hideHeader="true"
              >
              <template #body>
                <div>
                  <dummy-payment-required
                    @activate="activate"
                    :dummyInfo="dummyInfo"
                    :isModal="true">
                  </dummy-payment-required>
                </div>
              </template>
            </modal-component>
            <Form ref="observer" as="div" class="form-row" v-slot="{ errors }">
              <div class="col-auto" @click="handleActivation" :data-toggle="!isModuleActive ? 'modal' : null" :data-target="!isModuleActive ? '#TaskSettingsModal' : null">
                <div class="form-row">
                  <div class="col-lg-12">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('TASKS_MAIN_SETTINGS') }}
                      </span>
                    </el-divider>
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.enable_geolocation"
                      v-model="settings.enable_geolocation"
                      :disabled="!isModuleActive"
                      :label="$t('TASK_SETTINGS_ALLOW_GEOLOCATION')"
                      :hint="$t('TASK_SETTINGS_ALLOW_GEOLOCATION_HINT')"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.allow_free_geolocation_start"
                      v-model="settings.allow_free_geolocation_start"
                      :disabled="!isModuleActive"
                      :label="$t('TASK_SETTINGS_ALLOW_FREE_GEOLOCATION_START')"
                      :hint="$t('TASK_SETTINGS_ALLOW_FREE_GEOLOCATION_START_HINT')"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.check_gps"
                      v-model="settings.check_gps"
                      :disabled="!isModuleActive"
                      :label="$t('TASK_SETTINGS_CHECK_GPS_LOCATION')"
                      :hint="$t('TASK_SETTINGS_CHECK_GPS_LOCATION_HINT')"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.allow_starting_without_shifts"
                      v-model="settings.allow_starting_without_shifts"
                      :disabled="!isModuleActive"
                      :label="$t('ALLOW_STARTING_WITHOUT_SHIFTS')"
                      :hint="$t('ALLOW_STARTING_WITHOUT_SHIFTS_HINT')"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive || !settings.check_gps"
                      name="amount"
                      :label="$t('TASK_SETTINGS_RADIUS_FROM_ADDR')"
                      :hint="$t('TASK_SETTINGS_RADIUS_FROM_ADDR_HINT')"
                      v-model="settings.radius"
                      :validation="{required: true, integer: true, min_value: 0}"
                      :precision="0"
                      :step="1"
                      :min="0"
                    />
                  </div>
                  <div class="col-lg-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive"
                      name="geolocation_distance_filter"
                      :label="$t('GEOLOCATION_DISTANCE_FILTER')"
                      :hint="$t('GEOLOCATION_DISTANCE_FILTER_HINT')"
                      v-model="settings.geolocation_distance_filter"
                      :validation="{required: true, integer: true, min_value: 10}"
                      :precision="0"
                      :step="1"
                      :min="10"
                    />
                  </div>
                  <div class="col-lg-12 col-md-6 mt-2">
                    <ui-checkbox
                      name="settings.show_tasks"
                      v-model="settings.show_tasks"
                      :disabled="!isModuleActive"
                      :label="$t('Show tasks for all company users')"
                      :hint="$t('TASKS_SETTING_SHOW_TASKS_FOR_ALL_HINT')"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-2">
                    <label class="control-label font-weight-bold">
                      {{ $t("ADD_DEFAULT_CITY") }}
                      <ui-hint :content="$t('ADD_DEFAULT_CITY_HINT')"/>
                    </label>
                    <gmv-autocomplete
                      class="form-control w-100"
                      :placeholder="$t('Enter search address')"
                      :value="settings.default_city"
                      :language="locale"
                      @place_changed="(val) => settings.default_city = val.formatted_address">
                    </gmv-autocomplete>
                    <span v-show="errors['address']" class="invalid-feedback">{{ $t(errors['address'] || "") }}</span>
                  </div>
                  <div class="col-lg-12 col-md-6 mt-2">
                    <ui-checkbox
                      name="settings.disallow_overlapping"
                      v-model="settings.disallow_overlapping"
                      :disabled="!isModuleActive"
                      :label="$t('TASKS_DISALLOW_OVERLAPPING')"
                      :hint="$t('TASKS_DISALLOW_OVERLAPPING_HINT')"
                    />
                  </div>
                  <div class="col-lg-12 col-md-6 mt-2">
                    <ui-checkbox
                      name="settings.change_date_of_unstarted_tasks"
                      v-model="settings.change_date_of_unstarted_tasks"
                      :disabled="!isModuleActive"
                      :label="$t('CHANGE_DATE_OF_UNSTARTED_TASKS')"
                      :hint="$t('CHANGE_DATE_OF_UNSTARTED_TASKS_HINT')"
                    />
                  </div>
                  <div class="col-lg-6 col-md-6 mt-2">
                    <ui-number-input
                      :disabled="!isModuleActive || !settings.change_date_of_unstarted_tasks"
                      name="change_date_after_minutes"
                      :label="$t('CHANGE_DATE_AFTER_MINUTES')"
                      :hint="$t('CHANGE_DATE_AFTER_MINUTES_HINT')"
                      v-model="settings.change_date_after_minutes"
                      :validation="{required: true, integer: true, min_value: 0}"
                      :precision="0"
                      :step="1"
                      :min="0"
                    />
                  </div>
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('ACCESS_LEVEL_SETTINGS') }}
                      </span>
                    </el-divider>
                  </div>
                  <div class="col-lg-12 mt-2">
                    <label class="control-label font-weight-bold">{{ $t('MIN_ROLE_ACCESS') }}</label>
                    <div>
                      <ui-radio
                        name="gg_admin"
                        :label="$t('Admin')"
                        v-model="settings.min_role"
                        :disabled="!isModuleActive"
                        :set-value="'gg_admin'"
                      />
                    </div>
                    <div>
                      <ui-radio
                        name="gg_manager"
                        :label="$t('Manager')"
                        v-model="settings.min_role"
                        :disabled="!isModuleActive"
                        :set-value="'gg_manager'"
                      />
                    </div>
                    <div>
                      <ui-radio
                        name="gg_user"
                        :label="$t('User')"
                        v-model="settings.min_role"
                        :disabled="!isModuleActive"
                        :set-value="'gg_user'"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 mt-2">
                    <ui-multi-select
                      name="types"
                      :label="$t('POSITIONS_ALLOWED')"
                      :hint="$t('POSITIONS_ALLOWED_HINT')"
                      v-model="settings.positions_list"
                      :disabled="settings.min_role !== 'gg_user' || !isModuleActive"
                      :options="positionsChoices"
                      :key-name="'id'"
                      :label-name="'name'"
                      :value-name="'id'"
                    />
                  </div>
                  <div class="col-lg-12 mt-2">
                    <el-divider content-position="left">
                      <span class="text-primary text-uppercase font-weight-bold">
                        {{ $t('FILE_ASSIGNMENT_LIST_OPTIONS') }}
                      </span>
                    </el-divider>
                  </div>
                  <div class="col-12 col-md-6 mt-2">
                    <el-tag
                      v-for="option in settings.file_assignment_list"
                      :key="option"
                      closable
                      size="large"
                      class="mr-1"
                      :disable-transitions="false"
                      @close="handleCloseOption(option)"
                    >
                      {{ option }}
                    </el-tag>
                    <el-button
                      class="mx-1"
                      type="primary"
                      v-if="!fileAssignmentInputVisible"
                      @click="handleInputOpen"
                    >
                      {{ $t('ADD_NEW_OPTION') }}
                    </el-button>
                    <ui-text-input
                      class="mt-1"
                      v-if="fileAssignmentInputVisible"
                      ref="FileAssignmentInputValueRef"
                      id="fileAssignmentInputValue"
                      :name="'fileAssignmentInputValue'"
                      v-model="fileAssignmentInputValue"
                      @keyup.enter="handleInputConfirm"
                      @blur="handleInputConfirm"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsTasks',
  components: { ModalComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      module: null,
      settings: {
        check_gps: false,
        radius: 50,
        min_role: 'gg_admin',
        positions_list: [],
        file_assignment_list: [],
        show_tasks: false,
        disallow_overlapping: false,
        default_city: '',
        allow_starting_without_shifts: false,
        change_date_of_unstarted_tasks: false,
        geolocation_distance_filter: 10,
        change_date_after_minutes: 0,
        allow_free_geolocation_start: false,
        enable_geolocation: false
      },
      positionsChoices: [],
      moduleInfo: null,
      fileAssignmentInputVisible: false,
      fileAssignmentInputValue: ''
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'tasks')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 20) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.settings = {
              check_gps: this.module.settings.check_gps ? this.module.settings.check_gps : false,
              radius: this.module.settings.radius ? this.module.settings.radius : 50,
              min_role: this.module.settings.min_role ? this.module.settings.min_role : 'gg_admin',
              positions_list: this.module.settings.positions_list ? this.module.settings.positions_list : [],
              file_assignment_list: this.module.settings.file_assignment_list ? this.module.settings.file_assignment_list : [],
              show_tasks: this.module.settings.show_tasks ? this.module.settings.show_tasks : false,
              default_city: this.module.settings.default_city,
              disallow_overlapping: this.module.settings.disallow_overlapping,
              allow_starting_without_shifts: this.module.settings.allow_starting_without_shifts ? this.module.settings.allow_starting_without_shifts : false,
              change_date_of_unstarted_tasks: this.module.settings.change_date_of_unstarted_tasks ? this.module.settings.change_date_of_unstarted_tasks : false,
              change_date_after_minutes: this.module.settings.change_date_after_minutes ? +this.module.settings.change_date_after_minutes : 0,
              geolocation_distance_filter: this.module.settings.geolocation_distance_filter ? +this.module.settings.geolocation_distance_filter : 10,
              allow_free_geolocation_start: !!this.module.settings.allow_free_geolocation_start,
              enable_geolocation: !!this.module.settings.enable_geolocation
            }
          }
        }
      })
      // модуль посещаемости активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 21]).then(response => {
        if (response.active === true) {
          this.isModuleActive = true
          this.$store.dispatch('getPositions', this.$store.getters.companyId).then((positions) => {
            this.positionsChoices = positions.map(item => {
              return {
                id: item.id,
                name: item.title
              }
            })
          })
        }
      }).catch(err => {
        if (err.response.data.module) {
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    async submitForm () {
      try {
        const result = await this.$refs.observer.validate()
        if (result.valid && this.module) {
          await this.$store.dispatch('updateModuleSettings', [
            this.$store.getters.companyId,
            this.module.id,
            {
              settings: this.settings
            }
          ])
          await this.$store.dispatch('clearCompanyStore')
          await this.$store.dispatch('getCompany', this.$store.getters.companyId)

          this.$emit('success')
        }
      } catch (error) {
        this.$emit('error')
      }
    },
    handleInputConfirm () {
      if (this.fileAssignmentInputValue) {
        this.settings.file_assignment_list.push(this.fileAssignmentInputValue)
      }
      this.fileAssignmentInputVisible = false
      this.fileAssignmentInputValue = ''
      console.log(this.settings.file_assignment_list)
    },
    handleCloseOption (option) {
      this.settings.file_assignment_list.splice(this.settings.file_assignment_list.indexOf(option), 1)
    },
    handleInputOpen () {
      this.fileAssignmentInputVisible = true
      this.$nextTick(_ => {
        // this.$refs.ui_input.focus()
        const elements = document.getElementsByName('fileAssignmentInputValue')
        elements.forEach(element => element.focus())
      })
    }
  }
}
</script>

<style scoped>

</style>
