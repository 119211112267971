<template>
  <el-tooltip
    :show-after="1000"
    effect="light"
    placement="top"
    class="w-100">
    <template #content>
      <div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('EVENT_TYPE')}}: </span>
          <span class="font-weight-bold">{{$t(eventData.event_type)}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('STARTS_AT')}}: </span>
          <span class="font-weight-bold">{{moment(eventData.dtstart).format(localeDateFormat)}}</span>
        </div>
        <div v-if="eventData.note" class="my-1">
          <span class="font-weight-bold text-primary">{{$t('EVENT_TITLE')}}: </span>
          <span class="font-weight-bold">{{eventData.note}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('TIME')}}: </span>
          <span class="font-weight-bold">{{time}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('IS_FULL_DAY')}}: </span>
          <i v-if="eventData.duration === 1440" class="icon-check-circle text-success font-weight-bold" />
          <i v-if="eventData.duration !== 1440" class="icon-minus-circle text-warning font-weight-bold" />
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('IS_RECURRING')}}: </span>
          <i v-if="eventData.is_recurring" class="icon-check-circle text-success font-weight-bold" />
          <i v-if="!eventData.is_recurring" class="icon-minus-circle text-warning font-weight-bold" />
        </div>
        <template v-if="eventData.is_recurring">
          <div class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_FREQUENCY')}}: </span>
            <span class="font-weight-bold">{{$t(eventData.freq)}}</span>
          </div>
          <div v-if="eventData.byweekday.length > 0" class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_WEEKDAYS')}}: </span>
            <span v-for="day in eventData.byweekday" :key="day" class="font-weight-bold">{{$t(day)}} </span>
          </div>
          <div v-if="eventData.until" class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_UNTIL')}}: </span>
            <span class="font-weight-bold">{{moment(eventData.until).format(localeDateFormat)}}</span>
          </div>
        </template>
      </div>
    </template>
    <template v-if="viewType === 'resourceTimeMonth' && monthDisplay === 'compact'">
      <div class="text-center fc-content availability">
        <i class="text-black-50" :class="event.isUnavailability ? 'icon-user-x' : 'icon-user-check'" style="font-size: 15px"></i>
      </div>
    </template>
    <template v-else>
      <div class="fc-content availability">
        {{ time }} {{ event.title }}
      </div>
    </template>
  </el-tooltip>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment-timezone'

export default {
  name: 'AvailabilityFcComponent',
  mixins: [momentMixin],
  props: { event: Object, view: Object, monthDisplay: String },
  data () {
    return {
      moment: moment
    }
  },
  computed: {
    viewType () {
      return this.view.type
    },
    time () {
      // let end = this.to === '00:00' ? '24:00' : this.to
      return moment(this.event.start).format(this.localeTimeFormat) + ' - ' + moment(this.event.end).format(this.localeTimeFormat)
    },
    eventData () {
      return this.$store.getters.companyAvailabilityIndexed[this.event.id.split('__sp__')[0]]
    }
  }
}
</script>

<style scoped>
.availability {
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
  color: #2c2c2c;
  font-size: 9px;
  font-weight: bold;
}
</style>
