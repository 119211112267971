<template>
  <div>
    <import-load-demand-modal
      v-if="ImportLoadModal"
      :project_tz="project.time_zone"
      :getFilePreview="true"
      @close="close"
      @reload="reload"
      @updatePreviewData="updateDemandFromFile"
    />
    <update-load-demand-modal
      v-if="UpdateLoadModal"
      :loadItem="loadItem"
      :project_tz="project.time_zone"
      :doNotUpdate="true"
      @close="close"
      @reload="reload"
    />
    <create-load-demand-modal
      v-if="CreateLoadModal"
      :project_tz="project.time_zone"
      :doNotSave="true"
      @close="close"
      @reload="reload"
    />
    <dummy-payment-required
      v-if="dummy"
      :class="'mt-2'"
      @activate="activate"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-show="!dummy">
      <Form ref="observe">
        <div class="row">
          <div class="col-auto align-items-center">
            <ui-number-input
              :precision="0"
              :step="1"
              :min="1"
              name="minimum_shift_duration"
              v-model="shiftsControlState.minimum_shift_duration"
              :validation="{required: false, integer: true, greaterOrEqualThanZero: true, min_value: 1, max_value: maxValueForMinShiftTime}"
              :label="$t('minimum_shift_duration')"
              :hint="$t('minimum_shift_duration_hint')"
            />
          </div>
          <div class="col-auto align-items-center">
            <ui-number-input
              :precision="0"
              :step="1"
              :min="1"
              name="maximum_shift_duration"
              v-model="shiftsControlState.maximum_shift_duration"
              :validation="{required: false, integer: true, greaterOrEqualThanZero: true, min_value: 1, max_value: 24}"
              :label="$t('maximum_shift_duration')"
              :hint="$t('maximum_shift_duration_hint')"
            />
          </div>
          <div class="col-auto align-items-center">
            <ui-number-input
              :precision="0"
              :step="1"
              :min="1"
              name="minimum_coverage"
              v-model="shiftsControlState.minimum_coverage"
              :validation="{required: false, integer: true, min_value: 1, max_value: maxValueForMinCoverage}"
              :label="$t('minimum_coverage')"
              :hint="$t('minimum_coverage_hint')"
            />
          </div>
          <div class="col-auto align-items-center">
            <ui-number-input
              name="maximum_coverage"
              v-model="shiftsControlState.maximum_coverage"
              :precision="0"
              :step="1"
              :min="1"
              :validation="{required: false, integer: true, greaterOrEqualThanZero: true, min_value: 1, max_value: 999}"
              :label="$t('maximum_coverage')"
              :hint="$t('maximum_coverage_hint')"
            />
          </div>
          <div class="col-auto d-flex align-items-center mx-2">
            <i
              class="icon-plus btn-icon mx-2 text-primary"
              @click="createItem"
              role="button"
              data-toggle="modal"
              data-target="#CreateLoadStatisticModal"
            />
            <el-tooltip effect="light" :content="$t('Import load statistic from file')" placement="top">
              <i
                role="button"
                class="icon-download btn-icon text-primary mx-2"
                v-on:click="ImportFileModal"
                data-toggle="modal"
                data-target="#ImportLoadStatisticModal"
              />
            </el-tooltip>
          </div>
        </div>
        <div class="row">
          <div class="col-auto align-items-center">
            <label class="control-label mt-2 font-weight-bold">
              {{ $t('demand_create_templates') }}
              <ui-hint :content="$t('demand_create_templates_hint')" />
            </label>
            <div>
              <ui-switch
                name="create_templates"
                v-model="shiftsControlState.create_templates"
                @change="val => shiftsControlState.create_templates = val"
              />
            </div>
          </div>
        </div>
      </Form>
      <el-divider />
      <div class="row">
        <template v-if="shiftsControlState.scheduleDemand.length === 0">
          <div class="col text-center">
            <div class="font-weight-bold my-5">
              {{$t('NO_LOAD_STATISTIC_STORED')}}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="align-middle text-center table-header">
                    {{$t('Date')}}
                  </th>
                  <th class="align-middle text-center table-header">
                    {{$t('STEP_MINUTES')}}
                  </th>
                  <th class="align-middle text-center table-header">
                    {{$t('Demand values')}}
                  </th>
                  <th class="align-middle text-center table-header"></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in shiftsControlState.scheduleDemand" :key="index">
                    <td class="align-middle text-center">
                      {{fromZoneToZone(row.date, 'UTC', project.time_zone).format(localeDateFormat)}}
                    </td>
                    <td class="align-middle text-center">
                      {{row.step}}
                    </td>
                    <td class="align-middle text-center">
                      <div>
                        <!-- костыль, без текста канвас на первой итерации не растягивается на 100% -->
                        <span style="color: white">.</span>
                        <canvas :id="'demandChart_' + index" height="40" aria-label="demandChart" role="img" />
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <i
                        class="icon-pencil btn-icon mx-2 text-primary"
                        @click="updateItem(row)"
                        role="button"
                        data-toggle="modal"
                        data-target="#UpdateLoadStatisticModal"
                      />
                      <i
                        class="icon-trash btn-icon mx-2 text-danger"
                        @click="deleteItem(row)"
                        role="button"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import ImportLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/ImportLoadDemandModal'
import UpdateLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/UpdateLoadDemandModal'
import CreateLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/CreateLoadDemandModal'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import moment from 'moment'
import { Chart } from 'chart.js'

export default {
  name: 'Demand',
  mixins: [errorMixin, momentMixin, mixinDummy],
  components: { CreateLoadDemandModal, UpdateLoadDemandModal, ImportLoadDemandModal, DummyPaymentRequired },
  data () {
    return {
      filteredDemand: [],
      CreateLoadModal: false,
      ImportLoadModal: false,
      UpdateLoadModal: false,
      loadItem: {},
      myCharts: [],
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      shiftsControlState: {}
    }
  },
  props: {
    projectId: Number,
    schedule: Object,
    shiftsControl: Object,
    validate: Boolean
  },
  created () {
    this.shiftsControlState = this.shiftsControl
    this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 30]).then(response => {
      this.dummy = false
    }).catch((error) => {
      if (error.response.data.module) {
        this.dummy = true
        this.$eventBus.emit('dummy', true)
        this.dummyInfo = this.setupDummyData(error.response.data)
      }
    })

    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    project () {
      return this.$store.getters.projectById(this.projectId) || {}
    },
    maxValueForMinShiftTime () {
      return this.shiftsControlState.maximum_shift_duration
    },
    maxValueForMinCoverage () {
      return this.shiftsControlState.maximum_coverage
    },
    companySalary () {
      return this.$store.state.company.company.salary
    }
  },
  watch: {
    shiftsControl () {
      this.shiftsControlState = this.shiftsControl
    },
    validate (value) {
      this.$refs.observe.validate().then(result => {
        this.$emit('validationResult', result)
      })
    },
    startTime (value) {
      if (value > this.item.time_from) {
        this.item.time_from = value
      }
    },
    endTime (value) {
      if (value < this.item.time_to) {
        this.item.time_to = value
      }
    }
  },
  methods: {
    activate () {
      if (this.schedule.scheduleId) {
        const timeFrom = moment(this.schedule.mainInfo.period[0]).format(this.backendDateTimeFormat)
        const timeTo = moment(this.schedule.mainInfo.period[1]).format(this.backendDateTimeFormat)
        const query = `from=${timeFrom}&to=${timeTo}`
        this.$store.dispatch('getLoadStatistic', [this.schedule.scheduleId, query]).then((response) => {
          if (response) {
            this.shiftsControlState.scheduleDemand = response
            this.dummy = false
            this.init()
          }
        }).catch(() => {
          this.dummy = false
          this.shiftsControlState.scheduleDemand = []
        })
      } else {
        this.dummy = false
        this.init()
      }
    },
    init () {
      setTimeout(() => {
        this.handleCharts()
      }, 100)
    },
    createItem () {
      this.CreateLoadModal = true
    },
    updateItem (row) {
      this.loadItem = row
      this.UpdateLoadModal = true
    },
    deleteItem (row) {
      this.$confirm(this.$i18n?.t('CONFIRM_LOAD_STATISTIC_DELETE'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        const index = this.shiftsControlState.scheduleDemand.findIndex(item => item.date === row.date)
        if (index > -1) {
          this.shiftsControlState.scheduleDemand.splice(index, 1)
        }
        this.init()
      }).catch(() => {
        //
      })
    },
    ImportFileModal () {
      this.ImportLoadModal = true
    },
    close () {
      this.ImportLoadModal = false
      this.UpdateLoadModal = false
      this.CreateLoadModal = false
    },
    reload (payload = null) {
      const scheduleDemand = this.shiftsControlState.scheduleDemand
      if (payload) {
        payload.date = this.fromZoneToZone(payload.date, this.project.time_zone, 'UTC').format(this.backendDateTimeFormat)
        const index = this.shiftsControlState.scheduleDemand.findIndex(item => item.date === payload.date)
        // data for this date already exists, the case needs to be processed
        if (index > -1) {
          if (payload.repeated_dates_action === 'replace') {
            this.shiftsControlState.scheduleDemand[index] = payload
            this.init()
          } else {
            // To do nothing
          }
        } else {
          scheduleDemand.push(payload)
          this.shiftsControlState.scheduleDemand = scheduleDemand.sort((a, b) => new Date(a.date) - new Date(b.date))
          this.init()
        }
      }
    },
    updateDemandFromFile (data) {
      if (data.response) {
        const scheduleDemand = this.shiftsControlState.scheduleDemand
        data.response.forEach(element => {
          element.step = data.step
          const index = scheduleDemand.findIndex(item => item.date === element.date)
          if (index > -1) {
            if (data.repeated_dates_action === 'replace') {
              scheduleDemand[index] = element
            } else {
              // To do nothing
            }
          } else {
            scheduleDemand.push(element)
          }
        })
        this.shiftsControlState.scheduleDemand = scheduleDemand.sort((a, b) => new Date(a.date) - new Date(b.date))
        this.init()
      }
    },
    handleCharts () {
      this.myCharts.forEach(chart => chart.destroy())
      this.myCharts = []
      this.shiftsControlState.scheduleDemand.forEach((dataset, index) => {
        const myChart = new Chart('demandChart_' + index, {
          type: 'line',
          data: {
            labels: dataset.demand_values.map((val, index) => {
              return index
            }),
            datasets: [
              {
                label: this.$t('LOAD_GRAPH_LABEL'),
                data: dataset.demand_values,
                borderWidth: 1,
                fill: false,
                borderColor: 'rgb(36, 70, 194)',
                tension: 0.1,
                backgroundColor: 'rgb(36, 70, 194)'
              }
            ]
          },
          options: {
            elements: {
              point: {
                pointStyle: 'line'
              },
              line: {}
            },
            plugins: {
              legend: {
                display: true,
                position: 'top',
                reverse: false
              }
            },
            layout: {
              padding: 0
            }
          }
        })
        this.myCharts.push(myChart)
      })
    }
  }
}
</script>

<style scoped>
</style>
