<template>
  <Form ref="observe">
    <div class="row" data-cy="working-time-settings">
      <template v-if="templates.length > 0">
        <div v-for="(template, index) in templates" class="col-lg-6 d-flex align-items-stretch my-2" :key="index + '_new'">
          <div class="card w-100">
            <div class="card-body text-center">
              <shift-template
                :key="index"
                :template="template"
                :selectedDays="days"
                :index="index"
                :project="project"
                :type="type"
                :patternType="patternType"
                :validate="validate"
                :showAdvanced="true"
                :forDemand="true"
                :dummyInfo="breakModuleStatus"
                @activateBreaks="activateBreaks"
                @validateTemplates="validateTemplates">
              </shift-template>
            </div>
          </div>
        </div>
      </template>
      <div class="col-lg-12 d-inline-flex py-2">
        <button
          id="demo_master_prev_step"
          class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
          @click="prev">
          {{ $t('Previous')}}
        </button>
        <button
          id="demo_master_next_step"
          data-cy="next-step"
          class="btn btn-primary rounded-pill shadow-sm"
          @click="next">
          {{ $t('Next step')}}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import ShiftTemplate from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/TemplateComponent'

export default {
  name: 'DemandTemplatesSetting',
  components: { ShiftTemplate },
  mixins: [errorMixin],
  data () {
    return {
      days: [
        {
          long: this.$i18n?.t('Monday'),
          short: this.$i18n?.t('Mon'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 0
        },
        {
          long: this.$i18n?.t('Tuesday'),
          short: this.$i18n?.t('Tue'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 1
        },
        {
          long: this.$i18n?.t('Wednesday'),
          short: this.$i18n?.t('Wed'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 2
        },
        {
          long: this.$i18n?.t('Thursday'),
          short: this.$i18n?.t('Thu'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 3
        },
        {
          long: this.$i18n?.t('Friday'),
          short: this.$i18n?.t('Fri'),
          selected: true,
          disabled: this.type === 'by_pattern',
          index: 4
        },
        {
          long: this.$i18n?.t('Saturday'),
          short: this.$i18n?.t('Sat'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 5
        },
        {
          long: this.$i18n?.t('Sunday'),
          short: this.$i18n?.t('Sun'),
          selected: this.type === 'by_pattern',
          disabled: this.type === 'by_pattern',
          index: 6
        }
      ],
      templates: [],
      breakModuleStatus: {
        active: true
      },
      validate: false,
      templateErrors: 0,
      shiftsControlState: {},
      scheduleState: {}
    }
  },
  props: {
    project: Number,
    workingTime: Object,
    shiftsControl: Object,
    schedule: Object,
    minDurationWorkingMinutesDay: Number,
    salarySettings: Object,
    patternType: {
      type: String,
      default: ''
    },
    type: String
  },
  created () {
    this.scheduleState = this.schedule
    this.shiftsControlState = this.shiftsControl
    if (!this.scheduleState.scheduleSettings.templates.length || this.shiftsControlState.needReloadTemplates) {
      let payload = {
        minimum_shift_duration: this.shiftsControlState.minimum_shift_duration,
        maximum_shift_duration: this.shiftsControlState.maximum_shift_duration,
        minimum_coverage: this.shiftsControlState.minimum_coverage,
        maximum_coverage: this.shiftsControlState.maximum_coverage,
        project_id: this.scheduleState.mainInfo.project,
        demand: this.shiftsControlState.scheduleDemand
      }

      this.$store.dispatch('getTemplatesByDemand', [this.companyId, payload]).then((response) => {
        if (response) {
          this.shiftsControlState.needReloadTemplates = false
          this.templates = response
        }
      }).catch(() => {})
    } else {
      this.templates = this.scheduleState.scheduleSettings.templates
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    }),
    salary () {
      return this.$store.getters.company.salary
    },
    showRadioButton () {
      return +this.salary.minimum_break_between_shifts > 0 || +this.salary.minimum_break_between_shifts_for_women > 0 || +this.salary.minimum_break_in_row_per_week > 0
    }
  },
  watch: {
    schedule () {
      this.scheduleState = this.schedule
    },
    shiftsControl () {
      this.shiftsControlState = this.shiftsControl
    }
  },
  methods: {
    activateBreaks () {
      this.breakModuleStatus = { active: true }
    },
    validateTemplates () {
      this.templateErrors++
    },
    next () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          this.scheduleState.scheduleSettings.templates = this.templates
          this.$emit('next', {
            step: 'workingTime',
            data: {}
          })
        }
      })
    },
    prev () {
      this.scheduleState.scheduleSettings.templates = this.templates
      this.$emit('prev', {
        step: 'workingTime',
        data: {}
      })
    }
  }
}
</script>

<style scoped>

</style>
