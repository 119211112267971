<template>
  <div class="font-weight-bold" :class="{'col-form-label': !noMargins}">
    {{ label }}
    <span class="text-danger" :class="{'mx-1': required}">{{required ? '*' : ''}}</span>
    <ui-hint v-if="hint" :content="hint"/>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-input-label',

  props: {
    label: String,
    hint: String,
    noMargins: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  }

}
</script>
