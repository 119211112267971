<template>
  <ui-input-label v-if="label" :label="label" :hint="hint"/>
  <el-select
    ref="tagSelect"
    v-model="innerValue"
    :placeholder="placeholder || $t('Select')"
    :allow-create="allowCreate !== false"
    :disabled="!!disabled"
    :filterable="filterable !== false"
    :clearable="clearable"
    default-first-option
    :no-data-text="$t('No data')"
    @change="(val) => $emit('change', val)"
    v-on:keyup.enter="handleBlur"
    :class="{'is-invalid': isInvalid}"
    class="w-100"
    size="default"
    multiple>
    <el-option
      v-for="(option, key) in options"
      :key="option[keyName || 'id'] || key"
      :value="option[valueName || 'id']"
      :label="option[labelName || 'name']">
      <slot :option="option"></slot>
    </el-option>
  </el-select>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ errorMessage }"
    :rules="validation"
    as="div"
  >
    <el-form-item
      :error="errorMessage"
      :required="validation && !!validation.required"
      class="p-0 mt-0 mb-1"
    />
  </Field>
</template>

<script>
export default {
  name: 'ui-tags-select',
  data () {
    return {
      isInvalid: false,
      observer: null
    }
  },
  emits: ['update:modelValue', 'change'],
  props: {
    name: String,
    validation: [String, Object],
    value: Array,
    modelValue: Array,
    options: Array,
    placeholder: String,
    allowCreate: { type: Boolean, default: true },
    disabled: Boolean,
    filterable: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    keyName: String,
    valueName: String,
    labelName: String,
    label: String,
    hint: String
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (values) {
        const ids = []
        this.options?.forEach(option => {
          ids.push(option[this.valueName || 'id'])
        })
        values?.forEach(value => {
          if (!ids.includes(value)) {
            this.handleBlur()
          }
        })
        this.$emit('update:modelValue', values)
      }
    }
  },
  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleBlur () {
      // при создании опций на лету нужно чистить инпут, на блур инпут чистится самим элементс, поэтому вызываем блур на энтер
      this.$refs.tagSelect.blur()
    },
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }
}
</script>
