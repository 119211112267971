<template>
  <div>
    <create-application-modal
      v-if="showCreateApplicationModal">
    </create-application-modal>
    <div class="row mb-2 mx-auto">
      <div class="col">
        <span class="title-text">
        {{ $t('Applications') }}
        <ui-hint :content="$t('APPLICATIONS_HINT')" />
      </span>
      </div>
      <div v-if="appsList.length > 0" class="col-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          autofocus
          :is-search="true"
        />
      </div>
      <div v-if="appsList.length > 0" class="col-auto">
        <button
          type="button"
          @click="createApplication()"
          data-toggle="modal"
          data-target="#CreateApplicationModal"
          class="btn btn-primary btn-sm rounded-pill shadow-sm">
          {{ $t('Add app') }}
        </button>
      </div>
    </div>
    <div class="col-lg-12" v-if="appsList.length === 0">
      <div class="text-center">
        <div class="card-body text-center">
          <img class="img-fluid" style="max-height: 350px" src="../../../public/static/images/App/Splash_1_01.png"/>
          <h5 class="m-3">
            {{$t('Start building your first app')}}
          </h5>
          <div class="pb-3">{{ $t('After creating an application, you will be abble to get an access token to use the api.') }}</div>
          <button
            type="button"
            @click="createApplication()"
            data-toggle="modal"
            data-target="#CreateApplicationModal"
            class="btn btn-primary">
            {{ $t('New app') }}
          </button>
        </div>
      </div>
    </div>
    <div v-else class="card card-small">
      <div class="col-lg-12" v-if="appsList.length">
        <el-table
          :data="filteredApps"
          style="width: 100%">
          <el-table-column
            :label="$t('Logo')"
            width="100">
            <template v-slot="scope">
              <avatar
                :key="'applications_icon_id' + scope.row.id"
                :width="75"
                :borderRadius="0"
                :appId="scope.row.id"
                :placeholder="true"
                :placeholderUrl="'../../../static/images/App/Splash_1_01.png'"
                :cache="true">
              </avatar>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('App name')"
            >
            <template v-slot="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Category')"
            >
            <template v-slot="scope">
              <div v-if="scope.row.category">{{ scope.row.category.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Is active')"
            >
            <template v-slot="scope">
              <i v-if="scope.row.is_active" class="icon-check-circle icon-default text-success" />
              <i v-else class="icon-minus-circle icon-default text-warning" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Approved')"
            >
            <template v-slot="scope">
              <i v-if="scope.row.approved" class="icon-check-circle icon-default text-success" />
              <i v-else class="icon-minus-circle icon-default text-warning" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Updated At')"
            >
            <template v-slot="scope">
              {{ toTimeZone(scope.row.created_at).format(localeDateTimeFormat) }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('Actions')"
            width="100"
            >
            <template v-slot="scope">
              <router-link :to="`/c/${companyId}/developer/apps/${scope.row.id}`">
                <i role="button" class="icon-pencil btn-icon text-primary mx-2"></i>
              </router-link>
              <a @click="deleteApp(scope.row.id)">
                <i class="icon-trash btn-icon text-danger"></i>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CreateApplicationModal from './Components/CreateApplicationModal.vue'
import Avatar from '@/components/CommonComponents/Avatar'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Applications',
  components: {
    CreateApplicationModal,
    Avatar
  },
  mixins: [momentMixin, ToastMixin],
  data () {
    return {
      search: '',
      apps: [],
      showCreateApplicationModal: false,
      webhooks: []
    }
  },
  mounted () {
    this.$eventBus.on('closeCreateApplicationModal', this.closeCreateApplicationModal)
  },
  created () {
    this.$store.dispatch('getDeveloperApps', this.companyId)
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    appsList () {
      return this.$store.getters.apps
    },
    filteredApps () {
      const appsList = this.appsList
      // eslint-disable-next-line
      return appsList.filter((app, index) => {
        if (this.search === '') {
          return app
        }
        const filteredApps = app.name.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1
        if (filteredApps) {
          return filteredApps
        }
      }).sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    }
  },
  watch: {
  },
  methods: {
    createApplication () {
      this.showCreateApplicationModal = true
    },
    closeCreateApplicationModal () {
      this.showCreateApplicationModal = false
    },
    editApp () {
      console.log('editApp')
    },
    deleteApp (appId) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('deleteDeveloperApp', [this.companyId, appId]).then(response => {
          this.toastSuccess(this.$i18n?.t('App was deleted'))
        }).catch((error) => {
          this.toastError(this.$i18n?.t(error.response.data.message))
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
