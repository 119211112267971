<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="init()"
      :dummyInfo="dummyInfo"
      :compactMode="false"
    />
    <template v-if="!dummy">
      <div class="card card-small mb-2">
        <div class="card-body">
          <vacations-overuse
            :userInfo="userInfo"
            :employeeId="employeeId"
          />
        </div>
      </div>
      <div class="card card-small mb-2">
        <div class="card-body">
          <settings
            :employeeId="employeeId"
            :userInfo="userInfo"
            @reloadSettings="initSetting"
          />
        </div>
      </div>
      <div class="card card-small">
        <div v-if="showTransactions" class="card-body">
          <transactions
            :employeeId="employeeId"
            @reloadTransactions="initTransactions"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import mixinDummy from '@/mixins/mixinDummy'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import Settings from '@/components/User/user/UserComponents/VacationsComponents/Settings.vue'
import Transactions from '@/components/User/user/UserComponents/VacationsComponents/Transactions.vue'
import VacationsOveruse from '@/components/User/user/UserComponents/VacationsComponents/VacationsOveurse.vue'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'Vacations',
  components: { VacationsOveruse, Transactions, Settings, DummyPaymentRequired },
  mixins: [mixinDummy, momentMixin],
  data () {
    return {
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, intercomArticle: null
      },
      showTransactions: true
    }
  },
  props: {
    employeeId: Number,
    userInfo: Object
  },
  created () {
    this.init()
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    locale () {
      return this.$store.getters.locale
    }
  },
  methods: {
    init () {
      this.initSetting()
      this.initTransactions()
    },
    initSetting () {
      this.showTransactions = false
      this.$store.dispatch('getVacationsSettings', [this.companyId, this.employeeId]).then(r => {
        this.showTransactions = true
        this.dummy = false
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    initTransactions () {
      this.$store.dispatch('getVacationsTransactions', [this.companyId, this.employeeId]).then(r => {
        this.dummy = false
        this.$store.dispatch('getVacationsMonthReport', [
          this.companyId,
          moment().format(this.backendMonthYearFormat),
          this.employeeId
        ])
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
