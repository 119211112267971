<template>
  <div>
    <h3 class="mb-2">{{ $t('Create shift templates') }}</h3>
    <p class="mb-4">{{ $t('TEMPLATES_STEP_DESCRIPTION') }}</p>

    <div class="w-100">
      <div class="form-row">
        <div class="col">
          <label>{{ $t('Shift title') }}</label>
        </div>
        <div class="col">
          <label>{{ $t('Start time') }}</label>
        </div>
        <div class="col">
          <label>{{ $t('End time') }}</label>
        </div>
        <div class="col-0" style="width: 40px;"></div>
      </div>

      <div class="form-inputs-wrapper">
        <div class="form-row mb-2" v-for="(template, index) in mutatedShiftTemplates" :key="index">
          <div class="col">
            <ui-text-input
              :name="index + 'shiftTitle'"
              v-model="template.title"
              :validation="{required: index === 0 && active === 2}"
              :placeholder="$t('Shift title')"
            />
          </div>
          <div class="col">
            <ui-time-picker
                :name="index + 'startTime'"
                :placeholder="$t('Start time')"
                :validation="{required: index === 0 && active === 2 ? true : false}"
                :clearable="true"
                :value-format="'HH:mm'"
                :prefix-icon="null"
                v-model="template.startTime"
            />
          </div>
          <div class="col">
            <ui-time-picker
                :name="index + 'endTime'"
                :placeholder="$t('End time')"
                :validation="{required: index === 0 && active === 2 ? true : false}"
                :clearable="true"
                :value-format="'HH:mm'"
                :prefix-icon="null"
                v-model="template.endTime"
            />
          </div>
          <div class="col-0 d-flex justify-content-center align-items-center" style="width: 40px;">
            <i v-if="index !== 0" role="button" class="icon-x btn-icon p-2" @click="removeShiftTemplate(index)"></i>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-outline-primary" type="button" @click="addShiftTemplate">{{ $t('Add more templates') }}</button>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'TemplatesStep',
  mixins: [momentMixin],
  props: {
    shiftTemplates: {
      type: Array,
      required: true
    },
    active: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      mutatedShiftTemplates: this.shiftTemplates,
      shiftTemplatesCount: 3
    }
  },
  created () {
    this.pushShiftTemplatesToArr()
  },
  watch: {
    mutatedShiftTemplates () {
      this.$emit('refreshShiftTemplates', this.mutatedShiftTemplates)
    }
  },
  methods: {
    pushShiftTemplatesToArr () {
      let shiftTemplate = {
        title: this.$i18n?.t('Shift'),
        startTime: '09:00',
        endTime: '18:00'
      }

      for (let step = 0; step < this.shiftTemplatesCount; step++) {
        this.mutatedShiftTemplates.push(shiftTemplate)
        shiftTemplate = {
          title: '',
          startTime: '',
          endTime: ''
        }
      }
    },
    addShiftTemplate () {
      this.shiftTemplatesCount++
      this.mutatedShiftTemplates.push({
        title: '',
        startTime: '',
        endTime: ''
      })
    },
    removeShiftTemplate (indexToDelete) {
      this.mutatedShiftTemplates = this.mutatedShiftTemplates.filter((_, index) => index !== indexToDelete)
    }
  }
}
</script>

<style scoped lang="scss">
.el-date-editor.el-input {
  width: 100%;
}

</style>
