export default {
  methods: {
    checkAccess (companyId) {
      this.$store.dispatch('getTasksSettings', companyId).then((response) => {
        let minAllowed = 'gg_admin'
        if (response) {
          minAllowed = response.min_role
        }
        let role = this.$store.getters.companyRole
        if (!this.isEmployeeHasPermission('get-tasks-admin') &&
          this.isEmployeeHasPermission('get-tasks-manager')) {
          role = 'gg_manager'
        }
        const rating = {
          gg_admin: 3,
          gg_manager: 2,
          gg_user: 1
        }
        if (rating[minAllowed] > rating[role]) {
          this.accessErrorRedirect('You don\'t have permission for this action')
        }
        // console.log(response)
        if (!this.isEmployeeHasPermission('create-task-statuses') &&
          response.positions_list.length > 0) {
          this.$store.dispatch('getUserInfoById', {
            companyId: companyId,
            userId: this.currentCompanyUserId
          }).then((positionsList) => {
            const positions = positionsList.positions.map(item => item.id)
            const intersection = response.positions_list.filter(value => positions.includes(value))
            if (intersection.length < 1) {
              this.accessErrorRedirect('You don\'t have permission for this action')
            }
          })
        }
      })
    }
  },
  computed: {
    currentCompanyUserId () {
      return this.$store.getters.currentCompanyUserId
    }
  }
}
