<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div class="container-fluid p-0" v-else-if="loaded">
      <el-pagination
        layout="prev, pager, next"
        @current-change="getPage"
        v-model:current-page="pagination.currentPage"
        :page-size="25"
        :total="pagination.total"
      />
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Username") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Shift time") }}</th>
              <th class="align-middle text-left table-header" rowspan="2">{{ $t("Confirm status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in filteredData" v-bind:key="'row_' + index">
              <td class="text-nowrap">{{ row.username }}</td>
              <td class="text-nowrap">{{ convertTime(row.time_from) }} - {{ convertTime(row.time_to) }}</td>
              <td class="text-nowrap">
                <i v-if="row.confirmed_by_employee" class="icon-check-circle icon-default text-success" />
                <i v-else class="icon-minus-circle icon-default text-warning" />
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          layout="prev, pager, next"
          @current-change="getPage"
          v-model:current-page="pagination.currentPage"
          :page-size="25"
          :total="pagination.total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'Budget',
  components: { DummyPaymentRequired },
  mixins: [errorMixin, momentMixin, mixinDummy],
  data () {
    return {
      rows: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      }
    }
  },
  props: ['projects', 'period', 'search', 'confirmStatus'],
  created () {
    this.getTableData()
  },
  beforeUnmount () {
    this.$eventBus.off('exportShiftConfirmationReport')
  },
  mounted () {
    this.$eventBus.on('exportShiftConfirmationReport', this.exportReport)
  },
  methods: {
    getPage (page) {
      // console.log(page)
      this.getTableData(false, page)
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    exportReport () {
      this.getTableData(true)
    },
    getTableData (exportReport = false, page = 1) {
      this.loaded = false
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      let data = `?page=${page}&projects[]=` + this.projects.join('&projects[]=') + '&date_from=' + dateFrom + '&date_to=' + dateTo + '&confirm_status=' + this.confirmStatus
      if (exportReport) {
        data += '&export=1'
      }
      this.$store.dispatch('getShiftConfirmationReport', [this.$store.getters.companyId, data, exportReport]).then((response) => {
        // console.log(123, response)
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (exportReport) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_shift_confirmation_report')}_${moment(this.period[0]).format(this.backendDateFormat)}_${moment(this.period[1]).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.rows = response.data
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.toastError(this.$t(error.response.data.message))
          // this.$router.push('dashboard')
        }
      })
    },
    convertTime (time) {
      return this.fromZoneToZone(time, 'UTC', this.tz).format(this.localeDateTimeFormat)
    }
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    },
    filteredData () {
      return this.rows.filter((row, index) => {
        if (this.search === '') {
          return row
        }
        return ((row.username.toLowerCase()).indexOf(this.search.toString().toLowerCase()) > -1)
      })
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    },
    confirmStatus: function () {
      this.getTableData()
    }
  }
}
</script>

<style scoped>

</style>
