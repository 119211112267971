<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('VACATIONS_SETTINGS_TITLE') }}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3 d-flex font-weight-bold">
        <span class="mr-1">
           {{$t('VACATIONS_SETTINGS_FROM')}}
        </span>
        <ui-hint :content="$t('VACATIONS_SETTINGS_FROM_HINT')" />
      </div>
      <div class="col-lg-3 d-flex font-weight-bold">
        <span class="mr-1">
           {{$t('VACATIONS_SETTINGS_TO')}}
        </span>
        <ui-hint :content="$t('VACATIONS_SETTINGS_TO_HINT')" />
      </div>
      <div class="col-lg-3 d-flex justify-content-center font-weight-bold">
        <span class="mr-1">
           {{$t('VACATIONS_SETTINGS_YEAR_LIMIT')}}
        </span>
        <ui-hint :content="$t('VACATIONS_SETTINGS_YEAR_LIMIT_HINT')" />
      </div>
    </div>
    <el-divider />
    <Form ref="observer" v-slot="{ meta }">
      <div class="row d-flex align-items-center">
        <div class="col-lg-3">
          <ui-date-picker
            v-if="pickerType === 'date'"
            name="date_from"
            v-model="newSetting.date_from"
            pickerType="date"
            :clearable="false"
            @change="onChange"
            :placeholder="$t('SELECT_DATE')"
          />
          <ui-month-picker
            v-if="pickerType === 'month'"
            name="month_from"
            v-model="newSetting.date_from"
            :clearable="false"
            @change="onChange"
            :placeholder="$t('SELECT_MONTH')"
          />
        </div>
        <div class="col-lg-3">
        </div>
        <div class="col-lg-3 d-flex justify-content-center">
          <ui-number-input
            name="year_limit"
            v-model="newSetting.year_limit"
            :min="0"
            :precision="0"
            :step="1"
            :validation="{required: true, numeric: true, min_value: 0, max_value: 365}"
            @change="(val) => newSetting.year_limit = val"
          />
        </div>
        <div class="col-lg-3 d-flex justify-content-center">
          <div>
            <el-button
              size="large"
              type="primary"
              round
              @click="addSetting"
              :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
            >
              {{ $t('SAVE_WEB') }}
            </el-button>
          </div>
        </div>
      </div>
    </Form>
    <el-divider />
    <template v-for="(setting, index) in vacationSettings">
      <div v-if="setting.id !== settingToEdit.id" :key="setting.id" class="row mt-1">
        <div class="col-lg-3 px-4">
          {{convertToDate(setting.date_from, index, 'start')}}
        </div>
        <div class="col-lg-3">
          {{convertToDate(setting.date_to, index, 'end')}}
        </div>
        <div class="col-lg-3 d-flex justify-content-center">
          {{setting.year_limit}}
        </div>
        <div class="col-lg-3 d-flex justify-content-center">
          <div>
            <el-button
              v-if="vacationSettings.length === index + 1"
              size="default"
              type="primary"
              icon="Edit"
              circle
              @click="edit(setting)"
            />
          </div>
        </div>
      </div>
      <settings-edit
        v-if="setting.id === settingToEdit.id"
        :pickerType="index === 0 ? 'date' : 'month'"
        :key="'edit__' + setting.id"
        :setting="settingToEdit"
        :employeeId="employeeId"
        @cancel="() => settingToEdit.id = null"
      />
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import SettingsEdit from '@/components/User/user/UserComponents/VacationsComponents/SettingsEdit.vue'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Settings',
  components: { SettingsEdit },
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      newSetting: {
        date_from: moment().format(this.backendDateTimeFormat),
        year_limit: 14
      },
      settingToEdit: {
        id: null,
        date_from: moment().format(this.backendDateTimeFormat),
        year_limit: 14
      }
    }
  },
  props: {
    employeeId: Number
  },
  created () {
    this.$store.dispatch('getVacationsMonthReport', [this.companyId, moment().format(this.backendMonthYearFormat), this.employeeId])
    this.$eventBus.on('reloadSettings', this.reloadSettings)
  },
  computed: {
    vacationSettings () {
      return this.$store.getters.vacationsSettings
    },
    companyId () {
      return this.$store.getters.companyId
    },
    pickerType () {
      return this.vacationSettings.length > 0 ? 'month' : 'date'
    },
    format () {
      return this.pickerType === 'month' ? this.localeMonthYearFormatExtendedUi : this.localeDateFormatElementUi
    }
  },
  methods: {
    onChange (val) {
      this.newSetting.date_from = val
    },
    edit (setting) {
      this.settingToEdit = { ...setting }
      // console.log(this.settingToEdit)
    },
    convertToDate (val, index, type) {
      if (val) {
        if (index === 0 && type === 'start') {
          return moment(val).locale(this.$store.getters.locale).format(this.localeDateFormat)
        }
        if (index === this.vacationSettings.length - 1 && type === 'end') {
          return moment(val).locale(this.$store.getters.locale).format(this.localeDateFormat)
        }
        return moment(val).locale(this.$store.getters.locale).format(this.localeMonthYearFormatExtended)
      }
      return null
    },
    async addSetting () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const payload = {
            date_from: moment(this.newSetting.date_from).format('yyyy-MM-DD'),
            year_limit: this.newSetting.year_limit
          }
          this.$store.dispatch('createSetting', [this.companyId, this.employeeId, payload])
            .then(() => {
              this.reloadSettings()
              this.$notify({
                title: this.$i18n?.t('Success'),
                message: this.$i18n?.t('SETTING_CREATED_SUCCESS'),
                position: this.notificationPosition,
                type: 'success'
              })
            })
            .catch(e => {
              this.$notify({
                title: this.$i18n?.t('Error'),
                message: this.$i18n?.t(e.response.data.message),
                position: this.notificationPosition,
                type: 'error'
              })
            })
        }
      })
    },
    reloadSettings () {
      // console.log(123)
      this.settingToEdit.id = null
      this.$emit('reloadSettings')
    }
  }
}
</script>

<style scoped>

</style>
