<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-auto checkbox-wrapper">
          <ui-checkbox
            :name="module.id + '_enable'"
            v-model="enable"
            :disabled="module.always_on"
            size="big"
          />
        </div>
        <div class="col">
          <div class="font-weight-bold text-secondary">
            <span v-html="language in  module.locales ? module.locales[language].name : module.name"></span>
          </div>
          <div v-if="module.trial_period > 0" class="text-primary small">({{module.trial_period}} {{$t('month free trial')}})</div>
          <div class="mt-1 text-secondary">
            <span v-html="language in  module.locales ? module.locales[language].short_description : module.short_description"></span>
          </div>
        </div>
        <div class="col-auto text-secondary text-right">
          <div>${{module.price*1}}/{{$t('month')}}</div>
          <div v-if="module.user_price > 0">+${{module.user_price*1}} {{$t('per user')}}</div>
          <div v-if="module.per_unit > 0">+ ${{ module.unit_price * 1}} {{$t('per unit')}}</div>
          <div v-if="module.multi_purchase">
            <ui-number-input
              v-model="count"
              name="count"
              :min="minCount"
              :validation="{required: true}"
              :precision="0"
              :step="1"
              @change="changeCount"
              :controls-position="'right'"
              style="width: 95px"
            />
          </div>
        </div>
        <div class="col-lg-1 p-1">
          <div v-if="descriptionEnable" @click="descriptionEnable = !descriptionEnable" class="text-center">
            <i class="icon-more-horizontal" style="font-size: 15px; cursor: pointer;"></i>
          </div>
          <div v-else @click="descriptionEnable = !descriptionEnable" class="text-center">
            <i class="icon-more-horizontal" style="font-size: 15px; cursor: pointer;"></i>
          </div>
        </div>
      </div>
      <div v-if="module.modules.includes('14')" class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <span v-show="apiErrors['projects_departments']" class="text-danger small">
            {{ $t(apiErrors['projects_departments'] || "") }}
          </span>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
    <div v-if="descriptionEnable" class="card-footer bg-color-grey">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10 text-secondary">
          <span v-html="language in module.locales ? module.locales[language].description : module.description"></span>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'ModulesListItem',
  mixins: [errorMixin],
  data () {
    return {
      descriptionEnable: false,
      enable: false,
      count: 0,
      minCount: 0,
      language: this.$store.getters.locale
    }
  },
  props: { module: Object, position: Number },
  created () {
    this.$eventBus.on('disableModule', this.disableModule)
    this.enable = this.module.enableForCompany || this.module.always_on
    this.count = this.module.count
    if (this.module.free_count) {
      this.minCount = this.module.free_count
    }
  },
  beforeUnmount () {
    this.$eventBus.off('disableModule')
  },
  watch: {
    enable () {
      this.$eventBus.emit('changeStatus', { position: this.position, status: this.enable })
    }
  },
  methods: {
    changeStatus () {
      this.enable = !this.enable
      this.$eventBus.emit('changeStatus', { position: this.position, status: this.enable })
    },
    disableModule (id) {
      // console.log(id)
      if (this.module.id === id) this.changeStatus()
    },
    changeCount (value) {
      // this.errors.clear()
      if (value) {
        this.$eventBus.emit('changeCount', { position: this.position, count: value })
      }
    }
  }
}
</script>

<style scoped>
  .bg-color-grey {
    background-color: #f1f1f1;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    padding: 15px;
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 28px;
    height: 28px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 12px;
    left: 10px;
    top: 4px;
  }
</style>
