import { HTTP } from '@/api/http_utils'

// let promises = {}
// let lastSuccessful = {}

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  getBalance ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/balances').then(balance => {
        resolve(balance)
      })
    })
  },

  getEmployeesWithActivityCount ({ commit }, [id, from, to]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${id}/employees_with_activity?start=${from}&end=${to}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCards ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/cards').then(cards => {
        resolve(cards)
      })
    })
  },

  setCard ({ commit, rootState }, data) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/cards', data).then(card => {
        resolve(card)
      }).catch(err => {
        reject(err)
      })
    })
  },

  patchCard ({ commit, rootState }, [cardId, data]) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.patch('/companies/' + companyId + '/cards/' + cardId, data).then(card => {
        resolve(card)
      })
    })
  },

  deleteCard ({ commit, rootState }, cardId) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.delete('/companies/' + companyId + '/cards/' + cardId).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getInvoices ({ commit, rootState }, data = {}) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      const responseData = { responseType: data.blob ? 'blob' : 'json' }
      const payload = data.payload ? data.payload : ''
      HTTP.get('/companies/' + companyId + '/invoices' + payload, responseData).then(invoices => {
        resolve(invoices)
      })
    })
  },

  getUnpaidedInvoices ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/invoices/unpaided').then(invoices => {
        resolve(invoices)
      })
    })
  },

  getInvoiceById ({ commit, rootState }, invoiceId) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/invoices/' + invoiceId).then(invoice => {
        resolve(invoice)
      })
    })
  },

  paymentUnpaidInvoicesByCard ({ commit, rootState }, cardId) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/payments/card/${cardId}/invoices`).then(subscription => {
        resolve(subscription)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getTransactions ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/transactions').then(transactions => {
        resolve(transactions)
      })
    })
  },

  getSubscription ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/subscriptions').then(subscription => {
        resolve(subscription)
      })
    })
  },

  setSubscription ({ commit, rootState }, data) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/subscriptions', data).then(subscription => {
        resolve(subscription)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteSubscription ({ commit, rootState }, id) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.delete('/companies/' + companyId + '/subscriptions/' + id).then(subscription => {
        resolve(subscription)
      }).catch(err => {
        reject(err)
      })
    })
  },

  setStripeCharge ({ commit, rootState }, data) {
    const companyId = rootState.company.companyId

    return new Promise(resolve => {
      HTTP.post('/companies/' + companyId + '/payments/card', data).then(response => {
        resolve(response)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
