<template>
  <div>
    <div class="row pb-2">
      <div class="col-lg-12">
        <div class="text-primary text-uppercase font-weight-bold">{{ $t('DEVELOPERS_SECTION') }}</div>
        <div class="col-lg-12" v-if="webhooks.length === 0">
          <div class="text-center">
            <div class="card-body text-center">
              <img class="img-fluid" style="max-height: 350px" src="../../../../public/static/images/App/Splash_1_01.png"/>
              <h5 class="m-3">
                {{$t('Start creating your first webhook')}}
              </h5>
              <button
                type="button"
                @click="addHook"
                data-toggle="modal"
                data-target="#CreateApplicationModal"
                class="btn btn-primary">
                {{ $t('New webhook') }}
              </button>
            </div>
          </div>
        </div>
        <div v-for="(hook, index) in webhooks" :key="index" class="card my-2">
          <webhook-form-component
            :hook="hook"
          />
        </div>
        <div class="text-right">
          <button
            v-if="this.showAddButton"
            class="btn btn-sm btn-primary rounded-pill mx-1"
            role="button"
            @click="addHook">
            <i class="icon-plus icon-default text-white"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebhookFormComponent from '@/components/Developer/Components/WebhookFormComponent'

export default {
  name: 'WebhooksSettings',
  components: { WebhookFormComponent },
  data () {
    return {
      webhooks: []
    }
  },
  created () {
    this.$store.dispatch('getWebhooks', this.companyId)
    this.$store.dispatch('getWebhookEventsList', this.companyId)
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    webhooksIndexed () {
      return this.$store.getters.webhooksIndexed
    },
    showAddButton () {
      if (!this.isEmployeeHasPermission('create webhook')) {
        return false
      }
      // console.log(this.webhooks.length, this.$store.getters.webhooks.length)
      return this.webhooks.length > 0
    },
    webhookEvents () {
      return this.$store.getters.webhookEvents
    }
  },
  watch: {
    webhookEvents () {
      this.updateHooksList()
    },
    webhooksIndexed () {
      if (this.$store.getters.webhooks.length > 0) {
        this.webhooks = [...this.$store.getters.webhooks]
        this.updateHooksList()
      }
    }
  },
  methods: {
    updateHooksList () {
      this.webhooks.forEach(item => {
        if (!item.events) {
          item.events = {}
        }
        this.webhookEvents.forEach(model => {
          if (!item.events[model]) {
            item.events[model] = {
              event_model: model,
              event_type: {
                created: false,
                updated: false,
                deleted: false
              }
            }
          }
        })
      })
    },
    addHook () {
      this.webhooks.push({
        is_active: false,
        secret: null,
        callback_url: null,
        events: {}
      })
      this.updateHooksList()
    }
  }
}
</script>

<style scoped>

</style>
