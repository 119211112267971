<template>
  <div>
    <schedule-settings
      v-if="step === 2"
      :settings="settings"
      :project="project"
      :type="'advanced'"
      :shiftsControl="shiftsControl"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </schedule-settings>

    <demand-templates-setting
      v-if="step === 3 && schedule.scheduleSettings.activeTab === 'demand'"
      :workingTime="workingTime"
      :type="'advanced'"
      :project="project"
      :shiftsControl="shiftsControl"
      :minDurationWorkingMinutesDay="minDurationWorkingMinutesDay"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </demand-templates-setting>

    <working-time-settings
      v-if="step === (schedule.scheduleSettings.activeTab === 'demand' ? 4 : 3)"
      :workingTime="workingTime"
      :type="'advanced'"
      :shiftsControl="shiftsControl"
      :minDurationWorkingMinutesDay="minDurationWorkingMinutesDay"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </working-time-settings>

    <employees
      v-if="step === (schedule.scheduleSettings.activeTab === 'demand' ? 5 : 4)"
      :selectedUsers="employees"
      :type="'advanced'"
      :project="project"
      :requiredWorkers="requiredWorkers"
      :shiftsControl="shiftsControl"
      :settings="settings"
      @save="save"
      @prev="prev">
    </employees>

    <preview-schedule
      v-if="step === (schedule.scheduleSettings.activeTab === 'demand' ? 6 : 5)"
      :previewShifts="previewShifts"
      :conflictShifts="conflictShifts"
      :startDate="startDate"
      :project="project"
      @save="save"
      @prev="prev">
    </preview-schedule>
  </div>
</template>

<script>
import ScheduleSettings from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/ScheduleSettings'
import Employees from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/Employees'
import WorkingTimeSettings from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/WorkingTimeSettings'
import DemandTemplatesSetting from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/DemandTemplatesSetting'
import PreviewSchedule from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/PreviewSchedule'

export default {
  name: 'ScheduleAdvanced',
  components: {
    ScheduleSettings: ScheduleSettings,
    WorkingTimeSettings: WorkingTimeSettings,
    Employees: Employees,
    PreviewSchedule: PreviewSchedule,
    DemandTemplatesSetting: DemandTemplatesSetting
  },
  props: {
    step: Number,
    shiftsControl: Object,
    settings: Object,
    employees: Object,
    workingTime: Object,
    project: Number,
    previewShifts: Object,
    conflictShifts: Array,
    minDurationWorkingMinutesDay: Number,
    requiredWorkers: Number,
    startDate: [String, Date],
    schedule: Object
  },
  // created () {
  //   console.log('employees', this.employees)
  //   console.log('settings', this.settings)
  //   console.log('shiftsControl', this.shiftsControl)
  //   console.log('workingTime', this.workingTime)
  //   console.log('previewShifts', this.previewShifts)
  // },
  methods: {
    next (value) {
      this.$emit('next', {
        step: value.step,
        data: value.data
      })
    },
    prev (value) {
      this.$emit('prev', {
        step: value.step,
        data: value.data
      })
    },
    save (value) {
      this.$emit('save', {
        step: value.step,
        data: value.data
      })
    }
  }
}
</script>

<style scoped>

</style>
