<template>
  <div id="geolocationAccordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">{{ $t('GEOLOCATION_HEADER') }} <ui-hint v-if="moduleInfo" :content="moduleInfo"/></h5>
        <div class="font-weight-bold text-warning mt-2">{{ $t('NEED_ATTENDANCE_MODULE') }}</div>
      </div>

      <div
        id="collapseGeolocationSettings"
        class="collapse show"
        aria-labelledby="headingOne"
        data-parent="#geolocationAccordion">
        <div class="card-body">
          <modal-component
            :id="'GeolocationSettingsModal'"
            @closeModal="closeModal"
            :modalClass="'modal-dummy'"
            :modalBodyClass="'dummy-popup'"
            :hideFooter="true"
            :hideHeader="true"
            >
            <template #body>
              <dummy-payment-required
                @activate="activate"
                :dummyInfo="dummyInfo"
                :isModal="true"
                >
              </dummy-payment-required>
            </template>
          </modal-component>
          <el-divider content-position="left">
            <span class="text-primary text-uppercase font-weight-bold">
              {{ $t('GEOLOCATION_MAIN_SETTINGS') }}
            </span>
          </el-divider>
          <Form ref="observer" as="div" class="form-row mt-3">
            <div class="col-12 col-md-6" @click="handleActivation()"
                 :data-toggle="!isModuleActive ? 'modal' : null"
                 :data-target="!isModuleActive ? '#GeolocationSettingsModal' : null">
              <div class="mb-1 col-lg-12 my-2">
                <ui-checkbox
                  name="settings.is_geolocation_enable"
                  v-model="settings.is_geolocation_enable"
                  :label="$t('IS_GEOLOCATION_ENABLE')"
                  :hint="$t('IS_GEOLOCATION_ENABLE_HINT')"
                  :disabled="!isModuleActive"
                />
              </div>
              <div class="mb-2 col-lg-12 my-2">
                <ui-number-input
                  :disabled="!isModuleActive"
                  name="geolocation_distance_filter"
                  :label="$t('GEOLOCATION_DISTANCE_FILTER')"
                  :hint="$t('GEOLOCATION_DISTANCE_FILTER_HINT')"
                  v-model="settings.geolocation_distance_filter"
                  :validation="{required: true, integer: true, min_value: 10}"
                  :precision="0"
                  :step="1"
                  :min="10"
                />
              </div>
              <div class="mb-2 col-lg-12">
                <ui-multi-select
                  name="types"
                  :label="$t('EXCLUDED_POSITIONS_TITLE')"
                  :hint="$t('EXCLUDED_POSITIONS_HINT')"
                  v-model="settings.geolocation_excluded_positions"
                  :clearable="true"
                  class="w-100"
                  :options="positions"
                  keyName="id"
                  valueName="id"
                  labelName="title"
                  :disabled="!isModuleActive"
                />
              </div>
            </div>
            <div class="col-lg-12">
              <el-divider content-position="left">
                <span class="text-primary text-uppercase font-weight-bold">
                  {{ $t('GEOLOCATION_NOTIFICATION_SETTINGS') }}
                </span>
              </el-divider>
            </div>
            <div class="col-12 col-md-6" @click="handleActivation()"
                 :data-toggle="!isModuleActive ? 'modal' : null"
                 :data-target="!isModuleActive ? '#GeolocationSettingsModal' : null">
              <div class="mb-1 col-lg-12 my-2">
                <ui-checkbox
                  name="settings.left_location_notification"
                  v-model="settings.left_location_notification"
                  :label="$t('LEFT_LOCATION_NOTIFICATION')"
                  :hint="$t('LEFT_LOCATION_NOTIFICATION_HINT')"
                  :disabled="!isModuleActive"
                />
              </div>
              <div class="mx-2">
                <div class="mb-2 col-lg-12">
                  <ui-number-input
                    :disabled="!isModuleActive || !settings.left_location_notification"
                    name="left_location_notification_time"
                    :label="$t('Duration in minutes')"
                    v-model="settings.left_location_notification_time"
                    :validation="{required: true, integer: true, min_value: 5}"
                    :precision="0"
                    :step="1"
                    :min="5"
                  />
                </div>
                <div class="mb-2 col-lg-12">
                  <ui-number-input
                    :disabled="!isModuleActive || !settings.left_location_notification"
                    name="left_location_notification_distance"
                    :label="$t('Distance in meters')"
                    v-model="settings.left_location_notification_distance"
                    :validation="{required: true, integer: true, min_value: 10}"
                    :precision="0"
                    :step="1"
                    :min="10"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsWorkLocationControl',
  components: { ModalComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      geolocationModuleData: null,
      dummyInfo: {},
      dummy: false,
      companyModules: [],
      module: null,
      settings: {
        is_geolocation_enable: true,
        geolocation_distance_filter: 50,
        geolocation_excluded_positions: [],
        left_location_notification: false,
        left_location_notification_time: 5,
        left_location_notification_distance: 10
      },
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany',
      positions: 'positions'
    })
  },
  methods: {
    activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'geolocation')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 25) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.settings = this.module.settings
            if (!this.settings.is_geolocation_enable) {
              this.settings.is_geolocation_enable = false
            }
            if (!this.settings.geolocation_distance_filter) {
              this.settings.geolocation_distance_filter = 50
            }
            if (!this.settings.geolocation_excluded_positions) {
              this.settings.geolocation_excluded_positions = []
            }
            if (!this.settings.left_location_notification) {
              this.settings.left_location_notification = false
            }
            if (!this.settings.left_location_notification_time) {
              this.settings.left_location_notification_time = 5
            }
            if (!this.settings.left_location_notification_distance) {
              this.settings.left_location_notification_distance = 10
            }
          }
        }
      })
      this.$store.dispatch('getPositions', this.$store.getters.companyId)
      // модуль посещаемости активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 26]).then(settings => {
        this.isModuleActive = true
      }).catch(err => {
        if (err.response.data.module) {
          this.geolocationModuleData = err.response.data
          this.isModuleActive = false
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      if (!this.isModuleActive) {
        this.dummyInfo = this.setupDummyData(this.geolocationModuleData)
      }
      this.dummy = true
    },
    async submitForm () {
      try {
        const result = await this.$refs.observer.validate()
        if (result.valid) {
          await this.$store.dispatch('updateModuleSettings', [
            this.$store.getters.companyId,
            this.module.id,
            {
              settings: this.settings
            }
          ])
          this.$emit('success')
        }
      } catch (error) {
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>
</style>
