<template>
  <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required"/>
  <el-time-picker
    :name="name"
    v-model="innerValue"
    @change="(val) => $emit('change', val)"
    class="w-100"
    size="large"
    :placeholder="placeholder"
    :class="{'is-invalid': isInvalid}"
    :prefix-icon="prefixIcon"
    :value-format="valueFormat"
    :format="'HH:mm'"
    :picker-options="pickerOptions"
    :clearable="!!clearable"
    :disabled="!!disabled"
    :teleported="false"
  >
  </el-time-picker>
  <Field :name="name" v-model="innerValue" v-slot="{ errorMessage }" :rules="validation" as="div">
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1" />
  </Field>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'period-picker',
  mixins: [momentMixin],
  emits: ['update:modelValue', 'change'],
  props: {
    name: String,
    validation: Object,
    value: [String, Object, Date, Array],
    modelValue: [String, Object, Date, Array],
    clearable: Boolean,
    disabled: Boolean,
    label: String,
    hint: String,
    pickerFormat: String,
    pickerOptions: {
      type: Object,
      default: () => {
        return {
          start: '00:00',
          step: '00:05',
          end: '24:00'
        }
      }
    },
    valueFormat: String,
    placeholder: String,
    prefixIcon: {
      type: String,
      default: () => {
        return 'disabled'
      }
    }
  },

  data () {
    return {
      isInvalid: false
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (values) {
        this.$emit('update:modelValue', values)
      }
    },
    dateFormat () {
      if (this.pickerFormat) {
        return this.pickerFormat
      }
      return this.localeDateFormatElementUi
    }
  },

  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }

}
</script>

<style scoped lang="scss">

</style>
