<template>
  <Form ref="observe" as="div">
    <div class="row" data-cy="working-time-settings">
      <template v-if="patternType !== '5/2'">
        <div class="col-lg-12 pb-4">
          <span class="text-primary text-uppercase font-weight-bold">{{ $t('working_template_settings_description') }}</span>
        </div>
        <el-alert
          v-if="shiftsControlState.working_hours && (salary.quotas_per_day > 0 && maxDuration > salary.quotas_per_day)"
          class="mt-1"
          data-cy="alert-template-err"
          :title="$t('key_template_time_is_longer_salary_setting')"
          :closable="false"
          type="error"
        >
        </el-alert>
        <div class="col-lg-12 mb-2">
          <ui-checkbox
            name="working_hours"
            class="mb-auto"
            v-model="shiftsControlState.working_hours"
            :disabled="shiftsControlState.week_overtime_control_type === 'block_overtime'"
            :label="$t('use_company_working_hours_settings')"
            :hint="$t('use_company_working_hours_settings_hint')"
            data-cy="working-hours-checkbox"
          />
        </div>
        <div class="col-lg-12">
          <div class="col-lg-12 mb-2 ml-1">
            {{ $t('key_working_hours_per_day', { hours: salary.quotas_per_day }) }}
          </div>
          <div class="col-lg-12 mb-2 ml-1">
            {{ $t('key_working_hours_per_week', { hours: salary.quotas_per_week }) }}
          </div>
          <div class="col-lg-12 mb-2 ml-1">
            {{ $t('key_working_hours_per_month', { hours: salary.quotas_per_month }) }}
          </div>
          <div class="col-lg-12 mb-2 ml-1">
            <ui-checkbox
              name="takeFromPosition"
              class="mb-auto"
              :key="'manager'"
              v-model="takeFromPosition"
              :disabled="true"
              :label="$t('TAKE_FROM_POSITION')"
              :hint="$t('TAKE_FROM_POSITION_HINT')"
            />
          </div>
        </div>
        <div class="col-lg-12" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="weekends">{{ $t("Maximum shifts in a row") }}</label>
          <ui-hint :content="$t('MAXIMUM_SHIFTS_IN_ROW_HINT')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              name="break_between_shifts"
              :precision="0"
              :step="1"
              :min="0"
              v-model="shiftsControlState.break_between_shifts"
              :validation="{required: true, max_value: 100, min_value: 0, numeric: true}"
            />
          </div>
        </div>
        <div class="col-lg-12" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="minimum_break_in_row">{{ $t("Minimum breaks in a row") }}</label>
          <ui-hint :content="$t('MINIMUM_BREAKS_IN_ROW_HINT')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              name="minimum_break_in_row"
              :precision="0"
              :step="1"
              :min="0"
              v-model="shiftsControlState.minimum_breaks_in_row"
              :validation="{required: true, max_value: 100, min_value: 0, numeric: true}"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-3" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="maximum_break_in_row">{{ $t("Maximum breaks in a row") }}</label>
          <ui-hint :content="$t('MAXIMUM_BREAKS_IN_ROW_HINT')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              name="maximum_break_in_row"
              :precision="0"
              :step="1"
              :min="0"
              v-model="shiftsControlState.maximum_breaks_in_row"
              :validation="{required: true, max_value: 100, min_value: 0, numeric: true}"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-3" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="maximum_break_in_row">{{ $t("use_default_work_settings") }}</label>
          <ui-hint :content="$t('MAXIMUM_BREAKS_IN_ROW_HINT')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              id="weekends-2"
              name="weekends"
              :precision="0"
              :step="1"
              :min="0"
              v-model="workingTimeSettings.weekends"
              :validation="{required: true, max_value: 6, min_value: 0, numeric: true}"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-3" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="maximum_break_in_row">{{ $t("minimum_break_between_shifts") }}</label>
          <ui-hint :content="$t('minimum_break_between_shifts_hint')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              id="minimum_break_between_shifts"
              name="minimum_break_between_shifts"
              :precision="2"
              :step="0.1"
              :min="0"
              v-model="shiftsControlState.minimum_break_between_shifts"
              :validation="{required: true, max_value: 720, min_value: 0, decimal: 2}"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-3" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="maximum_break_in_row">{{ $t("minimum_break_between_shifts_for_women") }}</label>
          <ui-hint :content="$t('minimum_break_between_shifts_for_women_hint')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              id="minimum_break_between_shifts_for_women"
              name="minimum_break_between_shifts_for_women"
              :precision="2"
              :step="0.1"
              :min="0"
              v-model="shiftsControlState.minimum_break_between_shifts_for_women"
              :validation="{required: true, max_value: 720, min_value: 0, decimal: 2}"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-3" v-if="['by_availability', 'advanced'].includes(type)">
          <label class="text-wrap d-inline-block font-weight-bold" for="maximum_break_in_row">{{ $t("minimum_break_in_row_per_week") }}</label>
          <ui-hint :content="$t('minimum_break_in_row_per_week_hint')" />
          <div class="col-lg-6 mb-2 ml-1">
            <ui-number-input
              id="minimum_break_in_row_per_week"
              name="minimum_break_in_row_per_week"
              v-model="shiftsControlState.minimum_break_in_row_per_week"
              :precision="2"
              :step="0.1"
              :min="0"
              :validation="{required: true, max_value: 720, min_value: 0, decimal: 2}"
            />
          </div>
        </div>
      </template>
      <div class="col-lg-12 mb-2" v-if="(['by_availability', 'advanced'].includes(type) && scheduleState.scheduleSettings.activeTab !== 'demand') || patternType === '5/2'">
        <span class="text-wrap d-inline-block font-weight-bold" for="all_users">
          {{ $t('custom_rules') }}
        </span>
        <ui-hint :content="$t('custom_rules_hint')" />
      </div>
      <div class="col-lg-12 mb-2" v-if="(['by_availability', 'advanced'].includes(type) && scheduleState.scheduleSettings.activeTab !== 'demand') || patternType === '5/2'">
        <div class="col-lg-12 mb-2 ml-1">
          <div v-for="(rule, rule_key) in scheduleState.rules" :style="{background: !(rule_key % 2) ? '#e9ecef' : '#fff'}" class="p-1 rounded" :key="rule_key">
            <Rule
              :item="rule"
              :index="rule_key"
              :showItemConfig="true"
              :disabledActions="!true"
              :scheduleSettings="scheduleState.scheduleSettings"
              @deleteItem="deleteRule(rule_key)"
              @validateResult="validateResult">
            </Rule>
          </div>
        </div>
        <button
          class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm ml-3"
          aria-label="Close"
          @click="addRule"
        >
          {{ $t('Add') }}
        </button>
      </div>
      <div class="col-lg-12 d-inline-flex py-2">
        <button
          id="demo_master_prev_step"
          class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
          @click="prev">
          {{ $t('Previous')}}
        </button>
        <button
          id="demo_master_next_step"
          data-cy="next-step"
          class="btn btn-primary rounded-pill shadow-sm"
          :disabled="(shiftsControlState.working_hours && (salary.quotas_per_day > 0 && maxDuration > salary.quotas_per_day)) || ((type === 'advanced' || patternType === '5/2') && scheduleState.rules.length > 0 && !validateRulesResult)"
          @click="next">
          {{ $t('Next step')}}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import Rule from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/RuleItemComponent'
import moment from 'moment'

export default {
  name: 'WorkingTimeSettings',
  components: { Rule },
  mixins: [errorMixin],
  data () {
    return {
      takeFromPosition: false,
      workingTimeSettings: {
        timeControl: this.overtimeSelect(),
        weekends: '2',
        workingHoursDay: '8',
        workingHoursWeek: '40',
        workingHoursMonth: '184',
        break_between_shifts: 0
      },
      timeControlSettings: {
        missing: this.$i18n?.t('No control'),
        daysOff: this.$i18n?.t('By days off')
      },
      maxDuration: 0,
      validateRules: {},
      validateRulesResult: false,
      shiftsControlState: {},
      scheduleState: {}
    }
  },
  props: {
    workingTime: Object,
    shiftsControl: Object,
    schedule: Object,
    minDurationWorkingMinutesDay: Number,
    salarySettings: Object,
    patternType: {
      type: String,
      default: ''
    },
    type: String
  },
  created () {
    this.shiftsControlState = this.shiftsControl
    this.scheduleState = this.schedule
    if (this.patternType !== '5/2') {
      this.checkDuration()
      if (Object.keys(this.workingTime).length > 0) {
        this.workingTimeSettings = this.workingTime
      }
      this.takeFromPosition = this.salary.quotas_per_month_from_position

      this.workingTimeSettings.timeControl = this.overtimeSelect()
      this.workingTimeSettings.workingHoursDay = this.minDayDuration
    }
  },
  computed: {
    salary () {
      return this.$store.getters.company.salary
    },
    showRadioButton () {
      return +this.salary.minimum_break_between_shifts > 0 || +this.salary.minimum_break_between_shifts_for_women > 0 || +this.salary.minimum_break_in_row_per_week > 0
    },
    minDayDuration () {
      return Math.ceil(this.minDurationWorkingMinutesDay / 60 * 100) / 100
    }
  },
  watch: {
    shiftsControl () {
      this.shiftsControlState = this.shiftsControl
    },
    schedule () {
      this.scheduleState = this.schedule
    },
    minDurationWorkingMinutesDay () {
      this.workingTimeSettings.workingHoursDay = this.minDayDuration
    }
  },
  methods: {
    duration (template) {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + template.time_to).diff(moment(moment().format('YYYY-MM-DD') + ' ' + template.time_from), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        value = 24
      }

      if (value <= 0) {
        value = value + 24
      }

      if (template.breaks && template.breaks.length > 0) {
        template.breaks.forEach(element => {
          if (!element.billable) {
            value -= element.duration
          }
        })
      } else if (this.salary && !this.salary.pay_breaks && template.break_time) {
        value -= template.break_time / 60.0
      }

      if (value < 0) {
        value = 0
      }
      return Math.round(value * 100) / 100
    },
    validateResult (data) {
      const { validate, index } = data
      const validateRules = this.validateRules
      validateRules[index] = validate
      this.validateRules = validateRules
      this.validateRulesResult = !Object.values(validateRules).includes(false)
    },
    addRule () {
      this.scheduleState.rules.push({
        action_time: 'before',
        action_objects: [],
        rule: 'forbidden',
        objects: []
      })
    },
    deleteRule (ruleId) {
      this.scheduleState.rules.splice(ruleId, 1)
      const validateRules = this.validateRules
      delete validateRules[ruleId]
      this.validateRules = validateRules
      this.validateRulesResult = !Object.values(validateRules).includes(false)
    },
    checkDuration () {
      let maxDuration = 0

      this.scheduleState.scheduleSettings.templates.forEach(element => {
        const duration = this.duration(element)
        if (duration > maxDuration) {
          maxDuration = duration
        }
      })
      this.scheduleState.scheduleSettings.preloadedTemplates.forEach(element => {
        const duration = this.duration(this.scheduleState.scheduleSettings.preloadedTemplatesData[element])
        if (duration > maxDuration) {
          maxDuration = duration
        }
      })
      this.maxDuration = maxDuration
    },
    overtimeSelect () {
      return 'daysOff'
    },
    next () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          this.$emit('next', {
            step: 'workingTime',
            data: this.workingTimeSettings
          })
        }
      })
    },
    prev () {
      this.$emit('prev', {
        step: 'workingTime',
        data: this.workingTimeSettings
      })
    }
  }
}
</script>

<style scoped>

</style>
