<template>
  <el-button
    class="my-2"
    type="primary"
    @click="uploadFormEnable = !uploadFormEnable"
  >
    {{ $t('UPLOAD_TASK_FILES') }}
  </el-button>
  <template v-if="uploadFormEnable">
    <Form ref="observer" as="div">
      <div class="row">
        <div class="col">
          <ui-select
            :label="$t('SELECT_FILE_ASSIGNMENT')"
            name="fileAssignment"
            v-model="fileAssignment"
            :clearable="true"
            :allow-create="true"
            :options="fileAssignmentOptions"
            @change="changeFileAssignment"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ui-textarea-input
            name="comment"
            :label="$t('Comment')"
            v-model="comment"
            :validation="{required: false, max: 500}"
            :rows="2"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ui-file-upload
            name='file_up'
            :label="$t('TASK_FILES_UPLOAD')"
            :upload="upload"
            :auto-upload="false"
            :multiple="false"
            @onSuccess="success"
            @onError="error"
            :beforeUpload="(file) => beforeUpload(file)"
            :fileLimit="maxFileSize"
            :slotFile="true"
            @uploadRef="handleUploadRef"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <el-button class="mx-1 my-1" type="success" @click="submitUpload">
            {{ $t('UPLOAD_TO_SERVER') }}
          </el-button>
        </div>
      </div>
    </Form>
  </template>
  <el-divider/>
  <div class="text-primary title">{{ $t('Task files') }}</div>
  <template v-if="files && files.length > 0">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="p-2 file-block" v-for="(file) in files" :key="'task_file_' + file.id">
            <div class="d-flex justify-content-end">
              <i
                v-if="file.mime_type.slice(0, 5) === 'image'"
                class="icon-eye text-primary m-1"
                @click="handlePictureCardPreview(file)"
                style="cursor: pointer; font-size: 20px"
              >
              </i>
              <i
                class="icon-download text-primary m-1"
                @click="handleDownloadObject(file)"
                style="cursor: pointer; font-size: 20px"
              >
              </i>
              <i
                class="icon-trash text-danger m-1"
                @click="deleteFile(file.id)"
                style="cursor: pointer; font-size: 20px"
              >
              </i>
            </div>
            <div>
              <div
                v-if="file.mime_type && file.mime_type.slice(0, 5) === 'image'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
              ></div>
              <div
                v-else-if="file.mime_type === 'application/pdf'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
              ></div>
              <div v-else
                   class="img-thumbnail uploaded-file"
                   v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
              ></div>
            </div>
            <div class="text-break small mt-2">
              <span class="font-weight-bold">{{ $t('file_name') }}:</span> {{ file.name }}
            </div>
            <div class="text-break small">
              <span class="font-weight-bold">{{ $t('file_assignment') }}:</span>
              {{ file.file_assignment ? file.file_assignment : $t('no_file_assignment') }}
            </div>
            <div class="text-break small">
              <span class="font-weight-bold">{{ $t('comment') }}:</span>
              {{ file.comment ? file.comment : $t('no_file_comment') }}
            </div>
            <div class="text-break small">
              <span class="font-weight-bold">{{ $t('Created at') }}:</span>
              {{ convertDate(file.created_at) }}
            </div>
            <div class="text-break small">
              <span class="font-weight-bold">{{ $t('Uploaded by') }}:</span>
              {{ getEmployee(file.uploader_id) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible">
      <img width="100%" :src="dialogUrl" alt="">
    </el-dialog>
  </template>
  <template v-else>
    <div class="text-center p-5">
      {{ $t('No task files found') }}
    </div>
  </template>
  <template v-if="showChecklistFiles">
    <el-divider />
    <div class="text-primary title">{{ $t('Checklist files') }}</div>
    <template v-if="checklistFiles.length > 0">
      <div v-for="(field, key) in checklistFiles" :key="'existing-' + field['title'] + '-' + key"
           class="mb-2">
        <div class="row m-1 card">
          <div class="col-md-12 p-2">
            <template v-if="field['value'] && field['value'].length > 0">
              <div class="title">
                {{ field['title'] }}
              </div>
              <div class="file-block p-2" v-for="(file, index) in field['value']"
                   :key="'file_' + field['id'] + index">
                <div class="d-flex justify-content-end">
                  <i
                    v-if="file.mime_type.slice(0, 5) === 'image'"
                    class="icon-eye text-primary m-1"
                    @click="handlePictureCardPreview(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    class="icon-download text-primary m-1"
                    @click="handleDownloadObject(file)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                  <i
                    v-if="!disabled"
                    class="icon-trash text-danger m-1"
                    @click="deleteFile(field['id'], index)"
                    style="cursor: pointer; font-size: 20px"
                  >
                  </i>
                </div>
                <div>
                  <div
                    v-if="file.mime_type && file.mime_type.slice(0, 5) === 'image'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div
                    v-else-if="file.mime_type === 'application/pdf'"
                    class="img-thumbnail uploaded-file"
                    v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                  <div v-else
                       class="img-thumbnail uploaded-file"
                       v-bind:style="{'background-image': 'url('+getFilePreview(file)+')'}"
                  ></div>
                </div>
                <div class="text-truncate align-bottom mt-2">{{ file.name }}</div>
              </div>
              <el-dialog v-model="dialogVisible">
                <img width="100%" :src="dialogUrl" alt="">
              </el-dialog>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center p-5">
        {{ $t('No checklist files found') }}
      </div>
    </template>
  </template>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'
export default {
  name: 'TaskFilesComponent',
  mixins: [momentMixin],
  data () {
    return {
      maxFileSize: 102400,
      upload: {},
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      uploadFormEnable: false,
      comment: '',
      fileAssignment: null,
      fileAssignmentOptions: [],
      files: [],
      uploadRef: null,
      checklistFiles: []
    }
  },
  props: {
    taskId: Number,
    taskFiles: Array,
    checklistItems: Array,
    disabled: Boolean,
    showChecklistFiles: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.upload = {
      url: process.env.VUE_APP_ROOT_API + 'companies/' + this.$store.getters.companyId + '/tasks/' + this.taskId + '/task_file',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
      },
      payload: {}
    }
    this.fileAssignmentOptions = this.taskModule.settings.file_assignment_list ? this.taskModule.settings.file_assignment_list : []
    // console.log(this.fileAssignmentOptions)
    this.files = [...this.taskFiles]
    this.checklistFiles = this.checklistItems.filter(item => item.field_type === 'file')
  },
  emits: ['fileUpdated'],
  computed: {
    taskModule () {
      return this.$store.getters.ModulesByCompany.filter(item => item.module.id === 20)[0]
    },
    employees () {
      return this.$store.getters.employees
    }
  },
  mounted () {
  },
  methods: {
    success () {
      // this.$emit('fileUpdated')
      // this.toastSuccess(this.$t('File uploaded'))
      this.$store.dispatch('getTask', [this.companyId, this.taskId]).then(response => {
        this.uploadFormEnable = false
        this.comment = ''
        this.fileAssignment = null
        if (response.task_files) {
          this.files = [...response.task_files]
        }
      })
    },
    error () {
      this.toastError(this.$t('Access denied'))
    },
    beforeUpload (file) {
      const formats = [
        'application/pdf',
        'image/jpeg',
        'image/gif',
        'image/jpg',
        'image/png',
        'video/quicktime',
        'video/mp4',
        'video/avi',
        'video/mov'
      ]

      if (formats.indexOf(file.type) === -1) {
        this.toastError(this.$i18n?.t('File format should be: jpeg, jpg, png, gif, mp4, mov, avi, pdf'))
        return false
      }

      if (file.size / 1024 > this.maxFileSize) {
        this.toastError(this.$i18n?.t('File size can not exceed 102400 kb!'))
        return false
      }
      if (this.comment) {
        this.upload.payload.comment = this.comment
      }
      if (this.fileAssignment) {
        this.upload.payload.file_assignment = this.fileAssignment
      }
    },
    handlePictureCardPreview (file) {
      this.dialogUrl = file.url
      this.dialogVisible = true
      this.dialogType = file.mime_type
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    deleteFile (fileId) {
      this.$confirm(this.$t('This will permanently delete the file. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTaskFile', [this.companyId, this.taskId, fileId]).then(() => {
          this.files = this.files.filter(item => item.id !== fileId)
        })
      }).catch(() => {})
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../../public/static/images/App/file.png')
      }
    },
    changeFileAssignment (val) {
      this.fileAssignment = val
    },
    submitUpload () {
      if (this.uploadRef) {
        this.uploadRef.submit()
      }
    },
    handleUploadRef (ref) {
      this.uploadRef = ref
    },
    convertDate (date) {
      return moment(date).format(this.localeDateTimeFormat)
    },
    getEmployee (employeeId) {
      return this.employees.filter(item => item.id === employeeId)[0]?.full_name
    }
  }
}

</script>
<style>
.file-block {
  width: 190px !important;
}
</style>
