<template>
  <modal-component
    :id="'ImportFileTable'"
    :modalClass="'modal-xl'"
    @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Import Employees')}}</h5>
    </template>
    <template #body>
      <Form ref="observe" as="div">
        <div class="font-weight-bold">
          {{ $t('Please map the fields from the uploaded file to Shifton system filelds.')}}
        </div>
        <div class="row align-items-end">
          <div class="col px-0">
            <table class="table table-hover table-responsive">
              <thead v-if="file_preload">
              <tr>
                <th v-for="(item, key) in file_preload[0]" :key="key" class="p-1 m-0">
                  <ui-select
                    :name="key + '__name'"
                    v-model="fileRows[key]"
                    :clearable="true"
                    :filterable="false"
                    class="w-100"
                    :validation="{required: true}"
                    :options="select_options"
                    :key-name="'index'"
                    :label-name="'name'"
                    :value-name="'index'"
                    :disabled-option-callback="checkUsedOptions"
                  />
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, row_key) in file_preload" :key="row_key">
                <td v-for="(item, key) in row" :key="key" class="p-1 m-0 text-wrap">
                  {{ item }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col">
            <ui-checkbox
              name="invite"
              v-model="invite"
              :disabled="!isConfirmed"
              :label="$t('Send invite')"
            />
            <el-alert
              v-if="!isConfirmed"
              show-icon
              center
              :title="$t('UNCONFIRMED_PHONE_WARNING')"
              type="warning">
            </el-alert>
          </div>
          <div class="col" v-if="workMode !== 'field_service'">
            <ui-select
              v-model="projectId"
              :clearable="true"
              :filterable="false"
              class="w-100"
              name="projectId"
              id="projectId"
              :label="$t('Select project')"
              :validation="{required: false}"
              :options="projectChoices"
              :key-name="'value'"
              :label-name="'label'"
              :value-name="'value'"
            />
          </div>
        </div>
      </Form>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm mb-2" aria-label="Close"
                @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
// import { mapGetters } from 'vuex'

export default {
  name: 'importFileTable',
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      fileRows: {
        0: 'identifier_index',
        1: 'first_name_index',
        2: 'last_name_index',
        3: 'phone_index',
        4: 'email_index',
        5: 'job_part_index',
        6: 'position_index',
        7: 'skills_index'
      },
      select_options: [
        { name: this.$i18n?.t('identifier'), index: 'identifier_index' },
        { name: this.$i18n?.t('First name'), index: 'first_name_index' },
        { name: this.$i18n?.t('Last name'), index: 'last_name_index' },
        { name: this.$i18n?.t('Phone number'), index: 'phone_index' },
        { name: this.$i18n?.t('e-mail'), index: 'email_index' },
        { name: this.$i18n?.t('EMPLOYEE_JOB_PART'), index: 'job_part_index' },
        { name: this.$i18n?.t('Position'), index: 'position_index' },
        { name: this.$i18n?.t('Skills'), index: 'skills_index' }
      ],
      projectChoices: [
        {
          value: 'none',
          label: this.$t('None')
        }
      ],
      projectId: null,
      invite: false
    }
  },
  props: {
    file: null,
    file_preload: null,
    projectDefaultId: Number
  },
  computed: {
    isConfirmed () {
      return this.$store.getters.Profile.phone !== null
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    projectDefaultId () {
      this.init()
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getProjectsByCompany', this.companyId).then(response => {
        // console.log(response)
        this.projectChoices = [
          {
            value: 'none',
            label: this.$t('None')
          }
        ]
        response.forEach(project => {
          if (this.isEmployeeHasPermission('get projects by company') ||
            (this.isEmployeeHasPermission('get related projects full data') &&
              project.role === 'manager')) {
            this.projectChoices.push({
              value: project.id,
              label: project.name
            })
          }
        })
        if (this.projectChoices.length > 1) {
          if (this.projectDefaultId) {
            this.projectId = this.projectDefaultId
          } else {
            this.projectId = this.projectChoices[1].value
          }
        }
      })
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('closeFileTable')
    },
    checkUsedOptions ({ index }) {
      if (Object.values(this.fileRows).indexOf(index) !== -1) {
        return true
      }
      return false
    },
    save () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          const formData = new FormData()
          formData.append('company_id', this.$store.getters.companyId)
          formData.append('file', this.file)
          formData.append('preview', false)
          if (this.projectId && this.projectId !== 'none') {
            formData.append('project_id', this.projectId)
          }
          formData.append('send_invites', this.invite)
          for (const key in this.fileRows) {
            // formData[this.fileRows[key]] = key
            formData.append(this.fileRows[key], key)
          }
          // console.log(formData)
          this.$store.dispatch('importEmployees', formData).then(response => {
            if (response) {
              // let type = 'success'
              let body = `<div class="mb-3"><div class="row text-success font-weight-bold"><div class="col p-1 text-nowrap text-left">${this.$t('STATUS_SUCCESS')} ${response.success}</div></div>`
              if (response.restored.length > 0) {
                // type = 'warning'
                body += `<div class="row text-warning font-weight-bold border-top"><div class="col p-1 text-nowrap text-left">${this.$t('STATUS_RESTORED_TITLE')} ${response.restored.length}</div></div>`
                body += '<div class="row" style="max-height: 150px!important;"><div class="col">'
                response.restored.forEach(user => {
                  body += '<div class="row"><div class="col-6 text-nowrap  px-1 pb-1 text-left">'
                  body += user.last_name + ' ' + user.first_name
                  body += '</div><div class="col-6 text-nowrap px-1 pb-1">'
                  body += this.$t('STATUS_RESTORED')
                  body += '</div></div>'
                })
                body += '</div></div>'
              }
              if (response.duplicated.length > 0 || response.errors.length > 0) {
                // type = 'error'
                body += `<div class="row text-danger font-weight-bold border-top"><div class="col p-1 text-left">${this.$t('STATUS_FAIL_TITLE')} ${response.duplicated.length + response.errors.length}</div></div>`
                body += '<div class="row" style="max-height: 150px!important;"><div class="col">'
                if (response.errors.length > 0) {
                  response.errors.forEach(user => {
                    body += '<div class="row"><div class="col-6 text-nowrap px-1 pb-1 text-left">'
                    body += user.name
                    body += '</div><div class="col-6 px-1 text-nowrap pb-1">'
                    body += this.$t(user.error)
                    body += '</div></div>'
                  })
                }
                if (response.duplicated.length > 0) {
                  response.duplicated.forEach(user => {
                    body += '<div class="row"><div class="col-6 text-nowrap px-1 pb-1 text-left">'
                    body += user.last_name + ' ' + user.first_name
                    body += '</div><div class="col-6 text-nowrap px-1 pb-1 text-left">'
                    body += this.$t('STATUS_DUPLICATED')
                    body += '</div></div>'
                  })
                }
                body += '</div></div></div>'
              }
              this.closeModal()
              this.$eventBus.emit('refetchResources')
              this.$alert(body, this.$t('Import results'), {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'OK',
                customClass: 'alert-width',
                draggable: true,
                center: true,
                showClose: false
              })
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        } else {
          this.toastError(this.$i18n?.t('Select all fields'))
        }
      })
    }
  }
}
</script>
<style lang="scss">
.alert-width {
  min-width: 600px;
}
//.el-message-box__message {
//  width: 100%!important;
//}
</style>
