<template>
  <el-collapse-item
      class="px-2"
      v-show="showBlock"
      name="Schedules">
    <template #title>
      <div class="font-weight-bold">
        {{ $t('Schedules') }}
        <template v-if="isAdminOrManager && schedulesExpiring && Object.keys(schedulesExpiring).length > 0">
          <router-link :to="`/c/${companyId}/schedules`">
            <el-tooltip class="item" effect="light" :content="$t('SOME_SCHEDULES_ARE_ALMOST_EXPIRED')" placement="top">
              <i class="icon-warning btn-icon text-danger mx-1" />
            </el-tooltip>
          </router-link>
        </template>
      </div>
    </template>
    <!-- Расписания -->
    <div class="pr-1" v-show="showBlock">
      <div class="row my-1 border-bottom border-light">
        <div class="col">
          <ui-checkbox
            name="select_all"
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            {{ $t('Select all') }}
          </ui-checkbox>
        </div>
      </div>
      <template v-for="(items, index) in menuItemsSorted" :key="items[0] + '__g'">
        <ui-checkbox-group v-model="checkSchedule" @change="(val) => handleCheckScheduleChange(items[0])">
          <ui-checkbox
            :name="index + '__up_chk'"
            :custom-value="items[0]"
            :key="items[0] + '__'"
            class="w-100 pr-2"
            :indeterminate="isGroupIndeterminate[items[0]]"
            @change="(val) => { handleCheckScheduleChange(items[0]) }"
          >
            <el-tooltip class="item" effect="light" :show-after="1000" :content="schedulesInfo[items[0]]" placement="right">
              <div class="text-wrap text-truncate">
                <span class="text-dark text-truncate">{{ schedulesInfo[items[0]] }}</span>
                <template v-if="isAdminOrManager && schedulesExpiring && schedulesExpiring[items[0]]">
                  <template v-if="fromZoneToZone(schedulesExpiring[items[0]], 'UTC', $store.getters.actualTZ).isBefore(moment())">
                    <router-link :to="`/c/${companyId}/schedule/${items[0]}#resizeSchedule`">
                      <el-tooltip class="item" effect="light" :content="$t('SCHEDULE_IS_EXPIRED')" placement="top">
                        <i class="icon-warning btn-icon text-danger mx-1" />
                      </el-tooltip>
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link :to="`/c/${companyId}/schedule/${items[0]}#resizeSchedule`">
                      <el-tooltip class="item" effect="light" :content="$t('SCHEDULE_IS_ALMOST_EXPIRED')" placement="top">
                        <i class="icon-warning btn-icon text-warning mx-1" />
                      </el-tooltip>
                    </router-link>
                  </template>
                </template>
              </div>
            </el-tooltip>
          </ui-checkbox>
        </ui-checkbox-group>
        <ui-checkbox-group v-for="(value, index) in items[1]" v-model="selectedTemplates" @change="handleCheckedTemplatesChange" :key="value[0]">
          <!-- Скрываем кастомные темплейты, если для них нет шифтов на календаре -->
          <template v-if="!hideTemplate(value[0])">
            <div class="row" :key="'1-' + value[0]">
              <div class="col-10 text-truncate">
                <el-tooltip
                    class="item"
                    effect="light"
                    :show-after="1000"
                    :content="value[1][1]"
                    placement="right">
                  <div class="pl-2">
                    <ui-checkbox
                      :name="index + '__inner_chk'"
                      :checkbox-class="'w-100'"
                      :key="value[0]"
                      :custom-value="value[0]"
                    >
                      <div class="rounded text-wrap text-truncate w-100" :style="{background: value[1][2] ? value[1][2] : '', color: value[1][2] ? 'white' : 'black', padding: '5px'}">
                        <span class="text-truncate">
                          {{ value[1][1] }}
                        </span>
                      </div>
                    </ui-checkbox>
                  </div>
                </el-tooltip>
              </div>
              <div v-if="showAddButton(value[0])" :key="'btn-' + value[0]" class="col-2 px-0">
                <div class="text-center">
                  <el-tooltip class="item" effect="light" :content="$t('Add shift to employee')" placement="top">
                    <i class="icon-plus-circle text-primary font-weight-bold"
                       style="font-size: 20px; cursor: pointer"
                       @click="$emit('plus-click', value[0])"
                       data-toggle="modal"
                       data-target="#AddShiftByTemplateModal">
                    </i>
                  </el-tooltip>
                </div>
              </div>
              <div v-else :key="'hint-' + value[0]" class="col-2 px-0 text-center">
                <ui-hint :content="$t('This checkmark hides or shows custom shifts created for the schedule.')" placement="top" />
              </div>
            </div>
          </template>
        </ui-checkbox-group>
        <el-divider class="mt-0 mb-1"></el-divider>
      </template>
    </div>
  </el-collapse-item>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'Schedules',
  mixins: [momentMixin],
  data () {
    return {
      selectedTemplates: [],
      isIndeterminate: false,
      isGroupIndeterminate: {},
      checkAll: true,
      menuItems: {},
      checkSchedule: [],
      menuItemsSorted: [],
      isInit: false
    }
  },

  props: {
    currentProject: Object,
    hideCalendar: Boolean
  },

  created () {
    this.$eventBus.on('updateTemplate', () => {
      this.init()
    })
    if (this.currentProjectId) {
      this.init()
    }
  },
  beforeUnmount () {
    this.$eventBus.off('updateTemplate')
  },
  computed: {
    ...mapGetters({
      rolesByProjects: 'rolesByProjects',
      templatesByProject: 'templatesByProject',
      schedulesByProject: 'schedulesByProject',
      currentProjectId: 'currentProjectId',
      schedulesInfo: 'schedulesInfo',
      customTemplatesToHide: 'customTemplatesToHide',
      deletedTemplatesToHide: 'deletedTemplatesToHide'
    }),
    showBlock () {
      let templates = Object.keys(this.templatesByProject)
      templates = templates.filter(item => !this.customTemplatesToHide.includes(item))
      templates = templates.filter(item => !this.deletedTemplatesToHide.includes(item))
      return templates.length > 0
    },
    schedulesExpiring () {
      return this.$store.getters.schedulesExpiring
    },
    isAdminOrManager () {
      return this.isEmployeeHasPermission('update-schedule-admin') || this.rolesByProjects[this.currentProjectId] === 'manager'
    }
  },

  methods: {
    moment,
    init () {
      this.menuItems = {}
      this.schedulesByProject.forEach(scheduleId => {
        this.isGroupIndeterminate[scheduleId] = false
        this.menuItems[scheduleId] = {}
        Object.keys(this.templatesByProject).forEach((key) => {
          if (+key.split('__sp__')[0] === +scheduleId) {
            this.menuItems[scheduleId][key] = this.templatesByProject[key]
          }
        })
      })
      this.menuItemsSorted = Object.entries(this.menuItems).sort((as, bs) => {
        return this.schedulesInfo[as[0]].localeCompare(this.schedulesInfo[bs[0]], navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      this.menuItemsSorted.forEach(entry => {
        const val = Object.entries(entry[1]).sort((as, bs) => {
          return as[1][1].localeCompare(bs[1][1], navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
        })
        entry[1] = val
      })
      this.selectAllSchedules()
    },
    hideTemplate (id) {
      if (this.customTemplatesToHide.includes(id)) {
        return true
      }
      if (this.deletedTemplatesToHide.includes(id)) {
        return true
      }
      return false
    },

    // группа чекбоков по шедулю
    handleCheckScheduleChange (key) {
      this.selectedTemplates = this.selectedTemplates.filter(item => item && this.checkSchedule.includes(item.split('__sp__')[0]))
      if (this.checkSchedule.includes(key)) {
        Object.keys(this.templatesByProject).forEach((templateId) => {
          if (templateId.split('__sp__')[0] === key) {
            this.selectedTemplates.push(templateId)
          }
        })
      }
      if (this.selectedTemplates.length > 0) {
        this.isIndeterminate = Object.keys(this.templatesByProject).length > this.selectedTemplates.length
        if (this.checkSchedule.length === this.schedulesByProject.length) {
          this.checkAll = true
        }
        this.isGroupIndeterminate[key] = false
      } else {
        this.isIndeterminate = false
        this.checkAll = false
      }
    },

    // чекбокс выбрать все
    handleCheckAllChange (val) {
      this.selectedTemplates = []
      this.checkSchedule = []
      if (val) {
        this.selectAllSchedules()
      }
      Object.keys(this.isGroupIndeterminate).forEach(key => {
        this.isGroupIndeterminate[key] = false
      })
      this.isIndeterminate = false
    },

    // чекбоксы темплейтов
    handleCheckedTemplatesChange (value) {
      const checkedCount = value.length
      const checkedWithHide = [...new Set([...value, ...this.customTemplatesToHide, ...this.deletedTemplatesToHide])]
      this.checkAll = Object.keys(this.templatesByProject).length === checkedWithHide.length
      this.isIndeterminate = checkedCount > 0 && !this.checkAll
      this.isIndeterminateGroup()
    },

    // проверка промежуточного состояние группы чекбоксов расписания (отображается как "-") и обновление списка выбранных шедулей
    isIndeterminateGroup () {
      const tmpIndeterm = {}
      this.selectedTemplates.forEach(templateId => {
        if (templateId && !this.customTemplatesToHide.includes(templateId) && !this.deletedTemplatesToHide.includes(templateId)) {
          if (!tmpIndeterm[templateId.split('__sp__')[0]]) {
            tmpIndeterm[templateId.split('__sp__')[0]] = 1
          } else {
            tmpIndeterm[templateId.split('__sp__')[0]]++
          }
        }
      })
      this.checkSchedule = []
      const menu = Object.assign({}, this.menuItems)
      Object.keys(menu).forEach(key => {
        this.customTemplatesToHide.forEach(id => {
          delete menu[key][id]
        })
        this.deletedTemplatesToHide.forEach(id => {
          delete menu[key][id]
        })
        this.isGroupIndeterminate[key] = Object.keys(menu[key]).length > tmpIndeterm[key]
      })
      this.checkSchedule = Object.keys(tmpIndeterm)
    },

    selectAllSchedules () {
      this.selectedTemplates = []
      this.schedulesByProject.forEach(id => {
        this.checkSchedule.push(id.toString())
      })
      Object.keys(this.templatesByProject).forEach((key) => {
        this.selectedTemplates.push(key)
      })
      this.isInit = true
    },

    clearAllSchedules () {
      this.selectedTemplates = []
    },
    showAddButton (templateId) {
      const templateIdArray = templateId.split('__sp__')
      return ((this.isEmployeeHasPermission('update-schedule-admin') ||
              this.rolesByProjects[this.currentProjectId] === 'manager') &&
          templateIdArray.length > 1 &&
          // 4-й элемент массива this.currentProject.templatesByProject[templateId] - темплейт удален (true) или нет (false)
          this.templatesByProject[templateId][3] !== true &&
          +templateIdArray[1] !== 0)
    }
  },

  watch: {
    currentProjectId (val) {
      if (val && this.isInit) { // this.isInit костыль чтобы при хардрелоде дважды инит не отрабатывал, т.к. вотчатся templatesByProject
        this.init()
      }
    },
    selectedTemplates () {
      this.$store.dispatch('updateSelectedTemplates', this.selectedTemplates)
    },
    templatesByProject () {
      this.init()
    }
  }
}
</script>

<style lang="scss">
.el-checkbox__label {
  width: 100% !important;
}
</style>
<style lang="scss" scoped>
.link-all-schedules {
  cursor: pointer;
  &:hover {
    color: black !important;
  }
}
</style>
