<template>
  <div>
    <template v-if="dummy">
      <dummy-payment-required
        @activate="moduleActivated()"
        :dummyInfo="dummyInfo"
        :prevent-dismiss="true"
        :compactMode="true"
      />
    </template>
    <template v-else>
      <template v-if="Object.keys(tasks).length < 1">
        <div class="text-center mt-5">
          <h5 class="text-center">{{ $t('NO_TASKS_AVAILABLE') }}</h5>
        </div>
      </template>
      <template v-else>
        <el-container class="d-block" style="max-height: 300px; overflow-y: auto; background-color: #f9f9f9">
          <div v-if="+calculatedLeadTime > +shiftDuration" class="text-center bg-warning">
            {{ $t('WARNING_TASKS_DURATION') }}
          </div>
          <div v-for="item in tasks" :key="item.id"
               class="m-1 p-1 font-weight-bold bg-white">
            <ui-checkbox
              :name="item.id + '__' + item.title"
              :value="selectedTasks.filter(id => id === item.id).length > 0"
              :label="item.title"
              @change="(val) => onTaskChange(val, item.id)"
            />
          </div>
        </el-container>
        <div class="text-center my-2">
          <button class="btn btn-success rounded-pill shadow-sm" aria-label="Close" @click="attachTasks">
            {{ $t('ATTACH_TASKS') }}
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'TasksComponent',
  components: { DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      tasks: {},
      selectedTasks: [],
      calculatedLeadTime: 0,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  props: {
    shiftDuration: Number,
    shiftId: Number,
    shiftInfo: Object
  },
  created () {
    if (this.shiftInfo && this.shiftInfo.tasks) {
      this.shiftInfo.tasks.forEach(task => {
        this.selectedTasks.push(task.id)
      })
    }
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    shiftInfo () {
      if (this.shiftInfo && this.shiftInfo.tasks) {
        this.shiftInfo.tasks.forEach(task => {
          this.selectedTasks.push(task.id)
        })
      }
    },
    selectedTasks () {
      this.calculateLeadTime()
    },
    tasks () {
      this.calculateLeadTime()
    }
  },
  methods: {
    onTaskChange (state, id) {
      if (!state) {
        this.selectedTasks = this.selectedTasks.filter(item => item !== id)
      } else {
        this.selectedTasks.push(id)
      }
    },
    calculateLeadTime () {
      this.calculatedLeadTime = 0
      this.selectedTasks.forEach(taskId => {
        if (this.tasks[taskId]) {
          this.calculatedLeadTime += +this.tasks[taskId].lead_time * 60
        }
      })
    },
    init () {
      this.tasks = {}
      if (!this.$store.getters.tasksToAttach) {
        this.$store.dispatch('getTasksToAttach', [this.$store.getters.companyId, this.shiftId]).then(tasks => {
          tasks.forEach(task => {
            this.tasks[task.id] = task
          })
        }).catch((error) => {
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          } else {
            this.$router.push('dashboard')
          }
        })
      } else {
        this.$store.getters.tasksToAttach.forEach(task => {
          this.tasks[task.id] = task
        })
      }
      // console.log(this.tasks)
    },
    moduleActivated () {
      this.dummy = false
      this.init()
      this.$emit('activeTasks')
    },
    attachTasks () {
      // todo массовый апдейт тасков api
      // удаляем с шифта таски, с которых убрали галочку
      this.shiftInfo.tasks.forEach(task => {
        if (!this.selectedTasks.includes(task.id)) {
          const payload = Object.assign({}, task)
          payload.shift_id = null
          this.$store.dispatch('updateTask', [this.$store.getters.companyId, task.id, payload])
        }
      })
      const selectedTasks = []
      // апдейтим шифт айди у тасков с галочкой
      this.selectedTasks.forEach(taskId => {
        const payload = Object.assign({}, this.tasks[taskId])
        payload.shift_id = this.shiftId
        payload.employee_id = this.shiftInfo.employee_id
        this.$store.dispatch('updateTask', [this.$store.getters.companyId, taskId, payload])
        selectedTasks.push(this.tasks[taskId])
      })
      this.$emit('updateTask', selectedTasks)
    }
  }
}
</script>

<style scoped>
  tr { background-color: #fff; cursor: pointer;}
</style>
