import { HTTP } from '@/api/http_utils'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getChecklists ({ commit }, [companyId, pageQuery]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/checklists` + pageQuery).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getChecklist ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/checklists/${id}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createChecklist ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/checklists`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editChecklist ({ commit }, [companyId, checklistId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/checklists/${checklistId}`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteChecklist ({ commit }, [companyId, checklistId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/checklists/${checklistId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
