<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('LATE_ACTIVITIES')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="lateActivities.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in lateActivities" :key="item.id  + '__late'" class="mb-2">
                <div>
                  {{ item.title }} {{ item.full_name }}
                </div>
                <div class="small">
                  {{toTime(item.time_from)}} - {{toTime(item.time_to)}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_LATE_ACTIVITIES')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('ON_ACTIVITY')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="nowActivities.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in nowActivities" :key="item.id  + '__now'" class="mb-1">
                <div>
                  {{ item.title }} {{ item.full_name }}
                </div>
                <div class="small">
                  {{toTime(item.time_from)}} - {{toTime(item.time_to)}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_ON_ACTIVITY')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col-auto mt-2">{{$t('FUTURE_ACTIVITIES')}}</div>
            <div class="col">
              <ui-select
                name="before_time"
                v-model="beforeActivity"
                :clearable="false"
                :filterable="false"
                class="w-50"
                :options="timeOptions"
                :key-name="'value'"
                :label-name="'label'"
                :value-name="'value'"
                @change="this.init"
              />
            </div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="filteredFutureActivity.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in filteredFutureActivity" :key="item.id + '__future'" class="mb-1">
                <div>
                  {{ item.title }} {{ item.full_name }}
                </div>
                <div class="small">
                  {{toTime(item.time_from)}} - {{toTime(item.time_to)}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_FUTURE_ACTIVITIES')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'

export default {
  name: 'ActivityStatistic',
  mixins: [momentMixin],
  data () {
    return {
      lateActivities: [],
      nowActivities: [],
      filteredFutureActivity: [],
      beforeActivity: 60,
      timeOptions: [
        {
          value: 60,
          label: '60 ' + this.$t('min')
        },
        {
          value: 45,
          label: '45 ' + this.$t('min')
        },
        {
          value: 30,
          label: '30 ' + this.$t('min')
        },
        {
          value: 15,
          label: '15 ' + this.$t('min')
        },
        {
          value: 5,
          label: '5 ' + this.$t('min')
        }
      ]
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$eventBus.on('updateActivityStatus', () => {
      setTimeout(() => {
        this.init()
      }, 500)
    })
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      profileTimeZone: 'profileTimeZone',
      employeesIndexed: 'employeesIndexed'
    }),
    activities () {
      return this.$store.getters.activities
    }
  },
  watch: {
    activities () {
      this.lateActivities = []
      this.nowActivities = []
      this.filteredFutureActivity = []
      const now = moment()
      const before = moment().add(this.beforeActivity, 'minutes')
      this.activities.forEach(activity => {
        if (activity.employee_id) {
          const activityStart = this.fromZoneToZone(activity.time_from, 'UTC', this.profileTimeZone)
          activity.full_name = this.employeesIndexed[activity.employee_id].last_name + ' ' + this.employeesIndexed[activity.employee_id].first_name
          if (activity.logs.length > 0 && activity.time_spents.length === 0) {
            this.nowActivities.push({ ...activity })
          }
          if (activity.logs.length === 0 && activityStart.isBefore(now)) {
            this.lateActivities.push({ ...activity })
          }
          if (activity.logs.length === 0 && activityStart.isAfter(now) && activityStart.isBefore(before)) {
            this.filteredFutureActivity.push({ ...activity })
          }
        }
      })
      this.nowActivities.sort((a, b) => {
        if (a.time_from > b.time_to) {
          return 1
        }
        if (a.time_from < b.time_to) {
          return -1
        }
        return 0
      })
      this.lateActivities.sort((a, b) => {
        if (a.time_from > b.time_to) {
          return 1
        }
        if (a.time_from < b.time_to) {
          return -1
        }
        return 0
      })
      this.filteredFutureActivity.sort((a, b) => {
        if (a.time_from > b.time_to) {
          return 1
        }
        if (a.time_from < b.time_to) {
          return -1
        }
        return 0
      })
    }
  },
  methods: {
    init () {
      const dateFrom = this.toUTC(moment()).add(-60, 'minutes').format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment()).add(1, 'days').format(this.backendDateTimeFormat)
      const activityQuery = `?date_from=${dateFrom}&date_to=${dateTo}`
      this.$store.dispatch('getShiftActivities', [this.companyId, activityQuery])
    },
    toTime (date) {
      return this.fromZoneToZone(date, 'UTC', this.profileTimeZone).format(this.localeTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
