import {
  GET_CURRENCIES,
  GET_LANGUAGES,
  GET_COUNTRIES,
  GET_CITIES,
  GET_CITY,
  GET_COUNTRY_NAMES,
  GET_SUBDIVISION_NAMES,
  GET_CITY_NAMES,
  GET_PROFESSIONS,
  GET_EDUCATIONS,
  GET_EXPERIENCE,
  GET_INVITE,
  GET_ALL_ZONES,
  GET_COUNTRY_ZONES,
  GET_FILES,
  GET_COMPANY_FILES,
  GET_AVATAR,
  SET_GENERATION_INFO,
  SET_AVATAR_HASH,
  SET_COMPANY_AVATAR_HASH,
  API_ERRORS,
  LAST_SEEN_DATE_MOBILE
} from '../mutation-types.js'
import { axiosBasic, CacheLoc, HTTP, cacheRequest } from '@/api/http_utils' // axiosCachedImage
// import index from '@/store/index'
const DISPLAY_EVENTS_SETTINGS = 'DISPLAY_EVENTS_SETTINGS'

const state = {
  countries: [],
  countryNames: [],
  subdivisionNames: [],
  cityNames: [],
  professions: [],
  cities: [],
  states: [],
  inviteList: [],
  allZones: [],
  countryZones: [],
  educationsList: [],
  files: [],
  companyFiles: [],
  avatar: { full: '', mini: '' },
  avatarHashId: '',
  companyAvatarHashId: '',
  currenciesList: [],
  languages: [],
  experience: [],
  lastSeenDateMobileApp: '',
  displayEventsSettings: null,
  warningInPast: true,
  working_hours_display_type: 'hide',
  require_user_confirm: false,
  filterScheduleTemplate: 'all',
  shiftLog: null,
  generationInfo: null
}

const getters = {
  countries: state => state.countries,
  countryNames: state => state.countryNames,
  subdivisionNames: state => state.subdivisionNames,
  cityNames: state => state.cityNames,
  professions: state => state.professions,
  educations: state => state.educationsList,
  experience: state => state.experience,
  cities: state => state.cities,
  invite: state => state.inviteList,
  allZones: state => state.allZones,
  countryZones: state => state.countryZones,
  files: state => state.files,
  companyFiles: state => state.companyFiles,
  avatar: state => state.avatar,
  avatarHashId: state => state.avatarHashId,
  companyAvatarHashId: state => state.avatarHashId,
  currenciesList: state => state.currenciesList,
  languages: state => state.languages,
  lastSeenDateMobileApp: state => state.lastSeenDateMobileApp,
  displayEventsSettings: state => state.displayEventsSettings,
  warningInPast: state => state.warningInPast,
  working_hours_display_type: state => state.working_hours_display_type,
  require_user_confirm: state => state.require_user_confirm,
  filterScheduleTemplate: state => state.filterScheduleTemplate,
  shiftLog: state => state.shiftLog,
  generationInfo: state => state.generationInfo
}

const SHIFT_LOG = 'SHIFT_LOG'

const mutations = {
  [DISPLAY_EVENTS_SETTINGS] (state, settings) {
    state.displayEventsSettings = settings
  },
  [GET_COUNTRIES] (state, countriesList) {
    state.countries = countriesList
  },
  [GET_CURRENCIES] (state, currencies) {
    state.currenciesList = currencies
  },
  [GET_LANGUAGES] (state, languages) {
    state.languages = languages
  },
  [GET_COUNTRY_NAMES] (state, countryNames) {
    state.countryNames = countryNames
  },
  [GET_SUBDIVISION_NAMES] (state, subdivisionNames) {
    state.subdivisionNames = subdivisionNames
  },
  [GET_CITY_NAMES] (state, cityNames) {
    state.cityNames = cityNames
  },
  [GET_CITIES] (state, cities) {
    state.cities = cities
  },
  [GET_PROFESSIONS] (state, professions) {
    state.professions = professions
  },
  [GET_EDUCATIONS] (state, educations) {
    state.educationsList = educations
  },
  [GET_EXPERIENCE] (state, experience) {
    state.experience = experience
  },
  [GET_CITY] (state, city) {
    state.city = city
  },
  [GET_INVITE] (state, data) {
    state.inviteList.push(data)
  },
  [GET_ALL_ZONES] (state, allZones) {
    state.allZones = allZones
  },
  [GET_COUNTRY_ZONES] (state, countryZones) {
    state.countryZones = countryZones
  },
  [GET_FILES] (state, files) {
    state.files = files
  },
  [GET_COMPANY_FILES] (state, companyFiles) {
    state.companyFiles = companyFiles
  },
  [GET_AVATAR] (state, avatar) {
    state.avatar = avatar
  },
  [SET_AVATAR_HASH] (state, avatarHashId) {
    state.avatarHashId = avatarHashId
  },
  [SET_COMPANY_AVATAR_HASH] (state, avatarHashId) {
    state.companyAvatarHashId = avatarHashId
  },
  [API_ERRORS] (state, error) {
    state.apiErrors = {}
    state.apiErrors = error
    // console.log(state.apiErrors)
  },
  [LAST_SEEN_DATE_MOBILE] (state, data) {
    state.lastSeenDateMobileApp = data
  },
  warningInPast (state, data) { // показывать предупреждение, если смена в прошлом, или нет
    state.warningInPast = data
  },
  working_hours_display_type (state, data) {
    state.working_hours_display_type = data
  },
  require_user_confirm (state, data) {
    state.require_user_confirm = data
  },
  filterScheduleTemplate (state, data) {
    state.filterScheduleTemplate = data
  },
  [SHIFT_LOG] (state, data) {
    state.shiftLog = data
  },
  [SET_GENERATION_INFO] (state, data) {
    state.generationInfo = data
  }
}

const actions = {
  setGenerationInfo ({ commit }, payload) {
    commit(SET_GENERATION_INFO, payload)
  },
  updateDisplaySettings ({ commit }, payload) {
    commit(DISPLAY_EVENTS_SETTINGS, payload)
  },
  checkEmail ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/check-email', data).then(response => {
        if (response.is_exist) {
          commit(API_ERRORS, {
            error_message: 'wrong data',
            errors: { email: ['This e-mail is already used'] }
          })
        }
        resolve(response)
      })
    })
  },
  getLocation ({ commit }) {
    return new Promise(resolve => {
      HTTP.get('/location').then(response => {
        if (response) {
          resolve(response)
        }
      })
    })
  },
  invite ({ commit }, [phone, code, email]) {
    return new Promise((resolve) => {
      const data = {
        phone: phone,
        code: code,
        email: email
      }
      commit(GET_INVITE, data)
      resolve(data)
    })
  },
  getCountries ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/countries', null, 'utils', 'countries').then(countries => {
        commit(GET_COUNTRIES, countries)
        resolve(countries)
      })
    })
  },
  /**
   * Сброс стора при смене языка для кеширования валидных данных
   * @param commit
   */
  switchLangReset ({ commit }) {
    commit(GET_COUNTRY_NAMES, [])
    commit(GET_CITY_NAMES, [])
    commit(GET_SUBDIVISION_NAMES, [])
    commit(GET_CURRENCIES, [])
  },
  /**
   * Сброс стора при смене страны в Location.vue для кеширования валидных данных
   * @param commit
   */
  changeCountry ({ commit }) {
    commit(GET_CITY_NAMES, [])
    commit(GET_SUBDIVISION_NAMES, [])
  },
  /**
   * Сброс стора при смене штата/области в Location.vue для кеширования валидных данных
   * @param commit
   */
  changeSubdivision ({ commit }) {
    commit(GET_CITY_NAMES, [])
  },
  getCity ({ commit }, data) {
    return new Promise(resolve => {
      const country = data[0]
      const city = data[1]
      if (city.length > 1) {
        return HTTP.get('/countries/' + country + '/cities?q=' + city).then(cities => {
          commit(GET_CITIES, cities)
          resolve(cities)
        })
      }
    })
  },
  getCityById ({ commit }, [cityId, lang]) {
    return new Promise(resolve => {
      HTTP.get(`/city/${cityId}?lang=${lang}`).then(city => {
        // commit(GET_CITIES, city)
        resolve(city)
      })
    })
  },
  getCurrencies ({ commit }, lang) {
    return new Promise(resolve => {
      // const lang = this.$store.getters.locale ? this.$store.getters.locale : 'en'
      cacheRequest(`/currencies?lang=${lang}`, null, 'utils', 'currenciesList').then(response => {
        Object.keys(response).forEach(key => {
          let toArray = response[key].name.split(' ')
          toArray = toArray.map(item => {
            return item.trim().charAt(0).toUpperCase() + item.slice(1)
          })
          response[key].name = toArray.join(' ')
        })
        commit(GET_CURRENCIES, response)
        resolve(response)
      })
    })
  },
  getLanguages ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/languages', null, 'utils', 'languages').then(response => {
        commit(GET_LANGUAGES, response)
        resolve(response)
      })
    })
  },
  getProfessions ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/professions/', null, 'utils', 'professions').then(response => {
        commit(GET_PROFESSIONS, response)
        resolve(response)
      })
    })
  },
  getEducations ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/educations/', null, 'utils', 'educations').then(out => {
        commit(GET_EDUCATIONS, out)
        resolve(out)
      })
    })
  },
  getExperience ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/experience/', null, 'utils', 'experience').then(out => {
        commit(GET_EXPERIENCE, out)
        resolve(out)
      })
    })
  },
  getTimeZones ({ commit }) {
    return new Promise(resolve => {
      cacheRequest('/time-zones', null, 'utils', 'allZones').then(response => {
        commit(GET_ALL_ZONES, response)
        resolve(response)
      })
    })
  },
  getTimeZone ({ commit }) {
    return new Promise(resolve => {
      HTTP.get('/time-zone').then(timeZone => {
        resolve(timeZone)
      })
    })
  },
  getCompanyFiles ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get('companies/' + companyId + '/files').then(response => {
        if (response) {
          commit(GET_COMPANY_FILES, response)
          resolve(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getFiles ({ commit }, id) {
    return new Promise((resolve, reject) => {
      let url = ''
      if (id) {
        url = 'employees/' + id + '/files'
      } else {
        url = '/files'
      }
      HTTP.get(url).then(response => {
        if (response) {
          commit(GET_FILES, response)
          resolve(response)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAvatar ({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.get('/users/' + id + '/avatar').then(avatar => {
        if (avatar) {
          commit(GET_AVATAR, avatar)
          localStorage.setItem('userAvatar', avatar.url)
          resolve(avatar)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  getAvatarImg ({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data.cache === false) CacheLoc.clear()
      // TODO: переделать под кеш
      HTTP.get(data.avatarUrl).then(avatar => {
        if (avatar) {
          resolve(avatar)
          const hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
          commit(SET_AVATAR_HASH, hash)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getCompanyAvatarImg ({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data.cache === false) CacheLoc.clear()
      // TODO: переделать под кеш
      HTTP.get(data.avatarUrl).then(avatar => {
        if (avatar) {
          resolve(avatar)
          const hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
          commit(SET_COMPANY_AVATAR_HASH, hash)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDeveloperAppIcom ({ commit }, [companyId, appId, cache]) {
    return new Promise((resolve, reject) => {
      if (cache === false) CacheLoc.clear()
      // TODO: переделать под кеш
      HTTP.get(`companies/${companyId}/developer/app/${appId}/icon`).then(icon => {
        resolve(icon)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDeveloperAppIcomMini ({ commit }, [companyId, appId, cache]) {
    return new Promise((resolve, reject) => {
      if (cache === false) CacheLoc.clear()
      // TODO: переделать под кеш
      HTTP.get(`companies/${companyId}/developer/app/${appId}/icon_min`).then(icon => {
        resolve(icon)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // getAvatarImg ({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     if (data.cache === false) CacheLoc.reset()
  //     axiosCachedImage.get(data.avatarUrl, { cache: data.cache }).then(response => {
  //       if (response.data.byteLength) {
  //         let mimeType = response.headers['content-type'].toLowerCase()
  //         let imgBase64 = Buffer.from(response.data, 'binary').toString('base64')
  //         let img = 'data:' + mimeType + ';base64,' + imgBase64
  //         if (data.cache === false) {
  //           // записывает в стор стэйт рандомный хэш для обновления аватарки в хедере "на лету", при загрузке нового аватара
  //           let hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  //           commit(SET_AVATAR_HASH, hash)
  //         }
  //         resolve(img)
  //       }
  //     }).catch(error => {
  //       index.dispatch('getErrors', error.response.data).then(() => {
  //         reject(error)
  //       })
  //     })
  //   })
  // },
  delFile ({ commit }, [id]) {
    return new Promise((resolve, reject) => {
      axiosBasic.delete(`files/${id}/delete`).then(response => {
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  },
  delCompanyFile ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.post(`companies/${companyId}/file/delete`, data).then(response => {
        resolve(true)
      })
    })
  },
  changeVisibility ({ commit }, [companyId, fileId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`companies/${companyId}/file/${fileId}`, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  shareFile ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.put(`companies/${companyId}/file/share`, data).then(response => {
        if (response) {
          // console.log('X', response)
          commit(GET_FILES, response)
          resolve(response)
        }
      })
    })
  },
  usersStatusFields () {
    return new Promise(resolve => {
      HTTP.get('/users-fields').then(out => {
        resolve(out)
      })
    })
  },
  setLog ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/logs', data).then(out => {
        resolve(out)
      })
    })
  },
  getCountryNames ({ commit }, lang) {
    return new Promise(resolve => {
      cacheRequest('/countries?lang=' + lang, null, 'utils', 'countryNames').then(countries => {
        commit(GET_COUNTRY_NAMES, countries)
        resolve(countries)
      })
    })
  },
  getSubdivisionNames ({ commit }, [lang, countryCode]) {
    return new Promise((resolve, reject) => {
      cacheRequest('/countries/' + countryCode + '/subdivisions?lang=' + lang, null, 'utils', 'subdivisionNames').then(subdivisionNames => {
        commit(GET_SUBDIVISION_NAMES, subdivisionNames)
        resolve(subdivisionNames)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCityNames ({ commit }, [lang, countryCode, subdivisionId]) {
    return new Promise((resolve, reject) => {
      let url = ''
      if (subdivisionId) {
        url = '/countries/' + countryCode + '/subdivisions/' + subdivisionId + '/cities?lang=' + lang
      } else {
        url = '/countries/' + countryCode + '/cities?lang=' + lang
      }
      cacheRequest(url, null, 'utils', 'cityNames').then(cityNames => {
        commit(GET_CITY_NAMES, cityNames)
        resolve(cityNames)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getShiftLogs ({ commit }, shiftId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/shifts/${shiftId}/logs`).then(response => {
        commit(SHIFT_LOG, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  translationsByType ({ commit }, [lang, subtype]) {
    return new Promise((resolve, reject) => {
      HTTP.get('/languages/' + lang + '/subtypes/' + subtype + '/translations').then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  sendEmergencyShiftsNotification ({ commit }, [projectId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/projects/${projectId}/emergency_notify`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
