<template>
  <div>
    <ReferralModal
      v-if="showPromoModal"
      :modalData="modalData"
      :promoId="promoId"
      @closePromoModal="closePromoModal"
      :title="'ADD_PROMO_MODAL'"
    />
    <div data-toggle="modal" data-target="#ClickAddPromoModal" id="addPromoModalID"></div>
    <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Referral system") }}
        </span>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row my-2 mx-1 font-weight-bold">
          {{ $t("How does the system work?") }}
        </div>
        <div class="row bg-light my-3 mx-1 p-2 h-100 align-items-center rounded">
          <div class="col-auto px-0 my-1">
            <div class="number-circle">
              <span class="font-weight-bold">1</span>
            </div>
          </div>
          <div class="col-auto my-1">
            <span class="text-primary m-1">
              {{ $t("Step") }}
              <i v-if="locale === 'he'" class="icon-chevron-left"></i>
              <i v-else class="icon-chevron-right"></i>
            </span>
          </div>
          <div class="col-sm px-0 my-1">
            <div class="row">
              <div class="col-auto">
                <span class="m-1">{{ $t("Copy the link and send it to a friend:") }}</span>
              </div>
              <div class="col-auto">
                <span id="referralLink" class="m-1 font-weight-bold text-primary">{{ referralLink }}</span>
              </div>
              <div class="col-auto">
                <span class="m-1">
                  <el-tooltip class="item" effect="dark" :content="$t('Copy link')" placement="top">
                    <i class="icon-copy text-primary" style="font-size: 1.2em; cursor: pointer;" @click="copyLink"></i>
                  </el-tooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-light my-3 mx-1 p-2 h-100 align-items-center rounded">
          <div class="col-auto px-0 my-1">
            <div class="number-circle">
              <span class="font-weight-bold">2</span>
            </div>
          </div>
          <div class="col-auto my-1">
            <span class="text-primary m-1">
              {{ $t("Step") }}
              <i v-if="locale === 'he'" class="icon-chevron-left"></i>
              <i v-else class="icon-chevron-right"></i>
            </span>
          </div>
          <div class="col-sm px-0 my-1">
            <span class="m-1">{{ $t("Your friend registers using the link and starts using the service") }}</span>
          </div>
        </div>
        <div class="row bg-light my-3 mx-1 p-2 h-100 align-items-center rounded">
          <div class="col-auto px-0 my-1">
            <div class="number-circle">
              <span class="font-weight-bold">3</span>
            </div>
          </div>
          <div class="col-auto my-1">
            <span class="text-primary m-1">
              {{ $t("Step") }}
              <i v-if="locale === 'he'" class="icon-chevron-left"></i>
              <i v-else class="icon-chevron-right"></i>
            </span>
          </div>
          <div class="col-sm px-0 my-1">
            <span class="m-1">{{ $t(`Get ${referralShare}% of all its payments`) }}</span>
          </div>
        </div>
        <div class="row my-3 mx-1 font-weight-bold">
          {{ $t('REFERRAL_SYSTEM_DESCRIPTION_1') }}
        </div>
        <div class="row my-3 mx-1">
          {{ $t('REFERRAL_SYSTEM_DESCRIPTION_2') }}
        </div>
        <div class="row my-3 mx-1">
          <i class="icon-coin-dollar text-primary mx-1" style="font-size: 1.8em;"></i>
          <span class="font-weight-bold mx-1">{{ $t('My promos') }}</span>
          <button
            class="btn btn-primary btn-sm rounded-pill"
            data-toggle="modal"
            data-target="#ClickAddPromoModal"
            type="button"
            @click="addPromo"
            >
            {{ $t('Create Pormo Code') }}
          </button>
        </div>
        <div class="row my-3 mx-1 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="text-center">
                <th class="text-left" scope="col">{{$t('Code')}}</th>
                <th class="text-center" scope="col">{{$t('Referred companies')}}</th>
                <th class="text-center" scope="col">{{$t('Referral fee')}}</th>
                <th class="text-center" scope="col">{{$t('Created at')}}</th>
                <th class="text-rigth">{{ $t('Actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="promo in promos" :key="promo.id" class="text-center">
                <td class="text-left">
                  {{ promo.code }}
                </td>
                <td class="text-center">
                  {{ promo.referred_companies_count }}
                </td>
                <td class="text-center">
                  {{ promo.referral_share }}
                </td>
                <td class="text-center">
                  {{ promo.created_at }}
                </td>
                <td class="text-primary text-rigth">
                  <i role="button" @click="editPromo(promo)"
                    data-toggle="modal"
                    data-target="#ClickAddPromoModal"
                    class="icon-pencil btn-icon text-primary mx-2">
                  </i>
                  <i role="button" @click="removePromo(promo)" class="icon-trash btn-icon text-danger mx-2"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row my-3 mx-1">
          <i class="icon-layers text-primary mx-1" style="font-size: 1.8em;"></i>
          <span class="font-weight-bold mx-1">{{ $t('My referrals') }}</span>
        </div>
        <div class="row my-3 mx-1 table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="text-center">
                <th class="text-left" scope="col">{{$t('A country')}}</th>
                <th class="text-left" scope="col">{{$t('Type of activity')}}</th>
                <th class="text-center" scope="col">{{$t('Number of employees')}}</th>
                <th class="text-center" scope="col">{{$t('Registration date')}}</th>
                <th class="text-center" scope="col">{{$t('Referral fee')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="referral in referrals" :key="referral.id" class="text-center">
                <td class="text-left">
                  {{ getCountryName(referral.address) }}
                </td>
                <td class="text-left">
                  {{ $t(referral.industry) }}
                </td>
                <td class="text-center">
                  {{ referral.workers }}
                </td>
                <td class="text-center">
                  {{ referral.created_at }}
                </td>
                <td class="text-primary text-center">
                  {{ referral.agent_share }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReferralModal from '@/components/Referrals/ReferralModal'

export default {
  name: 'Referrals',
  components: { ReferralModal },
  data () {
    return {
      referrals: [],
      countries: {},
      promos: [],
      showPromoModal: false,
      modalData: {},
      promoId: null
    }
  },
  created () {
    if (this.locale) {
      this.$store.dispatch('getCountryNames', this.locale).then((countries) => {
        this.countries = countries
      })
    }
    if (this.companyId) {
      this.$store.dispatch('getReferrals', this.$store.getters.companyId).then(response => {
        this.referrals = response
      })
      this.initPromos()
    }
  },
  computed: {
    referralLink () {
      return `https://shifton.com/${this.$store.getters.locale}?f=${this.$store.getters.companyId}`
    },
    locale () {
      return this.$store.getters.locale
    },
    companyId () {
      return this.$store.getters.companyId
    },
    referralShare () {
      // this.$store.dispatch('getCompanies')
      return this.$store.state.company.company.referral_share
    }
  },
  watch: {
    locale () {
      this.$store.dispatch('getCountryNames', this.locale).then((countries) => {
        this.countries = countries
      })
    },
    companyId () {
      this.$store.dispatch('getReferrals', this.$store.getters.companyId).then(response => {
        this.referrals = response
      })
    }
  },
  methods: {
    initPromos () {
      this.$store.dispatch('getCompanyPromos', this.$store.getters.companyId).then(response => {
        this.promos = response.sort(function (a, b) {
          const c = new Date(a.created_at)
          const d = new Date(b.created_at)
          return d - c
        })
      })
    },
    closePromoModal () {
      this.showPromoModal = false
      this.modalData = {}
      this.initPromos()
    },
    addPromo () {
      this.showPromoModal = true
      console.log('Add promo')
    },
    editPromo (promo) {
      this.modalData = promo
      this.showPromoModal = true
      console.log('editPromo')
    },
    removePromo (promo) {
      console.log('removePromo')
      this.$confirm(this.$t('This will permanently delete the promo. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('destroyCompanyPromo', promo.id).then((response) => {
          this.toastSuccess(this.$i18n?.t('Delete completed'))
          this.initPromos()
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        this.$notify({
          type: 'info',
          message: this.$t('Delete canceled')
        })
      })
    },
    getCountryName (countryCode) {
      if (countryCode) {
        return this.countries[countryCode.toUpperCase()].name
      }
      return null
    },
    copyLink () {
      const node = document.getElementById('referralLink')
      const el = document.createElement('textarea')
      el.value = node.innerText
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.toastSuccess(this.$i18n?.t('Link copied to clipboard'))
    }
  }
}
</script>

<style scoped>
  .number-circle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;

    background: #fff;
    border: 2px solid #53a8dd;
    color: #53a8dd;
    text-align: center;

  }
</style>
