<template>
  <div class="card card-small">
    <div class="card-body">
      <div class="row pb-2">
        <div class="col-lg-12">
          <div class="text-primary text-uppercase font-weight-bold">{{ $t('Company balance') }}</div>
          <div class="font-weight-bold">{{ $t('Status of payment balance, invoices and payments.') }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 p-2">
          <div class="card bg-color-grey h-100 shadow-none">
            <div class="card-body pb-0 d-flex">
              <div class="row">
                <div class="col-lg-3 pl-1 pr-1 my-auto">
                  <img class="img-fluid" src="../../../../public/static/images/App/balance2.png"/>
                </div>
                <div class="col-lg-6 d-flex">
                  <div class="text-secondary font-weight-bold mb-3 my-auto m-auto">
                    <span class="">{{ $t("Balance").toUpperCase() }} $ {{ parseFloat(balance).toFixed(2) }}</span>
                  </div>
                </div>
                <div class="col-lg-3"></div>
              </div>
            </div>
            <div class="card-footer bg-transparent text-center border-top-0">
<!--              <router-link-->
<!--                :to="`/c/${companyId}/payments`"-->
<!--                class="btn btn-success rounded-pill shadow-sm">-->
<!--                {{ $t('REPLENISH')}}-->
<!--              </router-link>-->
            </div>
          </div>
        </div>
        <div class="col-lg-6 p-2">
          <div v-if="subscription === null" class="card bg-color-grey h-100 shadow-none">
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 my-auto pl-1 pr-1">
                  <img class="img-fluid" src="../../../../public/static/images/App/balance.png" alt="balance"/>
                </div>
                <div class="col-lg-9">
                  <div class="mb-3">{{ $t("Total cost of selected services").toUpperCase() }} ${{ Math.floor(monthlyPaymentByModule * 100) / 100 }}
                    <br>
                    + ${{ Math.floor(monthlyPaymentByUser * 100) / 100 }} {{ $t("PER_EMPLOYEES").toUpperCase() }}</div>
                  <div class="">{{ $t("Next monthly payment is due on") }} {{ dueDate() }}</div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent text-center border-top-0">
              <router-link
                :to="`/c/${companyId}/subscribes`"
                class="btn btn-success rounded-pill shadow-sm font-weight-bold">
                {{ $t('Monthly payment').toUpperCase() }}
              </router-link>
            </div>
          </div>
          <div v-else class="card bg-color-grey h-100 shadow-none">
            <div class="card-body pb-0">
              <div class="row">
                <div class="col-lg-3 my-auto pl-1 pr-1">
                  <img class="img-fluid" src="../../../../public/static/images/App/balance.png"/>
                </div>
                <div class="col-lg-9">
                  <div class="text-secondary mb-3">{{ $t("Monthly payment for selected modules is enabled") }}</div>
                  <div class="text-secondary">{{ $t("Next monthly payment is due on") }} {{ dueDate() }}</div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent text-right border-top-0">
              <button class="btn bg-success btn-circle font-weight-bold text-secondary" @click="disableSubscription">{{$t('Cancel')}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 p-2">
          <invoices />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 p-2 d-flex">
          <transactions />
        </div>
        <div class="col-lg-6 p-2 d-flex">
          <modules-trial />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Invoices from './BalanceComponents/Invoices'
import Transactions from './BalanceComponents/Transactions'
import ModulesTrial from './BalanceComponents/ModulesTrial'
import momentMixin from '../../../mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'Balance',
  mixins: [momentMixin],
  components: {
    Invoices: Invoices,
    Transactions: Transactions,
    ModulesTrial: ModulesTrial
  },
  data () {
    return {
      balance: 0.0,
      monthlyPaymentByModule: 0,
      monthlyPaymentByUser: 0,
      subscription: null,
      activeUsers: 0
    }
  },
  props: ['newbie'],
  created () {
    const from = moment().startOf('month').format(this.backendDateTimeFormat)
    const to = moment().add(1, 'month').startOf('month').format(this.backendDateTimeFormat)
    this.$store.dispatch('getEmployeesWithActivityCount', [this.companyId, from, to]).then(response => {
      // console.log(1, response) d
      if (response) {
        this.activeUsers = response.employees_with_activity
      }
    })
    this.$store.dispatch('getBalance').then(response => {
      // console.log(2, response)
      if (response) {
        this.balance = response.balance
      }
    })
    this.$store.dispatch('getSubscription').then(response => {
      // console.log(3, response)
      if (response && response.length > 0) {
        this.subscription = response[0].id
      }
    })
    // console.log(this.modules)
  },
  computed: {
    modules () {
      return this.$store.getters.ModulesByCompany
    },
    companyId () {
      return this.$store.getters.companyId
    }
  },
  watch: {
    modules () {
      this.monthlyPaymentCalculation()
    },
    activeUsers () {
      this.monthlyPaymentCalculation()
    }
  },
  methods: {
    monthlyPaymentCalculation () {
      this.monthlyPaymentByModule = 0
      this.monthlyPaymentByUser = 0
      if (this.modules && Array.isArray(this.modules)) {
        this.modules.forEach(module => {
          this.monthlyPaymentByModule += +module.price * (+module.count - +module.free_count)
          this.monthlyPaymentByUser += +module.user_price * +this.activeUsers
        })
      }
    },
    dueDate () {
      return moment().add(1, 'Month').date(2).format(this.localeDateFormat)
    },
    disableSubscription () {
      this.$store.dispatch('deleteSubscription', this.subscription).then(response => {
        this.subscription = null
        this.toastSuccess(this.$t('Subscription is disable'))
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  }
}
</script>

<style scoped>
  .bg-color-green {
    background-color: #56a71b;
  }
  .bg-color-grey {
    background-color: #f1f1f1;
  }
</style>
