<template>
  <div v-if="shiftInfoState.request_id" class="row">
    <div class="col d-inline-flex">
      <el-alert
        class="mb-3"
        :closable="false"
        :show-icon="true"
        type="warning">
        <template #title>
          <div>
                <span v-if="shiftRequest && shiftRequest.subtype === 'exchange'" class="mx-1">
                  {{$t('REQUESTED_SHIFT_WARNING')}}
                </span>
            <span v-if="shiftRequest && shiftRequest.subtype === 'change_shift'" class="mx-1">
                  {{$t('REQUESTED_SHIFT_CHANGE_WARNING')}}
                </span>
            <span v-if="shiftRequest && shiftRequest.subtype === 'drop_to_employee'" class="mx-1">
                  {{$t('REQUESTED_SHIFT_DROP_TO_EMPLOYEE_WARNING')}}
                </span>
            <span v-if="shiftRequest && shiftRequest.subtype === 'move_to_freeboard'" class="mx-1">
                  {{$t('REQUESTED_MOVE_TO_FREEBOARD_WARNING')}}
          </span>
            <template v-if="isEmployeeHasPermission('add-request-message')">
              <button
                class="btn btn-danger btn-sm rounded-pill btn-fix-width shadow-sm mx-1"
                data-dismiss="modal"
                aria-label="Close"
                @click="cancelRequest">
                {{ $t('Cancel') }}
              </button>
              <button
                v-if="exchangeRequest.status === 'accepted'"
                :disabled="blockConfirm"
                class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-1"
                @click="confirmRequest">
                {{ $t('Confirm') }}
              </button>
            </template>
          </div>
        </template>
        <div>
          <span v-if="shiftRequest && shiftRequest.subtype === 'exchange'" class="mx-1">
            {{ $t('EXCHANGE_TO') }}: {{exchangeRequest.name}}; {{$t('SHIFT_EXCHANGE_DATE')}}: {{exchangeRequest.from}} - {{exchangeRequest.to}}
          </span>
          <span v-if="shiftRequest && shiftRequest.subtype === 'change_shift'" class="mx-1">
            {{ $t('SHIFT_CHANGE_REQUEST_WARNING') }}
          </span>
          <span v-if="shiftRequest && shiftRequest.subtype === 'move_to_freeboard'" class="mx-1">
            {{ $t('MOVE_TO_FREEBOARD_WARNING') }}
          </span>
          <span v-if="shiftRequest && shiftRequest.subtype === 'drop_to_employee'" class="mx-1">
            {{ $t('DROP_TO') }}: {{shiftRequest.data.user_name_drop_to}}
          </span>
        </div>
      </el-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShiftRequestWarningComponent',
  data () {
    return {
      shiftInfoState: {}
    }
  },
  props: [
    'shiftInfo',
    'shiftRequest',
    'exchangeRequest',
    'weekOvertime',
    'weekOvertimeEmployeeToExchange'
  ],
  created () {
    this.shiftInfoState = this.shiftInfo
    console.log(this.shiftInfoState)
  },
  watch: {
    shiftInfo () {
      this.shiftInfoState = this.shiftInfo
    }
  },
  computed: {
    blockConfirm () {
      return (this.weekOvertime.is_overtime || this.weekOvertimeEmployeeToExchange.is_overtime) &&
        this.weekOvertime.week_overtime_control_type === 'block_overtime'
    },
    showConfirm () {
      return (this.weekOvertime.is_overtime || this.weekOvertimeEmployeeToExchange.is_overtime) &&
        this.weekOvertime.week_overtime_control_type === 'confirm_overtime'
    }
  },
  methods: {
    cancelRequest () {
      this.$prompt(this.$i18n?.t('State a reason'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, do it!'),
        cancelButtonText: this.$i18n?.t('No, cancel please'),
        inputPattern: /.{3,}/,
        inputPlaceholder: this.$i18n?.t('State a reason'),
        inputErrorMessage: 'Invalid value'
      }).then(({ value }) => {
        this.cancel(value)
      }).catch(() => {})
    },
    confirmRequest  () {
      const data = {
        subtype: 'exchange',
        status: 'confirmed',
        message: null
      }
      if (this.showConfirm) {
        const body = document.getElementsByTagName('body')
        body[0].setAttribute('style', 'padding-right: 0px;')
        this.$confirm(this.$i18n?.t('CONFIRM_EXCHANGE'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Ok'),
          cancelButtonText: this.$i18n?.t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          // console.log(this.userRequest)
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftInfoState.request_id, data]).then(response => {
            this.shiftInfoState.request_id = null
            this.$eventBus.emit('refetchEvents')
          })
        }).catch(() => {
        })
      } else {
        // console.log(this.userRequest)
        this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftInfoState.request_id, data]).then(response => {
          this.shiftInfoState.request_id = null
          this.$eventBus.emit('refetchEvents')
        })
      }
    },
    cancel (reason) {
      return new Promise((resolve, reject) => {
        let data
        if (reason) {
          data = {
            subtype: this.shiftRequest.subtype,
            status: 'declined',
            message: reason
          }
          this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.shiftInfoState.request_id, data]).then(response => {
            this.shiftInfoState.request_id = null
            resolve(response)
          }).catch((e) => {
            reject(e)
          })
        } else {
          this.toastWarning(this.$i18n?.t('Enter reason'))
          resolve(false)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
