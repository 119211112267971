<template>
  <modal-component :id="'EmergencyShiftsModal'" @closeModal="closeModal" :modalClass="'modal-md'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('EMERGENCY_SHIFTS_NOTIFICATIONS')}}</h5>
    </template>
    <template #body>
      <div v-if="dummy" class="dummy-container">
        <dummy-payment-required
          :compact-mode="true"
          v-if="dummy"
          @activate="activate()"
          :dummyInfo="dummyInfo">
        </dummy-payment-required>
      </div>
      <div v-else>
        <Form ref="observer" as="div">
          <div class="row mb-2">
            <div class="col-md-6">
              <ui-date-picker
                :label="$t('FREEBOARD_SHIFTS_EMERGENCY_FROM')"
                :hint="$t('FREEBOARD_SHIFTS_EMERGENCY_FROM_HINT')"
                name="date_from"
                v-model="from"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
            <div class="col-md-6">
              <ui-date-picker
                :label="$t('FREEBOARD_SHIFTS_EMERGENCY_TO')"
                :hint="$t('FREEBOARD_SHIFTS_EMERGENCY_TO_HINT')"
                name="date_to"
                v-model="to"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-12">
              <ui-textarea-input
                name="message"
                :label="$t('FREEBOARD_SHIFTS_EMERGENCY_MESSAGE')"
                :hint="$t('FREEBOARD_SHIFTS_EMERGENCY_MESSAGE_HINT')"
                v-model="message"
                :validation="{required: true, max: 256}"
                :rows="2"
                :placeholder="$t('FREEBOARD_SHIFTS_EMERGENCY_MESSAGE')"
              />
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template v-if="!dummy" #footer>
      <div class="w-100">
        <div class="mx-auto text-center">
          <button
            class="btn btn-success rounded-pill shadow-sm"
            @click="handleSending">
            {{ $t('SEND_EMERGENCY_SHIFTS_NOTIFICATIONS') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinDummy from '@/mixins/mixinDummy'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import moment from 'moment'

export default {
  name: 'ClickEmergencyShiftsNotificationModal',
  mixins: [
    momentMixin,
    errorMixin,
    mixinDummy
  ],
  components: {
    ModalComponent: ModalComponent,
    DummyPaymentRequired: DummyPaymentRequired
  },
  data () {
    return {
      from: moment().startOf('day').format(this.backendDateTimeFormat),
      to: moment().startOf('day').format(this.backendDateTimeFormat),
      message: '',
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  props: {
    projectId: Number
  },
  created () {
    this.init(true, 'check')
  },
  computed: {
    actualTz () {
      return this.$store.getters.actualTZ
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    locale () {
      return this.$store.getters.locale
    }
  },
  watch: {
    from (val) {
      if (moment(val, this.backendDateTimeFormat).isAfter(moment(this.to, this.backendDateTimeFormat))) {
        this.to = val
      }
    },
    to (val) {
      if (moment(val, this.backendDateTimeFormat).isBefore(moment(this.from, this.backendDateTimeFormat))) {
        this.from = val
      }
    }
  },
  methods: {
    init (justChecked, message) {
      const payload = {
        from: this.fromZoneToZone(this.from, this.actualTz, 'UTC').format(this.backendDateTimeFormat),
        to: this.fromZoneToZone(this.to, this.actualTz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
        message: message,
        just_checked: justChecked
      }
      return this.$store.dispatch('sendEmergencyShiftsNotification', [this.projectId, payload]).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    activate () {
      this.dummy = false
      this.init(true, 'check')
    },
    closeModal () {
      this.$parent.$emit('hideEmergencyShiftsModalShow')
    },
    handleSending () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const promise = this.init(false, this.message)
          promise.then(() => {
            document.getElementById('EmergencyShiftsModal').click()
            this.closeModal()
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
