import { HTTP } from '@/api/http_utils'

const UPDATE_ACTIVITY_TEMPLATES = 'UPDATE_ACTIVITY_TEMPLATES'
const UPDATE_ACTIVITY_TEMPLATE = 'UPDATE_ACTIVITY_TEMPLATE'
const CREATE_ACTIVITY_TEMPLATE = 'CREATE_ACTIVITY_TEMPLATE'
const DELETE_ACTIVITY_TEMPLATE = 'DELETE_ACTIVITY_TEMPLATE'
const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES'
const CREATE_ACTIVITY = 'CREATE_ACTIVITY'
const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
const DELETE_ACTIVITY = 'DELETE_ACTIVITY'

const state = {
  activityTemplates: [],
  activities: []
}

const promises = {}

const getters = {
  activityTemplates: state => state.activityTemplates,
  activities: state => state.activities
}

const mutations = {
  [UPDATE_ACTIVITY_TEMPLATES] (state, templates) {
    state.activityTemplates = templates
  },
  [CREATE_ACTIVITY_TEMPLATE] (state, template) {
    state.activityTemplates.push(template)
  },
  [UPDATE_ACTIVITY_TEMPLATE] (state, template) {
    state.activityTemplates = state.activityTemplates.map(item => {
      if (template.id === item.id) {
        return template
      }
      return item
    })
  },
  [DELETE_ACTIVITY_TEMPLATE] (state, templateId) {
    state.activityTemplates = state.activityTemplates.filter(item => item.id !== templateId)
  },
  [UPDATE_ACTIVITIES] (state, activities) {
    state.activities = activities
  },
  [CREATE_ACTIVITY]  (state, activity) {
    state.activities.push(activity)
  },
  [UPDATE_ACTIVITY] (state, activity) {
    state.activities = state.activities.map(item => {
      if (activity.id === item.id) {
        return activity
      }
      return item
    })
  },
  [DELETE_ACTIVITY] (state, activityId) {
    state.activities = state.activities.filter(item => +item.id !== +activityId)
  }
}

const actions = {
  updateShiftActivitiesList ({ commit }, payload) {
    commit(UPDATE_ACTIVITIES, payload)
  },
  getActivityTemplates ({ commit }, companyId) {
    const promiseKey = `companies/${companyId}/shift_activities/templates`

    if (promises[promiseKey]) {
      console.error('repeating activity templates')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(promiseKey).then(response => {
        commit(UPDATE_ACTIVITY_TEMPLATES, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  postActivityTemplates ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/templates`

    if (promises[promiseKey]) {
      console.error('repeating activity templates post')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        commit(CREATE_ACTIVITY_TEMPLATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  patchActivityTemplates ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/templates/${payload.id}`

    if (promises[promiseKey]) {
      console.error('repeating activity templates patch')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.patch(promiseKey, payload).then(response => {
        commit(UPDATE_ACTIVITY_TEMPLATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  deleteActivityTemplates ({ commit }, [companyId, id]) {
    const promiseKey = `companies/${companyId}/shift_activities/templates/${id}`

    if (promises[promiseKey]) {
      console.error('repeating activity templates delete')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.delete(promiseKey).then(_ => {
        commit(DELETE_ACTIVITY_TEMPLATE, id)
        resolve(null)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getShiftActivities ({ commit }, [companyId, queryParams]) {
    const promiseKey = `companies/${companyId}/shift_activities${queryParams}`

    if (promises[promiseKey]) {
      console.error('repeating activities')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(promiseKey).then(response => {
        commit(UPDATE_ACTIVITIES, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  postShiftActivities ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities`

    if (promises[promiseKey]) {
      console.error('repeating activities post')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        commit(CREATE_ACTIVITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  patchShiftActivities ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/${payload.id}`

    if (promises[promiseKey]) {
      console.error('repeating activities patch')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.patch(promiseKey, payload).then(response => {
        commit(UPDATE_ACTIVITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  patchShiftActivitiesMassAction ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/update`

    if (promises[promiseKey]) {
      console.error('repeating activities mass action')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        // commit(UPDATE_ACTIVITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  deleteActivity ({ commit }, [companyId, id]) {
    const promiseKey = `companies/${companyId}/shift_activities/${id}`

    if (promises[promiseKey]) {
      console.error('repeating activities delete')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.delete(promiseKey).then(_ => {
        commit(DELETE_ACTIVITY, id)
        resolve(null)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  distributeShiftActivity ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/distribute`

    if (promises[promiseKey]) {
      console.error('repeating activity distribute')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getPersonalActivity ({ commit }, companyId) {
    const promiseKey = `companies/${companyId}/shift_activities/active`

    if (promises[promiseKey]) {
      console.error('repeating active activities')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(promiseKey).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  patchPersonalShiftActivity ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/${payload.id}/edit`

    if (promises[promiseKey]) {
      console.error('repeating personal activity patch')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.patch(promiseKey, payload).then(response => {
        commit(UPDATE_ACTIVITY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getTemplatesForUnscheduledActivity ({ commit }, companyId) {
    const promiseKey = `companies/${companyId}/shift_activities/unscheduled/templates`

    if (promises[promiseKey]) {
      console.error('repeating data for unscheduled activities')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(promiseKey).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  createUnscheduledActivity ({ commit }, [companyId, payload]) {
    const promiseKey = `companies/${companyId}/shift_activities/unscheduled/create`

    if (promises[promiseKey]) {
      console.error('repeating creating unscheduled activities')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.post(`companies/${companyId}/shift_activities/unscheduled`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
