<template>
  <div class="card flex-fill shadow-none">
    <div class="card-header font-weight-bold">
      {{ $t("ACTIVE_MODULES_TITLE").toUpperCase() }}
    </div>
    <div class="card-body p-1 table-responsive">
      <table v-if="paginationConfig.paginated.length > 0" class="table table-hover">
        <tbody>
        <tr v-for="(module, key) in paginationConfig.paginated" :key="key">
          <td class="text-left">{{language in module.module.locales ? module.module.locales[language].name : module.module.name }}</td>
          <td class="text-right">
            <div>{{ getTrialDays(module) + ' ' + $t("DAYS_TO_EXPIRED") }}</div>
            <div>{{ $t("Ends on") + ' ' + toTimeZone(module.expires_at).format(localeDateFormat) }}</div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="text-center w-100 d-flex font-weight-bold">
        <div class="m-auto">{{ $t('Nothing to show') }}</div>
      </div>
    </div>
    <div v-show="paginationConfig.paginated.length > 0" class="card-footer bg-transparent pb-0">
      <pagination :rows="paginationConfig.rows" :customClasses="'justify-content-center'" :pageSize="pageSize" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../CommonComponents/Pagination/Pagination'
import paginationMixin from '../../../CommonComponents/Pagination/mixinPagination'
import momentMixin from '../../../../mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'ModulesTrial',
  mixins: [paginationMixin, momentMixin],
  components: {
    Pagination: Pagination
  },
  data () {
    return {
      pageSize: 5
    }
  },
  created () {
    this.paginationConfig.rows = [
    ]
    this.ModulesByCompany.forEach(module => {
      if (moment(module.expires_at).isSameOrAfter(moment())) this.paginationConfig.rows.push(module)
    })
  },
  computed: {
    language () {
      return this.$store.getters.locale
    },
    ModulesByCompany () {
      return this.$store.getters.ModulesByCompany
    }
  },
  methods: {
    getTrialDays (module) {
      return moment(module.expires_at).diff(moment(), 'days', false)
    }
  }
}
</script>

<style scoped>

</style>
