<template>
  <div>
    <div class="mb-1">
      <span class="font-weight-bold">{{ infoContent.name }}
      </span>
    </div>
    <div class="mb-1">
      {{ infoContent.time }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipComponent',
  props: {
    infoContent: Object
  }
}
</script>

<style scoped>

</style>
