<template>
  <div>
    <div class="my-2 text-primary text-uppercase font-weight-bold">
      {{ isParentInfo ? $t('PARENT_INFO') : $t('SUBTASKS_INFO') }}
    </div>
    <div v-for="task in subtasks" :key="task.id" class="card mb-1">
      <div class="card-body p-2">
        <SubtaskInfoComponent
          @taskUpdated="taskUpdated"
          @handleDelete="handleDelete"
          :task="task"
          :tz="tz"
          :isParentInfo="isParentInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SubtaskInfoComponent from '@/components/Tasks/Task/Components/SubtaskInfoComponent'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'SubtasksInfoComponent',
  mixins: [ToastMixin],
  components: { SubtaskInfoComponent },
  props: { subtasks: Array, tz: String, isParentInfo: Boolean },
  methods: {
    taskUpdated () {
      this.$emit('taskUpdated')
    },
    handleDelete (id) {
      this.$confirm(this.$t('This will permanently delete the task. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTask', [this.companyId, id]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
          // this.subtasks = this.subtasks.filter(child => child.id !== id)
          this.$emit('deleted', id)
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>

<style scoped>

</style>
