<template>
  <modal-component :id="'ChangeCompany'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Select a company to switch to')}}</h5>
    </template>
    <template #body>
      <div>
        <template v-for="item in companies" :key="item.index">
          <div @click="changeCompany(item.id)"
               class="btn btn-block text-left"
               :class="item.id === activeCompany ? 'btn-outline-primary' : 'btn-outline-secondary'"
               data-dismiss="modal"
               aria-label="Close">
            <span class="mr-2">{{item.name}}</span>
            <i v-if="item.id === activeCompany" class="icon-check-circle text-primary font-weight-bold"></i>
          </div>
        </template>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'ChangeCompany',
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    companies () {
      // this.$store.dispatch('getCompanies')
      return this.$store.state.company.companies
    },
    activeCompany () {
      let id
      if (this.$store.state.company.Id) {
        id = this.$store.state.company.Id
      } else {
        id = this.$store.getters.companyId
      }
      return id
    }
  },
  methods: {
    changeCompany (id) {
      localStorage.removeItem('currentProject')
      localStorage.removeItem('projectRole')
      localStorage.removeItem('rolesByProjects')
      this.$store.dispatch('clearCompanyStore').then(() => {
        this.$store.dispatch('getCompany', id).then(() => {
          this.$router.push(`/c/${id}/dashboard`)
          this.$store.dispatch('clearEmployeesCache')
          this.$store.dispatch('clearProjectsCache')
          this.$store.dispatch('clearSchedulesCache')
          this.$store.dispatch('clearHolidaysCache')
          this.$store.dispatch('clearLocationsCache')
          this.$store.dispatch('clearModulesCache')
          this.$store.dispatch('clearPositionsCache')
          this.$store.dispatch('clearSkillsCache')
          this.$store.dispatch('clearTasksCache')
          this.$store.dispatch('clearTemplatesCache')
          this.closeModal()
        })
      })
    },
    closeModal () {
      this.$store.state.modal = false
    }
  }
}
</script>

<style scoped>
  .active-icon-width {
    width: 15px !important;
  }
</style>
