<template>
  <Form ref="observer" as="tr" class="col-md-9">
    <template v-if="mode==='view'">
      <td>{{scheduleData.scheduleTitle}}</td>
      <td v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
       (isEmployeeHasPermission('get-company-schedules-manager') &&
        salarySettings.show_salary_to_managers)" class="text-center">
        {{rate}}
      </td>
      <td v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
       (isEmployeeHasPermission('get-company-schedules-manager')
        && salarySettings.show_salary_to_managers)" class="d-flex justify-content-center">
        <ui-date-picker
          name="period"
          :clearable="false"
          :validation="{required: true}"
          v-model="dateRateChange"
          :disabled="true"
          class="el-date-picker-160px"
        />
      </td>
      <td class="text-center">
        <template v-for="mark in marksToShow" :key="mark.id">
          <el-tooltip class="item" effect="dark" :content="mark.name" placement="top">
            <span
              :style="[{'background-color': mark.color }]" class="circlearancione">
          </span>
          </el-tooltip>
        </template>
      </td>
      <td class="text-center">
        <i class="icon-pencil btn-icon text-primary mx-1" @click="toggleMode"></i>
        <i class="icon-trash btn-icon text-danger mx-1"
           data-toggle="modal"
           data-backdrop="static"
           data-target="#RemoveUserFromScheduleModal"
           @click="() => { this.$eventBus.emit('showRemoveUserFromScheduleModal', scheduleData.scheduleId) }"></i>
      </td>
    </template>
    <template v-if="mode==='edit'">
      <td>{{scheduleData.scheduleTitle}}</td>
      <td v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
       (isEmployeeHasPermission('get-company-schedules-manager') &&
        salarySettings.show_salary_to_managers)" class="text-center">
        <ui-number-input
          name="rate"
          v-model="rate"
          :validation="{required: true, decimal: 2}"
        />
      </td>
      <td v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
       (isEmployeeHasPermission('get-company-schedules-manager') &&
        salarySettings.show_salary_to_managers)" class="d-flex justify-content-center">
        <ui-date-picker
          name="period"
          :clearable="false"
          :validation="{required: true}"
          v-model="dateRateChange"
          class="el-date-picker-160px"
          :teleported="true"
        />
      </td>
      <td class="text-center">
        <ui-tags-select
          name="user_marks"
          v-model="marks"
          :clearable="true"
          :filterable="true"
          :allow-create="false"
          :label-name="'name'"
          :value-name="'id'"
          :key-name="'key'"
          :options="userMarksChoices">
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
      </td>
      <td class="text-center">
        <i class="icon-check btn-icon text-success mx-1" @click="save"></i>
        <i class="icon-x btn-icon text-danger mx-1" @click="returnState"></i>
      </td>
    </template>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'

export default {
  name: 'UserScheduleInfoComponent',
  mixins: [momentMixin],
  data () {
    return {
      mode: 'view',
      rate: null,
      dateRateChange: null,
      marks: [],
      marksToShow: []
    }
  },
  props: { scheduleData: Object, userMarksChoices: Array, userId: Number },
  created () {
    // console.log(12, this.scheduleData)
    this.init()
  },
  computed: {
    salarySettings () {
      return this.$store.getters.company.salary
    }
  },
  watch: {
    scheduleData: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  methods: {
    init () {
      this.rate = Math.round(this.scheduleData.userData.rate * 100) / 100
      this.marks = this.scheduleData.userData.marks
      this.filterMarks()
      this.dateRateChange = moment.utc(new Date()).format(this.backendDateFormat)
    },
    returnState () {
      this.init()
      this.toggleMode()
    },
    toggleMode () {
      if (this.mode === 'view') {
        this.mode = 'edit'
      } else {
        this.mode = 'view'
      }
    },
    filterMarks () {
      this.marksToShow = []
      this.userMarksChoices.forEach(mark => {
        if (this.marks.includes(mark.id)) this.marksToShow.push(mark)
      })
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            id: this.scheduleData.userData.id,
            schedule_id: this.scheduleData.userData.schedule_id,
            employee_id: this.scheduleData.userData.employee_id,
            marks: this.marks,
            date_from: this.fromZoneToZone(this.dateRateChange, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
          }
          if (this.isEmployeeHasPermission('get-company-schedules-admin') ||
            (this.isEmployeeHasPermission('get-company-schedules-manager') &&
              this.salarySettings.show_salary_to_managers)) {
            data.rate = parseFloat(this.rate)
          }
          this.$store.dispatch('addPayRateToUser', [this.scheduleData.scheduleId, this.scheduleData.userData.id, data]).then((scheduleUser) => {
            const schedules = this.$store.getters.schedules
            schedules.forEach(item => {
              if (item.id === scheduleUser.schedule_id) {
                item.users.forEach(user => {
                  if (user.employee_id === scheduleUser.employee_id) {
                    user.marks = scheduleUser.marks
                  }
                })
                const cloned = JSON.parse(JSON.stringify(item))
                this.$store.commit(UPDATE_SCHEDULE, cloned)
              }
            })
            this.$emit('updateSchedules')
            this.toggleMode()
            if (scheduleUser) {
              this.toastSuccess(this.$t('Schedule successfully edited'))
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .circlearancione {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    border-color: white;
    border-radius: 50%;
    border-width: 5px;
    height: 20px;
    width: 20px;
  }
  .cell-width {
    min-width: max-content!important;
  }
</style>
