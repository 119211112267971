<template>
  <div>
    <template v-if="dummy">
      <dummy-payment-required
        @activate="moduleActivated()"
        :dummyInfo="dummyInfo"
        :prevent-dismiss="true"
        :compactMode="true"
        />
    </template>
    <template v-else>
      <Form ref="observer" as="div">
        <div v-if="isEditable && showButtons" class="row">
          <div class="col-lg-6">
            <ui-number-input
              name="amount"
              :label="$t('BONUS_PENALTY_AMOUNT')"
              v-model="amount"
              :min="0"
              :validation="{required: true, decimal: 2}"
            />
          </div>
          <div class="col-lg-6">
            <ui-select
              :label="$t('BONUS_PENALTY_TYPE')"
              :name="'type'"
              v-model="type"
              :clearable="false"
              :placeholder="$t('Select')"
              :options="typeChoices"
              :value-name="'value'"
              :label-name="'label'"
              :key-name="'value'"
            />
          </div>
        </div>
        <div v-if="isEditable && showButtons" class="row">
          <div class="col-lg-12">
            <ui-textarea-input
              :label="$t('BONUS_PENALTY_COMMENT')"
              v-model="comment"
              name="comment"
              :rows="2"
            />
          </div>
        </div>
        <div v-if="isEditable && showButtons" class="my-2 text-center">
          <button
            class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
            aria-label="Close"
            @click="save">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </Form>
      <el-divider v-if="isEditable && showButtons"></el-divider>
      <template v-if="bonuses.length < 1">
        <div class="text-center my-3">
          {{ $t('NO_BONUSES_MESSAGE') }}
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-lg-12">
            <table class="table table-responsive table-borderless table-sm">
              <tr class="text-center">
                <th>
                  {{$t('Amount')}}
                </th>
                <th>
                  {{$t('Type')}}
                </th>
                <th>
                  {{$t('Comment')}}
                </th>
                <th></th>
              </tr>
              <tr v-for="bonus in bonuses" :key="bonus.id">
                <td class="text-center">{{bonus.amount}}</td>
                <td class="text-center">{{$t(bonus.type)}}</td>
                <td class="text-center">{{bonus.comment}}</td>
                <td><i v-if="isEditable" class="icon-trash btn-icon text-danger" @click="deleteBonus(bonus.id)"></i></td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import mixinDummy from '@/mixins/mixinDummy'
import moment from 'moment/moment'

export default {
  name: 'BonusPenaltyComponent',
  components: { DummyPaymentRequired: DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      bonuses: [],
      bonusPenalty: null,
      amount: 0,
      type: 'bonus',
      comment: '',
      typeChoices: [
        {
          value: 'bonus',
          label: this.$t('bonus')
        },
        {
          value: 'penalty',
          label: this.$t('penalty')
        },
        {
          value: 'advance',
          label: this.$t('Advance')
        }
      ],
      disableValidator: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  props: {
    shiftInfo: Object,
    isEditable: Boolean,
    shift: Object
  },
  created () {
    // console.log(this.shiftInfo)
    if (this.shiftInfo.id) {
      this.init()
    }
  },
  computed: {
    ...mapGetters(['locale', 'currentProjectId']),
    showButtons () {
      if (this.isEmployeeHasPermission('update-schedule-admin')) {
        return true
      }
      const project = this.$store.getters.projectById(this.currentProjectId)

      return !(project.block_change_in_past_for_manager === true && moment(this.shift.start).isBefore(moment()))
    }
  },
  watch: {
    shiftInfo () {
      this.init()
    }
  },
  methods: {
    moduleActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      // console.log(5, this.$store.getters.shiftBonus)
      if (!this.$store.getters.shiftBonus) {
        this.$store.dispatch('getBonusByShift', [this.$store.getters.companyId, this.shiftInfo.id]).then(bonuses => {
          this.bonuses = bonuses
          this.$emit('updateBonuses', bonuses)
        }).catch((error) => {
          if (error.response.data.module) {
            this.dummy = true
            this.$eventBus.emit('dummy', true)
            this.dummyInfo = this.setupDummyData(error.response.data)
          } else {
            this.$router.push('dashboard')
          }
        })
      } else {
        this.bonuses = this.$store.getters.shiftBonus
      }
    },
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const payload = {
            amount: this.amount,
            comment: this.comment,
            type: this.type,
            shift_id: this.shiftInfo.id
          }
          this.$store.dispatch('createBonus', [this.$store.getters.companyId, payload]).then(bonus => {
            this.bonuses.push(bonus)
            // this.$validator.pause()
            this.disableValidator = true
            this.amount = 0
            // this.$validator.resume()
            this.comment = ''
            this.$emit('updateBonuses', this.bonuses)
            this.$eventBus.emit('refetchEventsLazy')
          }).catch(e => {
            this.toastError(this.$t(e.response.data.message))
          })
        }
      })
    },
    deleteBonus (bonusId) {
      this.$store.dispatch('deleteBonus', [this.$store.getters.companyId, bonusId]).then(() => {
        this.$store.commit('SHIFT_BONUS', null)
        this.init()
        this.$eventBus.emit('refetchEvents')
      })
    }
  }
}
</script>

<style scoped>

</style>
