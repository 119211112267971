import { HTTP } from '@/api/http_utils'
import {
  GET_USER_AVAILABILITY
} from '../mutation-types.js'

const UPDATE_DELETED_USERS = 'UPDATE_DELETED_USERS'
const USER_MARKS_DELETED = 'USER_MARKS_DELETED'
const UPDATE_EMPLOYEES_UNAVAILABILITY_LIST = 'UPDATE_EMPLOYEES_UNAVAILABILITY_LIST'
const UPDATE_EMPLOYEES_AVAILABILITY_LIST = 'UPDATE_EMPLOYEES_AVAILABILITY_LIST'

const state = {
  userAvailability: [],
  employeesUnavailability: [],
  employeesAvailability: [],
  deletedUsers: {},
  userMarksDeleted: {} // информация по меткам пользователей, сгрупированная по id расписания
}

const promises = {}

const getters = {
  userAvailability: state => state.userAvailability,
  employeesUnavailability: state => state.employeesUnavailability,
  employeesAvailability: state => state.employeesAvailability,
  deletedUsers: state => state.deletedUsers,
  userMarksDeleted: state => state.userMarksDeleted
}

const mutations = {
  [GET_USER_AVAILABILITY] (state, userAvailability) {
    state.userAvailability = userAvailability
  },
  [UPDATE_EMPLOYEES_UNAVAILABILITY_LIST] (state, data) {
    state.employeesUnavailability = data
  },
  [UPDATE_EMPLOYEES_AVAILABILITY_LIST] (state, data) {
    state.employeesAvailability = data
  },
  [UPDATE_DELETED_USERS] (state, data) {
    if (Array.isArray(data)) {
      const usersById = {}
      data.forEach(item => {
        usersById[item.id] = item
      })
      state.deletedUsers = usersById
    } else {
      state.deletedUsers = data
    }
  },
  [USER_MARKS_DELETED] (state, data) {
    state.userMarksDeleted = data
  }
}

const actions = {
  updateDeletedUsers ({ commit }, payload) {
    commit(UPDATE_DELETED_USERS, payload)
  },
  updateUnavailabilityList ({ commit }, list) {
    commit(UPDATE_EMPLOYEES_UNAVAILABILITY_LIST, list)
  },
  updateAvailabilityList ({ commit }, list) {
    commit(UPDATE_EMPLOYEES_AVAILABILITY_LIST, list)
  },
  getDeletedUsers ({ commit }, [companyId, projectId, data]) {
    const promiseKey = `/companies/${companyId}/projects/${projectId}/employees/deleted${data}`

    if (promises[promiseKey]) {
      console.error('repeating requests deleted users')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/projects/${projectId}/employees/deleted${data}`).then(response => {
        commit(UPDATE_DELETED_USERS, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getUserInfoById ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + data.companyId + '/employees/' + data.userId).then(userInfo => {
        resolve(userInfo)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserAvailabilityById ({ commit }, [companyId, userId]) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + companyId + '/employees/' + userId + '/availability')
        .then(userAvailability => {
          commit(GET_USER_AVAILABILITY, userAvailability)
          resolve(userAvailability)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserAvailabilityByProject ({ commit }, [projectId, userId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/employees/${userId}/availability`)
        .then(userAvailability => {
          commit(GET_USER_AVAILABILITY, userAvailability)
          resolve(userAvailability)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setUserAvailabilityById ({ commit }, [companyId, userId, availabilityData]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/employees/' + userId + '/availability', availabilityData)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setUserAvailabilityByProject ({ commit }, [projectId, userId, availabilityData]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/projects/${projectId}/employees/${userId}/availability`, availabilityData)
        .then(userAvailability => {
          resolve(userAvailability)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserUnavailabilityByPeriod ({ commit }, [projectId, data]) {
    const promiseKey = `/projects/${projectId}/employees/unavailability${data}`

    if (promises[promiseKey]) {
      console.error('repeating requests unavailability')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/employees/unavailability${data}`)
        .then(userUnavailability => {
          commit(UPDATE_EMPLOYEES_UNAVAILABILITY_LIST, userUnavailability)
          resolve(userUnavailability)
        })
        .catch(err => {
          reject(err)
        }).finally(() => {
          delete promises[promiseKey]
        })
    })
    promises[promiseKey] = promise

    return promise
  },
  getUserAvailabilityByPeriod ({ commit }, [projectId, data]) {
    const promiseKey = `/projects/${projectId}/employees/availability${data}`

    if (promises[promiseKey]) {
      console.error('repeating requests availability')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/employees/availability${data}`)
        .then(userAvailability => {
          commit(UPDATE_EMPLOYEES_AVAILABILITY_LIST, userAvailability)
          resolve(userAvailability)
        })
        .catch(err => {
          reject(err)
        }).finally(() => {
          delete promises[promiseKey]
        })
    })
    promises[promiseKey] = promise

    return promise
  },
  dropWorkingTime ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/employee_working_time`)
        .then(userUnavailability => {
          resolve(userUnavailability)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCrispHash () {
    return new Promise((resolve, reject) => {
      HTTP.get('/user/crisp_hash')
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  // getIntercomHash () {
  //   return new Promise((resolve, reject) => {
  //     HTTP.get('/user/intercom_hash')
  //       .then(response => {
  //         resolve(response)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }
  // facebookInfo ({ commit }, token) {
  //   return new Promise((resolve, reject) => {
  //     Auth.facebookInfo(token)
  //       .then(resp => {
  //         // commit(SET_USER_AVAILABILITY, availabilityData)
  //         resolve(resp)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
