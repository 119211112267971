<template>
  <div class="w-100 mt-2">
    <template v-if="viewType === 'resourceTimeMonth'">
      <div class="text-right w-100">
        <div class="text-danger font-weight-bold mb-1" style="font-size: 1em">
          {{ statistic.users }}
        </div>
        <div class="text-danger font-weight-bold mb-1" style="font-size: 1em">
          {{ statistic.shifts }}
        </div>
        <div class="text-danger font-weight-bold mb-1 text-truncate">
          <template v-if="monthDisplay === 'full'">
            <div style="font-size: 1em">
              {{ Math.round((statistic.hours + Number.EPSILON) * 100) / 100 }}
            </div>
          </template>
          <template v-if="monthDisplay === 'compact'">
            <el-tooltip
              :show-after="1000"
              :hide-after="4000"
              effect="light"
              placement="top">
              <template #content>
                <div>{{ Math.round((statistic.hours + Number.EPSILON) * 100) / 100 }}</div>
              </template>
              <div style="font-size: 1em;" class="text-truncate">
                {{ Math.round(statistic.hours + Number.EPSILON) }}
              </div>
            </el-tooltip>
          </template>
        </div>
        <div class="text-danger font-weight-bold text-nowrap mb-1">
          <template v-if="monthDisplay === 'full'">
            <div style="font-size: 1em">
              {{ Math.round((statistic.breakTime + Number.EPSILON) * 100) / 100 }}
            </div>
          </template>
          <template v-if="monthDisplay === 'compact'">
            <el-tooltip
              :show-after="1000"
              :hide-after="4000"
              effect="light"
              placement="top">
              <template #content>
                <div>{{ Math.round((statistic.breakTime + Number.EPSILON) * 100) / 100 }}</div>
              </template>
              <div style="font-size: 1em" class="text-truncate">
                {{ Math.round(statistic.breakTime + Number.EPSILON) }}
              </div>
            </el-tooltip>
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="viewType === 'resourceTimelineDay'">
      <div v-if="presenceStatMode === 'text'" class="row w-100 p-0 m-0">
        <div v-for="(item, index) in hrs"
             :key="index"
             class="col text-danger font-weight-bold overflow-hidden px-0 pl-1">
          <div class="row p-0 m-0">
            <div class="col px-0">
              <div class="">
                {{presence.shifts[index]}}
              </div>
            </div>
          </div>
          <div class="row p-0 m-0">
            <div class="col px-0">
              <div class="">
                {{presence.breaks[index]}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="presenceStatMode === 'graph'" class="row w-100 p-0 m-0">
        <div class="col text-danger font-weight-bold overflow-hidden px-0">
          <canvas ref="myChart" aria-label="Chart" role="img" :style="{direction: direction}" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row px-2">
        <div class="col-auto text-danger font-weight-bold">
          <div class="row">
            <div class="col p-1">
              <el-tooltip :content="translate.t('Users')" placement="top" effect="light">
                <i class="icon-users text-secondary mr-1" style="font-size: 1.2em"></i>
              </el-tooltip>
            </div>
            <div class="col p-1">
              {{ statistic.users }}
            </div>
          </div>
        </div>
        <div class="col-auto text-danger font-weight-bold">
          <div class="row">
            <div class="col p-1">
              <el-tooltip :content="translate.t('Shifts')" placement="top" effect="light">
                <i class="icon-calendar text-secondary mr-1" style="font-size: 1.2em"></i>
              </el-tooltip>
            </div>
            <div class="col p-1">
              {{ statistic.shifts }}
            </div>
          </div>
        </div>
        <div class="col-auto text-danger font-weight-bold">
          <div class="row">
            <div class="col p-1">
              <el-tooltip :content="translate.t('Hours total')" placement="top" effect="light">
                <i class="icon-clock text-secondary mr-1" style="font-size: 1.2em"></i>
              </el-tooltip>
            </div>
            <div class="col p-1">
              {{hrsForHuman(statistic.hours + Number.EPSILON)}}
            </div>
          </div>
        </div>
        <div class="col-auto text-danger font-weight-bold text-nowrap">
          <div class="row">
            <div class="col p-1">
              <el-tooltip :content="translate.t('Break, hours')" placement="top" effect="light">
                <i class="icon-coffee text-secondary mr-1" style="font-size: 1.2em"></i>
              </el-tooltip>
            </div>
            <div class="col p-1">
              {{hrsForHuman(statistic.breakTime + Number.EPSILON)}}
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

export default {
  name: 'StatisticShiftComponent',
  data () {
    this.myChart = null
    return {
      // hrs: new Array(24),
      // myChart: null
    }
  },
  props: {
    statistic: Object,
    basicRate: Number,
    translate: Object,
    payBreaks: Number,
    view: Object,
    monthDisplay: String,
    presence: Object,
    presenceStatMode: String,
    direction: String,
    hrs: Array
  },
  mounted () {
    if (this.viewType === 'resourceTimelineDay' && this.presenceStatMode === 'graph') {
      // console.log(this.presence)
      const myChart = new Chart(this.$refs.myChart, {
        // type: 'LineWithLine',
        data: {
          labels: this.presence.labels,
          datasets: [
            {
              label: this.translate.t('SHIFTS_GRAPH_LABEL'),
              data: this.presence.shifts,
              type: 'line',
              borderWidth: 1,
              fill: false,
              borderColor: 'rgb(36, 70, 194)',
              tension: 0.1,
              backgroundColor: 'rgb(36, 70, 194)'
            },
            {
              label: this.translate.t('BREAKS_GRAPH_LABEL'),
              data: this.presence.breaks,
              type: 'line',
              borderWidth: 1,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1,
              backgroundColor: 'rgb(75, 192, 192)'
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            point: {
              pointStyle: 'line'
            },
            line: {}
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              reverse: this.direction === 'rtl'
            },
            tooltip: {
              enabled: true,
              intersect: false,
              textDirection: this.direction,
              rtl: this.direction === 'rtl',
              callbacks: {
                title: (context) => {
                  return context[0].label + ' - ' + this.presence.labels[context[0].dataIndex + 1]
                }
              }
            }
          },
          layout: {
            padding: 0
          },
          interaction: {
            mode: 'index'
          },
          scales: {
            x: {
              reverse: this.direction === 'rtl',
              display: false,
              beginAtZero: true,
              ticks: {
                display: false
              }
            },
            y: {
              display: false,
              beginAtZero: true,
              ticks: {
                display: false
              }
            }
          }
        }
      })
      myChart.canvas.parentNode.style.height = '200px'
      this.myChart = myChart
    }
    this.$emit('disableLoader')
  },
  computed: {
    viewType () {
      return this.view.type
    },
    cost () {
      return (this.payBreaks === true) ? this.statistic.hours * this.basicRate : (this.statistic.hours - this.statistic.breakTime) * this.basicRate
    }
  },
  watch: {
    'presence.shifts' (value) {
      if (this.myChart) {
        this.myChart.data.datasets[0].data = value
        this.myChart.update('active')
      }
    },
    'presence.breaks' (value) {
      if (this.myChart) {
        this.myChart.data.datasets[1].data = value
        this.myChart.update('active')
      }
    }
  }
}
</script>

<style scoped>
  .canvas-style {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
  }
</style>
