<template>
  <div>
    <!-- Ворнинги про неуплату -->
    <HeaderWarningComponent />
    <nav class="navbar navbar-expand-md fixed-top navbar-light bg-white shadow-sm py-0 px-0 pr-1">
      <router-link :to="dashboardLink" class="navbar-brand mx-1 ml-md-3 mr-md-3">
        <img src="../../assets/images/logo.svg" width="180" alt="shifton">
      </router-link>
      <!-- Мобильные кнопки - колокольчик и меню -->
      <HeaderMobileButtons
        :alertsCount="alertsCount"
        :isMobile="isMobile"
      />
      <div id="navbarTogglerLoggedBar"
           class="collapse navbar-collapse justify-content-between flex-wrap"
           :class="{'vh-100' : isMobile}"
      >
        <ul class="navbar-nav d-flex justify-content-center gap-5">
          <!-- Scheduling -->
          <HeaderShedulingComponent
            v-if="hasCompany && isWorkMode"
            :isMobile="isMobile"
            :currentProject="currentProject"
            :hasProjectsByCompany="hasProjectsByCompany"
          />
          <!-- Attendance -->
          <HeaderAttendanceComponent
            v-if="hasCompany && isWorkMode"
            :isMobile="isMobile"
            @activate="activate"
          />
          <!-- Tasks -->
          <HeaderTasksComponent
            v-if="hasCompany && isServiceMode"
            :isMobile="isMobile"
            :employeePositions="employeePositions"
          />
          <!-- Map -->
          <HeaderMapComponent
            v-if="hasCompany && isWorkMode && isEmployeeHasPermission('view-geolocation')"
          />
          <!-- Activity -->
          <HeaderActivityComponent
            v-if="hasCompany && isWorkMode && isEmployeeHasPermission('get-shift-activities')"
            :isMobile="isMobile"
            @activate="activate"
          />
        </ul>
        <ul class="navbar-nav d-flex gap-10" :class="{ navBtn: firstSteps >= 100 }">
          <!-- First Steps -->
          <HeaderFirstStepsComponent
            v-if="isWorkMode && isEmployeeHasPermission('create-schedule') && loaded === true"
            :schedules="schedules"
            @updateFirstSteps="val => firstSteps = val"
          />
          <!-- Help center -->
          <HeaderHelpComponent
            :isMobile="isMobile"
          />
          <!-- Alerts -->
          <HeaderAlertComponent
            v-if="hasCompany"
            :alertsCount="alertsCount"
          />
          <!-- Employees -->
          <HeaderEmployeesComponent
            v-if="hasCompany"
            :isMobile="isMobile"
          />
          <!-- Requests -->
          <HeaderRequestsModal
            @openCreateRequestModal="openCreateRequestModal"
            v-if="hasCompany && isWorkMode"
            :isMobile="isMobile"
          />
          <!-- Company -->
          <HeaderCompanyComponent
            v-if="hasCompany"
            :isMobile="isMobile"
            :employeeProfile="employeeProfile"
          />
          <!-- User -->
          <HeaderUserComponent
            :isMobile="isMobile"
            @openChangePasswordModal="openChangePasswordModal"
          />
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Echo from 'laravel-echo'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import momentMixin from '@/mixins/mixinMoment'
import setup from '@/modules/setup'
import mixinDummy from '@/mixins/mixinDummy'
import HeaderWarningComponent from '@/components/Headers/HeaderComponents/HeaderWarningComponent'
import HeaderMobileButtons from '@/components/Headers/HeaderComponents/HeaderMobileButtons'
import HeaderShedulingComponent from '@/components/Headers/HeaderComponents/HeaderShedulingComponent'
import HeaderAttendanceComponent from '@/components/Headers/HeaderComponents/HeaderAttendanceComponent'
import HeaderTasksComponent from '@/components/Headers/HeaderComponents/HeaderTasksComponent'
import HeaderActivityComponent from '@/components/Headers/HeaderComponents/HeaderActivityComponent'
import HeaderMapComponent from '@/components/Headers/HeaderComponents/HeaderMapComponent'
import HeaderFirstStepsComponent from '@/components/Headers/HeaderComponents/HeaderFirstStepsComponent'
import HeaderHelpComponent from '@/components/Headers/HeaderComponents/HeaderHelpComponent'
import HeaderAlertComponent from '@/components/Headers/HeaderComponents/HeaderAlertComponent'
import HeaderEmployeesComponent from '@/components/Headers/HeaderComponents/HeaderEmployeesComponent'
import HeaderRequestsModal from '@/components/Headers/HeaderComponents/HeaderRequestsModal'
import HeaderCompanyComponent from '@/components/Headers/HeaderComponents/HeaderCompanyComponent'
import HeaderUserComponent from '@/components/Headers/HeaderComponents/HeaderUserComponent.vue'

export default {
  mixins: [mixinWindowWidthWatch, momentMixin, mixinDummy],
  components: {
    HeaderUserComponent,
    HeaderCompanyComponent,
    HeaderRequestsModal,
    HeaderEmployeesComponent,
    HeaderAlertComponent,
    HeaderHelpComponent,
    HeaderFirstStepsComponent,
    HeaderMapComponent,
    HeaderActivityComponent,
    HeaderTasksComponent,
    HeaderAttendanceComponent,
    HeaderShedulingComponent,
    HeaderMobileButtons,
    HeaderWarningComponent,
    ...mapGetters.modal
  },
  data: function () {
    return {
      nodeEnv: process.env.NODE_ENV,
      modal: false,
      alertsCount: 0,
      avatarHashId: undefined,
      headerCache: true,
      hasProjectsByCompany: false,
      polling: null,
      schedules: [],
      loaded: false,
      employeeProfile: {}, // профиль сотрудника текущей компании
      employeePositions: {},
      firstSteps: 100
    }
  },
  name: 'HeaderV',
  created () {
    this.$eventBus.on('checkSteps', this.init)
    if (this.companyId) {
      this.pollData()
      this.init()
    }
  },
  beforeUnmount () {
    clearInterval(this.polling)
  },
  mounted () {
    this.$eventBus.on('updateAlertCount', () => {
      this.loadAlertsCount()
    })
    if (localStorage.getItem('api_key') && this.$store.getters.companyId) {
      this.loadSchedule()
    }
    // сокеты через Pusher
    window.Pusher = require('pusher-js')
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_KEY,
      wsHost: process.env.VUE_APP_PUSHER_HOST,
      wsPort: process.env.VUE_APP_PUSHER_PORT,
      wssPort: process.env.VUE_APP_PUSHER_WSS_PORT,
      forceTLS: false,
      encrypted: process.env.VUE_APP_PUSHER_ENCRYPTED === true,
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: `${process.env.VUE_APP_API_AUTH}broadcasting/auth`,
      auth:
        {
          headers:
            {
              Authorization: 'Bearer ' + localStorage.getItem('api_key')
            }
        }
    })
    this.makeChannel()
    this.refreshRole()
  },
  computed: {
    ...mapGetters([
      'Profile',
      'profileId',
      'locale'
    ]),
    currentProject () {
      let id = this.$store.getters.currentProjectId
      if (!id) {
        id = localStorage.getItem('currentProject')
      }
      if (!id && this.$store.getters.projectsCount > 0) {
        id = this.$store.getters.projects[0].id
      }
      return id
    },
    projectsCount () {
      return this.$store.getters.projectsCount
    },
    schedulesCount () {
      return this.$store.getters.schedulesCount
    },
    dashboardLink () {
      if (this.isEmployeeHasPermission('get-dashboard-project-users')) {
        return `/c/${this.companyId}/dashboard`
      }
      if (this.workMode === 'work') {
        return '/dashboard'
      }
      return `/c/${this.companyId}/user-tasks`
    },
    workModeCurrent () {
      const workModeStore = this.$store.getters.workMode
      const workMode = localStorage.getItem('work_mode')
      // console.log(1, workMode || workModeStore)
      return workMode || workModeStore
    },
    isWorkMode () {
      return this.workModeCurrent === 'work'
    },
    isServiceMode () {
      return this.workModeCurrent === 'field_service'
    }
  },
  watch: {
    companyId () {
      // console.log(1234, this.workMode)
      this.init()
      if (this.companyId) {
        this.makeCompanyChannel()
      }
    },
    projectsCount () {
      this.hasProjectsByCompany = !(this.projectsCount === 0)
    },
    schedulesCount () {
      this.schedules = this.$store.getters.schedules
    },
    profileId () {
      this.makeChannel()
    }
  },
  methods: {
    activate () {
      this.$eventBus.emit('reloadDummy', 'attendance')
      this.$eventBus.emit('reloadDummy', 'activities')
      this.$nextTick(() => {
        this.init()
      })
    },
    pollData () {
      this.polling = setInterval(() => {
        this.$store.dispatch('clearCompanyStore')
        this.$store.dispatch('getCompany', this.companyId)
      }, 60 * 60 * 1000)
    },
    makeChannel () {
      if (this.profileId) {
        const privateChannel = window.Echo.private('App.User.' + this.$store.getters.profileId)
        privateChannel.notification((notification) => {
          if (notification.message === 'generation_processed') {
            const generationInfo = {
              data: notification.data,
              status: notification.message
            }
            this.$store.dispatch('setGenerationInfo', generationInfo)
          }

          if (notification.message === 'update_task') {
            this.$store.commit('UPDATE_TASKS_INDEXED', notification.data.task)
            this.$eventBus.emit('taskCalendarRefreshEvents')
          }
          if (notification.message === 'check_alerts') {
            this.loadAlertsCount()
            this.$eventBus.emit('updateAlerts')
          }
          if (notification.message === 'refresh_account') {
            this.$store.dispatch('dropProfile')
            this.$store.dispatch('clearCompanyStore')
            this.refreshRole()
          }
          if (notification.message.type === 'module_updated') {
            const fn = setup[notification.message.module_permission_id]
            if (notification.message.action === 'enable') {
              if (fn) {
                fn.install()
              }
            }
            if (notification.message.action === 'disable') {
              if (fn) {
                fn.uninstall()
              }
            }
          }
        })
      }
    },
    makeCompanyChannel () {
      const privateChannelCompany = window.Echo.private('App.Company.' + this.companyId)
      privateChannelCompany.listen('CheckCompanySettingsEvent', notification => {
        if (notification.message === 'update_modules') {
          this.$store.dispatch('clearCompanyStore').then(async () => {
            await this.$store.dispatch('getCompany', this.$store.getters.companyId)
            await this.$store.dispatch('updateShouldUpdateWeekStatistic', true)
            this.$eventBus.emit('refetchEvents')
            this.$store.dispatch('getModulesByCompany', this.$store.getters.companyId)
          })
        }
      })
    },
    init () {
      const coworkers = new Promise(resolve => {
        this.$store.dispatch('getEmployeesByCompany', this.companyId).then(result => {
          const employees = this.$store.getters.employees
          const profileId = this.$store.getters.profileId

          employees.some(employee => {
            if (+employee.user_id === +profileId) {
              this.employeeProfile = employee
              this.employeePositions = {}
              this.employeeProfile.positions.forEach(position => {
                this.employeePositions[position.id] = true
              })

              return true
            }

            return false
          })
          resolve(result)
        })
      })
      const projects = new Promise(resolve => {
        this.$store.dispatch('getProjectsByCompany', this.$store.getters.companyId).then(projects => {
          resolve(projects)
          this.hasProjectsByCompany = !(this.$store.getters.projectsCount === 0)
          this.$store.dispatch('setProjectRole', this.$store.getters.projects)
        })
      })
      const schedules = new Promise(resolve => {
        this.$store.dispatch('getSchedules', this.$store.getters.companyId).then(schedules => {
          resolve(schedules)
          this.schedules = schedules
        })
      })
      Promise.all([coworkers, projects, schedules]).then(() => {
        this.loaded = true
      })
      this.loadAlertsCount()
    },
    loadAlertsCount () {
      this.$store.dispatch('getAlertsCount', this.$store.getters.companyId).then((response) => {
        if (response) {
          this.alertsCount = response.unread_notifications_count
        }
      })
    },
    refreshRole () {
      this.$store.dispatch('getProfile').then(() => {
        let id = this.$store.getters.companyId
        if (!id) {
          id = this.$store.getters.Profile.companies[0]
        }
        if (id) this.$store.dispatch('getCompany', id)
      })
    },
    loadSchedule () {
      this.$store.dispatch('getSchedules', this.$store.getters.companyId).then(() => {
      }).catch(() => {})
    },
    openChangePasswordModal () {
      this.$emit('openChangePasswordModal')
    },
    openCreateRequestModal () {
      this.$emit('openCreateRequestModal')
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all');

.menu-color {
  color: #4a4a4a !important;
}

@media (min-width: 768px) {
  .no-md-border {
    border-top: 0 !important;
  }
  .no-md-border-bottom {
    border-bottom: 0 !important;
  }
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.gap-10 {
  gap: 10px
}

.gap-5 {
  gap: 5px
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>
