<template>
  <div class="accordion" id="accordionDate">
    <el-divider />
    <div
      @click="changeCollapse('period')"
      id="headingOne"
      type="button"
      data-toggle="collapse"
      data-target="#period"
      aria-expanded="true"
      aria-controls="period"
    >
      <div class="row">
        <div class="col">
          <h6 class="mb-0">
            {{$t('Select a date to which you want to add shifts')}}
            <ui-hint :content="$t('DATES_HINT')"/>
          </h6>
        </div>
        <div class="col-auto">
          <i class="icon-chevron-right btn-icon text-primary mx-1" />
        </div>
      </div>
    </div>

    <div
      id="period"
      class="collapse show"
      aria-labelledby="headingOne"
      data-parent="#accordionDate"
    >
      <div class="row mb-2">
        <div class="col-md-6">
          <ui-date-picker
            :label="$t('Shift date from')"
            name="date_from"
            v-model="shiftDateFrom"
            :validation="{required: true}"
            :clearable="false"
          />
        </div>
        <div class="col-md-6">
          <ui-date-picker
            :label="$t('Shift date to')"
            name="date_to"
            v-model="shiftDateTo"
            :validation="{required: true}"
            :clearable="false"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <SelectWeekDayComponent @daysChanged="onChangeDays" />
        </div>
      </div>
    </div>
    <el-divider />
    <div
      @click="changeCollapse('dates')"
      id="headingTwo"
      type="button"
      data-toggle="collapse"
      data-target="#dates"
      aria-expanded="true"
      aria-controls="dates"
    >
      <div class="row">
        <div class="col">
          <h6 class="mb-0">
            {{$t('MULTIPLE_DAYS_SELECT_HEADER')}}
            <ui-hint :content="$t('MULTIPLE_DAYS_SELECT_HEADER_HINT')"/>
          </h6>
        </div>
        <div class="col-auto">
          <i class="icon-chevron-right btn-icon text-primary mx-1" />
        </div>
      </div>
    </div>
    <div
      id="dates"
      class="collapse mb-2"
      aria-labelledby="headingTwo"
      data-parent="#accordionDate">
      <MultipleDaysPickerComponent @onChange="onChangeDates" />
    </div>
    <el-divider />
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'
import SelectWeekDayComponent from '@/components/CommonComponents/Pickers/SelectWeekDayComponent'
import MultipleDaysPickerComponent from '@/components/CommonComponents/Pickers/MultipleDaysPickerComponent'

export default {
  name: 'DatesPickerComponent',
  components: { MultipleDaysPickerComponent, SelectWeekDayComponent },
  mixins: [momentMixin],
  data () {
    return {
      activeCollapse: 'period',
      prevCollapse: 'dates',
      shiftDateFrom: '',
      shiftDateTo: '',
      pickerOptionsTo: {
        disabledDate: (date) => {
          if (this.dateToLimit && this.dateFromLimit) {
            return moment(this.shiftDateFrom, this.localeDateFormat).isAfter(moment(date)) ||
              this.blockedDateFrom.isAfter(moment(date)) ||
              moment(date).isSameOrAfter(this.blockedDateTo)
          }
          return false
        },
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      },
      pickerOptionsFrom: {
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1,
        disabledDate: (date) => {
          if (this.dateToLimit && this.dateFromLimit) {
            return this.blockedDateFrom.isAfter(moment(date)) ||
              moment(date).isSameOrAfter(this.blockedDateTo)
          }
          return false
        }
      }
    }
  },
  props: {
    shiftAddDate: Object,
    shiftAddEndDate: Object,
    dateToLimit: String,
    dateFromLimit: String,
    hint: String
  },
  created () {
    this.shiftDateFrom = this.shiftAddDate.format(this.backendDateFormat)
    this.shiftDateTo = this.shiftAddEndDate ? this.shiftAddEndDate.format(this.backendDateFormat) : this.shiftAddDate.format(this.backendDateFormat)
    // console.log(this.shiftDateFrom, this.shiftDateTo)
  },
  computed: {
    blockedDateTo () {
      return this.fromZoneToZone(this.dateToLimit, 'UTC', this.$store.getters.profileTimeZone)
    },
    blockedDateFrom () {
      return this.fromZoneToZone(this.dateFromLimit, 'UTC', this.$store.getters.profileTimeZone)
    }
  },
  watch: {
    activeCollapse (val) {
      this.$emit('onChangeActiveCollapse', val)
    },
    shiftDateFrom (val) {
      this.$emit('onChangeShiftDateFrom', val)
      if (moment(val, this.backendDateFormat).isAfter(moment(this.shiftDateTo, this.backendDateFormat))) {
        this.shiftDateTo = val
      }
    },
    shiftDateTo (val) {
      this.$emit('onChangeShiftDateTo', val)
      if (moment(val, this.backendDateFormat).isBefore(moment(this.shiftDateFrom, this.backendDateFormat))) {
        this.shiftDateFrom = val
      }
    }
  },
  methods: {
    changeCollapse (val) {
      this.activeCollapse = val
      setTimeout(() => {
        const el = document.getElementById(val)
        if (!el.classList.contains('show')) {
          const val2 = val === 'period' ? 'dates' : 'period'
          const el2 = document.getElementById(val2)
          el2.classList.add('show')
          // el.classList.add('show')
        }
      }, 500)
    },
    onChangeDates (dates) {
      this.$emit('onChangeDates', dates)
    },
    onChangeDays (days) {
      this.$emit('onChangeDays', days)
    }
  }
}
</script>

<style scoped>

</style>
