<template>
  <div class="p-1" data-cy="employees">
    <div class="row mb-3">
      <div class="col-lg-6 text-left py-1">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('Select employees') }}</span>
      </div>
      <div v-if="type === 'advanced' || type === 'by_availability' || type === 'by_pattern'" class="col-lg-6 text-right py-1">
        {{ $t('Employees needed') + ': ' + requiredWorkers }}
      </div>
      <div class="col-lg-6">
        <ui-checkbox
          name="linkShifts"
          v-if="type === 'advanced' || type === 'by_availability' || (type === 'by_pattern' && ['2/2', '1/2', '2/2/3', '3/3', '4/2', '5/2', '5/2/6/1'].includes(patternType))"
          v-model="shiftsControlState.linkShifts"
          :label="$t('Link shift to employee')"
          :hint="$t('LINK_SHIFT_TO_EMPLOYEE_HINT')"
        />
      </div>
      <div class="col-lg-6 py-1" :class="type === 'simple' ? 'text-left' : 'text-right'">
        {{ $t('Selected') }}: {{ employees.selectedUsers.length }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col bg-light">
        <ui-checkbox
          name="selectAllUsers"
          data-cy="select-all-employees"
          class="p-2"
          v-model="selectAllUsers"
          :label="$t('Select all')"
          @change="selectAll"
        />
      </div>
      <div v-if="positions.length > 0" class="col-auto bg-light">
        <div class="my-1 text-right" style="min-width: 240px">
          <ui-multi-select
            name="selectedPositions"
            v-model="selectedPositions"
            class="w-100"
            :options="positions"
            :key-name="'id'"
            :value-name="'id'"
            :label-name="'title'"
            :clearable="true"
          />
        </div>
      </div>
      <div v-if="positions.length > 0" class="col-auto bg-light">
        <div class="my-1 text-right">
          <button
            id="add_filtered"
            class="btn btn-primary rounded-pill shadow-sm mx-2"
            @click="addFilteredEmployees">
            {{ $t('ADD_FILTERED_EMPLOYEES')}}
          </button>
        </div>
      </div>
    </div>
    <div class="">
      <template v-if="loaded">
        <ui-checkbox-group
          name="selectedUsers"
          v-model="employees.selectedUsers"
          @change="change"
          class="row row-cols-1 row-cols-md-3">
          <div v-for="(user, key) in selectFilteredUsers" v-bind:key="key" class="col d-flex flex-column">
            <div class="mb-auto">
              <ui-checkbox
                data-cy="select-employees"
                :name="user.id + '__selectFilteredUsers'"
                :custom-value="user.id"
                :key="key"
              >
                {{ user.username }}
              </ui-checkbox>
              <div class="mx-3" style="font-size: 10px!important;" v-for="position in user.positions" :key="user.id + '__sp__' + position.id">
                <span class="position-color mr-1" :style="{ backgroundColor: position.color }"></span>{{position.title}}
              </div>
            </div>
            <div class="my-1" v-if="shiftsControlState.linkShifts">
              <ui-multi-select
                v-model="linkShiftsToUser[user.id]"
                class="mr-1 w-100"
                :options="templatesList"
                :key-name="'key'"
                :value-name="'key'"
                :label-name="'value'"
                :clearable="true"
                :name="user.id + '__card_number'"
                :placeholder="$t('Choose a shift')"
              />
            </div>
            <el-divider class="my-1" />
          </div>
        </ui-checkbox-group>
      </template>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <span class="invalid-feedback" :style="{display: showErrors ? 'block' : 'none'}">
          {{ $t('Select at least one user') }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 d-inline-flex py-2">
        <button
          id="demo_master_prev_step"
          class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
          @click="prev">
          {{ $t('Previous')}}
        </button>
        <button
          id="demo_master_next_step"
          class="btn rounded-pill shadow-sm"
          data-cy="next-step"
          v-bind:class="[{'btn-success': type === 'simple'}, 'btn-primary']"
          @click="save">
          {{ type === 'simple' ? $t('SAVE_WEB') : $t('Next step') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Employees',
  data () {
    return {
      preloadedUsers: {},
      selectUsers: [],
      selectFilteredUsers: [],
      selectAllUsers: false,
      employees: {
        selectedUsers: []
      },
      linkShifts: false,
      loaded: false,
      linkShiftsToUser: {},
      templatesList: [],
      showErrors: false,
      positions: [],
      selectedPositions: [],
      shiftsControlState: {},
      settingsState: {}
    }
  },
  props: {
    shiftsControl: {
      type: Object,
      default: function () {
        return { }
      }
    },
    settings: {
      type: Object,
      default: function () {
        return {}
      }
    },
    selectedUsers: Object,
    type: String,
    patternType: {
      type: String,
      default: ''
    },
    project: Number,
    requiredWorkers: Number
  },
  created () {
    this.settingsState = this.settings
    this.shiftsControlState = this.shiftsControl
    // console.log(123, this.linkShiftsToUser)
    if (this.type === 'advanced' || this.type === 'by_availability' || (this.type === 'by_pattern' && ['2/2', '1/2', '2/2/3', '3/3', '4/2', '5/2', '5/2/6/1'].includes(this.patternType))) {
      const templatesList = []
      for (const [key, value] of Object.entries(this.settingsState.preloadedTemplatesData)) {
        if (this.settingsState.preloadedTemplates.includes(parseInt(key))) {
          templatesList.push({
            key: key,
            value: value.name
          })
        }
        if (value.users) {
          value.users.forEach(usr => {
            if (!(usr in this.linkShiftsToUser)) {
              this.linkShiftsToUser[usr] = []
            }
            this.linkShiftsToUser[usr].push(key)
          })
        }
      }

      this.settingsState.templates.forEach((element, index) => {
        const templateKey = 'new_' + index
        templatesList.push({
          key: templateKey,
          value: element.name
        })

        element.users.forEach(usr => {
          if (!(usr in this.linkShiftsToUser)) {
            this.linkShiftsToUser[usr] = []
          }
          this.linkShiftsToUser[usr].push(templateKey)
        })
      })

      this.templatesList = templatesList
    }
    this.employees.selectedUsers = this.selectedUsers.users
    const positions = this.$store.getters.positions
    this.positions = []
    if (positions) {
      positions.sort((a, b) => {
        if (a.priority > b.priority) return 1
        if (a.priority < b.priority) return -1
        return 0
      })
      this.positions = positions
    }
    const users = this.$store.getters.employeesProjectIndexed[this.project] ? this.$store.getters.employeesProjectIndexed[this.project] : []
    // console.log(123, users)
    if (users.users) {
      users.users.forEach(user => {
        this.preloadedUsers[user.id] = user
        this.selectUsers.push(user)
        this.selectFilteredUsers.push(user)
        if (!(user.id in this.linkShiftsToUser)) {
          this.linkShiftsToUser[user.id] = []
        }
      })
    }
    this.selectUsers.sort((a, b) => (a.username.toLowerCase() > b.username.toLowerCase()) ? 1 : ((b.username.toLowerCase() > a.username.toLowerCase()) ? -1 : 0))
    this.selectFilteredUsers.sort((a, b) => (a.username.toLowerCase() > b.username.toLowerCase()) ? 1 : ((b.username.toLowerCase() > a.username.toLowerCase()) ? -1 : 0))
    this.selectAllUsers = Object.keys(this.preloadedUsers).length === this.selectedUsers.users.length
    this.loaded = true
  },
  computed: {
    checkedUsers () {
      return this.employees.selectedUsers
    }
  },
  beforeUnmount () {
    if (this.type === 'advanced' || this.type === 'by_availability' || (this.type === 'by_pattern' && ['2/2', '1/2', '2/2/3', '3/3', '4/2', '5/2'].includes(this.patternType))) {
      const usersByShiftTemplates = {}
      this.templatesList.forEach(element => {
        usersByShiftTemplates[element.key] = []
      })

      for (const [key, value] of Object.entries(this.linkShiftsToUser)) {
        value.forEach(shiftId => {
          usersByShiftTemplates[shiftId].push(parseInt(key))
        })
      }

      for (const [key, value] of Object.entries(usersByShiftTemplates)) {
        if (key.toString().startsWith('new_')) {
          const keyIndex = key.replace('new_', '')
          const tmpl = this.settingsState.templates[keyIndex]
          tmpl.users = value
          this.settingsState.templates[keyIndex] = tmpl
        } else {
          this.settingsState.preloadedTemplatesData[parseInt(key)].users = value
        }
      }
    }
  },
  watch: {
    settings () {
      this.settingsState = this.settings
    },
    shiftsControl () {
      this.shiftsControlState = this.shiftsControl
    },
    linkShiftsToUser: {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler: function (after, before) {
        // console.log(before, after)
        // console.log('linkShiftsToUser has changed!')
      }
    },
    checkedUsers () {
      this.showErrors = false
    },
    showErrors () {
      if (this.showErrors) {
        this.showAlert()
      }
    },
    selectedPositions () {
      this.filterEmployees()
    }
  },
  methods: {
    filterEmployees () {
      this.selectAllUsers = false
      if (this.selectedPositions.length < 1) {
        this.selectFilteredUsers = [...this.selectUsers]
        return
      }
      this.employees.selectedUsers = []
      this.selectFilteredUsers = this.selectUsers.filter(employee => {
        let val = false
        employee.positions.forEach(position => {
          if (this.selectedPositions.includes(position.id)) {
            val = true
          }
        })
        return val
      })
    },
    addFilteredEmployees () {
      this.selectFilteredUsers.forEach(employee => {
        this.employees.selectedUsers.push(employee.id)
      })
      this.employees.selectedUsers = [...new Set(this.employees.selectedUsers)]
    },
    isChecked (id) {
      return this.employees.selectedUsers.includes(id)
    },
    showAlert () {
      this.$message({
        showClose: true,
        message: this.$i18n?.t('Select at least one user'),
        customClass: 'mt-5',
        type: 'error'
      })
    },
    save () {
      this.showErrors = false
      if (this.checkedUsers.length > 0) {
        this.$emit('save', {
          step: 'employees',
          data: {
            users: this.employees.selectedUsers,
            // checkAvailability: this.checkAvailability,
            preview: this.type !== 'simple'
          }
        })
      } else {
        this.showErrors = true
      }
    },
    prev () {
      this.$emit('prev', {
        step: 'employees',
        data: {
          users: this.employees.selectedUsers
        }
      })
    },
    change () {
      this.selectAllUsers = this.checkedUsers.length === this.preloadedUsers.length
    },
    selectAll () {
      this.employees.selectedUsers = []
      if (this.selectAllUsers) {
        this.selectFilteredUsers.forEach(user => {
          this.employees.selectedUsers.push(user.id)
        })
      }
    }
  }
}
</script>

<style scoped>
.position-color {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  vertical-align: middle;
}
</style>
