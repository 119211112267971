<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-else-if="loaded" class="container-fluid p-0">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="align-middle text-left table-header text-nowrap" v-for="(item, key) in columns" v-bind:key="item.name+ '_' + key">{{item.title}}</th>
          </tr>
          </thead>
          <tbody>
          <tr class="text-left" v-for="(row, index) in paginationConfig.paginated" v-bind:key="index">
            <td >
              {{ row.name }}
              <div class="small">
                {{positionsByUsers[row.employee_id] ? positionsByUsers[row.employee_id].positionName : ''}}
              </div>
            </td>
            <td>{{ displayType(row.time_off_type) }} <span v-if="row.reason">({{ row.reason.title }})</span></td>
            <td>{{ row.period }}</td>
            <td v-if="row.duration === 'all_day' && row.paid">{{ roundValue(row.duration_time / 60.0 / 24.0) }}</td><td v-else>0</td>
            <td v-if="row.duration === 'all_day' && !row.paid">{{ roundValue(row.duration_time / 60.0 / 24.0) }}</td><td v-else>0</td>
            <td v-if="row.duration !== 'all_day' && row.paid">{{ parseFloat(row.duration_time / 60.0).toFixed(2) }}</td><td v-else>0</td>
            <td v-if="row.duration !== 'all_day' && !row.paid">{{ parseFloat(row.duration_time / 60.0).toFixed(2) }}</td><td v-else>0</td>
          </tr>
          </tbody>
        </table>
      </div>
      <pagination :rows="paginationConfig.rows" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import Pagination from '../../CommonComponents/Pagination/Pagination'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  mixins: [errorMixin, momentMixin, paginationMixin, mixinDummy],
  data () {
    return {
      columns: [
        { name: 'name', title: this.$i18n?.t("Employee's name") },
        { name: 'time_off_type', title: this.$i18n?.t('Time-off type') },
        { name: 'period', title: this.$i18n?.t('Period') },
        { name: 'hours', title: this.$i18n?.t('Paid days') },
        { name: 'paid_hours', title: this.$i18n?.t('Unpaid days') },
        { name: 'unpaid_hours', title: this.$i18n?.t('Paid hours') },
        { name: 'unpaid_hours', title: this.$i18n?.t('Unpaid hours') }
      ],
      rows: [],
      headRows: {},
      loaded: false,
      display: [],
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  name: 'TimeOff',
  props: ['projects', 'period', 'search', 'selectedMarks', 'marksByUsers', 'selectedPositions', 'typeTimeOff', 'positionsByUsers'],
  components: { pagination: Pagination, DummyPaymentRequired: DummyPaymentRequired },
  created () {
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    filteredEmployees: function () {
      return this.filteredByName(this.filteredByPositions(this.filteredByMarks(this.filteredByTypeTimeOff(this.rows))))
    }
  },
  methods: {
    filteredByTypeTimeOff (employees) {
      if (this.typeTimeOff.includes('all')) {
        return employees
      } else {
        return employees.filter(row => this.typeTimeOff.includes(row.time_off_type))
      }
    },
    filteredByName (employees) {
      return employees.filter((row) => {
        if (this.search === '') {
          return row
        }
        return ((row.name.toLowerCase()).indexOf(this.search.toString().toLowerCase()) > -1)
      })
    },
    filteredByPositions (employees) {
      return employees.filter((row) => {
        if (this.selectedPositions.length === 0) {
          return row
        }
        return this.positionsByUsers[row.employee_id] && this.selectedPositions.includes(this.positionsByUsers[row.employee_id].positionId)
      })
    },
    filteredByMarks (employees) {
      const selectedMarks = this.selectedMarks
      const marksByUsers = this.marksByUsers

      return employees.filter((row) => {
        if (selectedMarks.length === 0 && marksByUsers) {
          return row
        }
        let find = 0
        const userId = row.employee_id
        selectedMarks.forEach(selectMark => {
          const splitData = selectMark.split(':')
          const scheduleId = parseInt(splitData[0])
          const markId = parseInt(splitData[1])
          if (marksByUsers[userId] && scheduleId in marksByUsers[userId] && marksByUsers[userId][scheduleId].indexOf(markId) !== -1) {
            find += 1
          }
        })
        return find > 0
      })
    },
    setFilteredData () {
      this.paginationConfig.rows = this.filteredEmployees
    },
    displayType (type) {
      if (type === 'sick') {
        return this.$i18n?.t('Sick day')
      } else if (type === 'vacation') {
        return this.$i18n?.t('Vacation')
      } else if (type === 'holiday') {
        return this.$i18n?.t('Holiday')
      } else if (type === 'exchange') {
        return this.$i18n?.t('Exchange')
      } else if (type === 'move_to_freeboard') {
        return this.$i18n?.t('Move to Free Board')
      } else if (type === 'move_to_user') {
        return this.$i18n?.t('Move to user')
      } else return this.$i18n?.t(type)
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    getTableData () {
      this.loaded = false
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      const data = '?date_from=' + dateFrom + '&date_to=' + dateTo
      this.$store.dispatch('getTimeOff', [this.$store.getters.companyId, data]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        this.setTable(response)
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    setTable (response) {
      const self = this
      self.rows = []
      this.rows = []
      if (response.length > 0) {
        response.forEach(function (item) {
          const row = {
            name: item.user_data.full_name,
            employee_id: item.user_data.employee_id,
            time_off_type: item.type_time_off,
            period: self.toTimeZone(item.date_from).format(self.localeDateTimeFormat) + ' - ' + self.toTimeZone(item.date_to).format(self.localeDateTimeFormat),
            duration: item.duration,
            duration_time: item.duration_time,
            paid: item.paid,
            reason: item.reason
          }
          self.rows.push(row)
        })
        this.setFilteredData()
      } else {
        self.rows = []
        this.setFilteredData()
      }
    }
  },
  watch: {
    period: function () {
      this.getTableData()
    },
    search: function () {
      this.setFilteredData()
    },
    selectedMarks: function () {
      this.setFilteredData()
    },
    selectedPositions () {
      this.setFilteredData()
    },
    typeTimeOff () {
      this.setFilteredData()
    }
  }
}
</script>

<style>
</style>
