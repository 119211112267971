import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_LOCATIONS_LIST = 'UPDATE_LOCATIONS_LIST'
const UPDATE_LOCATION = 'UPDATE_LOCATION'
const DELETE_LOCATION = 'DELETE_LOCATION'

const state = {
  locationsIndex: {}
}

const getters = {
  locations: state => Object.values(state.locationsIndex).sort((a, b) => {
    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
  }),
  locationsIndexed: state => state.locationsIndex,
  locationsCount: state => {
    return Object.values(state.locationsIndex).length
  },
  hasLocation: (state, getters) => {
    return getters.locationsCount > 0
  },
  locationById: (state) => (id) => {
    return state.locationsIndex[id]
  }
}

const mutations = {
  [UPDATE_LOCATIONS_LIST] (state, payload) {
    state.locationsIndex = {}
    payload.forEach(item => {
      state.locationsIndex[item.id] = item
    })
  },
  [UPDATE_LOCATION] (state, location) {
    state.locationsIndex[location.id] = location
  },
  [DELETE_LOCATION] (state, locationId) {
    delete state.locationsIndex[locationId]
  }
}

const actions = {
  clearLocationsCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getLocations ({ commit }, companyId) {
    const promiseKey = '/companies/' + companyId + '/locations'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.locationsIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/locations').then(locations => {
        commit(UPDATE_LOCATIONS_LIST, locations)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(locations)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getSingleLocation ({ commit }, [companyId, locationId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/locations/${locationId}`).then(response => {
        commit(UPDATE_LOCATION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  createLocation ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/locations`, payload).then(response => {
        commit(UPDATE_LOCATION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  editLocation ({ commit }, [companyId, locationId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/locations/${locationId}`, payload).then(response => {
        commit(UPDATE_LOCATION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteLocation ({ commit }, [companyId, locationId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/locations/${locationId}`).then(response => {
        commit(DELETE_LOCATION, locationId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
