<template>
  <modal-component :id="'ClickAvailabilityModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title d-flex align-items-center" id="InvitesModalTitle">
        <span class="mr-1">{{ $t('AVAILABILITY_MODAL')}}</span>
        <ui-hint class="mx-1" :content="$t('AVAILABILITY_MODAL_HINTS')"/>
      </h5>
    </template>
    <template #body>
      <div>
        <div class="row">
          <div class="col">
            <ui-date-picker
              name="from_date"
              :disabled="!!eventId"
              :clearable="false"
              :label="$t('STARTS_FROM_DATE_LABEL')"
              :hint="$t('STARTS_FROM_DATE_LABEL_HINT')"
              :validation="{required: true}"
              v-model="rRules.dtstart"
              @change="handleStartDateChange"
              :placeholder="$t('Pick a day')"
            />
          </div>
          <div class="col"></div>
        </div>
        <div class="row">
          <div class="col">
            <ui-textarea-input
              :rows="2"
              name="note"
              v-model="note"
              :placeholder="$t('note')"
              :label="$t('NOTE_LABEL')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="font-weight-bold my-2">
              {{$t('AVAILABILITY_TYPE_LABEL')}}
              <ui-hint :content="$t('AVAILABILITY_TYPE_HINTS')"/>
            </div>
            <ui-radio
              name="availability"
              :label="$t('availability')"
              v-model="event_type"
              :set-value="'availability'"
            />
            <ui-radio
              name="unavailability"
              :label="$t('unavailability')"
              v-model="event_type"
              :set-value="'unavailability'"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="font-weight-bold my-2">{{$t('SELECT_TIME_LABEL')}}</div>
          </div>
          <div class="col-auto">
            <ui-checkbox
              name="IS_FULL_DAY"
              v-model="isFullDay"
              :label="$t('IS_FULL_DAY')"
              :hint="$t('IS_FULL_DAY_TOOLTIP')"
            />
          </div>
          <div class="col">
            <ui-time-picker
              :name="'time_from'"
              :placeholder="$t('from')"
              :validation="{required: true}"
              :clearable="false"
              :value-format="'HH:mm'"
              v-model="from"
              prefix-icon="Clock"
            />
          </div>
          <div class="col">
            <ui-time-picker
              :name="'time_to'"
              :placeholder="$t('to')"
              :validation="{required: true}"
              :clearable="false"
              :value-format="'HH:mm'"
              v-model="to"
              prefix-icon="Clock"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="font-weight-bold my-2">
              {{$t('IS_RECURRING_LABEL')}}
              <ui-hint :content="$t('IS_RECURRING_HINTS')"/>
            </div>
            <ui-checkbox
              name="IS_RECURRING_EVENT"
              v-model="is_recurring"
              :label="$t('IS_RECURRING_EVENT')"
            />
          </div>
        </div>
        <template v-if="is_recurring">
          <div class="row">
            <div class="col-4">
              <div class="font-weight-bold mt-1 mb-2">
                {{$t('IS_LIMITED_LABEL')}}
                <ui-hint :content="$t('IS_LIMITED_HINT')"/>
              </div>
              <ui-checkbox
                name="IS_LIMITED"
                v-model="isLimited"
                :label="$t('IS_LIMITED')"
              />
            </div>
            <div class="col-4">
              <ui-date-picker
                :disabled="!isLimited"
                name="until_date"
                :clearable="true"
                :label="$t('UNTIL_DATE_LABEL')"
                :hint="$t('UNTIL_DATE_HINT')"
                v-model="rRules.until"
                :placeholder="$t('Pick a day')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <ui-select
                name="interval"
                v-model="rRules.interval"
                :clearable="false"
                :placeholder="$t('Select')"
                :label="$t('FREQUENCY_LABEL')"
                :hint="$t('FREQUENCY_LABEL_HINT')"
                :options="frequencyOptions"
                :key-name="'value'"
                :label-name="'label'"
                :value-name="'value'"
              />
            </div>
          </div>
          <template v-if="rRules.interval === 1">
            <div class="row">
              <div class="col">
                <div class="font-weight-bold mt-2">{{$t('WEEK_SELECT_LABEL')}}</div>
              </div>
            </div>
            <SelectWeekDayComponent
              @rruleChanged="onChangeDays"
              :selection="rRules.byweekday"
              :newHint="'AVAILABILITY_DAYS_HINT'"
            />
          </template>
        </template>
        <div class="mx-auto text-center mt-3">
          <button
            v-if="eventId"
            class="btn btn-danger rounded-pill btn-fix-width shadow-sm mx-1"
            data-cy="add-user-button"
            @click="deleteEvent">
            {{ $t('Delete') }}
          </button>
          <button
            class="btn btn-success rounded-pill btn-fix-width shadow-sm mx-1"
            data-cy="add-user-button"
            @click="save">
            {{ $t('SAVE_WEB') }}
          </button>
          <button
            v-if="eventId"
            class="btn btn-warning rounded-pill btn-fix-width shadow-sm mx-1"
            data-cy="add-user-button"
            @click="saveAsNew">
            {{ $t('SAVE_AS_NEW_RRULE') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'
import errorsMixin from '@/mixins/mixinApiErrors'
import SelectWeekDayComponent from '@/components/CommonComponents/Pickers/SelectWeekDayComponent'

export default {
  name: 'AvailabilityModal',
  components: {
    SelectWeekDayComponent,
    ModalComponent
  },
  mixins: [momentMixin, errorsMixin],
  data () {
    return {
      note: null,
      color: '#74b65f',
      event_type: 'availability',
      is_recurring: false,
      duration: '09:00',
      rRules: {
        dtstart: null,
        until: null,
        duration: 0,
        freq: 'DAILY',
        interval: 1,
        byhour: 8,
        byminute: 0,
        byweekday: [],
        exdate: []
      },
      isFullDay: false,
      from: null,
      to: null,
      disableTime: false,
      frequencyOptions: [
        {
          value: 1,
          label: this.$t('DAILY')
        },
        {
          value: 7,
          label: this.$t('WEEKLY')
        },
        {
          value: 14,
          label: this.$t('2_WEEKLY')
        }
      ],
      isLimited: false,
      dialogFormVisible: false,
      updateType: 'all'
    }
  },
  props: { selectedDate: [Date, String], selectedEmployeeId: Number, eventId: Number },
  created () {
    // console.log(55, moment.utc(this.selectedDate).startOf('day').format(this.backendDateTimeFormat))
    if (this.eventId) {
      const event = this.eventsIndexed[this.eventId]
      if (event) {
        // console.log(55, event.dtstart)
        // console.log(event.byhour)
        this.note = event.note
        this.color = event.color
        this.event_type = event.event_type
        this.is_recurring = event.is_recurring
        this.duration = moment.utc(moment.duration(event.duration, 'minutes').asMilliseconds()).format('HH:mm')
        this.rRules.dtstart = moment.utc(this.selectedDate).startOf('day').format(this.backendDateTimeFormat)
        if (event.until) {
          this.isLimited = true
          // this.rRules.until = moment(event.until).add(-1, 'day').format(this.backendDateTimeFormat)
          this.rRules.until = event.until
        }
        this.rRules.freq = 'DAILY'
        this.rRules.interval = event.interval
        this.rRules.byhour = event.byhour
        this.rRules.byminute = event.byminute
        this.rRules.byweekday = event.byweekday
        this.rRules.exdate = event.exdate
        // console.log(3, event.byhour, this.rRules)
        if (event.duration >= 1440) {
          this.isFullDay = true
        }
      }
    } else {
      this.rRules.dtstart = moment(this.selectedDate).format(this.backendDateTimeFormat)
    }
    this.setTime()
  },
  computed: {
    eventsIndexed () {
      return this.$store.getters.availabilityIndexed
    }
  },
  watch: {
    'rRules.interval' () {
      if (this.rRules.interval > 1) {
        this.rRules.byweekday = []
      }
    },
    selectedDate () {
      this.rRules.dtstart = moment(this.selectedDate).format(this.backendDateTimeFormat)
    },
    isFullDay () {
      if (this.isFullDay) {
        this.rRules.byhour = 0
        this.rRules.byminute = 0
        this.duration = '24:00'
        this.from = '00:00'
        this.to = '00:00'
        this.disableTime = true
      } else {
        this.disableTime = false
      }
    },
    from () {
      this.setRRuleTime()
    },
    to () {
      this.setRRuleTime()
    },
    event_type () {
      if (this.event_type === 'availability') {
        this.color = '#74b65f'
      } else {
        this.color = '#e28b23'
      }
    },
    is_recurring () {
      if (!this.is_recurring) {
        this.rRules.until = null
        this.isLimited = false
      }
    },
    isLimited () {
      if (!this.isLimited) {
        this.rRules.until = null
      }
    }
  },
  methods: {
    handleStartDateChange (val) {
      if (moment(val).isAfter(moment(this.rRules.until))) {
        this.rRules.until = val
      }
      // console.log(val)
      // console.log(this.rRules.until)
    },
    closeModal () {
      // console.log(1)
      this.$emit('closeClickAvailabilityModal')
    },
    setTime () {
      this.from = moment(this.rRules.dtstart)
        .add(this.rRules.byhour, 'hours')
        .add(this.rRules.byminute, 'minutes')
        .format(this.backendTimeFormat)
      this.to = moment(this.from, this.backendTimeFormat)
        .add(moment.duration(this.duration).asMinutes(), 'minutes')
        .format(this.backendTimeFormat)
      // console.log(4, this.from, this.to)
    },
    setRRuleTime () {
      const from = this.from.split(':')
      // console.log(5, parseInt(from[0]), parseInt(from[1]))
      this.rRules.byhour = parseInt(from[0])
      this.rRules.byminute = parseInt(from[1])
      if (this.from === this.to) {
        this.duration = '24:00'
        return
      }
      const d1 = moment.duration(this.from).asMinutes()
      const d2 = moment.duration(this.to).asMinutes()
      let durationAsMinutes = 0
      if (d2 < d1) {
        durationAsMinutes = d2 + 24 * 60 - d1
      } else {
        durationAsMinutes = d2 - d1
      }
      this.duration = moment
        .utc(moment.duration(durationAsMinutes, 'minutes').asMilliseconds())
        .format('HH:mm')
    },
    onChangeDays (days) {
      this.rRules.byweekday = days
      // console.log(days)
    },
    save () {
      const payload = this.handlePayload()
      if (!this.eventId) {
        this.createAvailability(payload)
      } else {
        // бизнеслогика апдейта вынесена в родительский помпонент, тк нужно вызывать дополнительную модалку с подвтерждением
        this.$emit('onUpdate', payload)
        document.getElementById('ClickAvailabilityModal').click()
        this.$emit('closeClickAvailabilityModal')
      }
    },
    saveAsNew () {
      const payload = this.handlePayload()
      this.createAvailability(payload)
    },
    deleteEvent () {
      const payload = {

      }
      this.$emit('onDelete', payload)
      document.getElementById('ClickAvailabilityModal').click()
      this.$emit('closeClickAvailabilityModal')
    },
    handlePayload () {
      const payload = { ...this.rRules }
      payload.dtstart = moment(this.rRules.dtstart).format(this.backendDateTimeFormat)
      payload.note = this.note
      payload.is_recurring = this.is_recurring
      payload.color = this.color
      payload.event_type = this.event_type
      payload.duration = moment.duration(this.duration).asMinutes()
      if (this.rRules.until) {
        payload.until = moment(this.rRules.until).endOf('day').format(this.backendDateTimeFormat)
      }
      return payload
    },
    createAvailability (payload) {
      this.$store.dispatch('createAvailability', [this.selectedEmployeeId, payload]).then(() => {
        document.getElementById('ClickAvailabilityModal').click()
        this.$emit('closeClickAvailabilityModal')
        this.$emit('refetch')
      }).catch(e => {
        this.toastError(this.$i18n?.t(e.message))
      })
    }
  }
}
</script>

<style scoped>

</style>
