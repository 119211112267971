<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div class="container-fluid p-0" v-else-if="loaded">
      <ui-checkbox
        name="showHuman"
        v-model="showHuman"
        :container-class="'p-2'"
      >
        {{ $t('SHOW_HUMAN') }}
      </ui-checkbox>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="align-middle text-center table-header" v-for="(item, key) in columns" v-bind:key="item.name+ '_' + key">{{item.title}}</th>
          </tr>
          </thead>
          <tbody v-if="this.rows.length > 0">
          <template v-for="value in paginationConfig.paginated">
            <template v-for="(rows, employee_id) in value">
              <template v-if="headRows[employee_id]">
                <tr v-bind:key="employee_id + '_total'" class="secondary pointer border border-primary" @click="showHide(employee_id)">
                  <td class="text-nowrap">
                    <strong>{{rows[0]['name']}}</strong>
                    <div class="small">
                      {{rows.length > 0 && positionsByUsers[rows[0]['employee_id']] ? positionsByUsers[rows[0]['employee_id']].positionName : ''}}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['duration'])}}
                      <template v-if="showHuman">
                        <br>
                        ({{hrsForHuman(headRows[employee_id]['duration'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['break'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['break'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['lateness'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['lateness'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['early'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['early'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['absence'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['absence'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['absence_worked'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['absence_worked'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['worked'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['worked'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['overtime'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['overtime'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center"><strong>{{roundValue(headRows[employee_id]['shifts_count'])}}</strong></td>
                </tr>
                <template v-for="(row, i) in rows">
                  <template v-if="!display.includes(employee_id)">
                    <tr v-if="i === 0" v-bind:key="employee_id + '_' + i">
                      <td :rowspan="rows.length" class="text-nowrap"></td>
                      <td class="text-nowrap" v-if="row.project !== ''" :rowspan="headRows[employee_id][row.project]['length']"><strong>{{row.project}}</strong></td>
                      <td class="text-nowrap">{{row.date}}</td>
                      <td class="text-center">
                        {{roundValue(row.duration)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.duration)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.break)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.break)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.lateness)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.lateness)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.early)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.early)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.absence)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.absence)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.absence_worked)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.absence_worked)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.worked)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.worked)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.overtime)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.overtime)}})
                        </template>
                      </td>
                      <td class="text-center">{{roundValue(row.shifts_count)}}</td>
                    </tr>
                    <tr v-else v-bind:key="employee_id + '_' + i">
                      <td class="text-nowrap" v-if="row.project !== ''" :rowspan="headRows[employee_id][row.project]['length']"><strong>{{row.project}}</strong></td>
                      <td class="text-nowrap">{{row.date}}</td>
                      <td class="text-center">
                        {{roundValue(row.duration)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.duration)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.break)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.break)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.lateness)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.lateness)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.early)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.early)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.absence)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.absence)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.absence_worked)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.absence_worked)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.worked)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.worked)}})
                        </template>
                      </td>
                      <td class="text-center">
                        {{roundValue(row.overtime)}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(row.overtime)}})
                        </template>
                      </td>
                      <td class="text-center">{{roundValue(row.shifts_count)}}</td>
                    </tr>
                  </template>
                </template>
                <tr class="bg-raspisanka-light" v-for="(project, projectName) in headRows[employee_id]['projects']" v-bind:key="project.name">
                  <template v-if="!display.includes(employee_id)">
                    <td class="text-nowrap"><strong>{{ $t("Project") }} {{projectName}} {{ $t("total") }}:</strong></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['duration'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['duration'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['break'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['break'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['lateness'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['lateness'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['early'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['early'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['absence'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['absence'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['absence_worked'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['absence_worked'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['worked'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['worked'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center">
                      <strong>
                        {{roundValue(project['overtime'])}}
                        <template v-if="showHuman">
                          <br> ({{hrsForHuman(project['overtime'])}})
                        </template>
                      </strong>
                    </td>
                    <td class="text-center"><strong>{{roundValue(project['shifts_count'])}}</strong></td>
                  </template>
                </tr>
                <tr v-bind:key="employee_id + '_grand_total'" class="bg-raspisanka-dlight border border-primary" v-if="!display.includes(employee_id)">
                  <td class="text-nowrap"><strong>{{ $t("Grand total") }}:</strong></td>
                  <td></td>
                  <td></td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['duration'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['duration'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['break'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['break'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['lateness'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['lateness'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['early'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['early'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['absence'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['absence'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['absence_worked'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['absence_worked'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['worked'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['worked'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center">
                    <strong>
                      {{roundValue(headRows[employee_id]['overtime'])}}
                      <template v-if="showHuman">
                        <br> ({{hrsForHuman(headRows[employee_id]['overtime'])}})
                      </template>
                    </strong>
                  </td>
                  <td class="text-center"><strong>{{roundValue(headRows[employee_id]['shifts_count'])}}</strong></td>
                </tr>
              </template>
            </template>
          </template>
          </tbody>
        </table>
      </div>
      <pagination :rows="paginationConfig.rows" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import Pagination from '../../CommonComponents/Pagination/Pagination'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  mixins: [errorMixin, momentMixin, paginationMixin, mixinDummy],
  data () {
    return {
      columns: [
        { name: 'name', title: this.$i18n?.t('Name') },
        { name: 'project', title: this.$i18n?.t('Project') },
        { name: 'date', title: this.$i18n?.t('Shift start / end') },
        { name: 'duration', title: this.$i18n?.t('Shift duration hrs') },
        { name: 'break', title: this.$i18n?.t('Break duration hrs') },
        { name: 'lateness', title: this.$i18n?.t('Late, hrs') },
        { name: 'early', title: this.$i18n?.t('Early leaving, hrs') },
        { name: 'absence', title: this.$i18n?.t('Absence, hrs') },
        { name: 'absence_worked', title: this.$i18n?.t('Absence, work hrs') },
        { name: 'worked', title: this.$i18n?.t('Hours worked hrs') },
        { name: 'overtime', title: this.$i18n?.t('Overtime hours worked hrs') },
        { name: 'shifts_count', title: this.$i18n?.t('Number of shifts') }
      ],
      rows: [],
      headRows: {},
      loaded: false,
      display: [],
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      showHuman: true
    }
  },
  name: 'EmployeeWorkingHours',
  props: ['projects', 'period', 'search', 'selectedMarks', 'showUsers', 'byPlanned', 'marksByUsers', 'selectedPositions', 'positionsByUsers'],
  components: { pagination: Pagination, DummyPaymentRequired: DummyPaymentRequired },
  beforeMount () {
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },
  beforeUnmount () {
    this.$eventBus.off('exportEmployeeWorkingHoursReport')
    this.$eventBus.off('exportTimeSheetReport')
  },
  mounted () {
    this.$eventBus.on('exportEmployeeWorkingHoursReport', this.exportReport)
    this.$eventBus.on('exportTimeSheetReport', this.exportTimeSheet)
  },
  methods: {
    exportReport (exportType) {
      this.getTableData(1, exportType)
    },
    exportTimeSheet (exportType) {
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      let payload = '?projects[]=' + this.projects.join('&projects[]=')
      payload += '&date_from=' + dateFrom
      payload += '&date_to=' + dateTo
      payload += '&export_type=' + exportType
      payload += '&planned=' + (this.planned ? 1 : 0)
      payload += '&show_users=' + this.showUsers
      payload += '&positions[]=' + this.selectedPositions.join('&positions[]=')
      this.selectedMarks.forEach(element => {
        const markArr = element.split(':')
        payload += `&marks[${markArr[0]}][]=${markArr[1]}`
      })

      this.$store.dispatch('getTimeSheetReport', payload).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const dtFrom = moment(this.toTimeZone(this.period[0])).format(this.localeDateFormat)
        const dtTo = moment(this.toTimeZone(this.period[1])).format(this.localeDateFormat)
        link.setAttribute('download', `${this.$i18n?.t('export_time_sheet')}_${dtFrom}_${dtTo}.xlsx`)
        document.body.appendChild(link)
        link.click()
        this.toastSuccess(this.$i18n?.t('Export completed'))
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    roundValue (value) {
      return Math.round(value * 100) / 100
    },
    filteredData () {
      const selectedMarks = this.selectedMarks
      const marksByUsers = this.marksByUsers

      this.paginationConfig.rows = this.rows.filter((row, index) => {
        if (this.search === '' && this.selectedPositions.length === 0 && (selectedMarks.length === 0 && marksByUsers)) {
          return row
        }

        // поиск по имени
        const self = this
        let findSearch = 0
        Object.keys(row).forEach((key) => {
          if ((row[key][0].name.toLowerCase()).indexOf(self.search.toString().toLowerCase()) > -1) findSearch++
        })

        let search = findSearch > 0 && this.search !== ''
        if (this.search === '') {
          search = true
        }

        // поиск по позиции
        let findPositions = 0
        Object.keys(row).forEach((key) => {
          row[key].forEach(userData => {
            if (this.positionsByUsers[key] && this.selectedPositions.includes(this.positionsByUsers[key].positionId)) {
              findPositions++
            }
          })
        })

        let searchPositions = findPositions > 0
        if (this.selectedPositions.length === 0) {
          searchPositions = true
        }

        // поиск по пометкам
        let findMarks = 0
        Object.keys(row).forEach((key) => {
          row[key].forEach(userData => {
            selectedMarks.forEach(selectMark => {
              const splitData = selectMark.split(':')
              const scheduleId = parseInt(splitData[0])
              const markId = parseInt(splitData[1])
              if (marksByUsers[key] && scheduleId in marksByUsers[key] && marksByUsers[key][scheduleId].indexOf(markId) !== -1) {
                findMarks += 1
              }
            })
          })
        })

        let searchMarks = findMarks > 0
        if (selectedMarks.length === 0 && marksByUsers) {
          searchMarks = true
        }
        return searchPositions && search && searchMarks
      })
    },
    showHide (employeeId) {
      if (this.display.includes(employeeId)) {
        for (let i = 0; i < this.display.length; i++) {
          if (this.display[i] === employeeId) {
            this.display.splice(i, 1)
          }
        }
      } else {
        this.display.push(employeeId)
      }
    },
    getTableData (exportReport = 0, exportType = 'default') {
      this.loaded = false
      const dateFrom = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat)
      const dateTo = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      let data = '?projects[]=' + this.projects.join('&projects[]=') + '&date_from=' + dateFrom + '&date_to=' + dateTo + '&show_users=' + this.showUsers

      data += `&export=${exportReport}`
      data += `&export_type=${exportType}`
      data += `&planned=${this.byPlanned ? 1 : 0}`
      data += '&positions[]=' + this.selectedPositions.join('&positions[]=')
      this.selectedMarks.forEach(element => {
        const markArr = element.split(':')
        data += `&marks[${markArr[0]}][]=${markArr[1]}`
      })

      this.$store.dispatch('getEmployeesWorkingHours', [this.$store.getters.companyId, data, exportReport]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (exportReport) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_workink_hours')}_${moment(this.toTimeZone(dateFrom)).format(this.backendDateFormat)}_${moment(this.toTimeZone(dateTo)).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.toastError(this.$t(error.response.data.message))
          // this.$router.push('dashboard')
        }
      })
    },
    setTable (response) {
      this.rows = []
      this.headRows = {}
      if (response.length > 0) {
        const self = this
        const rows = {}
        const display = []
        response.forEach(function (item) {
          const employeeId = item.user_data.employee_id
          const name = item.user_data.full_name
          display.push(employeeId.toString())
          if (!(employeeId in rows)) {
            rows[employeeId] = []
          }

          rows[employeeId].push({
            name: name,
            project: item.table_data.project_name,
            date: self.toTimeZone(item.table_data.date_from).format(self.localeDateTimeFormat) + ' / ' + self.toTimeZone(item.table_data.date_to).format(self.localeDateTimeFormat),
            duration: item.table_data.duration / 60.0,
            break: item.table_data.break_time / 60.0,
            lateness: item.table_data.lateness / 60.0,
            early: item.table_data.early / 60.0,
            absence: item.table_data.absence / 60.0,
            absence_worked: item.table_data.absence_worked / 60.0,
            worked: item.table_data.worked / 60.0,
            overtime: item.table_data.overtime / 60.0,
            start: item.table_data.date_from,
            employee_id: employeeId,
            shifts_count: 1
          })
        })

        Object.keys(rows)
          .sort((a, b) => {
            if (rows[a][0].name.toLowerCase() < rows[b][0].name.toLowerCase()) { return -1 }
            if (rows[a][0].name.toLowerCase() > rows[b][0].name.toLowerCase()) { return 1 }
            return 0
          })
          .forEach(function (key) {
            rows[key].sort(function (a, b) {
              if (a.project < b.project) { return -1 }
              if (a.project > b.project) { return 1 }
              if (a.project === b.project) {
                return a.start > b.start ? 1 : -1
              }
              return 0
            })
            if (!(key in self.headRows)) {
              self.headRows[key] = {
                duration: 0,
                break: 0,
                lateness: 0,
                early: 0,
                absence: 0,
                absence_worked: 0,
                worked: 0,
                overtime: 0,
                shifts_count: 0,
                projects: {}
              }
            }

            rows[key].forEach(function (item) {
              if (!(item.project in self.headRows[key].projects)) {
                self.headRows[key].projects[item.project] = {
                  duration: 0,
                  break: 0,
                  lateness: 0,
                  early: 0,
                  absence: 0,
                  absence_worked: 0,
                  worked: 0,
                  overtime: 0,
                  length: 0,
                  shifts_count: 0
                }
                self.headRows[key][item.project] = {
                  duration: 0,
                  break: 0,
                  lateness: 0,
                  early: 0,
                  absence: 0,
                  absence_worked: 0,
                  worked: 0,
                  overtime: 0,
                  length: 0,
                  shifts_count: 0
                }
              }
              self.headRows[key].duration += Math.round(item.duration * 100) / 100
              self.headRows[key].break += Math.round(item.break * 100) / 100
              self.headRows[key].lateness += Math.round(item.lateness * 100) / 100
              self.headRows[key].early += Math.round(item.early * 100) / 100
              self.headRows[key].absence += Math.round(item.absence * 100) / 100
              self.headRows[key].absence_worked += Math.round(item.absence_worked * 100) / 100
              self.headRows[key].worked += Math.round(item.worked * 100) / 100
              self.headRows[key].overtime += Math.round(item.overtime * 100) / 100
              self.headRows[key].shifts_count += 1
              self.headRows[key].projects[item.project].duration += Math.round(item.duration * 100) / 100
              self.headRows[key].projects[item.project].break += Math.round(item.break * 100) / 100
              self.headRows[key].projects[item.project].lateness += Math.round(item.lateness * 100) / 100
              self.headRows[key].projects[item.project].early += Math.round(item.early * 100) / 100
              self.headRows[key].projects[item.project].absence += Math.round(item.absence * 100) / 100
              self.headRows[key].projects[item.project].absence_worked += Math.round(item.absence_worked * 100) / 100
              self.headRows[key].projects[item.project].worked += Math.round(item.worked * 100) / 100
              self.headRows[key].projects[item.project].overtime += Math.round(item.overtime * 100) / 100
              self.headRows[key].projects[item.project].length += 1
              self.headRows[key].projects[item.project].shifts_count += 1
              self.headRows[key][item.project].duration += Math.round(item.duration * 100) / 100
              self.headRows[key][item.project].break += Math.round(item.break * 100) / 100
              self.headRows[key][item.project].lateness += Math.round(item.lateness * 100) / 100
              self.headRows[key][item.project].early += Math.round(item.early * 100) / 100
              self.headRows[key][item.project].absence += Math.round(item.absence * 100) / 100
              self.headRows[key][item.project].absence_worked += Math.round(item.absence_worked * 100) / 100
              self.headRows[key][item.project].worked += Math.round(item.worked * 100) / 100
              self.headRows[key][item.project].overtime += Math.round(item.overtime * 100) / 100
              self.headRows[key][item.project].length += 1
              self.headRows[key][item.project].shifts_count += 1
            })
            let projectName = ''
            rows[key].forEach(function (item) {
              const tmp = item.project
              item.project = item.project === projectName ? '' : item.project
              projectName = tmp
            })

            const oneRow = {}
            oneRow[key] = rows[key]
            self.rows.push(oneRow)
            self.headRows[key].length = rows[key].length
          })
        this.display = display.filter(function (value, index, self) {
          return self.indexOf(value) === index
        })
        this.filteredData()
        this.loaded = true
      } else {
        this.loaded = true
      }
    }
  },
  watch: {
    projects: function () {
      this.getTableData()
    },
    period: function () {
      this.getTableData()
    },
    search: function () {
      this.filteredData()
    },
    showUsers: function () {
      this.getTableData()
    },
    byPlanned () {
      this.getTableData()
    },
    selectedMarks: function () {
      this.filteredData()
    },
    selectedPositions () {
      this.filteredData()
    }
  }
}
</script>

<style>
  .pointer:hover {
    cursor: pointer;
  }
</style>
