<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-labelledby=""
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="modalClass ? modalClass : 'modal-dialog'" role="document">
      <div class="modal-content">
        <div v-if="!hideHeader" class="modal-header">
          <slot name="header"></slot>
          <button
            type="button"
            class="close"
            :id="closeModalId ? closeModalId : 'modalCloseButton'"
            data-dismiss="modal"
            data-cy="dismiss-modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div :class="modalBodyClass ? modalBodyClass : 'modal-body'">
          <slot name="body"></slot>
        </div>
        <div v-if="!hideFooter" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
  props: {
    id: String,
    modalClass: { type: String, default: 'modal-dialog' },
    modalBodyClass: { type: String, default: 'modal-body' },
    closeModalId: String,
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    // handle modal missclick
    const modal = document.getElementById(this.id)
    document.addEventListener('click', (event) => {
      const isClickInside = modal.contains(event.target)
      if (isClickInside && ![...modal.classList].includes('show')) {
        this.closeModal()
      }
    })
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped></style>
