<template>
  <div class="col-6 col-lg-3 d-flex p-1">
    <div
      class="card d-flex w-100"
      :class="{'border-primary': isEnabled, 'border-success': !isEnabled}"
    >
      <div
        class="card-header text-center"
      >
        <h5>{{ application.name }}</h5>
      </div>
      <div class="card-img-top d-flex justify-content-center" style="height: 120px; overflow: hidden">
        <div class="my-auto">
          <img :src="application.icon ? application.icon : placeholderUrl" style="max-width: 100%; max-height: 120px;" class="m-0 m-auto d-block h-auto pl-2 pr-2"/>
        </div>
      </div>
      <div class="card-body d-flex flex-column">
        <div class="mb-auto" style="height: 140px; overflow: scroll" v-html="application.description" />
      </div>
      <router-link
        :to="{ name: 'DeveloperAppView', params: { appId: application.id }}"
        :class="{'bg-success': !isEnabled, 'bg-primary': isEnabled}"
        class="card-footer pt-2 pb-2 text-center">
        <span class="font-weight-bold text-white">{{ isEnabled ? $t('MANAGE') : $t('CONNECT_APP') }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ApplicationItem',
  data () {
    return {
      placeholderUrl: '../../../static/images/App/Splash_1_01.png'
    }
  },
  props: {
    application: Object,
    isEnabled: Boolean
  },
  created () {
  }
}
</script>

<style scoped>

</style>
