import { HTTP } from '@/api/http_utils'

const state = {}

const promises = {}

const getters = {}

const mutations = {}

const actions = {
  createForecasting ({ commit }, [scheduleId, payload]) {
    const promiseKey = `/schedules/${scheduleId}/forecasting`

    if (promises[promiseKey]) {
      console.error('repeating create forecasting')
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
