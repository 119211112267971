<template>
  <div class="row">
    <div v-if="isAttendanceModuleOn" class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('LATE_BREAKS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="lateBreaks.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in lateBreaks" :key="item.id  + '__late'" class="mb-1">
                <div>
                  {{ item.employee.full_name }} {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{item.title}}
                </div>
                <div class="small">
                  {{toTime(item.shift.time_from)}} - {{toTime(item.shift.time_to)}} {{item.schedule.name}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_LATE_BREAKS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('ON_BREAK')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="nowBreaks.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in nowBreaks" :key="item.id  + '__now'" class="mb-1">
                <div>
                  {{ item.employee.full_name }} {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{item.title}}
                </div>
                <div class="small">
                  {{toTime(item.shift.time_from)}} - {{toTime(item.shift.time_to)}} {{item.schedule.name}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                 {{$t('NO_ON_BREAK')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col-auto mt-2">{{$t('FUTURE_BREAKS')}}</div>
            <div class="col">
              <div class="w-50">
                <ui-select
                  name="breaksReload"
                  v-model="beforeBreak"
                  :options="timeOptions"
                  :filterable="false"
                  :key-name="'val'"
                  :label-name="'label'"
                  :value-name="'val'"
                />
              </div>
            </div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="filteredFutureBreaks.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in filteredFutureBreaks" :key="item.id + '__future'" class="mb-1">
                <div>
                  {{ item.employee.full_name }} {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{item.title}}
                </div>
                <div class="small">
                  {{toTime(item.shift.time_from)}} - {{toTime(item.shift.time_to)}} {{item.schedule.name}}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_FUTURE_BREAKS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'BreaksStatistic',
  mixins: [momentMixin],
  data () {
    return {
      breaks: [],
      lateBreaks: [],
      nowBreaks: [],
      futureBreaks: [],
      beforeBreak: 60,
      timeOptions: [
        {
          val: 5,
          label: '5 ' + this.$t('min')
        },
        {
          val: 15,
          label: '15 ' + this.$t('min')
        },
        {
          val: 30,
          label: '30 ' + this.$t('min')
        },
        {
          val: 45,
          label: '45 ' + this.$t('min')
        },
        {
          val: 60,
          label: '60 ' + this.$t('min')
        }
      ]
    }
  },
  props: {
    isAttendanceModuleOn: Boolean
  },
  created () {
    // console.log(this.isAttendanceModuleOn)
    this.init()
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    filteredFutureBreaks () {
      const time = moment().tz(this.$store.getters.profileTimeZone).add(this.beforeBreak, 'minutes')
      // let x = this.futureBreaks.filter(item => this.fromZoneToZone(item.time_from, 'UTC', this.$store.getters.profileTimeZone).isBefore(time))
      // x = x.concat(x)
      // x = x.concat(x)
      // x.concat(x)
      // return x
      return this.futureBreaks.filter(item => this.fromZoneToZone(item.time_from, 'UTC', this.$store.getters.profileTimeZone).isBefore(time))
    }
  },
  methods: {
    init () {
      const currentStart = moment().tz(this.$store.getters.profileTimeZone).add(-60, 'minutes').format(this.backendDateTimeFormat)
      const currentEnd = moment().tz(this.$store.getters.profileTimeZone).add(60, 'minutes').format(this.backendDateTimeFormat)
      // console.log(currentStart, currentEnd)
      const dateStart = this.fromZoneToZone(currentStart, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const dateEnd = this.fromZoneToZone(currentEnd, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      // console.log(dateStart, dateEnd)
      const queryBreaks = '?time_from=' + dateStart + '&time_to=' + dateEnd
      this.$store.dispatch('getBreaksByCompany', [this.companyId, queryBreaks]).then(response => {
        this.breaks = response
        // console.log(response)
        if (this.isAttendanceModuleOn) {
          this.lateBreaks = response.filter(breakItem => {
            // if (!breakItem.attendance) breakItem.attendance = []
            return breakItem.shift && breakItem.employee_id > 0 &&
            breakItem.attendances.length === 0 &&
            this.fromZoneToZone(breakItem.time_from, 'UTC', this.$store.getters.profileTimeZone).isBefore(moment())
          })
          this.lateBreaks = this.lateBreaks.sort((a, b) => {
            return moment(a.time_from).isAfter(moment(b.time_from)) ? 1 : -1
          })
          this.nowBreaks = response.filter(breakItem => {
            // if (!breakItem.attendance) breakItem.attendance = []
            return breakItem.shift && breakItem.employee_id > 0 &&
            breakItem.attendances.length === 1
          })
          this.nowBreaks = this.nowBreaks.sort((a, b) => {
            return moment(a.time_from).isAfter(moment(b.time_from)) ? 1 : -1
          })
          this.futureBreaks = response.filter(breakItem => {
            // console.log(34, breakItem)
            // if (!breakItem.attendance) breakItem.attendance = []
            return breakItem.shift && breakItem.employee_id > 0 &&
            breakItem.attendances.length === 0 &&
            this.fromZoneToZone(breakItem.time_from, 'UTC', this.$store.getters.profileTimeZone).isSameOrAfter(moment())
          })
          this.futureBreaks = this.futureBreaks.sort((a, b) => {
            return moment(a.time_from).isAfter(moment(b.time_from)) ? 1 : -1
          })
        } else {
          this.nowBreaks = response.filter(breakItem => breakItem.shift && breakItem.employee_id > 0 &&
            moment().isBetween(this.fromZoneToZone(breakItem.time_from, 'UTC', this.$store.getters.profileTimeZone), this.fromZoneToZone(breakItem.time_to, 'UTC', this.$store.getters.profileTimeZone)))
          this.nowBreaks = this.nowBreaks.sort((a, b) => {
            return moment(a.time_from).isAfter(moment(b.time_from)) ? 1 : -1
          })
          this.futureBreaks = response.filter(breakItem => breakItem.shift && breakItem.employee_id > 0 && moment().isBefore(this.fromZoneToZone(breakItem.time_from, 'UTC', this.$store.getters.profileTimeZone)))
          this.futureBreaks = this.futureBreaks.sort((a, b) => {
            return moment(a.time_from).isAfter(moment(b.time_from)) ? 1 : -1
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toTime (date) {
      return this.fromZoneToZone(date, 'UTC', this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    }
  }
}
</script>

<style scoped>

</style>
