<template>
  <el-tooltip class="item" effect="light" :content="tooltip" placement="top">
    <i class="icon-plus-circle text-primary font-weight-bold"
       @click="click"
       data-toggle="modal"
       data-target="#AddShiftFreeBoard"
       style="font-size: 1.3em; cursor: pointer"></i>
  </el-tooltip>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'PlusShiftIcon',
  mixins: [momentMixin],
  props: {
    tooltip: String,
    isFreeBoard: Boolean,
    userId: Number,
    userMarks: Object
  },
  methods: {
    click () {
      this.$eventBus.emit('addShiftShow', {
        addShiftUserId: this.userId,
        userMarks: this.userMarks
      })
    }
  }
}
</script>

<style scoped>

</style>
