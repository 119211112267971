<template>
  <div>
    <div class="row mb-2 mx-auto">
      <ol class="breadcrumb bg-transparent p-0 m-0">
        <li class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/app-marketplace#developers`">
            <span class="breadcrumb-item">{{ $t('Integrations')}}</span>
          </router-link>
        </li>
        <li class="breadcrumb-item title-text active" aria-current="page">
          <span class="title-text">{{ $t('App info') }}</span>
        </li>
      </ol>
    </div>
    <div class="card card-small" v-if="app">
      <div class="card-header flex-row">
        <div class="d-flex justify-content-between">
          <div>
            <h4>{{ app.name }}</h4>
          </div>
          <div>
            <span v-if="companyApp && companyApp.enabled">
              <el-button
                type="danger"
                @click="disableApp"
                >
                {{ $t('Disable') }}
              </el-button>
            </span>
            <span v-else>
              <template v-if="+appId === 13">
                <img
                  src="@/assets/images/C2QB.png"
                  alt="connect_to_quickbooks"
                  @click="installDeveloperApp"
                  style="cursor: pointer; max-width: 220px">
              </template>
              <template v-else>
                <el-button
                  type="success"
                  @click="installDeveloperApp"
                  >
                  {{ $t('Enable') }}
                </el-button>
              </template>
            </span>
            <template v-if="activeTab === 'fields' && (companyApp && companyApp.enabled) && app.fields.length">
              <el-button
                class="ml-2"
                type="success"
                @click="saveFields"
                >
                {{ $t('SAVE_WEB') }}
              </el-button>
            </template>
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs" id="pills-tab" role="tablist">
        <li v-for="(title, key) in tabsData" :key="key" class="nav-item">
          <a class="nav-link"
            :class="{
              active: activeTab === key,
              disabled: key === 'fields' && (!(companyApp && companyApp.enabled) || (!companyApp.fields_result || !companyApp.fields_result.length))
            }"
            :href="'#' +  key"
            :id="'pills-' + key + '-tab'"
            data-toggle="pill"
            @click="activateTab(key)"
            role="tab" aria-controls="pills-home" aria-selected="true">{{ title }}</a>
        </li>
      </ul>
      <div class="card-body" id="accordionExample">
        <main-info
          v-if="activeTab === 'mainInfo' && loaded"
          :app="app">
        </main-info>
        <permissions
          v-if="activeTab === 'permissions' && loaded"
          :app="app"
          :companyApp="companyApp"
          >
        </permissions>
        <div class="col-6" v-if="activeTab === 'fields' && loaded">
          <fields
            :items="companyApp ? companyApp.fields_result : []"
            :disabled="false"
            @handleSaveFields="handleSaveFields"
            >
          </fields>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import errorMixin from '../../mixins/mixinApiErrors'
import MainInfo from '@/components/AppMarketplace/components/MainInfo'
import Permissions from '@/components/AppMarketplace/components/Permissions'
import Fields from '@/components/AppMarketplace/components/Fields'

export default {
  name: 'DeveloperAppView',
  components: {
    MainInfo,
    Permissions,
    Fields
  },
  mixins: [errorMixin],
  data () {
    return {
      tabsData: {
        mainInfo: this.$i18n?.t('Main information'),
        permissions: this.$i18n?.t('Permissions'),
        fields: this.$i18n?.t('Fields')
      },
      appId: +this.$route.params.appId,
      enableApp: this.$route.query.enable ? !!+this.$route.query.enable : false,
      companyApp: null,
      app: null,
      loaded: false,
      companyApps: []
    }
  },
  created () {
    this.getApp()
  },
  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'mainInfo'
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    canInstallAfterRedirect () {
      return this.app && this.enableApp
    }
  },
  methods: {
    getApp () {
      this.loaded = false
      this.$store.dispatch('getDevApp', this.appId).then(response => {
        this.app = response
        this.loaded = true
        this.getCompanyApps()
      })
    },
    getCompanyApps () {
      this.$store.dispatch('getCompanyApps').then(response => {
        this.companyApps = response
        this.companyApp = response.find(item => item.developer_app_id === this.app.id)
      })
    },
    installDeveloperApp () {
      const payload = {}

      if (this.app.redirect_url) {
        payload.redirect_type = this.app.activate_after_redirect ? 'instantaneous' : 'upon_completion'
      }

      if (this.enableApp && this.app.activate_after_redirect) {
        payload.redirect_type = 'forbidden'
      }

      this.$store.dispatch('installDeveloperApp', [this.companyId, this.app.id, payload]).then(response => {
        if (response.redirect_hash) {
          window.location.replace(this.app.redirect_url + `?redirect=1&company_id=${this.companyId}&hash=${response.redirect_hash}`)
        }

        if (this.$route.query?.enable) {
          // const query = Object.assign({}, this.$route.query)
          // delete query.enable

          this.$router.replace({ query: {} })
        }

        this.companyApp = response
        if (this.companyApp.fields_result && this.companyApp.fields_result.length) {
          this.activateTab('fields')
        }
      })
    },
    saveFields () {
      this.$eventBus.emit('validateDeveloperAppFields')
    },
    handleSaveFields () {
      this.$store.dispatch('updateCompanyAppFields', [this.companyApp.id, { fields: this.companyApp.fields_result }]).then(response => {
        this.companyApp = response
        this.toastSuccess(this.$i18n?.t('app_fields_saved'))
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    },
    disableApp () {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, disabled it!'),
        cancelButtonText: this.$i18n?.t('Cancel'),
        confirmButtonClass: 'deleteButton',
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteCompanyApp', this.companyApp.id).then(response => {
          if (this.activeTab === 'fields') {
            this.activateTab('mainInfo')
          }
          this.getApp()
          this.toastSuccess(this.$i18n?.t('App has been disabled.'))
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {})
    }
  },
  watch: {
    canInstallAfterRedirect (value) {
      if (value) {
        this.installDeveloperApp()
      }
    }
  }
}
</script>

<style scoped>
.summary {
  cursor: pointer !important;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}
</style>
