<template>
  <div>
    <div id="timeOffAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">{{ $t('TIME_OFF_SETTINGS_HEADER') }} <ui-hint v-if="moduleInfo" :content="moduleInfo"/></h5>
        </div>
        <div id="collapseTimeOffSettings" class="collapse show" aria-labelledby="headingOne" data-parent="#timeOffAccordion">
          <div class="card-body">
            <modal-component
              :id="'TimeOffSettingsModal'"
              @closeModal="closeModal"
              :modalClass="'modal-dummy'"
              :modalBodyClass="'dummy-popup'"
              :hideFooter="true"
              :hideHeader="true">
              <template #body>
                <div>
                  <dummy-payment-required
                    @activate="activate"
                    :dummyInfo="dummyInfo"
                    :isModal="true"
                  >
                  </dummy-payment-required>
                </div>
              </template>
            </modal-component>
            <Form ref="observer" as="div" class="form-row">
              <div
                class="col-auto"
                @click="handleActivation"
                :data-toggle="!isModuleActive ? 'modal' : null"
                :data-target="!isModuleActive ? '#TimeOffSettingsModal' : null"
              >
                <div class="form-row">
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.show_timeoff_to_coworkers"
                      v-model="settings.show_timeoff_to_coworkers"
                      :disabled="!isModuleActive"
                      :label="$t('TIME_OFF_SETTINGS_SHOW_TIMEOFF_TO_COWORKERS')"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.use_reasons"
                      v-model="settings.use_reasons"
                      :disabled="!isModuleActive"
                      :label="$t('TIME_OFF_SETTINGS_ENABLE_REASONS')"
                      :hint="$t('TIME_OFF_SETTINGS_ENABLE_REASONS_HINT')"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings.accounting_time_off_as_working_hours"
                      v-model="settings.accounting_time_off_as_working_hours"
                      :disabled="!isModuleActive"
                      :label="$t('ACCOUNTING_TIME_OFF_AS_WORKING_HOURS')"
                      :hint="$t('ACCOUNTING_TIME_OFF_AS_WORKING_HOURS_HINT')"
                    />
                  </div>
                  <div class="col-lg-12 mb-1 ">{{ $t('SHIFT_OVERLAP_WITH_TIME_OFF') }} <ui-hint :content="$t('SHIFT_OVERLAP_WITH_TIME_OFF_HINT')" /></div>
                  <div class="col-lg-6">
                    <ui-number-input
                      name="shift_overlap_with_time_off"
                      v-model="settings.shift_overlap_with_time_off"
                      :validation="{required: false, integer: true, min_value: 0}"
                      :disabled="!isModuleActive"
                      :precision="0"
                      :step="1"
                      :min="0"
                    />
                  </div>
                  <div class="col-lg-12 mb-1 font-weight-bold">{{ $t('TIME_OFF_SETTINGS_REASONS') }}</div>
                  <div class="col-lg-12" :disabled="!isModuleActive || !settings.use_reasons">
                    <div
                      v-for="(item, item_key) in items"
                      :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}"
                      class="p-1 rounded"
                      :key="item_key"
                    >
                      <TimeOffItem
                        :item="item"
                        :index="item_key"
                        :showItemConfig="true"
                        :disabledActions="!isModuleActive || !settings.use_reasons"
                        @deleteItem="deleteItem(item_key)"
                        @validateResult="validateResult"
                      />
                    </div>
                  </div>
                  <div class="pt-2">
                    <button
                      class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                      aria-label="Close"
                      :disabled="!isModuleActive || !settings.use_reasons"
                      @click="addItem">
                      {{ $t('Add item') }}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import TimeOffItem from '@/components/Company/ModulesPage/TimeOffItem.vue'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsTimeOff',
  components: { ModalComponent, DummyPaymentRequired, TimeOffItem },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      companyModules: [],
      module: null,
      settings: {
        use_reasons: false,
        show_timeoff_to_coworkers: false,
        accounting_time_off_as_working_hours: false,
        shift_overlap_with_time_off: 0
      },
      items: [],
      positionsChoices: [],
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'timeoff')
    },
    addItem () {
      this.items.push({
        id: null,
        title: null,
        is_other_data: false,
        type_time_off: 'vacation'
      })
    },
    deleteItem (itemKey) {
      this.$confirm(this.$t('This will permanently delete the reason status. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('OK'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        const item = this.items[itemKey]
        if (item.id) {
          this.$store.dispatch('deleteCompanyUserRequestReason', item.id).then(() => {
            this.items.splice(itemKey, 1)
          })
        } else {
          this.items.splice(itemKey, 1)
        }
        this.toastSuccess(this.$i18n?.t('Delete completed'))
      }).catch(() => {
        this.toastInfo(this.$i18n?.t('Delete canceled'))
      })
    },
    validateResult (value) {
      // this.counter++
      if (!value) this.validateErrors++
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 5) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            const settings = {
              use_reasons: this.module.settings.use_reasons ? this.module.settings.use_reasons : false,
              show_timeoff_to_coworkers: this.module.settings.show_timeoff_to_coworkers ? this.module.settings.show_timeoff_to_coworkers : false,
              accounting_time_off_as_working_hours: this.module.settings.accounting_time_off_as_working_hours ? this.module.settings.accounting_time_off_as_working_hours : false,
              shift_overlap_with_time_off: this.module.settings.shift_overlap_with_time_off ? this.module.settings.shift_overlap_with_time_off : 0
            }
            this.settings = settings
          }
        }
      })
      // модуль timeOff активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 18]).then(response => {
        if (response.active === true) {
          this.isModuleActive = true
          this.$store.dispatch('getCompanyUserRequestReasons').then(requestsReasons => {
            this.items = requestsReasons
          })
        }
      }).catch(err => {
        if (err.response.data.module) {
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    async submitForm () {
      try {
        if (this.isModuleActive) {
          const result = await this.$refs.observer.validate()

          if (result.valid) {
            await this.$store.dispatch('updateModuleSettings', [
              this.$store.getters.companyId,
              this.module.id,
              {
                settings: this.settings
              }
            ])

            await this.$store.dispatch('clearCompanyStore')
            await this.$store.dispatch('getCompany', this.$store.getters.companyId)

            this.$emit('success')
          }
        }
      } catch (error) {
        console.log('error: ', error)
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>

</style>
