<template>
  <div>
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t('Edit profile') }}</span>
    </div>

    <div class="row pb-3">
      <div class="col-lg-2">
        <div class="card card-small mb-4">
          <div class="card-body">
            <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical">
              <a v-for="(title, key) in tabsData" :key="key"
                :href="'#' +  key"
                class="nav-link pl-3 pr-1 rounded"
                :class="{active: activeTab === key}"
                data-toggle="pill" role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="activateTab(key)">
                {{ $t(title) }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10">
        <div class="card card-small mb-4">
          <div class="card-body">
            <div class="errors p-2" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
            <div v-if="loaded" class="tab-content">
              <main-profile-settings
                v-show="activeTab === 'general'"
                id="general"
                :userId="userId"
                :Profile="profile"
                :company="company"
                :disabled="disabled">
              </main-profile-settings>

              <notification-settings
                v-show="activeTab === 'notification'"
                id="notification"
                :Profile="profile">
              </notification-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'
import MainProfileSettings from './ProfileComponents/MainProfileSettings'
import NotificationSettings from '../../CommonComponents/NotificationSettings'
import { HTTP } from '@/api/http_utils'

export default {
  components: { MainProfileSettings, NotificationSettings },
  name: 'Profile',
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      languageChoices: [],
      // currencyChoices: [],
      disabled: false,
      activeTab: 'general',
      showTabs: true,
      loaded: false,
      buttonTitle: 'Please Select',
      tabsData: {
        general: 'General information',
        // 'skills': 'Professional skills',
        notification: 'Notifications'
      },
      profile: {}
    }
  },
  created () {
    if (this.$store.getters.companyId) {
      this.$store.dispatch('getCompany', this.$store.getters.companyId)
    }
    this.$store.dispatch('getCountries')
    this.$store.dispatch('getTimeZones')
    this.$store.dispatch('getProfile').then(profile => {
      this.profile = Object.assign({}, this.Profile)
      // console.log(this.Profile)
      if (!profile.languages) {
        this.Profile.languages = []
      }
      this.setData()
    })
  },
  mounted: function () {
    if (this.tabsData[this.$route.hash.substr(1)]) {
      this.activateTab(this.$route.hash.substr(1))
    }
  },
  computed: {
    ...mapGetters([
      'Profile',
      'company'
    ]),
    userId () {
      return this.$store.getters.profileId
    }
  },
  watch: {
    '$route' () {
      if (this.tabsData[this.$route.hash.substring(1)]) {
        this.activateTab(this.$route.hash.substring(1))
      }
    }
  },
  methods: {
    setData () {
      this.$store.dispatch('getLanguages').then((resolve) => {
        this.languageChoices = resolve
        this.loaded = true
      })
    },
    sendTokenToServer (token) {
      return HTTP.post('profile/firebase_tokens', { token, useragent: navigator.userAgent })
    },
    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
