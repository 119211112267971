<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('NEW_TODOS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="newTodos.length > 0">
            <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
              <div v-for="item in newTodos" :key="item.id  + '__new'" class="mb-2">
                <div class="font-weight-bold">
                  {{item.employee.full_name}}
                </div>
                <div>
                  {{item.content}}
                </div>
                <div class="small font-weight-bold">
                  {{ this.scheduledTimeToShow(item.deadline_date) }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_NEW_TODOS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('LATE_TODOS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="lateTodos.length > 0">
            <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
              <div v-for="item in lateTodos" :key="item.id  + '__active'" class="mb-2">
                <div class="font-weight-bold">
                  {{item.employee.full_name}}
                </div>
                <div>
                  {{item.content}}
                </div>
                <div class="small font-weight-bold">
                  {{ this.scheduledTimeToShow(item.deadline_date) }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_LATE_TODOS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('COMPLETED_TODOS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="completedTodos.length > 0">
            <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
              <div v-for="item in completedTodos" :key="item.id  + '__completed'" class="mb-2">
                <div class="font-weight-bold">
                  {{item.employee.full_name}}
                </div>
                <div>
                  {{item.content}}
                </div>
                <div class="small font-weight-bold">
                  {{ this.scheduledTimeToShow(item.deadline_date) }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_COMPLETED_TODOS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ToDoStatistic',
  mixins: [momentMixin],
  data () {
    return {
      newTodos: [],
      lateTodos: [],
      completedTodos: []
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      profileTimeZone: 'profileTimeZone',
      companyId: 'companyId'
    })
  },
  watch: {
    companyId () {
      if (this.companyId) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      const toDate = this.fromZoneToZone(moment().add(1, 'day').endOf('day'), this.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const fromDate = this.fromZoneToZone(moment().add(-1, 'day').startOf('day'), this.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const query = `date_to=${toDate}&date_from=${fromDate}`
      this.$store.dispatch('getTodosDashboard', [this.companyId, query]).then(response => {
        // console.log(123, response)
        this.newTodos = response.new
        this.lateTodos = response.late
        this.completedTodos = response.completed
      })
    },
    scheduledTimeToShow (time) {
      if (!time) {
        return this.$t('NO_DEADLINE_DATE')
      }
      return this.$t('DEADLINE_DATE') + ': ' + this.fromZoneToZone(time, 'UTC', this.profileTimeZone).format(this.localeDateTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
