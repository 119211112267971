<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo"
      :compactMode="true">
    </dummy-payment-required>
    <template v-if="!dummy && geolocations && geolocations.length > 0">
      <GmvMap
        mapId="mapKey"
        mapKey="mapKey"
        id="map-location-control"
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="roadmap"
        style="width:100%;  height: 65vh;"
        :options="mapOptions"
      >
        <template v-if="geolocations && geolocations.length > 0">
          <GmvMarker
            mapId="mapKey"
            ref="myMarker"
            :markerKey="m.id"
            :key="index"
            v-for="(m, index) in markers"
            :clickable="true"
            :position="m.position"
            :icon="m.icon"
            @click="toggleInfoWindow(m)"
            @mouseover="toggleInfoWindow(m)"
            @mouseout="closeInfoWindow()"
          />
        </template>
        <GmvInfoWindow
          mapId="mapKey"
          mapKey="mapKey"
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          :marker-key="markerKey"
          @closeclick="infoWinOpen=false"
        >
          <div>
            <div class="mb-1"><span class="font-weight-bold">{{$t('Time')}}</span>: {{ infoContent.time }}</div>
          </div>
        </GmvInfoWindow>
      </GmvMap>
    </template>
    <template v-if="!dummy && (!geolocations || geolocations.length === 0)">
      <div class="text-center font-weight-bold p-5 bg-white">
        {{$t('NO_GEOLOCATION_WARNING')}}
      </div>
    </template>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import { utilities } from '@gmap-vue/v3'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'EmployeeGeolocationComponent',
  components: { DummyPaymentRequired },
  mixins: [momentMixin],
  data () {
    return {
      markers: [],
      path: [],
      center: { lat: 0, lng: 0 },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -40
        }
      },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      infoContent: {
        time: ''
      },
      markerKey: null,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      map: null,
      zoom: 18,
      line: null,
      google: null
    }
  },
  created () {
    this.initGoogle()
    this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 26]).then(response => {
      // console.log(3, response)
      this.dummy = !response.active
    }).catch((error) => {
      if (error.response.data.module) {
        this.dummy = true
        this.dummyInfo = {
          title: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].name : error.response.data.module.name,
          trial: error.response.data.module.trial_period,
          price: +error.response.data.module.price,
          perUser: +error.response.data.module.user_price,
          isActive: error.response.data.module.is_active,
          id: +error.response.data.module.id,
          helpCenter: error.response.data.module.help_center,
          description: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].short_description : error.response.data.module.short_description
        }
      }
    })
    if (this.geolocations) {
      this.setPositions()
    }
  },
  mounted () {
    this.handleRef()
  },
  props: {
    geolocations: Array,
    color: String
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      profile: 'Profile'
    }),
    tz () {
      return this.$store.getters.actualTZ
    }
  },
  watch: {
    google () {
      if (this.google) {
        this.init()
      }
    },
    geolocations () {
      if (this.geolocations) {
        this.setPositions()
        this.updateMarkers(this.zoom)
        this.updatePolyline()
      }
    }
  },
  methods: {
    init () {
      this.updateMarkers(this.zoom)
      this.updatePolyline()
      this.map.addListener('zoom_changed', () => {
        this.updateMarkers(this.map.zoom)
      })
      setTimeout(() => {
        this.fitToMap(this.line)
      }, 500)
    },
    initGoogle () {
      this.google = getGoogleMapsAPI()
      if (!this.google) {
        setTimeout(() => {
          if (!this.google) {
            this.initGoogle()
          }
        }, 1000)
      }
    },
    handleRef () {
      if (this.geolocations.length > 0) {
        if (!this.map) {
          if (this.$refs.mapRef) {
            this.$refs.mapRef.mapPromise?.then((map) => {
              this.map = map
              if (this.google) {
                this.init()
              }
            })
          } else {
            setTimeout(() => {
              this.handleRef()
            }, 1000)
          }
        }
      }
    },
    toggleInfoWindow (marker) {
      this.infoWindowPos = marker.position
      this.markerKey = marker.id
      this.infoContent = marker
      this.infoWinOpen = true
    },
    closeInfoWindow () {
      this.infoWinOpen = false
    },
    getTableDataActivated () {
      this.dummy = false
    },
    setPositions () {
      if (this.geolocations && this.geolocations.length > 0) {
        const path = []
        this.geolocations.forEach(location => {
          path.push({
            lat: +location.latitude,
            lng: +location.longitude
          })
        })
        this.path = path
        const centerIndex = Math.ceil(this.geolocations.length / 2) - 1
        this.center = {
          lat: +this.geolocations[centerIndex].latitude,
          lng: +this.geolocations[centerIndex].longitude
        }
      }
    },
    updateMarkers (zoom) {
      const marks = []
      let step = 1
      if (zoom < 17) {
        step = Math.pow(17 - zoom, 2) * 10
      }
      const svgMarker = {
        path: 'M24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z',
        fillColor: '#ff2800',
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.4,
        anchor: new this.google.maps.Point(9, 15)
      }
      const svgStartMarker = {
        path: 'M5 4c0 0.719-0.391 1.359-1 1.719v19.781c0 0.266-0.234 0.5-0.5 0.5h-1c-0.266 0-0.5-0.234-0.5-0.5v-19.781c-0.609-0.359-1-1-1-1.719 0-1.109 0.891-2 2-2s2 0.891 2 2zM28 5v11.922c0 0.578-0.359 0.797-0.812 1.031-1.766 0.953-3.719 1.813-5.766 1.813-2.875 0-4.25-2.188-7.656-2.188-2.484 0-5.094 1.125-7.25 2.281-0.172 0.094-0.328 0.141-0.516 0.141-0.547 0-1-0.453-1-1v-11.594c0-0.375 0.187-0.641 0.484-0.859 0.375-0.25 0.828-0.469 1.234-0.672 1.969-1 4.359-1.875 6.578-1.875 2.453 0 4.375 0.812 6.547 1.828 0.438 0.219 0.891 0.297 1.375 0.297 2.453 0 5.094-2.125 5.781-2.125 0.547 0 1 0.453 1 1z',
        fillColor: '#ff2800',
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.8,
        anchor: new this.google.maps.Point(3, 25)
      }
      const svgFinishMarker = {
        path: 'M13 15.625v-3c-1.906 0.172-4.203 0.938-6 1.828v2.891c1.813-0.844 4.062-1.563 6-1.719zM13 9.094v-3.078c-1.969 0.094-4.281 1.016-6 1.969v2.953c1.844-0.953 4.062-1.766 6-1.844zM26 16.328v-2.875c-1.422 0.703-3.906 1.75-6 1.109v-3.5c-0.203-0.063-0.406-0.141-0.609-0.234-1.797-0.906-3.266-1.75-5.625-1.75-0.25 0-0.5 0.016-0.766 0.047v3.469h0.297c2.359 0 4.297 0.844 6.094 1.734 0.203 0.094 0.406 0.172 0.609 0.234v2.938c0.422 0.172 0.891 0.266 1.422 0.266 1.734 0 3.609-0.922 4.578-1.437zM26 9.656v-2.953c-1.25 0.672-3 1.422-4.781 1.422v0c-0.422 0-0.828-0.031-1.219-0.125v3.063c2.094 0.594 4.578-0.609 6-1.406zM5 4c0 0.734-0.406 1.375-1 1.719v19.781c0 0.281-0.219 0.5-0.5 0.5h-1c-0.281 0-0.5-0.219-0.5-0.5v-19.781c-0.594-0.344-1-0.984-1-1.719 0-1.109 0.891-2 2-2s2 0.891 2 2zM28 5v11.922c0 0.375-0.219 0.719-0.547 0.891-0.063 0.031-0.156 0.078-0.266 0.141-1 0.531-3.359 1.813-5.766 1.813-0.922 0-1.75-0.187-2.469-0.547l-0.438-0.219c-1.578-0.797-2.828-1.422-4.75-1.422-2.25 0-5.422 1.172-7.25 2.281-0.156 0.094-0.344 0.141-0.516 0.141s-0.344-0.047-0.5-0.125c-0.313-0.187-0.5-0.516-0.5-0.875v-11.594c0-0.344 0.187-0.672 0.484-0.859 1-0.594 4.531-2.547 7.812-2.547 2.609 0 4.734 0.953 6.531 1.828 0.406 0.203 0.875 0.297 1.391 0.297 1.844 0 3.875-1.172 4.844-1.75 0.203-0.109 0.375-0.203 0.484-0.266 0.313-0.156 0.672-0.141 0.969 0.031 0.297 0.187 0.484 0.516 0.484 0.859z',
        fillColor: '#ff2800',
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.8,
        anchor: new this.google.maps.Point(3, 25)
      }
      // console.log('step', step)
      for (let i = 0; i < this.geolocations.length - 1; i = i + step) {
        marks.push({
          id: i + '__marker',
          position: {
            lat: +this.geolocations[i].latitude,
            lng: +this.geolocations[i].longitude
          },
          icon: i === 0 ? svgStartMarker : svgMarker,
          time: this.fromZoneToZone(this.geolocations[i].recorded_time, 'UTC', this.tz).format(this.profile.date_format + ' ' + this.profile.time_format)
        })
      }
      // чобы всегда была последняя точка
      marks.push({
        id: 'finish__marker',
        position: {
          lat: +this.geolocations[this.geolocations.length - 1].latitude,
          lng: +this.geolocations[this.geolocations.length - 1].longitude
        },
        icon: svgFinishMarker,
        time: this.fromZoneToZone(this.geolocations[this.geolocations.length - 1].recorded_time, 'UTC', this.tz).format(this.profile.date_format + ' ' + this.profile.time_format)
      })
      this.markers = marks
    },
    updatePolyline () {
      this.line = null
      const lineSymbol = {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
      }
      let line = null
      line = new this.google.maps.Polyline({
        path: this.path,
        geodesic: true,
        strokeColor: '#ff2800',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [{
          icon: lineSymbol,
          repeat: '100px'
        }],
        map: this.map
      })
      this.line = line
    },
    fitToMap (polyline) {
      const bounds = this.map.getBounds()
      const points = polyline.getPath().getArray()
      for (let n = 0; n < points.length; n++) {
        bounds.extend(points[n])
      }
      this.map.fitBounds(bounds)
    }
  }
}
</script>

<style scoped>

</style>
