<template>
  <ui-hint v-if="schedules" class="schedule-info-icon" :content="html()" placement="top" :skip-margin="true" />
</template>

<script>
export default {
  name: 'ResourceInfoIcon',
  props: {
    schedules: Array
  },
  methods: {
    html () {
      let html = ''
      if (this.schedules) {
        this.schedules.forEach(schedule => {
          html += '<div color="' + schedule.color + '">' + schedule.title + '</div>'
        })
      }
      return html
    }
  }
}
</script>

<style lang="scss" scoped>
 .schedule-info-icon {
  margin-left: 0 !important;
 }
</style>
