<template>
  <div v-if="location" class="mt-2">
    <div class="font-weight-bold">
      {{$t('Location')}}: {{location.title}}
    </div>
    <div class="mt-2">
      {{$t('Address')}}: {{location.address}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShiftLocation',
  props: {
    location: Object
  }
}
</script>

<style scoped>

</style>
