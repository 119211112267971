<template>
  <Form ref="observe" as="div">
    <div class="row card-body">
      <div class="col-6 my-auto">
        <ui-checkbox
          class="mt-auto"
          name="rate"
          :label=" $t('IS_ACTIVE_HOOK')"
          v-model="hookState.is_active"
        />
      </div>
      <div class="col-6 text-right">
        <router-link id="log_web" v-if="hookState.id && isEmployeeHasPermission('get webhook')" class="nav-link"
                     :to="`/c/${companyId}/webhook-log/${hookState.id}`">
          <span class="menu-color">{{ $t('WEBHOOK_LOG')}}</span>
        </router-link>
      </div>
      <div class="col-12 my-1">
        <ui-text-input
          class="w-50"
          :label="$t('CALLBACK_URL')"
          :key="'callback_url'"
          :name="'callback_url'"
          v-model="hookState.callback_url"
          :placeholder="$t('callback_url')"
          :validation="{url: {require_protocol: true }, required: true}"
        />
      </div>
      <div class="col-12 my-1">
        <ui-text-input
          class="w-50"
          :label="$t('SECRET')"
          :key="'secret'"
          :name="'secret'"
          v-model="hookState.secret"
          :placeholder="$t('secret')"
          :validation="{required: true, min: 10, max: 20}"
        />
      </div>
      <div class="text-primary text-uppercase font-weight-bold col-12 my-2">
        {{ $t('SELECT_EVENTS_TO_CALLBACK') }}
        <button @click="selectAll" type="button" class="btn btn-link btn-sm float-right p-0 mx-1">{{$t('Select all')}}</button>
        <button @click="unselectAll" type="button" class="btn btn-link btn-sm float-right p-0 mx-1">{{$t('Unselect all')}}</button>
      </div>
      <div v-for="item in hookState.events" :key="item.event_model" class="col-3 my-1">
        <label>{{ $t(item.event_model) }}</label>
        <div v-for="(val, type) in item.event_type" :key="item.event_model + type" class="my-1">
          <ui-checkbox
            :name="item.event_model + type"
            :label="translateAction(type)"
            v-model="item.event_type[type]"
          />
        </div>
      </div>
      <div class="col-12 text-right">
        <button
          v-if="hookState.id && isEmployeeHasPermission('delete webhook')"
          class="btn btn-sm btn-danger rounded-pill mx-1"
          role="button"
          @click="deleteHook(hookState.id)">
          {{ $t('Delete') }}
        </button>
        <button
          v-if="(hookState.id && isEmployeeHasPermission('update webhook')) || (!hookState.id && isEmployeeHasPermission('create webhook'))"
          class="btn btn-sm btn-primary rounded-pill mx-1"
          role="button"
          @click="save">
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'WebhookFormComponent',
  mixins: [ToastMixin],
  data () {
    return {
      hookState: {}
    }
  },
  props: {
    hook: Object,
    appId: {
      type: Number,
      default: null
    }
  },
  created () {
    this.hookState = this.hook
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    unselectAll () {
      Object.keys(this.hookState.events).forEach(event => {
        Object.keys(this.hookState.events[event].event_type).forEach(key => {
          this.hookState.events[event].event_type[key] = false
        })
      })
    },
    selectAll () {
      Object.keys(this.hookState.events).forEach(event => {
        Object.keys(this.hookState.events[event].event_type).forEach(key => {
          this.hookState.events[event].event_type[key] = true
        })
      })
    },
    translateAction (description) {
      if (description === 'created') {
        return this.$t('LOG_ACTION_CREATED')
      }
      if (description === 'updated') {
        return this.$t('LOG_ACTION_UPDATED')
      }
      if (description === 'deleted') {
        return this.$t('LOG_ACTION_DELETED')
      }
      if (description === 'restored') {
        return this.$t('LOG_ACTION_RESTORED')
      }
      return ''
    },
    save () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          const action = this.hookState.id ? 'updateWebhook' : 'setWebhook'

          if (this.appId) {
            this.hookState.developer_app_id = this.appId
          }

          this.$store.dispatch(action, [this.companyId, this.hookState])
            .then(() => {
              this.toastSuccess(this.hookState.id ? this.$i18n?.t('WEBHOOK_UPDATED') : this.$i18n?.t('WEBHOOK_ADDED'))
              if (this.appId) {
                this.$eventBus.emit('updateDeveloperWebhooks')
              }
            })
            .catch(err => {
              this.toastError(this.$i18n?.t(err.response.data.message))
            })
        }
      })
      // console.log(this.hook)
    },
    deleteHook (id) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('deleteWebhook', [this.companyId, id]).then(() => {
          if (this.appId) {
            this.$eventBus.emit('updateDeveloperWebhooks')
          }
          this.toastSuccess(this.$i18n?.t('WEBHOOK_DELETED'))
        }).catch(err => {
          this.toastError(this.$i18n?.t(err.response.data.message))
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
