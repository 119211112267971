<template>
  <Form ref="observer" class="row" @submit="removeUsersFromSchedule">
    <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
      {{ $t("Remove users from schedule") }}
      <ui-hint :content="$t('REMOVE_USERS_FROM_SCHEDULE_HINT')"/>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <ui-multi-select
        name="removeUsers"
        :label="$t('Remove users')"
        v-model="removedUsers.removeUsers"
        :filterable="true"
        :options="removeUsersChoices"
        :validation="{required: true}"
        :key-name="'key'"
        :label-name="'name'"
        :value-name="'id'"
      />
      <ui-date-picker
        :label="$t('Remove users starting from the date')"
        name="start_datetime"
        v-model="removedUsers.removeDate"
        :validation="{required: true}"
        :clearable="false"
        data-vv-validate-on="change"
        :disabled-date="disabledDate"
      />
      <div class="mt-2">{{ $t('Select a date from which you want to remove users. All of their shifts will be placed on the Free Board.')}}</div>
      <div>
        <ui-select
          name="type"
          :label="$t('Removal options')"
          :hint="$t('REMOVAL_OPTIONS_HINT')"
          v-model="removedUsers.removeType"
          :clearable="false"
          :filterable="true"
          class="w-100"
          :options="removeTypeChoices"
          :key-name="'value'"
          :label-name="'label'"
          :value-name="'value'"
        />
        <span class="mt-2 text-center">{{ $t('You can either delete shifts of removed workers or place them on the Free Board.') }}</span>
      </div>
    </div>
    <div class="col-12 py-2">
      <button
        class="btn btn-success rounded-pill shadow-sm"
        :disabled="disabled"
      >
        {{ $t('SAVE_WEB') }}
      </button>
    </div>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'RemoveUsers',
  mixins: [
    ToastMixin,
    errorMixin,
    momentMixin
  ],

  data () {
    return {
      removedUsers: {
        removeUsers: [],
        removeDate: '',
        removeType: 'move_free_board'
      },
      removeUsersChoices: [],
      removeTypeChoices: [
        {
          value: 'move_free_board',
          label: this.$i18n?.t('Place on Free Board')
        },
        {
          value: 'remove',
          label: this.$i18n?.t('Delete from schedule')
        }
      ],
      disabled: false
    }
  },

  props: {
    schedule: Object,
    projectUsers: Array,
    preloadUsers: Object
  },

  created () {
    // console.log(123, this.schedule)
    this.createRemoveUserChoices()
  },

  watch: {
    scheduleUsersCount () {
      this.createRemoveUserChoices()
    },
    projectUsers () {
      this.createRemoveUserChoices()
    }
  },

  computed: {
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    scheduleUsersCount () {
      return this.schedule.employees.users.length
    }
  },

  methods: {
    disabledDate (date) {
      const from = this.toSelectedTimeZone(this.schedule.mainInfo.period[0], this.schedule.mainInfo.projectTZ).format(this.backendDateFormat)
      if (this.schedule) {
        return moment.utc(date).isBefore(from)
      }
      return false
    },
    removeUsersFromSchedule (values, { resetForm }) {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const formData = {
            users: this.removedUsers.removeUsers,
            shifts_action: this.removedUsers.removeType,
            date_from: moment.utc(this.removedUsers.removeDate).format(this.backendDateTimeFormat)
          }
          this.disabled = true
          this.$store.dispatch('deleteUsers', [this.$route.params.scheduleId, formData]).then(() => {
            this.removeUsersChoices = this.removeUsersChoices.filter(user => !this.removedUsers.removeUsers.includes(user.id))
            this.disabled = false
            this.clear()
            resetForm()
            // this.removedUsers.removeUsers = []
            this.$emit('getSchedule')
            this.toastSuccess(this.$i18n?.t('Schedule was successfully changed'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },

    clear () {
      this.removedUsers.removeUsers = []
      this.removedUsers.removeDate = ''
      this.removedUsers.removeType = 'move_free_board'
      // console.log('1', this.errors)
      // this.errors.items = []
      // console.log('2', this.errors)
    },

    createRemoveUserChoices () {
      this.removeUsersChoices = []
      this.projectUsers.forEach((id) => {
        if (this.schedule.employees.users.includes(id) && this.preloadUsers[id]) {
          this.removeUsersChoices.push({
            id: id,
            name: this.preloadUsers[id].full_name
          })
        }
      })
      this.removeUsersChoices.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })
    },

    returnUsers () {
      this.$eventBus.emit('updateRemovedUsers', this.removedUsers)
    }
  }
}
</script>

<style scoped>

</style>
