export default {
  data () {
    return {
      apiNonFieldErrors: []
    }
  },
  computed: {
    apiErrors () {
      return this.$store.getters.apiErrors
    }
  },
  watch: {
    apiErrors: function () {
      // this.errors.clear()
      try {
        if (Object.keys(this.apiErrors).length > 0) {
          this.apiErrors.errors && Object.keys(this.apiErrors.errors).forEach(key => {
            this.apiErrors.errors[key].forEach(errorMsg => {
              this.errors.add({
                field: key,
                msg: errorMsg
              })
            })
          })
        }
      } catch (e) {
        // console.log('apiErrors', e)
      }
    }
  }
}
