<template>
  <modal-component :id="'ClickDistributeActivity'" :modalClass="'modal-md'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div">
          <div class="row">
            <div class="col-6">
              <ui-date-picker
                :label="$t('From')"
                name="date_from"
                v-model="payload.date_from"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
            <div class="col-6">
              <ui-date-picker
                :label="$t('To')"
                name="date_to"
                v-model="payload.date_to"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 p-1">
              <div class="text-center">
                <button
                  class="btn btn-success btn-sm rounded-pill shadow-sm mx-1"
                  @click="distribute">
                  {{ $t('DISTRIBUTE') }}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent.vue'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'ShiftActivityDistributeModal',
  mixins: [momentMixin],
  components: { ModalComponent },
  data () {
    return {
      payload: {
        date_from: moment().startOf('isoWeek').toDate(),
        date_to: moment().endOf('isoWeek').toDate()
      },
      moment: moment
    }
  },
  props: { title: String, initStart: Object, initEnd: Object },
  created () {
    this.payload.date_from = this.initStart.toDate()
    this.payload.date_to = this.initEnd.toDate()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeShiftActivityModal')
    },
    distribute () {
      const payload = { ...this.payload }
      payload.date_from = this.fromZoneToZone(payload.date_from, this.tz, 'UTC').format(this.backendDateTimeFormat)
      payload.date_to = this.fromZoneToZone(payload.date_to, this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat)
      this.$store.dispatch('distributeShiftActivity', [this.companyId, payload]).then(_ => {
        this.$emit('updateActivity')
        this.toastSuccess(this.$i18n?.t('ACTIVITY_DISTRIBUTE_SUCCESS'))
        document.getElementById('modalCloseButton').click()
      }).catch(error => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    }
  }
}
</script>
<style scoped>

</style>
