import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_EMPLOYEES_LIST = 'UPDATE_EMPLOYEES_LIST'
const UPDATE_PROJECT_EMPLOYEES_LIST = 'UPDATE_PROJECT_EMPLOYEES_LIST'
const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE'
const UPDATE_PROJECT_EMPLOYEE = 'UPDATE_PROJECT_EMPLOYEE'
const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'

const state = {
  employeesIndex: {},
  projectEmployeesIndex: {}
}

const getters = {
  employees: state => Object.values(state.employeesIndex).sort((a, b) => {
    if (a.full_name > b.full_name) {
      return 1
    }
    if (a.full_name < b.full_name) {
      return -1
    }
    return 0
  }),
  employeesIndexed: state => state.employeesIndex,
  projectEmployees: state => Object.values(state.projectEmployeesIndex),
  projectEmployeesIndexed: state => state.projectEmployeesIndex,
  employeesCount: state => {
    return Object.values(state.employeesIndex).length
  },
  hasEmployee: (state, getters) => {
    return getters.employeesCount > 0
  },
  employeeById: (state) => (id) => {
    return state.employeesIndex[id]
  }
}

const mutations = {
  [UPDATE_EMPLOYEES_LIST] (state, payload) {
    // Object.values(state.employeesIndex).forEach((item) => {
    //   Vue.delete(state.employeesIndex, item.id)
    // })
    // Object.values(payload).forEach(item => {
    //   Vue.set(state.employeesIndex, item.id, item)
    // })

    const employees = {}
    if (Array.isArray(payload)) {
      payload.forEach(item => {
        if (item.identifier) {
          item.full_name = item.full_name + ' (' + item.identifier + ')'
        }
        employees[item.id] = item
      })
    } else {
      Object.values(payload).forEach(item => {
        if (item.identifier) {
          item.full_name = item.full_name + ' (' + item.identifier + ')'
        }
        employees[item.id] = item
      })
    }

    state.employeesIndex = employees
  },
  [UPDATE_PROJECT_EMPLOYEES_LIST] (state, payload) {
    Object.values(state.projectEmployeesIndex).forEach((item) => {
      delete state.projectEmployeesIndex[item.id]
    })

    const employees = {}

    if (Array.isArray(payload)) {
      payload.forEach(item => {
        employees[item.id] = item
      })
    } else {
      Object.values(payload).forEach(item => {
        employees[item.id] = item
      })
    }

    state.projectEmployeesIndex = employees
  },
  [UPDATE_EMPLOYEE] (state, employee) {
    if (employee.identifier) {
      employee.full_name = employee.full_name + ' (' + employee.identifier + ')'
    }
    state.employeesIndex[employee.id] = employee
  },
  [UPDATE_PROJECT_EMPLOYEE] (state, employee) {
    state.projectEmployeesIndex[employee.id] = employee
  },
  [DELETE_EMPLOYEE] (state, employeeId) {
    delete state.employeesIndex[employeeId]
    delete state.projectEmployeesIndex[employeeId]
  }
}

const actions = {
  // CRUD
  getEmployeesByCompany ({ commit }, companyId) {
    const promiseKey = '/companies/' + companyId + '/employees'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      // return Object.values(state.employeesIndex)
      return state.employeesIndex
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/employees').then(employees => {
        commit(UPDATE_EMPLOYEES_LIST, employees)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(employees)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  clearEmployeesCache () {
    promises = {}
    lastSuccessful = {}
  },

  getEmployeesByCompanyNoCached ({ commit }, companyId) {
    const uri = '/companies/' + companyId + '/employees'

    return new Promise(resolve => {
      HTTP.get(uri).then(employees => {
        commit(UPDATE_EMPLOYEES_LIST, employees)
        resolve(employees)
      })
    })
  },

  getEmployeesByProject ({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/projects/${projectId}/employees`).then(employees => {
        commit(UPDATE_PROJECT_EMPLOYEES_LIST, employees)
        resolve(employees)
      }).catch(err => {
        reject(err)
      })
    })
  },

  importEmployees ({ commit }, data) {
    return new Promise((resolve, reject) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: data }
      HTTP.post('/companies/' + data.get('company_id') + '/employees/import', data, config).then(response => {
        if (data.get('preview') === 'false') {
          response.employees.forEach(employee => {
            commit(UPDATE_EMPLOYEE, employee)
            commit(UPDATE_PROJECT_EMPLOYEE, employee)
          })
          response.restored.forEach(employee => {
            commit(UPDATE_EMPLOYEE, employee)
            commit(UPDATE_PROJECT_EMPLOYEE, employee)
          })
        }
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  addEmployees ({ commit, dispatch }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/employees', data)
        .then(response => {
          Object.entries(response).forEach(([key, value]) => {
            if (key !== 'errors') {
              commit(UPDATE_EMPLOYEE, value)
              if (data[0].project_id) { // костыль, чтобы понять, был новый юзер добавлен в проект или нет, может рабоать непраивильно
                commit(UPDATE_PROJECT_EMPLOYEE, value)
              }
            }
          })
          dispatch('clearProjectsCache')
          dispatch('clearSchedulesCache')
          // promises = {}
          // lastSuccessful = {}
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  patchEmployee ({ commit }, [companyId, employeeId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + companyId + '/employees/' + employeeId, data)
        .then(response => {
          commit(UPDATE_EMPLOYEE, response)
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  patchProjectEmployee ({ commit }, [projectId, employeeId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/projects/${projectId}/employees/${employeeId}`, data)
        .then(response => {
          commit(UPDATE_EMPLOYEE, response)
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  changeUserPriorityAndPermission ({ commit }, [companyId, employeeId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + companyId + '/employees/' + employeeId, data)
        .then(response => {
          commit(UPDATE_EMPLOYEE, response)
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  delUser ({ commit }, [companyId, userId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/companies/' + companyId + '/employees/' + userId)
        .then(response => {
          commit(DELETE_EMPLOYEE, userId)
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  batchDeleteUsers ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/employees/batch_remove', payload)
        .then(response => {
          payload.employees_id.forEach(userId => {
            commit(DELETE_EMPLOYEE, userId)
          })
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  dismissUser ({ commit }, [companyId, userId, dateFrom]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/employees/' + userId + '/dismiss', { date_from: dateFrom })
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  removeDismissUser ({ commit }, [companyId, userId]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + companyId + '/employees/' + userId + '/dismiss')
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  restoreEmployee ({ commit }, [companyId, userId]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + companyId + '/employees/' + userId + '/restore')
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
