<template>
  <modal-component :id="'OnboardWizard'" @closeModal="closeModal" :closeModalId="'modalOnboardWizardCloseButton'">
    <template #header>
      <h5 class="modal-title" id="OnboardWizardTitle">{{ $t('Onboard Wizard')}}</h5>
    </template>
    <template #body>
      <div>
        <el-steps class="wizard-steps" :active="active" finish-status="success" align-center>
          <el-step :title="$t('MODULES_TITLE')" class="mb-4"></el-step>
          <el-step :title="$t('POSITIONS_TITLE')" class="mb-4"></el-step>
          <el-step :title="$t('Employees')" class="mb-4"></el-step>
          <el-step :title="$t('Shifts templates')" class="mb-4"></el-step>
          <el-step :title="$t('Schedule template')" class="mb-4"></el-step>
        </el-steps>
        <Form ref="observe">
          <div class="main-wrapper">
            <h4 v-if="active === 0" class="text-center">{{ $t('WIZARD_WELCOME_MESSAGE')}}</h4>
            <!-- Step 1 -->
            <modules-step
              v-show="active === 0"
              class="wizard-content-wrapper"
              :refreshSelectedModules="refreshSelectedModules"
            >
            </modules-step>
            <!-- Step 2 -->
            <positions-step
              v-show="active === 1"
              class="wizard-content-wrapper"
              :selectedPositions="selectedPositions"
              :company="company"
              @refreshSelectedPositions="refreshSelectedPositions"
            >
            </positions-step>
            <!-- Step 3 -->
            <employees-step
              v-show="active === 2"
              class="wizard-content-wrapper"
              :selectedPositions="selectedPositions"
              :employees="employees"
              @refreshEmployees="refreshEmployees"
            >
            </employees-step>
            <!-- Step 4 -->
            <templates-step
              v-show="active === 3"
              class="wizard-content-wrapper"
              :shiftTemplates="shiftTemplates"
              :active="active"
              @refreshShiftTemplates="refreshShiftTemplates"
            >
            </templates-step>
            <!-- Step 5 -->
            <schedule-template-step
              v-show="active === 4"
              class="wizard-content-wrapper"
              @refreshScheduleTemplate="refreshScheduleTemplate"
            >
            </schedule-template-step>

            <div class="wizard-nav-buttons">
              <button class="btn btn-secondary" v-show="active > 0" @click="prevStep">{{ $t('Prev step') }}</button>
              <button class="btn btn-primary" v-show="active !== totalSteps" @click="nextStep">{{ $t('Next step') }}</button>

              <button
                class="btn btn-success"
                v-show="active === totalSteps"
                @click="save"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                {{ $t('SAVE_WEB') }}
              </button>

              <!-- save -->
              <save-step
                v-show="false"
                :company="company"
                :selectedPositions="selectedPositions"
                :employees="employees"
                :shiftTemplates="shiftTemplates"
                :scheduleTemplate="scheduleTemplate"
                :closeModal="closeModal"
                :createShifts="createShifts"
                :selectedModules="selectedModules"
                @disableLoader="disableLoader"
              >
              </save-step>
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div class="wizard-footer-wrapper">
        <div>
          <button class="btn btn-light" data-dismiss="modal">{{ $t('Skip guided setup') }}</button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import ModulesStep from './ModulesStep.vue'
import PositionsStep from './PositionsStep.vue'
import EmployeesStep from './EmployeesStep.vue'
import TemplatesStep from './TemplatesStep.vue'
import ScheduleTemplateStep from './ScheduleTemplateStep.vue'
import SaveStep from './SaveStep.vue'

export default {
  name: 'OnboardWizard',
  components: {
    ModalComponent,
    ModulesStep,
    PositionsStep,
    EmployeesStep,
    TemplatesStep,
    ScheduleTemplateStep,
    SaveStep
  },
  data () {
    return {
      selectedModules: [1, 8],
      defaultModules: [1, 8],
      fullscreenLoading: false,
      createShifts: null,
      active: 0,
      totalSteps: 4,
      selectedPositions: [],
      employees: [
        {
          first_name: this.$store.getters.Profile.first_name,
          last_name: this.$store.getters.Profile.last_name,
          email: this.$store.getters.Profile.email,
          phone: '',
          position: '',
          employee_id: this.$store.getters.company.employee_id,
          project_id: null,
          identifier: null,
          invite: false,
          email_check: false
        }
      ],
      shiftTemplates: [],
      isEmailExist: false,
      scheduleTemplate: '2/2'
    }
  },
  computed: {
    company () {
      return this.$store.getters.company
    },
    projectId () {
      return this.$store.getters.currentProjectId
    },
    scheduleId () {
      return this.$store.getters.schedulesByProject[0]
    }
  },
  methods: {
    disableLoader () {
      this.fullscreenLoading = false
    },
    save (e) {
      e.preventDefault()
      this.fullscreenLoading = true

      if (this.scheduleTemplate === 'Empty') {
        this.createShifts = false
      } else {
        this.createShifts = true
      }
    },
    refreshSelectedPositions (newSelectedPositions) {
      this.selectedPositions = newSelectedPositions
    },
    refreshEmployees (newEmployees) {
      this.employees = newEmployees
    },
    refreshShiftTemplates (newShiftTemplates) {
      this.shiftTemplates = newShiftTemplates
    },
    refreshScheduleTemplate (newScheduleTemplate) {
      this.scheduleTemplate = newScheduleTemplate
    },
    refreshSelectedModules (newSelectedModules) {
      this.selectedModules = [...this.defaultModules, ...newSelectedModules]
    },
    closeModal () {
      document.getElementById('modalOnboardWizardCloseButton').click()
      this.$emit('OnboardWizard')
    },
    async nextStep (e) {
      e.preventDefault()
      if (this.active === 1) {
        this.isEmailExist = false

        const checkEmails = []
        this.employees.forEach((employee, index) => {
          if (employee.email && index !== 0) {
            checkEmails.push(this.$store.dispatch('checkEmail', { email: employee.email }).then(response => {
              employee.email_check = response.is_exist
              if (response.is_exist) {
                this.isEmailExist = true
              }
            }))
          }
        })

        await Promise.all(checkEmails)
        if (!this.isEmailExist) {
          this.active++
        }
      } else if (this.active === 2) {
        this.$refs.observe.validate().then(result => {
          result && this.active++
        })
      } else {
        this.active++
      }
    },
    prevStep (e) {
      e.preventDefault()
      this.active--
    }
  }
}
</script>

<style scoped lang="scss">
  #OnboardWizard {
    padding: 0 !important;
  }

  #OnboardWizard :deep(.modal-dialog) {
    max-width: none;
    margin: 0;
    width: 100%;
    height: 100%;
    &.modal-dialog .modal-content {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  #OnboardWizard :deep(.modal-header) {
    height: 60px;
  }

  #OnboardWizard:deep(.modal-body) {
    height: 100%;
    overflow-y: auto;
    padding-top: 0;
  }

  #OnboardWizard:deep(.modal-footer) {
    height: 80px;
  }

  .wizard-steps {
    position: sticky;
    top: 0;
    z-index: 2;
    padding-top: 30px;
    margin-bottom: 30px;
    background-color: white;
  }

  #OnboardWizard:deep(.el-input__inner) {
    padding-left: 30px !important;
    border-color: #becad6;
    &::placeholder {
      color: lightgrey;
    }
    &:hover {
      border-color: #8fa4b8;
    }
  }

  #OnboardWizard:deep(.form-control) {
    font-weight: 400;
    &::placeholder {
      color: lightgrey;
    }
  }

  #OnboardWizard:deep(.el-checkbox-button__inner) {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    box-shadow: none;
  }

  .wizard-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .wizard-footer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .wizard-nav-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
    width: 100%;
  }

  .main-wrapper {
    max-width: 850px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
</style>
