<template>
  <div
    :class="containerClass ? containerClass : {'justify-content-center': centered, 'd-flex': true, 'align-items-center': true}">
    <Field :name="name"
           v-model="innerValue"
           type="checkbox"
           :value="true"
           :unchecked-value="false"
           v-slot="{ value, errorMessage, handleChange }"
    >
      <el-form-item :error="errorMessage" :class="{'w-100': centered !== true }" class="d-flex align-items-end">
        <el-checkbox
          :model-value="value"
          @update:model-value="handleChange"
          @change="(val) => $emit('change', val)"
          :data-cy="dataCy ? dataCy : null"
          :disabled="!!disabled"
          :checked="!!checked"
          :value="customValue"
          :border="border"
          :class="checkboxClass ? checkboxClass : {
        'el-checkbox-big': size === 'big',
      }"
        >
          <template v-if="label">
            <div class="d-flex align-items-end">
              {{ label }}
              <ui-hint v-if="hint" :content="hint"/>
            </div>
          </template>
          <template v-else>
            <slot></slot>
          </template>
        </el-checkbox>
      </el-form-item>
    </Field>
  </div>
</template>

<script>
export default {
  name: 'checkbox',
  emits: ['update:modelValue', 'change'],

  props: {
    name: String,
    modelValue: [Boolean, Array],
    placeholder: String,
    disabled: Boolean,
    checked: Boolean,
    label: String,
    hint: String,
    size: { type: String, default: 'default' },
    centered: { type: Boolean, default: false },
    dataCy: String,
    customValue: [String, Number, Array],
    border: { type: Boolean, default: false },
    indeterminate: [Boolean],
    checkboxClass: String,
    containerClass: String
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-checkbox-big {
  :deep(&) .el-checkbox__inner {
    width: 28px;
    height: 28px;

    &::after {
      border: 3px solid #FFF;
      border-left: 0;
      border-top: 0;
      height: 12px;
      left: 10px;
      top: 4px;
    }
  }
}
label.el-checkbox {
  align-items: flex-end !important;
}
.el-form-item {
  display: flex;
  --font-size: 14px;
  margin-bottom: 0!important;
}
</style>
