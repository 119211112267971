<template>
  <ui-input-label
    v-if="label"
    :label="label"
    :hint="hint"
    :required="validation && !!validation.required"
  />
  <el-input-number
    :id="id"
    v-model="innerValue"
    :precision="precision"
    :step="step"
    :placeholder="placeholder"
    :disabled="!!disabled"
    :validate-event="false"
    :min="min"
    :size="size"
    :controls="controls"
    :controls-position="controlsPosition"
    @change="(val) => $emit('change', val)"
    :data-cy="dataCy ? dataCy : null"
  />
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ errorMessage }"
    :rules="validation"
    as="div"
    :label="fieldLabel"
  >
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1" />
  </Field>
</template>

<script>
export default {
  name: 'number-input',
  emits: ['update:modelValue', 'change'],

  props: {
    name: String,
    validation: Object,
    value: [Number, String],
    modelValue: [Number, String],
    placeholder: String,
    disabled: Boolean,
    label: String,
    hint: String,
    controls: {
      type: Boolean, default: true
    },
    controlsPosition: {
      type: String, default: ''
    },
    precision: {
      type: Number, default: 2
    },
    step: {
      type: Number, default: 0.1
    },
    min: {
      type: Number, default: -Infinity
    },
    size: {
      type: String, default: 'default'
    },
    fieldLabel: {
      type: String, default: null
    },
    dataCy: String,
    id: {
      type: String, default: null
    }
  },

  computed: {
    innerValue: {
      get () {
        return +this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }
}
</script>
