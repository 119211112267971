<template>
  <Form ref="observer" as="tr">
    <td v-if="!isForm" align="left" style="min-width: 100px!important;">
      {{formatDate(period[0]).format(localeDateFormat)}}
    </td>
    <td v-if="!isForm" align="left" style="min-width: 100px!important;">
      {{formatDate(period[1]).format(localeDateFormat)}}
    </td>
    <td v-if="isForm" colspan="2">
      <ui-date-picker
        :pickerType="'daterange'"
        :name="'range'"
        v-model="period"
        :validation="{required: true}"
        :clearable="false"
      />
    </td>
    <td align="left">
      <template v-if="!isForm">
        {{showToTitle(data.show_to)}}
      </template>
      <template v-if="isForm">
        <ui-select
          name="show_to"
          v-model="data.show_to"
          :clearable="false"
          :options="showToChoices"
          :key-name="'value'"
          :label-name="'label'"
          :value-name="'value'"
        />
      </template>
    </td>
    <td align="left">
      <template v-if="!isForm">
        {{data.content}}
      </template>
      <template v-if="isForm">
        <ui-textarea-input
          name="content"
          v-model="data.content"
          :validation="{required: true, max: 300}"
          :rows="2"
          :placeholder="$t('Please input')"
        />
      </template>
    </td>
    <td align="center">
      <template v-if="!isForm">
        <i class="icon-pencil text-primary btn-icon mx-1"
           @click="editNote()"
           data-toggle="modal"
           data-target="#editNote"/>
        <i class="icon-trash text-danger btn-icon mx-1" @click="deleteNote(data.id)" />
      </template>
      <template v-if="isForm">
        <i class="icon-check text-success btn-icon mx-1"
           @click="update()"
        />
        <i class="icon-x text-primary btn-icon mx-1" @click="close()" />
      </template>
    </td>
  </Form>
</template>
<script>
import moment from 'moment/moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'NoteTableRow',
  mixins: [momentMixin],
  data () {
    return {
      data: {},
      period: [moment().startOf('month'), moment().endOf('month')],
      isForm: false,
      showToChoices: [
        {
          value: 'manager',
          label: this.$t('SHOW_MANAGER_SELECT')
        },
        {
          value: 'employee',
          label: this.$t('SHOW_EMPLOYEE_SELECT')
        },
        {
          value: 'all',
          label: this.$t('SHOW_ALL_SELECT')
        }
      ]
    }
  },
  props: {
    note: Object
  },
  mounted () {
    this.init()
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    init () {
      this.data = { ...this.note }
      this.period = [moment(this.note.start, this.localeDateTimeFormat), moment(this.note.end, this.localeDateTimeFormat).add(-1, 'day')]
    },
    close () {
      this.isForm = false
      this.init()
    },
    update () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('updateNote', [this.companyId, this.note.id, {
            employeeId: this.note.employee_id,
            start: this.fromZoneToZone(this.period[0], this.tz, 'UTC').format(this.backendDateTimeFormat),
            end: this.fromZoneToZone(this.period[1], this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
            content: this.data.content,
            show_to: this.data.show_to
          }]).then(response => {
            this.isForm = false
            const payload = { ...response }
            payload.start = this.fromZoneToZone(response.start, 'UTC', this.tz).format(this.localeDateTimeFormat)
            payload.end = this.fromZoneToZone(response.end, 'UTC', this.tz).format(this.localeDateTimeFormat)
            this.$emit('edited', payload)
          })
        }
      })
    },
    showToTitle (showTo) {
      if (showTo === 'all') {
        return this.$t('SHOW_TO_ALL_NOTE')
      }
      if (showTo === 'employee') {
        return this.$t('SHOW_TO_EMPLOYEE_NOTE')
      }
      return this.$t('SHOW_TO_MANAGER_NOTE')
    },
    formatDate (date) {
      return moment(date, this.localeDateTimeFormat)
    },
    editNote () {
      this.isForm = true
    },
    deleteNote (id) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteNote', [this.companyId, id]).then(() => {
          this.$emit('deleted', id)
        })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped>

</style>
