<template>
  <div style="min-height: 10px">
    <div v-if="$store.getters.weekStatisticData[this.resourceId] === 0 ||
     $store.getters.weekStatisticData[this.resourceId] > 0"
         :style="{ 'color': hrsTotalCalc > weekHrsCompanySetting &&
       (viewType === 'resourceTimelineWeek') &&
        weekHrsCompanySetting > 0 ? '#ff0000' : ''
    }">
      <template v-if="viewType === 'resourceTimelineWeek'">
        <span class="small mx-1">{{companyWeekHrsLabel}}:</span>
        <span class="small">{{hrsForHuman(+weekHrsCompanySetting + Number.EPSILON)}}</span>
        /
      </template>
        <span class="small">{{userWeekHrsLabel}}:</span>
        <span class="small mx-1">{{hrsForHuman(+hrsTotalCalc + Number.EPSILON)}}</span>
    </div>
  </div>

</template>

<script>

export default {
  name: 'EmployeeWeekStatisticComponent',
  data () {
    return {
      resource: null
    }
  },
  props: {
    resourceId: String,
    companyWeekHrsLabel: String,
    userWeekHrsLabel: String,
    weekHrsCompanySetting: String,
    show: Boolean,
    viewType: String
  },
  computed: {
    hrsTotalCalc () {
      return Math.round(+this.$store.getters.weekStatisticData[this.resourceId] / 60 * 100) / 100
    }
  }
}
</script>

<style scoped>

</style>
