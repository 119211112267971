<template>
  <el-checkbox-group
    class="positions-group"
    v-bind="$attrs"
    size="default"
  >
    <slot></slot>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'checkboxGroup'
}
</script>
<style scoped lang="scss">
.el-checkbox-big {
  :deep(&) .el-checkbox__inner {
    width: 28px;
    height: 28px;
    &::after {
      border: 3px solid #FFF;
      border-left: 0;
      border-top: 0;
      height: 12px;
      left: 10px;
      top: 4px;
    }
  }
}
</style>
