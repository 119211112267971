<template>
  <div class="row mt-2">
    <div class="col-3">
      <avatar
        :width="180"
        :borderRadius="50"
        :userId="userInfo.user_id"
        :placeholder="true"
        :placeholderUrl="'../../../static/images/App/placeholder.png'"
        :cache="false">
      </avatar>
      <div align="center" class="font-weight-bold">
        {{userInfo.full_name}}
      </div>
    </div>
    <Form ref="observer" as="div" class="col-9">
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label font-weight-bold">{{ $t('Employee ID:') }}</span> {{ userInfo.id }}
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label font-weight-bold">{{ $t('Time zone') }}:</span> {{userInfo.time_zone}}
        </div>
      </div>
      <div v-if="disableChangeUsername" class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <ui-text-input
            name="first_name"
            v-model="first_name"
            :validation="{required: true, max: 128}"
            :label="$t('First name')"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <ui-text-input
            name="last_name"
            v-model="last_name"
            :validation="{required: true, max: 128}"
            :label="$t('Last name')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label font-weight-bold">{{ $t('Phone number') }}: </span>
          <template v-if="userInfo.phones">
            <div v-for="(phone, key) in userInfo.phones" v-bind:key="phone.number">
              <i class="icon-check-circle text-primary font-weight-bold" v-if="phone.confirmed === true"></i>
              <i class="icon-check-square text-warning font-weight-bold" v-else></i>
              +{{key}}
            </div>
          </template>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label font-weight-bold">{{ $t('E-mail') }}:</span>
          <div v-if="userInfo.email">
            <i class="icon-check-circle text-primary font-weight-bold" v-if="userInfo.email_verified_at"></i>
            <i class="icon-check-square text-warning font-weight-bold" v-else></i>
            {{userInfo.email}}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label">
            <span class="font-weight-bold">{{ $t('Date of birth') }}: </span>
            <span v-if="userInfo.date_birth">{{fromZoneToZone(userInfo.date_birth, 'UTC', 'UTC').format(localeDateFormat)}}</span>
          </span>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('Gender') }}: </span>{{$t(userInfo.gender ? userInfo.gender : '')}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('I.D.') }}: </span>{{userInfo.identification}}</span>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('Country/Region') }}: </span>{{userInfo.country_code ? userInfo.country_code.toUpperCase() : ''}} {{country}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('State') }}: </span>{{subdivision}}</span>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('Zip code') }}: </span>{{userInfo.zip_code}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('City') }}: </span>{{city}}</span>
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <span class="control-label"><span class="font-weight-bold">{{ $t('Address') }}: </span>{{userInfo.address}}</span>
        </div>
      </div>
      <div class="row mt-2">
        <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end">
          <ui-select
            name="positions"
            v-model="positions"
            :clearable="true"
            :placeholder="$t('Select')"
            :label="$t('POSITIONS_SELECT')"
            :hint="$t('POSITIONS_SELECT_HINT')"
            :options="options"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end" v-if="positionChanged">
          <ui-date-picker
            name="replace_date"
            :clearable="false"
            :label="$t('POSITION_REPLACE_DATE')"
            :hint="$t('POSITION_REPLACE_DATE_HINT')"
            :validation="{required: true}"
            v-model="dateFrom"
          />
        </div>
        <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end">
          <ui-number-input
            name="required_working_time"
            v-model="required_working_time"
            :min="0"
            :validation="{required: false, decimal: 2}"
            :label="$t('EMPLOYEE_REQUIRED_WORKING_TIME')"
            :hint="$t('EMPLOYEE_REQUIRED_WORKING_TIME_TOOLTIP')"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end mt-2">
          <ui-text-input
            name="identifier"
            v-model="identifier"
            :validation="{required: false, max: 60}"
            :label="$t('EMPLOYEE_IDENTIFIER')"
            :hint="$t('EMPLOYEE_IDENTIFIER_TOOLTIP')"
          />
        </div>
        <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end mt-2">
          <ui-number-input
            name="job_part"
            v-model="job_part"
            :min="0"
            :precision="0"
            :step="1"
            :validation="{required: false, integer: true, min_value: 0}"
            :label="$t('EMPLOYEE_JOB_PART')"
            :hint="$t('EMPLOYEE_JOB_PART_TOOLTIP')"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end">
          <ui-multi-select
            name="skills"
            v-model="skills"
            :clearable="true"
            :multiple="true"
            :placeholder="$t('Select')"
            :label="$t('SKILLS_SELECT')"
            :hint="$t('SKILLS_SELECT_HINT')"
            :options="skillsOptions"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
        <div v-if="isEmployeeHasPermission('delete-company')" class="col-12 col-lg-6 align-self-end mt-2">
          <ui-checkbox
            name="allowedLogAccess"
            v-model="allowedLogAccess"
            :label="$t('EMPLOYEE_LOG_ACCESS')"
            :hint="$t('EMPLOYEE_LOG_ACCESS_TOOLTIP')"
          />
        </div>
        <div class="col-12 col-lg-6 align-self-end mt-2">
          <ui-color-picker
            name="color"
            v-model="color"
            :colors="colorsChoices"
            :label="$t('EMPLOYEE_COLOR')"
            :hint="$t('EMPLOYEE_COLOR_TOOLTIP')"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-lg-6 align-self-end">
          <label class="font-weight-bold mb-2">
            {{ $t('WORK_DAYS_EMPLOYEE') }}
            <ui-hint :content="$t('WORK_DAYS_EMPLOYEE_HINT')" />
          </label>
          <ui-checkbox
            name="isPersonalWorkDays"
            v-model="isPersonalWorkDays"
            :label="$t('IS_PERSONAL_WORK_DAYS')"
            :hint="$t('IS_PERSONAL_WORK_DAYS_HINT')"
          />
          <div class="row mt-2">
            <div
              v-for="(item, index) in workDays"
              :key="index"
              class="col-auto"
            >
              <ui-checkbox
                :name="index + '__is_work_day'"
                :disabled="!isPersonalWorkDays"
                v-model="item.is_work_day"
                :label="$t(item.name)"
                @change="val => handleDaysChange(val, index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-6">
          <button class="btn btn-success rounded-pill shadow m-2" @click="save">
            {{ $t('SAVE_WEB') }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '@/components/CommonComponents/Avatar'
import moment from 'moment-timezone'
import mixinMoment from '@/mixins/mixinMoment'
import mixinColors from '@/mixins/mixinColors'

export default {
  name: 'MainInfo',
  components: { Avatar },
  mixins: [mixinMoment, mixinColors],
  props: { userInfo: Object },
  data () {
    return {
      country: null,
      subdivision: null,
      city: null,
      positions: null,
      first_name: '',
      last_name: '',
      initPosition: null,
      dateFrom: moment().startOf('day').format(this.backendDateTimeFormat),
      options: [],
      skills: [],
      required_working_time: null,
      identifier: null,
      job_part: 100,
      allowedLogAccess: false,
      color: '#961800',
      disable_change_username: true,
      isPersonalWorkDays: false,
      workDays: null
    }
  },
  created () {
    if (this.userInfo.work_days) {
      this.isPersonalWorkDays = true
      this.workDays = this.userInfo.work_days
    } else {
      this.handleWorkDays()
    }
    this.required_working_time = this.userInfo.required_working_time_minutes !== null
      ? Math.round((this.userInfo.required_working_time_minutes / 60 + Number.EPSILON) * 100) / 100
      : null
    if (this.required_working_time === null) {
      this.required_working_time = this.baseWT ? this.baseWT : 0
    }
    const positions = this.userInfo.positions.length > 0 ? this.userInfo.positions[0].id : null
    this.positions = positions
    this.initPosition = positions

    this.skills = this.userInfo.skills?.length > 0 ? this.userInfo.skills.map(item => item.id) : []
    this.$store.dispatch('getPositions', this.$route.params.id).then((response) => {
      this.options = response
    })
    if (this.userInfo.identifier) {
      this.identifier = this.userInfo.identifier
    }
    if (this.userInfo.first_name) {
      this.first_name = this.userInfo.first_name
    }
    if (this.userInfo.last_name) {
      this.last_name = this.userInfo.last_name
    }
    if (this.userInfo.job_part) {
      this.job_part = Math.round(this.userInfo.job_part * 100) / 100
    }
    this.$store.dispatch('getSkills', this.companyId)
    this.color = this.userInfo.color
    this.allowedLogAccess = this.userInfo.is_log_allowed
    if (this.locale) {
      this.getCityInfo()
    }
  },
  watch: {
    userInfo: {
      handler: function () {
        if (this.userInfo.positions && this.userInfo.positions.length > 0) {
          this.positions = this.userInfo.positions.map(item => item.id)[0]
        }
        this.getCityInfo()
      },
      deep: true
    },
    locale () {
      this.getCityInfo()
    }
  },
  computed: {
    ...mapGetters(['avatar', 'locale']),
    companyRequiredWorkingTime () {
      let companyWT = this.$store.getters.company.required_working_time_minutes
      if (companyWT === null) return this.$t('Not implemented')
      companyWT = Math.round((companyWT / 60 + Number.EPSILON) * 100) / 100
      return companyWT.toString()
    },
    baseWT () {
      const companyWT = this.$store.getters.company.required_working_time_minutes
      if (companyWT === null) return null
      return Math.round((companyWT / 60 + Number.EPSILON) * 100) / 100
    },
    positionChanged () {
      return (this.positions ? this.positions : null) !== this.initPosition
    },
    disableChangeUsername () {
      return this.$store.getters.company.disable_change_username
    },
    skillsOptions () {
      return this.$store.getters.skills
    }
  },
  methods: {
    handleDaysChange (val, index) {
      console.log(1, val, index)
      this.workDays[index].is_work_day = val
    },
    handleWorkDays () {
      this.workDays = Object.assign([], this.$store.getters.company.work_days)
      this.workDays.sort((a, b) => {
        if (a.index > b.index) {
          return 1
        }
        if (a.index < b.index) {
          return -1
        }
        return 0
      })
      let firstDay = +this.$store.getters.company.salary.week_start + 1
      if (firstDay === 7) {
        firstDay = 0
      }
      const startArray = this.workDays.splice(firstDay)
      this.workDays = startArray.concat(this.workDays)
    },
    save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const payload = {
            positions_id: this.positions ? this.positions : null
          }

          if (this.positionChanged) {
            payload.date = this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
            this.$store.dispatch('attachPositions', [this.$route.params.id, this.userInfo.id, payload])
              .then((response) => {
                this.initPosition = payload.positions_id
              }).catch(e => {})
          }
          const data = {
            required_working_time_minutes: this.required_working_time
              ? Math.round(this.required_working_time * 60 + Number.EPSILON)
              : this.required_working_time,
            identifier: this.identifier,
            is_log_allowed: this.allowedLogAccess,
            job_part: this.job_part,
            color: this.color,
            skills: this.skills
          }

          if (this.disableChangeUsername) {
            data.first_name = this.first_name
            data.last_name = this.last_name
          }

          if (!this.isPersonalWorkDays) {
            data.work_days = null
          } else {
            data.work_days = this.workDays
          }

          if (this.isEmployeeHasPermission('get-employee-admin')) {
            this.patchAsAdmin(data)
          } else if (this.isEmployeeHasPermission('get related projects full data')) {
            this.patchAsManager(data)
          }
        }
      })
    },
    patchAsManager (data) {
      this.projectId = this.$route.params.projectId
      if (!this.projectId) {
        this.projectId = localStorage.getItem('currentProject')
      }
      this.$store.dispatch('patchProjectEmployee', [this.projectId, this.userInfo.id, data])
        .then(response => {
          this.$eventBus.emit('refetchUser')
          if (response.required_working_time_minutes === null) {
            this.required_working_time = this.baseWT
          }
        })
        .catch(() => {})
    },
    patchAsAdmin (data) {
      this.$store.dispatch('patchEmployee', [this.$store.getters.companyId, this.userInfo.id, data])
        .then(response => {
          this.$eventBus.emit('refetchUser')
          if (response.required_working_time_minutes === null) {
            this.required_working_time = this.baseWT
          }
        })
        .catch(() => {})
    },
    getCityInfo () {
      if (this.userInfo && this.userInfo.country_code) {
        this.$store.dispatch('getCountryNames', this.locale).then(countries => {
          this.country = countries[this.userInfo.country_code.toUpperCase()].name
        })
        if (this.userInfo.subdivision_id) {
          this.$store.dispatch('getSubdivisionNames', [this.locale, this.userInfo.country_code]).then(subdivisions => {
            this.subdivision = subdivisions.filter(item => item.id === this.userInfo.subdivision_id)[0].name
          })
          if (this.userInfo.city_id) {
            this.$store.dispatch('getCityNames', [this.locale, this.userInfo.country_code, this.userInfo.subdivision_id]).then(cities => {
              this.city = cities.filter(item => item.id === this.userInfo.city_id)[0].name
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
