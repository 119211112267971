<template>
  <div class="py-2 px-1">
    <template v-if="!isEdit">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col">
              <span class="project-color mx-1" :style="{ backgroundColor: mark.color }"></span>
              <span class="mx-2 text-primary font-weight-bold">{{mark.name}}</span>
            </div>
            <div class="col-auto">
              <i
                @click="handleEdit"
                class="icon-pencil btn-icon text-primary mx-2"
              />
              <i
                @click="handleDelete"
                class="icon-trash btn-icon text-danger"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12 col-lg-6">
          <Form ref="observer" as="div" class="row">
            <div class="col-auto my-auto">
              <color-picker
                :name="index + '__color'"
                  v-model="color"
                  :colors="colorsChoicesMarks"
              />
            </div>
            <div class="col">
              <ui-text-input
                  :id="'name__' + mark.id"
                  :name="'name__' + mark.id"
                  v-model="name"
                  :validation="{required: true, max: 128}"
              />
            </div>
            <div class="col-auto my-auto">
              <i
                  @click="handleSave"
                  class="icon-check btn-icon text-success mx-2"
              />
              <i
                  @click="handleEdit"
                  class="icon-x btn-icon text-primary"
              />
            </div>
          </Form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import colors from '@/mixins/mixinColorsMarks'
import errorMixin from '@/mixins/mixinApiErrors'
import ColorPicker from '@/ui/color-picker.vue'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'MarkItemComponent',
  mixins: [colors, errorMixin, ToastMixin],
  components: { ColorPicker },
  data () {
    return {
      isEdit: false,
      name: '',
      color: '#bbbbbb'
    }
  },
  props: {
    index: Number,
    mark: Object
  },
  created () {
    this.name = this.mark.name
    this.color = this.mark.color
  },
  methods: {
    handleEdit () {
      // fix for cancel editing
      if (!this.isEdit) {
        this.name = this.mark.name
        this.color = this.mark.color
      }
      this.isEdit = !this.isEdit
    },
    handleSave () {
      const payload = {
        name: this.name,
        color: this.color,
        id: this.mark.id
      }
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('updateTaskMarks', [this.companyId, payload]).then(() => {
            this.handleEdit()
            this.toastSuccess(this.$i18n?.t('TASK_MARK_UPDATED'))
          }).catch(error => {
            if (error.response.status === 422) {
              if (error.response.data.errors.name) {
                this.errors.add({
                  field: 'name' + this.mark.id,
                  msg: error.response.data.errors.name[0]
                })
              }
            } else {
              this.toastError(this.$i18n?.t(error.response.data.message))
            }
          })
        }
      })
    },
    handleDelete () {
      this.$confirm(this.$i18n?.t('CONFIRM_TASK_MARK_DELETE'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$emit('onDelete', this.mark.id)
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
