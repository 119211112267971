import moment from 'moment-timezone'

// исходные условия: предполагается что все даты и время в приложении задаются в таймзоне, которая указана в профиле пользователя
// кроме тех случаев, когда для расчетов используется таймзона из настроек проекта

export default {
  computed: {
    isCountryUs: function () {
      if (this.$store.getters.profileCountry.toLowerCase() === 'us') {
        return true
      }
      return false
    },
    backendTimeFormat: function () {
      return 'HH:mm'
    },
    backendTimeFormatExtended: function () {
      return 'HH:mm:00'
    },
    backendDateFormat: function () {
      return 'YYYY-MM-DD'
    },
    backendMonthYearFormat: function () {
      return 'YYYY-MM'
    },
    backendDateTimeFormat: function () {
      return 'YYYY-MM-DD HH:mm:00'
    },
    localeTimeFormat: function () {
      return this.$store.getters.profileTimeFormat
    },
    is12hourFormat: function () {
      return this.$store.getters.profileTimeFormat === 'hh:mm A'
    },
    localeDateFormat: function () {
      return this.$store.getters.profileDateFormat
    },
    localeMonthYearFormat: function () {
      return 'MM-YYYY'
    },
    localeMonthYearFormatExtended: function () {
      return 'MMMM YYYY'
    },
    localeMonthYearFormatUi: function () {
      return 'MM-yyyy'
    },
    localeWeekFormatUi: function () {
      return 'Week WW'
    },
    localeMonthYearFormatExtendedUi: function () {
      return 'MM-YYYY'
    },
    localeDateMonthFormat: function () {
      if (this.$store.getters.profileDateFormat === 'MM-DD-YYYY') {
        return 'MM.DD'
      }
      return 'DD.MM'
    },
    localeDateTimeFormat: function () {
      return this.$store.getters.profileDateFormat + ' ' + this.$store.getters.profileTimeFormat
    },
    localeDateTimeFormatShort: function () {
      if (this.$store.getters.profileDateFormat === 'DD-MM-YYYY') {
        return 'DD.MM' + ' ' + this.$store.getters.profileTimeFormat
      } else {
        return 'MM.DD' + ' ' + this.$store.getters.profileTimeFormat
      }
    },
    localeTimeFormatElementUi: function () {
      return this.$store.getters.profileTimeFormat
    },
    localeDateFormatElementUi: function () {
      if (this.$store.getters.profileDateFormat === 'MM-DD-YYYY') {
        return 'MM-DD-YYYY'
      }
      return 'DD-MM-YYYY'
    },
    backendDateFormatElementUi: function () {
      return 'yyyy-MM-dd'
    },
    localeDateTimeFormatElementUi: function () {
      return this.localeDateFormatElementUi + ' ' + this.localeTimeFormatElementUi
    }
  },

  methods: {
    // метод сначала привязывает к переданной строке таймзону из профиля пользователя а затем конвертирует ее в UTC
    toUTC: function (date) {
      const convertedDate = moment(date, this.localeDateTimeFormat).format(this.backendDateTimeFormat)
      const dateTz = moment.tz(convertedDate, this.$store.getters.profileTimeZone)
      return moment.utc(dateTz)
    },

    // метод привязывает к переданной строке таймзону UTC а затем конвертирует дату в таймзону по профилю пользователя
    toTimeZone: function (date) {
      return moment.tz(moment.utc(date), this.$store.getters.profileTimeZone)
    },

    toSelectedTimeZone: function (date, timeZone) {
      return moment.tz(moment.utc(date), timeZone)
    },

    // аналогично toUTC()
    timeToUTC: function (time) {
      const date = moment().format(this.backendDateFormat)
      const convertedTime = moment(time, this.localeTimeFormat).format(this.backendTimeFormat)
      const stamp = date + ' ' + convertedTime
      const dateTz = moment.tz(stamp, this.$store.getters.profileTimeZone)
      return dateTz.tz('UTC').format(this.backendTimeFormat)
    },

    // аналогично toTimeZone()
    timeToTz: function (time) {
      const date = moment().format(this.backendDateFormat)
      const stamp = date + ' ' + time
      const dateUTC = moment.tz(stamp, 'UTC')
      return dateUTC.tz(this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    },

    /**
     *
     * @param date string
     * @param fromTz string
     * @param toTz string
     * @returns {*}
     */
    fromZoneToZone (date, fromTz, toTz) {
      // date = moment(date, this.backendDateTimeFormat)
      date = moment(date)
      date.tz(fromTz, true)
      return date.tz(toTz)
    },

    /**
     *
     * @param date {moment}
     * @param fromTz string
     * @param toTz string
     * @returns {*}
     */
    fromZoneToZoneMoment (date, fromTz, toTz) {
      // date = moment(date, this.backendDateTimeFormat)
      date.tz(fromTz, true)
      return date.tz(toTz)
    },

    timeFromZoneToZone (time, fromTz, toTz, locale) {
      let date = moment().format(this.backendDateFormat)
      // console.log(date)
      const stamp = date + ' ' + time
      date = moment(stamp, this.backendDateTimeFormat)
      date.tz(fromTz, true)
      return date.tz(toTz).format(locale)
    },

    timeToLocale (time) {
      return moment(time, this.localeTimeFormat).format(this.localeTimeFormat)
    },

    backendDateFormatMethod: function () {
      return 'YYYY-MM-DD'
    },

    backendDateTimeFormatMethod: function () {
      return 'YYYY-MM-DD HH:mm:00'
    },

    /**
     * Проверка на пересечение временных диапазонов
     *
     * @param event1start string
     * @param event1end string
     * @param event2start string
     * @param event2end string
     * @param countBoundaryValuesAsOverlap boolean
     * @returns boolean
     */
    isOverlap (event1start, event1end, event2start, event2end, countBoundaryValuesAsOverlap = false) {
      if (countBoundaryValuesAsOverlap) {
        // Пересечение диапазонов (StartA <= EndB) and (EndA >= StartB)
        return !!(moment(event1start).isSameOrBefore(moment(event2end)) && moment(event1end).isSameOrAfter(moment(event2start)))
      }
      // Пересечение диапазонов (StartA < EndB) and (EndA > StartB)
      return !!(moment(event1start).isBefore(moment(event2end)) && moment(event1end).isAfter(moment(event2start)))
    },

    /**
     * Проверка на попадание даты в диапазон
     * если дата между началом и концом - вернет true, если дата совпадает с началом - вернет true, если дата совпадает с концом - вернет false
     *
     * @param value string
     * @param start string
     * @param end string
     * @returns boolean
     */
    isInclude (value, start, end) {
      return (moment(value).isSameOrAfter(start) && moment(value).isBefore(end))
    }
  }
}
