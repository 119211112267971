<template>
  <ui-tooltip
    :show-after="openDelay"
    class="w-100">
    <template #hint>
      <div>
        <div>{{tooltip}}</div>
        <div style="max-width: 400px">{{event.extendedProps.item.description}}</div>
        <template v-if="event.extendedProps.item.client_id">
          <div>{{ event.extendedProps.item.client.name }}</div>
          <div>{{ event.extendedProps.item.client.address }}</div>
        </template>
      </div>
    </template>
    <template #inner-html>
      <div class="fc-content py-1 text-truncate" data-toggle="modal" data-target="#ClickTaskModal" style="cursor: pointer">
        <div style="position: relative">
          <div>
            <span v-if="event.extendedProps.displayEventTime" class="fc-time ml-1 text-nowrap text-truncate">{{ start.format(timeFormat) }} - {{ end.format(timeFormat) }}</span>
            <span class="ml-1 overflow-hidden">{{ event.title }}</span>
          </div>
          <div class="ml-1" v-if="event.extendedProps.item.client_id">
            <div>{{ event.extendedProps.item.client.name }}</div>
            <div>{{ event.extendedProps.item.client.address }}</div>
          </div>
          <div class="ml-1" v-if="event.extendedProps.item.marks.length > 0">
            <template v-for="mark in event.extendedProps.item.marks">
              <ui-tooltip
                v-if="taskMarksIndexed[mark]"
                :key="mark"
                :show-after="openDelay">
                <template #hint>
                  <div>
                    {{taskMarksIndexed[mark].name}}
                  </div>
                </template>
                <template #inner-html>
                  <span class="project-color mr-1" :style="{ backgroundColor: taskMarksIndexed[mark].color }" />
                </template>
              </ui-tooltip>
            </template>
          </div>
          <div v-if="isTwoDays && lang === 'he'" style="position: absolute; left: 0px; top: 3px;" :style="{
          'background-color': event.backgroundColor
        }">
            <i class="icon-arrow-left"></i>
          </div>
          <div v-if="isTwoDays && lang !== 'he'" style="position: absolute; right: 0px; top: 3px;" :style="{
          'background-color': event.backgroundColor
        }">
            <i class="icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </template>
  </ui-tooltip>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'TaskComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 0,
      tags: [],
      markTemplate: '',
      tooltipEnable: true
    }
  },
  props: {
    event: Object,
    view: Object,
    tasksDisplayType: String
  },
  computed: {
    taskMarksIndexed () {
      return this.$store.getters.taskMarksIndexed
    },
    timeFormat () {
      return this.localeTimeFormat
    },
    viewType () {
      return this.view.type
    },
    start () {
      const start = new Date(this.event.start)
      return moment.parseZone(start)
    },
    end () {
      const end = new Date(this.event.end)
      return moment.parseZone(end)
    },
    durationHrs () {
      const duration = moment.duration(this.end.diff(this.start)).asHours()
      return Math.round(duration * 100) / 100
    },
    tooltip () {
      return this.event.title + ' ' + this.start.format(this.localeTimeFormat) + ' - ' + this.end.format(this.localeTimeFormat)
    },
    /** Проверяем, есть ли у шифта переход между датами (00:00 - 00:00 считается одним днем, если длительность шифта = 1 день) */
    isSameDay () {
      return moment(this.start.format(this.backendDateFormat), this.backendDateFormat).isSame(moment(this.end.format(this.backendDateFormat), this.backendDateFormat), 'day') ||
        (this.start.format(this.backendTimeFormat) === this.end.format(this.backendTimeFormat) &&
          this.start.format(this.backendTimeFormat) === '00:00' &&
          this.start.diff(this.end, 'days') === -1)
    },
    isTwoDays () {
      // console.log(123, this.lang)
      return (this.view.type === 'resourceTimeMonth' || this.view.type === 'resourceTimelineWeek') &&
        !this.isSameDay
    }
  },
  methods: {
    setView (view) {
      // eslint-disable-next-line vue/no-mutating-props
      this.view = view
    }
  }
}
</script>

<style scoped>
.confirmed {
  z-index: 100;
  position: absolute; /* Абсолютное позиционирование */
  top: -8px; /* Положение от верхнего края */
  right: 1px; /* Положение от правого края */
}
.red-circle {
  background: red;
  border: 1px solid white;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
/*.project-color {*/
/*width: 20px;*/
/*height: 20px;*/
/*display: inline-block;*/
/*border-radius: 25px;*/
/*vertical-align: middle;*/
/*}*/
</style>
