<template>
  <modal-component
    :id="'bonusPenaltyModal'"
    @closeModal="closeModal"
    :modalClass="'modal-xl'"
    :closeModalId="'modalBonusPenaltyCloseButton'"
    >
    <template #header>
      <h5 class="modal-title" >{{ $t('BONUS_PENALTI_IMPORT_MODAL_TITILE')}}</h5>
    </template>
    <template #body>
      <Form ref="observer" as="div">
        <el-steps :active="active" finish-status="success">
          <el-step :title="$t('BONUS_PEMALTY_STEP_1')"></el-step>
          <el-step :title="$t('BONUS_PEMALTY_STEP_2')"></el-step>
          <el-step :title="$t('BONUS_PEMALTY_STEP_3')"></el-step>
        </el-steps>
        <div v-if="active === 0">
          <select-file-step
            @setFile="setFile"
          />
        </div>
        <div v-else-if="active === 1">
          <!--        <el-alert-->
          <!--          v-if="errors.has('phone_index') || errors.has('bonus_penalty_index') || errors.has('date_index') || errors.has('time_from_index') || errors.has('time_to_index')"-->
          <!--          show-icon-->
          <!--          center-->
          <!--          :closable="false"-->
          <!--          :title="$t('BONUS_PENALTI_IMPORT_MATCH_FIELDS_ERRORS')"-->
          <!--          type="error">-->
          <!--        </el-alert>-->
          <match-table-step
            :filePreview="matchPreview"
            :rows="fileRows"
            @setFileRows="setFileRows"
            @skipFirstRow="skipFirstRow"
          />
        </div>
        <div v-else-if="active === 2">
          <preview-step
            :previewData="previewData"
            :fileRows="fileRows"
          />
        </div>
      </Form>
    </template>
    <template #footer>
      <div class="mx-auto text-right w-100">
        <button v-if="active >0 " class="btn btn-outline-primary rounded-pill shadow-sm mr-2" @click="prevStatus">{{ $t('Previous') }}</button>
        <button v-if="active !== 2" class="btn btn-primary rounded-pill shadow-sm" @click="nextStatus">{{ $t('Next') }}</button>
        <button v-else class="btn btn-success rounded-pill shadow-sm" @click="save">{{ $t('SAVE_WEB') }}</button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import SelectFileStep from '@/components/Schedule/CompanyScheduleComponents/Modals/BonusPenaltyModalComponents/SelectFileStep'
import MatchTableStep from '@/components/Schedule/CompanyScheduleComponents/Modals/BonusPenaltyModalComponents/MatchTableStep'
import PreviewStep from '@/components/Schedule/CompanyScheduleComponents/Modals/BonusPenaltyModalComponents/PreviewStep'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'

export default {
  components: {
    ModalComponent: ModalComponent,
    SelectFileStep: SelectFileStep,
    MatchTableStep: MatchTableStep,
    PreviewStep: PreviewStep
  },
  mixins: [
    momentMixin,
    errorMixin
  ],
  name: 'BonusPenaltyModal',
  data () {
    return {
      active: 0,
      publicPath: process.env.BASE_URL,
      file: '',
      fileRows: {},
      matchPreview: [],
      previewData: {
        matched_data: [],
        wrong_data: []
      },
      upload: {
        url: process.env.ROOT_API + 'import_file/',
        headers: {
          Authorization: 'api-key ' + localStorage.getItem('api_key')
        }
      }
    }
  },
  props: {
  },
  created () {
  },
  methods: {
    closeModal () {
      document.getElementById('modalBonusPenaltyCloseButton').click()
      this.$emit('closeBonusPenaltyModal')
    },
    setFile (item) {
      this.file = item
    },
    setFileRows (data) {
      this.fileRows = data
    },
    skipFirstRow (value) {
      this.skip_first_row = value
    },
    nextStatus () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (this.active === 0) {
            if (this.file) {
              this.matchFile(1)
            } else {
              this.toastError(this.$i18n?.t('Chose a file!'))
            }
          } else if (this.active === 1) {
            if (this.file && this.fileRows) {
              this.matchFile(0)
            }
          }
        }
      })
    },
    save () {
      const payload = { bonuses: [] }
      if (this.previewData.matched_data) {
        this.previewData.matched_data.forEach(element => {
          payload.bonuses.push({
            shift_id: element.shift.id,
            amount: element.bonus_penalty
          })
        })
      }

      this.$store.dispatch('bonusPenaltyBatchStore', [this.$store.getters.companyId, payload]).then(response => {
        this.toastSuccess(this.$i18n?.t('BONUS_PENALTI_SUCCESS_BATCH_STORE'))
        this.closeModal()
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    matchFile (preview) {
      const formData = new FormData()
      formData.append('preview', preview)
      formData.append('skip_first_row', this.skip_first_row ? 1 : 0)
      formData.append('file', this.file)

      if (!preview) {
        for (const key in this.fileRows) {
          formData.append(this.fileRows[key], key)
        }
      }

      this.$store.dispatch('bonusPenaltyImportMatchFile', [this.$store.getters.companyId, formData]).then(response => {
        if (response) {
          if (preview) {
            this.matchPreview = response.slice(0, 10)
          } else {
            this.previewData = response
          }
          this.active++
        }
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    prevStatus () {
      if (this.active === 1) {
        this.file = null
      } else if (this.active === 2) {
        this.previewData = []
        // this.fileRows = []
      }
      this.active--
    }
  }
}
</script>

<style>
  .el-input-number {
    position: relative;
    display: inline-block;
    width: 180px;
    line-height: 39px!important;
  }
  .el-input-number--medium .el-input__inner {
    padding-left: 43px!important;
    padding-right: 43px!important;
  }
</style>
