import { HTTP } from '@/api/http_utils'

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  // CRUD
  getCompanyUserRequestReasons ({ commit, rootState }) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/requests/reasons`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  creteCompanyUserRequestReason ({ commit, rootState }, data) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/requests/reasons`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getCompanyUserRequestReason ({ commit, rootState }, requestReasonId) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/requests/reasons/${requestReasonId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  updateCompanyUserRequestReason ({ commit, rootState }, [requestReasonId, data]) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/requests/reasons/${requestReasonId}`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteCompanyUserRequestReason ({ commit, rootState }, requestReasonId) {
    const companyId = rootState.company.companyId

    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/requests/reasons/${requestReasonId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
