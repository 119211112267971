import { HTTP } from '@/api/http_utils'

const UPDATE_NOTES_BY_COMPANY = 'UPDATE_NOTES_BY_COMPANY'
const NOTE_TO_EDIT = 'NOTE_TO_EDIT'
const UPDATE_NOTE_IN_LIST = 'UPDATE_NOTE_IN_LIST'
const DELETE_NOTE_FROM_LIST = 'DELETE_NOTE_FROM_LIST'

const state = {
  noteToEdit: null,
  notesByCompany: {}
}

const promises = {}

const getters = {
  noteToEdit: state => state.noteToEdit,
  notesByCompany: state => Object.values(state.notesByCompany)
}

const mutations = {
  [NOTE_TO_EDIT] (state, note) {
    state.noteToEdit = note
  },
  [UPDATE_NOTES_BY_COMPANY] (state, notes) {
    const data = {}
    notes.forEach(note => {
      data[note.id] = note
    })
    state.notesByCompany = data
  },
  [UPDATE_NOTE_IN_LIST] (state, payload) {
    state.notesByCompany[payload.id] = payload
  },
  [DELETE_NOTE_FROM_LIST] (state, noteId) {
    delete state.notesByCompany[noteId]
  }
}

const actions = {
  setNoteToEdit ({ commit }, payload) {
    commit(NOTE_TO_EDIT, payload)
  },
  getNotesByCompany ({ commit }, [companyId, queryParams]) {
    const promiseKey = `/companies/${companyId}/notes${queryParams}`

    if (promises[promiseKey]) {
      console.error('repeating requests notes')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/notes${queryParams}`).then(response => {
        commit(UPDATE_NOTES_BY_COMPANY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getNotesByEmployee ({ commit }, [companyId, employeeId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/employees/${employeeId}/notes`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createNote ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/employees/${payload.employeeId}/notes`, payload).then(response => {
        commit(UPDATE_NOTE_IN_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateNote ({ commit }, [companyId, noteId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/notes/${noteId}`, payload).then(response => {
        commit(UPDATE_NOTE_IN_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteNote ({ commit }, [companyId, noteId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/notes/${noteId}`).then(response => {
        commit(DELETE_NOTE_FROM_LIST, noteId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
