<template>
  <div class="card-body">
    <div class="row">
      <div v-for="(permission, key) in this.companyApp ? this.companyApp.permissions : app.permissions"
           :key="key"
           class="col-3"
      >
        <span class="text-wrap">{{ $t(permission) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeveloperAppMainInfo',
  props: {
    app: Object,
    companyApp: Object
  }
}
</script>
