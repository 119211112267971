<template>
  <div class="row">
    <div class="col">
      <label class="col-form-label font-weight-bold">{{ label }}</label>
      <ui-hint v-if="hint" :content="hint"/>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-form-row',

  props: {
    label: String,
    hint: String
  }

}
</script>
