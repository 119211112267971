<template>
    <div class="row mt-2" v-if="isEmployeeHasPermission('create-shift') &&
     showStat && Object.keys(statistic).length > 0">
      <div class="col-lg-12">
        <div class="p-1 text-left font-weight-bold">
          <span>{{ $t('Daily number of shifts') }}</span>
        </div>
        <div class="p-1">
          <div class="" v-for="(count, key, index) in stat"  :key="index">
            <i class="icon-clock pr-2" style="font-size: 15px"></i>
            {{ start(key) }} - {{ end(key) }}
            <span class="font-weight-bold pl-2">{{count}} </span>
            <span v-if="count === 1">{{ $t('shift')}}</span>
            <span v-else>{{ $t('shifts')}}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
// import moment from 'moment'

export default {
  name: 'Statistic',
  mixins: [momentMixin],
  data () {
    return {
      show: false
    }
  },
  props: {
    showStat: Boolean,
    statistic: Object
  },
  computed: {
    stat () {
      return this.statistic
    }
  },
  watch: {},
  methods: {
    splitKey (key, index) {
      const keyArray = key.split('_')
      return keyArray[index]
    },
    start (key) {
      return this.fromZoneToZone(this.splitKey(key, 0), 'UTC', this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    },
    end (key) {
      return this.fromZoneToZone(this.splitKey(key, 1), 'UTC', this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
