<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <template v-if="!dummy">
      <table class="table table-bordered">
        <thead>
          <tr class="text-center">
            <th class="align-middle text-center">{{$t("Employee's name")}}</th>
            <th class="align-middle text-center">{{$t('ACTIVE_TASKS')}}</th>
            <th class="align-middle text-center">{{$t('FINISHED_TASKS')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in tableData" :key="row.employee_id">
            <td class="align-middle text-left">
              {{ row.last_name }} {{ row.first_name }}
            </td>
            <td class="align-middle text-center">
              {{row.active_tasks}}
            </td>
            <td class="align-middle text-center">
              {{row.finished_tasks}}
            </td>
          </tr>
        </tbody>
      </table>
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        v-model:current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        :total="pagination.total"
      />
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import { mapGetters } from 'vuex'

export default {
  name: 'TasksReport',
  components: { DummyPaymentRequired: DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy],
  data () {
    return {
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      pagination: {
        currentPage: 1,
        total: 0,
        pageSize: 1
      },
      dummy: false,
      tableData: []
    }
  },
  props: ['period', 'search'],
  created () {
    this.init()
  },
  beforeUnmount () {
    this.$eventBus.off('exportTasksReport')
  },
  mounted () {
    this.$eventBus.on('exportTasksReport', this.exportReport)
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    period () {
      this.init()
    },
    search () {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.init()
      }, 1000)
    }
  },
  methods: {
    exportReport (exportType) {
      this.getTableData(exportType)
    },
    init () {
      this.getTableData()
    },
    getTableData (exportType = null) {
      const data = {
        page: this.pagination.currentPage,
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat),
        search: this.search,
        per_page: 25
      }

      let blob = 0
      if (exportType) {
        data.export_type = exportType
        blob = 1
      }

      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasksStats', [this.companyId, payload, blob]).then((response) => {
        if (exportType) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t(exportType)}_${moment(payload.start).format(this.backendDateFormat)}_${moment(payload.end).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.tableData = response.data
          this.pagination.currentPage = response.current_page
          this.pagination.total = response.total
          this.pagination.pageSize = +response.per_page
        }
        this.$eventBus.emit('dummy', false)
        this.loaded = true
        this.dummy = false
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    handleCurrentChange () {
      this.init()
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
