<template>
  <div class="card card-small my-1" v-bind:key="project.id">
    <div class="card-body">
      <div class="row my-auto">
        <div class="col my-1 my-auto">
          <span class="font-weight-bold">{{$t('Project')}} </span>
          <span class="font-weight-bold text-primary mx-1">{{project.name}}</span>
        </div>
        <div class="col-3 my-1 text-right my-auto" style="max-width: 180px">
          <ui-select
            v-if="isEmployeeHasPermission('get-employee-admin')"
            v-model="role"
            name="role"
            :clearable="false"
            :placeholder="$t('Select')"
            :options="roleChoices"
            :key-name="'value'"
            :label-name="'label'"
            :value-name="'value'"
            :validation="{required: true}"
          />
        </div>
        <div class="col-1 my-1 my-auto text-center">
          <i class="icon-trash btn-icon text-danger mx-1" @click="removeUserFromProject(project)"></i>
        </div>
      </div>
      <div v-if="schedules.length > 0" class="table-responsive m-1">
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th scope="col">{{$t('Schedule')}}</th>
            <th v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
             (isEmployeeHasPermission('get-company-schedules-manager') &&
              salarySettings.show_salary_to_managers)"
                class="text-center" scope="col">
              {{$t('Basic rate')}} ({{currency}}) <ui-hint :content="$t('BASIC_RATE_HINT')" />
            </th>
            <th v-if="isEmployeeHasPermission('get-company-schedules-admin') ||
             (isEmployeeHasPermission('get-company-schedules-manager') &&
              salarySettings.show_salary_to_managers)"
                class="text-center" scope="col">
              {{$t('Base rate change date')}}
              <ui-hint :content="$t('BASE_RATE_CHANGE_DATE_HINT')" />
            </th>
            <th class="text-center" scope="col" style="width: 20%">
              {{$t('Marks')}}  <ui-hint :content="$t('MARKS_HINT')" />
            </th>
            <th class="text-center" scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="schedule in schedules" :key="schedule.id">
            <user-schedule-info-component
              v-on:updateSchedules="updateSchedules"
              :userId="userId"
              :userMarksChoices="userMarksChoices"
              :scheduleData="schedule"
            >
            </user-schedule-info-component>
          </template>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button
          type="button"
          data-toggle="modal"
          data-backdrop="static"
          data-target="#AddUserToScheduleModal"
          @click="() => { this.$eventBus.emit('showAddUserToScheduleModal', [this.project, this.schedules]) }"
          class="btn btn-success rounded-pill shadow-sm">
          <span>{{ $t('Add to schedule')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserScheduleInfoComponent from './UserScheduleInfoComponent'

export default {
  name: 'UserProjectInfoComponent',
  components: { UserScheduleInfoComponent },
  data () {
    return {
      roleChoices: [
        {
          value: 'user',
          label: this.$t('Employee')
        },
        {
          value: 'manager',
          label: this.$i18n?.t('Manager')
        },
        {
          value: 'manager_user',
          label: this.$i18n?.t('Manager') + ' + ' + this.$i18n?.t('Employee')
        }
      ],
      role: null,
      schedules: [],
      userMarksChoices: []
    }
  },
  props: { project: Object, userId: Number },
  created () {
    this.$eventBus.on('updateSchedulesList', this.updateSchedules)
    this.role = this.project.role
    this.$store.dispatch('getMarksByProject', [this.project.id, true]).then((response) => {
      if (response) {
        this.userMarksChoices = response
      }
    })
    this.updateSchedules()
  },
  watch: {
    role () {
      this.changeProjectRole()
    },
    project () {
      this.role = this.project.role
    }
  },
  computed: {
    currency () {
      return this.$store.getters.currency
    },
    salarySettings () {
      return this.$store.getters.company.salary
    }
  },
  methods: {
    updateSchedules () {
      this.schedules = []
      this.$store.dispatch('getSchedulesByProject', [this.project.id]).then((schedules) => {
        schedules.forEach(schedule => {
          const result = schedule.users.filter(user => {
            return user.employee_id === this.userId
          })
          if (result.length > 0) {
            this.schedules.push({
              scheduleTitle: schedule.name,
              scheduleId: schedule.id,
              userData: result[0]
            })
          }
          // console.log(123, this.schedules)
        })
      })
    },
    changeProjectRole () {
      // console.log(this.project)
      // сначала удаляем юзера из юзеров/менеджеров проекта
      const managers = this.project.managers
      const managerIndex = managers.indexOf(this.userId)
      if (managerIndex !== -1) managers.splice(managerIndex, 1)
      const users = this.project.users
      const userIndex = users.indexOf(this.userId)
      if (userIndex !== -1) users.splice(userIndex, 1)
      // потом заново добавляем в зависимости от выбранной роли
      if (this.role === 'manager_user') {
        users.push(this.userId)
        managers.push(this.userId)
      } else if (this.role === 'manager') {
        managers.push(this.userId)
      } else {
        users.push(this.userId)
      }
      const projectData = { ...this.project }
      projectData.time_zone = this.project.timeZone
      projectData.rate = +this.project.rate
      projectData.managers = managers
      projectData.users = users
      this.$store.dispatch('editProject', [this.project.id, projectData]).then((project) => {
      }).catch(() => {
      })
    },
    removeUserFromProject () {
      if (this.isEmployeeHasPermission('create project') ||
        !this.project.managers.includes(this.userId)) {
        this.$confirm(this.$i18n?.t('This will remove user from project. Continue?'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Ok'),
          cancelButtonText: this.$i18n?.t('Cancel'),
          type: 'warning',
          center: true
        }).then(() => {
          const managers = this.project.managers
          let index = managers.indexOf(this.userId)
          if (index !== -1) managers.splice(index, 1)
          const users = this.project.users
          index = users.indexOf(this.userId)
          if (index !== -1) users.splice(index, 1)
          const projectData = { ...this.project }
          projectData.time_zone = this.project.timeZone
          projectData.rate = +this.project.rate
          projectData.managers = managers
          projectData.users = users
          this.$store.dispatch('editProject', [this.project.id, projectData]).then((project) => {
            this.$emit('init')
            this.$eventBus.emit('refetchUser')
            document.getElementById('EditUserModalClose').click()
          }).catch(() => {
          })
        }).catch(() => {
        })
      } else {
        this.$alert(this.$i18n?.t('You can`t remove another manager from project'), this.$i18n?.t('Warning'), {
          confirmButtonText: this.$i18n?.t('Ok'),
          type: 'warning',
          center: true,
          callback: action => {
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
