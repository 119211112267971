<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t("POSITIONS_TITLE") }}</span>
            <ui-hint :content="$t('POSITIONS_TITLE_TOOLTIP')" />
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <Form ref="observe" v-slot="{ meta }" @submit="save">
          <div class="row form-group">
            <div class="col-lg-auto">
              <ui-text-input
                :label="$t('POSITION_ADD')"
                id="title"
                :name="'title'"
                v-model='newPosition.title'
                :validation="{required: true}"
              />
            </div>
            <div class="col-lg-auto my-auto">
            <span class="form-group mt-4">
              <ui-color-picker
                name="newPosition.color"
                :label="$t('Choose a color:')"
                v-model="newPosition.color"
                :colors="colorsChoicesMarks"
              />
            </span>
            </div>
            <div class="col-lg-auto">
              <ui-number-input
                :label="$t('SORT_PRIORITY')"
                v-model="newPosition.priority"
                name="priority"
                :step="1"
                :precision="0"
                :min="1"
                :validation="{required: true}"
              />
            </div>
            <div v-if="isEmployeeHasPermission('get-employee-admin') ||
           (isEmployeeHasPermission('get-positions-show-salary') &&
            salarySettings.show_salary_to_managers)" class="col-lg-auto">
              <ui-text-input
                :label="$t('POSITION_RATE')"
                id="rate"
                name="rate"
                v-model="newPosition.rate"
                :validation="{required: false, decimalOrNull: true, greaterOrEqualThanZero: true}"
              />
            </div>
            <div class="col-lg-auto">
              <ui-text-input
                :label="$t('MONTHLY_HRS')"
                id="monthly_hrs"
                name="monthly_hrs"
                v-model="newPosition.monthly_hrs"
                :validation="{required: false, decimalOrNull: true, greaterOrEqualThanZero: true}"
              />
            </div>
            <div class="col-lg-auto mt-4">
              <button class="btn btn-success btn-circle shadow-sm" :class="{'disabled': !meta.valid}">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </div>
        </Form>
        <el-divider></el-divider>
        <div class="row">
          <div class="col-lg-12" v-if="positions.length > 0">
            <span class="text-uppercase font-weight-bold text-primary">{{ $t('POSITIONS_LIST_TITLE') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 table-responsive">
            <Form ref="edit">
              <table class="table table-hover">
                <thead>
                <tr>
                  <td>{{$t('title')}}</td>
                  <td>{{$t('color')}}</td>
                  <td  v-if="isEmployeeHasPermission('get-employee-admin') ||
                (isEmployeeHasPermission('get-positions-show-salary') &&
                 salarySettings.show_salary_to_managers)">{{$t('rate')}}</td>
                  <td v-if="inEdit.id">{{$t('WAGE_REPLACE_DATA')}}</td>
                  <td>{{$t('MONTHLY_HRS_TITLE')}}</td>
                  <td>{{$t('priority')}}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(position, index) in positions" v-bind:key="index">
                  <td>
                    <div class="my-auto">
                      <span v-if="position.id !== inEdit.id">{{position.title}}</span>
                      <ui-text-input
                        v-if="position.id === inEdit.id"
                        :name="index + '_position_title'"
                        v-model='inEdit.title'
                        :validation="{required: true}"
                      />
                    </div>
                  </td>
                  <td>
                    <span v-if="position.id !== inEdit.id" class="project-color" v-bind:style="{ backgroundColor: position.color }"></span>
                    <div v-if="position.id === inEdit.id" class="form__input border-vue-swatches">
                      <ui-color-picker
                        :name="index + '_inEdit.colo'"
                        v-model="inEdit.color"
                        :colors="colorsChoicesMarks"
                      />
                    </div>
                  </td>
                  <td  v-if="isEmployeeHasPermission('get-employee-admin') ||
                  (isEmployeeHasPermission('get-positions-show-salary') &&
                   salarySettings.show_salary_to_managers)">
                    <div class="my-auto">
                    <span v-if="position.id !== inEdit.id">
                      {{position.rate ? Math.floor(position.rate * 100) / 100 : ''}}
                    </span>
                      <ui-text-input
                        v-if="position.id === inEdit.id"
                        :name="index + '_position_rate'"
                        v-model="inEdit.rate"
                        :validation="{required: false, decimalOrNull: true, greaterOrEqualThanZero: true}"
                      />
                    </div>
                  </td>
                  <td v-if="inEdit.id">
                    <ui-date-picker
                      :name="index + '_from'"
                      v-if="position.id === inEdit.id"
                      v-model="from"
                      :validation="{required: position.id === inEdit.id}"
                      :clearable="false"
                      :disabled="position.rate === inEdit.rate"
                    />
                  </td>
                  <td>
                    <div v-if="position.id !== inEdit.id">
                      <span>{{position.monthly_hrs ? Math.floor(position.monthly_hrs * 100) / 100 : ''}}</span>
                    </div>
                    <ui-text-input
                      v-if="position.id === inEdit.id"
                      :name="index + '_monthly_hrs'"
                      v-model="inEdit.monthly_hrs"
                      :validation="{required: false, decimalOrNull: true, greaterOrEqualThanZero: true}"
                    />
                  </td>
                  <td>
                    <div v-if="position.id !== inEdit.id">
                      <span>{{position.priority}}</span>
                    </div>
                    <div v-if="position.id === inEdit.id">
                      <ui-number-input
                        v-model="inEdit.priority"
                        :name="index + 'priority'"
                        :precision="0"
                        :step="1"
                        :min="1"
                        :validation="{required: true}"
                      />
                    </div>
                  </td>
                  <td class="">
                    <div class="d-flex justify-content-end">
                      <a v-if="position.id !== inEdit.id" @click="editPosition(index)">
                        <i class="icon-pencil btn-icon text-primary mx-2"></i>
                      </a>
                      <a v-if="position.id !== inEdit.id" @click="deletePosition(position.id)">
                        <i class="icon-trash btn-icon text-danger"></i>
                      </a>
                      <div class="mt-1">
                        <i
                          v-if="position.id === inEdit.id"
                          class="icon-check btn-icon text-success"
                          @click="saveEdited(inEdit, position.rate !== inEdit.rate)" :title="$t('SAVE_WEB')"
                        ></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </Form>
          </div>
          <div class="col-lg-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/mixins/mixinColorsMarks'
import mixinApiErrors from '@/mixins/mixinApiErrors'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'

export default {
  mixins: [colors, mixinApiErrors, momentMixin],
  name: 'Positions',

  data () {
    return {
      createStub: {
        title: '',
        color: '#bbbbbb',
        priority: 1,
        monthly_hrs: null,
        rate: null
      },
      newPosition: {
        title: '',
        color: '#bbbbbb',
        priority: 1,
        monthly_hrs: null,
        rate: null
      },
      editStub: {
        id: null
      },
      inEdit: {
        id: null
      },
      from: moment().startOf('day').format(this.backendDateTimeFormat)
    }
  },

  created () {
    if (this.companyId) {
      this.getPositions()
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    positions () {
      const positions = this.$store.getters.positions

      if (positions) {
        positions.sort((a, b) => {
          if (a.priority > b.priority) return 1
          if (a.priority < b.priority) return -1
          return 0
        })
      }

      return positions
    },
    salarySettings () {
      return this.$store.getters.company.salary
    }
  },
  watch: {
    companyId () {
      if (this.companyId) {
        this.getPositions()
      }
    }
  },
  methods: {
    getPositions () {
      this.$store.dispatch('getPositions', this.companyId).then(response => {
        //
      }).catch(() => {
        // TODO: missing catch
      })
    },
    save (values, { resetForm }) {
      this.$refs.observe.validate().then(isValid => {
        if (isValid.valid) {
          this.$store.dispatch('createPosition', [this.companyId, this.newPosition]).then((response) => {
            this.newPosition = Object.assign({}, this.createStub)
            resetForm()
            this.toastSuccess(this.$t('Position successfully created'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },

    saveEdited (position, rateChanged) {
      this.$refs.edit.validate().then(isValid => {
        if (isValid) {
          if (rateChanged) {
            position.date = this.fromZoneToZone(this.from, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
          }
          this.$store.dispatch('editPosition', [this.companyId, position.id, position]).then((response) => {
            this.inEdit = Object.assign({}, this.editStub)
            this.toastSuccess(this.$t('Position successfully edited'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },

    editPosition (index) {
      this.inEdit = Object.assign({}, this.positions[index])
    },

    deletePosition (positionId) {
      const count = this.positions.filter(item => item.id === positionId)[0].related_employees_count
      let message
      if (count > 0) {
        message = this.$t('POSITION_WITH_EMPLOYEES_DELETE_WARNING') + ' ' + count
      } else {
        message = this.$t('POSITION_DELETE_WARNING')
      }
      this.$confirm(message, this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('deletePosition', [this.companyId, positionId]).then(() => {
          this.toastSuccess(this.$t('Delete completed'))
          this.$store.dispatch('getEmployeesByCompanyNoCached', this.companyId)
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        //
      })
    }
  }

}
</script>

<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
