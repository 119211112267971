<template>
  <div>
    <div class="row pb-2">
      <div class="col-lg-12">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}#balance`">
              <span class="breadcrumb-item">{{ $t('Edit company profile')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t('Payments page') }}</span>
          </li>
        </ol>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div class="row">
          <div class="col-lg-6 my-auto">
            <div :class="tab === 'card' ? 'card border-color-light-green radius-all shadow-sm' : 'card border-color-grey radius-all'">
              <div @click="tab = 'card'" class="card-body pt-2 pb-2 pointer-cursor">
                <div class="row">
                  <div class="col-lg-4 p-0 pl-3">
                    <img class="img-fluid" src="../../../public/static/images/App/ccard.png"/>
                  </div>
                  <div class="col-lg-8 text-center text-secondary font-weight-bold h-100 my-auto">
                    {{ $t('My card') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 my-auto">
            <div :class="tab === 'payPal' ? 'card border-color-light-green radius-all shadow-sm' : 'card border-color-grey radius-all'">
              <div @click="tab = 'payPal'" class="card-body pt-1 pb-1 pointer-cursor">
                <img class="img-fluid" src="../../../public/static/images/App/paypal.png"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <div v-show="tab === 'card'" class="card border-color-light-green radius-all shadow-sm">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <ui-select
                  id="companyWorkers"
                  v-model="card.cardNumber"
                  name="card_number"
                  :validation="{required: true}"
                  :clearable="false"
                  :filterable="false"
                  :placeholder="$t('Choose a card')"
                  :label="$t('Choose a card')"
                  :options="card.cards"
                  :key-name="'key'"
                  :label-name="'value'"
                  :value-name="'key'"
                />
              </div>
              <div class="col-auto d-flex align-items-end">
                <i class="icon-credit-card h4" />
              </div>
            </div>
            <ui-number-input
              class="w-25"
              name="amount"
              v-model="card.sum"
              :disabled="disableInput"
              :validation="{required: true, decimal: 2, min_value: 1}"
              :label="$t('Sum') + ' USD'"
            />
          </div>
          <div v-if="!card.showFormCard" class="card-footer text-muted bg-transparent border-0 text-center">
            <button :disabled="disabled" type="submit" name="save" class="btn btn-success btn-circle shadow-sm" @click="submitCard">Ok</button>
          </div>
        </div>
        <div v-show="tab === 'payPal'" class="card border-color-light-green radius-all shadow-sm">
          <div class="card-body">
            <ui-number-input
              class="w-25"
              name="amount"
              v-model="payPal.sumPayPal"
              :validation="{required: true, decimal: 2, min_value: 1}"
              :label="$t('Sum') + ' USD'"
            />
          </div>
          <div class="card-footer text-muted bg-transparent border-0 text-center">
            <button :disabled="payPal.sumPayPal <= 0" type="submit" name="savePayPal" class="btn btn-success btn-circle shadow-sm" @click="submitPayPal">Ok</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
    <div v-if="card.showFormCard" class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <credit-card></credit-card>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </div>
</template>

<script>
import CreditCard from './CompanySettingsComponents/FormComponents/CreditCard'
import errorMixin from '../../mixins/mixinApiErrors'

export default {
  name: 'Subscribes',
  components: { CreditCard },
  mixins: [errorMixin],
  data () {
    return {
      tab: 'card',
      card: {
        cardNumber: '',
        sum: 0,
        cards: [
          { key: 'addCard', value: this.$i18n?.t('Add card') }
        ],
        showFormCard: false,
        disabled: false
      },
      payPal: {
        sumPayPal: 0
      }
    }
  },
  created () {
    // console.log(window.location.origin + '/pay-pal-success')
    this.$eventBus.on('addCard', this.addCard)
    if (this.companyId) {
      this.getCards()
    }
  },
  beforeUnmount () {
    this.$eventBus.off('addCard')
  },
  computed: {
    disabled () {
      return this.card.sum <= 0 || this.card.cardNumber === '' || this.card.cardNumber === 'addCard'
    },
    cardNumber () {
      return this.card.cardNumber
    },
    disableInput () {
      return (!this.card.cardNumber || this.card.cardNumber === 'addCard')
    }
  },
  watch: {
    companyId () {
      this.getCards()
    },
    cardNumber (value) {
      this.card.showFormCard = value === 'addCard'
    },
    tab (value) {
      if (value === 'payPal') {
        // console.log()
        if (this.card.cardNumber === 'addCard') this.card.cardNumber = ''
        // this.card.cardNumber = ''
        this.card.showFormCard = false
      }
      // this.errors.clear()
    }
  },
  methods: {
    submitPayPal () {
      const data = {
        amount: this.payPal.sumPayPal,
        cancel_url: window.location.href,
        return_url: window.location.origin + '/pay-pal-success'
      }
      // console.log(window.location.href, this.$route.path)
      this.$store.dispatch('setPayPalLink', [this.$store.getters.companyId, data]).then(response => {
        if (response) {
          window.open(response.redirect_url, '_blank')
          this.$router.push('/c/' + this.$store.getters.companyId + '#balance')
        }
      })
    },
    submitCard () {
      const data = {
        card_id: this.card.cardNumber,
        amount: parseInt(this.card.sum)
      }
      this.$store.dispatch('setStripeCharge', data).then(response => {
        if (response && ['succeeded', 'pending'].includes(response.status)) {
          this.$store.dispatch('clearCompanyStore')
          this.$store.dispatch('getCompany', this.$store.getters.companyId)
          this.$eventBus.emit('checkSteps')
          this.toastSuccess(this.$t('Payment is send'))
          this.$router.push('/c/' + this.$store.getters.companyId + '#balance')
        } else {
          this.toastError(this.$t('STRIPE_DECLINE_PAYMENT'))
        }
      })
    },
    getCards () {
      this.card.showFormCard = false
      this.$store.dispatch('getCards').then(response => {
        if (response) {
          response.forEach(card => {
            this.card.cards.unshift({
              key: card.id,
              value: card.brand + ' ****' + card.last4 + ' (' + card.exp_month + '/' + card.exp_year + ')'
            })
            this.card.cardNumber = card.id
          })
        }
      })
    },
    addCard (card) {
      this.card.cards.unshift({
        key: card.id,
        value: card.brand + ' ****' + card.last4 + ' (' + card.exp_month + '/' + card.exp_year + ')'
      })
      this.card.cardNumber = card.id
      this.card.showFormCard = false
    }
  }
}
</script>

<style scoped>
  .border-color-grey {
    border-color: #bababa;
  }
  .border-color-light-green {
    border-color: #32c5d2;
  }
</style>
