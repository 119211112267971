<template>
  <Form ref="observer" as="div">
    <div class="row my-1">
      <div class="col-4">
        <ui-text-input
          :name="index + '__title'"
          v-model="itemState.title"
          :label="$t('CHECKLIST_ITEM_TITLE')"
          :validation="{required: true, min: 3, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
        />
      </div>
      <div class="col-4">
        <ui-select
          :name="index + '__duration'"
          :label="$t('SLOT_DURATION')"
          v-model="itemState.duration"
          class="w-100"
          :disabled="isDisabled"
          :options="breakInterval()"
          @change="changeSlotDuration"
          :validation="{required: true}"
          :key-name="'id'"
          :value-name="'id'"
          :label-name="'name'"
          :clearable="false"
        />
      </div>
      <div class="col">
        <label class="control-label font-weight-bold mt-2">{{ $t('Billable') }}</label>
        <div>
          <ui-checkbox
            :name="index + '__billable'"
            v-model="itemState.billable"
          />
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-4">
        <ui-time-picker
            :label="$t('START_TIME')"
            :name="index + 'time_from'"
            :placeholder="$t('From')"
            :disabled="isDisabled"
            @change="changed"
            :validation="{required: true}"
            :clearable="false"
            :value-format="'HH:mm'"
            v-model="itemState.time_from"
            :picker-options="pickerOptionsFrom"
        />
      </div>
      <div class="col-4">
        <ui-time-picker
            :label="$t('END_TIME')"
            :name="index + 'time_to'"
            :placeholder="$t('To')"
            :disabled="isDisabled"
            @change="changed"
            :validation="{required: true}"
            :clearable="false"
            :value-format="'HH:mm'"
            v-model="itemState.time_to"
            :picker-options="pickerOptionsTo"
        />
      </div>
      <div class="col">
        <label class="control-label font-weight-bold mt-2">{{ $t('Delete') }}</label>
        <div>
          <i class="icon-trash btn-icon text-danger my-auto"
             @click="deleteItem()"
             role="button">
          </i>
        </div>
      </div>
    </div>
    <div v-if="calculateWorkplaces" class="row my-1">
      <div class="col">
        {{$t('WORKERS_ON_BREAK')}} {{Math.floor(workersOut)}} - {{Math.ceil(workersOut)}}
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'BreakItem',
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      timeBreak: {
        start: 0,
        end: 240,
        step: 5
      },
      isDisabled: false,
      itemState: Object
    }
  },
  props: {
    index: Number,
    item: Object,
    validateItem: Boolean,
    showItemConfig: Boolean,
    startTime: String,
    endTime: String,
    validate: Boolean,
    workplaces: Array,
    calculateWorkplaces: Boolean
  },
  created () {
    this.itemState = this.item
    // this.pickerOptions.start = this.startTime
    // this.pickerOptions.end = this.endTime
    // console.log(moment.utc().startOf('day').add(5, 'minutes').format('HH:mm'))
  },
  computed: {
    maxWorkplaces () {
      return Math.max(...this.workplaces)
    },
    workersOut () {
      const from = moment('2021-03-16 ' + this.itemState.time_from)
      const to = moment('2021-03-16 ' + this.itemState.time_to)
      if (from.isAfter(to)) {
        to.add(1, 'day')
      }
      const breakDuration = to.diff(from, 'minutes')

      return this.maxWorkplaces / (breakDuration / this.itemState.duration)
    },
    pickerOptionsFrom () {
      const from = moment('2021-03-16 ' + this.startTime)
      const to = moment('2021-03-16 ' + this.endTime)
      if (to.isAfter(from)) {
        return {
          start: this.startTime,
          step: '00:05',
          end: this.endTime
        }
      }
      return {
        start: '00:00',
        step: '00:05',
        end: '24:00'
      }
    },
    pickerOptionsTo () {
      const step = moment.utc(moment.duration(this.itemState.duration, 'minutes').asMilliseconds()).format('HH:mm')
      // минут от начала перерыва до конца дня
      const duration = moment.duration(moment('2021-03-17 00:00').diff(moment('2021-03-16 ' + this.itemState.time_from))).asMinutes()
      // шаг пикера в минутах
      const stepMinutes = moment.duration(this.itemState.duration, 'minutes').asMinutes()
      // остаток минут, некратных минутам до конца дня к шагу пикера
      const remainder = duration % stepMinutes
      let oneMoreStep = 0
      // если минуты от начала перерыва до конца дня не кратны шагу пикера, разницу нужно добавить к началу следующего дня
      if (remainder > 0) {
        oneMoreStep = stepMinutes - remainder
      }
      const start = moment('2021-03-16 ' + this.itemState.time_from).add(duration + oneMoreStep, 'minutes')
      return {
        start: start.format(this.backendTimeFormat),
        step: step,
        end: '24:00'
      }
    }
  },
  watch: {
    item () {
      this.itemState = this.item
    },
    validate (value) {
      if (value) {
        this.validateItemHandle()
      }
    },
    startTime (value) {
      if (value > this.itemState.time_from) {
        this.itemState.time_from = value
      }
    },
    endTime (value) {
      if (value < this.itemState.time_to) {
        this.itemState.time_to = value
      }
    }
  },
  methods: {
    changeSlotDuration (val) {
      const from = moment(this.itemState.time_from, this.localeTimeFormat)
      const to = moment(this.itemState.time_to, this.localeTimeFormat)
      const diff = to.diff(from, 'minutes')
      const round = Math.round(diff / val) * val
      from.add(round, 'minutes')
      this.itemState.time_to = from.format('HH:mm')
    },
    changed () {
      this.$emit('breakChanged')
    },
    breakInterval () {
      const breakData = []
      let current = this.timeBreak.start
      let i = 0
      while (current <= this.timeBreak.end) {
        if (i !== 0) {
          breakData.push({
            id: current,
            name: current + ' ' + this.$t('min')
          })
        }
        current += this.timeBreak.step
        i += 1
      }
      return breakData
    },
    deleteItem () {
      this.$emit('deleteItem')
    },
    handleClose (tag) {
      this.itemState.type_options.splice(this.itemState.type_options.indexOf(tag), 1)
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        if (!this.itemState.type_options) this.itemState.type_options = []
        this.itemState.type_options.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
    },
    typeChange (type) {
      if (!['select', 'multi_select'].includes(type) || !this.itemState.type_options) {
        this.itemState.type_options = []
      }
    },
    validateItemHandle () {
      // console.log(3)
      this.$refs.observer.validate().then(result => {
        // if (result.valid) {
        //   this.$emit('validateResult', true)
        // } else {
        //   this.$emit('validateResult', false)
        // }
      })
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
