<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="!dummy">
      <todo-form-modal
        :formData="form"
        @init="init"
      />
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("Todos") }}
          <ui-hint :content="$t('TODOS_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <div class="row mb-1">
            <div class="col-12 col-md-3 d-inline-flex">
              <ui-multi-select
                name="employees"
                :placeholder="$t('Filter employees')"
                v-model="selectedEmployees"
                :options="employees"
                class="w-100 mt-1"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :clearable="true"
              />
              <el-button
                class="mx-1 my-auto"
                type="primary"
                @click="init"
                icon="Search">
              </el-button>
            </div>
            <div class="col-12 col-md-7 d-inline-flex align-items-center">
              <ui-radio-group v-model="status" class="mt-1">
                <ui-radio-button
                  name="status_new"
                  setValue="new">
                  {{ $t('SELECT_NEW_TODOS') }}
                </ui-radio-button>
                <ui-radio-button
                  name="status_completed"
                  setValue="completed">
                  {{ $t('SELECT_COMPLETED_TODOS') }}
                </ui-radio-button>
              </ui-radio-group>
            </div>
            <div v-if="isEmployeeHasPermission('update-todo-content')"
                class="col-12 col-md-2 my-auto">
              <button
                id="buttonTodoFormModal"
                ref="TodoFormModal"
                data-toggle="modal"
                data-target="#TodoFormModal"
                aria-controls="TodoFormModal"
                class="btn btn-primary btn-sm rounded-pill float-right"
                type="button"
                @click="addTodo">
                {{ $t('Create Todo') }}
              </button>
            </div>
          </div>
          <el-table
            class="table text-break"
            header-row-class-name="thead-light"
            header-cell-class-name="text-break"
            :data="todos || []"
            style="width: 100%">
            <el-table-column
              :label="$t('Content')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="content">
            </el-table-column>
            <el-table-column
              :label="$t('Employee')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="employee">
              <template #default="scope">
                <div class="text-break">
                  {{scope.row.employee.last_name}}  {{scope.row.employee.first_name}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Deadline')"
              cell-class-name="text-break"
              label-class-name="text-break"
              width="200"
              prop="deadline_date">
              <template #default="scope">
                <div class="text-break">
                  {{ scope.row.deadline_date ? toTimeZone(scope.row.deadline_date).format(localeDateTimeFormat) : '' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Status')"
              cell-class-name="text-break"
              label-class-name="text-break"
              width="150"
              prop="status">
              <template #default="scope">
                <div class="text-break">
                  {{$t(scope.row.status)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('Created at')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="lead_time">
              <template #default="scope">
                {{ scope.row.created_at ? toTimeZone(scope.row.created_at).format(localeDateTimeFormat) : '' }}
              </template>
            </el-table-column>
            <el-table-column
              cell-class-name="text-break"
              label-class-name="text-break"
              width="100"
              align="right">
              <!--        <template slot="header"></template>-->
              <template #default="scope">
                <el-tooltip :content="$t('Edit')" placement="top" effect="light">
                  <i
                    data-toggle="modal"
                    data-target="#TodoFormModal"
                    aria-controls="TodoFormModal"
                    class="icon-pencil btn-icon text-primary mx-1"
                    @click="editTodo(scope.row)">
                  </i>
                </el-tooltip>
                <template v-if="isEmployeeHasPermission('update-todo-content') && status === 'new'">
                  <el-tooltip :content="$t('Delete')" placement="top" effect="light">
                    <i
                        class="icon-trash btn-icon text-danger mx-1"
                        @click="handleDelete(scope.row)">
                    </i>
                  </el-tooltip>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import momentMixin from '@/mixins/mixinMoment'
import mixinDummy from '@/mixins/mixinDummy'
import TodoFormModal from '@/components/Tasks/ToDo/TodoFormModal'

export default {
  components: { TodoFormModal, DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy],
  name: 'TodoListComponent',
  data () {
    return {
      dummy: false,
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      status: 'new',
      selectedEmployees: [],
      todoDialogVisible: false,
      form: {
        content: '',
        deadline_date: null,
        employee_id: null,
        status: 'new'
      }
    }
  },
  created () {
    if (this.companyId) {
      this.checkAndInitialize()
    }
  },
  computed: {
    todos () {
      return this.$store.getters.todos
    },
    employees () {
      return this.$store.getters.employees.map(employee => {
        return {
          id: employee.id,
          name: employee.full_name
        }
      })
    },
    companyId () {
      return this.$store.getters.companyId
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    status () {
      this.init()
    },
    companyId (val) {
      if (val) {
        this.checkAndInitialize()
      }
    }
  },
  methods: {
    checkAndInitialize () {
      this.$store.dispatch('isModuleActive', [this.companyId, 31]).then((e) => {
        this.init()
      }).catch(err => {
        this.$store.dispatch('getModulesByCompany')
        this.dummy = true
        if (!err.response.data.module) {
          this.dummyInfo = {}
        } else {
          this.dummyInfo = this.setupDummyData(err.response.data)
        }
      })
    },
    init (fromFirstPage = false) {
      const page = fromFirstPage ? 1 : this.pagination.currentPage
      let query = `page=${page}&status=${this.status}`
      if (this.selectedEmployees.length > 0) {
        this.selectedEmployees.forEach(id => {
          query += `&employees[]=${id}`
        })
      }
      this.$store.dispatch('getTodos', [this.companyId, query]).then((response) => {
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange () {
      this.init()
    },
    editTodo (item) {
      this.form = {
        id: item.id,
        content: item.content,
        deadline_date: item.deadline_date ? this.fromZoneToZone(item.deadline_date, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat) : item.deadline_date,
        employee_id: item.employee_id,
        status: item.status
      }
    },
    addTodo () {
      this.form = {
        content: '',
        deadline_date: null,
        employee_id: null,
        status: 'new'
      }
    },
    handleDelete (item) {
      this.$confirm(this.$t('This will permanently delete the todo. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteTodo', [this.companyId, item.id]).then((response) => {
          this.toastSuccess(this.$t('Delete completed'))
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>
<style scoped>

</style>
