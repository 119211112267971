<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/projects`">
              <span class="breadcrumb-item">{{ $t('Projects')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t("EMPLOYEE_POSITIONS") }}</span>
            <ui-hint :content="$t('EMPLOYEE_POSITIONS_HINT')" />
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col text-muted small">
            <div>
              {{ $t('Positions are roles of one or several employees on the schedule.') }}
            </div>
          </div>
        </div>
        <Form ref="observer" v-slot="{ meta }" as="div">
        <div class="row form-group">
          <div class="col-lg-3">
            <ui-text-input
              name="tag_title"
              :label="$t('Add a position')"
              v-model="tag.name"
              :validation="{required: true, max: 128}"
            />
          </div>
          <div class="col-lg-2 my-auto form-group">
            <ui-color-picker
              name="color"
              v-model="tag.color"
              :colors="colorsChoicesMarks"
              :label="$t('Choose a color:')"
            />
          </div>
          <span class="col-lg-3 mt-4">
            <button class="btn btn-success btn-circle shadow-sm" :class="{'disabled': !meta.valid}" :disabled="!meta.valid" type="button" @click="save">
              {{ $t('SAVE_WEB') }}
            </button>
          </span>
        </div>
        </Form>
        <div class="row">
          <div class="col-lg-12" v-if="tags.length > 0">
            <span class="text-uppercase font-weight-bold text-primary">{{ $t('Project positions') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <table class="table table-hover">
              <tbody>
              <template v-for="(tag, index) in tags" :key="index">
                <TagEditComponent
                  :index="index"
                  :tag="tag"
                  :projectId="projectId"
                  :colorsChoicesMarks="colorsChoicesMarks"
                >
                </TagEditComponent>
              </template>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import colors from '@/mixins/mixinColorsMarks'
import errorMixin from '@/mixins/mixinApiErrors'
import TagEditComponent from '@/components/Project/Components/TagEditComponent'

export default {
  name: 'Tags',
  mixins: [colors, errorMixin],
  components: { TagEditComponent },

  data () {
    return {
      projectId: '',
      tag: {
        name: '',
        color: '#bbbbbb',
        enabled: true
      }
    }
  },

  computed: {
    tags () {
      return this.$store.getters.projectMarks
    }
  },

  created () {
    this.projectId = this.$route.params.projectId
    this.$store.dispatch('getMarksByProject', [this.projectId])
  },

  methods: {
    save () {
      this.$refs.observer.validate().then(isValid => {
        if (isValid) {
          this.$store.dispatch('createMark', [this.tag, this.projectId]).then(() => {
            this.tag = {
              name: '',
              color: '#bbbbbb',
              enabled: true
            }
          }).catch(e => {
            this.toastError(this.$i18n?.t(e.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
