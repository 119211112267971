<template>
  <div class="row justify-content-center">
    <div class="col-md-4 card">
      <div class="card-header">
        <h5 class="text-center">{{ $t('Password recovery confirm') }}</h5>
      </div>
      <Form ref="observe" as="div">
        <div class="card-body">
          <ui-text-input
            class="mt-3"
            id="password"
            :name="'password'"
            :type="'password'"
            v-model="password"
            :validation="{required: true, password: true, min: 8}"
            :placeholder="$t('Password')"
            :prepend-icon="'icon-lock'"
            ref="password"
          />
          <ui-text-input
            class="mt-3"
            id="password_confirmation"
            :name="'password_confirmation'"
            :type="'password'"
            v-model="password_confirmation"
            :validation="{required: true, password: true, min: 8, passwordConfirm: password}"
            :placeholder="$t('Password confirm')"
            :prepend-icon="'icon-lock'"
          />
          <div class="d-flex pt-4 justify-content-center">
            <button
              type="submit"
              name="submit"
              class="btn btn-primary rounded-pill shadow-sm"
              @click="submitForm">
              {{ $t('Change password') }}
            </button>
          </div>
          <div class="d-flex pt-3 justify-content-center">
          <span>{{ $t('SignIn') }}
              <router-link :to="'/login'" class="">{{ $t('Login') }}</router-link>
            </span>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import errorMixin from '../mixins/mixinApiErrors'

export default {
  name: 'PasswordRecovery',
  mixins: [errorMixin],
  data () {
    return {
      recaptcha_key: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
      recaptchaShow: (process.env.VUE_APP_RECAPTCHA_SHOW === 'true'),
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    submitForm (event) {
      this.recovery()
      event.preventDefault()
    },
    recovery () {
      this.$refs.observe.validate().then(async result => {
        if (result.valid) {
          await this.$recaptchaLoaded()

          let token = this.sitekey
          if (!this.recaptchaShow) {
            this.recaptcha_key = token
          } else {
            token = await this.$recaptcha('passwordRecoveryConfirm')
            this.recaptcha_key = token
          }
          this.$store.dispatch('passwordRecoveryConfirm',
            {
              password: this.password,
              password_confirmation: this.password_confirmation,
              token: this.$route.params.hash,
              recaptcha_key: this.recaptcha_key
            }).then(response => {
            this.toastSuccess(this.$i18n?.t(response.success))
            this.$router.push('/login')
          }).catch(() => {})
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
