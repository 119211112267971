<template>
  <div>
    <div class="mb-2 mt-2" v-if="previewData.wrong_data.length">
      <table class="table table-hover">
        <thead>
        </thead>
        <tbody>
        <tr v-for="(row, row_key) in previewData.wrong_data" :key="row_key">
          <td v-for="(item, key) in row.row" :key="key">
            <template v-if="row.errors.wrong_employee === true && (['phone_index', 'email_index'].includes(fileRows[key]))">
              <el-tooltip :content="$t('BONUS_PENALTY_WRONG_EMPLOYEE')" placement="top" effect="light">
              <span class="text-danger">{{ item }}</span>
              </el-tooltip>
            </template>
            <template v-else-if="row.errors.wrong_date_time === true && (['date_index', 'time_from_index', 'time_to_index'].includes(fileRows[key]))">
              <el-tooltip :content="$t('BONUS_PENALTY_WRONG_DATE')" placement="top" effect="light">
              <span class="text-danger">{{ item }}</span>
              </el-tooltip>
            </template>
            <template v-else>{{ item }}</template>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="font-weight-bold text-danger mb-2 mt-2">
        {{ $t('BONUS_PENALTI_IMPORT_PREVIEW_DESCRIPTION') }}: {{ previewData.wrong_data.length }}
      </div>
    </div>
    <div v-else>
      <el-alert
          show-icon
          center
          :closable="false"
          :title="$t('BONUS_PENALTI_IMPORT_MATCHED_SHIFTS')"
          type="success">
        </el-alert>
    </div>
  </div>
</template>

<script>
import momentMixin from '../../../../../mixins/mixinMoment'

export default {
  name: 'Preview',
  mixins: [momentMixin],
  props: {
    previewData: {
      type: Object,
      default: function () {
        return { }
      }
    },
    fileRows: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },
  methods: {
    convertDate (value) {
      return this.fromZoneToZone(value, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateTimeFormat)
    },
    getProjectName (projectId) {
      const project = this.$store.getters.projectById(projectId)
      return project.name
    },
    getScheduleName (scheduleId) {
      const schedule = this.$store.getters.scheduleById(scheduleId)
      return schedule.name
    }
  }
}
</script>
