<template>
  <div>
    <schedule-pattern
      v-if="step === 2"
      :patternType="patternType"
      @next="next"
      @prev="prev">
    </schedule-pattern>

    <schedule-settings
      v-if="step === 3"
      :settings="settings"
      :project="project"
      :type="'by_pattern'"
      :patternType="patternType"
      :shiftsControl="shiftsControl"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </schedule-settings>

    <working-time-settings
      v-if="step === 4 && patternType === '5/2'"
      :workingTime="workingTime"
      :type="'advanced'"
      :shiftsControl="shiftsControl"
      :patternType="patternType"
      :minDurationWorkingMinutesDay="minDurationWorkingMinutesDay"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </working-time-settings>

    <employees
      v-if="step === (patternType === '5/2' ? 5 : 4)"
      :selectedUsers="employees"
      :type="'by_pattern'"
      :patternType="patternType"
      :project="project"
      :requiredWorkers="requiredWorkers"
      :shiftsControl="shiftsControl"
      :settings="settings"
      @save="save"
      @prev="prev">
    </employees>

    <preview-schedule
      v-if="step === (patternType === '5/2' ? 6 : 5)"
      :previewShifts="previewShifts"
      :conflictShifts="conflictShifts"
      :startDate="startDate"
      :project="project"
      @save="save"
      @prev="prev">
    </preview-schedule>
  </div>
</template>

<script>
import SchedulePattern from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SchedulePattern'
import ScheduleSettings from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/ScheduleSettings'
import WorkingTimeSettings from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/WorkingTimeSettings'
import Employees from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/Employees'
import PreviewSchedule from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/PreviewSchedule'

export default {
  name: 'ScheduleByPattern',
  components: {
    SchedulePattern: SchedulePattern,
    ScheduleSettings: ScheduleSettings,
    WorkingTimeSettings: WorkingTimeSettings,
    Employees: Employees,
    PreviewSchedule: PreviewSchedule
  },
  props: {
    step: Number,
    settings: Object,
    employees: Object,
    workingTime: Object,
    project: Number,
    previewShifts: Object,
    conflictShifts: Array,
    minDurationWorkingMinutesDay: Number,
    requiredWorkers: Number,
    startDate: [String, Date],
    patternType: String,
    schedule: Object,
    shiftsControl: Object
  },
  methods: {
    next (value) {
      // console.log(value)
      this.$emit('next', {
        step: value.step,
        data: value.data
      })
    },
    prev (value) {
      // console.log(123, value)
      this.$emit('prev', {
        step: value.step,
        data: value.data
      })
    },
    save (value) {
      // console.log('a', value)
      this.$emit('save', {
        step: value.step,
        data: value.data
      })
    }
  }
}
</script>

<style scoped>

</style>
