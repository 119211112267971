<template>
  <div>
    <div v-if="loaded" class="container-fluid p-0">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="align-middle text-center table-header text-nowrap" v-for="(item, key) in columns" v-bind:key="item.name+ '_' + key">{{item.title}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in paginationConfig.paginated" v-bind:key="index">
            <td class="text-nowrap">{{ row.full_name }}</td>
            <td class="text-nowrap">{{ getEmployeePosition(row.position_id) }}</td>
            <td>{{ row.phone ? '+' + row.phone : ''}}</td>
            <td>{{row.email}}</td>
            <td class="text-center">
              <template v-if="row['user_id']">
                <i class="icon-check-circle icon-default text-success" />
              </template>
              <template v-else>
                <i class="icon-minus-circle icon-default text-warning" />
              </template>
            </td>
            <td class="text-nowrap">{{ getRecruitmentFiringDate(row) }}</td>
            <td>{{row.address}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <pagination
          class="pl-1 pr-3"
          :rows="paginationConfig.rows"
          :pageSize="paginationConfig.pageSize"
          :currentPage="paginationConfig.currentPage"
          v-on:updatePaginated="updatePaginated"
          v-show="paginationConfig.rows && paginationConfig.rows.length > 0"
        ></pagination>
        <div class="col-auto">
          <ui-select
            name="pageSize"
            v-model="paginationConfig.pageSize"
            :options="pageSizeChoices.map(i => {
              return {id: i, title: i}
            })"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import paginationMixin from '../../CommonComponents/Pagination/mixinPagination'
import momentMixin from '@/mixins/mixinMoment'
import Pagination from '../../CommonComponents/Pagination/Pagination'
import moment from 'moment'

export default {
  mixins: [errorMixin, paginationMixin, momentMixin],
  data () {
    return {
      columns: [
        { name: 'name', title: this.$i18n?.t('Name') },
        { name: 'position', title: this.$i18n?.t('Position') },
        { name: 'phone', title: this.$i18n?.t('Phone') },
        { name: 'email', title: this.$i18n?.t('E-mail') },
        { name: 'is_invited', title: this.$i18n?.t('IS_INVITED') },
        { name: 'recruitment_firing_date', title: this.$i18n?.t('Recruitment / Firing date') },
        { name: 'address', title: this.$i18n?.t('Address') }
      ],
      rows: [],
      pageSizeChoices: [25, 50, 100, 200, 500],
      loaded: false
    }
  },
  name: 'CompanyWorkers',
  props: ['project', 'period', 'search', 'selectedPositions', 'positionsByUsers', 'showUsers', 'filterByStatus'],
  components: { pagination: Pagination },
  created () {
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    },
    companyProjects () {
      return this.$store.getters.projects
    },
    allProjectsUsers () {
      let users = []
      this.companyProjects.forEach(project => {
        users = users.concat(project.users)
      })
      return users
    }
  },
  beforeUnmount () {
    this.$eventBus.off('exportCompanyWorkersReport')
  },
  mounted () {
    this.$eventBus.on('exportCompanyWorkersReport', this.exportReport)
  },
  methods: {
    exportReport () {
      this.getTableData(1)
    },
    getEmployeePosition (positionId) {
      const position = this.$store.getters.positionById(positionId)
      if (position) {
        return position.title
      }
      return ''
    },
    getProjectById (projectId) {
      return this.$store.getters.projectById(projectId)
    },
    filteredData () {
      const self = this
      let filteredData = self.rows
      filteredData = filteredData.sort((a, b) => {
        if (a.full_name > b.full_name) return 1
        if (a.full_name < b.full_name) return -1
        return 0
      })

      // filter by name/email/phone
      filteredData = filteredData.filter((row, index) => {
        if (this.search === '') {
          return row
        }
        return (
          row.full_name.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1 ||
          (row.email && row.email.toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1) ||
          (row.phone && ('+' + row.phone).toLowerCase().indexOf(this.search.toString().toLowerCase()) > -1)
        )
      })

      // filter by positions
      filteredData = filteredData.filter((row, index) => {
        if (this.selectedPositions.length === 0) {
          return row
        }

        let find = 0
        if (this.selectedPositions.includes(row.position_id)) {
          find++
        }
        return find > 0
      })

      // filter by period
      if (this.filterByStatus !== 'ignore') {
        const start = this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat))
        const end = this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days')
        if (this.filterByStatus === 'hired') {
          filteredData = filteredData.filter((row, index) => {
            const employmentDate = this.fromZoneToZone(row.created_at, 'UTC', this.tz)
            return employmentDate.isSameOrAfter(start) && employmentDate.isBefore(end)
          })
        }
        if (this.filterByStatus === 'dismissed') {
          filteredData = filteredData.filter((row, index) => {
            if (!row.dismiss && !row.deleted_at) {
              return false
            }
            const dismissalDate = row.dismiss ? this.fromZoneToZone(row.dismiss.date_from, 'UTC', this.tz) : null
            const deletedDate = row.deleted_at ? this.fromZoneToZone(row.deleted_at, 'UTC', this.tz) : null
            const deletedAt = dismissalDate || deletedDate
            if (!deletedAt) {
              return false
            }
            return deletedAt.isSameOrAfter(start) && deletedAt.isBefore(end)
          })
        }
      }

      this.paginationConfig.rows = filteredData
    },
    getRecruitmentFiringDate (row) {
      const dismissalDate = row.dismiss ? this.fromZoneToZone(row.dismiss.date_from, 'UTC', this.tz).format(this.localeDateTimeFormat) : ''
      const deletedDate = row.deleted_at ? this.fromZoneToZone(row.deleted_at, 'UTC', this.tz).format(this.localeDateTimeFormat) : ''
      const employmentDate = this.fromZoneToZone(row.created_at, 'UTC', this.tz).format(this.localeDateTimeFormat)
      const deletedAt = dismissalDate !== '' ? dismissalDate : deletedDate

      return employmentDate + ' / ' + deletedAt
    },
    getTableData (exportReport = 0) {
      this.loaded = false
      const from = this.fromZoneToZone(this.period[0], this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(this.period[1], this.$store.getters.profileTimeZone, 'UTC').add(1, 'day').format(this.backendDateTimeFormat)
      let data = '?project=' + this.project + '&export=' + exportReport +
        '&date_filter_type=' + this.filterByStatus + '&date_from=' + from + '&date_to=' + to
      if (this.selectedPositions.length > 0) {
        data += '&positions[]=' + this.selectedPositions.join('&positions[]=')
      }
      data += '&show_users=' + this.showUsers

      this.$store.dispatch('getCompanyWorkers', [this.$store.getters.companyId, data, exportReport]).then((response) => {
        this.loaded = true
        this.$eventBus.emit('dummy', false)
        if (exportReport) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_project_workers')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.$eventBus.emit('dummy', true)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    setTable (response) {
      this.rows = []
      Object.keys(response).forEach(key => {
        this.rows.push(response[key])
      })

      this.filteredData()
    }
  },
  watch: {
    project: function () {
      this.getTableData()
    },
    search: function () {
      this.filteredData()
    },
    showUsers: function () {
      this.getTableData()
    },
    selectedPositions () {
      this.filteredData()
    },
    filterByStatus () {
      this.filteredData()
    },
    period () {
      this.filteredData()
    }
  }
}
</script>

<style>
  th {
    text-align: center;
  }
</style>
