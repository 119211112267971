<template>
  <div class="w-100 px-1 d-flex align-items-center" style="min-height: 55px">
    <div class="row px-2">
      <div class="col-auto px-1 text-danger font-weight-bold text-left">
        <el-tooltip :content="translate.t('Shifts')" placement="top" effect="light">
          <i class="icon-calendar mr-1 text-secondary" style="font-size: 1.2em"></i>
        </el-tooltip>
        <span>
        {{ shifts }}
      </span>
      </div>
      <div class="col-auto px-1 text-danger font-weight-bold text-left">
        <el-tooltip :content="translate.t('Hours total')" placement="top" effect="light">
          <i class="icon-clock mr-1 text-secondary" style="font-size: 1.2em"></i>
        </el-tooltip>
        <span>
          {{hrsForHuman(hours + Number.EPSILON)}}
      </span>
      </div>
      <div class="col-auto px-1 text-danger font-weight-bold text-left">
        <el-tooltip :content="translate.t('Break, hours')" placement="top" effect="light">
          <i class="icon-coffee mr-1 text-secondary" style="font-size: 1.2em"></i>
        </el-tooltip>
        <span>
          {{hrsForHuman(breakTime + Number.EPSILON)}}
      </span>
      </div>
      <div v-if="view.type === 'resourceTimelineDay'" class="col-auto px-1 text-danger font-weight-bold text-left">
        <el-tooltip :content="translate.t('USERS_TOTAL')" placement="top" effect="light">
          <i class="icon-users mr-1 text-secondary" style="font-size: 1.2em"></i>
        </el-tooltip>
        <span>
        {{ users }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'InfoResourceComponent',
  mixins: [momentMixin],
  data () {
    return {
      shifts: 0,
      hours: 0,
      breakTime: 0,
      users: 0
      // currency: this.$store.getters.currency
    }
  },
  props: { basicRate: Number, payBreaks: Number, translate: Object },
  created () {
    this.$eventBus.on('updateStatistic', this.updateStatistic)
  },
  beforeUnmount () {
    this.$eventBus.off('updateStatistic')
  },
  computed: {
    infoEvents () {
      return this.$store.getters.infoEvents
    },
    view () {
      return this.$store.getters.view
    },
    title () {
      // tmp
      return this.translate.t('Info')
    },
    cost () {
      return (this.payBreaks === true) ? this.hours * this.basicRate : (this.hours - this.breakTime) * this.basicRate
    }
  },
  watch: {
    infoEvents () {
      this.updateStatistic()
    }
  },
  methods: {
    updateStatistic () {
      this.hours = 0
      this.shifts = 0
      this.users = 0
      this.breakTime = 0

      const viewStart = moment(this.view.currentStart).format(this.backendDateTimeFormat)
      const viewEnd = moment(this.view.currentEnd).format(this.backendDateTimeFormat)

      this.infoEvents.forEach(infoEvent => {
        if (this.isOverlap(infoEvent.start, infoEvent.end, viewStart, viewEnd)) {
          this.hours += infoEvent.statistic.minutes ? infoEvent.statistic.minutes : 0
          this.shifts += infoEvent.statistic.shifts ? infoEvent.statistic.shifts : 0
          this.users += infoEvent.statistic.users ? infoEvent.statistic.users : 0
          this.breakTime += infoEvent.statistic.breakTimeMinutes ? infoEvent.statistic.breakTimeMinutes : 0
        }
      })
      this.hours = Math.round((this.hours / 60) * 100) / 100
      this.breakTime = Math.round((this.breakTime / 60) * 100) / 100
    }
  }
}
</script>

<style scoped>

</style>
