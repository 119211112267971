<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage }"
    :rules="validation"
    as="div"
  >
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required" />
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
    <vue-tel-input
      :model-value="value"
      v-bind="{field, ...bindPhoneProps}"
      :name="name"
      :id="inputId"
      @validate="phoneValidation"
      :class="{'is-invalid': !validPhone}"
      @input="$emit('update:modelValue', innerValue)"
      @change="$emit('update:modelValue', innerValue)"
      @blur="$emit('update:modelValue', innerValue)"
      :data-cy="dataCy ? dataCy : null"
    />
    </el-form-item>
  </Field>
</template>

<script>
export default {
  name: 'tel-input',

  emits: ['update:modelValue', 'isPhoneValid'],
  expose: ['clearInvalid'],
  props: {
    name: String,
    validation: Object,
    value: String,
    modelValue: String,
    label: String,
    hint: String,
    dataCy: String,
    inputId: String,
    defaultCountry: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      isInit: true,
      innerValue: null,
      validPhone: true,
      // bindPhoneProps2: {
      //   defaultCountry: 'US',
      //   disabledFetchingCountry: false,
      //   disabled: false,
      //   disabledFormatting: false,
      //   placeholder: this.$i18n?.t('Enter a phone number'),
      //   required: false,
      //   enabledCountryCode: false,
      //   enabledFlags: true,
      //   preferredCountries: ['US', 'UA', 'RU', 'IL', 'DE', 'PL', 'ES', 'EL'],
      //   onlyCountries: [],
      //   ignoredCountries: [],
      //   autocomplete: 'off',
      //   maxLen: 20,
      //   wrapperClasses: 'form-control rounded',
      //   inputClasses: '',
      //   dropdownOptions: {
      //     disabledDialCode: false
      //   },
      //   inputOptions: {
      //     showDialCode: true
      //   },
      //   validCharactersOnly: true,
      //   projectId: null
      // },
      bindPhoneProps: {
        defaultCountry: this.defaultCountry || 'US',
        preferredCountries: ['US', 'UA', 'IL', 'DE', 'PL', 'ES', 'EL'],
        styleClasses: 'form-control rounded',
        inputOptions: {
          showDialCode: true
        },
        validCharactersOnly: true
        // dropdownOptions: {
        //   disabled: false,
        //   showDialCodeInList: true,
        //   showDialCodeInSelection: false,
        //   showFlags: true,
        //   showSearchBox: true,
        //   searchBoxPlaceholder: '',
        //   tabindex: 0
        // },
        // placeholder: this.$t('Enter a phone number'),
        // inputOptions: {
        //   showDialCode: true
        // },
        // validCharactersOnly: true
      }
    }
  },

  created () {
    this.innerValue = this.modelValue
  },

  watch: {
    modelValue (val) {
      this.innerValue = val
    }
  },
  methods: {
    clearInvalid () {
      this.isInit = true
      this.validPhone = true
    },
    phoneValidation (val) {
      if (this.isInit) {
        this.validPhone = true
        this.isInit = false
      } else {
        this.validPhone = val.valid
        this.$emit('isPhoneValid', val.valid)
      }
    }
  }

}
</script>
<style>
.vue-tel-input.form-control.rounded {
  display: flex!important;
}
</style>
