import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_POSITIONS_LIST = 'UPDATE_POSITIONS_LIST'
const UPDATE_POSITION = 'UPDATE_POSITION'
const DELETE_POSITION = 'DELETE_POSITION'

const state = {
  positionsIndex: {}
}

const getters = {
  positions: state => Object.values(state.positionsIndex),
  positionsIndexed: state => state.positionsIndex,
  positionsCount: state => {
    return Object.values(state.positionsIndex).length
  },
  hasPosition: (state, getters) => {
    return getters.positionsCount > 0
  },
  positionById: (state) => (id) => {
    return state.positionsIndex[id]
  }
}

const mutations = {
  [UPDATE_POSITIONS_LIST] (state, payload) {
    Object.values(state.positionsIndex).forEach((item) => {
      delete state.positionsIndex[item.id]
    })
    payload.forEach(item => {
      state.positionsIndex[item.id] = item
    })
  },
  [UPDATE_POSITION] (state, position) {
    state.positionsIndex[position.id] = position
  },
  [DELETE_POSITION] (state, positionId) {
    delete state.positionsIndex[positionId]
  }
}

const actions = {
  clearPositionsCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getPositions ({ commit }, companyId) {
    const promiseKey = '/companies/' + companyId + '/positions'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.positionsIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/positions').then(positions => {
        commit(UPDATE_POSITIONS_LIST, positions)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(positions)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getPosition ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/positions/${id}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  createPosition ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/positions`, payload).then(position => {
        commit(UPDATE_POSITION, position)
        resolve(position)
      }).catch(err => {
        reject(err)
      })
    })
  },

  editPosition ({ commit }, [companyId, positionId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/positions/${positionId}`, payload).then(position => {
        commit(UPDATE_POSITION, position)
        resolve(position)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deletePosition ({ commit }, [companyId, positionId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/positions/${positionId}`).then(response => {
        commit(DELETE_POSITION, positionId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  attachPositions ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/employees/${employeeId}/positions`, payload).then(response => {
        // console.log(response)
        commit('UPDATE_EMPLOYEE', response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  batchAttachPositions ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/companies/' + companyId + '/employees/batch_positions', payload)
        .then(response => {
          console.log(response)
          response.forEach(employee => {
            commit('UPDATE_EMPLOYEE', employee)
          })
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
