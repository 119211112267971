<template>
  <el-dialog
    key="showMobileAppModal"
    v-model:visible="showMobileAppModal"
    :show-close="false"
    @close="handleClose"
    width="80%">
    <div style="max-height: 300px; overflow:auto; background-color: white">
      <div class="text-center mt-2">
        <div class="mb-2 text-center text-wrap text-break">{{$t('MOBILE_APP_TITLE')}}</div>
        <div class="my-2 text-center text-wrap text-break">{{$t('MOBILE_APP_WELCOME')}}</div>
        <a v-if="showGoogle" href="https://play.google.com/store/apps/details?id=com.shifton.work" target="_blank">
          <img style="width: 225px; vertical-align: middle" src="@/assets/images/google-play-badge.png" alt="">
        </a>
        <a v-if="showIOS" href="https://apps.apple.com/app/shifton/id1510772762" target="_blank">
          <img style="width: 180px; vertical-align: middle" src="@/assets/images/App_Store.svg" alt="">
        </a>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="() => {this.showMobileAppModal = false}">{{$t('REMIND_ME_LATER')}}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import {
  LAST_SEEN_DATE_MOBILE
} from '@/store/mutation-types.js'

export default {
  name: 'MobileAppModal',
  mixins: [momentMixin],
  data () {
    return {
      showMobileAppModal: false
    }
  },
  computed: {
    lastSeenDateMobileApp () {
      return this.$store.getters.lastSeenDateMobileApp
    },
    mobilePlatform () {
      return this.getOS === 'Android' || this.getOS === 'iOS'
    },
    showGoogle () {
      // return true
      return this.getOS === 'Android'
    },
    showIOS () {
      // return true
      return this.getOS === 'iOS'
    }
  },
  created () {
    const storedDate = localStorage.getItem('last_seen_date')
    this.$store.commit(LAST_SEEN_DATE_MOBILE, storedDate)
    this.interval = setInterval(() => {
      // console.log('interval')
      // todo
      if (moment() > moment(this.lastSeenDateMobileApp).add(7, 'days')) {
        this.showMobileAppModal = true
        return
      }
      // todo
      this.showMobileAppModal = false
    }, 60 * 60 * 1000)
  },
  mounted () {
    if (!this.lastSeenDateMobileApp) {
      this.showMobileAppModal = true
    }
  },
  beforeUnmount () {
    clearInterval(this.interval)
  },
  methods: {
    handleClose () {
      const date = moment().format(this.backendDateTimeFormat)
      localStorage.setItem('last_seen_date', date)
      this.$store.commit(LAST_SEEN_DATE_MOBILE, date)
      this.showMobileAppModal = false
    }
  }
}
</script>

<style scoped>

</style>
