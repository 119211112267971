<template>
  <modal-component :id="'RemoveUserModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('Remove dismiss') }}</h5>
    </template>
    <template #body>
      <div>
        <div class="text-center mb-2">
          <span class="icon-user-x display-4"></span>
        </div>
        <div class="text-center">
          <label>
            <span class="font-weight-bold"> {{ dismissUser.full_name }}</span>
          </label>
          <label>
            {{ $t('cancellation of the user dismissal from the company.') }}
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="text-center">
          <button type="button" class="btn btn-success rounded-pill shadow-sm mr-2" data-dismiss="modal" aria-label="Close" @click="deleteUser">
            <span aria-hidden="true">{{ $t('Accept')}}</span>
          </button>
          <button type="button" class="btn btn-outline-secondary rounded-pill shadow-sm ml-2" data-dismiss="modal" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">{{ $t('Cancel')}}</span>
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'RemoveDismissUserModal',
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      dateFrom: null
    }
  },
  props: {
    dismissUser: null
  },
  methods: {
    closeModal () {
      this.$parent.$emit('closeRemoveDismissUserModal')
    },
    deleteUser () {
      this.$store.dispatch('removeDismissUser', [this.$store.getters.companyId, this.dismissUser.id]).then(response => {
        this.$eventBus.emit('removeDismissUserResult', this.dismissUser.id)
        this.toastSuccess(this.$t('User dismiss has been successfully removed.'))
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
