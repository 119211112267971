<template>
  <modal-component :id="'ClickShiftActivityModal'" :modalClass="'modal-lg'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div">
          <div v-if="!activity.id" class="row">
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Template')"
                :name="'activity_template'"
                v-model="activity.template_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="activityTemplatesOptions"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
                :validation="{required: false}"
                @change="handleTemplateChange"
              />
            </div>
            <div class="col-6 form-group text-left">
              <ui-number-input
                :label="$t('ACTIVITIES_COUNT')"
                :hint="$t('ACTIVITIES_COUNT_HINT')"
                class="w-100"
                name="count"
                :precision="0"
                :step="1"
                :min="1"
                v-model="activity.count"
                :validation="{required: !activity.id, min_value: 1, integer: true}"
              />
            </div>
          </div>
          <div class="row">
            <!--      title-->
            <div class="col-6 form-group text-left">
              <ui-text-input
                :name="'activity_title'"
                v-model="activity.title"
                :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
                :label="$t('ACTIVITY_LABEL')"
              />
            </div>
            <div class="col-6 form-group text-left">
              <!--      color-->
              <ui-color-picker
                name="color"
                v-model="activity.color"
                :colors="colorsChoices"
                :label="$t('Choose a color:')"
              />
            </div>
          </div>
          <div v-if="!activity.id" class="row">
            <div class="col-lg-6 form-group text-left">
              <!--      time_from-->
              <ui-time-picker
                :label="$t('Time from')"
                :name="'time_from'"
                :placeholder="$t('From')"
                :validation="{required: true}"
                :value-format="'HH:mm'"
                v-model="activity.time_from"
                :clearable="false"
              />
            </div>
            <div class="col-lg-6 form-group text-left">
              <!--      time_to-->
              <ui-time-picker
                :label="$t('Time to')"
                :name="'time_to'"
                :placeholder="$t('To')"
                :validation="{required: true}"
                :value-format="'HH:mm'"
                v-model="activity.time_to"
                :clearable="false"
              />
            </div>
          </div>
          <div v-if="activity.id" class="row">
            <div class="col-lg-6 form-group text-left">
              <!--      time_from-->
              <ui-datetime-picker
                :label="$t('Time from')"
                :name="'time_from_1'"
                :placeholder="$t('From')"
                :validation="{required: true}"
                v-model="activity.time_from"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </div>
            <div class="col-lg-6 form-group text-left">
              <!--      time_to-->
              <ui-datetime-picker
                :label="$t('Time to')"
                :name="'time_to_1'"
                :placeholder="$t('To')"
                :validation="{required: true}"
                v-model="activity.time_to"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </div>
          </div>
          <dates-picker-component
            v-if="!activity.id"
            :dateToLimit="null"
            :dateFromLimit="null"
            :shiftAddDate="initFrom"
            :shiftAddEndDate="initTo"
            @onChangeDays="onChangeDays"
            @onChangeDates="onChangeDates"
            @onChangeActiveCollapse="onChangeActiveCollapse"
            @onChangeShiftDateFrom="onChangeShiftDateFrom"
            @onChangeShiftDateTo="onChangeShiftDateTo"
          />
          <!--      project_id-->
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Project')"
                :name="'project'"
                v-model="activity.project_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="projects"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
                @change="handleProjectChange"
              />
            </div>
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Schedule')"
                :name="'schedule'"
                v-model="activity.schedule_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="schedules"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
              />
            </div>
          </div>
          <!--      location_id-->
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Location')"
                :name="'location'"
                v-model="activity.location_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="locations"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
              />
            </div>
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Position')"
                :name="'position'"
                v-model="activity.position_id"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="positions"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
              />
            </div>
          </div>
          <!--      marks-->
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-multi-select
                name="marks"
                :label="$t('Marks')"
                v-model="activity.marks"
                :clearable="true"
                class="w-100"
                :options="marks"
                keyName="id"
                valueName="id"
                labelName="title"
              />
            </div>
            <div class="col-6 form-group text-left">
              <ui-textarea-input
                name="note"
                :label="$t('Notes')"
                v-model="activity.note"
                :validation="{required: false, max: 256}"
                :rows="1"
              />
            </div>
          </div>
          <!-- Skills -->
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-multi-select
                name="skills"
                :label="$t('Skills')"
                v-model="activity.skills"
                :clearable="true"
                class="w-100"
                :options="skills"
                keyName="id"
                valueName="id"
                labelName="title"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 p-1">
              <div class="text-center">
                <button
                  v-if="activity.id"
                  class="btn btn-danger btn-sm rounded-pill shadow-sm mx-1"
                  @click="deleteActivity">
                  {{ $t('Delete') }}
                </button>
                <button
                  class="btn btn-primary btn-sm rounded-pill shadow-sm mx-1"
                  @click="create">
                  {{ $t('SAVE_WEB') }}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import ModalComponent from '@/components/CommonComponents/ModalComponent.vue'
import mixinColors from '@/mixins/mixinColors'
import DatesPickerComponent from '@/components/Schedule/CompanyScheduleComponents/Components/DatesPickerComponent.vue'
import moment from 'moment'

export default {
  name: 'ShiftActivityModal',
  mixins: [errorMixin, momentMixin, mixinColors, ToastMixin],
  components: {
    DatesPickerComponent,
    ModalComponent
  },
  data () {
    return {
      initFrom: moment().startOf('isoWeek'),
      initTo: moment().endOf('isoWeek'),
      activity: {
        id: null,
        title: null,
        template_id: null,
        time_from: '09:00',
        time_to: '17:00',
        date_from: null,
        date_to: null,
        location_id: null,
        position_id: null,
        project_id: null,
        schedule_id: null,
        marks: [],
        skills: [],
        count: 1,
        note: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: [],
        type: 'period',
        color: '#4e93e9',
        time_zone: null
      },
      pickerOptions: {
        firstDayOfWeek: this.$store.getters.company && this.$store.getters.company.salary ? +this.$store.getters.company.salary.week_start + 1 : null
      }
    }
  },
  props: { title: String, shiftActivityModalData: Object, initStart: Object, initEnd: Object },
  created () {
    this.activity.time_zone = this.tz
    this.initFrom = this.initStart
    this.initTo = this.initEnd
    this.activity = { ...this.shiftActivityModalData }
    this.activity.marks = this.activity.marks.map(mark => mark.id)
    this.$store.dispatch('getSkills', this.companyId)
    if (this.activity.id) {
      this.activity.time_from = this.fromZoneToZone(this.activity.time_from, 'UTC', this.tz)
      this.activity.time_to = this.fromZoneToZone(this.activity.time_to, 'UTC', this.tz)
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    },
    activityTemplates () {
      return this.$store.getters.activityTemplates
    },
    activityTemplatesOptions () {
      return this.$store.getters.activityTemplates.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    projects () {
      // console.log(1, this.$store.getters.projects)
      return this.$store.getters.projects.map(item => {
        return {
          id: item.id,
          title: item.name
        }
      })
    },
    schedules () {
      const schedules = this.$store.getters.schedules
      // console.log(2, schedules)
      if (this.activity.project_id) {
        return schedules.filter(schedule => schedule.project_id === this.activity.project_id).map(item => {
          return {
            id: item.id,
            title: item.name
          }
        })
      }
      return schedules.map(item => {
        return {
          id: item.id,
          title: item.name
        }
      })
    },
    locations () {
      return this.$store.getters.locations.filter(location => location.hide === 0).map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    positions () {
      return this.$store.getters.positions.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    skills () {
      return this.$store.getters.skills.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    marks () {
      const marks = this.$store.getters.companyMarks
      if (this.activity.project_id) {
        return marks.filter(mark => mark.project_id === this.activity.project_id).map(item => {
          return {
            id: item.id,
            title: item.name
          }
        })
      }
      return marks.map(item => {
        return {
          id: item.id,
          title: item.name
        }
      })
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeShiftActivityModal')
    },
    onChangeDates (dates) {
      this.activity.dates = []
      dates.forEach(item => {
        this.activity.dates.push(moment(item.id, this.backendDateFormat).format(this.backendDateTimeFormat))
      })
    },
    onChangeDays (days) {
      this.activity.days = days
    },
    onChangeActiveCollapse (val) {
      this.activity.type = val
    },
    onChangeShiftDateFrom (val) {
      this.activity.date_from = moment(val).format(this.backendDateFormat)
    },
    onChangeShiftDateTo (val) {
      this.activity.date_to = moment(val).format(this.backendDateFormat)
    },
    handleTemplateChange (val) {
      if (val) {
        const template = this.activityTemplates.filter(template => template.id === val)[0]
        if (template) {
          this.activity.title = template.title
          this.activity.template_id = template.template_id
          this.activity.time_from = template.time_from
          this.activity.time_to = template.time_to
          this.activity.location_id = template.location_id
          this.activity.position_id = template.position_id
          this.activity.project_id = template.project_id
          this.activity.schedule_id = template.schedule_id
          this.activity.marks = template.marks
          this.activity.skills = template.skills
          this.activity.color = template.color
        }
      }
      this.activity.template_id = val
    },
    handleProjectChange () {
      this.activity.schedule_id = null
      this.activity.marks = []
    },
    create () {
      // console.log(123, this.activity)
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const payload = { ...this.activity }
          payload.schedule_id = payload.schedule_id === '' ? null : payload.schedule_id
          payload.project_id = payload.project_id === '' ? null : payload.project_id
          if (this.activity.id) {
            payload.time_from = this.fromZoneToZone(payload.time_from, this.tz, 'UTC').format(this.backendDateTimeFormat)
            payload.time_to = this.fromZoneToZone(payload.time_to, this.tz, 'UTC').format(this.backendDateTimeFormat)
            this.$store.dispatch('patchShiftActivities', [this.companyId, payload]).then(() => {
              this.$emit('updateActivity')
              this.toastSuccess(this.$i18n?.t('ACTIVITY_UPDATED'))
              document.getElementById('modalCloseButton').click()
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          } else {
            if (payload.time_from) {
              payload.time_from = moment(payload.time_from, 'HH:mm:ss').format('HH:mm')
            }
            if (payload.time_to) {
              payload.time_to = moment(payload.time_to, 'HH:mm:ss').format('HH:mm')
            }
            const start = moment(payload.date_from, this.backendDateFormat).format(this.backendDateTimeFormat)
            const end = moment(payload.date_to, this.backendDateFormat).add(1, 'day').format(this.backendDateTimeFormat)
            payload.date_from = this.fromZoneToZone(start, this.tz, 'UTC').format(this.backendDateTimeFormat)
            payload.date_to = this.fromZoneToZone(end, this.tz, 'UTC').format(this.backendDateTimeFormat)
            this.$store.dispatch('postShiftActivities', [this.companyId, payload]).then(() => {
              this.$emit('updateActivity')
              this.toastSuccess(this.$i18n?.t('ACTIVITY_CREATED'))
              document.getElementById('modalCloseButton').click()
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          }
        }
      })
    },
    deleteActivity () {
      this.$confirm(this.$t('CONFIRM_SHIFT_ACTIVITY_DELETE_MESSAGE'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteActivity', [this.companyId, this.activity.id]).then(_ => {
          this.$emit('updateActivity')
          this.toastSuccess(this.$t('Delete completed'))
          document.getElementById('modalCloseButton').click()
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>
<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
