<template>
  <div class="row mt-2">
    <div class="col-lg-12">
      <ui-select
        name="name"
        v-model="id"
        :options="projectChoices"
        :key-name="'id'"
        :label-name="'name'"
        :value-name="'id'"
        :filterable="true"
        @change="projectChanged"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Projects',

  data () {
    return {
      id: ''
    }
  },
  created () {
    this.id = this.currentProjectId
  },
  computed: {
    currentProjectId () {
      return +this.$store.getters.currentProjectId
    },
    projectChoices () {
      const projects = this.$store.getters.projects
      // console.log(45, projects)
      return projects.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    }
  },
  watch: {
    currentProjectId () {
      this.id = this.currentProjectId
    }
  },
  methods: {
    projectChanged (id) {
      this.$store.commit('PROJECT_IS_CHANGING', true)
      this.$store.dispatch('updateCurrentProjectId', +id)
      history.pushState(
        {},
        null,
        `/c/${this.companyId}/p/${id}/schedule`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
