<template>
  <Field :name="name" v-model="innerValue" v-slot="{ errors }" :rules="validation" as="div">
    <ui-input-label v-if="label" :label="label" :hint="hint"/>
    <el-date-picker
      :name="name"
      v-model="innerValue"
      type="monthrange"
      align="right"
      @input="$emit('update:modelValue', innerValue)"
      @change="$emit('update:modelValue', innerValue)"
      @blur="$emit('update:modelValue', innerValue)"
      size="default"
      unlink-panels
      range-separator="-"
      prefix-icon="disabled"
      :start-placeholder="$t('Start month')"
      :end-placeholder="$t('End month')"
      :picker-options="pickerOptions"
      :clearable="!!clearable"
      :disabled="!!disabled"
      :class="{'is-invalid': errors.length}"
      >
    </el-date-picker>
    <span v-show="errors.length" class="invalid-feedback">{{ errors[0] }}</span>
  </Field>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'month-range-picker',
  mixins: [momentMixin],
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: [String, Object],
    value: [Array],
    modelValue: [Array],
    clearable: Boolean,
    disabled: Boolean,
    label: String,
    hint: String,
    pickerOptions: { type: Object, default () { return {} } }
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }

}
</script>

<style scoped lang="scss">

</style>
