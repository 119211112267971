<template>
  <modal-component
    :id="'CalculatorModal'"
    :modalClass="'modal-xl'"
    @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="CalculatorModalTitle">{{ $t('CALCULATOR_MODAL_TITLE')}}</h5>
    </template>
    <template #body>
      <div>
        <div class="row my-0 py-0">
          <div class="col-12 my-0 py-0">
            <div class="mb-1">
              <ui-select
                :filterable="false"
                name="SELECT_STEP"
                class="w-50"
                v-model="step"
                :label="$t('SELECT_STEP')"
                :options="stepOptions"
                :key-name="'value'"
                :value-name="'value'"
                :label-name="'label'"
                :clearable="false"
              />
            </div>
            <table class="table table-hover table-responsive table-sm">
              <thead>
              <tr>
                <th class="text-left">{{ $t("TEMPLATE_TITLE") }}</th>
                <th class="text-center" style="max-width: 100px">{{ $t("TEMPLATE_TIME") }}</th>
                <th class="text-center" v-for="day in days" :key="day.short">
                  {{ $t(day.short) }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(template, t_index) in templates" :key="t_index + '__tmpl'">
                <td class="text-left align-middle">
                <span class="">
                  {{template.name}}
                </span>
                </td>
                <td class="text-center align-middle">{{template.time_from}} - {{template.time_to}}</td>
                <td class="text-center num-input"
                    v-for="(workplaces, index) in template.workplaces"
                    :key="'tmpl_wrkpl_' + index"
                    style="max-width: 100px"
                >
                  <ui-number-input
                    :name="index + '__count'"
                    v-model="template.workplaces[index]"
                    :disabled="!days[index].selected"
                    :precision="0"
                    :step="1"
                    :min="1"
                    :size="'small'"
                    :controls="false"
                    :validation="{required: true, integer: true, min_value: 1}"
                    @change="(val) => {
                    if (typeof val === 'undefined') {
                      template.workplaces[index] = 0
                      // console.log(123)
                    }
                    template.by_days = true
                    calculateWorkplaces()
                  }"
                  />
                </td>
              </tr>
              <tr v-for="(template, t_index) in preloadedTemplatesState" :key="t_index + '__pr_tmpl'">
                <td class="text-left align-middle">
                <span class="">
                  {{template.name}}
                </span>
                </td>
                <td class="text-center align-middle">{{template.time_from}} - {{template.time_to}}</td>
                <td class="text-center num-input"
                    v-for="(workplaces, index) in template.workplaces"
                    :key="'tmpl_wrkpl_' + index">
                  <ui-number-input
                    :name="index + '__count1'"
                    v-model="template.workplaces[index]"
                    :disabled="!days[index].selected"
                    :precision="0"
                    :step="1"
                    :min="0"
                    :controls="false"
                    :validation="{required: true, integer: true, min_value: 0}"
                    @change="(val) => {
                    handleInput(val, index, t_index)
                  }"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row my-0 py-0">
          <div class="col-12 my-0 py-0">
            <table class="table table-hover table-striped table-sm table-bordered">
              <thead>
              <tr>
                <th class="text-center" style="max-width: 100px">{{ $t("CALCULATED_TIME") }}</th>
                <th class="text-center" v-for="day in days" :key="day.short">
                  {{ $t(day.short) }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(rowTitle) in Object.keys(calculatedValues)" :key="rowTitle">
                <td class="text-left align-middle">
                  {{rowTitle}}
                </td>
                <td v-for="(val, index) in calculatedValues[rowTitle]" :key="rowTitle + '_' + index">
                  {{val}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm"  data-dismiss="modal" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('Ok')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
export default {
  name: 'CalculatorModalComponent',
  components: { ModalComponent },
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      calculatedValues: {},
      step: 1,
      stepOptions: [
        {
          value: 0.5,
          label: 0.5 * 60 + ' ' + this.$t('min')
        },
        {
          value: 1,
          label: 1 * 60 + ' ' + this.$t('min')
        }
      ],
      preloadedTemplatesState: []
    }
  },
  props: { templates: Array, preloadedTemplates: Array, days: Array },
  created () {
    this.preloadedTemplatesState = this.preloadedTemplates
    this.handleWorkplaces()
    this.createValues()
    this.calculateWorkplaces()
  },
  watch: {
    preloadedTemplates () {
      this.preloadedTemplatesState = this.preloadedTemplates
    },
    step () {
      this.calculateWorkplaces()
    },
    templates () {
      this.handleWorkplaces()
      this.createValues()
      this.calculateWorkplaces()
    },
    preloadedTemplatesState () {
      this.handleWorkplaces()
      this.createValues()
      this.calculateWorkplaces()
    }
  },
  methods: {
    handleInput (val, index, tIndex) {
      let value
      if (typeof val === 'undefined') {
        value = 0
        // template.workplaces[index] = 0
      } else {
        value = val
      }
      this.preloadedTemplatesState[tIndex].by_days = true
      this.preloadedTemplatesState[tIndex].pivot.by_days = true
      if (this.preloadedTemplatesState[tIndex].pivot.workplaces) {
        this.preloadedTemplatesState[tIndex].pivot.workplaces[index] = value
      } else {
        this.preloadedTemplatesState[tIndex].pivot.workplaces = []
        this.preloadedTemplatesState[tIndex].pivot.workplaces[index] = value
      }
      this.calculateWorkplaces()
    },
    handleWorkplaces () {
      this.templates.forEach(template => {
        // console.log(11, template)
        template.workplaces.forEach((workplace, index) => {
          if (!this.days[index].selected) {
            template.workplaces[index] = 0
          }
        })
      })
      this.preloadedTemplatesState.forEach(template => {
        // console.log(12, template)
        template.workplaces.forEach((workplace, index) => {
          if (!this.days[index].selected) {
            template.workplaces[index] = 0
          }
        })
      })
    },
    createValues () {
      // console.log(this.step)
      const range = moment().startOf('day')
      const calculatedValues = {}
      for (let i = 0; i <= 24; i += this.step) {
        calculatedValues[range.format(this.backendTimeFormat)] = [0, 0, 0, 0, 0, 0, 0]
        range.add(this.step, 'hour')
      }
      this.calculatedValues = calculatedValues
      // console.log(4, this.calculatedValues)
    },
    calculateWorkplaces () {
      this.createValues()
      this.preloadedTemplatesState.forEach(template => {
        this.handleTemplateCalculation(template)
      })
      this.templates.forEach(template => {
        this.handleTemplateCalculation(template)
      })
      // console.log(3, this.calculatedValues)
      const calculatedValues = {}
      Object.keys(this.calculatedValues).forEach(key => {
        // console.log(key)
        const filtered = this.calculatedValues[key].filter(item => item > 0).length
        // console.log(filtered)
        if (filtered > 0) {
          calculatedValues[key] = this.calculatedValues[key]
        }
      })
      this.calculatedValues = calculatedValues
      // console.log(7, this.calculatedValues)
    },
    handleTemplateCalculation (template) {
      const timeFrom = moment(moment().format(this.backendDateFormat) + ' ' + template.time_from)
      const timeTo = moment(moment().format(this.backendDateFormat) + ' ' + template.time_to)
      if (timeTo.isAfter(timeFrom)) { // нормальные смены
        while (timeFrom.isBefore(timeTo)) {
          template.workplaces.forEach((workplace, index) => {
            let key = null
            if (this.step === 1) {
              key = timeFrom.clone().startOf('hour').format(this.backendTimeFormat)
            }
            if (this.step === 0.5) {
              const diff = timeFrom.diff(timeFrom.clone().startOf('hour'), 'minutes')
              if (diff < 30) {
                key = timeFrom.clone().startOf('hour').format(this.backendTimeFormat)
              } else {
                key = timeFrom.clone()
                  .startOf('hour')
                  .add(30, 'minutes')
                  .format(this.backendTimeFormat)
              }
            }
            // console.log(45, this.calculatedValues[key][index])
            this.calculatedValues[key][index] = +this.calculatedValues[key][index]
            this.calculatedValues[key][index] += +workplace
          })
          timeFrom.add(this.step, 'hour')
        }
      } else { // переходящие смены
        const startOfDay = moment().startOf('day')
        const endOfDay = moment().add(1, 'day').startOf('day')
        while (startOfDay.isBefore(timeTo)) {
          template.workplaces.forEach((workplace, index) => {
            let key = null
            if (this.step === 1) {
              key = startOfDay.clone().startOf('hour').format(this.backendTimeFormat)
            }
            if (this.step === 0.5) {
              const diff = startOfDay.diff(startOfDay.clone().startOf('hour'), 'minutes')
              if (diff < 30) {
                key = startOfDay.clone().startOf('hour').format(this.backendTimeFormat)
                // console.log(diff, key)
              } else {
                key = startOfDay.clone()
                  .startOf('hour')
                  .add(30, 'minutes')
                  .format(this.backendTimeFormat)
              }
            }
            // второя половина переходящей смены переносится на следующий день, а если считаем для воскресенья, то на понедельник
            this.calculatedValues[key][index + 1 > 6 ? 0 : index + 1] += +workplace
          })
          startOfDay.add(this.step, 'hour')
        }
        while (timeFrom.isBefore(endOfDay)) {
          template.workplaces.forEach((workplace, index) => {
            let key = null
            if (this.step === 1) {
              key = timeFrom.clone().startOf('hour').format(this.backendTimeFormat)
            }
            if (this.step === 0.5) {
              const diff = timeFrom.diff(timeFrom.clone().startOf('hour'), 'minutes')
              if (diff < 1) {
                key = timeFrom.clone().startOf('hour').format(this.backendTimeFormat)
              } else {
                key = timeFrom.clone()
                  .startOf('hour')
                  .add(30, 'minutes')
                  .format(this.backendTimeFormat)
              }
            }
            // console.log(9, key)
            this.calculatedValues[key][index] += +workplace
          })
          timeFrom.add(this.step, 'hour')
        }
      }
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$parent.$emit('closeCalculatorModal')
    },
    save () {}
  }
}
</script>

<style>
  .text-center.num-input > .el-input-number.is-without-controls .el-input__inner {
    padding: 0px!important;
    min-height: 30px!important;
    max-height: 30px!important;
  }
  /*.el-input-number__decrease, .el-input-number__increase {*/
  /*  width: 20px!important;*/
  /*}*/
</style>
