import {
  GET_STATS
} from '../mutation-types.js'
import { HTTP } from '@/api/http_utils'

const promises = {}

const state = {
  Stats: {}
}

const getters = {
  Stats: state => state.Stats
}

const mutations = {
  [GET_STATS] (state, Stats) {
    state.Stats = Stats
  }
}

const actions = {
  getCompanyWorkers ({ commit }, [companyId, data, blob = 0]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/company_workers` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getPayRates ({ commit }, [companyId, data, blob = 0]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/payreports` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserPayRates ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/employee_payreport` + data).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBudget ({ commit }, [companyId, data, blob = 0]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/budged_reports` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getShiftConfirmationReport ({ commit }, [companyId, data, blob]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/shift_confirmation_report` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getLateEmployeesReport ({ commit }, [companyId, data, blob]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/late_employees_report` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getEmployeesWorkingHours ({ commit }, [companyId, data, blob = 0]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/working_hour` + data, { responseType: blob ? 'blob' : 'json' }).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getMinimumHours ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/minimum_hours` + data).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTimeOff ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/time_off` + data).then(Stats => {
        if (Stats) {
          commit(GET_STATS, Stats)
        }
        resolve(Stats)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTasksStats ({ commit }, [companyId, query, blob = 0]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/tasks_report${query}`, { responseType: blob ? 'blob' : 'json' }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getActivityStats ({ commit }, [companyId, query, exportReport]) {
    const promiseKey = `companies/${companyId}/stats/activity_report${query}`

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/stats/activity_report${query}`, { responseType: exportReport ? 'blob' : 'json' }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    }).finally(_ => {
      delete promises[promiseKey]
    })

    promises[promiseKey] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
