export const sortArrOfObjectsByPropAsc = (arrayOfObjects, prop) => {
  const sortedArr = [...arrayOfObjects].sort((a, b) => {
    const propA = typeof a?.[prop] === 'number' ? a?.[prop] : a?.[prop]?.toLowerCase()
    const propB = typeof b?.[prop] === 'number' ? b?.[prop] : b?.[prop]?.toLowerCase()

    if (typeof propA === 'number') {
      return propA - propB
    }

    if (propA < propB) {
      return -1
    }
    if (propA > propB) {
      return 1
    }
    return 0
  })

  return sortedArr
}
