<template>
  <li class="nav-item border-top no-md-border my-auto">
    <div class="btn-group nav-link px-0" v-bind:class="{ 'py-0' : isMobile }">
      <router-link id="task_calendar" :to="`/c/${companyId}/shift-activities`" style="width:100%">
                    <span class="d-md-none p-0 mx-2">
                      <i class="icon-clipboard mobile-menu-icon"></i>
                    </span>
        <button type="button" id="activity_service" class="btn btn-light menu-color">{{
            $t('SHIFT_ACTIVITIES_LINK')
          }}
        </button>
      </router-link>
      <button id="activity_dropdown" @click="getActivities" type="button" class="btn dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown" aria-expanded="false">
        <span class="sr-only">Toggle Dropdown</span>
      </button>
      <div class="dropdown-menu dropdown-menu-right" :style="{minWidth: dummy ? '400px' : null }">
        <template v-if="dummy">
          <dummy-payment-required
            @activate="activate"
            :dummyInfo="dummyInfo"
            :compactMode="true">
          </dummy-payment-required>
        </template>
        <template v-if="!dummy">
          <div v-if="activities && activities.length > 0" class="mb-1 px-1" style="min-width: 250px">
            <template v-for="activity in activities" :key="activity.id">
              <div>
                <button
                  type="submit"
                  @click="updateActivity(activity)"
                  data-cy="switch-view"
                  :class="activity.next_statuses[0].check_in ? 'btn-outline-success' : 'btn-outline-danger'"
                  class="btn rounded-pill shadow-sm w-100 mt-1">
                  <template v-if="activity.next_statuses[0].check_in">
                    <div class="mb-1">
                      <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
                      <span class="mx-1">{{activity.title}}</span>
                    </div>
                    <div>
                      {{ $t('START_ACTIVITY') }}
                    </div>
                  </template>
                  <template v-if="activity.next_statuses[0].check_out">
                    <div class="mb-1">
                      <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
                      <span class="mx-1">{{activity.title}}</span>
                    </div>
                    <div>
                      {{ $t('FINISH_ACTIVITY') }}
                    </div>
                  </template>
                </button>
              </div>
            </template>
          </div>
          <div v-if="unscheduledActivities && unscheduledActivities.length > 0" class="mb-1 px-1" style="min-width: 250px">
            <template v-for="activityData in unscheduledActivities" :key="'activity' + activityData.id">
              <div>
                <button
                  type="submit"
                  @click="createUnscheduledActivity(activityData.id)"
                  data-cy="switch-view"
                  :class="activityData.next_statuses[0].check_in ? 'btn-outline-success' : 'btn-outline-danger'"
                  class="btn rounded-pill shadow-sm w-100 mt-1">
                  <template v-if="activityData.next_statuses[0].check_in">
                    <div>
                      <span class="project-color" v-bind:style="{ backgroundColor: activityData.color }" />
                      <span class="mx-1">{{activityData.title}}</span>
                    </div>
                  </template>
                </button>
              </div>
            </template>
          </div>
          <div v-if="!activities.length && !unscheduledActivities.length" class="p-3">
            {{$t('NO_ACTIVITIES_TO_UPDATE')}}
          </div>
        </template>
      </div>
    </div>
  </li>
</template>
<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import momentMixin from '@/mixins/mixinMoment'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  components: { DummyPaymentRequired },
  mixins: [mixinDummy, momentMixin],
  name: 'HeaderActivityComponent',
  data () {
    return {
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: {}
      },
      activities: [],
      unscheduledActivities: []
    }
  },
  emits: ['activate'],
  props: {
    isMobile: Boolean
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    activate () {
      this.$emit('activate')
    },
    convertShiftTime (shiftTime) {
      return this.fromZoneToZone(shiftTime, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateTimeFormat)
    },
    getActivities () {
      this.$store.dispatch('getTemplatesForUnscheduledActivity', this.companyId).then(response => {
        this.dummy = false
        this.unscheduledActivities = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          error.response.data.module_id = error.response.data.id
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })

      this.$store.dispatch('getPersonalActivity', this.companyId).then(response => {
        this.dummy = false
        this.activities = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          error.response.data.module_id = error.response.data.id
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    createUnscheduledActivity (activityTemplateId) {
      const payload = {
        template_id: activityTemplateId
      }

      this.$store.dispatch('createUnscheduledActivity', [this.companyId, payload]).then(response => {
        this.unscheduledActivities = response
        this.toastSuccess(this.$i18n?.t('UNSCHEDULED_ACTIVITY_STARTED'))
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    updateActivity (activity) {
      const payload = {
        id: activity.id,
        next_status_id: activity.next_statuses[0].id
      }
      this.$store.dispatch('patchPersonalShiftActivity', [this.companyId, payload]).then(response => {
        this.$eventBus.emit('updateActivityStatus')
        this.toastSuccess(this.$i18n?.t('ACTIVITY_UPDATED'))
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    }
  }
}
</script>
<style scoped lang="scss">

.menu-color {
  color: #4a4a4a !important;
}

.no-bg, .no-bg:visited, .no-bg:hover, .no-bg:active {
  background: none;
}

.btn-sm-header {
  padding: 0.12rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.35rem;
}

.dropdown-toggle {
  border-radius: 0px 7px 7px 0px !important;
}

#activity_service,
#activity_dropdown {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: transparent;
  border: none;
}

#activity_service:hover,
#activity_dropdown:hover {
  background-color: #eceeef;
}

.dropdown-toggle::after {
  border-top-color: black;
  border-right-color: transparent;
  border-bottom-color: initial;
  border-left-color: transparent;
}

.scheduling-btn {
  background-color: transparent;
  border: none;
}

.mobile-menu-icon {
  color: rgba(0, 0, 0, 0.5);
}

.btn {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.navBtn {
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
}
</style>
