<template>
  <div v-if="dummy">
    <dummy-payment-required
      @activate="loadData()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
  </div>
  <div v-else-if="loaded">
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li v-if="isEmployeeHasPermission('get-company-files-manager')"
              class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/projects`">
              <span>{{ $t('Projects') }}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text">
            <router-link
              v-if="isEmployeeHasPermission('get-employee-admin')"
              :to="`/c/${companyId}/users/`">
              <span>{{ $t('Users list') }}</span>
            </router-link>
            <router-link
              v-if="isEmployeeHasPermission('get-employees-availability-manager') && !isEmployeeHasPermission('get-employee-admin')"
              :to="`/c/${companyId}/project/${projectId}/users/`">
              <span>{{ $t('Users list') }}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text">
            <router-link
              v-if="isEmployeeHasPermission('get-employee-admin')"
              :to="`/c/${companyId}/users/${employeeId}`">
              <span>{{ $t('User info') }}</span>
            </router-link>
            <router-link
              v-if="isEmployeeHasPermission('get-employees-availability-manager') && !isEmployeeHasPermission('get-employee-admin')"
              :to="`/c/${companyId}/project/${projectId}/user/${employeeId}`">
              <span>{{ $t('User info') }}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t('User Files') }}</span>
          </li>
        </ol>
      </nav>
      <div class="ml-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          :is-search="true"
        />
      </div>
    </div>

    <div class="mt-2">
      <div class="row mt-4">
        <div class="col-lg-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="text-left">
                  <ui-checkbox
                    :value="selectAllCheckboxes"
                    @change="toggleAllCheckboxes"
                    name="selectAllCheckboxes"
                  />
                </th>
                <th class="text-left">{{ $t("File name") }}</th>
                <th class="text-left">{{ $t("Date created") }}</th>
                <th class="text-left">{{ $t("Type") }}</th>
                <th class="text-left">{{ $t("Size") }}</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in filteredFiles" :key="file.id">
                <td>
                  <ui-checkbox-group v-model="selectedFiles">
                    <ui-checkbox
                      :custom-value="file.id"
                      :name="'f__' + file.id"
                      @change="updateCheckbox"
                    />
                  </ui-checkbox-group>
                </td>
                <td class="align-middle"><img :src="getFilePreview(file)" class="img-fluid thumbnail mr-2"/>{{ file.fullname }}</td>
                <td class="align-middle">{{createdDate(file)}} {{createdTime(file)}}</td>
                <td class="align-middle">{{getFileType(file)}}</td>
                <td class="align-middle">{{getFileSize(file.size)}}</td>
                <td class="d-flex justify-content-end">
                  <i class="icon-download btn-icon mx-1" @click="handleDownloadObject(file)"></i>
                  <i class="icon-trash btn-icon text-danger mx-1" @click="tryRemoveFileFromCompany(file)"></i>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3">
                  <button class="btn btn-outline-danger rounded-pill" @click="removeFiles()">{{ $t("Remove selected") }}</button>
                </th>
                <th class="text-right bold">{{$t("Total:")}}</th>
                <th>{{ getTotalFileSize(files) }}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'EmployeeFiles',
  mixins: [momentMixin, mixinDummy],
  components: {
    DummyPaymentRequired: DummyPaymentRequired
  },
  data () {
    return {
      files: [],
      selectedFiles: [],
      selectAllCheckboxes: false,
      search: '',
      display: {
        status: false,
        name: '',
        button: ''
      },
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      projectId: null,
      employeeId: null,
      dummy: false,
      loaded: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  beforeMount () {
    this.loadData()
    // files.forEach((file, index) => this.selectedFiles[index] = false)
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    userId () {
      return this.$store.getters.profileId
    },
    apiKey () {
      return localStorage.getItem('api_key')
    },
    filteredFiles () {
      return this.files.filter(file => {
        return file.fullname.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('isModuleActive', [this.companyId, process.env.VUE_APP_COMPANY_FILES_ID]).then(resolve => {
        this.dummy = false
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.$router.push('dashboard')
        }
      })
      this.employeeId = this.$route.params.userId
      this.projectId = +this.$route.query.q
      this.$store.dispatch('getFiles', this.employeeId).then(files => {
        this.files = files
        this.loaded = true
      })
    },
    createdDate (file) {
      return this.fromZoneToZone(file.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateFormat)
    },
    createdTime (file) {
      return this.fromZoneToZone(file.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeTimeFormat)
    },
    getFileType (file) {
      const fileNameInfo = file.fullname.split(/\.(?=[^.]+$)/)
      return fileNameInfo && fileNameInfo[1] && fileNameInfo[1].toUpperCase()
    },
    getFileSize (size) {
      if (size <= 1024 ** 2) {
        return (size / 1024).toFixed(2) + this.$t(' KB')
      }
      if (size <= 1024 ** 3) {
        return (size / 1024 ** 2).toFixed(2) + this.$t(' MB')
      } else {
        return (size / 1024 ** 3).toFixed(2) + this.$t(' GB')
      }
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../public/static/images/App/file.png')
      }
    },
    getTotalFileSize (files) {
      let size = 0
      files.forEach(file => {
        size += file.size
        return size
      })
      return this.getFileSize(size)
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    tryRemoveFileFromCompany (file) {
      this.$confirm(this.$i18n?.t('Confirm deletion'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.removeFileFromCompany(file)
      }).catch(() => {})
    },
    removeFileFromCompany (file) {
      this.$store.dispatch('delCompanyFile', [this.$store.getters.companyId, { file_id: file.id }]).then(response => {
        this.$store.dispatch('getFiles', this.$route.params.userId).then(files => {
          this.files = files
        })
      })
    },
    toggleAllCheckboxes (val) {
      this.selectedFiles = []
      if (val) {
        for (const i in this.files) {
          this.selectedFiles.push(this.files[i].id)
        }
      }
    },
    updateCheckbox (val) {
      if (!val) {
        this.selectAllCheckboxes = false
      }
    },
    removeFiles () {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        const filtered = this.filteredFiles
        for (const i in filtered) {
          if (this.selectedFiles.includes(filtered[i].id)) {
            this.removeFileFromCompany(filtered[i])
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>
  .thumbnail {
    height: 30px !important;
  }
</style>
