<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('EXCHANGE_BREAKS_REQUEST_TITLE') }}</div>
    </div>
    <div class="mt-3">
      <div>
        {{ $t('User') }}
        <span class="visible-md-inline visible-lg-inline ml-3">
            <avatar
              class="rounded-circle d-inline-block font-weight-normal"
              :width="35"
              :borderRadius="50"
              :placeholder="false"
              :userId="request.data.creator_user_id"
              :mini="true"
              :cache="true"
              :userName="request.messages[0].employee_name">
            </avatar>
          </span>
        <span class="ml-1 font-weight-bold">{{request.messages[0].employee_name}} </span>
        <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
        <span>{{ $t('USER_ASKS_TO_EXCHANGE_BREAKS') }}</span>
      </div>
      <div class="mt-3 font-weight-bold">{{ $t('BREAK_FROM_TIME')}}</div>
      <div class="mt-3">
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.break_from.time_from).format(localeDateTimeFormat) }}</span>
        <span> - </span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.break_from.time_to).format(localeDateTimeFormat) }}</span>
      </div>
      <div class="mt-3 font-weight-bold">{{ $t('BREAK_TO_TIME')}}</div>
      <div class="mt-3">
        <div class="mt-3">
          <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.break_to.time_from).format(localeDateTimeFormat) }}</span>
          <span> - </span>
          <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.break_to.time_to).format(localeDateTimeFormat) }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
export default {
  name: 'BreakExchange',
  props: ['request', 'employee'],
  components: { Avatar },
  mixins: [momentMixin, requestMixin]
}
</script>

<style scoped>

</style>
