<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('VACATIONS_TRANSACTIONS_TITLE') }}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-3 d-flex font-weight-bold">
        {{$t('VACATIONS_TRANSACTIONS_DATE')}}
      </div>
      <div class="col-lg-3 d-flex font-weight-bold">
        {{$t('VACATIONS_TRANSACTIONS_AMOUNT')}}
        <ui-hint :content="$t('VACATIONS_TRANSACTIONS_AMOUNT_HINT')" />
      </div>
      <div class="col-lg-4 d-flex justify-content-center font-weight-bold">
        {{$t('VACATIONS_TRANSACTIONS_COMMENT')}}
      </div>
    </div>
    <el-divider />
    <Form ref="observer" v-slot="{ meta }">
      <div class="row">
        <div class="col-lg-3">
          <ui-date-picker
            name="SELECT_DAY"
            v-model="newTransaction.date"
            :clearable="false"
            :placeholder="$t('SELECT_DAY')"
            @change="onDateChange"
          />
          <span
            v-if="monthStatistic && monthStatistic.balance"
            class="small"
            :class="{
              'text-primary': newTransaction.amount > 0 || (newTransaction.amount < 0 && monthStatistic.balance >= - newTransaction.amount),
              'text-warning': newTransaction.amount < 0 && monthStatistic.balance < - newTransaction.amount
            }"
          >
            {{$t('AVAILABLE_VACATIONS_FOR_CURRENT_MONTH')}} {{monthStatistic.balance}} {{$t('days')}}
            <ui-hint :content="$t('AVAILABLE_VACATIONS_FOR_CURRENT_MONTH_HINT')" />
          </span>
          <span v-else class="small text-primary">
            {{$t('NO_VACATION_STATISTIC_PRECALCULATED')}}
            <ui-hint :content="$t('NO_VACATION_STATISTIC_PRECALCULATED_HINT')" />
          </span>
        </div>
        <div class="col-lg-3">
          <ui-number-input
            name="amount"
            :precision="0"
            :step="1"
            v-model="newTransaction.amount"
            :validation="{required: true, integer: true, is_not: 0}"
            @change="(val) => newTransaction.amount = val"
          />
        </div>
        <div class="col-lg-4">
          <ui-textarea-input
            name="comment"
            v-model="newTransaction.comment"
            :rows="2"
          />
        </div>
        <div class="col-lg-2 d-flex justify-content-center">
          <div>
            <el-button
              size="large"
              type="primary"
              round
              @click="save"
              :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
            >
              {{ $t('SAVE_WEB') }}
            </el-button>
          </div>
        </div>
      </div>
    </Form>
    <el-divider />
    <template v-for="transaction in vacationsTransactions">
      <div v-if="transaction.id !== transactionToEdit.id" :key="transaction.id" class="row mt-1">
        <div class="col-lg-4 px-4">
          {{convertToDate(transaction.date)}}
        </div>
        <div class="col-lg-2 d-flex justify-content-center">
          {{Math.round(transaction.amount * 10000) / 10000}}
        </div>
        <div class="col-lg-3">
          {{transaction.comment}}
        </div>
        <div class="col-lg-3 d-flex justify-content-center">
          <div>
            <el-button
              size="default"
              type="primary"
              icon="Edit"
              circle
              @click="edit(transaction)"
            />
          </div>
        </div>
      </div>
      <transactions-edit
        v-if="transaction.id === transactionToEdit.id"
        :key="'edit__' + transaction.id"
        :transaction="transactionToEdit"
        :employeeId="employeeId"
        @cancelEdit="() => transactionToEdit.id = null"
      />
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import TransactionsEdit from '@/components/User/user/UserComponents/VacationsComponents/TransactionsEdit.vue'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Transactions',
  mixins: [momentMixin, errorMixin],
  components: { TransactionsEdit },
  data () {
    return {
      newTransaction: {
        date: moment().format(this.backendDateTimeFormat),
        amount: -1,
        comment: null
      },
      transactionToEdit: {
        id: null,
        date: moment().format(this.backendDateTimeFormat),
        amount: -1,
        comment: null
      }
    }
  },
  props: {
    employeeId: Number
  },
  created () {
    const date = moment().format(this.backendMonthYearFormat)
    this.$store.dispatch('getVacationsMonthReport', [this.companyId, date, this.employeeId])
    this.$eventBus.on('reloadTransactions', this.reloadTransactions)
    this.onEmployeeChange()
  },
  computed: {
    vacationsTransactions () {
      return this.$store.getters.vacationsTransactions
    },
    companyId () {
      return this.$store.getters.companyId
    },
    monthStatistic () {
      return this.$store.getters.vacationsMonthStatistic[this.employeeId]
    }
  },
  methods: {
    onEmployeeChange () {
      this.$store.dispatch('getVacationsMonthReport', [
        this.companyId,
        moment(this.newTransaction.date).format(this.backendMonthYearFormat),
        this.employeeId
      ])
    },
    onDateChange (val) {
      const oldDate = moment(this.newTransaction.date)
      const newDate = moment(val)
      this.newTransaction.date = val
      if (!oldDate.isSame(newDate, 'month')) {
        this.$store.dispatch('getVacationsMonthReport', [
          this.companyId,
          newDate.format(this.backendMonthYearFormat),
          this.employeeId
        ])
      }
    },
    edit (item) {
      this.transactionToEdit = { ...item }
    },
    convertToDate (val) {
      if (val) {
        return moment(val).locale(this.$store.getters.locale).format(this.localeDateFormat)
      }
      return null
    },
    async save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const payload = {
            date: moment(this.newTransaction.date).format(this.backendDateFormat),
            amount: this.newTransaction.amount,
            comment: this.newTransaction.comment
          }
          this.$store.dispatch('createVacationsTransactions', [this.companyId, this.employeeId, payload])
            .then(() => {
              this.reloadTransactions()
              this.toastSuccess(this.$i18n?.t('TRANSACTION_CREATED_SUCCESS'))
            })
            .catch(err => {
              this.toastError(this.$i18n?.t(err.response.data.message))
            })
        }
      })
    },
    reloadTransactions () {
      this.newTransaction = {
        date: moment().format(this.backendDateTimeFormat),
        amount: -1,
        comment: null
      }
      this.transactionToEdit = {
        id: null,
        date: moment().format(this.backendDateTimeFormat),
        amount: -1,
        comment: null
      }
      this.$emit('reloadTransactions')
    }
  }
}
</script>

<style scoped>

</style>
