<template>
  <div v-if="isNotPassed" class="row p-2 m-1 rounded" style="background-color: #ccf1f7">
    <div class="col-lg-4 my-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row m-0" @click="goToLink(`/c/${companyId}/users`)" style="cursor: pointer">
            <div class="col-auto text-center d-flex">
              <div  :class="{'step-circle': lang !== 'he', 'step-circle-he': lang === 'he', 'bg-primary': showUsers, 'bg-success': !showUsers}"
                class="d-flex justify-content-center align-self-center">
                <div class="align-self-center text-white font-weight-bold">
                  1
                </div>
              </div>
            </div>
            <div class="col">
              <div class="font-weight-bold">
                <span class="text-black">{{$t('Add an employees')}}</span>
              </div>
              <div>
                {{$t('FIRST_STEP_HINTS')}}
              </div>
            </div>
            <div class="col-auto text-center px-0 d-flex">
              <template v-if="showUsers">
                <i :class="{'icon-circle-right': lang !== 'he', 'icon-circle-left': lang === 'he'}" class="text-primary align-self-center"
                  style="font-size: 40px">
                </i>
              </template>
              <template v-else>
                <i class="icon-check-circle text-success align-self-center" style="font-size: 40px"></i>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 my-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row m-0" @click="goToLink(`/c/${companyId}/project`)" style="cursor: pointer">
            <div class="col-auto text-center d-flex">
              <div class="d-flex justify-content-center align-self-center"
                :class="{'step-circle': lang !== 'he', 'step-circle-he': lang === 'he', 'bg-primary': showProject, 'bg-success': !showProject}">
                <div class="align-self-center text-white font-weight-bold">
                  2
                </div>
              </div>
            </div>
            <div class="col">
              <div class="font-weight-bold">
                <span class="text-black">{{$t('Create a project')}}</span>
              </div>
              <div>
                {{$t('SECOND_STEP_HINTS')}}
              </div>
            </div>
            <div class="col-auto text-center px-0 d-flex">
              <template v-if="showProject">
                <i :class="{'icon-circle-right': lang !== 'he', 'icon-circle-left': lang === 'he'}" class="text-primary align-self-center"
                  style="font-size: 40px">
                </i>
              </template>
              <template v-else>
                <i class="icon-check-circle text-success align-self-center" style="font-size: 40px"></i>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 my-1">
      <div class="card">
        <div class="card-body p-2">
          <div class="row m-0" @click="goToLink(`/c/${companyId}/schedule/master`)" style="cursor: pointer">
            <div class="col-auto text-center d-flex">
              <div class="d-flex justify-content-center align-self-center"
                :class="{'step-circle': lang !== 'he', 'step-circle-he': lang === 'he', 'bg-primary': showSchedule, 'bg-success': !showSchedule}">
                <div class="align-self-center text-white font-weight-bold">
                  3
                </div>
              </div>
            </div>
            <div class="col">
              <div class="font-weight-bold">
                <span class="text-black">{{$t('Create a schedule')}}</span>
              </div>
              <div>
                {{$t('THIRD_STEP_HINTS')}}
              </div>
            </div>
            <div class="col-auto text-center px-0 d-flex">
              <template v-if="showSchedule">
                <i :class="{'icon-circle-right': lang !== 'he', 'icon-circle-left': lang === 'he'}"
                  class="text-primary align-self-center" style="font-size: 40px">
                </i>
              </template>
              <template v-else>
                <i class="icon-check-circle text-success align-self-center" style="font-size: 40px"></i>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstSteps',

  data () {
    return {
      employees_count: 10,
      projects_count: 10
    }
  },

  created () {
    this.$store.dispatch('getEmployeesByCompany', this.companyId).then(employees => {
      this.employees_count = this.$store.getters.employeesCount
    })
    this.$store.dispatch('getProjectsByCompany', this.companyId).then((projects) => {
      this.projects_count = this.$store.getters.projectsCount
    })
  },

  props: {
    schedules: Array
  },
  computed: {
    lang () {
      return this.$store.getters.locale
    },
    companyId () {
      return this.$store.getters.companyId
    },
    isNotPassed () {
      return this.showProject || this.showUsers || this.showSchedule
    },
    projectsCount () {
      return this.$store.getters.projectsCount
    },
    showProject () {
      return this.projectsCount < 1
    },
    showUsers () {
      return this.employees_count <= 1
    },
    showSchedule () {
      // if (this.schedules.length < 1) return true
      let manuallyCount = 0
      this.schedules.forEach(schedule => {
        if (schedule.schedule_type === 'manually') manuallyCount++
      })
      if (this.schedules.length < 1) return true
      return this.schedules.length === 1 && manuallyCount > 0
    }
  },

  watch: {
    schedules () {
      if (this.schedules.length < 1) {
        this.schedulesShow = true
      } else {
        let manuallyCount = 0
        this.schedules.forEach(schedule => {
          if (schedule.schedule_type === 'manually') manuallyCount++
        })
        this.schedulesShow = this.schedules.length === 1 && manuallyCount > 0
      }
    }
  },

  methods: {
    goToLink (link) {
      this.$router.push(link)
    }
  }
}
</script>

<style scoped>
  .step-circle {
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
    margin-left: -45px;
  }
  .step-circle-he {
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
    margin-right: -45px;
  }
</style>
