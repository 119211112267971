export default {
  computed: {
    notificationPosition () {
      return 'bottom-right'
    },
    notificationCustomClass () {
      return 'el-notify'
    }
  },
  methods: {
    toastSuccess (message) {
      this.$notify({
        title: this.$i18n?.t('Success'),
        message: message,
        position: this.notificationPosition,
        customClass: this.notificationCustomClass,
        type: 'success'
      })
    },
    toastInfo (message) {
      this.$notify({
        title: this.$i18n?.t('Info'),
        message: message,
        position: this.notificationPosition,
        customClass: this.notificationCustomClass,
        type: 'info'
      })
    },
    toastWarning (message) {
      this.$notify({
        title: this.$i18n?.t('Warning'),
        message: message,
        position: this.notificationPosition,
        customClass: this.notificationCustomClass,
        type: 'warning'
      })
    },
    toastError (message, allowHTML = false) {
      this.$notify({
        title: this.$i18n?.t('Error'),
        message: message,
        position: this.notificationPosition,
        customClass: this.notificationCustomClass,
        dangerouslyUseHTMLString: allowHTML,
        type: 'error'
      })
    },
    toastCustom (options) {
      const defaults = {
        title: this.$i18n?.t('Info'),
        position: this.notificationPosition,
        customClass: this.notificationCustomClass,
        type: 'info'
      }

      this.$notify({ ...defaults, ...options })
    }
  }
}
