import moment from 'moment'

export default class FullCalendarFilters {
  /**
   *
   * @param resources array
   * @param filteredResources array
   */
  constructor (resources, filteredResources) {
    this.resources = resources
    this.filteredResources = filteredResources
  }

  filterByUsers (values) {
    const filteredResources = this.resources.filter(item => values.includes(item.id))
    filteredResources.forEach(item => {
      item.order = values.indexOf(item.id)
    })
    this.resources = filteredResources
    return this.resources
  }

  filterByPositions (values) {
    // console.log(this.resources)
    const filteredResources = this.resources.filter(item => item.positionsIds && values.includes(item.positionsIds[0]))
    filteredResources.forEach(item => {
      item.order = values.indexOf(item.id)
    })
    this.resources = filteredResources
    return this.resources
  }

  filterBySchedules (values, schedules) {
    let employeesId = []
    values.forEach(scheduleId => {
      const schedule = schedules[scheduleId]
      schedule.users.forEach(user => {
        employeesId.push(user.employee_id)
      })
    })
    employeesId = [...new Set(employeesId)]
    const filteredResources = this.resources.filter(item => employeesId.includes(item.id))
    this.resources = filteredResources
    // console.log(1, this.resources)
    return this.resources
  }

  /**
   *
   * @param values
   * @param marksList Информация по меткам пользователя, сгрупированная по id пользователя
   * @returns {*}
   */
  filterByUserMarks (values, marksList) {
    const usersByMarksId = {}
    Object.keys(marksList).forEach(userId => {
      marksList[userId].forEach(mark => {
        if (values.includes(mark.markId)) {
          usersByMarksId[userId] = true
        }
      })
    })
    const filteredResources = []
    this.resources.forEach(user => {
      if (usersByMarksId[user.id]) {
        filteredResources.push(user)
      }
    })
    this.resources = filteredResources
    return this.resources
  }

  /**
   * Фильтра по пометкам на шифтах - перебираем шифты и получаем список пользователей у которых есть шифты с пометками
   */
  filterByShiftMarks (marksIdsFromFilter, shifts, view) {
    const usersWithMarkedShifts = {}
    const filteredShifts = this.filterShiftsByRange(shifts, view.currentStart, view.currentEnd)
    filteredShifts.forEach(shift => {
      let intersection = []
      const marks = shift.marks
      if (Array.isArray(marks) && marks.length > 0) {
        intersection = shift.marks.filter(value => marksIdsFromFilter.includes(value))
      }
      if (intersection.length > 0) usersWithMarkedShifts[shift.employee_id] = true
    })
    const userIds = Object.keys(usersWithMarkedShifts).map(item => +item)
    const filtered = this.resources.filter(user => userIds.includes(user.id))
    this.resources = filtered
    return this.resources
  }

  filterByLocations (locationsIdsFromFilter, shifts, view) {
    const usersWithLocatedShifts = {}
    const filteredShifts = this.filterShiftsByRange(shifts, view.currentStart, view.currentEnd)
    filteredShifts.forEach(shift => {
      if (locationsIdsFromFilter.includes(shift.location_id)) usersWithLocatedShifts[shift.employee_id] = true
    })
    const userIds = Object.keys(usersWithLocatedShifts).map(item => +item)
    const filtered = this.resources.filter(user => userIds.includes(user.id))
    this.resources = filtered
    return this.resources
  }

  getResources () {
    return this.filteredResources.concat(this.resources)
  }

  filterShiftsByRange (shifts, start, end) {
    return shifts.filter(shift => moment(shift.time_from).isSame(moment(start)) || moment(shift.time_from).isBetween(moment(start), moment(end)))
  }

  filterByUsersWithShifts (shifts, templatesIDs) {
    // console.log(templatesIDs)
    return new Promise((resolve, reject) => {
      // добавляем в ресурсы фриборд, праздники и статистику
      const fixedResources = {
        0: '0',
        holidays: 'holidays',
        info_event: 'info_event'
      }
      let resources = this.filteredResources.filter(item => {
        return !!fixedResources[item.id.toString()]
      })
      // фильтруем шифты по выбранным темплейтам расписаний (боковое меню)
      shifts = shifts.filter(item => {
        // console.log(2, templatesIDs, item)
        if (!templatesIDs[item.schedule_id]) {
          return false
        }
        return !!templatesIDs[item.schedule_id][item.template_id]
      })
      // получаем список айдишников юзеров со сменами и убираме дубликаты
      const employeesIds = {}
      shifts.forEach(shift => {
        employeesIds[shift.employee_id] = shift.employee_id
      })
      // фильтруем ресурсы по полученым айдишникам и добавляем фриборд, праздники и статистику
      resources = resources.concat(this.filteredResources.filter(item => {
        // employeesIds.includes(item.id))
        return !!employeesIds[item.id]
      }))
      resolve(resources)
    })
  }
}
