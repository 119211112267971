<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text">
              <router-link :to="{ name: 'TaskStatuses', params: { companyId: companyId} }">
                <span class="breadcrumb-item">{{ $t('Statuses')}}</span>
              </router-link>
            </li>
            <li v-if="taskStatusId" class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("EDIT_TASK_STATUS")}}
              <ui-hint :content="$t('EDIT_TASK_STATUS_HINT')" />
            </li>
            <li v-else class="breadcrumb-item title-text active" aria-current="page">
              {{this.$i18n?.t("CREATE_TASK_STATUS")}}
              <ui-hint :content="$t('CREATE_TASK_STATUS_HINT')" />
            </li>
          </ol>
        </nav>
      </div>

      <div class="card card-small">
        <div class="card-body">
          <form @submit.prevent="handleSave">
            <Form ref="observer" as="div" class="row">
              <div class="col-lg-6">
                <div class="pb-2">
                  <ui-text-input
                      id="title"
                      name="title"
                      :label="$t('Title')"
                      v-model="taskStatus.title"
                      :validation="{required: true, min: 3}"
                  />
                </div>
                <div class="pb-2">
                  <ui-multi-select
                      name="types"
                      :label="$t('ALLOWED_STATUS_NEXT_STEP')"
                      :hint="$t('ALLOWED_STATUS_NEXT_STEP_HINT')"
                      v-model="taskStatus.next_statuses"
                      :options="taskStatusesChoices"
                      :key-name="'id'"
                      :label-name="'title'"
                      :value-name="'id'"
                  />
                </div>
                <div class="pb-2">
                  <color-picker
                    name="Color"
                    :label="$t('Color')"
                    v-model="taskStatus.color"
                    :colors="colorsChoices"
                  />
                </div>
                <div v-if="!taskStatus.is_first" class="pb-2">
                  <ui-checkbox
                    name="check_in"
                    :label="$t('IS_CHECK_IN')"
                    v-model="taskStatus.check_in"
                  />
                </div>
                <div v-if="!taskStatus.is_first" class="pb-2">
                  <ui-checkbox
                    name="check_out"
                    :label="$t('IS_CHECK_OUT')"
                    v-model="taskStatus.check_out"
                  />
                </div>
                <div v-if="!taskStatus.is_first" class="pb-2">
                  <ui-checkbox
                    name="is_final"
                    :label="$t('IS_FINAL_STATUS')"
                    v-model="taskStatus.is_final"
                  />
                </div>
                <div v-if="!taskStatus.is_first" class="pb-2">
                  <ui-checkbox
                    name="check_gps"
                    :label="$t('Check gps')"
                    v-model="taskStatus.check_gps"
                  />
                </div>
              </div>
            </Form>
            <div class="row">
              <div class="col py-2 text-left">
                <button class="btn btn-success btn-circle shadow-sm" type="submit">{{ $t('SAVE_WEB') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import colors from '@/mixins/mixinColors'
import mixinDummy from '@/mixins/mixinDummy'
import ToastMixin from '@/mixins/ToastMixin'
import ColorPicker from '@/ui/color-picker.vue'

export default {
  name: 'TaskStatus',
  mixins: [errorMixin, momentMixin, TasksAccessMixin, colors, mixinDummy, ToastMixin],
  components: { ColorPicker, DummyPaymentRequired },
  data () {
    return {
      taskStatus: {
        title: null,
        check_gps: false,
        color: '#958d19',
        check_in: false,
        check_out: false,
        is_final: false,
        is_first: false,
        next_statuses: null
      },
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      taskStatusesChoices: []
    }
  },
  created () {
    if (this.companyId) {
      this.checkAccess(this.companyId)
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    taskStatusId () {
      return this.$route.params.taskStatusId
    }
  },
  watch: {
    'taskStatus.check_in' (val) {
      if (val) {
        this.taskStatus.check_out = false
      }
    },
    'taskStatus.check_out' (val) {
      if (val) {
        this.taskStatus.check_in = false
      }
    }
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    init () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then((statuses) => {
        const options = []
        statuses.forEach(item => {
          if (this.taskStatusId && +this.taskStatusId === +item.id) {
            // do nothing
          } else {
            options.push({
              id: item.id,
              title: item.title
            })
          }
        })
        this.taskStatusesChoices = options
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (this.taskStatusId) {
          this.$store.dispatch('getTasksStatus', [this.companyId, this.taskStatusId]).then((response) => {
            this.taskStatus = response
          }).catch(() => {})
        }
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    handleSave () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.showShifts = false
          const payload = Object.assign({}, this.taskStatus)
          if (this.taskStatusId) {
            this.$store.dispatch('updateTasksStatus', [this.companyId, this.taskStatusId, payload]).then((response) => {
              this.taskStatus = response
              // this.errors.clear()
              this.toastSuccess(this.$t('Task status updated'))
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status === 400 && errorMessage) {
                if (errorMessage === 'only a one is_final allowed') {
                  this.taskStatus.is_final = false
                }
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          } else {
            this.$store.dispatch('createTasksStatus', [this.companyId, payload]).then((response) => {
              this.toastSuccess(this.$t('Task status created'))
              this.taskStatus = response
              this.$router.push(`/c/${this.companyId}/tasks-statuses/${response.id}`)
            }).catch((error) => {
              const errorMessage = error.response.data.message
              if (error.response.status === 400 && errorMessage) {
                this.toastError(this.$i18n?.t(errorMessage))
              }
            })
          }
        }
      }).catch(() => {})
    }
  }
}
</script>

<style>
</style>
