<template>
  <div>
    <div id="timeOffAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">{{ $t('EMERGENCY_SHIFTS_SETTINGS_HEADER') }} <ui-hint v-if="moduleInfo" :content="moduleInfo"/></h5>
        </div>
        <div id="collapseTimeOffSettings" class="collapse show" aria-labelledby="headingOne" data-parent="#timeOffAccordion">
          <div class="card-body">
            <modal-component
                :id="'emergencyShiftsSettingsModal'"
                @closeModal="closeModal"
                :modalClass="'modal-dummy'"
                :modalBodyClass="'dummy-popup'"
                :hideFooter="true"
                :hideHeader="true">
              <template #body>
                <dummy-payment-required
                    @activate="activate"
                    :dummyInfo="dummyInfo"
                    :isModal="true"
                >
                </dummy-payment-required>
              </template>
            </modal-component>
            <Form ref="observer" as="div" class="form-row">
              <div
                  class="col-auto"
                  @click="handleActivation"
                  :data-toggle="!isModuleActive ? 'modal' : null"
                  :data-target="!isModuleActive ? '#emergencyShiftsSettingsModal' : null"
              >
                <div class="form-row">
                  <div class="col-lg-12">
                    <ui-radio
                      name="settings.show_emergency_shifts_to_all_false"
                      v-model="settings.show_emergency_shifts_to_all"
                      :disabled="!isModuleActive"
                      :label="$t('SHOW_EMERGENCY_SHIFT_WITH_BONUSES_TO_NOTIFIED_EMPLOYEES_ONLY')"
                      :hint="$t('SHOW_EMERGENCY_SHIFT_WITH_BONUSES_TO_NOTIFIED_EMPLOYEES_ONLY_HINT')"
                      :set-value="false"
                    />
                  </div>
                  <div class="col-lg-12">
                    <ui-radio
                      name="settings.show_emergency_shifts_to_all_true"
                      v-model="settings.show_emergency_shifts_to_all"
                      :disabled="!isModuleActive"
                      :label="$t('SHOW_EMERGENCY_SHIFTS_WITH_BONUSES_TO_ALL')"
                      :hint="$t('SHOW_EMERGENCY_SHIFTS_WITH_BONUSES_TO_ALL_HINT')"
                      :set-value="true"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
            type="submit"
            name="submit"
            class="btn btn-success rounded-pill shadow-sm"
            @click="submitForm"
            :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsEmergencyShifts',
  components: { ModalComponent, DummyPaymentRequired },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      companyModules: [],
      module: null,
      settings: {
        show_emergency_shifts_to_all: false
      },
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'timeoff')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 27) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            const settings = {
              show_emergency_shifts_to_all: this.module.settings.show_emergency_shifts_to_all ? this.module.settings.show_emergency_shifts_to_all : false
            }
            this.settings = settings
          }
        }
      })
      // модуль timeOff активен?
      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 28]).then(response => {
        if (response.active === true) {
          this.isModuleActive = true
          this.$store.dispatch('getCompanyUserRequestReasons').then(requestsReasons => {
            this.items = requestsReasons
          })
        }
      }).catch(err => {
        if (err.response.data.module) {
          this.isModuleActive = false
          this.dummyInfo = this.setupDummyData(err.response.data)
          this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
            ? err.response.data.module.locales[this.locale].description
            : err.response.data.module.description
        }
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    async submitForm () {
      try {
        if (this.isModuleActive) {
          const result = await this.$refs.observer.validate()

          if (result.valid) {
            await this.$store.dispatch('updateModuleSettings', [
              this.$store.getters.companyId,
              this.module.id,
              {
                settings: this.settings
              }
            ])

            await this.$store.dispatch('clearCompanyStore')
            await this.$store.dispatch('getCompany', this.$store.getters.companyId)

            this.$emit('success')
          }
        }
      } catch (error) {
        console.log('error: ', error)
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>

</style>
