<template>
  <div>
    <div class="row mb-2 mx-auto">
      <div class="col">
        <span v-if="!archiveEnable" class="title-text">{{ $t('User requests') }}</span>
        <span v-if="archiveEnable" class="title-text">{{ $t('Archive requests') }}</span>
      </div>
      <div class="col-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :is-search="true"
          :placeholder="$t('Search')"
        />
      </div>
      <div class="col-auto">
        <button v-if="archiveEnable" class="btn btn-sm btn-primary rounded-pill shadow-sm" @click="change">
          {{ $t('View requests') }}
        </button>
        <button v-if="!archiveEnable" class="btn btn-sm btn-secondary rounded-pill shadow-sm" @click="change">
          {{ $t('Archive') }}
        </button>
      </div>
    </div>

    <div class="card card-small">
      <div class="row">
        <div class="col-auto m-2">
          <ui-select
            :name="'top_pagination'"
            v-model="perPage"
            :clearable="false"
            :filterable="false"
            :placeholder="$t('Select')"
            :options="perPageOptionsSelect"
            @change="changePerPage"
            :key-name="'id'"
            :value-name="'id'"
            :label-name="'name'"
          />
        </div>
        <div v-if="requests.last_page > 1" class="col-auto my-auto">
          <el-pagination
            :current-page="page"
            :page-size="perPage"
            :page-sizes="perPageOptions"
            :total="requests.total"
            background
            layout="prev, pager, next"
            @current-change="goToPage"
            @prev-click="prevPage"
            @next-click="nextPage"
            @size-change="changePerPage">
          </el-pagination>
        </div>
      </div>
      <div class="table-responsive card-body">
        <template v-if="requests.data.length < 1">
          <div class="text-center">
            <img class="img-fluid" src="../../../public/static/images/App/Splash_1_01.png" style="max-height: 350px"/>
            <h5 class="m-3">
              {{ $t('NO_REQUESTS') }}
            </h5>
          </div>
        </template>
        <table v-else class="table table-hover">
          <thead>
          <tr>
            <th class="text-left">{{ $t('Type of request') }}</th>
            <th class="text-left">{{ $t('Project name') }}</th>
            <th class="text-left">{{ $t('Created by') }}</th>
            <th class="text-left">{{ $t('Personnel Number') }}</th>
            <th class="text-left">{{ $t('Created at') }}</th>
            <th class="text-left">{{ $t('Status') }}</th>
            <th class="text-right"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="request in requests.data" :key="request.key">
            <th class="text-left">
              <router-link :to="`/c/${companyId}/request/${request.id}`" class="name-color">{{ request.sub_type }}
              </router-link>
              <div>{{ displayType(request.subtype) }}</div>
            </th>
            <td>{{ request.data ? request.data.project_name : null }}</td>
            <td>{{ getEmployeeName(request.created_by) }}</td>
            <td>{{ getEmployeePersonnelNumber(request.created_by) }}</td>
            <td>{{ created(request.created_at) }}</td>
            <td><span :style="'color:' + statusColor(request.status) ">{{ displayStatus(request.status) }}</span></td>
            <td class="text-right">
              <router-link :to="`/c/${companyId}/request/${request.id}`">
                <i class="icon-eye btn-icon text-primary font-weight-bold mx-2" role="button"></i>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-auto m-2">
          <ui-select
            :name="'bottom_pagination'"
            v-model="perPage"
            :clearable="false"
            :filterable="false"
            :placeholder="$t('Select')"
            :options="perPageOptionsSelect"
            @change="changePerPage"
            :key-name="'id'"
            :value-name="'id'"
            :label-name="'name'"
          />
        </div>
        <div v-if="requests.last_page > 1" class="col-auto my-auto">
          <el-pagination
            :current-page="page"
            :page-size="perPage"
            :page-sizes="perPageOptions"
            :total="requests.total"
            background
            layout="prev, pager, next"
            @current-change="goToPage"
            @prev-click="prevPage"
            @next-click="nextPage"
            @size-change="changePerPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'
import paginationMixin from '../CommonComponents/Pagination/mixinPagination'

export default {
  name: 'Requests',
  mixins: [momentMixin, paginationMixin],
  async created () {
    if (this.companyId) {
      await this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId)
      this.getRequests()
    }
  },
  data () {
    return {
      page: 1,
      perPage: 50,
      archive: [],
      archiveEnable: false,
      search: '',
      perPageOptions: [
        50,
        100,
        500
      ],
      perPageOptionsSelect: [
        {
          id: 50,
          name: '50'
        },
        {
          id: 100,
          name: '100'
        },
        {
          id: 500,
          name: '500'
        }
      ],
      timeoutId: null
    }
  },
  computed: {
    ...mapGetters(['requests']),
    employeesIndexed () {
      return this.$store.getters.employeesIndexed
    }
  },
  watch: {
    async companyId () {
      await this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId)
      this.getRequests()
    },
    archiveEnable () {
      this.page = 1
      if (this.archiveEnable) {
        this.getArchivedRequests()
      } else {
        this.getRequests()
      }
    },
    search () {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.page = 1
        if (this.archiveEnable) {
          this.getArchivedRequests()
        } else {
          this.getRequests()
        }
      }, 1000)
    }
  },
  methods: {
    changePerPage (val) {
      this.perPage = val
      this.page = 1
      if (this.archiveEnable) {
        this.getArchivedRequests()
      } else {
        this.getRequests()
      }
    },
    prevPage () {
      if (this.page > 1) {
        this.page--
        if (this.archiveEnable) {
          this.getArchivedRequests()
        } else {
          this.getRequests()
        }
      }
    },
    nextPage () {
      if (this.page < this.logs.last_page) {
        this.page++
        if (this.archiveEnable) {
          this.getArchivedRequests()
        } else {
          this.getRequests()
        }
      }
    },
    goToPage (page) {
      this.page = page
      if (this.archiveEnable) {
        this.getArchivedRequests()
      } else {
        this.getRequests()
      }
    },
    getRequests () {
      let companyId = ''
      if (this.$store.state.company.Id) {
        companyId = this.$store.state.company.Id
      } else {
        companyId = this.$store.getters.companyId
      }
      const payload = {
        companyId: companyId,
        page: this.page,
        perPage: this.perPage,
        search: this.search
      }
      this.$store.dispatch('getRequests', payload)
    },
    getArchivedRequests () {
      let companyId = ''
      if (this.$store.state.company.Id) {
        companyId = this.$store.state.company.Id
      } else {
        companyId = this.$store.getters.companyId
      }
      const payload = {
        q: 'archive',
        companyId: companyId,
        page: this.page,
        perPage: this.perPage,
        search: this.search
      }
      this.$store.dispatch('getRequests', payload)
    },
    created (dateBirth) {
      return this.fromZoneToZone(dateBirth, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateTimeFormat)
    },
    change () {
      this.archiveEnable = !this.archiveEnable
    },
    getEmployeeName (id) {
      const employee = this.employeesIndexed[id]

      return employee && (employee.full_name)
    },
    getEmployeePersonnelNumber (id) {
      const employee = this.employeesIndexed[id]
      return employee && employee.identifier ? employee.identifier : null
    },
    displayStatus (status) {
      // capitalize first
      status = status.charAt(0).toUpperCase() + status.slice(1)

      return this.$i18n?.t(status)
    },
    statusColor (status) {
      if (status === 'new') {
        return '#0aa4eb'
      } else if (status === 'confirmed') {
        return '#00ac60'
      } else if (status === 'declined' || status === 'expired') {
        return '#f6840b'
      } else {
        return ''
      }
    },
    displayType (subtype) {
      if (subtype === 'time_off') {
        return this.$i18n?.t('Time off')
      } else if (subtype === 'exchange') {
        return this.$i18n?.t('Shift Swap Request')
      } else if (subtype === 'move_to_user') {
        return this.$i18n?.t('Take shift from Free Board Request')
      } else if (subtype === 'move_to_freeboard') {
        return this.$i18n?.t('Shift to Free Board Request')
      } else if (subtype === 'change_shift') {
        return this.$i18n?.t('SHIFT_CHANGE_REQUEST')
      } else if (subtype === 'break_exchange') {
        return this.$i18n?.t('break_exchange')
      } else if (subtype === 'create_shift') {
        return this.$i18n?.t('create_shift')
      } else if (subtype === 'create_break') {
        return this.$i18n?.t('create_break')
      } else if (subtype === 'drop_to_employee') {
        return this.$i18n?.t('drop_to_employee')
      } else {
        return this.$i18n?.t(subtype)
      }
    },
    convertDate (date) {
      return this.toTimeZone(date).format(this.localeDateTimeFormat)
    }
  }
}
</script>

<style scoped>

</style>
