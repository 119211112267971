<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li v-if="$route.params.id" class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/templates`">
              <span class="breadcrumb-item">{{ $t('Shifts templates')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span v-if="templateId">{{ $t('Edit template') }}</span>
            <span v-if="!templateId">{{ $t('Add template') }}</span>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <Form ref="observe">
          <div class="row">
            <div class="col-lg-6">
              <ui-select
                id="schedule_select"
                name="schedule_select"
                :disabled="!!templateId"
                :label="$t('Schedule')"
                v-model="workTemplate.schedule_id"
                :clearable="false"
                :filterable="false"
                class="w-100"
                :validation="{required: true}"
                @change="changeSchedule"
                :options="scheduleChoices"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-9">
              <template-component
                v-if="isTemplateReady"
                :template="workTemplate"
                :selectedDays="days"
                :index="0"
                :type="schedule.schedule_type"
                :validate="validate"
                :showAdvanced="true"
                :customClass="'col-12 col-md-6'"
                :dummyInfo="breakModuleStatus"
                @activateBreaks="activateBreaks"
                @validationResult="createTemplate"
                @validateTemplates="validateTemplates">
              </template-component>
            </div>
          </div>
        </Form>
        <div class="row">
          <div class="col-lg-6">
            <div class="mx-auto text-center">
              <template v-if="templateId">
                <button
                  @click="removeTemplate"
                  class="btn btn-danger btn-circle shadow-sm">
                  {{ $t('Delete') }}
                </button>
                <button
                  @click="cloneTemplate"
                  class="btn btn-primary btn-circle shadow-sm mx-1">
                  {{ $t('Clone') }}
                </button>
              </template>
              <button class="btn btn-success rounded-pill shadow-sm" @click="switchValidator">{{ $t('SAVE_WEB') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import colorMixin from '@/mixins/mixinColors'
import TemplateComponent from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/SubComponents/TemplateComponent'
import { mapGetters } from 'vuex'
// import { toRaw } from 'vue'

export default {
  name: 'WorkTemplate',
  components: { TemplateComponent },
  mixins: [
    momentMixin,
    colorMixin,
    ToastMixin
  ],

  data () {
    return {
      workTemplate: {
        id: null,
        name: '',
        color: '#4e93e9',
        time_from: '10:00',
        time_to: '17:00',
        break_time: 0,
        night_shift: false,
        allow_break: false,
        users: [],
        is_active: false,
        schedule_id: null,
        schedule_type: 'manually',
        workplaces: [1, 1, 1, 1, 1, 1, 1],
        breaks: [],
        by_days: false,
        rate_per_shift: 0,
        marks: []
      },
      breakTimeChoices: [
        { id: 0, name: this.$t('None') },
        { id: 10, name: this.$t('10 min') },
        { id: 15, name: this.$t('15 min') },
        { id: 20, name: this.$t('20 min') },
        { id: 30, name: this.$t('30 min') },
        { id: 45, name: this.$t('45 min') },
        { id: 60, name: this.$t('60 min') },
        { id: 90, name: this.$t('90 min') }
      ],
      selected_template: undefined,
      isActive: {},
      validate: false,
      breakModuleStatus: {
        active: true
      },
      days: [],
      isTemplateReady: false,
      clone: false,
      validationErrors: 0
    }
  },
  computed: {
    ...mapGetters({ locale: 'locale', projects: 'projectsIndexed' }),
    companySalary () {
      return this.$store.getters.company.salary
    },
    schedules () {
      const schedules = this.$store.getters.schedules
      // console.log(123, this.projects)
      return schedules.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    scheduleChoices () {
      if (this.isEmployeeHasPermission('create project')) {
        return this.schedules
      }
      return this.schedules.filter(schedule => this.projects[schedule.project_id] && this.projects[schedule.project_id].role === 'manager')
    },
    templateId () {
      return this.workTemplate && this.workTemplate.id
    },
    schedule () {
      if (this.workTemplate && this.schedules && this.$store.getters.schedulesIndexed[this.workTemplate.schedule_id]) {
        return this.$store.getters.schedulesIndexed[this.workTemplate.schedule_id]
      }
      return {}
    }
  },

  created () {
    // console.log(this.clone)
    this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then(response => {
      // console.log(response)
      this.breakModuleStatus = response
    }).catch((error) => {
      if (error.response.data.module) {
        // console.log(error.response.data.module, this.locale)
        this.breakModuleStatus = {
          title: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].name : error.response.data.module.name,
          trial: error.response.data.module.trial_period,
          price: +error.response.data.module.price,
          perUser: +error.response.data.module.user_price,
          isActive: error.response.data.module.is_active,
          id: +error.response.data.module.id,
          helpCenter: error.response.data.module.help_center,
          description: error.response.data.module.locales[this.locale] ? error.response.data.module.locales[this.locale].short_description : error.response.data.module.short_description
        }
        // console.log(this.breakModuleStatus)
      }
    })

    this.$store.dispatch('getSchedules', this.companyId)

    if (this.$route.params.templateId !== undefined) {
      this.$store.dispatch('getTemplate', [this.$route.params.scheduleId, this.$route.params.templateId]).then((template) => {
        this.workTemplate = template
        this.workTemplate.marks2 = template.marks
        this.workTemplate.rate_per_shift = this.workTemplate.rate_per_shift !== null ? this.workTemplate.rate_per_shift : this.companySalary.basic_shift_rate
      })
    } else {
      this.workTemplate.rate_per_shift = this.companySalary.basic_shift_rate ? this.companySalary.basic_shift_rate : 0
      if (this.$store.getters.filterScheduleTemplate !== 'all') {
        this.workTemplate.schedule_id = +this.$store.getters.filterScheduleTemplate
      } else {
        this.workTemplate.schedule_id = this.schedules && this.schedules.length && this.scheduleChoices[0].id
      }
      this.setDays()
      this.prepareTemplate()
      this.isTemplateReady = true
    }
  },

  watch: {
    templateId () {
      this.setDays()
      this.prepareTemplate()
      this.isTemplateReady = true
    }
  },
  methods: {
    changeSchedule (val) {
      this.workTemplate.schedule_id = val
    },
    removeTemplate () {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then(() => {
        const name = this.workTemplate.name
        this.$store.dispatch('removeTemplate', [this.workTemplate.schedule_id, this.workTemplate.id]).then((response) => {
          this.toastSuccess([this.$i18n?.t('Template shift'), name, this.$i18n?.t('was successfully deleted.')].join(' '))
          this.$router.push(`/c/${this.companyId}/templates`)
        }).catch(error => {
          this.toastError(this.$i18n?.t(error.response.data.message))
        })
      }).catch(() => {})
    },
    cloneTemplate () {
      this.clone = true
      this.switchValidator()
    },
    activateBreaks () {
      this.breakModuleStatus = { active: true }
    },
    setDays () {
      this.days = [{
        long: this.$i18n?.t('Monday'),
        short: this.$i18n?.t('Mon'),
        selected: true,
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 0
      },
      {
        long: this.$i18n?.t('Tuesday'),
        short: this.$i18n?.t('Tue'),
        selected: true,
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 1
      },
      {
        long: this.$i18n?.t('Wednesday'),
        short: this.$i18n?.t('Wed'),
        selected: true,
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 2
      },
      {
        long: this.$i18n?.t('Thursday'),
        short: this.$i18n?.t('Thu'),
        selected: true,
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 3
      },
      {
        long: this.$i18n?.t('Friday'),
        short: this.$i18n?.t('Fri'),
        selected: true,
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 4
      },
      {
        long: this.$i18n?.t('Saturday'),
        short: this.$i18n?.t('Sat'),
        selected: this.schedule.schedule_type === 'by_pattern',
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 5
      },
      {
        long: this.$i18n?.t('Sunday'),
        short: this.$i18n?.t('Sun'),
        selected: this.schedule.schedule_type === 'by_pattern',
        disabled: this.schedule.schedule_type === 'by_pattern',
        index: 6
      }]
      const weekStart = this.$store.getters.company.salary.week_start
      // переставляем местами ворклейсы на каждый день, в зависимости от настроек дня начала недели
      const clonedTemplate = JSON.parse(JSON.stringify(this.workTemplate))
      // const clonedTemplate = Object.assign({}, this.workTemplate)
      const x = clonedTemplate.workplaces.splice(weekStart)
      clonedTemplate.workplaces = x.concat(clonedTemplate.workplaces)
      this.workTemplate = clonedTemplate
      // console.log(1, this.workTemplate)
      // перестраиваем порядок дней недели, начиная с дня, который задан как день начала недели в настройках компании
      const weekPart = this.days.splice(weekStart)
      this.days = weekPart.concat(this.days)
    },
    validateTemplates () {
      this.validationErrors++
    },
    switchValidator () {
      this.validationErrors = 0
      this.validate = !this.validate
    },
    createTemplate (val) {
      this.$refs.observe.validate().then(result => {
        if (result && val && this.validationErrors === 0) {
          this.saveTemplate()
        }
        this.switchValidator()
      })
    },
    prepareTemplate () {
      let workplaces = [1, 1, 1, 1, 1, 1, 1]
      let workplacesTotal = 1
      // let breaks = []
      let byDays = false
      // if (this.schedule.breaks) {
      //   breaks = this.schedule.breaks.filter(el => {
      //     return el.template_id === this.workTemplate.id
      //   })
      // }

      if (this.$route.params.templateId !== undefined) {
        workplaces = this.workTemplate.workplaces
        if ([...new Set(this.workTemplate.workplaces)].length > 1) byDays = true
        workplacesTotal = workplaces[0]
      }
      this.workTemplate.by_days = byDays
      // Vue.set(this.workTemplate, 'breaks', breaks)
      this.workTemplate.workplacesTotal = workplacesTotal
    },
    saveTemplate () {
      this.$refs.observe.validate().then(result => {
        // console.log(result, validOption)
        if (result.valid) {
          this.workTemplate.break_time = +this.workTemplate.break_time
          this.workTemplate.rate_per_shift = +this.workTemplate.rate_per_shift
          if (this.$route.params.templateId && this.clone === false) {
            this.$store.dispatch('editTemplate', [this.workTemplate.schedule_id, this.$route.params.templateId, this.workTemplate]).then((response) => {
              this.$router.push(`/c/${this.companyId}/templates`)
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          } else {
            if (this.workTemplate.allow_break) {
              this.workTemplate.breaks.forEach(breakItem => {
                breakItem.id = null
              })
            } else {
              this.workTemplate.breaks = []
            }
            this.$store.dispatch('saveTemplate', [this.workTemplate.schedule_id, this.workTemplate]).then((response) => {
              this.$router.push(`/c/${this.companyId}/templates`)
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .help-text-vue-swatches {
    padding-left: 10px;
    width: 80%;
    font-size: 12px;
    top: 37px;
  }
  .template-border {
    border: solid 1px rgb(215,215,215);
    border-radius: 7px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .font-blue {
    color: #0abaf2;
  }
</style>
