<template>
  <div>
    <div class="row mb-2 mx-auto">
        <span class="text-primary text-uppercase font-weight-bold">
          {{ $t("Main information") }}
        </span>
        <div class="ml-auto text-primary text-uppercase font-weight-bold">
          <span class="font-weight-bold" :class="appState.approved ? 'text-success' : 'text-warning'">{{ $t('Approved') }}</span>
        </div>
    </div>
    <Form ref="observe" as="div">
      <div class="row">
        <div class="col-lg-3">
          <div id="avatar_image_container" class="mt-5">
            <avatar
              v-if="showAvatar"
              :width="180"
              :borderRadius="0"
              :appId="appId"
              :placeholder="false"
              :cache="cacheAvatar">
            </avatar>
            <div align="center" class="mt-3">
              <ui-file-upload
                accept="image/*"
                name='file'
                :upload="{ url: url, headers: headers }"
                :auto-upload="true"
                :multiple="false"
                :show-file-list="false"
                :limit="1"
                @onSuccess="getAvatar"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 my-3">
          <ui-text-input
            id="name"
            :name="'name'"
            class="w-50"
            v-model="appState.name"
            :validation="{required: true, min: 3, max: 128}"
            :label="$t('Application name')"
            :hint="$t('APPLICATION_NAME_HINT')"
          />
        </div>
        <div class="col-lg-12 my-2">
          <ui-select
            name="category_id"
            :label="$t('Application category')"
            :hint="$t('APPLICATION_CATEGORY_HINT')"
            v-model="appState.category_id"
            :clearable="false"
            :filterable="false"
            class="w-50"
            :validation="{required: true}"
            :options="appCategories"
            :key-name="'id'"
            :label-name="'name'"
            :value-name="'id'"
          />
        </div>
        <div class="col-lg-12 my-1">
          <label class="control-label font-weight-bold" for="title">{{ $t('Description') }}
            <ui-hint :content="$t('APPLICATION_DESCRIPTION_HINT')" />
          </label>
          <div class="w-50">
            <tip-tap-editor v-model="appState.description" />
          </div>
        </div>
        <div class="col-lg-12 my-1">
          <label class="control-label font-weight-bold" for="title">{{ $t('Full description') }}
            <ui-hint :content="$t('APPLICATION_FULL_DESCRIPTION_HINT')" />
          </label>
          <div class="w-50">
            <tip-tap-editor v-model="appState.full_description" />
          </div>
        </div>
        <div class="col-lg-12 my-1">
          <ui-switch
            name="is_active"
            :value="appState.is_active"
            @change="val => appState.is_active = val"
            :active-text="$t('Is active')"
          />
        </div>
        <div class="col-lg-12 py-2">
          <button class="btn btn-success btn-circle shadow-sm m-1" @click="save">{{ $t('SAVE_WEB') }}</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColors from '@/mixins/mixinColors'
import ToastMixin from '@/mixins/ToastMixin'
import Avatar from '@/components/CommonComponents/Avatar'
import TipTapEditor from '@/components/Editor/TipTapEditor'

export default {
  name: 'MainInfo',
  mixins: [errorMixin, mixinColors, ToastMixin],
  components: {
    TipTapEditor,
    Avatar
  },
  data () {
    return {
      appId: this.$route.params.appId,
      cacheAvatar: true,
      url: '',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
      },
      showAvatar: true,
      appState: {}
    }
  },
  props: {
    app: Object
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    appCategories () {
      return this.$store.getters.appCategories
    }
  },
  created () {
    this.appState = this.app
    this.$store.dispatch('getAppCategories')
    this.url = process.env.VUE_APP_ROOT_API + `companies/${this.$store.getters.companyId}/developer/app/${this.appId}/icon`
  },
  methods: {
    onEditorChange (event) {
      this.appState.description = event.html
    },
    onFullDescChange (event) {
      this.appState.full_description = event.html
    },
    save () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          const payload = {
            name: this.appState.name,
            description: this.appState.description,
            full_description: this.appState.full_description,
            is_active: this.appState.is_active,
            category_id: this.appState.category_id
          }

          this.$store.dispatch('updateDeveloperApp', [this.companyId, this.appId, payload]).then((response) => {
            this.toastSuccess(this.$i18n?.t('App was updated'))
          }).catch((error) => {
            const errorMessage = error.response.data.message
            if (error.response.status === 400 && errorMessage) {
              this.toastError(this.$i18n?.t(errorMessage))
            }
          })
        }
      })
    },
    getAvatar () {
      this.cacheAvatar = false
      this.showAvatar = false
      // возвращаем флаг кэширования в тру, чтобы можно было менять аватар без перезагрузки страницы несколько раз подряд
      const self = this
      setTimeout(function () {
        self.cacheAvatar = true
        self.showAvatar = true
      }, 500)
      this.$eventBus.emit('updateLogo')
      // this.cacheAvatar = true
    }
  }
}
</script>

<style scoped>

</style>
