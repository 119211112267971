<template>
  <modal-component
    :id="'HandleBreak'"
    data-cy="click-break-modal-component"
    @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('BREAK_MODAL_HEADER')}}</h5>
    </template>
    <template #body>
      <div
        class="accordion"
        id="accordionDate"
      >
        <el-divider />
        <div
          v-if="isEmployeeHasPermission('get-breaks-by-company-admin') || rolesByProjects[currentProjectId] === 'manager'"
          @click="changeCollapse('edit')"
          id="headingOne"
          type="button"
          data-toggle="collapse"
          data-target="#edit"
          aria-expanded="true"
          aria-controls="edit"
        >
          <div class="row">
            <div class="col">
              {{$t('BREAK_EDIT_HEADER')}}
            </div>
          </div>
        </div>
        <div
          id="edit"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionDate"
        >
          <div class="row mt-2">
            <div class="col-md-12">
              {{breakEvent.break.title}}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 py-1">
              <ui-datetime-picker
                :label="$t('BREAK_START')"
                name="fromTime"
                v-model="startTime"
                :validation="{required: true}"
                :clearable="false"
                @change="onDateChange('from')"
              />
            </div>
            <div class="col-md-6 py-1">
              <ui-datetime-picker
                :label="$t('BREAK_END')"
                name="toTime"
                v-model="endTime"
                :validation="{required: true}"
                :clearable="false"
                @change="onDateChange('to')"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6 py-1">
              <span class="text-secondary small">{{ $t('PLANNED_BREAK_START') }} {{plannedStartDateTime}}</span>
            </div>
            <div class="col-md-6 py-1">
              <span class="text-secondary small">{{ $t('PLANNED_BREAK_END') }} {{plannedEndDateTime}}</span>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6 py-1">
              <ui-checkbox
                name="isAbsent"
                :label="$t('IS_BREAK_ABSENT')"
                v-model="isAbsent"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 p-1">
              <div class="d-flex">
                <button
                  class="btn btn-danger btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="deleteBreak">
                  {{ $t('Delete') }}
                </button>
                <button
                  class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="save">
                  {{ $t('SAVE_WEB') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <el-divider />
        <div
          @click="changeCollapse('exchange')"
          id="headingTwo"
          type="button"
          data-toggle="collapse"
          data-target="#exchange"
          aria-expanded="true"
          aria-controls="exchange"
        >
          <div class="row">
            <div class="col">
              {{$t('BREAK_EXCHANGE_HEADER')}}
            </div>
          </div>
        </div>
        <div
          id="exchange"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionDate"
        >
          <template v-if="breakEvent.break.extendedProps.requestId">
            <div class="m-5 text-center">{{$t('HAS_REQUEST')}}</div>
          </template>
          <template v-if="breaksToExchange.length > 0 && !breakEvent.break.extendedProps.requestId">
            <div class="my-1">
              {{$t('CURRENT_BREAK')}}
            </div>
            <div class="my-1">
              {{breakStart}} - {{breakEnd}} {{breakEvent.break.title}}
            </div>
            <div class="my-1">
              {{$t('EXCHANGE_TO_BREAKS')}}
            </div>
            <el-container style="height: 300px; overflow-y: auto; background-color: #efefef">
              <el-radio-group v-model="exchangeToId" class="m-1 w-100" name="break_exchange">
                <template v-for="breakSlot in breaksToExchange" :key="breakSlot.id">
                  {{breakEvent.shift.start}} {{breakSlot.time_from}}
                  <div class="my-1 bg-white pt-1 px-1 w-100">
                    <el-radio
                      :label="breakSlot.id">
                      {{breakSlot.time_from}} - {{breakSlot.time_to}} {{breakSlot.shift.employee.full_name}}
                    </el-radio>
                  </div>
                </template>
              </el-radio-group>
            </el-container>
            <div class="mx-auto text-center mt-2">
              <button
                class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                data-cy="add-user-button"
                @click="makeExchange">
                {{ $t('MAKE_EXCHANGE') }}
              </button>
            </div>
          </template>
          <template v-if="!breakEvent.break.request_id && breaksToExchange.length < 1">
            <div class="m-5 text-center">{{$t('NO_BREAKS_TO_EXCHANGE')}}</div>
          </template>
        </div>
        <el-divider />
      </div>
      <div>
<!--      <el-collapse v-model="activeName" accordion>-->
<!--        <el-collapse-item v-if="isEmployeeHasPermission('get-breaks-by-company-admin') || rolesByProjects[currentProjectId] === 'manager'" :title="$t('BREAK_EDIT_HEADER')" name="edit">-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item :disabled="!isEmployeeHasPermission('get-breaks-by-company')" :title="$t('BREAK_EXCHANGE_HEADER')" name="exchange">-->
<!--        </el-collapse-item>-->
<!--      </el-collapse>-->
    </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [
    momentMixin,
    ToastMixin
  ],
  name: 'ClickBreakModal',

  data () {
    return {
      breaksToExchange: [],
      startTime: moment().format(this.backendDateTimeFormat),
      endTime: moment().format(this.backendDateTimeFormat),
      exchangeToId: null,
      activeName: 'exchange',
      defaultTimeStart: '08:00',
      defaultTimeEnd: '21:00',
      breakStart: '',
      breakEnd: '',
      isAbsent: false
    }
  },

  props: {
    breakEvent: Object
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId'
    }),
    plannedStartDateTime () {
      return this.breakEvent.break.extendedProps.plannedStart
    },
    plannedEndDateTime () {
      return this.breakEvent.break.extendedProps.plannedEnd
    },
    pickerOptions () {
      return {
        disabledDate: (date) => {
          return moment(date, this.localeDateFormat).isBefore(moment(this.breakEvent.shift.start, this.localeDateFormat).add(-1, 'day')) ||
            moment(date, this.localeDateFormat).isAfter(moment(this.breakEvent.shift.end, this.localeDateFormat))
        },
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      }
    }
  },

  watch: {
    breakEvent () {
      this.init()
    }
  },

  methods: {
    init () {
      if (this.isEmployeeHasPermission('get-breaks-by-company-admin') || this.rolesByProjects[this.currentProjectId] === 'manager') {
        this.activeName = 'edit'
      } else {
        this.activeName = 'exchange'
      }
      this.startTime = moment(this.breakEvent.break.extendedProps.realStart).format(this.backendDateTimeFormat)
      this.endTime = moment(this.breakEvent.break.extendedProps.realEnd).format(this.backendDateTimeFormat)
      this.isAbsent = this.breakEvent.break.extendedProps.isAbsent
      this.breakStart = moment(this.breakEvent.break.extendedProps.realStart).format(this.localeDateTimeFormat)
      this.breakEnd = moment(this.breakEvent.break.extendedProps.realEnd).format(this.localeDateTimeFormat)
      // console.log(this.startTime, this.endTime)
      this.$store.dispatch('getBreaksToExchange', [this.breakEvent.projectId, this.breakEvent.break.id.split('__sp__')[1]]).then(response => {
        response.forEach(breakSlot => {
          breakSlot.time_from = this.fromZoneToZone(breakSlot.time_from, 'UTC', this.$store.getters.actualTZ).format(this.backendTimeFormat)
          breakSlot.time_to = this.fromZoneToZone(breakSlot.time_to, 'UTC', this.$store.getters.actualTZ).format(this.backendTimeFormat)
        })
        this.breaksToExchange = response.sort((a, b) => {
          if (a.time_from > b.time_from) return 1
          if (a.time_from < b.time_from) return -1
          return 0
        })
      })
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.exchangeToId = null
    },
    makeExchange () {
      if (this.exchangeToId) {
        if (this.isEmployeeHasPermission('get-breaks-by-company')) {
          const formData = {
            exchange_to: this.exchangeToId
          }
          this.$store.dispatch('exchangeBreaks', [this.breakEvent.projectId, this.breakEvent.break.id.split('__sp__')[1], formData]).then(response => {
            if (response) {
              this.toastSuccess(this.$i18n?.t('Exchange successfully made'))
              this.$eventBus.emit('refetchEvents')
              document.getElementById('modalCloseButton').click()
              this.closeModal()
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        } else {
          const formData = {
            subtype: 'break_exchange',
            break_from: this.breakEvent.break.id.split('__sp__')[1],
            break_to: this.exchangeToId
          }
          this.$store.dispatch('setRequest', [this.$store.getters.companyId, formData]).then(resolve => {
            if (resolve) {
              this.toastSuccess(this.$i18n?.t('Request successfully send'))
              this.$eventBus.emit('refetchEvents')
              document.getElementById('modalCloseButton').click()
              this.closeModal()
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      }
    },
    onDateChange (picker) {
      if (picker === 'to') {
        if (moment(this.endTime).isBefore(moment(this.startTime))) {
          this.startTime = this.endTime
        }
      }
      if (picker === 'from') {
        if (moment(this.startTime).isAfter(moment(this.endTime))) {
          this.endTime = this.startTime
        }
      }
    },
    save () {
      const payload = {
        time_from: this.fromZoneToZone(this.startTime, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
        time_to: this.fromZoneToZone(this.endTime, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
        is_absent: this.isAbsent
      }
      this.$store.dispatch('updateBreaks', [this.breakEvent.projectId, this.breakEvent.break.id.split('__sp__')[1], payload]).then(response => {
        this.$eventBus.emit('refetchEventsLazy')
        document.getElementById('modalCloseButton').click()
        this.closeModal()
      }).catch((err) => {
        if (err.response.data.message === 'key_attendance_clock_in') {
          this.toastError(this.$i18n?.t('key_attendance_clock_in'))
        } else {
          this.toastError(this.$i18n?.t('SHIFT_RANGE_CONFLICT'))
        }
      })
    },
    deleteBreak () {
      const body = document.getElementsByTagName('body')
      body[0].setAttribute('style', 'padding-right: 0px;')
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('deleteBreak', [this.breakEvent.projectId, this.breakEvent.break.id.split('__sp__')[1]]).then(response => {
          this.$eventBus.emit('refetchEventsLazy')
          document.getElementById('modalCloseButton').click()
          this.closeModal()
          this.toastSuccess(this.$i18n?.t('BREAK_DELETE_SUCCESS'))
        }).catch(() => {
          this.toastError(this.$i18n?.t('SHIFT_RANGE_CONFLICT'))
        })
      }).catch(() => {})
    },
    changeCollapse (val) {
      this.activeName = val
      setTimeout(() => {
        const el = document.getElementById(val)
        if (!el.classList.contains('show')) {
          el.classList.add('show')
        }
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
