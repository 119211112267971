<template>
  <modal-component :id="'ImportLoadStatisticModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Import load statistic')}}</h5>
    </template>
    <template #body>
      <div>
        <img width="100%" height="100%" src="../../../../../public/static/images/App/load_statistic_inport_example.png"/>
        <div class="font-weight-bold my-2">
          {{ $t('Need an example?') }} <a :href="`${publicPath}static/files/Load_Example.xlsx`">{{ $t('Download our template') }}</a>
        </div>
        <div class="row">
          <div class="col-6">
            <ui-select
              :label=" $t('STEP_VALUE')"
              :hint="$t('STEP_VALUE_HINT')"
              name="actionType"
              v-model="step"
              :clearable="false"
              :filterable="false"
              class="w-100"
              :options="stepList"
              key-name="value"
              label-name="label"
              value-name="value"
              :disabled="true"
              :validation="{required: true}"
            />
          </div>
          <div class="col-6">
            <div class="font-weight-bold">
              {{ $t('REPEATED_DATES_ACTION')}} <ui-hint :content="$t('REPEATED_DATES_ACTION_HINT')"/>
            </div>
            <div class="mt-2">
              <ui-radio
                name="replace"
                :label="$t('Replace')"
                v-model="repeatedDatesAction"
                :set-value="'replace'"
              />
              <ui-radio
                name="skip"
                :label="$t('Skip')"
                v-model="repeatedDatesAction"
                :set-value="'skip'"
              />
            </div>
          </div>
        </div>
        <div class="mt-3">
          <ui-file-upload
            name='file'
            :upload="upload"
            :auto-upload="false"
            :multiple="false"
            :limit="1"
            @file="getFile"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          type="button"
          class="btn btn-success rounded-pill shadow-sm"
          aria-label="Close"
          @click="save">
          <span aria-hidden="true">{{ $t('Import a file')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import momentMixin from '../../../../mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'ImportLoadDemandModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin],
  data () {
    return {
      step: 60,
      stepList: [
        {
          label: this.$t('60 min'),
          value: 60
        }
        // add steps here
      ],
      repeatedDatesAction: 'replace',
      publicPath: process.env.BASE_URL,
      file: '',
      size: null,
      fileList: [],
      upload: {
        url: process.env.VUE_APP_ROOT_API + `companies/${this.$store.getters.company.id}/load-statistic/upload/`,
        headers: {
          Authorization: 'api-key ' + localStorage.getItem('api_key')
        }
      },
      fileLimit: 10240000
    }
  },
  props: {
    scheduleId: Number,
    project_tz: String,
    getFilePreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('close')
    },
    getFile (data) {
      this.file = data.file
      this.size = data.size
    },
    save () {
      if (this.file) {
        if (this.size / 1000 > this.fileLimit) {
          return
        }
        const formData = new FormData()
        formData.append('step', this.step)
        formData.append('repeated_dates_action', this.repeatedDatesAction)
        formData.append('file', this.file)
        if (!this.getFilePreview) {
          this.$store.dispatch('uploadLoadStatistic', [this.scheduleId, formData]).then(response => {
            this.file = null
            this.size = null
            this.toastSuccess(this.$i18n?.t('Upload completed!'))
            this.$emit('reload')
            this.closeModal()
          })
        } else {
          formData.append('time_zone', this.project_tz)
          this.$store.dispatch('previewDemandFile', [this.$store.getters.company.id, formData]).then(response => {
            this.file = null
            this.size = null

            const data = {
              repeated_dates_action: this.repeatedDatesAction,
              step: this.step,
              response: response
            }

            this.$emit('updatePreviewData', data)
            this.closeModal()
          })
        }
      } else {
        this.toastError(this.$i18n?.t('Chose a file!'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
