import { HTTP } from '@/api/http_utils'

let promises = {}

const state = {}

const getters = {}

const mutations = {}

const actions = {
  clearModulesCache () {
    promises = {}
  },
  getModulesAllAvailable ({ commit }, companyId) {
    return new Promise(resolve => {
      HTTP.get('/modules', { params: { company_id: companyId } }).then(modules => {
        resolve(modules)
      })
    })
  },
  isModuleActive ({ commit }, [companyId, modulePermissionId]) {
    const promiseKey = `companies/${companyId}/modules/${modulePermissionId}/status`

    if (promises[promiseKey]) {
      console.info('repeating requests isModuleActive')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`companies/${companyId}/modules/${modulePermissionId}/status`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  updateModuleSettings ({ commit, rootState, dispatch }, [companyId, modulePermissionId, data]) {
    // console.log(55, rootState)
    return new Promise((resolve, reject) => {
      HTTP.patch(`companies/${companyId}/modules/${modulePermissionId}`, data).then(response => {
        // console.log(34, response, rootState.company.ModulesByCompany)
        const modules = []
        rootState.company.ModulesByCompany.forEach(module => {
          if (+module.id === +response.id) {
            modules.push(response)
          } else {
            modules.push(module)
          }
        })
        commit('GET_MODULES_BY_COMPANY', modules)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
