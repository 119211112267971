<template>
  <div>
    <div class="row mb-2 mx-auto">
      <ol class="breadcrumb bg-transparent p-0 m-0">
        <li class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/schedules`">
            <span class="breadcrumb-item">{{ $t('Schedules')}}</span>
          </router-link>
        </li>
        <li v-if="$route.params.scheduleId" class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/schedule/${$route.params.scheduleId}`">
            <span class="breadcrumb-item">{{ $t('Edit schedule') }} {{ schedule.mainInfo.title }}</span>
          </router-link>
        </li>
        <li class="breadcrumb-item title-text active" aria-current="page">
          <span v-if="$route.params.scheduleId" class="title-text">{{ $t('EDIT_SCHEDULE_MASTER') }}</span>
          <span v-else>{{ $t('ADD_SCHEDULE_MASTER') }}</span>
        </li>
      </ol>
    </div>
    <div class="row pb-3">
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body">
            <el-steps :active="steps.step" finish-status="success" :space="75" direction="vertical">
              <el-step v-for="(stepName, index) in steps.stepsDataCommon" :key="index" description="">
                <template #title>
                  <div>
                    <span @click="clickNavigate(index)" :style="{cursor: (index < steps.step) ? 'pointer' : ''}">{{ $t(stepName) }}</span>
                  </div>
                </template>
              </el-step>
              <template v-if="isSimple">
                <el-step v-for="(stepName, index) in steps.stepsDataSimple" :key="index + 2" description="">
                  <template #title>
                    <div>
                      <span @click="clickNavigate(index + 2)" :style="{cursor: (index + 2 < steps.step) ? 'pointer' : ''}">{{ $t(stepName) }}</span>
                    </div>
                  </template>
                </el-step>
              </template>
              <template v-if="isAdvanced || isByAvailability">
                <el-step v-for="(stepName, index) in steps.stepsDataPro" :key="index + 2" description="">
                  <template #title>
                    <div>
                    <span
                      @click="clickNavigate(index + 2)" :style="{cursor: (index + 2 < steps.step) ? 'pointer' : ''}">{{ $t(stepName) }}</span>
                    </div>
                  </template>
                </el-step>
              </template>
              <template v-if="isByPattern">
                <el-step v-for="(stepName, index) in steps.stepsDataPattern" :key="index + 2" description="">
                  <template #title>
                    <div>
                      <span @click="clickNavigate(index + 2)" :style="{cursor: (index + 2 < steps.step) ? 'pointer' : ''}">
                        {{ $t(stepName) }}
                      </span>
                    </div>
                  </template>
                </el-step>
              </template>
            </el-steps>
          </div>
        </div>

        <statistic
          class="mt-2"
          v-if="Object.keys(schedule.mainInfo).length > 0"
          :schedule="schedule">
        </statistic>
      </div>
      <div v-if="loaded" class="col-lg-9">
        <div class="card">
          <div class="card-body">
            <main-information
              v-if="steps.step === 0"
              :scheduleMainInfo="schedule.mainInfo"
              :isExist="isExist"
              :scheduleId="schedule.scheduleId"
              @projectChanged="() =>  this.schedule.employees.users = []"
              @next="next">
            </main-information>
            <schedule-type
              v-if="steps.step === 1"
              :type="schedule.scheduleType"
              @next="next"
              @prev="prev">
            </schedule-type>
            <schedule-simple
              v-if="isSimple"
              :step="steps.step"
              :settings="schedule.scheduleSettings"
              :employees="schedule.employees"
              :project="schedule.mainInfo.project"
              :shiftsControl="schedule.mainInfo.shiftsControl"
              :schedule="schedule"
              @save="save"
              @next="next"
              @prev="prev">
            </schedule-simple>
            <schedule-advanced
              v-if="isAdvanced"
              :step="steps.step"
              :settings="schedule.scheduleSettings"
              :workingTime="schedule.workingTime"
              :employees="schedule.employees"
              :project="schedule.mainInfo.project"
              :shiftsControl="schedule.mainInfo.shiftsControl"
              :previewShifts="previewShifts"
              :conflictShifts="conflictShifts"
              :startDate="schedule.mainInfo.period[0]"
              :minDurationWorkingMinutesDay="minDurationWorkingMinutesDay"
              :requiredWorkers="requiredWorkers"
              :schedule="schedule"
              @save="save"
              @next="next"
              @prev="prev">
            </schedule-advanced>
            <schedule-by-availability
              v-if="isByAvailability"
              :step="steps.step"
              :settings="schedule.scheduleSettings"
              :workingTime="schedule.workingTime"
              :employees="schedule.employees"
              :project="schedule.mainInfo.project"
              :shiftsControl="schedule.mainInfo.shiftsControl"
              :previewShifts="previewShifts"
              :conflictShifts="conflictShifts"
              :startDate="schedule.mainInfo.period[0]"
              :minDurationWorkingMinutesDay="minDurationWorkingMinutesDay"
              :requiredWorkers="requiredWorkers"
              :schedule="schedule"
              @save="save"
              @next="next"
              @prev="prev">
            </schedule-by-availability>
            <schedule-by-pattern
              v-if="isByPattern"
              :step="steps.step"
              :patternType="schedule.patternType"
              :settings="schedule.scheduleSettings"
              :employees="schedule.employees"
              :project="schedule.mainInfo.project"
              :shiftsControl="schedule.mainInfo.shiftsControl"
              :previewShifts="previewShifts"
              :conflictShifts="conflictShifts"
              :startDate="schedule.mainInfo.period[0]"
              :requiredWorkers="requiredWorkers"
              :schedule="schedule"
              @save="save"
              @next="next"
              @prev="prev">
            </schedule-by-pattern>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainInformation from '@/components/Schedule/CreateScheduleComponents/MainInformation'
import ScheduleType from '@/components/Schedule/CreateScheduleComponents/ScheduleType'
import ScheduleSimple from '@/components/Schedule/CreateScheduleComponents/ScheduleSimple'
import ScheduleAdvanced from '@/components/Schedule/CreateScheduleComponents/ScheduleAdvanced'
import ScheduleByAvailability from '@/components/Schedule/CreateScheduleComponents/ScheduleByAvailability'
import ScheduleByPattern from '@/components/Schedule/CreateScheduleComponents/ScheduleByPattern'
import Statistic from '@/components/Schedule/CommonScheduleComponents/StatisticComponent'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
// import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
// import Vue from 'vue'
import 'moment-weekday-calc'

// const Loader = defineComponent({
//   template: `<div class="spinner-modal-content text-center my-4">
//                 <sync-loader
//                   :loading=true
//                   color="#32c5d2"
//                   size="15px">
//                 </sync-loader>
//               </div>`,
//   components: {
//     SyncLoader: SyncLoader
//   }
// })

export default {
  name: 'CreateSchedule',
  mixins: [momentMixin],
  components: {
    MainInformation: MainInformation,
    ScheduleType: ScheduleType,
    ScheduleSimple: ScheduleSimple,
    ScheduleAdvanced: ScheduleAdvanced,
    ScheduleByAvailability: ScheduleByAvailability,
    ScheduleByPattern: ScheduleByPattern,
    Statistic: Statistic
  },
  data () {
    return {
      steps: {
        step: 0,
        stepsDataCommon: [
          'Basic information',
          'Schedule type'
        ],
        stepsDataSimple: [
          'Data for shifts',
          'Employees'
        ],
        stepsDataPro: [
          'Data for shifts',
          'Working time settings',
          'Employees',
          'Preview schedule'
        ],
        stepsDataPattern: [
          'Pattern type',
          'Data for shifts',
          'Employees',
          'Preview schedule'
        ]
      },
      schedule: {
        scheduleId: null,
        scheduleType: null,
        patternType: null,
        mainInfo: {},
        rules: [],
        scheduleSettings: {},
        workingTime: {},
        employees: {
          // checkAvailability: false,
          users: []
        }
      },
      isExist: null,
      minDurationWorkingMinutesDay: 1,
      requiredWorkers: 1,
      previewShifts: {},
      conflictShifts: [],
      loaded: false,
      loader: null
    }
  },
  created () {
    this.schedule.scheduleId = this.$route.params.scheduleId || null
    this.isExist = this.$route.params.scheduleId || null
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    isManually () {
      return this.steps.step > 1 && this.schedule.scheduleType === 'manually'
    },
    isSimple () {
      return this.steps.step > 1 && this.schedule.scheduleType === 'simple'
    },
    isAdvanced () {
      return this.steps.step > 1 && this.schedule.scheduleType === 'advanced'
    },
    isByAvailability () {
      return this.steps.step > 1 && this.schedule.scheduleType === 'by_availability'
    },
    isByPattern () {
      return this.steps.step > 1 && this.schedule.scheduleType === 'by_pattern'
    },
    masterRouteIdParam () {
      return this.$route.params.scheduleId
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    schedule: {
      handler: function () {
        if (this.schedule.scheduleType === 'by_pattern') {
          if (this.schedule.patternType === '5/2') {
            this.steps.stepsDataPattern = [
              'Pattern type',
              'Data for shifts',
              'Working time settings',
              'Employees',
              'Preview schedule'
            ]
          } else {
            this.steps.stepsDataPattern = [
              'Pattern type',
              'Data for shifts',
              'Employees',
              'Preview schedule'
            ]
          }
        } else if (this.schedule.scheduleType === 'advanced' || this.schedule.scheduleType === 'by_availability') {
          if (this.schedule.scheduleSettings.activeTab && this.schedule.scheduleSettings.activeTab === 'demand') {
            this.steps.stepsDataPro = [
              'Data for shifts',
              'Setting up templates based on load',
              'Working time settings',
              'Employees',
              'Preview schedule'
            ]
          } else {
            this.steps.stepsDataPro = [
              'Data for shifts',
              'Working time settings',
              'Employees',
              'Preview schedule'
            ]
          }
        }
      },
      deep: true
    },
    masterRouteIdParam (current) {
      this.schedule.scheduleId = current || null
      this.isExist = current || null
      this.loaded = false
      if (!current) {
        this.clearSchedule()
      } else {
        this.preloadSchedule()
      }
      this.loaded = true
    }
  },
  methods: {
    init () {
      if (this.schedule.scheduleId) {
        this.preloadSchedule()
      } else {
        this.clearSchedule()
      }
    },
    clearSchedule () {
      this.schedule = {
        generatedId: null,
        scheduleId: null,
        scheduleType: null,
        patternType: null,
        mainInfo: {},
        rules: [],
        scheduleSettings: {},
        workingTime: {},
        employees: {
          // checkAvailability: false,
          users: []
        }
      }
      this.loaded = true
    },
    preloadSchedule () {
      this.$store.dispatch('getScheduleById', this.schedule.scheduleId).then((schedule) => {
        // console.log(schedule)
        // this.toTimeZone(schedule.date_from).format(this.backendDateFormat)
        const project = this.$store.getters.projectById(schedule.project_id)

        this.schedule.scheduleType = schedule.schedule_type
        this.schedule.patternType = schedule.pattern_type
        this.schedule.breaks = schedule.breaks
        this.schedule.allow_break = schedule.breaks ? schedule.breaks.length > 0 : false
        this.schedule.mainInfo.title = schedule.name
        this.schedule.mainInfo.color = schedule.color
        this.schedule.rules = schedule.rules ? schedule.rules : []
        this.schedule.mainInfo.period = []
        this.schedule.mainInfo.period.push(schedule.date_from)
        this.schedule.mainInfo.period.push(schedule.date_to)
        this.schedule.mainInfo.project = schedule.project_id
        this.schedule.mainInfo.projectName = project.name
        this.schedule.mainInfo.projectTZ = project.time_zone
        this.schedule.scheduleSettings.days = {
          0: {
            long: this.$i18n?.t('Monday'),
            short: this.$i18n?.t('Mon'),
            selected: true,
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 0
          },
          1: {
            long: this.$i18n?.t('Tuesday'),
            short: this.$i18n?.t('Tue'),
            selected: true,
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 1
          },
          2: {
            long: this.$i18n?.t('Wednesday'),
            short: this.$i18n?.t('Wed'),
            selected: true,
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 2
          },
          3: {
            long: this.$i18n?.t('Thursday'),
            short: this.$i18n?.t('Thu'),
            selected: true,
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 3
          },
          4: {
            long: this.$i18n?.t('Friday'),
            short: this.$i18n?.t('Fri'),
            selected: true,
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 4
          },
          5: {
            long: this.$i18n?.t('Saturday'),
            short: this.$i18n?.t('Sat'),
            selected: this.schedule.scheduleType === 'by_pattern',
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 5
          },
          6: {
            long: this.$i18n?.t('Sunday'),
            short: this.$i18n?.t('Sun'),
            selected: this.schedule.scheduleType === 'by_pattern',
            disabled: this.schedule.scheduleType === 'by_pattern',
            index: 6
          }
        }
        schedule.work_days.forEach(day => {
          // console.log(day)
          this.schedule.scheduleSettings.days[day].selected = true
        })
        this.schedule.mainInfo.payRate = schedule.rate
        // this.schedule.employees.checkAvailability = schedule.availability
        this.schedule.mainInfo.shiftsControl = {}
        this.schedule.mainInfo.shiftsControl.shiftsExchange = schedule.shift_exchange
        this.schedule.mainInfo.shiftsControl.managerConfirmation = schedule.shift_exchange_confirmation
        this.schedule.mainInfo.shiftsControl.shift_exchange_same_position_only = schedule.shift_exchange_same_position_only

        this.schedule.mainInfo.shiftsControl.putOnFreeBoard = schedule.allow_put_on_freeboard
        this.schedule.mainInfo.shiftsControl.putOnFreeboardConfirmation = schedule.allow_put_on_freeboard_confirmation

        this.schedule.mainInfo.shiftsControl.takeFromFreeboard = schedule.allow_take_from_freeboard
        this.schedule.mainInfo.shiftsControl.takeFromFreeboardConfirmation = schedule.allow_take_from_freeboard_confirmation

        this.schedule.mainInfo.shiftsControl.visibleEveryone = schedule.visible_everyone
        this.schedule.mainInfo.shiftsControl.visible_everyone_type = schedule.visible_everyone_type
        this.schedule.mainInfo.shiftsControl.putOnFreeBoard = schedule.allow_put_on_freeboard
        // this.schedule.mainInfo.shiftsControl.bind_break = schedule.bind_break
        this.schedule.mainInfo.shiftsControl.week_overtime_control_type = schedule.week_overtime_control_type
        this.schedule.mainInfo.shiftsControl.working_hours = schedule.working_hours
        this.schedule.mainInfo.shiftsControl.break_between_shifts = schedule.break_between_shifts
        this.schedule.mainInfo.shiftsControl.minimum_breaks_in_row = schedule.minimum_breaks_in_row
        this.schedule.mainInfo.shiftsControl.maximum_breaks_in_row = schedule.maximum_breaks_in_row
        this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts = schedule.minimum_break_between_shifts
        this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts_for_women = schedule.minimum_break_between_shifts_for_women
        this.schedule.mainInfo.shiftsControl.minimum_break_in_row_per_week = schedule.minimum_break_in_row_per_week
        this.schedule.mainInfo.shiftsControl.linkShifts = schedule.link_shifts

        this.schedule.mainInfo.shiftsControl.minimum_shift_duration = schedule.minimum_shift_duration
        this.schedule.mainInfo.shiftsControl.maximum_shift_duration = schedule.maximum_shift_duration
        this.schedule.mainInfo.shiftsControl.minimum_coverage = schedule.minimum_coverage
        this.schedule.mainInfo.shiftsControl.maximum_coverage = schedule.maximum_coverage
        this.schedule.mainInfo.shiftsControl.create_templates = schedule.create_templates

        this.schedule.mainInfo.shiftsControl.allowShiftRequest = schedule.allow_shift_request
        this.schedule.mainInfo.shiftsControl.allowShiftRequestConfirmation = schedule.allow_shift_request_confirmation

        this.schedule.mainInfo.shiftsControl.allowBreakRequest = schedule.allow_break_request
        this.schedule.mainInfo.shiftsControl.allowBreakRequestConfirmation = schedule.allow_break_request_confirmation

        this.schedule.mainInfo.shiftsControl.allow_drop_to_employee = schedule.allow_drop_to_employee
        this.schedule.mainInfo.shiftsControl.allow_drop_to_employee_confirmation = schedule.allow_drop_to_employee_confirmation

        this.schedule.mainInfo.shiftsControl.allow_split_shift_by_employee = schedule.allow_split_shift_by_employee

        this.schedule.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates = schedule.allow_exchange_break_in_diff_templates

        this.schedule.scheduleSettings.templates = []
        // console.log(preloadIds)
        this.schedule.scheduleSettings.preloadedTemplatesData = {}
        this.schedule.scheduleSettings.preloadedTemplates = []

        const scheduleTemplates = this.$store.getters.templatesByScheduleId(schedule.id)
        scheduleTemplates.forEach(template => {
          if (template.is_active) {
            // console.log('template', template)
            template.by_days = false
            if ([...new Set(template.workplaces)].length > 1) template.by_days = true
            this.schedule.scheduleSettings.preloadedTemplatesData[template.id] = template
            this.schedule.scheduleSettings.preloadedTemplates.push(template.id)
          }
        })
        // console.log('templates', this.schedule.scheduleSettings.preloadedTemplatesData)
        // this.schedule.workingTime['timeControl'] = schedule.check_time
        this.schedule.workingTime.weekends = schedule.week_days_off
        schedule.users.forEach(user => {
          this.schedule.employees.users.push(user.employee_id)
        })
        // console.log(123, this.schedule)
        this.loaded = true
      })
    },
    next (value) {
      this.steps.step++
      this.setSchedule(value)
    },
    prev (value) {
      this.schedule.generatedId = null
      this.steps.step--
      this.setSchedule(value)
    },
    save (value) {
      if (value.data.preview) {
        this.next(value)
        this.generateSchedule(value.data.preview)
      } else {
        this.setSchedule(value)
        this.generateSchedule(value.data.preview)
      }
    },
    generateSchedule (isPreview) {
      // console.log('A')
      const data = this.setScheduleData(isPreview)
      if (this.schedule.scheduleId) {
        // редактирование расписания
        data.id = this.schedule.scheduleId
        // console.log('B', data)
        this.rebuildSchedule(isPreview, data)
      } else {
        // создание нового расписания
        this.createSchedule(isPreview, data)
      }
    },
    rebuildSchedule (isPreview, data) {
      // console.log('C')
      this.loaderRun()
      this.$store.dispatch('rebuildSchedule', data).then((response) => {
        this.loader.close()
        this.responseProcessing(response, isPreview)
      }).catch((error) => {
        this.toastError(this.$t(error.response.data.message))
        this.loader.close()
      })
    },
    loaderRun () {
      this.loader = this.$loading({
        lock: true,
        text: this.$i18n?.t('Your schedule is currently generating. Please stand by a few minutes.'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
    },
    createSchedule (isPreview, data) {
      this.loaded = false
      this.loaderRun()
      this.$store.dispatch('addSchedule', data).then((response) => {
        // this.$eventBus.emit('checkSteps')
        this.loader.close()
        this.responseProcessing(response, isPreview)
        this.loaded = true
      }).catch((err) => {
        this.previewShifts = {}
        this.loader.close()
        this.loaded = true
        if (err.response.data.errors &&
          typeof err.response.data.errors === 'object') {
          Object.keys(err.response.data.errors).forEach(key => {
            const error = err.response.data.errors[key]
            this.toastError(this.$t(error[0]))
          })
        } else {
          this.toastError(this.$i18n?.t(err.response.data.message))
        }
      })
    },
    responseProcessing (response, isPreview) {
      if (response) {
        if (isPreview) {
          this.previewShifts = {}
          this.schedule.generatedId = response.generated_id
          this.conflictShifts = response.conflict_shifts
          response.preview_data.forEach((shift, index) => {
            this.previewShifts[index] = shift
          })
          // console.log(this.previewShifts)
        } else {
          // console.log(1)
          localStorage.setItem('currentProject', this.schedule.mainInfo.project)
          this.toastSuccess(this.$i18n?.t('Refresh the page if you didn\'t see shifts in a minute.'))
          this.$router.push(`/c/${this.companyId}/schedule/company`)
        }
      } else {
        this.toastError(this.$t('GENERATION_FAILED'))
      }
    },
    setScheduleData (isPreview) {
      const generateBy = this.schedule.scheduleSettings.activeTab || 'template'
      let preloadTemplatesData = []

      if (generateBy === 'template') {
        this.schedule.scheduleSettings.preloadedTemplates.forEach(templateId => {
          this.schedule.scheduleSettings.preloadedTemplatesData[templateId].template_id = this.schedule.scheduleSettings.preloadedTemplatesData[templateId]['id']
          // delete this.schedule.scheduleSettings.preloadedTemplatesData[templateId]['id']
          preloadTemplatesData.push(this.schedule.scheduleSettings.preloadedTemplatesData[templateId])
        })
      }

      preloadTemplatesData = preloadTemplatesData.concat(this.schedule.scheduleSettings.templates)

      const templates = []
      preloadTemplatesData.forEach(tmpl => {
        if (tmpl.breaks && tmpl.breaks.length > 0) {
          const breaks = []
          tmpl.breaks.forEach(brk => {
            brk.time_from = moment(brk.time_from, 'HH:mm').format(this.backendTimeFormat)
            brk.time_to = moment(brk.time_to, 'HH:mm').format(this.backendTimeFormat)
            breaks.push(brk)
          })
          tmpl.break_time = 0
          tmpl.breaks = breaks
        }
        if (tmpl.time_to === '24:00') tmpl.time_to = '00:00'
        templates.push(tmpl)
      })

      let createTemplates = this.schedule.mainInfo.shiftsControl.create_templates

      if (generateBy === 'template') {
        createTemplates = true
      }

      const schedule = {
        schedule_type: this.schedule.scheduleType,
        pattern_type: this.schedule.patternType,
        generate_by: generateBy,
        demand: this.schedule.mainInfo.shiftsControl.scheduleDemand,
        name: this.schedule.mainInfo.title,
        color: this.schedule.mainInfo.color,
        date_from: this.fromZoneToZone(moment(this.schedule.mainInfo.period[0], this.backendDateFormat).format(this.backendDateTimeFormat), this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat),
        date_to: this.fromZoneToZone(moment(this.schedule.mainInfo.period[1], this.backendDateFormat).add(1, 'day').format(this.backendDateTimeFormat), this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat),
        company_id: parseInt(this.$store.getters.companyId),
        project_id: parseInt(this.schedule.mainInfo.project),
        work_days: this.workingDays(),
        rules: this.schedule.rules,
        users: this.schedule.employees.users,
        preview: isPreview,
        rate: this.schedule.mainInfo.payRate,
        // availability: this.schedule.employees.checkAvailability,
        week_overtime_control_type: this.schedule.mainInfo.shiftsControl.week_overtime_control_type,
        link_shifts: this.schedule.mainInfo.shiftsControl.linkShifts,
        working_hours: this.schedule.mainInfo.shiftsControl.working_hours,
        break_between_shifts: this.schedule.mainInfo.shiftsControl.break_between_shifts,
        minimum_breaks_in_row: this.schedule.mainInfo.shiftsControl.minimum_breaks_in_row,
        maximum_breaks_in_row: this.schedule.mainInfo.shiftsControl.maximum_breaks_in_row,
        minimum_break_between_shifts: this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts,
        minimum_break_between_shifts_for_women: this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts_for_women,
        minimum_break_in_row_per_week: this.schedule.mainInfo.shiftsControl.minimum_break_in_row_per_week,
        minimum_shift_duration: +this.schedule.mainInfo.shiftsControl.minimum_shift_duration,
        maximum_shift_duration: +this.schedule.mainInfo.shiftsControl.maximum_shift_duration,
        minimum_coverage: +this.schedule.mainInfo.shiftsControl.minimum_coverage,
        maximum_coverage: +this.schedule.mainInfo.shiftsControl.maximum_coverage,
        create_templates: createTemplates,
        visible_everyone: this.schedule.mainInfo.shiftsControl.visibleEveryone,
        visible_everyone_type: this.schedule.mainInfo.shiftsControl.visible_everyone_type,
        shift_exchange: this.schedule.mainInfo.shiftsControl.shiftsExchange,
        shift_exchange_confirmation: this.schedule.mainInfo.shiftsControl.managerConfirmation,
        shift_exchange_same_position_only: this.schedule.mainInfo.shiftsControl.shift_exchange_same_position_only,
        allow_put_on_freeboard: this.schedule.mainInfo.shiftsControl.putOnFreeBoard,
        allow_put_on_freeboard_confirmation: this.schedule.mainInfo.shiftsControl.putOnFreeboardConfirmation,
        allow_shift_request_confirmation: this.schedule.mainInfo.shiftsControl.allowShiftRequestConfirmation,
        allow_shift_request: this.schedule.mainInfo.shiftsControl.allowShiftRequest,
        allow_break_request_confirmation: this.schedule.mainInfo.shiftsControl.allowBreakRequestConfirmation,
        allow_split_shift_by_employee: this.schedule.mainInfo.shiftsControl.allow_split_shift_by_employee,
        allow_break_request: this.schedule.mainInfo.shiftsControl.allowBreakRequest,
        allow_take_from_freeboard: this.schedule.mainInfo.shiftsControl.takeFromFreeboard,
        allow_take_from_freeboard_confirmation: this.schedule.mainInfo.shiftsControl.takeFromFreeboardConfirmation,
        allow_exchange_break_in_diff_templates: this.schedule.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates,
        // bind_break: this.schedule.mainInfo.shiftsControl.bind_break,
        // shifts_templates_ids: this.schedule.scheduleSettings.preloadedTemplates,
        shifts_templates: templates,
        generated_id: this.schedule.generatedId,
        week_days_off: this.schedule.workingTime.weekends ? parseInt(this.schedule.workingTime.weekends) : 0,
        out_schedule_shifts_action: this.schedule.mainInfo.outScheduleShiftsAction,
        allow_drop_to_employee: this.schedule.mainInfo.shiftsControl.allow_drop_to_employee,
        allow_drop_to_employee_confirmation: this.schedule.mainInfo.shiftsControl.allow_drop_to_employee_confirmation
        // check_time: this.schedule.workingTime.timeControl
      }
      return schedule
    },
    createEmptySchedule () {
      this.$store.dispatch('getProjectUsers', this.schedule.mainInfo.project).then((response) => {
        if (response.users.length > 0) {
          const ids = []
          response.users.forEach(user => {
            ids.push(user.id)
          })
          const schedule = {
            schedule_type: this.schedule.scheduleType,
            pattern_type: null,
            name: this.schedule.mainInfo.title,
            color: this.schedule.mainInfo.color,
            date_from: this.fromZoneToZone(moment(this.schedule.mainInfo.period[0], this.backendDateFormat).format(this.backendDateTimeFormat), this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat),
            date_to: this.fromZoneToZone(moment(this.schedule.mainInfo.period[1], this.backendDateFormat).add(1, 'day').format(this.backendDateTimeFormat), this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat),
            company_id: this.$store.getters.companyId,
            project_id: this.schedule.mainInfo.project,
            users: ids,
            work_days: [0, 1, 2, 3, 4, 5, 6],
            preview: false,
            shifts_templates: [],
            rate: this.schedule.mainInfo.payRate,
            visible_everyone: this.schedule.mainInfo.shiftsControl.visibleEveryone,
            visible_everyone_type: this.schedule.mainInfo.shiftsControl.visible_everyone_type,
            week_overtime_control_type: this.schedule.mainInfo.shiftsControl.week_overtime_control_type,
            working_hours: this.schedule.mainInfo.shiftsControl.working_hours,
            break_between_shifts: this.schedule.mainInfo.shiftsControl.break_between_shifts,
            minimum_breaks_in_row: this.schedule.mainInfo.shiftsControl.minimum_breaks_in_row,
            maximum_breaks_in_row: this.schedule.mainInfo.shiftsControl.maximum_breaks_in_row,
            minimum_break_between_shifts: this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts,
            minimum_break_between_shifts_for_women: this.schedule.mainInfo.shiftsControl.minimum_break_between_shifts_for_women,
            minimum_break_in_row_per_week: this.schedule.mainInfo.shiftsControl.minimum_break_in_row_per_week,
            minimum_shift_duration: this.schedule.mainInfo.shiftsControl.minimum_shift_duration,
            maximum_shift_duration: this.schedule.mainInfo.shiftsControl.maximum_shift_duration,
            minimum_coverage: this.schedule.mainInfo.shiftsControl.minimum_coverage,
            maximum_coverage: this.schedule.mainInfo.shiftsControl.maximum_coverage,
            create_templates: this.schedule.mainInfo.shiftsControl.create_templates,
            link_shifts: this.schedule.mainInfo.shiftsControl.linkShifts,
            shift_exchange: this.schedule.mainInfo.shiftsControl.shiftsExchange,
            shift_exchange_confirmation: this.schedule.mainInfo.shiftsControl.managerConfirmation,
            shift_exchange_same_position_only: this.schedule.mainInfo.shiftsControl.shift_exchange_same_position_only,
            allow_put_on_freeboard: this.schedule.mainInfo.shiftsControl.putOnFreeBoard,
            allow_put_on_freeboard_confirmation: this.schedule.mainInfo.shiftsControl.putOnFreeboardConfirmation,
            allow_shift_request: this.schedule.mainInfo.shiftsControl.allowShiftRequest,
            allow_shift_request_confirmation: this.schedule.mainInfo.shiftsControl.allowShiftRequestConfirmation,
            allow_break_request: this.schedule.mainInfo.shiftsControl.allowBreakRequest,
            allow_break_request_confirmation: this.schedule.mainInfo.shiftsControl.allowBreakRequestConfirmation,
            allow_split_shift_by_employee: this.schedule.mainInfo.shiftsControl.allow_split_shift_by_employee,
            allow_take_from_freeboard: this.schedule.mainInfo.shiftsControl.takeFromFreeboard,
            allow_take_from_freeboard_confirmation: this.schedule.mainInfo.shiftsControl.takeFromFreeboardConfirmation,
            allow_exchange_break_in_diff_templates: this.schedule.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates,
            allow_drop_to_employee: this.schedule.mainInfo.shiftsControl.allow_drop_to_employee,
            allow_drop_to_employee_confirmation: this.schedule.mainInfo.shiftsControl.allow_drop_to_employee_confirmation,
            hours_per_month: 0,
            hours_per_week: 0,
            hours_per_day: 0,
            week_days_off: 0
          }
          this.createSchedule(false, schedule)
        } else {
          this.steps.step = 1
        }
      })
    },
    workingDays () {
      const workingDays = []
      Object.keys(this.schedule.scheduleSettings.days).forEach(key => {
        if (this.schedule.scheduleSettings.days[key].selected) {
          workingDays.push(parseInt(key))
        }
      })
      return workingDays
    },
    clickNavigate (step) {
      if (step < this.steps.step) {
        this.steps.step = step
      }
    },
    setSchedule (value) {
      window.scrollTo(0, 0)
      switch (value.step) {
        case 'mainInfo':
          this.schedule.mainInfo = value.data
          this.isExist = null
          break
        case 'scheduleType':
          if (this.schedule.scheduleType !== value.data) this.schedule.scheduleSettings = {}
          this.schedule.scheduleType = value.data
          if (this.isManually) {
            if (this.schedule.scheduleId) {
              // console.log('rebuild')
              const data = this.setScheduleData(false)
              data.id = this.schedule.scheduleId
              delete data.shifts_templates
              this.rebuildSchedule(false, data)
            } else {
              // console.log('create')
              this.createEmptySchedule()
            }
          }
          break
        case 'scheduleSettings':
          // console.log(555, value.data)
          this.schedule.scheduleSettings = value.data
          // console.log(0, this.schedule.scheduleSettings)
          if (this.schedule.scheduleType === 'by_pattern') {
            this.employeesNeededByPattern()
          }
          this.minDurationWorkingMinutesDay = this.longestTemplate()
          break
        case 'employees':
          this.schedule.employees.users = value.data.users
          // this.schedule.employees.checkAvailability = value.data.checkAvailability
          break
        case 'workingTime':
          this.schedule.workingTime = value.data
          if (this.schedule.scheduleType === 'advanced' || this.schedule.scheduleType === 'by_availability') {
            this.employeesNeededAdvanced()
          }
          break
        case 'patternType':
          this.schedule.patternType = value.data
          // console.log(33, this.schedule.patternType)
          break
        case 'preview':
          break
        default:
          break
      }
    },
    employeesNeededAdvanced () {
      // todo
      let required = 0
      if (this.schedule.scheduleSettings.activeTab === 'demand') {
        let demandByDays = {}

        this.schedule.scheduleSettings.templates.forEach(template => {
          if (template.demand) {
            Object.entries(template.demand).forEach(([key, value]) => {
              if (!demandByDays.hasOwnProperty(key)) {
                demandByDays[key] = value
              } else {
                demandByDays[key] += value
              }
            })
          }
        })
        required = parseInt(Math.max(...Object.values(demandByDays)))
      } else {
        this.schedule.scheduleSettings.preloadedTemplates.forEach(value => {
          // console.log(0, this.schedule.scheduleSettings.preloadedTemplatesData[value])
          required += this.requiredUsersByTemplate(this.schedule.scheduleSettings.preloadedTemplatesData[value])
        })
        this.schedule.scheduleSettings.templates.forEach(template => {
          // console.log(0, template)
          required += this.requiredUsersByTemplate(template)
        })
      }

      this.requiredWorkers = Math.ceil(required)
    },
    employeesNeededByPattern () {
      let required = 0
      // console.log(1, this.schedule.scheduleSettings.preloadedTemplates)
      this.schedule.scheduleSettings.preloadedTemplates.forEach(value => {
        required += parseInt(Math.max(...Object.values(this.schedule.scheduleSettings.preloadedTemplatesData[value].workplaces)))
      })
      // console.log(2)
      this.schedule.scheduleSettings.templates.forEach(template => {
        required += parseInt(Math.max(...Object.values(template.workplaces)))
      })
      // console.log(required, this.schedule.patternType)
      if (this.schedule.patternType === '1/1') {
        required *= 2
      }
      if (this.schedule.patternType === '1/2') {
        required *= 3
      }
      if (this.schedule.patternType === '1/3') {
        required *= 4
      }
      if (this.schedule.patternType === '2/2') {
        required *= 2
      }
      if (this.schedule.patternType === '2/2/alternation') {
        required *= 2
      }
      if (this.schedule.patternType === '2/2/3') {
        required *= 2
      }
      if (this.schedule.patternType === '3/3') {
        required *= 2
      }
      if (this.schedule.patternType === '4/2') {
        required = Math.ceil(required * 1.5)
      }
      if (this.schedule.patternType === 'day/night/48') {
        required *= 2
      }
      if (this.schedule.patternType === '5/2') {
        required = 0
        this.schedule.scheduleSettings.preloadedTemplates.forEach(value => {
          required += parseInt(Math.ceil(Object.values(this.schedule.scheduleSettings.preloadedTemplatesData[value].workplaces).reduce((a, b) => parseInt(a) + parseInt(b), 0) / 5.0))
        })

        this.schedule.scheduleSettings.templates.forEach(value => {
          required += parseInt(Math.ceil(Object.values(value.workplaces).reduce((a, b) => parseInt(a) + parseInt(b), 0) / 5.0))
        })
      }

      if (this.schedule.patternType === '5/2/6/1') {
        required = 0
        this.schedule.scheduleSettings.preloadedTemplates.forEach(value => {
          const workplaces = Object.values(this.schedule.scheduleSettings.preloadedTemplatesData[value].workplaces).reduce((a, b) => parseInt(a) + parseInt(b), 0) / 7.0
          required += workplaces * (30 / 23.6)
        })

        this.schedule.scheduleSettings.templates.forEach(value => {
          const workplaces = Object.values(value.workplaces).reduce((a, b) => parseInt(a) + parseInt(b), 0) / 7.0
          required += workplaces * (30 / 23.6)
        })

        required = parseInt(Math.ceil(required))
      }

      this.requiredWorkers = required
    },
    requiredUsersByTemplate (template) {
      let shitsByTemplate = 0
      for (const key in template.workplaces) {
        if (this.schedule.scheduleSettings.days[key].selected) {
          shitsByTemplate = shitsByTemplate + parseInt(template.workplaces[key], 10)
        }
      }
      // console.log(template, shitsByTemplate)
      // console.log('schedule', this.schedule)
      const userWeekends = this.schedule.workingTime.weekends
      // console.log('userWeekends', userWeekends)
      let companyWeekends = 0
      for (const key in this.schedule.scheduleSettings.days) {
        if (!this.schedule.scheduleSettings.days[key].selected) companyWeekends++
      }
      // console.log('companyWeekends', companyWeekends)

      let numWorkDays = 7 - userWeekends
      if (numWorkDays < companyWeekends) {
        numWorkDays = companyWeekends
      }

      let needUsers = (shitsByTemplate / numWorkDays.toFixed(2))
      // console.log('needUsers', needUsers)
      if (needUsers < Math.max(...Object.values(template.workplaces))) {
        needUsers = Math.max(...Object.values(template.workplaces))
      }

      return needUsers
      // console.log(1, 7 - companyWeekends, 7 - userWeekends, template.workplaces)
      // if (userWeekends < companyWeekends) userWeekends = companyWeekends
      // return Math.ceil(Math.max(...Object.values(template.workplaces)) * (7 - companyWeekends) / (7 - userWeekends))
      // if (this.schedule.workingTime.workingHoursMonth > 0) {
      // let workingDays = this.workingDays()
      // workingDays = workingDays.map(function (day) {
      //   return day + 1
      // })
      //
      // // продолжительность шедуля в месяцах
      // let scheduleDurationAsMonth = moment().isoWeekdayCalc(this.schedule.mainInfo.period[0], this.schedule.mainInfo.period[1], [1, 2, 3, 4, 5, 6, 7]) / 30
      //
      // // количество рабочих дней в шедуле за заданый период с учетом выбранных рабочих дней
      // let scheduleWorkingDaysDurationAsDays = moment().isoWeekdayCalc(this.schedule.mainInfo.period[0], this.schedule.mainInfo.period[1], workingDays)
      //
      // // рабочее время шедуля в часах, согласно темплейта
      // let scheduleDurationByTemplateAsHours = this.periodDuration(template.time_from, template.time_to, 'HH:mm').asHours() * scheduleWorkingDaysDurationAsDays
      //
      // // количество работников, необходимых для одного рабочего места с учетом разрешенных рабочих часов в месяц
      // // рабочее время шедуля по темплейту / (разрешенные рабочие часы в месяц * продолжительность шедуля в месяцах)
      // let workersRequiredForSingleWorkplace = scheduleDurationByTemplateAsHours / (this.schedule.workingTime.workingHoursMonth * scheduleDurationAsMonth)
      //
      // // необходимое количество работников для темплейта
      // // количество работников, необходимых для одного рабочего места * количество рабочих мест с округлением вверх
      // return Math.ceil(workersRequiredForSingleWorkplace * template.workplaces)
      // }
      // return template.workplaces
    },
    longestTemplate () {
      const durations = []
      // console.log(56, this.schedule.scheduleSettings.preloadedTemplatesData)
      this.schedule.scheduleSettings.preloadedTemplates.forEach(value => {
        const timeFrom = this.schedule.scheduleSettings.preloadedTemplatesData[value].time_from
        const timeTo = this.schedule.scheduleSettings.preloadedTemplatesData[value].time_to
        const duration = this.periodDuration(timeFrom, timeTo, 'HH:mm')
        durations.push(duration.asMinutes())
      })
      this.schedule.scheduleSettings.templates.forEach(template => {
        const duration = this.periodDuration(template.time_from, template.time_to, 'HH:mm')
        durations.push(duration.asMinutes())
      })
      return Math.max.apply(Math, durations)
    },
    /**
     * @param from String
     * @param to String
     * @param format String date format
     * @returns {moment.Duration}
     */
    periodDuration (from, to, format) {
      const durationMs = moment(to, format).diff(moment(from, format))
      return moment.duration(durationMs)
    }
  }
}
</script>

<style>
  .el-step__head.text-primary {
    color: #28a745!important;
    border-color: #28a745!important;
  }
  .el-step__title.text-primary {
    color: #28a745!important;
  }
</style>
