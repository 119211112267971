<template>
  <Form ref="observer" class="row" @submit="addUsersToSchedule">
    <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
      {{ $t("Add users to schedule") }}
      <ui-hint :content="$t('ADD_USERS_TO_SCHEDULE_HINT')"/>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <ui-multi-select
        name="addUsers"
        :label="$t('Add users')"
        v-model="addUsers.addUsers"
        :filterable="true"
        :options="addUsersChoices"
        :validation="{required: true}"
        :key-name="'key'"
        :label-name="'name'"
        :value-name="'id'"
      />
      <ui-date-picker
        :label="$t('Add users starting from the date')"
        name="start_date"
        v-model="addUsers.addDate"
        :validation="{required: true}"
        :clearable="false"
        data-vv-validate-on="change"
        :disabled-date="disabledDate"
      />
      <div class="mt-2">{{ $t('Users will work starting from a selected date')}}</div>
      <div v-if="this.schedule && this.schedule.scheduleType === 'by_availability'">
        <ui-select
          name="type"
          :label="$t('Addition type')"
          :hint="$t('ADDITIONAL_TYPE_HINT')"
          v-model="addUsers.addType"
          :clearable="false"
          :filterable="true"
          class="w-100"
          :options="addTypeChoices"
          :key-name="'key'"
          :label-name="'name'"
          :value-name="'id'"
        />
        <span class="mt-2 text-center">{{ $t('_ADD_USER_ADD_TYPE_DESC') }}</span>
      </div>
    </div>
    <div class="col-12 py-2">
      <button
        class="btn btn-success rounded-pill shadow-sm"
        :disabled="disabled"
      >
        {{ $t('SAVE_WEB') }}
      </button>
    </div>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'AddUsers',
  mixins: [
    errorMixin,
    momentMixin
  ],

  data () {
    return {
      addUsers: {
        addUsers: [],
        addDate: '',
        addType: 'without_shifts',
        preview: true
      },
      addUsersChoices: [],
      addTypeChoices: [
        {
          id: 'without_shifts',
          name: this.$i18n?.t('Add without shifts')
        }
        // {
        //   id: 0,
        //   name: this.$i18n?.t('From free board')
        // }
      ],
      disabled: false
    }
  },

  props: {
    schedule: Object,
    projectUsers: Array,
    preloadUsers: Object
  },

  created () {
    this.createUserChoises()
    // console.log(this.schedule)
    if (this.schedule && this.schedule.scheduleType === 'by_availability') {
      this.addTypeChoices.push({
        id: 'by_availability',
        name: this.$i18n?.t('By user availability')
      })
    }
  },

  watch: {
    scheduleUsersCount () {
      this.createUserChoises()
    },
    projectUsers () {
      this.createUserChoises()
    }
  },

  computed: {
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    scheduleUsersCount () {
      return this.schedule.employees.users.length
    }
  },

  methods: {
    disabledDate (date) {
      const from = this.toSelectedTimeZone(this.schedule.mainInfo.period[0], this.schedule.mainInfo.projectTZ).format(this.backendDateFormat)
      return moment.utc(date).isBefore(from)
    },
    addUsersToSchedule (values, { resetForm }) {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.disabled = true

          const formData = {
            users: this.addUsers.addUsers,
            add_type: this.addUsers.addType,
            date_from: moment(this.addUsers.addDate).format(this.backendDateTimeFormat),
            preview: this.addUsers.preview
          }

          this.$store.dispatch('addUsers', [this.$route.params.scheduleId, formData]).then((response) => {
            resetForm()
            this.disabled = false
            this.$emit('getSchedule')
            this.toastSuccess(this.$i18n?.t('Schedule was successfully changed'))
            this.addUsersChoices = this.addUsersChoices.filter(user => !this.addUsers.addUsers.includes(user.id))
            this.addUsers = {
              addUsers: [],
              addDate: '',
              addType: 'without_shifts',
              preview: true
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },
    createUserChoises () {
      this.addUsersChoices = []
      this.projectUsers.forEach((id) => {
        if (!this.schedule.employees.users.includes(id) && this.preloadUsers[id]) {
          this.addUsersChoices.push({
            id: id,
            name: this.preloadUsers[id].full_name
          })
        }
      })
      this.addUsersChoices.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })
    }
  }
}
</script>

<style>

</style>
