<template>
  <el-dropdown @command="(e) => {this.$emit('command', e)}">
    <span class="el-dropdown-link">
      <slot></slot>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="choice in items"
          :command="choice.id"
          :key="choice.id">
          {{choice.title}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>

export default {
  name: 'dropdown',
  props: ['content', 'items']
}
</script>
