<template>
  <el-dialog
    :title="$t('ATTENDANCE_MODAL_SELECT')"
    v-model:visible="visible"
    custom-class="rounded"
    :width="modalWidth"
    center>
    <div class="p-3">
      <template v-if="next.type === 'shift_start'">
        <div class="row" v-for="shift in next.data" :key="shift.id ? shift.id : shift.schedule_id + '__sh'">
          <div class="col">
            <el-radio v-model="object_id" :label="shift.id ? shift.id : shift.schedule_id">
              <span class="text-wrap">
                <span class="user-mark" :style="{background: shift.color}"></span>
                <span class="px-1">
                  <template v-if="!shift.id">
                      <i role="button" class="icon-calendar"></i> {{ getScheduleName(shift.schedule_id) }}
                      ({{getTimeRange(shift)}})
                  </template>
                  <template v-else>
                    {{getTimeRange(shift)}}
                  </template>
                </span>
                {{shift.template ? shift.template.name : null}}
              </span>
            </el-radio>
          </div>
        </div>
      </template>
      <template v-if="next.type === 'break_start'">
        <div class="row" v-for="breakItem in next.data" :key="breakItem.id + '__br'">
          <div class="col">
            <el-radio v-model="object_id" :label="breakItem.id">
              <span class="text-wrap text-break">
                <span class="user-mark" :style="{background: 'rgb(171, 205, 239)'}"></span>
                <span class="px-1">
                  {{toTimeZone(breakItem.time_from).format(localeTimeFormatElementUi)}} -
                  {{toTimeZone(breakItem.time_to).format(localeTimeFormatElementUi)}}
                </span>
                {{breakItem.break ? $t(breakItem.break.title) : null}}
              </span>
            </el-radio>
          </div>
        </div>
      </template>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-success rounded-pill shadow-sm mx-2"
            data-dismiss="modal"
            aria-label="Close" @click="proceedAttendance(next.type, object_id)">
            <span aria-hidden="true">{{ $t('FIRE_ATTENDANCE_EVENT')}}</span>
          </button>
          <button type="button"
                  @click="closeModal"
                  class="btn btn-success rounded-pill shadow-sm mx-2"
                  aria-label="Close">
            <span aria-hidden="true">{{ $t('Cancel')}}</span>
          </button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
// import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'AttendanceChoiceComponent',
  mixins: [momentMixin],
  data () {
    return {
      object_id: null,
      visible: false,
      windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    }
  },
  props: {
    next: Object,
    dialogAttendanceVisible: Boolean
  },
  created () {
    this.visible = this.dialogAttendanceVisible
    this.object_id = this.next.data[0].id ? this.next.data[0].id : this.next.data[0].schedule_id
  },
  computed: {
    modalWidth () {
      if (this.windowWidth < 600) {
        return '100%'
      }
      return '400px'
    }
  },
  watch: {
    visible (val) {
      if (!this.visible) {
        this.$emit('proceedCloseAttendance')
      }
    },
    dialogAttendanceVisible () {
      this.visible = this.dialogAttendanceVisible
    }
  },
  methods: {
    proceedAttendance (modalType, objectId) {
      let scheduleId = null
      // shift id is not specified, we pass the schedule id
      if (!this.next.data[0].id) {
        scheduleId = objectId
        objectId = null
      }
      this.$emit('proceedAttendance', [modalType, objectId, scheduleId])
      this.visible = false
    },
    closeModal () {
      this.$emit('proceedCloseAttendance')
      this.visible = false
    },
    getTimeRange (shift) {
      return this.toTimeZone(shift.time_from).format(this.localeTimeFormatElementUi) + ' - ' + this.toTimeZone(shift.time_to).format(this.localeTimeFormatElementUi)
    },
    getScheduleName (scheduleId) {
      const schedule = this.$store.getters.scheduleById(scheduleId)
      if (schedule) {
        return schedule.name
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
