<template>
  <modal-component :id="'EditUserModal'"
                   :closeModalId="'EditUserModalClose'"
                   @closeModal="closeModal"
                   :modalClass="'modal-xl'">
    <template #header>
      <h5 class="modal-title">{{ $t('EDIT_USER_MODAL_TITLE')}}</h5>
    </template>
    <template #body>
      <user :userId="userId" />
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import User from '@/components/User/user/User'

export default {
  components: {
    User,
    ModalComponent
  },
  name: 'UserEditModalComponent',
  data () {
    return {}
  },
  props: {
    userId: Number
  },
  created () {},
  methods: {
    closeModal () {
      // document.getElementById('EditUserModalClose').click()
      this.$emit('hideUserEditModal')
      // this.$parent.$emit('AddUser')
    }
  }
}
</script>

<style scoped>

</style>
