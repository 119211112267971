<template>
  <div>
    <div class="row mb-2 mx-auto">
      <ol class="breadcrumb bg-transparent p-0 m-0">
        <li class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/schedules`">
            <span class="breadcrumb-item">{{ $t('Schedules')}}</span>
          </router-link>
        </li>
        <li class="breadcrumb-item title-text active" aria-current="page">
          <span class="title-text">{{ $t('Edit schedule') }} {{ statistic.mainInfo.title }}</span>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <div class="card card-small">
          <div class="card-body">
            <div id="dropdown-vertical" class="dropdown d-flex d-md-none">
              <button class="btn btn-primary dropdown-toggle w-100" type="button" @click="toggleMenu">
                {{ $t(buttonTitle) }}
              </button>
            </div>
            <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical" :class="{'d-none': !showTabs}">
              <a v-for="(title, key) in tabsData" :key="key"
                 :href="'#' +  key"
                 class="nav-link pl-3 pr-1 rounded"
                 :class="{active: activeTab === key}"
                 data-toggle="pill" role="tab"
                 :aria-controls="'v-pills-' + key" aria-selected="true"
                 @click="activateTab(key)">
                {{ $t(title) }}
              </a>
            </div>
          </div>
        </div>
        <statistic
          v-if="loaded"
          class="my-3"
          :schedule="statistic">
        </statistic>
      </div>
      <div class="col-lg-9">
        <div class="card card-small">
          <div class="card-body">
            <main-settings
              v-if="activeTab === 'mainInfo' && loaded"
              :schedule="statistic"
              @updateMainInfo="updateMainInfo">
            </main-settings>

            <pay-rate-schedule
              v-if="activeTab === 'payRate' && loaded"
              :schedule="schedule"
              :projectUsers="projectUsers"
              :preloadUsers="preloadUsers">
            </pay-rate-schedule>

            <add-users
              v-if="activeTab === 'addUsers' && loaded"
              @getSchedule="getSchedule"
              :schedule="statistic"
              :projectUsers="projectUsers"
              :preloadUsers="preloadUsers">
            </add-users>

            <remove-users
              v-if="activeTab === 'removeUsers' && loaded"
              @getSchedule="getSchedule"
              :schedule="statistic"
              :projectUsers="projectUsers"
              :preloadUsers="preloadUsers">
            </remove-users>

            <resize-schedule
              v-if="activeTab === 'resizeSchedule' && loaded"
              @getSchedule="getSchedule"
              :scheduleId="statistic.scheduleId">
            </resize-schedule>

            <load-demand
              v-if="activeTab === 'loadDemand' && loaded"
              :scheduleId="scheduleId"
              :project="statistic.project"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainSettings from './EditScheduleComponents/MainSettings'
import AddUsers from './EditScheduleComponents/AddUsers'
import RemoveUsers from './EditScheduleComponents/RemoveUsers'
import ResizeSchedule from './EditScheduleComponents/ResizeSchedule'
import PayRateSchedule from './EditScheduleComponents/PayRate'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import Statistic from '@/components/Schedule/CommonScheduleComponents/StatisticComponent'
import { mapGetters } from 'vuex'
import LoadDemand from '@/components/Schedule/EditScheduleComponents/LoadDemand'
// import moment from 'moment'

export default {
  name: 'EditSchedule',
  components: {
    LoadDemand,
    MainSettings: MainSettings,
    PayRateSchedule: PayRateSchedule,
    AddUsers: AddUsers,
    RemoveUsers: RemoveUsers,
    Statistic: Statistic,
    ResizeSchedule: ResizeSchedule
  },
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      scheduleId: null,
      activeTab: 'mainInfo',
      showTabs: true,
      buttonTitle: 'Please Select',
      tabsData: {},
      schedule: null,
      statistic: {
        project: null,
        scheduleId: null,
        scheduleType: null,
        users: [],
        mainInfo: {},
        scheduleSettings: {},
        workingTime: {},
        employees: {
          checkAvailability: false,
          users: []
        },
        patternType: null
      },
      projectUsers: [],
      disabled: false,
      loaded: false
    }
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      preloadUsers: 'employeesIndexed'
    }),
    salaryForManager () {
      if (this.isEmployeeHasPermission('get-company-schedules-admin')) {
        return true
      }
      if (this.$store.getters.company && this.$store.getters.company.company && this.$store.getters.company.company.salary) {
        return this.$store.getters.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.getters.company.company.salary.show_salary_to_managers
      }
      return true
    }
  },

  created () {
    this.tabsData = {
      mainInfo: 'Main information',
      addUsers: 'Add users',
      removeUsers: 'Remove users',
      resizeSchedule: 'Resize schedule',
      loadDemand: 'Load demand'
    }
    if (this.salaryForManager) {
      this.tabsData.payRate = 'Pay rate'
    }
    if (this.companyId) {
      this.getSchedule()
    }
  },

  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'mainInfo'
  },

  watch: {
    companyId () {
      this.getSchedule()
    },
    schedule () {
      this.setStatistic(this.schedule)
      this.updateMainInfo(this.schedule)
    }
  },
  methods: {
    updateMainInfo (schedule) {
      const project = this.$store.getters.projectById(schedule.project_id)

      this.statistic.project = project
      this.statistic.mainInfo.title = schedule.name
      this.statistic.mainInfo.color = schedule.color
      this.statistic.mainInfo.shiftsControl = {}
      this.statistic.mainInfo.shiftsControl.visibleEveryone = schedule.visible_everyone
      this.statistic.mainInfo.shiftsControl.visible_everyone_type = schedule.visible_everyone_type
      this.statistic.mainInfo.shiftsControl.week_overtime_control_type = schedule.week_overtime_control_type
      this.statistic.mainInfo.shiftsControl.working_hours = schedule.working_hours
      this.statistic.mainInfo.shiftsControl.linkShifts = schedule.link_shifts
      this.statistic.mainInfo.shiftsControl.shiftsExchange = schedule.shift_exchange
      this.statistic.mainInfo.shiftsControl.shift_exchange_same_position_only = schedule.shift_exchange_same_position_only
      this.statistic.mainInfo.shiftsControl.managerConfirmation = schedule.shift_exchange_confirmation
      this.statistic.mainInfo.shiftsControl.allow_put_on_freeboard = schedule.allow_put_on_freeboard
      this.statistic.mainInfo.shiftsControl.allow_put_on_freeboard_confirmation = schedule.allow_put_on_freeboard_confirmation
      this.statistic.mainInfo.shiftsControl.allow_shift_request = schedule.allow_shift_request
      this.statistic.mainInfo.shiftsControl.allow_shift_request_confirmation = schedule.allow_shift_request_confirmation
      this.statistic.mainInfo.shiftsControl.allow_drop_to_employee = schedule.allow_drop_to_employee
      this.statistic.mainInfo.shiftsControl.allow_drop_to_employee_confirmation = schedule.allow_drop_to_employee_confirmation
      this.statistic.mainInfo.shiftsControl.allow_break_request = schedule.allow_break_request
      this.statistic.mainInfo.shiftsControl.allow_break_request_confirmation = schedule.allow_break_request_confirmation
      this.statistic.mainInfo.shiftsControl.allow_split_shift_by_employee = schedule.allow_split_shift_by_employee
      this.statistic.mainInfo.shiftsControl.allow_take_from_freeboard = schedule.allow_take_from_freeboard
      this.statistic.mainInfo.shiftsControl.allow_take_from_freeboard_confirmation = schedule.allow_take_from_freeboard_confirmation
      this.statistic.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates = schedule.allow_exchange_break_in_diff_templates
      this.statistic.mainInfo.shiftsControl.break_between_shifts = schedule.break_between_shifts
      this.statistic.mainInfo.shiftsControl.minimum_breaks_in_row = schedule.minimum_breaks_in_row
      this.statistic.mainInfo.shiftsControl.maximum_breaks_in_row = schedule.maximum_breaks_in_row
      this.statistic.mainInfo.shiftsControl.minimum_break_between_shifts = schedule.minimum_break_between_shifts
      this.statistic.mainInfo.shiftsControl.minimum_break_between_shifts_for_women = schedule.minimum_break_between_shifts_for_women
      this.statistic.mainInfo.shiftsControl.minimum_break_in_row_per_week = schedule.minimum_break_in_row_per_week
      this.statistic.mainInfo.shiftsControl.minimum_shift_duration = schedule.minimum_shift_duration
      this.statistic.mainInfo.shiftsControl.maximum_shift_duration = schedule.maximum_shift_duration
      this.statistic.mainInfo.shiftsControl.minimum_coverage = schedule.minimum_coverage
      this.statistic.mainInfo.shiftsControl.maximum_coverage = schedule.maximum_coverage
      this.statistic.mainInfo.shiftsControl.create_templates = schedule.create_templates
      this.statistic.workingTime.timeControl = schedule.check_time
      this.statistic.patternType = schedule.pattern_type
      this.statistic.mainInfo.period = []
      this.statistic.rules = schedule.rules
      this.statistic.mainInfo.period.push(schedule.date_from)
      this.statistic.mainInfo.period.push(schedule.date_to)
    },

    toggleMenu () {
      this.showTabs = !this.showTabs
    },

    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    },

    getSchedule () {
      if (!(this.isEmployeeHasPermission('get-company-schedules-admin') || this.isEmployeeHasPermission('get-company-schedules-manager'))) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      // this.loaded = false
      if (this.$route.params.scheduleId) {
        const employeesByCompany = this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId)
        const scheduleById = this.$store.dispatch('getScheduleById', this.$route.params.scheduleId)
        Promise.all([employeesByCompany, scheduleById]).then((values) => {
          this.scheduleId = +this.$route.params.scheduleId
          this.schedule = values[1]
          this.loaded = true
        })
      }
    },

    setStatistic (schedule) {
      const project = this.$store.getters.projectById(schedule.project_id)
      const scheduleTemplates = this.$store.getters.templatesByScheduleId(schedule.id)

      this.statistic.patternType = schedule.pattern_type
      this.statistic.scheduleId = schedule.id
      this.statistic.project = project
      this.statistic.scheduleType = schedule.schedule_type
      this.statistic.mainInfo.title = schedule.name
      this.statistic.mainInfo.color = schedule.color
      this.statistic.mainInfo.period = []
      this.statistic.mainInfo.period.push(schedule.date_from)
      this.statistic.mainInfo.period.push(schedule.date_to)
      this.statistic.mainInfo.project = schedule.project_id
      this.statistic.mainInfo.projectName = project.name
      this.statistic.mainInfo.projectTZ = project.time_zone
      this.projectUsers = project.users
      this.statistic.scheduleSettings.days = {
        0: {
          long: this.$t('Monday'),
          short: this.$t('Mon'),
          selected: false
        },
        1: {
          long: this.$t('Tuesday'),
          short: this.$t('Tue'),
          selected: false
        },
        2: {
          long: this.$t('Wednesday'),
          short: this.$t('Wed'),
          selected: false
        },
        3: {
          long: this.$t('Thursday'),
          short: this.$t('Thu'),
          selected: false
        },
        4: {
          long: this.$t('Friday'),
          short: this.$t('Fri'),
          selected: false
        },
        5: {
          long: this.$t('Saturday'),
          short: this.$t('Sat'),
          selected: false
        },
        6: {
          long: this.$t('Sunday'),
          short: this.$t('Sun'),
          selected: false
        }
      }
      schedule.work_days.forEach(day => {
        this.statistic.scheduleSettings.days[day].selected = true
      })
      this.statistic.employees.users = []
      schedule.users.forEach(user => {
        this.statistic.employees.users.push(user.employee_id)
      })
      this.statistic.mainInfo.payRate = schedule.rate * 1
      // this.statistic.employees.checkAvailability = schedule.availability
      this.statistic.mainInfo.shiftsControl = {}
      this.statistic.mainInfo.shiftsControl.visibleEveryone = schedule.visible_everyone
      this.statistic.mainInfo.shiftsControl.visible_everyone_type = schedule.visible_everyone_type
      this.statistic.mainInfo.shiftsControl.week_overtime_control_type = schedule.week_overtime_control_type
      this.statistic.mainInfo.shiftsControl.working_hours = schedule.working_hours
      this.statistic.mainInfo.shiftsControl.linkShifts = schedule.link_shifts
      this.statistic.mainInfo.shiftsControl.shiftsExchange = schedule.shift_exchange
      this.statistic.mainInfo.shiftsControl.managerConfirmation = schedule.shift_exchange_confirmation
      this.statistic.mainInfo.shiftsControl.shift_exchange_same_position_only = schedule.shift_exchange_same_position_only
      this.statistic.mainInfo.shiftsControl.allow_put_on_freeboard = schedule.allow_put_on_freeboard
      this.statistic.mainInfo.shiftsControl.allow_put_on_freeboard_confirmation = schedule.allow_put_on_freeboard_confirmation
      this.statistic.mainInfo.shiftsControl.allow_shift_request = schedule.allow_shift_request
      this.statistic.mainInfo.shiftsControl.allow_shift_request_confirmation = schedule.allow_shift_request_confirmation
      this.statistic.mainInfo.shiftsControl.allow_break_request = schedule.allow_break_request
      this.statistic.mainInfo.shiftsControl.allow_break_request_confirmation = schedule.allow_break_request_confirmation
      this.statistic.mainInfo.shiftsControl.allow_split_shift_by_employee = schedule.allow_split_shift_by_employee
      this.statistic.mainInfo.shiftsControl.allow_take_from_freeboard = schedule.allow_take_from_freeboard
      this.statistic.mainInfo.shiftsControl.allow_take_from_freeboard_confirmation = schedule.allow_take_from_freeboard_confirmation
      this.statistic.mainInfo.shiftsControl.allow_exchange_break_in_diff_templates = schedule.allow_exchange_break_in_diff_templates
      this.statistic.mainInfo.shiftsControl.break_between_shifts = schedule.break_between_shifts
      this.statistic.mainInfo.shiftsControl.minimum_breaks_in_row = schedule.minimum_breaks_in_row
      this.statistic.mainInfo.shiftsControl.maximum_breaks_in_row = schedule.maximum_breaks_in_row
      this.statistic.mainInfo.shiftsControl.minimum_break_between_shifts = schedule.minimum_break_between_shifts
      this.statistic.mainInfo.shiftsControl.minimum_break_between_shifts_for_women = schedule.minimum_break_between_shifts_for_women
      this.statistic.mainInfo.shiftsControl.minimum_break_in_row_per_week = schedule.minimum_break_in_row_per_week
      this.statistic.mainInfo.shiftsControl.minimum_shift_duration = schedule.minimum_shift_duration
      this.statistic.mainInfo.shiftsControl.maximum_shift_duration = schedule.maximum_shift_duration
      this.statistic.mainInfo.shiftsControl.minimum_coverage = schedule.minimum_coverage
      this.statistic.mainInfo.shiftsControl.maximum_coverage = schedule.maximum_coverage
      this.statistic.mainInfo.shiftsControl.create_templates = schedule.create_templates
      this.statistic.mainInfo.shiftsControl.create_templates = schedule.create_templates
      this.statistic.scheduleSettings.templates = scheduleTemplates
      this.statistic.scheduleSettings.preloadedTemplates = []
      this.statistic.workingTime.weekends = schedule.week_days_off
      this.statistic.workingTime.timeControl = schedule.check_time
      this.statistic.rules = schedule.rules
      this.statistic.scheduleSettings.templates = []
      this.statistic.scheduleSettings.preloadedTemplatesData = {}
      this.statistic.scheduleSettings.preloadedTemplates = []

      scheduleTemplates.forEach(template => {
        template.by_days = false
        if ([...new Set(template.workplaces)].length > 1) template.by_days = true
        this.statistic.scheduleSettings.preloadedTemplatesData[template.id] = template
        this.statistic.scheduleSettings.preloadedTemplates.push(template.id)
      })
    }
  }
}
</script>

<style lang="scss">
</style>
