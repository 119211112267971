<template>
  <div>
    <div>
      <el-link
        :underline="false"
        @click="clear"
        type="primary">
        {{$t('Clear')}}
      </el-link>
    </div>
    <div>
      <VDatePicker
        ref="v-datepicker"
        :locale="locale"
        mode="date"
        @dayclick="onDayClick"
        expanded
        :attributes='attributes'
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MultipleDaysPickerComponent',
  data () {
    return {
      selectedDays: [],
      attributes: []
    }
  },
  mounted () {
    this.onDayClick({
      id: moment().format('YYYY-MM-DD'),
      date: moment().toDate()
    })
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },
  methods: {
    clear () {
      this.selectedDays = []
      this.attributes = []
    },
    onDayClick (day) {
      const idx = this.selectedDays.findIndex(d => d.id === day.id)
      if (idx >= 0) {
        const selectedDays = [...new Set(this.selectedDays)]
        // console.log(idx, this.day, selectedDays[idx])
        // this.day = null
        selectedDays.splice(idx, 1)
        this.selectedDays = selectedDays
      } else {
        this.selectedDays.push({
          id: day.id,
          date: day.date
        })
      }
      // у пикера не всегда визуально сбрасывается день при повторном нажатии на дату, т.е. дата остается подствеченной, хотя из selectedDays дата удаляется
      // поэтому для фикса this.$nextTick и attributes в виде метода вместо computed
      this.attributes = []
      this.$nextTick(() => {
        this.attributes = this.setAttributes()
      })
      // console.log(this.selectedDays)
      this.$emit('onChange', this.selectedDays)
    },
    setAttributes () {
      return this.selectedDays.map(day => {
        return {
          dates: day.date,
          // An optional key can be used for retrieving this attribute later,
          // and will most likely be derived from your data object
          key: 'v-calendar__' + day.id,
          // Attribute type definitions
          highlight: {
            color: 'blue',
            fillMode: 'light'
          }, // Boolean, String, Object
          // dot: false, // Boolean, String, Object
          // bar: false, // Boolean, String, Object
          // content: 'blue', // Boolean, String, Object
          // popover: {
          //   visibility: 'hidden'
          // }, // Only objects allowed
          // Your custom data object for later access, if needed
          // customData: {},
          // You can optionally provide dates to exclude
          // excludeDates: null,
          // Think of `order` like `z-index`
          order: 0
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
