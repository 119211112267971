import { HTTP } from '@/api/http_utils'

const actions = {
  getNews ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get('/dashboard/news').then(news => {
        resolve(news)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getHints ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get('/dashboard/hints').then(hints => {
        resolve(hints)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getProjectsDashboard ({ commit }, [id, from]) {
    return new Promise(resolve => {
      HTTP.get(`/companies/${id}/dashboard/projects?start=${from}`).then(projects => {
        resolve(projects)
      })
    })
  },
  getRequestsStatistic ({ commit }, companyID) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + companyID + '/dashboard/requests').then(requests => {
        resolve(requests)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  actions
}
