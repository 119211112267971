<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('Change shifts') }}</div>
<!--      <div class="ml-md-4" style="line-height: 27px">-->
<!--        &nbsp;<span>{{ $t('Company') }}</span><span class="ml-2 font-weight-bold">{{request.company}}</span>-->
<!--      </div>-->
<!--      <div class="ml-md-4" style="line-height: 27px">-->
<!--        &nbsp;<span>{{ $t('Project') }}</span><span class="ml-2 font-weight-bold">{{request.project}}</span>-->
<!--      </div>-->
    </div>
    <div class="mt-2 d-flex align-items-center">
      {{ $t('User') }}
      <span class="visible-md-inline visible-lg-inline ml-1">
          <avatar v-if="request"
                  class="rounded-circle d-inline-block font-weight-normal"
                  :width="35"
                  :borderRadius="50"
                  :placeholder="false"
                  :userId="request.data.user_from_id"
                  :mini="true"
                  :cache="true"
                  :userName="request.data.user_from_name">
          </avatar>
        </span>
      <span class="ml-1 font-weight-bold" v-if="request">{{request.data.user_from_name}}</span>
      <span class="mx-1" v-if="employeeFrom && employeeFrom.identifier"> ({{employeeFrom.identifier}})</span>
      <span class="mx-1">{{ $t('offers swapping a shift') }}</span>
      ({{ toTimeZone(request.data.shift_from_time_from).format(localeDateTimeFormat) }} - {{ toTimeZone(request.data.shift_from_time_to).format(localeDateTimeFormat) }})
      <template v-if="checkOvertime.length > 0 && checkOvertime[1].is_overtime">
        <span class="text-warning small mx-2" v-if="checkOvertime[0].week_overtime">
              {{ $t('WEEK_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[1].week_overtime * 100) / 100 }}
        </span>
        <span class="text-warning small mx-2" v-if="checkOvertime[1].month_overtime">
          {{ $t('MONTH_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[1].month_overtime * 100) / 100 }}
        </span>
      </template>
    </div>
    <ShiftTitle :request="{
      data: {
        project_name: request.data.project_from_name,
        schedule_name: request.data.schedule_from_name,
        schedule_color: request.data.schedule_from_color,
        shift_color: request.data.shift_from_color
      },
      template: request.template_from,
    }" />
    <ShiftLocation v-if="request.shift_from" :location="request.shift_from.location" />
    <ShiftBreaks v-if="request.shift_from" :breaks="request.shift_from.breaks" />
    <ShiftMarks v-if="request.marks_from" :marks="request.marks_from" />
    <div class="mt-2 d-flex align-items-center">
      {{ $t('to user') }}
      <span class="visible-md-inline visible-lg-inline ml-1">
          <avatar
            class="rounded-circle d-inline-block font-weight-normal"
            :width="35"
            :borderRadius="50"
            :placeholder="false"
            :mini="true"
            :cache="true"
            :userId="request.data.user_id"
            :userName="request.data.user_name"></avatar>
        </span>
      <span class="ml-1 font-weight-bold">{{request.data.user_name}}</span>
      <span class="mx-1" v-if="employeeTo && employeeTo.identifier"> ({{employeeTo.identifier}})</span>
      <span class="mx-1">{{$t('on a shift')}}</span>
      ({{ toTimeZone(request.data.shift_to_time_from).format(localeDateTimeFormat) }} - {{ toTimeZone(request.data.shift_to_time_to).format(localeDateTimeFormat) }})
      <template v-if="checkOvertime.length > 0 && checkOvertime[0].is_overtime">
        <span class="text-warning small mx-2" v-if="checkOvertime[0].week_overtime">
          {{ $t('WEEK_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].week_overtime * 100) / 100}}
        </span>
        <span class="text-warning small mx-2" v-if="checkOvertime[0].month_overtime">
          {{ $t('MONTH_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].month_overtime * 100) / 100}}
        </span>
      </template>
    </div>
    <ShiftTitle :request="{
      data: {
        project_name: request.data.project_to_name,
        schedule_name: request.data.schedule_to_name,
        schedule_color: request.data.schedule_to_color,
        shift_color: request.data.shift_to_color
      },
      template: request.template,
    }" />
    <ShiftLocation v-if="request.shift" :location="request.shift.location" />
    <ShiftBreaks v-if="request.shift" :breaks="request.shift.breaks" />
    <ShiftMarks v-if="request.marks" :marks="request.marks" />
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
import ShiftBreaks from '@/components/Requests/ViewComponents/ShiftBreaks'
import ShiftLocation from '@/components/Requests/ViewComponents/ShiftLocation'
import ShiftMarks from '@/components/Requests/ViewComponents/ShiftMarks'
import ShiftTitle from '@/components/Requests/ViewComponents/ShiftTitle'
export default {
  name: 'ExchangeShifts',
  props: ['request', 'checkOvertime', 'employeeFrom', 'employeeTo'],
  components: { ShiftTitle, ShiftMarks, ShiftLocation, ShiftBreaks, Avatar },
  mixins: [momentMixin, requestMixin],
  created () {
    console.log(22, this.employeeFrom, this.employeeTo)
  }
}
</script>

<style scoped>

</style>
