<template>
  <div v-if="marks.length > 0" class="mt-2">
    <div class="mb-2 font-weight-bold">
      {{$t('Marks')}}:
    </div>
    <div v-for="shiftMark in marks" :key="shiftMark.id">
      <span class="project-color" :style="{ backgroundColor: shiftMark.color }"></span> {{shiftMark.name}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShiftMarks',
  props: {
    marks: Array
  }
}
</script>

<style scoped>
.project-color {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
