<template>
  <div class="row">
    <div v-if="isAttendanceModuleOn" class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('PROBLEM_SHIFTS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="problemShifts.length > 0">
            <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
              <div v-for="item in problemShifts" :key="item.id  + '__problem'" class="mb-2">
                <el-badge class="bage-item" :value="item.isLate ? $t('LATE_BADGE') : $t('EARLY_BADGE')">
                  <div>
                    {{ item.employee.full_name }}
                    <template v-if="item.marks.length > 0">
                      <span v-for="mark in item.marks" class="small mr-1 project-color" :key="item.employee.id + '__' + mark.id">
                        <el-tooltip
                          effect="light"
                          placement="top">
                          <template #content>
                            <div>{{ mark.name }}</div>
                          </template>
                          <span class="project-color" v-bind:style="{ backgroundColor: mark.color }"></span>
                        </el-tooltip>
                      </span>
                    </template>
                  </div>
                  <div class="small">
                    {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{schedules[item.schedule_id]}}
                  </div>
                  <template v-if="item.employee.positions.length > 0">
                    <div v-for="position in item.employee.positions" class="small" :key="item.employee.id + '__' + position.id">
                      {{position.title}}
                    </div>
                  </template>
                </el-badge>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_LATE_SHIFTS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('ON_SHIFT')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="nowShifts.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in nowShifts" :key="item.id  + '__now'" class="mb-1">
                <div>
                  {{ item.employee.full_name }}
                  <template v-if="item.marks.length > 0">
                    <span v-for="mark in item.marks" class="small mr-1 project-color" :key="item.employee.id + '__' + mark.id">
                      <el-tooltip
                        effect="light"
                        placement="top">
                        <template #content>
                          <div>{{ mark.name }}</div>
                        </template>
                        <span class="project-color" v-bind:style="{ backgroundColor: mark.color }"></span>
                      </el-tooltip>
                    </span>
                  </template>
                </div>
                <div class="small">
                  {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{schedules[item.schedule_id]}}
                </div>
                <template v-if="item.employee.positions.length > 0">
                  <div v-for="position in item.employee.positions" class="small mr-1" :key="item.employee.id + '__' + position.id">
                    {{position.title}}
                  </div>
                </template>
<!--                <div class="small">-->
<!--                  {{toTime(item.shift.time_from)}} - {{toTime(item.shift.time_to)}} {{item.schedule.name}}-->
<!--                </div>-->
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                 {{$t('NO_ON_SHIFT')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col-auto mt-2">{{$t('FUTURE_SHIFTS')}}</div>
            <div class="col">
              <div class="w-50">
                <ui-select
                  name="shiftReload"
                  v-model="beforeShift"
                  :options="timeOptions"
                  :filterable="false"
                  :key-name="'val'"
                  :label-name="'label'"
                  :value-name="'val'"
                />
              </div>
            </div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
          <template v-if="filteredFutureBreaks.length > 0">
            <div class="overflow-auto mt-2" style="max-height: 220px!important;">
              <div v-for="item in filteredFutureBreaks" :key="item.id + '__future'" class="mb-1">
                <div>
                  {{ item.employee.full_name }}
                  <template v-if="item.marks.length > 0">
                    <span v-for="mark in item.marks" class="small mr-1 project-color" :key="item.employee.id + '__' + mark.id">
                      <el-tooltip
                        effect="light"
                        placement="top">
                        <template #content>
                          <div>{{ mark.name }}</div>
                        </template>
                        <span class="project-color" v-bind:style="{ backgroundColor: mark.color }"></span>
                      </el-tooltip>
                    </span>
                  </template>
                </div>
                <div class="small">
                  {{toTime(item.time_from)}} - {{toTime(item.time_to)}} {{schedules[item.schedule_id]}}
                </div>
                <template v-if="item.employee.positions.length > 0">
                  <div v-for="position in item.employee.positions" class="small mr-1" :key="item.employee.id + '__' + position.id">
                    {{position.title}}
                  </div>
                </template>
<!--                <div class="small">-->
<!--                  {{toTime(item.shift.time_from)}} - {{toTime(item.shift.time_to)}} {{item.schedule.name}}-->
<!--                </div>-->
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center h-100">
              <div class="my-5">
                {{$t('NO_FUTURE_SHIFTS')}}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ShiftsStatistic',
  mixins: [momentMixin],

  data () {
    return {
      shifts: [],
      lateShifts: [],
      earlyShifts: [],
      problemShifts: [],
      nowShifts: [],
      futureShifts: [],
      beforeShift: 60,
      timeOptions: [
        {
          val: 5,
          label: '5 ' + this.$t('min')
        },
        {
          val: 15,
          label: '15 ' + this.$t('min')
        },
        {
          val: 30,
          label: '30 ' + this.$t('min')
        },
        {
          val: 45,
          label: '45 ' + this.$t('min')
        },
        {
          val: 60,
          label: '60 ' + this.$t('min')
        }
      ]
    }
  },

  props: {
    isAttendanceModuleOn: Boolean
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      profileTimeZone: 'profileTimeZone',
      employeesIndexed: 'employeesIndexed'
    }),
    schedules () {
      const schedules = this.$store.getters.schedules
      const schedulesById = {}
      schedules.forEach(schedule => {
        schedulesById[schedule.id] = schedule.name
      })
      return schedulesById
    },
    marks () {
      const companyMarks = this.$store.getters.companyMarks
      const marksById = {}
      companyMarks.forEach(mark => {
        marksById[mark.id] = mark
      })
      return marksById
    },
    filteredFutureBreaks () {
      const time = moment().tz(this.profileTimeZone).add(this.beforeShift, 'minutes')

      return this.futureShifts.filter(item => this.fromZoneToZone(item.time_from, 'UTC', this.profileTimeZone).isBefore(time))
    }
  },

  created () {
    this.$store.dispatch('getCompanySchedules')
    this.$store.dispatch('getMarksByCompany')
    this.init()
  },

  methods: {
    init () {
      const currentStart = moment().tz(this.profileTimeZone).add(-60, 'minutes').format(this.backendDateTimeFormat)
      const currentEnd = moment().tz(this.profileTimeZone).add(60, 'minutes').format(this.backendDateTimeFormat)
      const dateStart = this.fromZoneToZone(currentStart, this.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const dateEnd = this.fromZoneToZone(currentEnd, this.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)

      const query = 'start=' + dateStart + '&end=' + dateEnd
      this.$store.dispatch('getShiftsByCompany', [this.companyId, query]).then(response => {
        this.shifts = response
        response.forEach(shift => {
          shift.marks = []
          if (shift.employee_id > 0) {
            // shift.employee = this.employeesIndexed[shift.employee_id]
            let marks = []
            shift.employee.schedule_user.forEach(user => {
              marks = marks.concat(user.marks)
            })
            marks = [...new Set(marks)]
            marks.forEach(mark => {
              if (this.marks[mark]) {
                shift.marks.push(this.marks[mark])
              }
            })
          }
        })

        response.sort((a, b) => {
          if (a.time_from > b.time_to) {
            return 1
          }
          if (a.time_from === b.time_to) {
            if (a.employee.full_name > b.employee.full_name) {
              return -1
            }
            return 1
          }
          return -1
        })

        if (this.isAttendanceModuleOn) {
          this.lateShifts = response.filter(shiftItem => {
            return shiftItem.employee_id > 0 &&
            shiftItem.attendances.length === 0 &&
            this.fromZoneToZone(shiftItem.time_from, 'UTC', this.profileTimeZone).isBefore(moment())
          }).map(shiftItem => {
            shiftItem.isLate = true
            return shiftItem
          })

          this.earlyShifts = response.filter(shiftItem => {
            return shiftItem.employee_id > 0 &&
              // shiftItem.attendances.length > 1 &&
              // shiftItem.attendances[shiftItem.attendances.length - 1]['type'] === 'shift_end' &&
              shiftItem.presence_info &&
              shiftItem.presence_info.early > 0
          }).map(shiftItem => {
            shiftItem.isEarly = true
            return shiftItem
          })

          this.problemShifts = []
          this.problemShifts = this.problemShifts.concat(this.lateShifts, this.earlyShifts)
          this.problemShifts = this.problemShifts.sort((a, b) => {
            if (moment(a.time_from).isAfter(moment(b.time_from))) {
              return 1
            }
            if (moment(a.time_from).isSame(moment(b.time_from))) {
              if ((a.employee.full_name).toLowerCase() > (b.employee.full_name).toLowerCase()) {
                return 1
              }
            }
            return -1
          })

          this.nowShifts = response.filter(shiftItem => {
            return shiftItem.employee_id > 0 &&
              shiftItem.attendances.length > 0 &&
              shiftItem.attendances[shiftItem.attendances.length - 1].type !== 'shift_end'
          })
          this.nowShifts = this.nowShifts.sort((a, b) => {
            if (moment(a.time_from).isAfter(moment(b.time_from))) {
              return 1
            }
            if (moment(a.time_from).isSame(moment(b.time_from))) {
              if ((a.employee.full_name).toLowerCase() > (b.employee.full_name).toLowerCase()) {
                return 1
              }
            }
            return -1
          })

          this.futureShifts = response.filter(shiftItem => {
            return shiftItem.employee_id > 0 &&
            shiftItem.attendances.length === 0 &&
            this.fromZoneToZone(shiftItem.time_from, 'UTC', this.profileTimeZone).isSameOrAfter(moment())
          })
          this.futureShifts = this.futureShifts.sort((a, b) => {
            if (moment(a.time_from).isAfter(moment(b.time_from))) {
              return 1
            }
            if (moment(a.time_from).isSame(moment(b.time_from))) {
              if ((a.employee.full_name).toLowerCase() > (b.employee.full_name).toLowerCase()) {
                return 1
              }
            }
            return -1
          })
        } else {
          this.nowShifts = response.filter(shiftItem => shiftItem.employee_id > 0 &&
            moment().isBetween(this.fromZoneToZone(shiftItem.time_from, 'UTC', this.profileTimeZone), this.fromZoneToZone(shiftItem.time_to, 'UTC', this.profileTimeZone)))
          this.nowShifts = this.nowShifts.sort((a, b) => {
            if (moment(a.time_from).isAfter(moment(b.time_from))) {
              return 1
            }
            if (moment(a.time_from).isSame(moment(b.time_from))) {
              if ((a.employee.full_name).toLowerCase() > (b.employee.full_name).toLowerCase()) {
                return 1
              }
            }
            return -1
          })
          this.futureShifts = response.filter(shiftItem => shiftItem.employee_id > 0 && moment().isBefore(this.fromZoneToZone(shiftItem.time_from, 'UTC', this.profileTimeZone)))
          this.futureShifts = this.futureShifts.sort((a, b) => {
            if (moment(a.time_from).isAfter(moment(b.time_from))) {
              return 1
            }
            if (moment(a.time_from).isSame(moment(b.time_from))) {
              if ((a.employee.full_name).toLowerCase() > (b.employee.full_name).toLowerCase()) {
                return 1
              }
            }
            return -1
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },

    toTime (date) {
      return this.fromZoneToZone(date, 'UTC', this.profileTimeZone).format(this.localeTimeFormat)
    }
  }
}
</script>

<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
