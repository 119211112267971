export default {
  methods: {
    checkNotify () {
      if (this.currentProject && this.rolesByProjects[this.currentProject] !== 'user') {
        this.$store.dispatch('getNotifyCount', this.currentProject).then((notify) => {
          if (notify.total_changes > 0) {
            this.notify = true
          } else {
            this.notify = false
          }
          localStorage.setItem('scheduleNotify', this.notify)
        }).catch(e => {})
      }
    }
  }
}
