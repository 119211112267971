<template>
  <Form ref="observer" as="div" class="row my-1">
    <div class="col-3 my-1">
      <ui-text-input
        :disabled="disabledActions"
        :name="index + '__title'"
        :label="$t('REQUEST_REASON_ITEM_TITLE')"
        :hint="$t('REQUEST_REASON_ITEM_TITLE_HINT')"
        v-model="itemState.title"
        :validation="{required: true}"
        @blur="onBlur"
      />
    </div>
    <div class="col-3 my-1">
      <ui-text-input
        :disabled="disabledActions"
        :name="index + '__short_title'"
        :label="$t('REQUEST_REASON_ITEM_SHORT_TITLE')"
        :hint="$t('REQUEST_REASON_ITEM_SHORT_TITLE_HINT')"
        v-model="itemState.short_title"
        @blur="onBlur"
      />
    </div>
    <div class="col-2 my-1 d-flex align-items-end">
      <ui-checkbox
        :name="index + '__item.is_other_data'"
        v-model="itemState.is_other_data"
        :disabled="disabledActions"
        :label="$t('IS_OTHER_DATA')"
        :hint="$t('IS_OTHER_DATA_HINT')"
      />
    </div>
    <div class="col-3 my-1">
      <ui-select
        :name="index + '__types'"
        :label="$t('CHECKLIST_ITEM_TYPE')"
        v-model="itemState.type_time_off"
        :disabled="disabledActions"
        @change="typeChange"
        class="w-100"
        :options="typeChoices"
        :validation="{required: true}"
        :key-name="'id'"
        :value-name="'id'"
        :label-name="'name'"
      />
    </div>
    <div class="col-auto m-1 d-flex text-center">
      <i
        class="icon-trash btn-icon text-danger my-auto"
        @click="deleteItem()"
        role="button"
      >
      </i>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'TimeOffItem',
  mixins: [errorMixin],
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      typeChoices: [
        {
          id: 'sick',
          name: this.$t('Sick day')
        },
        {
          id: 'vacation',
          name: this.$t('Vacation')
        },
        {
          id: 'holiday',
          name: this.$t('Holiday')
        }
      ],
      itemState: {}
    }
  },
  props: { item: Object, showItemConfig: Boolean, disabledActions: Boolean, index: Number },
  created () {
    this.itemState = this.item
  },
  methods: {
    onBlur () {
      this.saveAction()
    },
    deleteItem () {
      if (!this.disabledActions) {
        this.$emit('deleteItem')
      }
    },
    handleClose (tag) {
      this.itemState.type_options.splice(this.itemState.type_options.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        if (!this.itemState.type_options) this.itemState.type_options = []
        this.itemState.type_options.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
    },
    typeChange () {
      this.saveAction()
    },
    saveAction () {
      this.$refs.observer.validate().then(result => {
        if (result.valid && this.itemState.title && this.itemState.title.length) {
          if (this.itemState.id) {
            this.$store.dispatch('updateCompanyUserRequestReason', [this.itemState.id, this.itemState]).then(requestsReason => {
              this.itemState.title = requestsReason.title
              this.itemState.short_title = requestsReason.short_title
              this.itemState.type_time_off = requestsReason.type_time_off
            })
          } else {
            this.$store.dispatch('creteCompanyUserRequestReason', this.itemState).then(requestsReason => {
              this.itemState.id = requestsReason.id
              this.itemState.title = requestsReason.title
              this.itemState.short_title = requestsReason.short_title
              this.itemState.type_time_off = requestsReason.type_time_off
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
