<template>
  <el-dialog
    key="showUnscheduledActivitiesDialog"
    :title="$t('Select a template to start an unplanned activity')"
    v-model="showUnscheduledActivitiesDialog"
    :width="elDialogWidth">
    <div class="my-3" style="max-height: 300px; overflow:auto; background-color: white">
      <div class="border border-light pointer-cursor p-2 mb-2" v-for="(template, index) in unscheduledActivitiesTemplates" :key="index" @click="createUnscheduledActivity(template.id)">
        <span data-v-b3bc6338="" class="project-color" :style="{'background-color': template.color}"></span> {{ template.title }}
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showUnscheduledActivitiesDialog = false">{{$t('Close')}}</el-button>
      </span>
    </template>
  </el-dialog>
  <div class="row align-items-center bg-light py-1 my-1" v-if="isEnable && (activities.length > 0 || unscheduledActivitiesTemplates.length > 0)">
    <template v-for="activity in activities" :key="activity.id">
      <div class="col-auto text-left card mx-1">
        <div class="row align-items-center">
          <div class="col my-1">
            <div class="mb-1">
              <span class="project-color" v-bind:style="{ backgroundColor: activity.color }" />
              <span class="mx-1">{{activity.title}}</span>
            </div>
            <div v-if="$store.getters.projectById(activity.project_id)">
              <i role="button" class="icon-calendar mr-1" /> {{ $store.getters.projectById(activity.project_id).name }}
            </div>
            <div v-if="$store.getters.scheduleById(activity.schedule_id)">
              <i role="button" class="icon-calendar mr-1" /> {{ $store.getters.scheduleById(activity.schedule_id).name }}
            </div>
            <div>
              {{getTime(activity.time_from)}} - {{getTime(activity.time_to)}}
            </div>
          </div>
          <div class="col-auto align-content-end">
            <button
                type="submit"
                @click="updateActivity(activity)"
                :class="activity.next_statuses[0].check_in ? 'btn-outline-success' : 'btn-outline-danger'"
                class="btn btn-sm rounded-pill shadow-sm m-1">
              <template v-if="activity.next_statuses[0].check_in">
                {{ $t('START_ACTIVITY') }}
              </template>
              <template v-if="activity.next_statuses[0].check_out">
                {{ $t('FINISH_ACTIVITY') }}
              </template>
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-if="unscheduledActivitiesTemplates.length" class="col-auto text-left card mx-1">
      <div class="row align-items-center">
        <div class="col my-1">
          <div class="mb-1">
            <span class="mx-1">{{ $t('Unscheduled activity available') }}</span>
          </div>
        </div>
        <div class="col-auto align-content-end">
          <button
              type="submit"
              @click="openUnscheduledActivitiesModal()"
              :class="'btn-outline-success'"
              class="btn btn-sm rounded-pill shadow-sm m-1">
              {{ $t('START_ACTIVITY') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
export default {
  name: 'ActivityDashboardComponent',
  mixins: [momentMixin],
  data () {
    return {
      isEnable: false,
      activities: [],
      unscheduledActivitiesTemplates: [],
      showUnscheduledActivitiesDialog: false
    }
  },
  created () {
    this.getActivities()
    this.getTemplatesForUnscheduleActivities()
  },
  mounted () {
    this.$eventBus.on('reloadDummy', (emiter) => {
      if (emiter === 'activities') {
        this.getActivities()
        this.getTemplatesForUnscheduleActivities()
      }
    })
    this.$eventBus.on('updateActivityStatus', _ => {
      this.getActivities()
      this.getTemplatesForUnscheduleActivities()
    })
  },
  beforeUnmount () {
    this.$eventBus.off('reloadDummy')
    this.$eventBus.off('updateActivityStatus')
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    },
    elDialogWidth () {
      const lWidth = window.screen.width
      if (lWidth < 800) {
        return '95%'
      }
      return '40%'
    }
  },
  methods: {
    getActivities () {
      this.isEnable = false
      this.$store.dispatch('getPersonalActivity', this.companyId).then(response => {
        this.isEnable = true
        this.activities = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.isEnable = false
        }
      })
    },
    getTemplatesForUnscheduleActivities () {
      this.$store.dispatch('getTemplatesForUnscheduledActivity', this.companyId).then(response => {
        this.isEnable = true
        this.unscheduledActivitiesTemplates = response
      }).catch((error) => {
        if (error.response.data.module) {
          this.isEnable = false
        }
      })
    },
    openUnscheduledActivitiesModal () {
      this.showUnscheduledActivitiesDialog = true
    },
    createUnscheduledActivity (activityTemplateId) {
      const payload = {
        template_id: activityTemplateId
      }

      this.$store.dispatch('createUnscheduledActivity', [this.companyId, payload]).then(response => {
        this.getActivities()
        this.unscheduledActivitiesTemplates = []
        this.showUnscheduledActivitiesDialog = false
        this.toastSuccess(this.$i18n?.t('UNSCHEDULED_ACTIVITY_STARTED'))
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    updateActivity (activity) {
      const payload = {
        id: activity.id,
        next_status_id: activity.next_statuses[0].id
      }
      this.$store.dispatch('patchPersonalShiftActivity', [this.companyId, payload]).then(response => {
        this.toastSuccess(this.$i18n?.t('ACTIVITY_UPDATED'))
        this.getActivities()
        this.getTemplatesForUnscheduleActivities()
      }).catch((error) => {
        this.toastError(this.$i18n?.t(error.response.data.message))
      })
    },
    getTime (time) {
      return this.fromZoneToZone(time, 'UTC', this.tz).format(this.localeTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
