<template>
  <modal-component :id="'MassActionUserModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('POSITIONS_MODAL_TITLE')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div" class="row">
          <div class="col-12">
            <ui-select
              name="positions"
              v-model="positions"
              :clearable="true"
              :placeholder="$t('Select')"
              :label="$t('POSITIONS_SELECT')"
              :hint="$t('POSITIONS_SELECT_HINT')"
              :options="options"
              :key-name="'id'"
              :label-name="'title'"
              :value-name="'id'"
            />
          </div>
          <div class="col-12" v-if="positions">
            <ui-date-picker
              name="replace_date"
              :clearable="false"
              :label="$t('POSITION_REPLACE_DATE')"
              :hint="$t('POSITION_REPLACE_DATE_HINT')"
              :validation="{required: true}"
              v-model="dateFrom"
            />
          </div>
        </Form>
        <div class="row mt-2">
          <div class="col-12">
            <button
              class="btn btn-success rounded-pill shadow"
              aria-label="Close"
              @click="save">
              {{ $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import ToastMixin from '@/mixins/ToastMixin'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'MassUpdateUserModal',
  components: { ModalComponent },
  mixins: [ToastMixin, momentMixin],
  data () {
    return {
      positions: null,
      options: [],
      dateFrom: moment().startOf('day').format(this.backendDateTimeFormat)
    }
  },
  emits: ['clearSelection', 'closeMassActionUserModal'],
  props: {
    selectedEmployees: Array
  },
  created () {
    this.$store.dispatch('getPositions', this.$route.params.id).then((response) => {
      this.options = response
    })
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    save () {
      this.$refs.observer.validate().then(value => {
        if (value) {
          const data = {
            employees_id: this.selectedEmployees,
            positions_id: this.positions,
            date: this.fromZoneToZone(this.dateFrom, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
          }
          this.$store.dispatch('batchAttachPositions', [this.companyId, data]).then(() => {
            // this.toastSuccess(this.$t('POSITIONS_UPDATED_SUCCESS'))
            this.$emit('clearSelection')
            this.closeModal()
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
            this.closeModal()
          })
        }
      })
    },
    closeModal () {
      document.getElementById('MassActionUserModal').click()
      this.$emit('closeMassActionUserModal')
    }
  }
}
</script>

<style scoped>

</style>
