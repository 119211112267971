<template>
  <el-switch
      :disabled="disabled"
      v-model="innerValue"
      @change="$emit('change:modelValue', innerValue)"
      :active-text="activeText"
      :inactive-text="inactiveText">
  </el-switch>
</template>

<script>

export default {
  name: 'ui-switch',
  emits: ['update:modelValue', 'change:modelValue'],

  props: {
    value: Boolean,
    modelValue: Boolean,
    activeText: String,
    inactiveText: String,
    disabled: { type: Boolean, default: false }
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
