<template>
  <modal-component :id="'AddUserToProjectModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('Add employee to project')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observe" as="div">
          <div class="mb-2">
            <ui-select
              name="project"
              :label="$t('Select project')"
              v-model="projectId"
              :clearable="false"
              :filterable="false"
              :validation="{required: true}"
              class="w-100"
              :options="projectChoices"
              :key-name="'value'"
              :label-name="'label'"
              :value-name="'value'"
            />
          </div>
          <div class="mb-2" v-if="isEmployeeHasPermission('get-employee-admin')">
            <ui-select
              name="role"
              :label="$t('Select role')"
              v-model="role"
              :clearable="false"
              :filterable="false"
              class="w-100"
              :options="roleChoices"
              :validation="{required: true}"
              :key-name="'value'"
              :label-name="'label'"
              :value-name="'value'"
            />
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-primary rounded-pill shadow-sm" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'

export default {
  name: 'AddUserToProjectModal',
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      projectChoices: [],
      roleChoices: [
        {
          value: 'user',
          label: this.$t('Employee')
        },
        {
          value: 'manager',
          label: this.$i18n?.t('Manager')
        },
        {
          value: 'manager_user',
          label: this.$i18n?.t('Manager') + ' + ' + this.$i18n?.t('Employee')
        }
      ],
      project: {},
      projectId: null,
      role: 'user'
    }
  },
  props: { userId: Number },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    projectId () {
      this.$store.dispatch('getProject', this.projectId).then((project) => {
        this.project = project
        this.project.timeZone = project.time_zone
        this.project.rate = project.rate * 1
      })
    }
  },
  methods: {
    init () {
      // console.log(this.userId)
      if (!this.isEmployeeHasPermission('get project data')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.$store.dispatch('getProjectsByCompany', this.companyId).then(response => {
        // console.log(response)
        response.forEach(project => {
          if (this.isEmployeeHasPermission('delete-employee') ||
            (this.isEmployeeHasPermission('get-project-schedules-manager') &&
              project.role === 'manager')) {
            if (!project.managers.includes(this.userId) && !project.users.includes(this.userId)) {
              this.projectChoices.push({
                value: project.id,
                label: project.name
              })
            }
          }
        })
      })
    },
    closeModal () {
      this.$emit('closeAddUserToProjectModal')
    },
    save () {
      // name: "sssss"
      // color: "#8A2BE2"
      // time_zone: "Europe/Kiev"
      // rate: 4
      // managers: [3556]
      // users: [1286, 2611, 2234]

      this.$refs.observe.validate().then(({ valid }) => {
        if (valid) {
          if (this.role === 'manager') {
            this.project.managers.push(this.userId)
          } else if (this.role === 'user') {
            this.project.users.push(this.userId)
          } else {
            this.project.managers.push(this.userId)
            this.project.users.push(this.userId)
          }
          const projectData = this.project
          projectData.time_zone = this.project.timeZone
          projectData.rate = +this.project.rate
          this.$store.dispatch('editProject', [this.projectId, projectData]).then((project) => {
            document.getElementById('modalCloseButton').click()
            this.closeModal()
            this.$eventBus.emit('updateProjectsList')
            // console.log(project)
          }).catch(() => {})
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
