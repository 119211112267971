<template>
  <div style="position: relative" data-toggle="modal" data-target="#ClickShiftActivityModal">
    <div>
      <span class="ml-1 overflow-hidden">{{ title }}</span>
    </div>
  </div>
</template>
<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment/moment'

export default {
  name: 'ShiftActivityBackgroundItem',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 0,
      tags: [],
      markTemplate: '',
      tooltipEnable: true
    }
  },
  props: {
    event: Object
  },
  computed: {
    start () {
      const start = new Date(this.event.start)
      return moment.parseZone(start)
    },
    end () {
      const end = new Date(this.event.end)
      return moment.parseZone(end)
    },
    title () {
      return this.start.format(this.localeTimeFormat) + ' - ' + this.end.format(this.localeTimeFormat)
    }
  }
}
</script>
<style scoped>
</style>
