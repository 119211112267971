const UPDATE_CURRENT_PROJECT_ID = 'UPDATE_CURRENT_PROJECT_ID'
const UPDATE_SCHEDULES_BY_PROJECT = 'UPDATE_SCHEDULES_BY_PROJECT'
const UPDATE_TEMPLATES_BY_PROJECT = 'UPDATE_TEMPLATES_BY_PROJECT'
const UPDATE_TEMPLATE_BY_PROJECT = 'UPDATE_TEMPLATE_BY_PROJECT'
const DELETE_TEMPLATE_BY_PROJECT = 'DELETE_TEMPLATE_BY_PROJECT'
const UPDATE_SCHEDULES_INFO_BY_PROJECT = 'UPDATE_SCHEDULES_INFO_BY_PROJECT'
const UPDATE_FILTERS = 'UPDATE_FILTERS'
const UPDATE_RESOURCES = 'UPDATE_RESOURCES'
const UPDATE_FILTERED_RESOURCES = 'UPDATE_FILTERED_RESOURCES'
const UPDATE_WEEK_STATISTIC = 'UPDATE_WEEK_STATISTIC'
const UPDATE_EVENTS_IS_LOADED = 'UPDATE_EVENTS_IS_LOADED'
const UPDATE_SHOULD_UPDATE_WEEK_STATISTIC = 'UPDATE_SHOULD_UPDATE_WEEK_STATISTIC'
const DROP_STATE = 'DROP_STATE'
const UPDATE_INFO_EVENTS = 'UPDATE_INFO_EVENTS'
const UPDATE_VIEW = 'UPDATE_VIEW'
const UPDATE_SELECTED_TEMPLATES = 'UPDATE_SELECTED_TEMPLATES'
const UPDATE_FILTERED_SHIFTS = 'UPDATE_FILTERED_SHIFTS'
const UPDATE_FILTERED_SHIFT = 'UPDATE_FILTERED_SHIFT'
const DELETE_FILTERED_SHIFT = 'DELETE_FILTERED_SHIFT'
const UPDATE_CUSTOM_TEMPLATES_TO_HIDE = 'UPDATE_CUSTOM_TEMPLATES_TO_HIDE'
const REMOVE_CUSTOM_TEMPLATES_TO_HIDE = 'REMOVE_CUSTOM_TEMPLATES_TO_HIDE'
const UPDATE_DELETED_TEMPLATES_TO_HIDE = 'UPDATE_DELETED_TEMPLATES_TO_HIDE'
const UPDATE_EVENTS = 'UPDATE_EVENTS'
const UPDATE_EVENT = 'UPDATE_EVENT'
const DELETE_EVENT = 'DELETE_EVENT'
const UPDATE_FREE_BOARD_EVENTS = 'UPDATE_FREE_BOARD_EVENTS'
const UPDATE_FREE_BOARD_EVENT = 'UPDATE_FREE_BOARD_EVENT'
const DELETE_FREE_BOARD_EVENT = 'DELETE_FREE_BOARD_EVENT'
const UPDATE_SHOULD_REFETCH = 'UPDATE_SHOULD_REFETCH'
const UPDATE_SCHEDULES_EXPIRING_INFO_BY_PROJECT = 'UPDATE_SCHEDULES_EXPIRING_INFO_BY_PROJECT'
const UPDATE_ACTUAL_TZ = 'UPDATE_ACTUAL_TZ'

const state = {
  currentProjectId: null,
  schedulesByProject: [],
  templatesByProject: {},
  schedulesInfo: null,
  schedulesExpiring: null,
  weekStatisticData: {},
  selectedTemplates: [],
  fc_events: null,
  fc_free_board_events: {},
  filters: {
    values: []
  },
  resources: [],
  filteredResources: [],
  isEventsLoaded: true,
  shouldUpdateWeekWorkingHoursStatistic: null,
  infoEvents: [],
  view: {
    currentStart: null,
    currentEnd: null
  },
  filteredShifts: {},
  customTemplatesToHide: [],
  deletedTemplatesToHide: [],
  shouldRefetch: true,
  actualTZ: 'UTC'
}

const getters = {
  currentProjectId: state => +state.currentProjectId,
  schedulesByProject: state => state.schedulesByProject,
  templatesByProject: state => state.templatesByProject,
  schedulesInfo: state => state.schedulesInfo,
  schedulesExpiring: state => state.schedulesExpiring,
  weekStatisticData: state => state.weekStatisticData,
  selectedTemplates: state => state.selectedTemplates,
  fc_events: state => {
    if (!state.fc_events) {
      return null
    }
    const events = Object.values(state.fc_events)
    // console.log(1, state.filters, events)
    if (state.filters.type === 'byLocations' && state.filters.marksType === 'location') {
      return events.filter(event => event.location && state.filters.values.includes(event.location.id))
    }
    if (state.filters.type === 'byMarks' && state.filters.marksType === 'byShift') {
      return events.filter(event => {
        if (!event.marks) {
          return false
        }
        let result = false
        event.marks.forEach(mark => {
          if (state.filters.values.includes(mark)) {
            result = true
          }
        })
        return result
      })
    }
    return events
  },
  fc_free_board_events: state => state.fc_free_board_events,
  fc_filters: state => state.filters,
  fc_resources: state => state.resources,
  fc_filtered_resources: state => state.filteredResources,
  isEventsLoaded: state => state.isEventsLoaded,
  shouldUpdateWeekWorkingHoursStatistic: state => state.shouldUpdateWeekWorkingHoursStatistic,
  infoEvents: state => state.infoEvents,
  view: state => state.view,
  filteredShiftsIndexed: state => state.filteredShifts,
  filteredShifts: state => Object.values(state.filteredShifts),
  customTemplatesToHide: state => state.customTemplatesToHide,
  deletedTemplatesToHide: state => state.deletedTemplatesToHide,
  shouldRefetch: state => state.shouldRefetch,
  actualTZ: state => state.actualTZ
}

const mutations = {
  [UPDATE_CURRENT_PROJECT_ID] (state, projectId) {
    state.currentProjectId = projectId
  },
  [UPDATE_SCHEDULES_BY_PROJECT] (state, schedulesByProject) {
    state.schedulesByProject = schedulesByProject
  },
  [UPDATE_TEMPLATES_BY_PROJECT] (state, templatesByProject) {
    state.templatesByProject = templatesByProject
  },
  [UPDATE_TEMPLATE_BY_PROJECT] (state, template) {
    state.templatesByProject[template.id] = template.name
  },
  [DELETE_TEMPLATE_BY_PROJECT] (state, templateId) {
    delete state.templatesByProject[templateId]
  },
  [UPDATE_SCHEDULES_INFO_BY_PROJECT] (state, schedulesInfo) {
    state.schedulesInfo = schedulesInfo
  },
  [UPDATE_SCHEDULES_EXPIRING_INFO_BY_PROJECT] (state, schedulesExpiring) {
    state.schedulesExpiring = schedulesExpiring
  },
  [UPDATE_WEEK_STATISTIC] (state, payload) {
    state.weekStatisticData = payload
  },
  [UPDATE_SELECTED_TEMPLATES] (state, payload) {
    state.selectedTemplates = payload
  },
  [UPDATE_EVENTS] (state, payload) {
    if (payload) {
      const events = {}
      payload.forEach(event => {
        events[event.id] = event
      })
      state.fc_events = events
    } else {
      state.fc_events = null
    }
  },
  [UPDATE_EVENT] (state, payload) {
    state.shouldRefetch = false
    state.fc_events[payload.id] = payload
  },
  [DELETE_EVENT] (state, eventId) {
    delete state.fc_events[eventId]
  },
  [UPDATE_FREE_BOARD_EVENTS] (state, payload) {
    state.fc_free_board_events = payload
  },
  [UPDATE_FREE_BOARD_EVENT] (state, payload) {
    state.shouldRefetch = false
    const fcFreeBoardEvents = JSON.parse(JSON.stringify(state.fc_free_board_events))
    if (!fcFreeBoardEvents[payload.freeBoardKey]) {
      fcFreeBoardEvents[payload.freeBoardKey] = []
    }
    fcFreeBoardEvents[payload.freeBoardKey].push(payload)
    state.fc_free_board_events = fcFreeBoardEvents
  },
  [DELETE_FREE_BOARD_EVENT] (state, event) {
    if (state.fc_free_board_events[event.freeBoardKey].length === 1) { // если удаляем последний эвент из группы на фриборде
      delete state.fc_free_board_events[event.freeBoardKey]
    } else { // если не фриборде сгруппировано больше одного эвента
      const filtered = state.fc_free_board_events[event.freeBoardKey].filter(e => e.id !== event.id)
      state.fc_free_board_events[event.freeBoardKey] = filtered
    }
  },
  [UPDATE_SHOULD_REFETCH] (state, payload) {
    state.shouldRefetch = payload
  },
  [UPDATE_FILTERS] (state, payload) {
    state.filters = payload
  },
  [UPDATE_RESOURCES] (state, payload) {
    state.resources = payload
  },
  [UPDATE_FILTERED_RESOURCES] (state, payload) {
    state.filteredResources = payload
  },
  [UPDATE_EVENTS_IS_LOADED] (state, payload) {
    state.isEventsLoaded = payload
  },
  [UPDATE_SHOULD_UPDATE_WEEK_STATISTIC] (state, payload) {
    state.shouldUpdateWeekWorkingHoursStatistic = payload
  },
  [DROP_STATE] (state) {
    // const projectId = state.currentProjectId
    // state.currentProjectId = null
    state.schedulesByProject = []
    state.templatesByProject = {}
    state.schedulesInfo = null
    state.weekStatisticData = {}
    state.selectedTemplates = []
    state.fc_events = null
    state.filters = {
      type: 'usersSelectAll',
      values: [],
      marksType: 'byUser'
    }
    state.resources = []
    state.filteredResources = []
    state.isEventsLoaded = true
    state.shouldUpdateWeekWorkingHoursStatistic = null
  },
  [UPDATE_INFO_EVENTS] (state, payload) {
    state.infoEvents = [...payload]
  },
  [UPDATE_VIEW] (state, payload) {
    state.view = { ...payload }
    state.view.currentStart = payload.currentStart
    state.view.currentEnd = payload.currentEnd
  },
  [UPDATE_FILTERED_SHIFTS] (state, payload) {
    const values = {}
    payload.forEach(item => {
      values[item.id] = item
    })
    state.filteredShifts = values
  },
  [UPDATE_FILTERED_SHIFT] (state, payload) {
    state.filteredShifts[payload.id] = payload
  },
  [DELETE_FILTERED_SHIFT] (state, shiftId) {
    delete state.filteredShifts[shiftId]
  },
  [UPDATE_CUSTOM_TEMPLATES_TO_HIDE] (state, payload) {
    state.customTemplatesToHide = [...payload]
  },
  [REMOVE_CUSTOM_TEMPLATES_TO_HIDE] (state, payload) {
    const filtered = state.customTemplatesToHide.filter(val => val !== payload)
    state.customTemplatesToHide = [...filtered]
  },
  [UPDATE_DELETED_TEMPLATES_TO_HIDE] (state, payload) {
    state.deletedTemplatesToHide = [...payload]
  },
  [UPDATE_ACTUAL_TZ] (state, tz) {
    state.actualTZ = tz
  }
}

const actions = {
  updateCustomTemplatesToHide ({ commit }, payload) {
    commit(UPDATE_CUSTOM_TEMPLATES_TO_HIDE, payload)
  },
  removeCustomTemplatesToHide ({ commit }, payload) {
    commit(REMOVE_CUSTOM_TEMPLATES_TO_HIDE, payload)
  },
  updateDeletedTemplatesToHide ({ commit }, payload) {
    // console.log(34, payload)
    commit(UPDATE_DELETED_TEMPLATES_TO_HIDE, payload)
  },
  updateFilteredShiftsList ({ commit }, payload) {
    commit(UPDATE_FILTERED_SHIFTS, payload)
  },
  updateFilteredShift ({ commit }, payload) {
    commit(UPDATE_FILTERED_SHIFT, payload)
  },
  deleteFilteredShift ({ commit }, shiftId) {
    commit(DELETE_FILTERED_SHIFT, shiftId)
  },
  updateSelectedTemplates ({ commit }, payload) {
    commit(UPDATE_SELECTED_TEMPLATES, payload)
  },
  updateInfoEvents ({ commit }, payload) {
    commit(UPDATE_INFO_EVENTS, payload)
  },
  addFCEvent ({ commit }, payload) {
    commit(UPDATE_EVENT, payload)
  },
  deleteFcEvent ({ commit }, eventId) {
    commit(DELETE_EVENT, eventId)
  },
  addFreeBoardEvent ({ commit }, payload) {
    commit(UPDATE_FREE_BOARD_EVENT, payload)
  },
  updateShouldRefetch ({ commit }, payload) {
    commit(UPDATE_SHOULD_REFETCH, payload)
  },
  updateView ({ commit }, payload) {
    commit(UPDATE_VIEW, payload)
  },
  dropFCState ({ commit }) {
    commit(DROP_STATE)
  },
  getEvents ({ commit, rootState, dispatch }, payload) {
    commit(UPDATE_EVENTS_IS_LOADED, false)
    const { queryShifts, queryHolidays, queryUnavailability, queryBreaks, currentProjectId, companyId, viewType, activityQuery } = payload

    let shifts = []
    if (rootState.utils.displayEventsSettings.shifts) {
      shifts = dispatch('getShiftsByProjectId', [currentProjectId, queryShifts])
    }

    const holidays = dispatch('getHolidays', [companyId, queryHolidays])

    const notes = dispatch('getNotesByCompany', [companyId, queryHolidays])

    const deletedUsers = dispatch('getDeletedUsers', [companyId, currentProjectId, queryHolidays])

    let responseActivity = []
    if (rootState.utils.displayEventsSettings.activity) {
      responseActivity = dispatch('getShiftActivities', [companyId, activityQuery])
    }

    let requests = []
    if (rootState.utils.displayEventsSettings.timeOff) {
      requests = dispatch('getRequestsForCalendar', [companyId, queryHolidays])
    }

    let availabilities = []
    availabilities = dispatch('getCompanyAvailability', queryUnavailability)

    let breaks = []
    if (rootState.utils.displayEventsSettings.breaks) {
      breaks = new Promise(resolve => {
        if (viewType === 'resourceTimelineDay') {
          dispatch('getBreaks', [currentProjectId, queryBreaks]).then((response) => {
            resolve(response)
          }).catch((e) => {
            const settings = JSON.parse(localStorage.getItem('displayEventsSettings'))
            settings.breaks = false
            dispatch('updateDisplaySettings', settings)
            localStorage.setItem('displayEventsSettings', JSON.stringify(settings))
            resolve([])
          })
        } else {
          resolve([])
        }
      })
    }

    Promise.all([shifts, holidays, deletedUsers, requests, availabilities, breaks, notes, responseActivity]).then(() => {
      commit(UPDATE_EVENTS_IS_LOADED, true)
      commit(UPDATE_SHOULD_UPDATE_WEEK_STATISTIC, true)
    })
  },
  updateTemplateByProject ({ commit }, payload) {
    commit(UPDATE_TEMPLATE_BY_PROJECT, payload)
  },
  deleteTemplateByProject ({ commit }, templateId) {
    commit(DELETE_TEMPLATE_BY_PROJECT, templateId)
  },
  updateWeekStatistic ({ commit }, payload) {
    commit(UPDATE_WEEK_STATISTIC, payload)
  },
  updateResources ({ commit }, payload) {
    commit(UPDATE_RESOURCES, payload)
  },
  updateShouldUpdateWeekStatistic ({ commit }, payload) {
    commit(UPDATE_SHOULD_UPDATE_WEEK_STATISTIC, payload)
  },
  updateFilteredResources ({ commit }, payload) {
    commit(UPDATE_FILTERED_RESOURCES, payload)
  },
  updateFilters ({ commit }, payload) {
    commit(UPDATE_FILTERS, payload)
  },
  updateFcEvents ({ commit }, payload) {
    commit(UPDATE_EVENTS, payload)
  },
  updateFreeBoardEvents ({ commit }, payload) {
    commit(UPDATE_FREE_BOARD_EVENTS, payload)
  },
  updateCurrentProjectId ({ commit }, projectId) {
    return new Promise(resolve => {
      commit(UPDATE_CURRENT_PROJECT_ID, projectId)
      resolve(projectId)
    })
  },
  updateProjectData ({ commit }, data) {
    return new Promise(resolve => {
      commit(UPDATE_SCHEDULES_BY_PROJECT, data.schedulesByProject)
      commit(UPDATE_TEMPLATES_BY_PROJECT, data.templatesByProject)
      commit(UPDATE_SCHEDULES_INFO_BY_PROJECT, data.schedulesInfo)
      commit(UPDATE_SCHEDULES_EXPIRING_INFO_BY_PROJECT, data.schedulesExpiring)
      resolve(data)
    })
  },
  updateActualTZ ({ commit, rootState, dispatch }) {
    const profileTZ = rootState.profile.Profile.time_zone
    const projectTZ = rootState.projects.projectsIndex[state.currentProjectId] ? rootState.projects.projectsIndex[state.currentProjectId].time_zone : null
    const actualTZMode = localStorage.getItem('actualTZMode')
    if (!actualTZMode || actualTZMode === 'employeeTZ' || projectTZ === null) {
      commit(UPDATE_ACTUAL_TZ, profileTZ)
    } else {
      commit(UPDATE_ACTUAL_TZ, projectTZ)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
