import momentMixin from '@/mixins/mixinMoment'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export default class Info {
  constructor (currentStart = null, currentEnd = null, tz = 'UTC') {
    this.currentStart = currentStart
    this.currentEnd = currentEnd
    this.infoEvents = []
    this.setInfoEvents()
    this.tz = tz
  }

  getInfoEvents () {
    return this.infoEvents
  }

  setRange (currentStart, currentEnd) {
    this.currentStart = currentStart
    this.currentEnd = currentEnd
    return this
  }

  setInfoEvents () {
    this.infoEvents = []
    if (this.currentStart && this.currentEnd) {
      const start = moment(this.currentStart)
      const end = moment(this.currentStart)
      const currentEnd = moment(this.currentEnd)
      do {
        end.add(1, 'day')
        this.infoEvents.push(this.createInfoEvent(start, end))
        start.add(1, 'day')
      } while (end.isBefore(currentEnd))
    }
    return this
  }

  createInfoEvent (start, end) {
    const event = {
      id: [start, end].join('__n__'),
      title: '',
      displayEventTime: false,
      start: start.format(this.backendDateTimeFormatMethod()),
      end: end.format(this.backendDateTimeFormatMethod()),
      resourceId: 'info_event',
      editable: false,
      isInfoEvent: true,
      color: 'rgb(255, 255, 255, 0.1)',
      statistic: {
        hours: 0,
        shifts: 0,
        users: 0,
        breakTime: 0
      },
      resource: 'info_event',
      getStatistic () {
        return this.statistic
      }
    }
    return event
  }

  clearStatistic () {
    this.infoEvents.forEach(infoEvent => {
      infoEvent.statistic.hours = 0
      infoEvent.statistic.shifts = 0
      infoEvent.statistic.users = 0
      infoEvent.statistic.breakTime = 0
    })
  }

  /** Считаем часы в день начала смены **/
  updateStatistic (shifts, resources, filterByUsers = true) {
    this.clearStatistic()
    const cloned = JSON.parse(JSON.stringify(shifts))
    const stat = {}
    const resourcesIds = resources.map(item => item.id)
    // console.log(shifts)
    // console.log(cloned)
    cloned.forEach(shift => {
      // console.log(shift)
      if (this.handleCondition(shift, resourcesIds, filterByUsers)) {
        const shiftKey = this.fromZoneToZone(shift.time_from, 'UTC', this.tz).format(this.backendDateFormatMethod())
        const shiftStart = this.fromZoneToZone(shift.time_from, 'UTC', this.tz).format(this.backendDateTimeFormatMethod())
        const shiftEnd = this.fromZoneToZone(shift.time_to, 'UTC', this.tz).format(this.backendDateTimeFormatMethod())
        const eventRange = moment.range(shiftStart, shiftEnd)
        let duration = moment.duration(eventRange.valueOf()).asMinutes()
        if (shift.presence_info && shift.presence_info.absence) {
          duration = 0
          // console.log(33, shift)
        }

        if (!stat[shiftKey]) {
          stat[shiftKey] = {
            minutes: 0,
            shifts: 0,
            breakTime: 0,
            users: {}
          }
        }
        stat[shiftKey].minutes += duration
        stat[shiftKey].shifts++
        if (duration > 0) {
          stat[shiftKey].breakTime += shift.break_time
        }
        if (!stat[shiftKey].users[shift.employee_id]) stat[shiftKey].users[shift.employee_id] = 0
        stat[shiftKey].users[shift.employee_id]++
      }
    })
    this.infoEvents.forEach(infoEvent => {
      const currentStat = stat[moment(infoEvent.start, this.backendDateTimeFormatMethod()).format(this.backendDateFormatMethod())]
      if (currentStat) {
        infoEvent.statistic.hours = Math.round(((currentStat.minutes - currentStat.breakTime) / 60) * 100) / 100
        infoEvent.statistic.minutes = currentStat.minutes - currentStat.breakTime
        infoEvent.statistic.shifts = currentStat.shifts
        infoEvent.statistic.users = Object.keys(currentStat.users).length
        infoEvent.statistic.breakTime = Math.round((currentStat.breakTime / 60) * 100) / 100
        infoEvent.statistic.breakTimeMinutes = currentStat.breakTime
      }
    })
    return this
  }

  handleCondition (shift, resourcesIds, filterByUsers) {
    if (filterByUsers) { // статистика с фильтрацией по выбранным юзерам для стандартной вьюхи
      return +shift.employee_id !== 0 && !shift.isBackground && resourcesIds.includes(+shift.employee_id)
    } else { // статистика для вьюхи по темплейтам без учета фиальтра по юзерам
      return +shift.employee_id !== 0 && !shift.isBackground
    }
  }
}

Object.assign(Info.prototype, momentMixin.methods)
