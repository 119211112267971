<template>
  <table class="table table-borderless table-sm">
    <tbody class="p-0">
    <tr class="p-0" @click="toggleInfo" style="cursor: pointer">
      <td class="p-0 pb-2 text-primary font-weight-bold text-truncate text-center" colspan="2">{{$t('Working time details')}} <i class="icon-chevron-down"></i></td>
    </tr>
    <template v-if="shiftInfo.chunks && (!shiftInfo.presence_info || !shiftInfo.presence_info.absence)">
      <template v-if="showInfo">
        <tr class="p-0">
          <td class="text-primary font-weight-bold p-0">{{$t('Rate')}}</td>
          <td class="text-primary text-center font-weight-bold p-0">{{$t('Time')}}</td>
        </tr>
        <template v-for="(chunk, index) in shiftInfo.chunks" :key="index">
          <tr class="p-0">
            <td class="p-0">
              {{chunk.total_percent}} %
            </td>
            <td class="text-center p-0">
              {{Math.round(chunk.time / 60 * 100) / 100}} {{$t('hrs')}}
            </td>
          </tr>
        </template>
        <tr class="text-primary font-weight-bold border-top p-0">
          <td class="p-0">
            {{$t('Total')}}, {{$t('hrs')}}
          </td>
          <td class="text-center p-0">
            {{Math.round(totalTime / 60 * 100) / 100}} {{$t('hrs')}}
          </td>
        </tr>
        <tr class="text-primary font-weight-bold p-0">
          <td class="p-0">
            {{$t('BONUS_PENALTY')}}
          </td>
          <td class="text-center p-0">
            {{bonusPenalty}}
          </td>
        </tr>
        <tr v-if="salaryForManager" class="text-primary font-weight-bold p-0">
          <td class="p-0">
            {{$t('Total')}}, {{currency}}
          </td>
          <td class="text-center p-0">
            {{totalCost}}
          </td>
        </tr>
      </template>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'ShiftStatisticComponent',
  data () {
    return {
      showInfo: true
    }
  },
  props: ['shiftInfo'],
  created () {
    this.showInfo = !this.isMobile
  },
  watch: {
    isMobile () {
      this.showInfo = !this.isMobile
    }
  },
  computed: {
    salaryForManager () {
      if (this.isEmployeeHasPermission('get-company-schedules-admin')) {
        return true
      }
      return this.$store.state.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.state.company.company.salary.show_salary_to_managers
    },
    currency () {
      return this.$store.getters.company && this.$store.getters.company.salary ? this.$store.getters.company.salary.currency : 'USD'
    },
    bonusPenalty () {
      let sum = 0
      if (this.shiftInfo.bonuses) {
        this.shiftInfo.bonuses.forEach(bonus => {
          if (bonus.type === 'bonus') {
            sum += +bonus.amount
          }
          if (bonus.type === 'penalty') {
            sum -= +bonus.amount
          }
        })
      }
      return Math.round((sum + Number.EPSILON) * 100) / 100
    },
    totalCost () {
      let summ = 0
      if (this.shiftInfo.chunks) {
        this.shiftInfo.chunks.forEach(chunk => {
          summ += +chunk.cost
        })
      }
      if (this.shiftInfo.presence_info) {
        // if (this.shiftInfo.presence_info.lateness) {
        //   let cost = summ / (this.totalTime + this.shiftInfo.presence_info.lateness)
        //   summ = summ - this.shiftInfo.presence_info.lateness * cost
        // }
        if (this.shiftInfo.presence_info.absence) {
          summ = 0
        }
      }
      return Math.round(((summ + this.bonusPenalty) + Number.EPSILON) * 100) / 100
    },
    totalTime () {
      let time = 0
      if (this.shiftInfo.chunks) {
        this.shiftInfo.chunks.forEach(chunk => {
          time += chunk.time
        })
      }
      if (this.shiftInfo.presence_info) {
        // if (this.shiftInfo.presence_info.lateness) {
        //   time = time - this.shiftInfo.presence_info.lateness
        // }
        if (this.shiftInfo.presence_info.absence) {
          time = 0
        }
      }
      return time
    }
  },
  methods: {
    toggleInfo () {
      this.showInfo = !this.showInfo
    }
  }
}
</script>

<style scoped>

</style>
