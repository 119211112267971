<template>
  <Form ref="observe" as="div">
    <div class='row mb-2 mx-auto'>
      <nav aria-label='breadcrumb'>
        <ol class='breadcrumb bg-transparent p-0 m-0'>
          <li class='breadcrumb-item title-text'>
            <router-link :to="{ name: 'Locations', params: { companyId: companyId} }">
              <span class='breadcrumb-item'>{{ $t('LOCATIONS_TITLE')}}</span>
            </router-link>
          </li>
          <li class='breadcrumb-item title-text active' aria-current='page'>
            <span class='title-text'>{{locationId ? $t('UPDATE_LOCATION_TITLE') : $t('CREATE_LOCATION_TITLE')}}</span>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <ui-text-input
                  :label="$t('LOCATION_TITLE')"
                  id="title"
                  :name="'title'"
                  v-model="location.title"
                  :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
                  :placeholder="$t('Title')"
                />
              </div>
              <div class="col-md-6">
                <label class="col-form-label font-weight-bold">{{ $t('ADDRESS_TITLE') }}</label>
                <GmvAutocomplete
                  :value="location.address"
                  :options="{fields: ['geometry', 'formatted_address']}"
                  class="form-control"
                  @place_changed='setPlace'
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <ui-color-picker
                  name="color"
                  :label="$t('Choose a color:')"
                  v-model="location.color"
                  :colors="colorsChoices"
                />
              </div>
              <div class="col-md-6">
                <ui-text-input
                  :label="$t('LOCATION_RATE_TITLE')"
                  :hint="$t('LOCATION_RATE_HINT')"
                  :placeholder="$t('Please input')"
                  v-model="location.rate"
                  name="rate"
                  :validation="{required: false, decimalOrNull: true}"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <label class="col-form-label font-weight-bold">{{ $t('SWITCH_SHOWTYPE_TITLE') }}</label>
                <div>
                  <ui-switch
                    name="isCircle"
                    v-model="isCircle"
                    @change="val => isCircle = val"
                    :active-text="$t('SWITCH_CIRCLE')"
                    :inactive-text="$t('SWITCH_POLYGON')"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <template v-if="location.use_type === 'circle'">
                  <ui-number-input
                    :label="$t('RADIUS_TITLE')"
                    v-model="location.allowed_radius"
                    name="radius"
                    :precision="0"
                    :step="1"
                    :min="0"
                  />
                </template>
                <template v-if="location.use_type === 'polygon'">
                  <label class="col-form-label font-weight-bold">{{ $t('COORDINATES_CLEAR_TITLE') }}</label>
                  <div>
                    <button
                      class="btn btn-outline-primary btn-sm rounded-pill"
                      @click="clearPolygon">
                      {{ $t('CLEAR_POLYGON') }}
                    </button>
                  </div>
                </template>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <Form ref="net" as="div">
                  <div class="row align-items-end">
                    <div class="col">
                      <ui-text-input
                        :label="$t('ADD_NETWORK_TITLE')"
                        :hint="$t('ADD_NETWORK_HINT')"
                        v-model="newNetwork"
                        name="network"
                        :validation="{ip: true}"
                      />
                    </div>
                    <div class="col-auto px-0 mx-0 mb-2">/</div>
                    <div class="col-md-3">
                      <ui-text-input
                        :label="$t('SUBNET')"
                        :hint="$t('ADD_SUBNET_HINT')"
                        v-model="newSubnet"
                        name="subnet"
                        :validation="{integer: true}"
                      />
                    </div>
                  </div>
                  <button
                    class="btn btn-outline-primary btn-sm rounded-pill mt-2"
                    @click="addNetwork">
                    {{ $t('ADD_NETWORK') }}
                  </button>
                </Form>
              </div>
              <div class="col-md-6">
                <label class="col-form-label font-weight-bold">{{ $t('AVAILABLE_NETWORKS') }}</label>
                <template v-if="location.networks.length > 0">
                  <div class="row mt-1" v-for="(network, index) in location.networks" :key="'net__' + index">
                    <div class="col">
                      {{network}}
                    </div>
                    <div class="col-auto">
                      <i class="icon-trash btn-icon text-danger" @click="deleteNetwork(index)"></i>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center mt-3">
                    {{$t('NO_NETWORKS_ADDED')}}
                  </div>
                </template>
              </div>
            </div>
            <el-divider />
            <div class="row">
              <div class="col text-center">
                <button
                  class="btn btn-success btn-sm rounded-pill mt-2"
                  @click="save">
                  {{ $t('SAVE_CHANGES') }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <GmvMap
              ref="mapRef"
              :center="center"
              @click="mapClick($event)"
              :zoom='18'
              style='width:100%;  height: 600px;'
            >
              <GmvCircle
                v-if="location.use_type === 'circle'"
                @radius_changed="circleRadiusChanged"
                @center_changed="circleDragEnd"
                :center="center"
                :draggable="true"
                :editable="true"
                :radius="+location.allowed_radius"
              />
              <GmvPolygon
                ref="polyRef"
                v-if="location.use_type === 'polygon' && polygonEnable"
                :draggable="true"
                :editable="true"
                :paths="polygon"
                @paths_changed="polygonChanged($event)"
              />
            </GmvMap>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import colors from '@/mixins/mixinColors'
import { utilities } from '@gmap-vue/v3'

const { getGoogleMapsAPI } = utilities

export default {
  name: 'Location',
  mixins: [errorMixin, colors],
  data () {
    return {
      location: {
        id: null,
        title: null,
        address: null,
        longitude: null,
        latitude: null,
        allowed_radius: 100,
        networks: [],
        geometry: [],
        rate: '',
        color: '#479d3b',
        use_type: 'circle'
      },
      newNetwork: null,
      newSubnet: null,
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      places: [],
      polygon: [],
      isCircle: true,
      google: null,
      polygonEnable: true
    }
  },
  created () {
    this.initGoogle()
    if (this.google) {
      this.init()
    }
  },
  computed: {
    locationId () {
      return this.$route.params.locationId
    },
    companyId () {
      return this.$store.getters.companyId
    },
    locale () {
      return this.$store.getters.locale
    }
  },
  watch: {
    isCircle () {
      if (this.isCircle) {
        this.location.use_type = 'circle'
      } else {
        this.location.use_type = 'polygon'
      }
    },
    google () {
      if (this.google) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      if (this.locationId) {
        this.$store.dispatch('getSingleLocation', [this.companyId, this.locationId]).then(response => {
          this.location = response
          if (this.location.rate === null) {
            this.location.rate = ''
          }
          this.isCircle = this.location.use_type === 'circle'
          if (this.location.geometry && this.location.geometry.coordinates) {
            const polygon = []
            this.location.geometry.coordinates[0].forEach(point => {
              polygon.push({
                lng: +point[0],
                lat: +point[1]
              })
            })
            this.polygon = polygon
          }
          this.geolocate()
        })
      } else {
        this.geolocate()
      }
    },
    initGoogle () {
      this.google = getGoogleMapsAPI()
      if (!this.google) {
        setTimeout(() => {
          this.initGoogle()
        }, 1000)
      }
    },
    setPlace (place) {
      if (place.formatted_address && place.geometry) {
        this.location.address = place.formatted_address
        this.location.latitude = place.geometry.location.lat()
        this.location.longitude = place.geometry.location.lng()
        this.center = {
          lat: this.location.latitude,
          lng: this.location.longitude
        }
        this.$refs.mapRef.mapPromise?.then((map) => {
          map.panTo(this.center)
        })
      } else {
        this.location.address = place.name
      }
    },
    geolocate () {
      if (this.$refs.mapRef) {
        if (this.location.latitude && this.location.longitude) {
          this.center = {
            lat: +this.location.latitude,
            lng: +this.location.longitude
          }
        } else {
          navigator.geolocation.getCurrentPosition(position => {
            if (position) {
              this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              }
              this.location.latitude = position.coords.latitude
              this.location.longitude = position.coords.longitude
            }
          })
        }
        this.$refs.mapRef.mapPromise?.then((map) => {
          map.panTo(this.center)
        })
      } else {
        setTimeout(() => {
          this.geolocate()
        }, 1000)
      }
    },
    toGeoJSON (polygon) {
      // lng - lat
      const paths = []
      polygon.forEach(path => {
        paths.push([path.lng, path.lat])
      })
      if (JSON.stringify(paths[0]) !== JSON.stringify(paths[paths.length - 1])) { // first and last point must be the same
        paths.push(paths[0])
      }
      this.location.geometry = {
        type: 'Polygon',
        coordinates: [paths]
      }
    },
    mapClick (val) {
      this.polygonEnable = false
      if (this.location.use_type === 'polygon') {
        const position = {
          lat: val.latLng.lat(),
          lng: val.latLng.lng()
        }
        // т.к. полигон должен быть замкнутым (координаты первой точки должны совпадать с координатами последней),
        // сначала удалям последнее значение массива (которое равно первому), добавляем новую точку,
        // а затем снова копируем первое значение массива в конец массива
        if (this.polygon.length > 1) {
          this.polygon.splice(this.polygon.length - 1)
        }
        this.polygon.push(position)
        this.polygon.push(this.polygon[0])
        this.toGeoJSON(this.polygon)
      }
      this.$nextTick(() => {
        this.polygonEnable = true
      })
    },
    polygonChanged (mvcArray) {
      this.polygon = []
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j)
          this.polygon.push({ lat: point.lat(), lng: point.lng() })
        }
      }
      // paths.forEach(path => {
      //   this.polygon.push({
      //     lat: path.lat(),
      //     lng: path.lng()
      //   })
      // })
      this.toGeoJSON(this.polygon)
    },
    clearPolygon () {
      this.polygon = []
      this.toGeoJSON(this.polygon)
    },
    circleRadiusChanged (val) {
      const radius = val || 0
      this.location.allowed_radius = Math.round(radius)
    },
    circleDragEnd (val) {
      const geocoder = new this.google.maps.Geocoder()
      const latlng = {
        lat: parseFloat(val.lat()),
        lng: parseFloat(val.lng())
      }
      this.location.latitude = latlng.lat
      this.location.longitude = latlng.lng
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.location.address = results[0].formatted_address
          } else {
            console.log('No results found')
          }
        } else {
          console.log(status)
        }
      }).catch(() => {})
    },
    addNetwork () {
      this.$refs.net.validate('net').then(result => {
        if (result.valid) {
          let network = null
          if (this.newNetwork && this.newNetwork.length > 0) {
            network = this.newNetwork
            if (this.newSubnet && this.newSubnet.length > 0) {
              network += '/' + this.newSubnet
            }
            this.location.networks.push(network)
            this.newNetwork = null
            this.newSubnet = null
          }
        }
      })
    },
    deleteNetwork (index) {
      this.location.networks.splice(index, 1)
    },
    save () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          if (this.locationId) {
            this.$store.dispatch('editLocation', [this.companyId, this.locationId, this.location]).then(() => {
              this.toastSuccess(this.$i18n?.t('LOCATION_UPDATE_MESSAGE'))
            })
          } else {
            this.$store.dispatch('createLocation', [this.companyId, this.location]).then(() => {
              this.toastSuccess(this.$i18n?.t('LOCATION_CREATE_MESSAGE'))

              this.$router.push(`/c/${this.companyId}/locations`)
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
