<template>
  <div>
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          {{ $t('ACTIVITY_MODULE_SETTINGS') }}
          <ui-hint v-if="moduleInfo" :content="moduleInfo"/>
        </h5>
      </div>
      <div class="collapse show" aria-labelledby="headingOne">
        <div class="col-lg-12 font-weight-bold text-warning my-2">{{ $t('ACTIVITY_MODULE_INFO_ALERT') }}</div>
        <div class="card-body">
          <modal-component
            :id="'ActivitySettingsModal'"
            @closeModal="closeModal"
            :modalClass="'modal-dummy'"
            :modalBodyClass="'dummy-popup'"
            :hideFooter="true"
            :hideHeader="true"
            >
            <template #body>
              <div>
                <dummy-payment-required
                  @activate="activate"
                  :dummyInfo="dummyInfo"
                  :isModal="true">
                </dummy-payment-required>
              </div>
            </template>
          </modal-component>
          <Form ref="observer" as="div" class="form-row">
            <div class="col-auto"
                @click="handleActivation"
                :data-toggle="!isModuleActive ? 'modal' : null"
                :data-target="!isModuleActive ? '#ActivitySettingsModal' : null">
              <div class="form-row">
                <el-divider content-position="left">
                  <span class="text-primary text-uppercase font-weight-bold">
                    {{ $t('MAIN_SETTINGS') }}
                  </span>
                </el-divider>
                <div class="col-lg-12 mb-1">
                  <ui-number-input
                    name="action_time_before"
                    :label="$t('ACTION_TIME_BEFORE')"
                    v-model="settings.action_time_before"
                    :validation="{required: true, integer: true, min: 0}"
                    class="w-50"
                    :disabled="!isModuleActive"
                    :precision="0"
                    :step="1"
                    :min="0"
                  />
                </div>
                <div class="col-lg-12 mb-1">
                  <ui-number-input
                    name="action_time_after"
                    :label="$t('ACTION_TIME_AFTER')"
                    v-model="settings.action_time_after"
                    :validation="{required: true, integer: true, min: 0}"
                    class="w-50"
                    :disabled="!isModuleActive"
                    :precision="0"
                    :step="1"
                    :min="0"
                  />
                </div>
                <div class="col-lg-12 mt-2 mb-1">
                  <ui-checkbox
                    name="settings.change_event_time"
                    v-model="settings.change_event_time"
                    :disabled="!isModuleActive"
                    :label="$t('CHANGE_EVENT_TIME')"
                    :hint="$t('CHANGE_EVENT_TIME_HINT')"
                  />
                </div>
                <div class="col-lg-12 mt-2 mb-1">
                  <el-divider content-position="left">
                  <span class="text-primary text-uppercase font-weight-bold">
                    {{ $t('ACTION_WITH_UNCLOSED_ACTIVITIES') }}
                  </span>
                    <ui-hint :content="$t('ACTION_WITH_UNCLOSED_ACTIVITIES_HINT')" />
                  </el-divider>
                  <label class="control-label font-weight-bold">{{ $t('ACTION_WITH_UNCLOSED_ACTIVITIES_LABEL') }}</label>
                  <ui-radio
                    name="send_message"
                    :label="$t('Send Message')"
                    v-model="settings.action_after_end_time"
                    :set-value="'send_message'"
                    :disabled="!isModuleActive"
                  />
                  <ui-radio
                    name="close"
                    :label="$t('Close')"
                    v-model="settings.action_after_end_time"
                    :set-value="'close'"
                    :disabled="!isModuleActive"
                  />
                </div>
                <div class="col-lg-12 mt-2 mb-1">
                  <el-divider content-position="left">
                  <span class="text-primary text-uppercase font-weight-bold">
                    {{ $t('UNSCHEDULED_ACTIVITY') }}
                    <ui-hint :content="$t('UNSCHEDULED_ACTIVITY_HINT')" />
                  </span>
                  </el-divider>
                  <div class="col-lg-12">
                    <ui-checkbox
                      name="settings_unscheduled_activity"
                      v-model="settings.unscheduled_activity"
                      :disabled="!isModuleActive"
                      :label="$t('unscheduled_activity') + ' ' + '(' + $t('min') + ')'"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <ui-number-input
                      :disabled="!isModuleActive || !settings.unscheduled_activity"
                      name="unscheduled_activity_time"
                      :label="$t('UNSCHEDULED_ACTIVITY_LENGTH_MINUTES')"
                      v-model="settings.unscheduled_activity_time"
                      :validation="{required: true, integer: true, min_value: 5, max_value: 1440}"
                      :precision="0"
                      :step="1"
                      :min="5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsActivity',
  components: {
    ModalComponent,
    DummyPaymentRequired
  },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      module: null,
      settings: {
        action_time_before: 0,
        action_time_after: 0,
        change_event_time: false,
        action_after_end_time: 'send_message',
        unscheduled_activity: false,
        unscheduled_activity_time: 60
      },
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    async activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'activities')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 28) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.settings.action_time_before = module.settings.action_time_before ? module.settings.action_time_before : 0
            this.settings.action_time_after = module.settings.action_time_after ? module.settings.action_time_after : 0
            this.settings.change_event_time = module.settings.change_event_time ? module.settings.change_event_time : false
            this.settings.action_after_end_time = module.settings.action_after_end_time ? module.settings.action_after_end_time : 'send_message'
            this.settings.unscheduled_activity = module.settings.unscheduled_activity ? module.settings.unscheduled_activity : false
            this.settings.unscheduled_activity_time = module.settings.unscheduled_activity_time ? module.settings.unscheduled_activity_time : 60
          }
        }
      })

      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 29]).then((e) => {
        this.isModuleActive = true
      }).catch(err => {
        this.isModuleActive = false
        this.dummyInfo = this.setupDummyData(err.response.data)
        this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
          ? err.response.data.module.locales[this.locale].description
          : err.response.data.module.description
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    async submitForm () {
      try {
        const result = await this.$refs.observer.validate()
        if (result.valid) {
          await this.$store.dispatch('updateModuleSettings', [
            this.$store.getters.companyId,
            this.module.id,
            {
              settings: this.settings
            }
          ])
          this.$emit('success')
        }
      } catch (error) {
        console.log(error)
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>

</style>
