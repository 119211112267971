<template>
  <modal-component :id="'ClickMassActionActivity'" :modalClass="'modal-lg'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer" as="div">
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-select
                :label="$t('Action')"
                :name="'action'"
                v-model="payload.action"
                :clearable="false"
                :placeholder="$t('Select')"
                :options="activityActionOptions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :disabled="true"
                :validation="{required: true}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ui-date-picker
                :label="$t('From')"
                name="date_from"
                v-model="payload.date_from"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
            <div class="col-6">
              <ui-date-picker
                :label="$t('To')"
                name="date_to"
                v-model="payload.date_to"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-multi-select
                :label="$t('Template')"
                :name="'activity_template'"
                v-model="payload.templates"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="activityTemplatesOptions"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
                :validation="{required: false}"
              />
            </div>
            <div class="col-6 form-group text-left">
              <ui-multi-select
                :label="$t('Project')"
                :name="'project'"
                v-model="payload.projects"
                :clearable="true"
                :placeholder="$t('Select')"
                :options="projects"
                :key-name="'id'"
                :label-name="'title'"
                :value-name="'id'"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 form-group text-left">
              <ui-multi-select
                name="employees"
                :label="$t('Employees')"
                v-model="payload.employees"
                :options="employees"
                :clearable="true"
                :key-name="'id'"
                :label-name="'full_name'"
                :value-name="'id'"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 p-1">
              <div class="text-center">
                <button
                  class="btn btn-success btn-sm rounded-pill shadow-sm mx-1"
                  @click="update">
                  {{ $t('DO_THE_ACTION') }}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent.vue'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'ShiftActivityMassActionModal',
  mixins: [momentMixin],
  components: { ModalComponent },
  data () {
    return {
      payload: {
        action: 'delete',
        templates: [],
        date_from: moment().startOf('isoWeek').toDate(),
        date_to: moment().endOf('isoWeek').toDate(),
        employees: [],
        projects: []
      },
      activityActionOptions: [
        {
          id: 'delete',
          name: this.$t('Delete')
        }
      ],
      moment: moment
    }
  },
  props: { title: String, initStart: Object, initEnd: Object },
  created () {
    this.payload.date_from = this.initStart.toDate()
    this.payload.date_to = this.initEnd.toDate()
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    tz () {
      return this.$store.getters.profileTimeZone
    },
    activityTemplatesOptions () {
      return this.$store.getters.activityTemplates.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    projects () {
      return this.$store.getters.projects.map(item => {
        return {
          id: item.id,
          title: item.name
        }
      })
    },
    employees () {
      const list = [...this.$store.getters.employees]
      list.unshift({
        id: 0,
        full_name: this.$t('FREE_BOARD')
      })
      return list
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeShiftActivityModal')
    },
    update () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          let warning = ''
          if (this.payload.action === 'delete') {
            warning = 'ACTIVITY_DELETE_WARNING'
          }
          this.$confirm(
            this.$i18n?.t(warning),
            this.$i18n?.t('Are you sure?'),
            {
              confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
              cancelButtonText: this.$i18n?.t('No, cancel it!!'),
              type: 'warning',
              center: true
            }
          ).then(() => {
            const payload = { ...this.payload }
            payload.date_from = this.fromZoneToZone(payload.date_from, this.tz, 'UTC').format(this.backendDateTimeFormat)
            payload.date_to = this.fromZoneToZone(payload.date_to, this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat)
            this.$store.dispatch('patchShiftActivitiesMassAction', [this.companyId, payload]).then(_ => {
              this.$emit('updateActivity')
              this.toastSuccess(this.$i18n?.t('ACTIVITIES_MASS_ACTION_SUCCESS'))
              document.getElementById('modalCloseButton').click()
            }).catch(error => {
              this.toastError(this.$i18n?.t(error.response.data.message))
            })
          }).catch(() => {
          })
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
