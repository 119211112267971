<template>
  <div class="row">
    <div class="col">
      <ul class="nav mb-1" :class="tabClass">
        <li v-for="(tab, index) in tabs" class="nav-item" :key="index">
          <router-link class="nav-link" :class="{'active': tab.isActive}" :to="tab.link">
            <span>{{ $t(tab.title)}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabNavigationComponent',
  props: { tabs: Array },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  computed: {
    tabClass () {
      if (this.windowWidth < 475) {
        return 'flex-column nav-pills'
      }
      return 'nav-tabs'
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped>

</style>
