import { HTTP } from '@/api/http_utils'

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  exportSchedule ({ commit }, [projectId, fileType, dateFrom, range, displayUsers, mode]) {
    return new Promise(resolve => {
      HTTP.get(`/projects/${projectId}/export?type=${fileType}&range=${range}&display_users=${displayUsers}&date_from=${dateFrom}&mode=${mode}`, { responseType: 'blob' }).then(response => {
        resolve(response)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
