import { HTTP } from '@/api/http_utils'

const state = {
  availability: {},
  companyAvailability: {}
}

const getters = {
  availability: state => Object.values(state.availability),
  availabilityIndexed: state => state.availability,
  companyAvailability: state => Object.values(state.companyAvailability),
  companyAvailabilityIndexed: state => state.companyAvailability
}

const COMPANY_AVAILABILITY_SET = 'COMPANY_AVAILABILITY_SET'
const AVAILABILITY_SET = 'AVAILABILITY_SET'
const AVAILABILITY_UPDATE = 'AVAILABILITY_UPDATE'
const AVAILABILITY_DELETE = 'AVAILABILITY_DELETE'

const mutations = {
  [COMPANY_AVAILABILITY_SET] (state, data) {
    const payload = {}
    data.forEach(item => {
      payload[item.id] = item
    })
    state.companyAvailability = payload
  },
  [AVAILABILITY_SET] (state, data) {
    const payload = {}
    data.forEach(item => {
      payload[item.id] = item
    })
    state.availability = payload
  },
  [AVAILABILITY_UPDATE] (state, data) {
    state.availability[data.id] = data
  },
  [AVAILABILITY_DELETE] (state, data) {
    delete state.availability[data]
  }
}

const actions = {
  getCompanyAvailability ({ commit, rootState }, payload) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/availability${payload}`).then(response => {
        commit(COMPANY_AVAILABILITY_SET, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getAvailability ({ commit }, [employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/employees/${employeeId}/employee_availability${payload}`).then(response => {
        commit(AVAILABILITY_SET, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createAvailability ({ commit }, [employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/employees/${employeeId}/employee_availability`, payload).then(response => {
        // console.log(99, response)
        commit(AVAILABILITY_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateAvailability ({ commit }, [employeeId, eventId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/employees/${employeeId}/employee_availability/${eventId}`, payload).then(response => {
        commit(AVAILABILITY_UPDATE, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteAvailability ({ commit }, [employeeId, eventId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/employees/${employeeId}/employee_availability/${eventId}/delete`, payload).then(() => {
        commit(AVAILABILITY_DELETE, eventId)
        resolve(true)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
