<template>
  <li v-if="firstSteps < 100" class="nav-item d-none d-md-block my-auto">
    <router-link class="p-0" :to="link">
      <div class="row p-0" id="demo_steps">
        <div class="col d-flex pr-0">
          <div class="align-self-center">
            <div class="text-secondary">
              {{ $t('Create a first schedule') }}
            </div>
            <button
              class="btn btn-outline-primary btn-sm-header rounded-pill btn-fix-width shadow-sm float-right">
              {{ $t('Setup') }}
            </button>
          </div>
        </div>
        <div class="col-auto d-flex">
          <el-progress
            type="circle"
            :width="60"
            color="#17c671"
            :percentage="firstSteps">
          </el-progress>
        </div>
      </div>
    </router-link>
  </li>
</template>
<script>
export default {
  name: 'HeaderFirstStepsComponent',
  props: {
    schedules: Array
  },
  emits: ['updateFirstSteps'],
  computed: {
    projectsCount () {
      return this.$store.getters.projectsCount
    },
    showProject () {
      return this.projectsCount < 1
    },
    showUsers () {
      return this.$store.getters.employeesCount <= 1
    },
    showSchedule () {
      if (this.schedules.length < 1) return true
      let manuallyCount = 0
      this.schedules.forEach(schedule => {
        if (schedule.schedule_type === 'manually') manuallyCount++
      })
      return this.schedules.length === 1 && manuallyCount > 0
    },
    firstSteps () {
      let value = 0
      if (!this.showProject) value += 33
      if (!this.showUsers) value += 33
      if (!this.showSchedule) value += 33
      if (!this.showProject && !this.showUsers && !this.showSchedule) value = 100
      this.$emit('updateFirstSteps', value)
      return value
    },
    link () {
      if (this.showUsers) return `/c/${this.companyId}/users`
      if (this.showProject) return `/c/${this.companyId}/project`
      if (this.showSchedule) return `/c/${this.companyId}/schedule/master`
      return ''
    }
  }
}
</script>
<style lang="scss">
.el-progress__text {
  font-size: 12px !important;
}
</style>
