<template>
  <Form ref="observer" v-slot="{ meta }" as="tr">
    <td>
      <div class="my-auto">
        <span v-if="!inEdit.id">{{tag.name}}</span>
        <ui-text-input
          v-if="inEdit.id"
          :name="index + '__tag_title'"
          :label="$t('Add a position')"
          v-model="inEdit.name"
          :validation="{required: true, max: 128}"
        />
      </div>
    </td>
    <td class="w-25">
      <span v-if="!inEdit.id" class="project-color" v-bind:style="{ backgroundColor: tag.color }"></span>
      <ui-color-picker
        :name="index + '__color'"
        v-if="inEdit.id"
        v-model="inEdit.color"
        :colors="colorsChoicesMarks"
        :label="$t('Choose a color:')"
      />
    </td>
    <td class="w-25">
      <div class="d-flex justify-content-end">
        <a v-if="!inEdit.id" @click="editTag()">
          <i class="icon-pencil btn-icon text-primary mx-2"></i>
        </a>
        <a v-if="!inEdit.id" @click="deleteTag()">
          <i class="icon-trash btn-icon text-danger"></i>
        </a>
        <div class="mt-1">
          <i
            v-if="inEdit.id"
            class="icon-check btn-icon text-success"
            :class="{'disabled': !meta.valid}"
            @click="saveEdited(tag)" :title="$t('SAVE_WEB')">
          </i>
        </div>
      </div>
    </td>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'TagEditComponent',
  mixins: [errorMixin],
  props: {
    tag: Object,
    projectId: String,
    colorsChoicesMarks: Array,
    index: Number
  },
  data () {
    return {
      editStub: {
        id: null
      },
      inEdit: {
        id: null
      }
    }
  },
  methods: {
    saveEdited () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('editMarks', [this.inEdit, this.projectId, this.inEdit.id]).then(() => {
            this.inEdit = Object.assign({}, this.editStub)
          }).catch(e => {
            this.toastError(this.$i18n?.t(e.response.data.message))
          })
        }
      })
    },
    editTag () {
      this.inEdit = Object.assign({}, this.tag)
    },
    deleteTag () {
      this.$store.dispatch('deleteMark', [this.projectId, this.tag.id]).then(() => {
      })
    }
  }
}
</script>

<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
