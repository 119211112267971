<template>
  <div class="row my-1">
    <div class="col-5">
      <ui-number-input
        :name="index + '__settings.dynamic_breaks.shift_time'"
        :label="$t('Shift time') + ' (' + $t('hrs') + ')'"
        :disabled="disabledActions"
        v-model="itemState.shift_time"
        :validation="{required: true, decimal: true, min_value: 0}"
        :hint="$t('Shift_time_HINT')"
      />
    </div>
    <div class="col-5">
      <ui-number-input
        :name="index + '__settings.dynamic_breaks.break_time'"
        :label="$t('Break time') + ' (' + $t('min') + ')'"
        :disabled="disabledActions"
        v-model="itemState.break_time"
        :validation="{required: true, decimal: true, min_value: 0}"
        :precision="0"
        :step="1"
        :min="0"
        :hint="$t('Break_time_HINT')"
      />
    </div>
    <div class="col-auto m-1 d-flex text-center">
      <i
        class="icon-trash btn-icon text-danger my-auto pt-2"
        @click="deleteItem()"
        role="button"
      >
      </i>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'BreakItem',
  mixins: [errorMixin],
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      itemState: {}
    }
  },
  props: {
    item: {
      type: Object
    },
    showItemConfig: {
      type: Boolean
    },
    disabledActions: {
      type: Boolean
    },
    index: Number
  },
  created () {
    this.itemState = this.item
  },
  methods: {
    deleteItem () {
      if (!this.disabledActions) {
        this.$emit('deleteItem')
      }
    },
    handleClose (tag) {
      this.itemState.type_options.splice(this.itemState.type_options.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        if (!this.itemState.type_options) this.itemState.type_options = []
        this.itemState.type_options.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
