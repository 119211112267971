<template>
  <div>
    <div class="row d-flex align-items-center mb-1">
      <div class="col">
        <h1 class="title-text">{{ $t('Integrations') }}</h1>
      </div>
      <div class="col-auto">
        <ui-input-label :label="$t('Select category')" />
      </div>
      <div class="col-auto">
        <ui-select
          name="location"
          v-model="categoryFilter"
          class="w-100"
          :options="appCategories"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 p-1">
        <div class="card card-small">
          <div class="card-body">
            <developer-app-marketplace :filter="categoryFilter" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeveloperAppMarketplace from '@/components/AppMarketplace/DeveloperAppMarketplace'
import { toRaw } from 'vue'

export default {
  name: 'Developer',
  components: { DeveloperAppMarketplace },
  data () {
    return {
      activeTab: 'integrations',
      tabsData: {
        integrations: 'Integrations',
        developers: 'Developers integrations'
      },
      showTabs: true,
      buttonTitle: 'Please Select',
      categoryFilter: 'all'
    }
  },
  created () {
    this.$store.dispatch('getAppCategories')
  },
  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'integrations'
    if (this.accessDenied) {
      this.accessErrorRedirect('Only company owner can access this page')
    }
  },
  watch: {
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('Only company owner can access this page')
      }
    }
  },
  computed: {
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('get webhook')
    },
    appCategories () {
      return [{ id: 'all', name: this.$i18n?.t('All') }].concat(toRaw((this.$store.getters.appCategories)))
    }
  },
  methods: {
    toggleMenu () {
      this.showTabs = !this.showTabs
    },
    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    }
  }
}
</script>

<style scoped>

</style>
