<template>
  <el-tooltip :placement="placement" :show-after="openDelay" :hide-after="hideAfter" :effect="effect">
    <template #content>
      <slot name="hint"></slot>
    </template>
    <slot name="inner-html"></slot>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ui-tooltip',

  props: {
    placement: { type: String, default: 'top' },
    openDelay: { type: Number, default: 0 },
    hideAfter: { type: Number, default: 0 },
    effect: { type: String, default: 'light' },
    tooltipClass: { type: String, default: null }
  },

  data () {
    return {}
  }
}
</script>
