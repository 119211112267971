<template>
  <Form ref="observe">
    <div class="m-1 p-1 font-weight-bold bg-white">
      <div class="row mb-2">
        <div class="col">
          {{fromDate}} - {{toDate}} {{task.name}}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <ShiftAttachComponent
            @attachShift="handleEmployeeChange"
            v-if="taskItem.schedule_id"
            :date="taskItem.scheduled_time"
            :taskId="+taskItem.id"
            :leadTime="+taskItem.lead_time"
            :scheduledTime="taskItem.scheduled_time"
            :clientId="taskItem.client_id"
            :tz="tz"
            :userTask="false"
            :hide-title="true"
            :scheduleId="taskItem.schedule_id"
            :showSubtaskCreate="false"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <ui-number-input
            :label="$t('Lead time (in hrs)')"
            v-model="taskItem.lead_time"
            id="lead_time"
            name="lead_time"
            :precision="2"
            :step="0.1"
            :min="0"
            @change="handleChange"
            :validation="{decimal: true, required: true}"
          />
        </div>
        <div class="col-6">
          <ui-datetime-picker
            :label="$t('Scheduled time')"
            name="scheduled_time"
            :placeholder="$t('Pick a date time')"
            v-model="taskItem.scheduled_time"
            @change="handleChange"
            :clearable="true"
            :picker-options="{firstDayOfWeek: +$store.getters.company.salary.week_start + 1}"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ui-tags-select
            name="marks"
            :label="$t('TASK_MARK')"
            v-model="taskItem.marks"
            class="w-100"
            :options="taskMarksList"
            :validation="{required: false}"
            :key-name="'id'"
            :value-name="'id'"
            :label-name="'name'"
            :placeholder="$t('Select')"
            :filterable="true"
            :allow-create="true"
            @change="handleChange"
          >
            <template v-slot:default="slotProps">
              <div  class="user-mark mr-3" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
              {{ slotProps.option.name }}
            </template>
          </ui-tags-select>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'
import ShiftAttachComponent from '@/components/Tasks/Task/Components/ShiftAttachComponent.vue'
export default {
  name: 'SubtaskItemComponent',
  components: { ShiftAttachComponent },
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      taskItem: {
        task_id: null,
        shift_id: null,
        scheduled_time: moment().format(this.localeDateTimeFormat),
        lead_time: 0,
        color: null,
        name: '',
        time_from: moment().format(this.localeDateTimeFormat),
        time_to: moment().format(this.localeDateTimeFormat),
        calculatedLeadTime: moment().format(this.localeDateTimeFormat),
        tasksCount: 0,
        selected: false,
        marks: []
      },
      shifts: []
    }
  },
  props: { task: Object },
  created () {
    this.handleSetup()
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    },
    fromDate () {
      return this.fromZoneToZone(this.taskItem.time_from, 'UTC', this.tz).format(this.localeDateTimeFormat)
    },
    toDate () {
      return this.fromZoneToZone(this.taskItem.time_to, 'UTC', this.tz).format(this.localeDateTimeFormat)
    },
    taskMarksList () {
      const list = []
      if (this.$store.getters.taskMarks.length > 0) {
        this.$store.getters.taskMarks.forEach(item => {
          list.push({
            name: item.name,
            id: item.id,
            color: item.color
          })
        })
      }
      return list
    }
  },
  methods: {
    handleEmployeeChange (id) {
      this.taskItem.shift_id = id
      this.handleChange()
    },
    handleChange () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          this.$emit('itemUpdated', this.taskItem)
        }
      })
    },
    handleSetup () {
      this.handleShifts()
      this.taskItem = JSON.parse(JSON.stringify(this.task))
      this.taskItem.scheduled_time = this.fromZoneToZone(this.taskItem.scheduled_time, 'UTC', this.tz).toString()
    },
    handleShifts () {
      this.shifts = []
      this.selectedShift = null
      this.moment = moment
      let date = null
      if (this.task.scheduled_time) {
        date = this.fromZoneToZone(this.task.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)
      }
      const payload = [
        this.task.company_id,
        this.task.schedule_id,
        date,
        this.task.client_id,
        this.task.lead_time
      ]
      this.$store.dispatch('getShiftsToAttach', payload).then(response => {
        const shifts = {}
        response.forEach(shift => {
          shifts[shift.id] = shift
          let leadTime = 0
          shifts[shift.id].tasksCount = 0
          shifts[shift.id].tasks.forEach(task => {
            leadTime += +task.lead_time * 60
            shifts[shift.id].tasksCount++
          })
          shifts[shift.id].calculatedLeadTime = leadTime
        })

        this.shifts = Object.values(shifts)
        let shiftsWithClients = this.shifts.filter(item => item.distance >= 0)
        let shiftsWithoutClients = this.shifts.filter(item => item.distance < 0 || item.distance === undefined)
        shiftsWithClients = shiftsWithClients.sort((a, b) => {
          if (a.distance > b.distance) {
            return 1
          }
          if (a.distance < b.distance) {
            return -1
          }
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }

          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })

        shiftsWithoutClients = shiftsWithoutClients.sort((a, b) => {
          if (moment(a.time_from).isAfter(moment(b.time_from))) {
            return 1
          }
          if (moment(a.time_from).isBefore(moment(b.time_from))) {
            return -1
          }
          if (a.employee.full_name > b.employee.full_name) {
            return 1
          }
          if (a.employee.full_name < b.employee.full_name) {
            return -1
          }
          return 0
        })
        this.shifts = shiftsWithClients.concat(shiftsWithoutClients)
      })
    }
  }
}
</script>

<style scoped>

</style>
