<template>
  <div class="row" data-cy="schedule-pattern-type">
    <div class="col-12">
      <ui-select
        :label="$t('Pattern type')"
        :hint="$t('PATTERN_TYPE_HINT')"
        name="schedule_pattern"
        id="schedule_pattern"
        v-model="patternTypeSelected"
        :options="patternTypeSettings"
        :label-name="'title'"
        :clearable="false"
        :option-hint="true"
      />
      <div >
        {{ $t(patternTypeSettings[patternTypeSelected].description) }}
      </div>
    </div>
    <div class="col-lg-12 d-inline-flex my-2">
      <button
        id="demo_master_prev_step"
        class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
        @click="prev">
        {{ $t('Previous')}}
      </button>
      <button
        id="demo_master_next_step"
        data-cy="next-step"
        class="btn btn-primary rounded-pill shadow-sm"
        @click="next">
        {{ $t('Next step')}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchedulePattern',
  data () {
    return {
      patternTypeSelected: '2/2',
      patternTypeSettings: {
        '1/1': {
          id: '1/1',
          title: '1/1',
          description: 'Employees will work on a 1 work days / 1 days off schedule.'
        },
        '1/2': {
          id: '1/2',
          title: '1/2',
          description: 'Employees will work on a 1 work days / 2 days off schedule.'
        },
        '1/3': {
          id: '1/3',
          title: '1/3',
          description: 'Employees will work on a 1 work days / 3 days off schedule.'
        },
        '2/2': {
          id: '2/2',
          title: '2/2',
          description: 'Employees will work on a 2 work days / 2 days off schedule.'
        },
        '2/2/alternation': {
          id: '2/2/alternation',
          title: '2/2/alternation',
          description: '2_2_alternation_description'
        },
        '2/2/3': {
          id: '2/2/3',
          title: '2/2/3',
          description: 'Employees will work on a 2 work days / 2 work days / 3 days off schedule.'
        },
        '3/3': {
          id: '3/3',
          title: '3/3',
          description: '3_3_description'
        },
        '4/2': {
          id: '4/2',
          title: '4/2',
          description: '4_2_description'
        },
        '5/2': {
          id: '5/2',
          title: '5/2',
          description: 'Employees will work on a 5 work days / 2 days off schedule.'
        },
        '5/2/6/1': {
          id: '5/2/6/1',
          title: '5/2/6/1',
          description: 'The employee works 5 days, then 2 days off, after 6 days of work and one day off.'
        },
        'day/night/48': {
          id: 'day/night/48',
          title: 'day/night/48',
          description: 'day_night_48_description'
        }
      }
    }
  },
  created () {
    this.patternTypeSelected = this.patternType || '2/2'
  },
  props: { patternType: String },
  methods: {
    next () {
      this.$emit('next', {
        step: 'patternType',
        data: this.patternTypeSelected
      })
    },
    prev () {
      this.$emit('prev', {
        step: 'patternType',
        data: this.patternTypeSelected
      })
    }
  }
}
</script>

<style scoped>

</style>
