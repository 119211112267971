<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded">
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("TASKS_MAIN_TITLE") }}
          <ui-hint :content="$t('TASKS_MAIN_TITLE_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body table-responsive">
          <div class="row">
            <div class="col"></div>
            <DateNavigationBar
                :hideDateButton="false"
                :hideAddTaskButton="true"
                @changeDate="changePeriod"
            />
          </div>
          <tab-navigation-component :tabs="tabs"></tab-navigation-component>
          <tasks-table-component
            :isBank="true"
            @reload="init"
            :statusesData="statusesData"
            :list="list" />
          <el-pagination
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            v-model:current-page="pagination.currentPage"
            :page-size="25"
            :total="pagination.total"
            >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksTableComponent from '@/components/Tasks/Task/Components/TasksTableComponent'
import mixinDummy from '@/mixins/mixinDummy'
import DateNavigationBar from '@/components/Tasks/CommonComponents/DateNavigationBar.vue'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'Tasks',
  components: { DateNavigationBar, TasksTableComponent, TabNavigationComponent: TabNavigationComponent, DummyPaymentRequired },
  mixins: [momentMixin, mixinDummy],
  data () {
    return {
      list: [],
      search: '',
      tabs: [],
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      pagination: {
        currentPage: this.$route.query.page ? this.$route.query.page : 1,
        total: 0
      },
      statusesData: [],
      period: [moment().format(this.backendDateFormat), moment().format(this.backendDateFormat)]
    }
  },
  created () {
    if (this.companyId) {
      this.getStatuses()
      this.init()
      this.setTubs()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.getStatuses()
      this.init()
      this.setTubs()
    }
  },
  methods: {
    changePeriod (val) {
      this.period = val
      this.init()
    },
    getStatuses () {
      this.$store.dispatch('getTasksStatuses', this.companyId).then(result => {
        const statusesData = []
        result.forEach(item => {
          statusesData.push({
            text: item.title,
            value: item.title
          })
        })
        this.statusesData = statusesData
      })
    },
    setTubs () {
      this.tabs = [
        {
          link: `/c/${this.companyId}/user-tasks`,
          title: 'My Tasks',
          isActive: false
        },
        {
          link: '#',
          title: 'Tasks Bank',
          isActive: true
        }
      ]
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    },
    handleCurrentChange (val) {
      this.$router.replace({ query: { page: val } }).catch(() => {})
      this.init()
    },
    init () {
      this.$store.dispatch('getTasksSettings', this.$store.getters.companyId).then((response) => {
        if (!response.show_tasks) {
          this.accessErrorRedirect('You do not have access to this page.')
        }
      })
      const data = {
        search: this.searchQuery,
        page: this.pagination.currentPage,
        date_from: this.toUTC(moment(this.period[0], this.backendDateFormat)).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment(this.period[1], this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasksBank', [this.companyId, payload]).then((response) => {
        this.list = response.data
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        if (response.current_page > response.last_page) {
          this.$router.replace({ query: { page: response.last_page } }).catch(() => {})
        }
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    handleEdit (index, row) {
      this.$router.push(`/c/${this.companyId}/tasks/${row.id}`)
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    }
  }
}
</script>

<style scoped>

</style>
