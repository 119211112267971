<template>
  <div class="pt-3 ql-editor" v-html="app.full_description">
  </div>
</template>

<script>

export default {
  name: 'DeveloperAppMainInfo',
  props: {
    app: Object
  }
}
</script>
