<template>
  <div>
    <el-tooltip :show-after="openDelay" :hide-after="hideDelay" effect="light" placement="top" class="w-100">
      <template #content>
        <div>{{ start }} - {{ end }} {{ name }}</div>
      </template>
      <template v-if="displayMode === 'extended'">
        <div class="fc-content mx-1 font-weight-bold"
             :data-toggle="showModal ? 'modal' : ''"
             :data-target="showModal ? '#HandleBreak' : ''"
             style="cursor: pointer">
        <span class="fc-time">
          <i class="icon-coffee font-weight-bold"></i> <span>{{ start }}</span> - <span>{{ end }}</span>
        </span>
        </div>
      </template>
      <template v-else>
        <div class="fc-content"
             :data-toggle="showModal ? 'modal' : ''"
             :data-target="showModal ? '#HandleBreak' : ''">
          <span class="fc-time ml-1 text-nowrap text-truncate"></span>
        </div>
      </template>
    </el-tooltip>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'BreakComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 1000,
      hideDelay: 4000,
      name: null,
      start: null,
      end: null,
      displayMode: 'extended'
    }
  },
  props: {
    event: Object
  },
  mounted () {
    this.displayMode = localStorage.getItem('dayDisplayType')
    this.name = this.event.title
    this.start = moment(this.event.extendedProps.realStart).format(this.localeTimeFormat)
    this.end = moment(this.event.extendedProps.realEnd).format(this.localeTimeFormat)
  },
  computed: {
    showModal () {
      if (this.isEmployeeHasPermission('create-breaks')) {
        return true
      }
      return this.$store.getters.currentCompanyUserId === this.event.extendedProps.employeeId
    }
  }
}
</script>

<style scoped>

</style>
