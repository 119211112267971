<template>
  <Form ref="observer" as="div" class="row" v-slot="{ meta }">
    <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
      {{ $t("Schedule resizing") }}
    </div>
    <div class="col-lg-6 my-1">
      <ui-select
        name="actionType"
        :label="$t('Resize type')"
        :hint="$t('RESIZE_TYPE_HINT')"
        v-model="actionType"
        :clearable="false"
        :filterable="false"
        class="w-100"
        :options="actionTypeList"
        :key-name="'value'"
        :label-name="'label'"
        :value-name="'value'"
      />
    </div>
    <div class="col-lg-6"></div>
    <div v-if="actionType === 'extend'" class="col-lg-12 mt-1">
      <ui-radio
        name="with_shifts_by_templates"
        :label="$t('EXTEND_WITH_SHIFTS_BY_TEMPLATES')"
        :hint="$t('EXTEND_WITH_SHIFTS_BY_TEMPLATES_HINT')"
        v-model="extendType"
        :set-value="'with_shifts_by_templates'"
      />
      <ui-radio
        name="with_shifts_by_demand"
        :label="$t('EXTEND_WITH_SHIFTS_BY_DEMAND')"
        :hint="$t('EXTEND_WITH_SHIFTS_BY_DEMAND_HINT')"
        v-model="extendType"
        :set-value="'with_shifts_by_demand'"
      />
      <ui-radio
        name="without_shifts"
        :label="$t('EXTEND_WITHOUT_SHIFTS')"
        :hint="$t('EXTEND_WITHOUT_SHIFTS_HINT')"
        v-model="extendType"
        :set-value="'without_shifts'"
      />
      <ui-radio
        name="by_previous_period"
        v-if="schedule.pattern_type === '2/2' || schedule.pattern_type === '2/2/alternation'"
        :label="$t('TAKE_PREVIOUS_PERIOD')"
        :hint="$t('TAKE_PREVIOUS_PERIOD_HINT')"
        v-model="extendType"
        :set-value="'by_previous_period'"
      />
    </div>
    <div class="col-lg-6">
      <ui-date-picker
        v-if="this.actionType === 'extend'"
        :label="label"
        name="date_e"
        v-model="dateValue"
        :validation="{required: true}"
        :clearable="false"
        :disabledDate="this.disabledDateExtend"
      />
      <ui-date-picker
        v-else
        :label="label"
        name="date_s"
        v-model="dateValue"
        :validation="{required: true}"
        :clearable="false"
        :disabledDate="this.disabledDateShorten"
      />
      <div class="mt-2">{{ info }}</div>
    </div>
    <div v-if="actionType === 'extend'" class="col-lg-12 my-1">
      <label class="control-label font-weight-bold">{{ $t('OUT_SCHEDULE_SHIFT_ACTION') }}</label>
      <ui-hint :content="$t('OUT_SCHEDULE_SHIFT_ACTION_HINT')"/>
      <ui-radio
        name="ignore"
        :label="$t('OUT_SCHEDULE_SHIFT_IGNORE')"
        v-model="outScheduleShiftsAction"
        :set-value="'ignore'"
      />
      <ui-radio
        name="delete"
        :label="$t('OUT_SCHEDULE_SHIFT_DELETE')"
        v-model="outScheduleShiftsAction"
        :set-value="'delete'"
      />
    </div>
    <div class="col-12 py-2">
      <button
        class="btn btn-success rounded-pill shadow-sm"
        :disabled="!meta.valid"
        :class="{'disabled': !meta.valid}"
        @click="save">
        {{ $t('SAVE_WEB') }}
      </button>
    </div>
  </Form>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'ResizeSchedule',
  mixins: [
    errorMixin,
    momentMixin
  ],

  data () {
    return {
      label: this.$t('Extend schedule to'),
      info: this.$t('Select a date to which you want to extend the schedule.'),
      dateValue: '',
      disabled: false,
      actionType: 'extend',
      actionTypeData: {
        extend: this.$t('Extend schedule'),
        shorten: this.$t('Shorten schedule')
      },
      actionTypeList: [
        {
          value: 'extend',
          label: this.$t('Extend schedule')
        },
        {
          value: 'shorten',
          label: this.$t('Shorten schedule')
        }
      ],
      withShifts: true,
      extendType: 'without_shifts',
      outScheduleShiftsAction: 'ignore'
    }
  },

  props: {
    scheduleId: Number
  },
  computed: {
    schedule () {
      // console.log(56, this.$store.getters.scheduleById(this.scheduleId))
      return this.$store.getters.scheduleById(this.scheduleId)
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    disabledDateExtend () {
      // console.log(this.schedule)
      const to = this.schedule.date_to
      if (this.schedule) {
        const dataFrom = new Date(to)
        const dataTo = moment(dataFrom).add(3, 'M').toDate()
        return function (date) {
          return date.getTime() < dataFrom.getTime() || date.getTime() > dataTo.getTime()
        }
      }
      return null
    },
    disabledDateShorten () {
      if (this.schedule) {
        const from = this.schedule.date_from
        const to = this.schedule.date_to
        const dataFrom = this.toTimeZone(from)
        const dataTo = this.toTimeZone(to)
        return function (date) {
          return moment(date).isBefore(dataFrom) || moment(date).isSameOrAfter(dataTo)
        }
      }
      return null
    }
  },
  watch: {
    actionType () {
      this.typeChange()
    }
  },
  methods: {
    disabledDate () {
      if (this.actionType === 'extend') {
        return this.disabledDateExtend
      } else {
        return this.disabledDateShorten
      }
    },

    typeChange () {
      if (this.actionType === 'extend') {
        this.label = this.$t('Extend schedule to')
        this.info = this.$t('Select a date to which you want to extend the schedule.')
      } else {
        this.label = this.$t('Shorten schedule to')
        this.info = this.$t('Select a date to which you want to shorten the schedule.')
      }
    },

    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const data = {
            date_to: moment(this.dateValue).add(1, 'day').format('YYYY-MM-DD') + ' 00:00',
            preview: false,
            extend_type: this.extendType,
            out_schedule_shifts_action: this.outScheduleShiftsAction
            // without_shifts: !this.withShifts
          }
          this.$store.dispatch('resizeSchedule', [this.$route.params.scheduleId, data]).then(response => {
            this.dateValue = ''
            // this.$eventBus.$emit('getSchedule')
            this.$emit('getSchedule')
            this.toastSuccess(this.$i18n?.t('Schedule was successfully changed'))
            this.$refs.observer.resetForm()
            // this.errors.clear()
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
