<template>
  <Field :name="name" v-model="innerValue" v-slot="{ errors }" :rules="validation" as="div">
    <ui-input-label v-if="label" :label="label" :hint="hint"/>
    <div class="border-vue-swatches">
      <v-swatches
        v-model="innerValue"
        :swatches="colors || colorsChoices"
        row-length="6"
        shapes="circles"
        show-border
        popover-y="bottom"
        max-height="240"
        swatch-size="28"
        :wrapper-style="{'max-height': '240px'}"
        :trigger-style="{ width: '20px', height: '20px' }">
      </v-swatches>
    </div>
    <span v-show="errors.length" class="invalid-feedback">{{ errors[0] }}</span>
  </Field>
</template>

<script>
import { VSwatches } from 'vue3-swatches'
import 'vue3-swatches/dist/style.css'
import colors from '@/mixins/mixinColors'

export default {
  name: 'color-picker',
  components: { VSwatches },
  mixins: [colors],
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: [String, Object],
    modelValue: String,
    colors: Array,
    label: String,
    hint: String
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.help-text-vue-swatches {
  padding-left: 10px;
  width: 80%;
  display: inline-block;
  font-size: 12px;
}
</style>
