import { HTTP } from '@/api/http_utils'

const state = {
  todos: [],
  todosIndexed: {}
}

const getters = {
  todosIndexed: state => state.todosIndexed,
  todos: state => Object.values(state.todosIndexed).sort((a, b) => {
    if (a.id > b.id) return -1
    if (a.id < b.id) return 1
    return 0
  })
}

const TODOS_INDEXED = 'TODOS_INDEXED'
const CREATE_TODOS_INDEXED = 'CREATE_TODOS_INDEXED'
const UPDATE_TODOS_INDEXED = 'UPDATE_TODOS_INDEXED'
const DELETE_TODOS_INDEXED = 'DELETE_TODOS_INDEXED'

const mutations = {
  [TODOS_INDEXED] (state, payload) {
    const todos = {}
    payload.forEach(todo => {
      todos[todo.id] = todo
    })
    state.todosIndexed = todos
  },
  [CREATE_TODOS_INDEXED] (state, payload) {
    state.todosIndexed[payload.id] = payload
  },
  [UPDATE_TODOS_INDEXED] (state, payload) {
    state.todosIndexed[payload.id] = payload
  },
  [DELETE_TODOS_INDEXED] (state, id) {
    if (state.todosIndexed[id]) {
      delete state.todosIndexed[id]
    }
  }
}

const actions = {
  getTodosDashboard ({ commit }, [companyId, query]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/todo/dashboard?` + query).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTodos ({ commit }, [companyId, query]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/todo?` + query).then(response => {
        commit(TODOS_INDEXED, response.data ? response.data : [])
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getTodo ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/todo/${id}`).then(response => {
        commit(UPDATE_TODOS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createTodo ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/todo`, payload).then(response => {
        commit(CREATE_TODOS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateTodo ({ commit }, [companyId, todoId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/todo/${todoId}`, payload).then(response => {
        commit(UPDATE_TODOS_INDEXED, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteTodo ({ commit }, [companyId, todoId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/todo/${todoId}`).then(response => {
        commit(DELETE_TODOS_INDEXED, todoId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
