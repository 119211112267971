<template>
  <div class="row">
    <div class="col-lg-12 text-left mb-4">
      <span class="text-primary text-uppercase font-weight-bold">{{ $t('Schedule preview') }}</span>
    </div>
    <div v-if="!showWarning" class="col-lg-12">
      <full-calendar data-cy="calendar-preview" ref="dashboardCalendar" :options="calendarOptions">
        <template v-slot:eventContent='arg'>
          <b>{{ formatTime(arg.event.start) }} - {{ formatTime(arg.event.end) }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </full-calendar>
    </div>
    <div v-if="showWarning" class="col-lg-12">
      <div class="text-center my-5 py-5">{{$t('NO_SHIFTS_TO_PREVIEW_WARNING')}}</div>
    </div>
    <div class="col-lg-12 d-inline-flex pt-2">
      <button
        id="demo_master_prev_step"
        class="btn btn-outline-primary rounded-pill shadow-sm mr-2"
        @click="prev">
        {{ $t('Previous')}}
      </button>
      <button
        id="demo_master_next_step"
        data-cy="next-step"
        :disabled="disabledSave"
        v-if="Object.keys(previewShifts).length > 0"
        class="btn btn-success rounded-pill shadow-sm"
        @click="save">
        {{ $t('SAVE_WEB') }}
      </button>
    </div>
    <el-dialog
      key="showShiftConflictsModal"
      :title="$t('CONFLICT_SHIFTS_TITLE')"
      :close-on-click-modal="false"
      :show-close="false"
      v-model="showShiftConflictsModal"
      :before-close="handleClose"
      data-cy="modal-shifts-conflicts"
      width="40%">
      <div style="max-height: 300px; overflow:auto; background-color: white">
        <div class="border border-light p-2 mb-2" v-for="(shift, index) in conflicts" :key="index">
          {{ convertTime(shift.time_from) }} / {{ convertTime(shift.time_to) }} {{ shift.username }}
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
        <el-button @click="declineShifts">{{$t('Change parameters')}}</el-button>
        <el-button type="primary" data-cy="modal-shifts-conflicts-confirm" @click="confirmShifts">{{$t('Confirm')}}</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import ruLocale from '@fullcalendar/core/locales/ru'
import heLocale from '@fullcalendar/core/locales/he'
import deLocale from '@fullcalendar/core/locales/de'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import momentMixin from '@/mixins/mixinMoment'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import moment from 'moment-timezone'

export default {
  name: 'PreviewSchedule',
  components: { FullCalendar },
  mixins: [momentMixin, mixinFCWeek],
  data () {
    return {
      events: {},
      defaultViewName: 'dayGridMonth',
      licenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
      calendarOptions: {
        schedulerLicenseKey: this.licenseKey,
        plugins: [dayGridPlugin, bootstrapPlugin],
        aspectRatio: 1.5,
        themeSystem: 'bootstrap',
        selectable: false,
        height: 'auto',
        initialDate: this.startDate,
        headerToolbar: {
          start: 'title',
          center: 'prev,next',
          end: 'today'
        },
        buttonText: {
          prev: '<',
          next: '>'
        },
        defaultView: this.defaultViewName,
        locales: [ruLocale, heLocale, deLocale, plLocale, esLocale, elLocale],
        eventDisplay: 'block',
        views: {
          dayGridMonth: {
            editable: false,
            displayEventTime: true,
            displayEventEnd: true,
            fixedWeekCount: false
          }
        },
        scrollTime: '00:00:00',
        nextDayThreshold: '24:00:00',
        eventTimeFormat: {
          hour: 'numeric',
          hour12: this.is12hourFormat,
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        },
        loading: (isLoading) => {
          this.$eventBus.emit('loading', isLoading)
        },
        // eventClassNames: ['rounded', 'p-1'],
        eventOverlap: false,
        events: (info, successCallback) => {
          this.createEvents().then((events) => {
            successCallback(events)
          })
        },
        eventContent: (addInfo) => {
          // console.log(addInfo)
          if (!addInfo.event.extendedProps.isFilledWorkplaces) {
            return {
              html: '<span class="align-baseline">' + this.formatTime(addInfo.event.start) + ' - ' + this.formatTime(addInfo.event.end) + '</span>' +
                '<i class="icon-alert-circle text-danger mx-1 bg-warning" style="font-size: 1.3em;border-radius: 8px"></i>' +
                '<span>' + addInfo.event.title + '</span>'
            }
          } else {
            return {
              html: '<span class="align-baseline">' + this.formatTime(addInfo.event.start) + ' - ' + this.formatTime(addInfo.event.end) + '</span>' +
                '<span class="mx-1">' + addInfo.event.title + '</span>'
            }
          }
        }
      },
      disabledSave: true,
      conflicts: [],
      showShiftConflictsModal: false,
      calendarApi: null,
      showWarning: false
    }
  },
  props: {
    previewShifts: Object,
    conflictShifts: Array,
    startDate: [String, Date],
    project: Number
  },
  mounted () {
    // console.log(45, this.previewShifts)
    if (Object.keys(this.previewShifts).length < 1) {
      this.showWarning = true
    }
    if (this.$refs.dashboardCalendar) {
      this.calendarApi = this.$refs.dashboardCalendar.getApi()
      this.calendarApi.setOption('locale', this.lang)
      this.calendarApi.setOption('direction', this.direction)
      this.calendarApi.setOption('firstDay', this.firstDay)
    }
  },
  created () {
    const isExist = this.$route.params.scheduleId || null
    if (this.mutatedConflictShifts && !isExist) {
      this.displayConflicts()
    }
  },
  computed: {
    mutatedConflictShifts () {
      return this.conflictShifts
    },
    firstDay () {
      return this.fcWeek[this.apiWeek[this.$store.getters.company.salary.week_start]]
    },
    lang () {
      return this.$store.getters.locale ? this.$store.getters.locale : 'en'
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    }
  },
  watch: {
    // '$refs.dashboardCalendar': () => {
    //   this.calendarApi = this.$refs.dashboardCalendar.getApi()
    // },
    // mutatedConflictShifts: {
    //   handler: 'displayConflicts',
    //   deep: true
    // },
    mutatedConflictShifts (value) {
      if (value) {
        this.displayConflicts()
      }
    },
    previewShifts: {
      handler: function (val) {
        if (this.calendarApi) {
          this.calendarApi.refetchEvents()
        }
        if (Object.keys(this.previewShifts).length < 1) {
          this.showWarning = true
        } else {
          this.showWarning = false
        }
      },
      deep: true
    },
    lang (value) {
      this.calendarApi.setOption('locale', value)
    }
  },
  methods: {
    formatTime (val) {
      return moment(val).format(this.localeTimeFormat)
    },
    displayConflicts () {
      if (this.mutatedConflictShifts.length > 0) {
        // console.log('this.mutatedConflictShifts', this.mutatedConflictShifts)
        const users = this.$store.getters.employeesProjectIndexed[this.project] ? this.$store.getters.employeesProjectIndexed[this.project] : []
        const projectUsers = {}
        users.users.forEach(user => {
          projectUsers[user.id] = user
        })

        const conflictShifts = []
        this.mutatedConflictShifts.forEach(shift => {
          shift.username = projectUsers.hasOwnProperty(shift.employee_id) ? projectUsers[shift.employee_id].username : 0
          conflictShifts.push(shift)
        })

        this.conflicts = conflictShifts
        this.showShiftConflictsModal = true
      } else {
        this.disabledSave = false
      }
    },
    declineShifts () {
      this.showShiftConflictsModal = false
      this.$emit('prev', {
        step: 'preview',
        data: {}
      })
    },
    confirmShifts () {
      this.showShiftConflictsModal = false
      this.disabledSave = false
    },
    handleClose (done) {
      this.$confirm('Are you sure to close this dialog?')
        .then(_ => {
          console.log(done)
          // done()
          // this.prev()
        })
        .catch(_ => {})
    },
    save () {
      this.$emit('save', {
        step: 'preview',
        data: {
          preview: false
        }
      })
    },
    prev () {
      this.$emit('prev', {
        step: 'preview',
        data: {}
      })
    },
    createEvents () {
      return new Promise((resolve, reject) => {
        this.events = []
        Object.keys(this.previewShifts).forEach(key => {
          const event = this.setEvent(this.previewShifts[key], key)
          this.events.push(event)
        })
        // console.log(2, this.events)
        resolve(this.events)
      })
    },
    convertTime (shiftTime) {
      return this.fromZoneToZone(shiftTime, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateTimeFormat)
    },
    setEvent (shift, index) {
      const title = this.direction === 'ltr'
        ? shift.users + '/' + shift.workplaces + ' ' + shift.name
        : shift.name + ' ' + shift.workplaces + '/' + shift.users
      // конвертация времени со смещением по таймзоне пользователя (из профиля) относительно UTC (в базе время хранится сконвертированным в UTC)
      const startTime = this.fromZoneToZone(shift.date_from, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(shift.date_to, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const event = {
        id: index,
        // title: shift['name'] + ' ' + this.$i18n?.t('Users') + ': ' + shift['users'] + ' / ' + this.$i18n?.t('Workplaces') + ': ' + shift['workplaces'],
        title: title,
        displayEventTime: true,
        displayEventEnd: true,
        start: startTime,
        end: endTime,
        color: shift.color,
        editable: false,
        isFilledWorkplaces: +shift.users >= +shift.workplaces
      }
      // console.log(shift, event)
      return event
    }
  }
}
</script>

<style scoped>
</style>
