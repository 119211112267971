<template>
  <Form as="div" ref="observer">
    <div class="row form-group">
      <div class="col-lg-3">
        <ui-text-input
            id="name-create"
            name="name-create"
            :label="$t(titleName)"
            v-model="name"
            :validation="{required: true, max: 128}"
        />
      </div>
      <div class="col-lg-2 my-auto">
        <span class="form-inline form-group mt-4">
          <label class="col-form-label mr-2">{{ $t(colorPickerName) }}</label>
          <div class="border-vue-swatches">
            <color-picker
              name="color"
              v-model="color"
              :colors="colorsChoicesMarks"
            />
          </div>
        </span>
      </div>
      <span class="col-lg-3 mt-4">
            <button class="btn btn-success btn-circle shadow-sm" type="button" @click="save">
              {{ $t('SAVE_WEB') }}
            </button>
          </span>
    </div>
  </Form>
</template>

<script>
import colors from '@/mixins/mixinColorsMarks'
import errorMixin from '@/mixins/mixinApiErrors'
import ColorPicker from '@/ui/color-picker.vue'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'MarkCreateForm',
  mixins: [colors, errorMixin, ToastMixin],
  components: { ColorPicker },
  data () {
    return {
      name: '',
      color: '#bbbbbb'
    }
  },
  props: { titleName: String, colorPickerName: String },
  methods: {
    save () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          this.$store.dispatch('createTaskMarks', [this.companyId, {
            name: this.name,
            color: this.color
          }]).then(() => {
            this.name = ''
            this.color = '#bbbbbb'
            this.toastSuccess(this.$i18n?.t('TASK_MARK_CREATED'))
          }).catch(error => {
            this.toastError(this.$i18n?.t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .pointer {
    cursor: pointer;
  }
</style>
