<template>
  <modal-component :id="'CreateApplicationModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('New app')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observe">
          <div class="form-row">
            <div class="col-lg-12 form-group">
              <ui-text-input
                :label="$t('App name')"
                :name="'name'"
                v-model="name"
                :validation="{required: true, min: 1, max: 128}"
              />
            </div>
            <div class="col-lg-12 form-group">
              <ui-select
                :label="$t('App category')"
                name="category"
                v-model="category_id"
                :clearable="false"
                class="w-100"
                :options="appCategories"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :validation="{required: true}"
              />
            </div>
            <div class="col-lg-12 form-group">
              <ui-textarea-input
                name="description"
                :label="$t('Description')"
                v-model="description"
                :validation="{required: true}"
                :rows="2"
              />
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div class="text-center">
        <button type="button" class="btn btn-outline-secondary rounded-pill shadow-sm mr-2" data-dismiss="modal" aria-label="Close" @click="closeModal">
          <span aria-hidden="true">{{ $t('Cancel')}}</span>
        </button>
        <button type="button" class="btn btn-success rounded-pill shadow-sm ml-2" @click="createApp">
          <span aria-hidden="true">{{ $t('Create app')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '@/mixins/mixinApiErrors'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'CreateApplicationModal',
  mixins: [ToastMixin, errorMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      name: '',
      description: '',
      category_id: null
    }
  },
  created () {
    this.$store.dispatch('getAppCategories')
  },
  computed: {
    appCategories () {
      return this.$store.getters.appCategories
    }
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$eventBus.emit('closeCreateApplicationModal')
    },
    createApp () {
      this.$refs.observe.validate().then(isValid => {
        if (isValid.valid) {
          const payload = {
            name: this.name,
            description: this.description,
            category_id: this.category_id
          }

          this.$store.dispatch('createDeveloperApp', [this.companyId, payload]).then(response => {
            this.toastSuccess(this.$i18n?.t('App was created'))
            this.closeModal()
          }).catch((error) => {
            const errorMessage = error.response.data.message
            if (error.response.status === 400 && errorMessage) {
              this.toastError(this.$i18n?.t(errorMessage))
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
