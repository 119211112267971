<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('REQUEST_CREATE_BREAK_TITLE') }}</div>
    </div>
    <div class="mt-2">
      <div class="d-flex align-items-center">
        {{ $t('User') }}
        <span class="visible-md-inline visible-lg-inline ml-1">
            <avatar
              class="rounded-circle d-inline-block font-weight-normal"
              :width="35"
              :borderRadius="50"
              :placeholder="false"
              :userId="request.messages[0].employee_id"
              :mini="true"
              :cache="true"
              :userName="request.messages[0].employee_name">
            </avatar>
          </span>
        <span class="ml-1 font-weight-bold">{{request.messages[0].employee_name}} </span>
        <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
        <span class="mx-1">{{ $t('KEY_USER_ASKS_TO_CREATE_BREAK') }}</span>
      </div>
      <ShiftTitle :request="request" />
      <div class="mt-2 font-weight-bold">{{ $t('Time')}}</div>
      <div class="mt-2">
        <span>{{ $t('Break begins at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.time_from).format(localeDateTimeFormat) }}</span>
      </div>
      <div class="mt-2">
        <span>{{ $t('Break ends at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.data.time_to).format(localeDateTimeFormat) }}</span>
      </div>
      <div v-if="request.data.comment" class="mt-2">
        <div class="mt-2 font-weight-bold">{{ $t('Message')}}</div>
        <div>{{ request.data.comment }}</div>
      </div>
    </div>
    <div class="row mt-2 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
import ShiftTitle from '@/components/Requests/ViewComponents/ShiftTitle'
export default {
  name: 'CreateBreak',
  props: ['request', 'employee'],
  components: { ShiftTitle, Avatar },
  mixins: [momentMixin, requestMixin]
}
</script>

<style scoped>

</style>
