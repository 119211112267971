<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('Move to freeboard') }}</div>
    </div>
    <div class="mt-3" v-if="request.messages">
      <div>
        {{ $t('User') }}
        <span class="visible-md-inline visible-lg-inline ml-3">
          <avatar
            class="rounded-circle d-inline-block font-weight-normal"
            :width="35"
            :borderRadius="50"
            :placeholder="false"
            :userId="request.messages[0].employee_id"
            :mini="true"
            :cache="true"
            :userName="request.messages[0].employee_name">
          </avatar>
        </span>
        <span class="ml-1 font-weight-bold">{{request.messages[0].employee_name}} </span>
        <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
        <span v-if="request.split === true">{{ $t('asks to put a part of the shift on the Freeboard') }}</span>
        <span v-else>{{ $t('asks to put a shift on the Freeboard') }}</span>
      </div>
      <ShiftTitle :request="request" />
      <div class="mt-3">
        <span>{{ $t('Shift begins at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.shift.time_from).format(localeDateTimeFormat) }}</span>
      </div>
      <div class="mt-3">
        <span>{{ $t('Shift ends at')}}</span>
        <span class="font-weight-bold ml-1">{{ toTimeZone(request.shift.time_to).format(localeDateTimeFormat) }}</span>
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
    <ShiftLocation :location="request.shift.location" />
    <ShiftBreaks :breaks="request.shift.breaks" />
    <ShiftMarks :marks="request.marks" />
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
import ShiftBreaks from '@/components/Requests/ViewComponents/ShiftBreaks'
import ShiftLocation from '@/components/Requests/ViewComponents/ShiftLocation'
import ShiftMarks from '@/components/Requests/ViewComponents/ShiftMarks'
import ShiftTitle from '@/components/Requests/ViewComponents/ShiftTitle'
export default {
  name: 'MoveToFreeboard',
  props: ['request', 'employee'],
  components: { ShiftTitle, ShiftMarks, ShiftLocation, ShiftBreaks, Avatar },
  mixins: [momentMixin, requestMixin]
}
</script>

<style scoped>

</style>
