<template>
  <div>
    <div class="font-weight-bold mb-2 mt-2">
      {{ $t('bonus_penalti_import_description') }}
    </div>
    <img width="100%" height="100%" src="../../../../../../public/static/images/App/example_import_bonuses.png"/>
    <div class="font-weight-bold my-2">
      {{ $t('Need an example?') }} <a :href="`${publicPath}static/files/import_bonus_penalty_example.xlsx`">{{ $t('Download our template') }}</a>
    </div>
    <ui-file-upload
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      name='file'
      :upload="{ url: '#' }"
      :auto-upload="false"
      :multiple="false"
      :limit="1"
      @file="getFile"
    />
  </div>
</template>

<script>
export default {
  name: 'SelectFileAction',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      file: '',
      fileList: [],
      matchPreview: []
    }
  },
  props: {},
  created () {},
  methods: {
    getFile (data) {
      this.file = data.file
      this.$emit('setFile', data.file)
    }
  }
}
</script>
