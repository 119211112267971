<template>
  <div>
    <div class="row">
      <div class="col-lg-3">
        <h1 class="title-text">{{ $t('DEVELOPERS_HEADER') }}</h1>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-2" v-if="isEmployeeHasPermission('delete-company')">
            <div class="card card-small">
              <div class="card-body">
                <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical" :class="{'d-none': !showTabs}">
                  <a v-for="(title, key) in tabsData" :key="key"
                    :href="'#' +  key"
                    class="nav-link pl-3 pr-1 rounded"
                    :class="{active: activeTab === key}"
                    data-toggle="pill" role="tab"
                    :aria-controls="'v-pills-' + key" aria-selected="true"
                    @click="activateTab(key)">
                    {{ $t(title) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div :class="isEmployeeHasPermission('delete-company') ? 'col-lg-10' : 'col-lg-12'">
            <div class="card card-small">
              <div class="card-body">
                <div class="tab-content">
                    <overview v-if="activeTab === 'overview'" />
                    <webhooks-settings v-if="activeTab === 'webhooks'" />
                    <applications v-if="activeTab === 'applications'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import WebhooksSettings from '@/components/Developer/Components/WebhooksSettings'
import Applications from '@/components/Developer/Applications'
import Overview from '../Developer/Components/Overview.vue'

export default {
  name: 'Developer',
  components: { WebhooksSettings, Applications, Overview },
  data () {
    return {
      activeTab: 'overview',
      tabsData: {
        overview: 'DEV_OVERVIEW',
        webhooks: 'Webhooks',
        applications: 'Applications'
      },
      showTabs: true,
      buttonTitle: 'Please Select'
    }
  },
  mounted () {
    if (this.accessDenied) {
      this.accessErrorRedirect('Access denied')
    }
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'overview'
  },
  computed: {
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('get webhook')
    }
  },
  methods: {
    toggleMenu () {
      this.showTabs = !this.showTabs
    },
    activateTab (key) {
      // console.log(key)
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    }
  },
  watch: {
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('Only company owner can access this page')
      }
    }
  }
}
</script>

<style scoped>

</style>
