<template>
  <div class="row">
    <div class="col-lg-12 text-primary text-uppercase font-weight-bold">
      {{ $t("Authorization") }}
    </div>
    <div class="col-lg-6 my-3">
      <label class="control-label font-weight-bold" for="title">{{ $t("Access Token") }}
        <ui-hint :content="$t('ACCESS_TOKEN_HINT')" />
      </label>
      <div class="control-label font-weight-bold float-right" @click="copy"><i class="icon-copy btn-icon text-success mx-1"></i></div>
      <ui-textarea-input
        name="access_token"
        v-model="appState.access_token"
        :disabled="true"
        :rows="15"
      />
    </div>
    <div class="col-lg-12 my-2">
      <h5>{{ $t('OAuth') }}</h5>
      <ui-number-input
        :label="$t('Client ID')"
        :hint="$t('OAUTH_CLIENT_ID_HINT')"
        id="id"
        name="id"
        class="w-50"
        :disabled="true"
        :precision="0"
        :step="1"
        :min="0"
        v-model="appState.client.id"
      />
    </div>
    <div class="col-lg-12 my-2">
      <ui-text-input
        :label="$t('Client Secret')"
        :hint="$t('OAUTH_CLIENT_SECRET_HINT')"
        id="secret"
        name="secret"
        class="w-50"
        :disabled="true"
        v-model="appState.client.secret"
      />
    </div>
    <div class="col-lg-12 my-2">
      <h5>{{ $t('Permissions') }}</h5>
      <label class="control-label font-weight-bold" for="title">{{ $t("PERMISSIONS_EXPLANATION") }}</label>
      <div class="text-primary text-uppercase font-weight-bold col-12 my-2 pb-2">
        <button @click="selectAll" type="button" class="btn btn-link btn-sm float-right p-0 mx-1">{{$t('Select all')}}</button>
        <button @click="unselectAll" type="button" class="btn btn-link btn-sm float-right p-0 mx-1">{{$t('Unselect all')}}</button>
      </div>
      <div class="row">
        <div v-for="permission in permissions" :key="permission.id" class="col-3">
          <ui-checkbox
            :name="permission.id + permission.name"
            v-model="selectedPermissions[permission.name]"
            :label="getCheckBoxText(permission)"
          />
        </div>
      </div>
    </div>
    <div class="col-lg-12 py-2">
      <button class="btn btn-success btn-circle shadow-sm m-1" @click="save">{{ $t('SAVE_WEB') }}</button>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Authorization',
  mixins: [errorMixin, ToastMixin],
  data () {
    return {
      appId: this.$route.params.appId,
      selectedPermissions: {},
      appState: {}
    }
  },
  props: {
    app: Object
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    permissions () {
      return this.$store.getters.permissionsList
    }
  },
  created () {
    this.appState = this.app
    if (this.companyId) {
      this.init()
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getPermissions')
      const selectedPermissions = {}
      this.appState.permissions.forEach(perm => {
        selectedPermissions[perm] = true
      })
      this.selectedPermissions = selectedPermissions
    },
    save () {
      const payload = {
        name: this.appState.name,
        description: this.appState.description,
        category_id: this.appState.category_id,
        is_active: this.appState.is_active,
        permissions: Object.keys(Object.fromEntries(Object.entries(this.selectedPermissions).filter(([key, value]) => value === true)))
      }

      this.$store.dispatch('updateDeveloperApp', [this.companyId, this.appId, payload]).then((response) => {
        this.toastSuccess(this.$i18n?.t('App was updated'))
      }).catch((error) => {
        const errorMessage = error.response.data.message
        if (error.response.status === 400 && errorMessage) {
          this.toastError(this.$i18n?.t(errorMessage))
        }
      })
    },
    async copy () {
      await navigator.clipboard.writeText(this.appState.access_token)
      this.toastSuccess(this.$i18n?.t('Copied to clipboard'))
    },
    selectAll () {
      const selectedPermissions = {}
      this.permissions.forEach(element => {
        selectedPermissions[element.name] = true
      })
      this.selectedPermissions = selectedPermissions
    },
    unselectAll () {
      const selectedPermissions = {}
      this.permissions.forEach(element => {
        selectedPermissions[element.name] = false
      })
      this.selectedPermissions = selectedPermissions
    },
    getCheckBoxText (permission) {
      if (permission.description) {
        return permission.description
      }
      return permission.name
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
