import { HTTP } from '@/api/http_utils'

const UPDATE_PERMISSIONS_LIST = 'UPDATE_PERMISSIONS_LIST'

const state = {
  permissionsList: []
}

const getters = {
  permissionsList: state => state.permissionsList
}

const mutations = {
  [UPDATE_PERMISSIONS_LIST] (state, data) {
    state.permissionsList = data
  }
}

const actions = {
  getPermissions ({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get('/permissions').then(response => {
        commit(UPDATE_PERMISSIONS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
