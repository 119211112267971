<template>
  <div>
    <import-load-demand-modal
      v-if="ImportLoadModal"
      :schedule-id="scheduleId"
      @close="close"
      @reload="reload"
    />
    <update-load-demand-modal
      v-if="UpdateLoadModal"
      :loadItem="loadItem"
      :project_tz="project.time_zone"
      @close="close"
      @reload="reload"
    />
    <create-load-demand-modal
      v-if="CreateLoadModal"
      :schedule-id="scheduleId"
      :project_tz="project.time_zone"
      @close="close"
      @reload="reload"
    />
    <forecasting-load-demand-modal
      v-if="ForecastingModal"
      :schedule-id="scheduleId"
      :project_tz="project.time_zone"
      @close="close"
      @reload="reload"
    />
    <dummy-payment-required
      v-if="dummy"
      @activate="init"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-show="!dummy">
      <div class="row">
        <div class="col d-flex align-items-center">
          <ui-date-picker
            :label="$t('Select period')"
            :picker-type="'daterange'"
            :name="'range'"
            v-model="range"
            :start-placeholder="$t('Start date')"
            :end-placeholder="$t('End date')"
            :validation="{required: true}"
            :clearable="false"
            @change="onRangeChange"
          />
        </div>
        <div class="col-auto d-flex align-items-center mx-2">
          <i
            class="icon-plus btn-icon mx-2 text-primary"
            @click="createItem"
            role="button"
            data-toggle="modal"
            data-target="#CreateLoadStatisticModal"
          />
          <el-tooltip effect="light" :content="$t('Import load statistic from file')" placement="top">
            <i
              role="button"
              class="icon-download btn-icon text-primary mx-2"
              v-on:click="ImportFileModal"
              data-toggle="modal"
              data-target="#ImportLoadStatisticModal"
            />
          </el-tooltip>
          <el-tooltip effect="light" :content="$t('Forecasting')" placement="top">
            <i
              role="button"
              class="icon-trending-up btn-icon text-primary mx-2"
              v-on:click="OpenForecastingModal"
              data-toggle="modal"
              data-target="#ForecastingLoadDemandModal"
            />
          </el-tooltip>
          <el-tooltip effect="light" :content="$t('delete_load_demand_for_selected_days')" placement="top">
            <i
              role="button"
              class="icon-trash btn-icon text-danger mx-2"
              v-on:click="BatchDeleteDemand"
            />
          </el-tooltip>
        </div>
      </div>
      <el-divider />
      <div class="row">
        <template v-if="loadStatistic.length === 0">
          <div class="col text-center">
            <div class="font-weight-bold my-5">
              {{$t('NO_LOAD_STATISTIC_STORED')}}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="align-middle text-center table-header">
                    {{$t('Date')}}
                  </th>
                  <th class="align-middle text-center table-header">
                    {{$t('STEP_MINUTES')}}
                  </th>
                  <th class="align-middle text-center table-header">
                    {{$t('Demand values')}}
                  </th>
                  <th class="align-middle text-center table-header"></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in loadStatistic" :key="row.id">
                    <td class="align-middle text-center">
                      {{fromZoneToZone(row.date, 'UTC', project.time_zone).format(localeDateFormat)}}
                    </td>
                    <td class="align-middle text-center">
                      {{row.step}}
                    </td>
                    <td class="align-middle text-center">
                      <div>
                        <!-- костыль, без текста канвас на первой итерации не растягивается на 100% -->
                        <span style="color: white">.</span>
                        <canvas :ref="'myChart_' + index" height="40" aria-label="Chart" role="img" />
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <i
                        class="icon-pencil btn-icon mx-2 text-primary"
                        @click="updateItem(row)"
                        role="button"
                        data-toggle="modal"
                        data-target="#UpdateLoadStatisticModal"
                      />
                      <i
                        class="icon-trash btn-icon mx-2 text-danger"
                        @click="deleteItem(row.id)"
                        role="button"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import ImportLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/ImportLoadDemandModal'
import UpdateLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/UpdateLoadDemandModal'
import CreateLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/CreateLoadDemandModal'
import ForecastingLoadDemandModal from '@/components/Schedule/EditScheduleComponents/LoadDemandComponents/ForecastingLoadDemandModal'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import { Chart } from 'chart.js'

export default {
  name: 'LoadDemand',
  mixins: [errorMixin, momentMixin, mixinDummy],
  components: { CreateLoadDemandModal, UpdateLoadDemandModal, ImportLoadDemandModal, DummyPaymentRequired, ForecastingLoadDemandModal },
  data () {
    return {
      range: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
      ImportLoadModal: false,
      UpdateLoadModal: false,
      CreateLoadModal: false,
      ForecastingModal: false,
      loadItem: null,
      myCharts: [],
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  props: {
    scheduleId: Number,
    project: Object
  },
  created () {
    this.init()
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    loadStatistic () {
      return this.$store.getters.loadStatistic
    }
  },
  methods: {
    init () {
      const query = `from=${this.from.format(this.backendDateTimeFormat)}&to=${this.to.format(this.backendDateTimeFormat)}`
      this.$store.dispatch('getLoadStatistic', [this.scheduleId, query]).then(() => {
        this.dummy = false
        this.handleCharts()
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    handleCharts () {
      this.myCharts.forEach(chart => chart.destroy())
      this.myCharts = []
      this.loadStatistic.forEach((dataset, index) => {
        const myChart = new Chart(this.$refs['myChart_' + index], {
          type: 'line',
          data: {
            labels: dataset.demand_values.map((val, index) => {
              return index
            }),
            datasets: [
              {
                label: this.$t('LOAD_GRAPH_LABEL'),
                data: dataset.demand_values,
                borderWidth: 1,
                fill: false,
                borderColor: 'rgb(36, 70, 194)',
                tension: 0.1,
                backgroundColor: 'rgb(36, 70, 194)'
              }
            ]
          },
          options: {
            elements: {
              point: {
                pointStyle: 'line'
              },
              line: {}
            },
            plugins: {
              legend: {
                display: true,
                position: 'top',
                reverse: false
              }
            },
            layout: {
              padding: 0
            }
            // interaction: {
            //   mode: 'index'
            // }
            // scales: {
            //   x: {
            //     reverse: this.direction === 'rtl',
            //     display: true,
            //     beginAtZero: true,
            //     ticks: {
            //       display: true
            //     }
            //   },
            //   y: {
            //     display: true,
            //     beginAtZero: true,
            //     ticks: {
            //       display: true
            //     }
            //   }
            // }
          }
        })
        // myChart.canvas.parentNode.style.height = '100px'
        this.myCharts.push(myChart)
      })
    },
    close () {
      this.loadItem = null
      this.ImportLoadModal = false
      this.UpdateLoadModal = false
      this.CreateLoadModal = false
      this.ForecastingModal = false
    },
    reload () {
      this.init()
    },
    ImportFileModal () {
      this.ImportLoadModal = true
    },
    OpenForecastingModal () {
      this.ForecastingModal = true
    },
    onRangeChange (val) {
      this.from = moment(val[0])
      this.to = moment(val[1]).endOf('day')
      this.init()
    },
    updateItem (item) {
      this.loadItem = JSON.parse(JSON.stringify(item))
      this.UpdateLoadModal = true
    },
    createItem () {
      this.CreateLoadModal = true
    },
    deleteItem (id) {
      this.$confirm(this.$i18n?.t('CONFIRM_LOAD_STATISTIC_DELETE'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteLoadStatistic', [this.scheduleId, id]).then(() => {
          this.init()
          this.toastSuccess(this.$i18n?.t('Item successfully deleted'))
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        //
      })
    },
    BatchDeleteDemand () {
      this.$confirm(this.$i18n?.t('confirm_delete_load_demand_for_selected_days'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        const payload = {
          date_from: this.fromZoneToZone(this.from, this.project.time_zone, 'UTC').format(this.backendDateTimeFormat),
          date_to: this.fromZoneToZone(moment(this.to).add(1, 'minutes'), this.project.time_zone, 'UTC').format(this.backendDateTimeFormat)
        }
        this.$store.dispatch('batchDeleteLoadDemand', [this.scheduleId, payload]).then(() => {
          this.init()
          this.toastSuccess(this.$i18n?.t('Item successfully deleted'))
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {})
    }
  }
}
</script>
<style scoped>

</style>
