<template>
  <ui-input-label v-if="label" :label="label" :hint="hint"/>
    <el-select
      v-model="innerValue"
      :placeholder="placeholder || $t('Select')"
      :clearable="!!clearable"
      :disabled="!!disabled"
      :filterable="true"
      :multiple="multiple"
      class="w-100"
      :class="{'is-invalid': isInvalid}"
      @change="(val) => $emit('change', val)"
      size="default">
      <el-option-group
        v-for="group in options"
        :key="group.label"
        :label="group[groupLabelName || 'label']">
        <el-option
          v-for="(option, key) in group.options"
          :key="option[keyName || 'id'] || key"
          :value="option[valueName || 'id']"
          :label="option[labelName || 'name']"
        />
      </el-option-group>
    </el-select>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ errorMessage }"
    :rules="validation"
    as="div"
  >
    <el-form-item
      :error="errorMessage"
      :required="validation && !!validation.required"
      class="p-0 mt-0 mb-1"
    />
  </Field>
</template>

<script>
export default {
  name: 'ui-group-select',
  data () {
    return {
      isInvalid: false,
      observer: null
    }
  },
  emits: ['update:modelValue', 'change'],
  props: {
    name: String,
    validation: [String, Object],
    value: [Number, String, Array],
    modelValue: [Number, String, Array],
    options: Array,
    placeholder: String,
    clearable: Boolean,
    disabled: Boolean,
    filterable: { type: Boolean, default: true },
    groupLabelName: String,
    keyName: String,
    valueName: String,
    labelName: String,
    label: String,
    hint: String,
    multiple: { type: Boolean, default: false }
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }
}
</script>
