import { HTTP } from '@/api/http_utils'

const state = {}
const getters = {}
const mutations = {}

const actions = {
  getNotifyCount ({ commit }, projectId) {
    return new Promise(resolve => {
      HTTP.get(`/projects/${projectId}/notified`).then(notify => {
        resolve(notify)
      })
    })
  },
  sendNotify ({ commit }, projectId) {
    return new Promise(resolve => {
      HTTP.post(`/projects/${projectId}/notified`).then(notify => {
        resolve(notify)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
