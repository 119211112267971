<template>
  <div v-if="userRequest">
    <div class="row">
      <div class="col-md-12 row ml-0 pr-0">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/requests`">
              <span class="breadcrumb-item">{{ $t('User requests')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t('User request info') }}</span>
          </li>
        </ol>
        <div class="float-left mt-1" :style="'color:' + statusColor(userRequest.status) ">{{displayStatus(userRequest.status)}}</div>
      </div>
    </div>
    <div class="card card-small">
      <!-- info start -->
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-12">
            <time-off
              :employee="employeesIndexed[userRequest.created_by]"
              :userRequest="userRequest"
              v-if="userRequest.subtype === 'time_off'">
            </time-off>
            <exchange-shifts
              :employeeFrom="employeesIndexed[userRequest.created_by]"
              :employeeTo="employeesIndexed[userRequest.data.employee_to_id]"
              :request="userRequest"
              :checkOvertime="checkOvertimeInfo"
              v-if="userRequest.subtype === 'exchange'">
            </exchange-shifts>
            <break-exchange
              :employee="employeesIndexed[userRequest.created_by]"
              v-if="userRequest.subtype === 'break_exchange'"
              :request="userRequest" />
            <change-shift
              :employee="employeesIndexed[userRequest.created_by]"
              :request="userRequest"
              :checkOvertime="checkOvertimeInfo"
              v-if="userRequest.subtype === 'change_shift'"
            />
            <drop-to-employee
              :employeeFrom="employeesIndexed[userRequest.created_by]"
              :employeeTo="employeesIndexed[userRequest.data.employee_id_drop_to]"
              v-if="userRequest.subtype === 'drop_to_employee'"
              :request="userRequest"
            />
            <create-shift
              :employee="employeesIndexed[userRequest.created_by]"
              :request="userRequest"
              :checkOvertime="checkOvertimeInfo"
              v-if="userRequest.subtype === 'create_shift'"
            />
            <create-break
              :employee="employeesIndexed[userRequest.created_by]"
              :request="userRequest"
              v-if="userRequest.subtype === 'create_break'"
            />
            <move-to-freeboard
              :employee="employeesIndexed[userRequest.created_by]"
              :request="userRequest"
              v-if="userRequest.subtype === 'move_to_freeboard'">
            </move-to-freeboard>
            <move-to-user
              :employee="employeesIndexed[userRequest.created_by]"
              :request="userRequest"
              :checkOvertime="checkOvertimeInfo"
              v-if="userRequest.subtype === 'move_to_user'">
            </move-to-user>
            <div v-if="userRequest.subtype !== 'time_off'" class="container-fluid mt-3 col-md-12">
              <div v-if="userRequest.subtype === 'exchange' || userRequest.subtype === 'drop_to_employee'"
                   class="row mt-3 ml-0 pl-0 b-bottom pb-5 align-middle">
                <button
                  v-if="userRequest.status === 'new' &&
               ((userRequest.subtype === 'exchange' && userRequest.shift.employee.user_id === this.$store.getters.profileId) ||
                (userRequest.subtype === 'drop_to_employee' && userRequest.employees[0] === this.$store.getters.currentCompanyUserId))"
                  class="btn btn-sm btn-circle btn-success float-right btn-height pt-0 pb-0 mr-4 shadow-sm"
                  :disabled="blockAcceptButton"
                  @click="accept">
                  {{ $t('Accept') }}
                </button>
                <button
                  v-if="userRequest.status === 'accepted' &&
               (isEmployeeHasPermission('get-requests-admin') ||
               this.rolesByProjects[this.userRequest.shift.project_id] === 'manager')"
                  class="btn btn-sm btn-circle btn-success float-right btn-height pt-0 pb-0 mr-4 shadow-sm"
                  :disabled="blockAcceptButton"
                  @click="confirm">
                  {{ $t('Confirm') }}
                </button>
                <button
                  v-if="userRequest.status === 'new' || userRequest.status === 'accepted'"
                  class="btn btn-sm btn-circle btn-danger float-right btn-height pt-0 pb-0 mr-4 shadow-sm"
                  @click="runSwal">
                  {{ $t('Cancel') }}
                </button>
              </div>

              <div v-else class="row mt-3 ml-0 pl-0 b-bottom pb-5 align-middle">
                <button
                  v-if="userRequest.status === 'new' && (isEmployeeHasPermission('get-requests-admin') ||
               (this.userRequest.shift && this.rolesByProjects[this.userRequest.shift.project_id] === 'manager') ||
               (this.userRequest.data && this.userRequest.data.project_id && this.rolesByProjects[userRequest.data.project_id] === 'manager') ||
               (userRequest.subtype === 'break_exchange' &&
                userRequest.employees.includes($store.getters.currentCompanyUserId)))"
                  class="btn btn-sm btn-circle btn-success float-right btn-height pt-0 pb-0 mr-4 shadow-sm"
                  :disabled="userRequest.subtype !== 'break_exchange' && blockAcceptButton"
                  @click="confirm">
                  {{ $t('Confirm') }}
                </button>
                <button
                  v-if="userRequest.status === 'new' || (userRequest.status === 'confirmed' && userRequest.subtype === 'time_off')"
                  class="btn btn-sm btn-circle btn-danger float-right btn-height pt-0 pb-0 shadow-sm"
                  @click="runSwal">
                  {{ $t('Cancel') }}
                </button>
                <div
                  v-if="
                userRequest.status === 'accepted'
                && userRequest.user_confirm === true
                && userRequest.manager_confirm === false
                && !isEmployeeHasPermission('delete-request')" class="small align-middle"
                  :style="'color:' + statusColor(userRequest.status)">
                  {{$t('Request awaits manager approval')}}
                </div>
              </div>
            </div>
            <!-- info end -->
            <div class="container mt-4 col-md-12">
              <div class="row ml-0 pl-0 col-12">
                <div class="name-color font-weight-bold fs-16">{{ $t('Request discussion') }}</div>
              </div>
              <div class="chat position-relative">
                <div class="row mt-4 ml-0 pl-0 col-12" v-for="message in userRequest.messages" :key="message.key">
                  <div class="col-md-4 col-5">
                    <div class="row">
                      <div class="visible-md-inline visible-lg-inline col-md-2 col-2 p-0 mt-auto mb-auto">
                        <avatar
                          class="rounded-circle d-inline-block m-0 m-auto"
                          :width="35"
                          :borderRadius="50"
                          :userId="message.profile_id"
                          :placeholder="false"
                          :mini="true"
                          :cache="true"
                          :userName="message.employee_name">
                        </avatar>
                      </div>
                      <div
                        class="mt-1 col-10 px-3"
                        :class="{'my-name': myMessage(message.employee_name)}">
                        {{message.employee_name}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 col-7 mt-auto mb-auto">
                    <div class="row">
                      <div class="col-md-9 row">
                        <div
                          class="triangle-left mt-2"
                          :class="{
                        'triangle-message-right': message.status === 'text' && lang !== 'he',
                        'triangle-my-message-right': myMessage(message.employee_name) && lang !== 'he',
                        'triangle-confirmed-right': (message.status === 'new' || message.status === 'confirmed' || message.status === 'accepted') && lang !== 'he',
                        'triangle-cancel-right': (message.status === 'canceled' || message.status === 'declined') && lang !== 'he',
                        'triangle-message-left': message.status === 'text' && lang === 'he',
                        'triangle-my-message-left': myMessage(message.employee_name) && lang === 'he',
                        'triangle-confirmed-left': (message.status === 'new' || message.status === 'confirmed' || message.status === 'accepted') && lang === 'he',
                        'triangle-cancel-left': (message.status === 'canceled' || message.status === 'declined') && lang === 'he'
                      }"></div>
                        <div
                          class="chat-rounded w-90 p-2 message"
                          :class="{
                        'chat-message': message.status === 'text',
                        'chat-my-message': myMessage(message.employee_name),
                        'confirmed': message.status === 'confirmed' || message.status === 'new',
                        'accepted': message.status === 'accepted',
                        'canceled': message.status === 'canceled',
                        'declined': message.status === 'declined'
                      }">
                          <span v-if="message.status === 'text'">{{message.content}}</span>
                          <span v-if="message.status === 'new'" class="white">{{ $t('Request created') }}
                        <span v-if="message.content && message.content !== 'Request created'"> {{ $t('with message') }}: {{message.content}}
                        </span>
                      </span>
                          <span v-if="message.status === 'confirmed'" class="white">{{ $t('Request confirmed') }}</span>
                          <span v-if="message.status === 'accepted'" class="white">{{ $t('Request accepted') }}</span>
                          <span v-if="message.status === 'canceled'" class="white">{{ $t('Request canceled') }}
                        <span v-if="message.content && message.content.length > 0"> {{ $t('with message') }}: {{message.content}}
                        </span>
                      </span>
                          <span v-if="message.status === 'declined'" class="white">{{ $t('Request declined') }}
                        <span v-if="message.content && message.content.length > 0"> {{ $t('with message') }}: {{message.content}}
                        </span>
                      </span>
                        </div>
                      </div>
                      <div class="font-xs col-md-3 pl-md-0" style="color: #bebebe">{{toTimeZone(message.created_at).format(localeDateTimeFormat)}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="userRequest.status !== 'expired'">
                <Form ref="observe" @submit="sendMessage">
                  <div class="row mt-4">
                    <div class="ml-1 col-md-6 pr-md-5">
                      <ui-text-input
                        name="message"
                        :placeholder="this.$i18n?.t('Enter message here...')"
                        v-model="message"
                        :validation="{ required: true }"
                      />
                    </div>
                  </div>
                  <div class="row mt-3 pb-4">
                    <div class="ml-1 col-md-7">
                      <button class="btn btn-sm btn-primary rounded-pill shadow-sm">{{ $t('Send') }}</button>
                    </div>
                  </div>
                </Form>
              </template>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div
              v-if="userRequest.subtype === 'time_off' &&
             (isEmployeeHasPermission('get-requests-admin') ||
             isEmployeeHasPermission('get-requests-manager'))"
            >
              <GraphVacation :user-request="userRequest" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TimeOff from './RequestComponents/TimeOff'
import ExchangeShifts from './RequestComponents/ExchangeShifts'
import MoveToUser from './RequestComponents/MoveToUser'
import CreateShift from './RequestComponents/CreateShift'
import MoveToFreeboard from './RequestComponents/MoveToFreeboard'
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './RequestComponents/mixinRequest'
import Avatar from '../CommonComponents/Avatar'
import ChangeShift from '@/components/Requests/RequestComponents/ChangeShift'
import BreakExchange from '@/components/Requests/RequestComponents/BreakExchange'
import CreateBreak from '@/components/Requests/RequestComponents/CreateBreak'
import DropToEmployee from '@/components/Requests/RequestComponents/DropToEmployee'
import GraphVacation from '@/components/Requests/RequestComponents/GraphVacation.vue'

export default {
  name: 'Request',
  mixins: [momentMixin, requestMixin],
  components: { GraphVacation, DropToEmployee, CreateBreak, BreakExchange, ChangeShift, TimeOff, ExchangeShifts, MoveToFreeboard, MoveToUser, Avatar, CreateShift },
  data () {
    return {
      message: '',
      userRequest: null,
      userId: '',
      isOvertime: false,
      checkOvertimeInfo: [],
      weekOvertimeControlType: 'allow_overtime',
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      graphStatistic: null
    }
  },
  created () {
    if (this.companyId) {
      const marks = this.$store.dispatch('getMarksByCompany').catch(() => {})
      const templates = this.$store.dispatch('getTemplatesByCompany').catch(() => {})
      const schedules = this.$store.dispatch('getCompanySchedules', true).catch(() => {})
      Promise.all([marks, templates, schedules]).then(() => {
        this.init()
      })
    }
  },
  computed: {
    ...mapGetters(['avatar', 'companyMarks', 'schedules', 'allTemplates', 'projects']),
    hasAvatar () {
      return !!(localStorage.getItem('userAvatar') !== 'undefined' || this.avatar.mini)
    },
    lang () {
      return this.$store.getters.locale
    },
    blockAcceptButton () {
      if (this.weekOvertimeControlType === 'block_overtime' && this.isOvertime) {
        return true
      }
      return false
    },
    rolesByProjects () {
      return this.$store.getters.rolesByProjects || {}
    },
    employeesIndexed () {
      return this.$store.getters.employeesIndexed
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    '$route' (to, from) {
      if (from.params.id !== to.params.id) {
        this.userId = this.$store.getters.profileId
        this.$store.dispatch('getAvatar', this.userId).catch(() => {})
        this.$store.dispatch('getRequest', this.$route.params.requestId)
      }
    }
  },
  methods: {
    // проверка пераработки по 2-м шифтрам на обмен
    checkOvertime (shiftFrom, shiftTo) {
      const shiftACheckResult = new Promise(resolve => {
        // возьмет данные по отработаным часам юзера shiftTo.employee_id, за неделю,
        // начало которой будет вычислено по shiftFrom.time_from
        // и от полученых данных отнимет продолжительность шифта shiftTo.id
        // и прибавит период с shiftFrom.time_from по shiftFrom.time_to минус shiftFrom.break_time
        const query = `shift_id=${shiftTo.id}&employee_id=${shiftTo.employee_id}&time_from=${shiftFrom.time_from}&time_to=${shiftFrom.time_to}&break_time=${+shiftFrom.break_time}`
        this.$store.dispatch('isWeekOvertime', [+shiftFrom.schedule_id, query]).then(result => {
          resolve(result)
        }).catch(() => {
          resolve([])
        })
      })
      const shiftBCheckResult = new Promise(resolve => {
        const query = `shift_id=${shiftFrom.id}&employee_id=${shiftFrom.employee_id}&time_from=${shiftTo.time_from}&time_to=${shiftTo.time_to}&break_time=${+shiftTo.break_time}`
        this.$store.dispatch('isWeekOvertime', [+shiftTo.schedule_id, query]).then(result => {
          resolve(result)
        })
      })
      return Promise.all([shiftACheckResult, shiftBCheckResult])
    },
    init () {
      this.userId = this.$store.getters.profileId
      this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId)
      this.$store.dispatch('getAvatar', this.userId).catch(() => {})
      this.$store.dispatch('getRequest', [this.$store.getters.companyId, this.$route.params.requestId]).then(request => {
        // console.log(1, request)
        const updatedRequest = request
        if (request.shift) {
          updatedRequest.schedule = this.schedules.filter(schedule => +schedule.id === request.shift.schedule_id)[0]
          updatedRequest.project = this.projects.filter(project => +project.id === request.shift.project_id)[0]
          updatedRequest.template = this.allTemplates.filter(template => +template.id === request.shift.template_id)[0]
          updatedRequest.marks = this.companyMarks.filter(mark => request.shift.marks.includes(mark.id))
        }

        if (request.shift_from) {
          const filteredSChedule = this.schedules.filter(schedule => +schedule.id === request.shift_from.schedule_id)
          updatedRequest.schedule_from = filteredSChedule ? filteredSChedule[0] : {}

          const filteredProject = this.projects.filter(project => +project.id === request.shift_from.project_id)
          updatedRequest.project_from = filteredProject ? filteredProject[0] : {}

          const filteredTemplate = this.allTemplates.filter(template => +template.id === request.shift_from.template_id)
          updatedRequest.template_from = filteredTemplate ? filteredTemplate[0] : {}

          const shiftFromMarks = request.shift_from.marks ?? []
          updatedRequest.marks_from = this.companyMarks.filter(mark => shiftFromMarks.includes(mark.id))
        }

        if (request.subtype === 'create_shift') {
          updatedRequest.schedule = this.schedules.filter(schedule => +schedule.id === request.data.schedule_id)[0]
          updatedRequest.project = this.projects.filter(project => +project.id === request.data.project_id)[0]
          updatedRequest.template = request.data.template_id > 0 ? this.allTemplates.filter(template => +template.id === request.data.template_id)[0] : null
          // updatedRequest.marks_from = this.companyMarks.filter(mark => request.data.marks.includes(mark.id))
          if (updatedRequest.schedule) { // если расписание не удалено
            let query = `employee_id=${request.created_by}&time_from=${request.date_from}&time_to=${request.date_to}`
            if (request.template) {
              query += `&break_time=${request.template.allow_break ? +request.template.break_time : 0}`
            }
            this.$store.dispatch('isWeekOvertime', [+request.schedule.id, query]).then(result => {
              this.checkOvertimeInfo = [result]
              this.weekOvertimeControlType = result.week_overtime_control_type
              if (result.is_overtime) {
                this.isOvertime = true
              }
            })
          }
        }

        this.userRequest = updatedRequest
        if (request.subtype === 'exchange' && request.shift_from && request.shift) {
          this.checkOvertime(request.shift_from, request.shift).then(values => {
            values[0].username = request.shift.employee.full_name
            values[1].username = request.shift_from.employee.full_name
            this.checkOvertimeInfo = values
            this.weekOvertimeControlType = values[0].week_overtime_control_type
            if (values[0].is_overtime || values[1].is_overtime) {
              this.isOvertime = true
            }
          })
        }
        if (request.subtype === 'move_to_user' && request.shift) {
          // console.log(2, request)
          const query = `shift_id=${request.shift.id}&employee_id=${request.created_by}&time_from=${request.shift.time_from}&time_to=${request.shift.time_to}&break_time=${+request.shift.break_time}`
          this.$store.dispatch('isWeekOvertime', [+request.shift.schedule_id, query]).then(result => {
            this.checkOvertimeInfo = [result]
            this.weekOvertimeControlType = result.week_overtime_control_type
            if (result.is_overtime) {
              this.isOvertime = true
            }
          })
        }
        // this.userRequest = request
      }).catch(() => {})
    },
    activate () {
      this.dummy = false
      this.init()
    },
    myMessage (user) {
      return user === localStorage.getItem('userName')
    },
    displayStatus (status) {
      if (status === 'new') {
        return this.$i18n?.t('New')
      } else if (status === 'created') {
        return this.$i18n?.t('Created')
      } else if (status === 'confirmed') {
        return this.$i18n?.t('Confirmed')
      } else if (status === 'accepted') {
        return this.$i18n?.t('Accepted')
      } else if (status === 'canceled') {
        return this.$i18n?.t('Canceled')
      } else if (status === 'declined') {
        return this.$i18n?.t('Declined')
      } else if (status === 'expired') {
        return this.$i18n?.t('Expired')
      } else return ''
    },
    statusColor (status) {
      if (status === 'new' || status === 'created') {
        return '#0aa4eb'
      } else if (status === 'confirmed' || status === 'accepted') {
        return '#00ac60'
      } else if (status === 'canceled' || status === 'declined' || status === 'expired') {
        return '#f6840b'
      } else return ''
    },
    confirm () {
      if (this.isOvertime && this.weekOvertimeControlType === 'confirm_overtime') {
        this.$confirm(this.$i18n?.t('CONFIRM_WEEK_OVERTIME'), this.$i18n?.t('Are you sure?'), {
          confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
          cancelButtonText: this.$i18n?.t('No, cancel it!!'),
          type: 'warning',
          center: true
        }).then((result) => {
          this.handleConfirm()
        }).catch(() => {})
      } else {
        this.handleConfirm()
      }
      // console.log(this.userRequest)
    },
    handleConfirm () {
      // console.log(this.userRequest)
      const data = {
        subtype: this.userRequest.subtype,
        status: 'confirmed',
        message: null
      }
      this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.userRequest.id, data]).then(response => {
        if (this.isOvertime) {
          this.$notify({
            title: this.$i18n?.t('Warning'),
            message: this.$i18n?.t('WARNING_WEEK_OVERTIME'),
            position: this.notificationPosition,
            type: 'warning'
          })
        }
        if (!this.isEmployeeHasPermission('delete-request')) {
          this.userRequest.user_confirm = true
        }
        if (this.isEmployeeHasPermission('get-requests-manager') ||
          this.isEmployeeHasPermission('get-requests-admin')) {
          this.userRequest.manager_confirm = true
        }
        this.userRequest.status = response.status
        // console.log(0, response)
        this.userRequest.messages = response.messages
      }).catch(err => {
        this.$notify({
          title: this.$i18n?.t('Error'),
          message: this.$i18n?.t(err.response.data.message),
          position: this.notificationPosition,
          type: 'error'
        })
      })
    },
    accept () {
      let data
      data = {
        subtype: this.userRequest.subtype,
        status: 'accepted',
        message: null
      }
      this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.userRequest.id, data]).then(response => {
        if (this.isOvertime && this.weekOvertimeControlType === 'confirm_overtime') {
          this.$notify({
            title: this.$i18n?.t('Warning'),
            message: this.$i18n?.t('WARNING_WEEK_OVERTIME'),
            position: this.notificationPosition,
            type: 'warning'
          })
        }
        if (!this.isEmployeeHasPermission('delete-request')) {
          this.userRequest.user_confirm = true
        }
        if (this.isEmployeeHasPermission('get-requests-manager') ||
          this.isEmployeeHasPermission('get-requests-admin')) {
          this.userRequest.manager_confirm = true
        }
        this.userRequest.status = response.status
      }).catch(err => {
        this.$notify({
          title: this.$i18n?.t('Error'),
          message: this.$i18n?.t(err.response.data.message),
          position: this.notificationPosition,
          type: 'error'
        })
      })
    },
    cancel (reason) {
      let data
      if (reason) {
        data = {
          subtype: this.userRequest.subtype,
          status: 'declined',
          message: reason
        }
        this.$store.dispatch('editStatus', [this.$store.getters.companyId, this.userRequest.id, data]).then(response => {
          this.userRequest.status = response.status
          this.userRequest.messages = response.messages
        })
      } else {
        this.$notify({
          title: this.$i18n?.t('Warning'),
          message: this.$i18n?.t('Enter reason'),
          type: 'warning'
        })
      }
    },
    runSwal () {
      this.$prompt(this.$i18n?.t('State a reason'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, do it!'),
        cancelButtonText: this.$i18n?.t('No, cancel please'),
        inputPattern: /.{3,}/,
        inputPlaceholder: this.$i18n?.t('State a reason'),
        inputErrorMessage: this.$i18n?.t('Invalid value')
      }).then(({ value }) => {
        this.cancel(value)
      }).catch(() => {})
    },
    sendMessage (values, { resetForm }) {
      this.$refs.observe.validate().then(isValid => {
        if (isValid) {
          this.$store.dispatch('requestChat', [this.$store.getters.companyId, this.userRequest.id, { message: this.message }]).then((response) => {
            this.message = ''
            this.userRequest.messages = response.messages
            resetForm()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .b-bottom {
    border-bottom: 1px solid #7f7f7f;
  }
  .btn-height {
    height: 27px;
  }
  .w-90 {
    width: 90%;
  }
  .chat-rounded {
    border-radius: 10px;
  }
  .chat-message {
    background-color: #f4f4f4;
    margin-left: -1px;
  }
  .chat-my-message {
    background-color: #e0f5ff;
    margin-left: -1px;
  }
  .confirmed {
    background-color: #00ab71;
    margin-left: -1px;
  }
  .accepted {
    background-color: #00ab71;
    margin-left: -1px;
  }
  .canceled {
    background-color: #f4705e;
    margin-left: -1px;
  }
  .declined {
    background-color: #f4705e;
    margin-left: -1px;
  }
  .triangle-left {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    /*border-right: 7px solid #e8e8e8;*/
    border-bottom: 7px solid transparent;
  }
  .triangle-message-right {
    border-right: 7px solid #f4f4f4;
  }
  .triangle-my-message-right {
    border-right: 7px solid #e0f5ff;
  }
  .triangle-confirmed-right {
    border-right: 7px solid #00ab71;
  }
  .triangle-cancel-right {
    border-right: 7px solid #f4705e;
  }
  .triangle-message-left {
    border-left: 7px solid #f4f4f4;
  }
  .triangle-my-message-left {
    border-left: 7px solid #e0f5ff;
  }
  .triangle-confirmed-left {
    border-left: 7px solid #00ab71;
  }
  .triangle-cancel-left {
    border-left: 7px solid #f4705e;
  }
  .border-avatar {
    border: 1px solid #bebebe;
  }
  .chat:before {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    background: #bebebe;
    top: 0;
    bottom: 0;
    margin-left: 11px;
  }
  .my-name {
    color: #0aa4eb;
  }
  .preview {
    width: 160px;
    height: 100px!important;
  }
  .message {
    word-wrap: break-word;
  }
</style>
