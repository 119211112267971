<template>
  <div>
    <div class="row mb-2 mx-auto">
      <div class="col-lg my-1">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item title-text active" aria-current="page">
              <router-link v-if="projectId" :to="`/c/${companyId}/project/${projectId}/users/${employeeId}#notes`">
                <span>{{ $t('User info') }}</span>
              </router-link>
              <router-link v-else :to="`/c/${companyId}/users/${employeeId}#notes`">
                <span>{{ $t('User info') }}</span>
              </router-link>
            </li>
            <li class="breadcrumb-item title-text active" aria-current="page">
              <span class="title-text">{{ $t('NOTE_EDIT') }}</span>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <note-form-component
          @noteCreated="handleRedirect"
          :note="note"
          :employeeId="employeeId" />
      </div>
    </div>
  </div>
</template>

<script>
import NoteFormComponent from '@/components/CommonComponents/NoteFormComponent'
export default {
  name: 'NoteEdit',
  components: { NoteFormComponent },
  data () {
    return {
      note: null
    }
  },
  created () {
    this.note = this.$store.getters.noteToEdit
  },
  computed: {
    employeeId () {
      return this.$route.query.employeeId
    },
    projectId () {
      return this.$route.query.projectId
    },
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    handleRedirect () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
