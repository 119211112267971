<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="init()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div v-if="loaded" class="row">
      <div class="col-md-12 p-2">
        <el-upload
          ref="f_upload"
          class="upload-demo"
          drag
          :action="action"
          :headers="headers"
          :before-upload="beforeUpload"
          :http-request="handleRequest"
        >
          <el-icon class="el-icon--upload"><i class="icon-upload btn-icon" /></el-icon>
          <div class="el-upload__text">
            {{ $t('Drop file here or click to upload') }}
          </div>
          <template #tip>
            <div class="el-upload__tip">
              {{ $t('files with a size less than 102400 kb') }}
            </div>
          </template>
        </el-upload>
        <template v-if="companyFiles.length > 0">
          <!-- <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6"> -->
          <div class="file-block p-2" v-for="item in companyFiles" :key="item.file.key">
            <!-- <div class="h-100 d-flex flex-column justify-content-between"> -->
            <div class="d-flex justify-content-end">
              <template v-if="item.employee_id !== item.upload_by">
                <el-tooltip
                  effect="light"
                  placement="top">
                  <template #content>
                    {{$t('HIDE_FILES_HINT')}}
                  </template>
                  <i
                    :class="item.file.show_to_employee ? 'icon-user-check text-primary' : 'icon-user-minus text-warning'"
                    class="m-1"
                    style="cursor: pointer; font-size: 20px"
                    @click="changeVisibility(item)"
                  />
                </el-tooltip>
              </template>
              <i
                v-if="item.file.mime_type.slice(0, 5) === 'image'"
                class="icon-eye text-primary m-1"
                @click="handlePictureCardPreview(item.file)"
                style="cursor: pointer; font-size: 20px"
              />
              <i
                class="icon-download text-primary m-1"
                @click="handleDownloadObject(item.file)"
                style="cursor: pointer; font-size: 20px"
              />
              <i
                class="icon-trash text-danger m-1"
                style="cursor: pointer; font-size: 20px"
                @click="tryRemoveFileFromCompany(item.file)"
              />
            </div>
            <div>
              <div
                v-if="item.file.mime_type && item.file.mime_type.slice(0, 5) === 'image'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(item.file)+')'}"
              ></div>
              <div
                v-else-if="item.file.mime_type === 'application/pdf'"
                class="img-thumbnail uploaded-file"
                v-bind:style="{'background-image': 'url('+getFilePreview(item.file)+')'}"
              ></div>
              <div v-else
                   class="img-thumbnail uploaded-file"
                   v-bind:style="{'background-image': 'url('+getFilePreview(item.file)+')'}"
              ></div>
            </div>
            <div class="text-truncate align-bottom mt-2">{{item.file.fullname}}</div>
            <!-- </div> -->
          </div>
          <el-dialog v-model="dialogVisible" append-to-body>
            <img width="100%" :src="dialogUrl">
          </el-dialog>
          <!-- </div> -->
        </template>
        <template v-else>
          <div class="text-center p-5">
          <span>
            {{$t('No files found')}}
          </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import axios from 'axios'

export default {
  name: 'Files',
  components: {
    DummyPaymentRequired: DummyPaymentRequired
  },
  mixins: [mixinDummy],
  data () {
    return {
      companyFiles: [],
      action: process.env.VUE_APP_ROOT_API + 'companies/' + this.$store.getters.companyId + '/file/upload',
      headers: {
        Authorization: `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`,
        'Content-Type': 'multipart/form-data'
      },
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      dialogVisible: false,
      dialogUrl: '',
      dialogType: '',
      showToEmployee: true
    }
  },
  created () {
    this.init()
  },
  props: {
    employeeId: Number
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getCompanyFiles', this.companyId).then(files => {
        if (files) {
          this.companyFiles = files.filter(file => file.employee_id === this.employeeId)
          this.loaded = true
        }
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.$router.push('dashboard')
        }
      })
    },
    async beforeUpload (rawFile) {
      if (rawFile.size / 1024 / 1024 > 1) {
        this.toastError(this.$i18n?.t('File size can not exceed 102400 kb!'))
        return false
      }
      await this.setVisibility()
    },
    handleRequest (option) {
      const formData = new FormData()
      formData.append(option.filename, option.file, option.file.name)
      formData.append('employee_id', this.employeeId)
      formData.append('show_to_employee', this.showToEmployee)
      let instance = axios.create()
      instance.defaults.headers.post['Authorization'] = `${localStorage.getItem('token_type')} ${localStorage.getItem('api_key')}`
      instance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
      instance({
        method: 'post',
        url: this.action,
        data: formData
      }).then(() => {
        this.success()
      }).catch(e => {})
    },
    success () {
      this.$refs.f_upload.clearFiles()
      this.init()
    },
    async setVisibility () {
      await this.$confirm(this.$i18n?.t('HIDE_FROM_EMPLOYEE_INFO'), this.$i18n?.t('HIDE_FROM_EMPLOYEE_TITLE'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.showToEmployee = false
        return true
      }).catch(() => {
        this.showToEmployee = true
        return true
      }).finally(() => {
        return true
      })
    },
    handlePictureCardPreview (file) {
      this.dialogUrl = file.url
      this.dialogVisible = true
      this.dialogType = file.mime_type
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    getFilePreview (file) {
      if (file.mime_type.startsWith('image')) {
        return file.url
      } else if (file.mime_type.endsWith('pdf')) {
        return require('../../../../../public/static/images/App/PDF.png')
      } else {
        return require('../../../../../public/static/images/App/file.png')
      }
    },
    tryRemoveFileFromCompany (file) {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.removeFileFromCompany(file)
      }).catch(() => {})
    },
    removeFileFromCompany (file) {
      this.$store.dispatch('delCompanyFile', [this.companyId, { file_id: file.id }]).then(response => {
        this.init()
      })
    },
    changeVisibility (companyFile) {
      this.$store.dispatch('changeVisibility', [this.companyId, companyFile.id, { show_to_employee: !companyFile.file.show_to_employee }]).then(response => {
        this.init()
      })
    }
  }
}
</script>

<style scoped>

</style>
