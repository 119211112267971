<template>
  <div>
    <div class="row mb-2 mx-auto">
      <ol class="breadcrumb bg-transparent p-0 m-0">
        <li class="breadcrumb-item title-text">
          <router-link :to="`/c/${companyId}/developer#applications`">
            <span class="breadcrumb-item">{{ $t('Applications')}}</span>
          </router-link>
        </li>
        <li class="breadcrumb-item title-text active" aria-current="page">
          <span class="title-text">{{ $t('Edit application') }} {{ app.title }}</span>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-lg-2">
        <div class="card card-small">
          <div class="card-body">
            <div id="dropdown-vertical" class="dropdown d-flex d-md-none">
              <button class="btn btn-primary dropdown-toggle w-100" type="button" @click="toggleMenu">
                {{ $t(buttonTitle) }}
              </button>
            </div>
            <div class="nav flex-column nav-pills d-md-block" id="tabs-vertical" role="tablist" aria-orientation="vertical" :class="{'d-none': !showTabs}">
              <a v-for="(title, key) in tabsData" :key="key"
                :href="'#' +  key"
                class="nav-link pl-3 pr-1 rounded"
                :class="{active: activeTab === key}"
                data-toggle="pill" role="tab"
                :aria-controls="'v-pills-' + key" aria-selected="true"
                @click="activateTab(key)">
                {{ $t(title) }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10">
        <div class="card card-small">
          <div class="card-body">
            <main-info
              v-if="activeTab === 'mainInfo' && loaded"
              :app="app">
            </main-info>
            <settings
              v-if="activeTab === 'settings' && loaded"
              :app="app">
            </settings>
            <authorization
              v-if="activeTab === 'authorization' && loaded"
              :app="app">
            </authorization>
            <webhook
              v-if="activeTab === 'webhook' && loaded"
              :app="app">
            </webhook>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainInfo from './Components/MainInfo'
import Settings from './Components/Settings'
import Authorization from './Components/Authorization'
import Webhook from './Components/Webhook'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'

export default {
  name: 'EditApplication',
  components: {
    MainInfo: MainInfo,
    Authorization: Authorization,
    Webhook: Webhook,
    Settings: Settings
  },
  mixins: [errorMixin, momentMixin],
  data () {
    return {
      appId: this.$route.params.appId,
      app: {
        title: ''
      },
      buttonTitle: '',
      activeTab: 'mainInfo',
      showTabs: true,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      preloadUsers: 'employeesIndexed'
    }),
    companyId () {
      return this.$store.getters.company.id
    },
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('get-develover-apps')
    }
  },
  created () {
    this.tabsData = {
      mainInfo: 'Main information',
      settings: 'Settings',
      authorization: 'Authorization',
      webhook: 'Webhook'
    }
    if (this.companyId) {
      this.getApp()
    }
  },
  mounted () {
    this.activeTab = window.location.hash ? window.location.hash.substr(1) : 'mainInfo'
  },
  methods: {
    toggleMenu () {
      this.showTabs = !this.showTabs
    },
    activateTab (key) {
      this.activeTab = key
      this.buttonTitle = this.tabsData[key]
      window.location.hash = key
      this.showTabs = false
    },
    getApp () {
      if (this.accessDenied) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.loaded = false
      if (this.appId) {
        this.$store.dispatch('getDeveloperApp', [this.companyId, this.appId]).then((app) => {
          this.app = app
          this.loaded = true
        })
      }
    }
  },
  watch: {
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('Only company owner can access this page')
      }
    }
  }
}
</script>

<style lang="scss">
</style>
