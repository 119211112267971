import { HTTP } from '@/api/http_utils'
// import { UPDATE_TEMPLATES_LIST, UPDATE_TEMPLATE } from './templates'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_SCHEDULES_LIST = 'UPDATE_SCHEDULES_LIST'
const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
const UPDATE_SCHEDULE_USERS = 'UPDATE_SCHEDULE_USERS'
const DELETE_SCHEDULE_USERS = 'DELETE_SCHEDULE_USERS'

const state = {
  schedulesIndex: {}
}

const getters = {
  schedules: state => Object.values(state.schedulesIndex),
  schedulesIndexed: state => state.schedulesIndex,
  schedulesCount: state => {
    return Object.values(state.schedulesIndex).length
  },
  hasSchedule: (state, getters) => {
    return getters.schedulesCount > 0
  },
  scheduleById: (state) => (id) => {
    return state.schedulesIndex[id]
  }
}

const mutations = {
  [UPDATE_SCHEDULES_LIST] (state, payload) {
    const values = {}
    payload.forEach(item => {
      values[item.id] = item
    })
    state.schedulesIndex = values
  },
  [UPDATE_SCHEDULE] (state, schedule) {
    state.schedulesIndex[schedule.id] = schedule
  },
  [DELETE_SCHEDULE] (state, scheduleId) {
    delete state.schedulesIndex[scheduleId]
  },
  [UPDATE_SCHEDULE_USERS] (state, payload) {
    payload.forEach(item => {
      state.schedulesIndex[item.schedule_id].users.push(item)
    })
  },
  [DELETE_SCHEDULE_USERS] (state, payload) {
    const users = [...state.schedulesIndex[payload.schedule_id].users]
    state.schedulesIndex[payload.schedule_id].users = users.filter(user => !payload.users.includes(user.employee_id))
  }
}

const actions = {
  clearSchedulesCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getSchedules ({ commit }, companyId) {
    const promiseKey = '/companies/' + companyId + '/schedules'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME && Object.keys(state.schedulesIndex).length > 0) {
      return Object.values(state.schedulesIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/schedules').then(schedules => {
        commit(UPDATE_SCHEDULES_LIST, schedules)

        // side-effect, as templates come with schedules
        const templates = []
        schedules.forEach(schedule => {
          schedule.templates.forEach(template => {
            templates.push(template)
          })
        })
        commit('UPDATE_TEMPLATES_LIST', templates)

        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(schedules)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getCompanySchedules ({ commit, rootState }, withTrashed = false) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/schedules?with_trashed=${withTrashed}`).then(schedules => {
        // commit(UPDATE_SCHEDULES_LIST, schedules)
        resolve(schedules)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getSchedulesByProject ({ commit }, [projectId]) {
    const promiseKey = '/projects/' + projectId + '/schedules'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }
    const promise = new Promise(resolve => {
      HTTP.get('/projects/' + projectId + '/schedules').then(schedules => {
        resolve(schedules)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })
    promises[promiseKey] = promise

    return promise
  },

  getSchedulesIds ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.get('/schedules?ids=' + data).then(schedules => {
        resolve(schedules)
      })
    })
  },

  getScheduleById ({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.get('/schedules/' + id).then(schedule => {
        commit(UPDATE_SCHEDULE, schedule)

        // side-effect, as templates come with schedules
        if (schedule.templates && schedule.templates.length > 0) {
          schedule.templates.forEach(template => {
            commit('UPDATE_TEMPLATE', template)
          })
        }

        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  addSchedule ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/schedules', data).then(schedule => {
        if (!data.preview) {
          commit(UPDATE_SCHEDULE, schedule)
        }
        // side-effect, as templates come with schedules
        if (schedule.templates && schedule.templates.length > 0) {
          schedule.templates.forEach(template => {
            commit('UPDATE_TEMPLATE', template)
          })
        }
        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  patchSchedule ({ commit }, [id, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/schedules/' + id, data).then(schedule => {
        // console.log(1, schedule)
        commit(UPDATE_SCHEDULE, schedule)

        // side-effect, as templates come with schedules
        if (schedule.templates && schedule.templates.length > 0) {
          schedule.templates.forEach(template => {
            commit('UPDATE_TEMPLATE', template)
          })
        }

        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteSchedule ({ commit, state }, scheduleId) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/schedules/' + scheduleId).then(response => {
        // side-effect, as templates come with schedules
        const schedule = state.schedulesIndex[scheduleId]
        schedule.templates.forEach(template => {
          commit('DELETE_TEMPLATE', template.id)
        })

        commit(DELETE_SCHEDULE, scheduleId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  restoreSchedule ({ commit }, scheduleId) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/schedules/${scheduleId}/restore`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  rebuildSchedule ({ commit }, data) {
    return new Promise((resolve, reject) => {
      // Vue.prototype.$eventBus.$emit('setLabel', 'Your schedule is currently generating. Please stand by a few minutes.')
      HTTP.patch('/schedules/' + data.id + '/rebuild', data).then(schedule => {
        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  generateSchedule ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/schedules', data).then(response => {
        if (response) {
          resolve(response)
        }
      })
    })
  },

  // OTHER
  isWeekOvertime ({ commit }, [scheduleId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/schedules/${scheduleId}/check_week_overtime?` + data).then(shifts => {
        resolve(shifts)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteUsers ({ commit }, [id, requestData]) {
    return new Promise((resolve, reject) => {
      // console.log(56, requestData)
      HTTP.delete(`/schedules/${id}/users`, { data: requestData }).then(schedule => {
        commit(DELETE_SCHEDULE_USERS, {
          schedule_id: id,
          users: requestData.users
        })
        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  addUsers ({ commit }, [id, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/schedules/' + id + '/users', data).then(users => {
        commit(UPDATE_SCHEDULE_USERS, users)
        resolve(users)
      }).catch(err => {
        reject(err)
      })
    })
  },

  addPayRateToUser ({ commit }, [id, userId, data]) {
    return new Promise(resolve => {
      HTTP.patch('/schedules/' + id + '/users/' + userId, data).then(schedule => {
        resolve(schedule)
      })
    })
  },

  attachNewTemplate ({ commit }, [scheduleId, data]) {
    return new Promise((resolve, reject) => {
      // Vue.prototype.$eventBus.$emit('setLabel', 'Your schedule is currently generating. Please stand by a few minutes.')
      HTTP.patch('/schedules/' + scheduleId + '/template', data).then(schedule => {
        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  resizeSchedule ({ commit }, [id, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/schedules/' + id + '/resize', data).then(schedule => {
        commit(UPDATE_SCHEDULE, schedule)
        resolve(schedule)
      }).catch(err => {
        reject(err)
      })
    })
  },

  scheduleUsersPayRate ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.post('/schedules/pay/rate', data).then(response => {
        resolve(response)
      })
    })
  },

  schedulePayRate ({ commit }, [id, data]) {
    return new Promise(resolve => {
      HTTP.patch('/schedules/' + id + '/rate', data).then(response => {
        resolve(response)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
