import { HTTP, cacheRequest } from '../../api/http_utils.js'
import {
  SET_PROFILE,
  DROP_PROFILE,
  SET_EMAIL_CONFIRMATION,
  SET_USER_CITY,
  SET_USER_COUNTRY,
  SET_USER_STATE
} from '../mutation-types.js'

const state = {
  Profile: {},
  Country: {},
  State: {},
  City: {},
  // userId: localStorage.getItem('userId'),
  companyLimit: localStorage.getItem('companyLimit'),
  demoCompaniesLimit: localStorage.getItem('demoCompaniesLimit'),
  emailConfirmed: localStorage.getItem('emailConfirmed')
}

const getters = {
  Profile: state => state.Profile,
  profileId: state => state.Profile.id,
  locale: state => state.Profile.locale ? state.Profile.locale : 'en',
  userName: state => state.Profile.full_name,
  profileCountry (state) {
    return state.Profile.country_code ? state.Profile.country_code : 'gb'
  },
  profileTimeFormat (state) {
    return state.Profile.time_format ? state.Profile.time_format : 'HH:mm'
  },
  profileDateFormat (state) {
    return state.Profile.date_format ? state.Profile.date_format : 'DD-MM-YYYY'
  },
  profileTimeZone (state) {
    return state.Profile.time_zone ? state.Profile.time_zone : 'UTC'
  },
  companyCount: state => state.Profile.companies ? state.Profile.companies.length : null,
  // todo do refactor below
  State: state => state.State,
  City: state => state.City,
  // userId: state => state.userId,
  companyLimit: state => state.companyLimit,
  emailConfirmed: state => state.emailConfirmed
}

const mutations = {
  [SET_EMAIL_CONFIRMATION] (state) {
    if (state.Profile.confirmed === false) {
      state.Profile.confirmed = true
      state.emailConfirmed = true
      localStorage.setItem('emailConfirmed', true)
    }
  },
  [DROP_PROFILE] (state) {
    state.Profile = {}
    localStorage.removeItem('profile')
  },
  [SET_PROFILE] (state, userProfile) {
    state.Profile = userProfile
    localStorage.setItem('profile', JSON.stringify(userProfile))
  },
  [SET_USER_COUNTRY] (state, userCountry) {
    state.Country = userCountry
  },
  [SET_USER_STATE] (state, userState) {
    state.State = userState
  },
  [SET_USER_CITY] (state, userCity) {
    state.City = userCity
  }
}

const actions = {
  getProfile ({ commit }) {
    return new Promise((resolve, reject) => {
      if (state.Profile.id) {
        resolve(state.Profile)
      } else if (localStorage.getItem('profile')) {
        commit(SET_PROFILE, JSON.parse(localStorage.getItem('profile')))
        resolve(state.Profile)
      } else if (localStorage.getItem('api_key')) {
        HTTP.get('/profile').then(profile => {
          if (profile) {
            commit(SET_PROFILE, profile)
          }
          resolve(profile)
        }).catch(err => {
          reject(err)
        })
      } else {
        reject(Promise.error)
      }
    })
  },
  setProfile ({ commit }, profileData) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/profile', profileData).then(profile => {
        if (profile) {
          commit(SET_PROFILE, profile)
        }
        resolve(profile)
      }).catch(err => {
        reject(err)
      })
    })
  },
  dropProfile ({ commit }) {
    commit(DROP_PROFILE)
  },
  setConfirmedEmail ({ commit }) {
    commit(SET_EMAIL_CONFIRMATION)
  },
  phone ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/profile/phone', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deletePhone ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.delete('/profile/phone', data)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  isExist ({ commit }, phoneData) {
    return new Promise((resolve, reject) => {
      HTTP.post('/phone-exist/', phoneData)
        .then(out => {
          resolve(out)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changePassword ({ commit }, passwordData) {
    return new Promise((resolve, reject) => {
      HTTP.post('/password', passwordData)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  resendVerificationEmail ({ commit }, data) {
    return new Promise((resolve, reject) => {
      HTTP.get('/email/resend?recaptcha_key=' + data.recaptcha_key).then((response) => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getUserInfo ({ commit }) {
    return new Promise((resolve, reject) => {
      // let profile = state.Profile
      cacheRequest('/countries', null, 'utils', 'countries').then(countries => {
        // countries.forEach(item => {
        //   if (profile.country_code && item.code === profile.country_code) {
        //     commit(SET_USER_COUNTRY, item)
        //     cacheRequest('/country/' + item.code + '/subdivision/names?lang=' + profile.locale, null, 'utils', 'subdivisionNames').then(subdivisionNames => {
        //       commit(GET_SUBDIVISION_NAMES, subdivisionNames)
        //       resolve(subdivisionNames)
        //     }).catch(err => {
        //       reject(err)
        //     })
        //   }
        // })
      })
      resolve()
    })
  }
  // facebookInfo ({ commit }, token) {
  //   return new Promise((resolve, reject) => {
  //     Auth.facebookInfo(token)
  //       .then(resp => {
  //         // commit(SET_USER_AVAILABILITY, availabilityData)
  //         resolve(resp)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
