<template>
  <el-tooltip
    :placement="placement || (isLocaleRTL ? 'left' : 'right')"
    effect="light">
    <template #content>
      <div class="text-wrap mx-1"><span v-html="content"></span></div>
    </template>
    <i class="icon-info" :class="{ 'text-danger' : !!danger, 'mx-1': !skipMargin }" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'ui-hint',

  props: [
    'content',
    'placement',
    'danger',
    'skipMargin'
  ],
  computed: {
    isLocaleRTL () {
      return this.$store?.getters?.locale === 'he'
    }

  }
}
</script>

<style lang="scss" scoped>
.text-wrap {
  max-width: 400px;
}

.icon-info {
  font-size: 1.25rem;
  vertical-align: text-top;
  color: #53a8dd;
}
</style>
