<template>
    <div>
      <div class="row mb-2 mx-auto">
        <span class="title-text">
          {{ $t("COMPANY_LOGS") }}
          <ui-hint :content="$t('COMPANY_LOGS_HINTS')" />
        </span>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <div id="filters" class="my-2">
            <div class="row">
              <div class="col-2">
                <ui-date-picker
                  name="from"
                  :clearable="false"
                  :label="$t('SELECT_FROM')"
                  v-model="from"
                  @change="(val) => changeDate('from', val)"
                />
              </div>
              <div class="col-2">
                <ui-date-picker
                  name="to"
                  :clearable="false"
                  :label="$t('SELECT_TO')"
                  v-model="to"
                  @change="(val) => changeDate('to', val)"
                />
              </div>
              <div class="col-2">
                <ui-select
                  :label="$t('SELECT_LOG_TYPE')"
                  name="type"
                  v-model="logName"
                  :clearable="true"
                  :options="logNameFilterComputedOptions"
                  @change="changeType"
                />
              </div>
              <div class="col-2">
                <ui-select
                  :label="$t('SELECT_ACTION_TYPE')"
                  name="action"
                  v-model="action"
                  :clearable="true"
                  :options="actionOptions"
                  @change="changeAction"
                />
              </div>
              <div class="col-2">
                <ui-select
                  :label="$t('SELECT_CAUSER')"
                  name="causer"
                  v-model="causer"
                  :clearable="true"
                  :options="filteredCompanyUsers"
                  @change="changeCauser"
                />
              </div>
            </div>
          </div>
          <el-divider/>
          <div class="row mb-2">
            <div class="col-2 col-xl-1">
              <ui-select
                name="up_pagination"
                v-model="perPage"
                :clearable="false"
                :options="perPageOptions"
                @change="changePerPage"
              />
            </div>
            <div v-if="logs.last_page > 1" class="col-auto">
              <el-pagination
                background
                layout="prev, pager, next"
                @size-change="changeFilter"
                @current-change="goToPage"
                @prev-click="prevPage"
                @next-click="nextPage"
                :current-page="page"
                :page-size="perPage"
                :page-sizes="perPageOptions"
                :total="logs.total">
              </el-pagination>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-light">
              <tr>
                <th class="text-left" style="width: 160px">{{ $t("Date") }}</th>
                <th class="text-left">{{ $t("CAUSER_NAME") }}</th>
                <th class="text-left">{{ $t("ACTION_TYPE") }}</th>
                <th class="text-left">{{ $t("LOG_NAME") }}</th>
                <th class="text-left">{{ $t("LOG_INFO") }}</th>
                <th class="text-left">{{ $t("ITEM_DIFF_BEFORE") }}</th>
                <th class="text-left">{{ $t("ITEM_DIFF_AFTER") }}</th>
                <th style="min-width: 80px"></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(row) in logs.data" :key="row.id">
                <tr>
                  <td style="width: 160px">
                    {{ fromZoneToZone(row.created_at, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat) }}
                  </td>
                  <td>
                    <template v-if="row.causer">
                      {{ row.causer.id in employeesByUserId ? employeesByUserId[row.causer.id].full_name : row.causer.full_name}}
                    </template>
                  </td>
                  <td :class="row.description === 'updated' ? 'text-warning' : row.description === 'created' ? 'text-success' : row.description === 'restored' ? 'text-info' : 'text-danger'">
                    {{ translateAction(row.description) }}
                  </td>
                  <td>{{ row.log_name  ? $t(row.log_name) : '' }}</td>
                  <td><!-- LOG_INFO -->
                    <template v-if="row.log_name === 'shift_logger'">
                      <template v-if="row.properties && row.properties.logger && row.properties.logger === 'shift_mass_logger'">
                        <div>
                          {{$t('SHIFTS_MASS_DELETE')}} <ui-hint :placement="'top'" :content="$t('SHIFTS_MASS_DELETE_HINT')" />
                        </div>
                        <div>
                          {{$t('SHIFTS_MASS_DELETE_COUNT')}}: {{row.properties.count}}
                        </div>
                      </template>
                      <template v-else>
                        <div>
                          {{projects[+row.properties.model.project_id] ? projects[+row.properties.model.project_id].name : ''}}
                          {{schedules[+row.properties.model.schedule_id] ? schedules[+row.properties.model.schedule_id].name : ''}}
                          {{templates[+row.properties.model.template_id] ? templates[+row.properties.model.template_id].name : $t('CUSTOM_TEMPLATE')}}
                        </div>
                        <div>
                          {{fromZoneToZone(row.properties.model.time_from, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}} - {{fromZoneToZone(row.properties.model.time_to, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                        </div>
                        <div v-if="+row.properties.model.employee_id > 0">
                          {{companyUsers[row.properties.model.employee_id] ? companyUsers[+row.properties.model.employee_id].full_name : ''}}
                        </div>
                        <div v-else>{{$t('Free shift')}}</div>
                      </template>
                    </template>
                    <template v-if="row.log_name === 'shift_copy_logger'">
                      <div>
                        {{row.properties.project}}
                      </div>
                      <div class="font-weight-bold">{{$t('COPY_SHIFTS_CONDITIONS')}}</div>
                      <div>
                        {{$t('copied_period_from')}}: {{fromZoneToZone(row.properties.condition.date_from, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                      <div>
                        {{$t('copied_period_to')}}: {{fromZoneToZone(row.properties.condition.date_to, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                      <div>
                        {{$t('date_insert_from')}}: {{fromZoneToZone(row.properties.condition.date_insert, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                      <div>
                        {{$t('merge_type')}}: {{row.properties.condition.merge_type ? $t(row.properties.condition.merge_type) : ''}}
                      </div>
                      <div>
                        {{$t('repeat_number')}}: {{row.properties.condition.repeat_number}}
                      </div>
                      <div>
                        {{$t('clone_with_indent')}}: {{row.properties.condition.with_indent ? $t('Yes') : $t('No')}}
                      </div>
                      <div>
                        {{$t('clone_with_marks')}}: {{row.properties.condition.clone_with_marks ? $t('Yes') : $t('No')}}
                      </div>
                      <div>
                        {{$t('clone_with_breaks')}}: {{row.properties.condition.clone_with_breaks ? $t('Yes') : $t('No')}}
                      </div>
                      <div>
                        {{$t('clone_with_note')}}: {{row.properties.condition.clone_with_note ? $t('Yes') : $t('No')}}
                      </div>
                      <div>
                        {{$t('clone_with_location')}}: {{row.properties.condition.clone_with_location ? $t('Yes') : $t('No')}}
                      </div>
                    </template>
                    <template v-if="loggerList.includes(row.log_name)">
                      {{row.properties.model.name ? row.properties.model.name :
                      row.properties.model.title ? row.properties.model.title :
                        row.properties.model.full_name}}
                    </template>
                    <template v-if="row.log_name === 'todo_logger'">
                      <div>
                        {{$t('Employee')}}: {{row.properties.belongs_to.last_name}} {{row.properties.belongs_to.first_name}}
                      </div>
                      <div>
                        {{$t('Deadline')}}: {{fromZoneToZone(row.properties.model.deadline_date, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                      <div>
                        {{$t('Content')}}: {{ row.properties.model.content }}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'invites_logger'">
                      <div>
                        {{$t('INVITE_CREATED')}}: {{row.properties.belongs_to.last_name}} {{row.properties.belongs_to.first_name}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'shift_mass_logger'">
                      <div>
                        {{$t('MASS_ACTION_TYPE')}}: {{row.properties.action_type ? $t(row.properties.action_type) : ''}}
                      </div>
                      <div>
                        {{$t('COUNT_UPDATED_ROWS')}}: {{row.properties.count ? $t(row.properties.count) : ''}}
                      </div>
                      <div>
                        {{$t('UPDATED_PROJECT')}}: {{row.properties.project && row.properties.project.name ? $t(row.properties.project.name) : ''}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'bonus_penalty_logger'">
                      <div>
                        {{row.properties.model.type ? $t(row.properties.model.type) : ''}}
                      </div>
                      <div>
                        {{$t('amount')}}: {{row.properties.model.amount}}
                      </div>
                      <div>
                        {{$t('shift id')}}: {{row.properties.model.shift_id}}
                      </div>
                      <div v-if="row.properties.model.shift">
                        {{$t('real_time')}}: {{fromZoneToZone(row.properties.model.shift.time_from, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                        - {{fromZoneToZone(row.properties.model.shift.time_to, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                      <div v-if="row.properties.model.shift">
                        {{$t('planned_time')}}: {{fromZoneToZone(row.properties.model.shift.planned_from, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                        - {{fromZoneToZone(row.properties.model.shift.planned_to, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'employee_logger' && row.properties.model">
                      {{row.properties.model.last_name}} {{row.properties.model.first_name}}
                    </template>
                    <template v-if="row.log_name === 'company_modules_logger'">
                      {{modules[+row.properties.model.module_id] ? $t(modules[+row.properties.model.module_id].name) : ''}}
                    </template>
                    <template v-if="row.log_name === 'break_slot_logger'">
                      <div>{{row.properties.model.title}}</div>
                      <div>
                        {{fromZoneToZone(row.properties.model.time_from, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                        - {{fromZoneToZone(row.properties.model.time_to, 'UTC', $store.getters.profileTimeZone).format(localeDateTimeFormat)}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'project_user_logger'">
                      <div v-if="row.description === 'deleted'">
                        {{$t('DELETED_EMPLOYEES_FROM_PROJECT')}} {{row.properties.deleted}}
                      </div>
                      <div v-if="row.description === 'updated'">
                        {{$t('ADDED_EMPLOYEES_TO_PROJECT')}} {{row.properties.added}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'attendance_logger'">
                      <div>
                        {{$t('ATTENDANCE_TYPE')}} {{row.properties.model.type}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'task_logs_logger'">
                      {{row.properties.model.task}} {{row.properties.model.status}}
                    </template>
                    <template v-if="row.log_name === 'company_files_logger' && row.properties.model">
                      {{row.properties.model.fullname}}
                    </template>
                    <template v-if="row.log_name === 'user_request_messages_logger'">
                      {{row.properties.model.content}}
                    </template>
                    <template v-if="row.log_name === 'dismiss_employee_logger'">
                      {{row.properties.model.full_name}} {{row.description === 'created' ? $t('MARKED_AS_DISMISSED') : $t('USER_DELETED_OR_RESTORED')}}
                    </template>
                    <template v-if="row.log_name === 'schedule_users_logger'">
                      <div v-if="row.description === 'deleted'">
                        {{row.properties.model.employee_name}} {{$t('DELETED_EMPLOYEES_FROM_SCHEDULE')}} {{row.properties.model.schedule_name}}
                      </div>
                      <div v-if="row.description === 'restored' || row.description === 'created'">
                        {{row.properties.model.employee_name}} {{$t('ADDED_EMPLOYEES_TO_SCHEDULE')}} {{row.properties.model.schedule_name}}
                      </div>
                    </template>
                    <template v-if="row.log_name === 'user_profile_updated_logger'">
                      {{row.properties.attributes.employee_name}}
                    </template>
                    <template v-if="row.log_name === 'shift_activity_logs_logger'">
                      {{row.properties.model.status_name}}
                    </template>
                    <template v-if="row.log_name === 'shift_activity_mass_logger'">
                     <div>
                       {{$t('count')}}: {{row.properties.count}}
                     </div>
                      <div>
                        {{$t('action_type')}}: {{row.properties.action_type ? $t(row.properties.action_type) : ''}}
                      </div>
                    </template>
                  </td>
                  <td>
                    <div v-if="row.description === 'updated'" v-html="formatDiff(row, 'old')"></div>
                  </td>
                  <td>
                    <template v-if="row.log_name === 'shift_copy_logger'">
                      <div class="font-weight-bold">{{$t('COPY_SHIFTS_RESULT')}}</div>
                      <div>
                        {{$t('total')}}: {{row.properties.result.total}}
                      </div>
                      <div>
                        {{$t('copied')}}: {{row.properties.result.copied}}
                      </div>
                      <div>
                        {{$t('ignored')}}: {{row.properties.result.ignored}}
                      </div>
                      <div>
                        {{$t('blocked_by_holidays')}}: {{row.properties.result.blocked_by_holidays}}
                      </div>
                    </template>
                    <div v-if="row.description === 'updated'" v-html="formatDiff(row, 'new')"></div>
                  </td>
                  <td>
                    <template v-if="row.description === 'deleted' && row.properties.model.deleted_at">
                      <template v-if="row.log_name === 'shift_logger' && row.properties.logger !== 'shift_mass_logger'">
                        <button class="btn btn-danger rounded-pill shadow-sm" @click="restoreShift(row.properties.model.id)">
                          {{ $t('RESTORE_SHIFT') }}
                        </button>
                      </template>
                      <template v-if="row.log_name === 'schedule_logger' && isProjectActive(row.properties.model)">
                        <button class="btn btn-danger rounded-pill shadow-sm" @click="restoreSchedule(row.properties.model.id)">
                          {{ $t('RESTORE_SCHEDULE') }}
                        </button>
                      </template>
                      <template v-if="row.log_name === 'employee_logger'">
                        <button class="btn btn-danger rounded-pill shadow-sm" @click="restoreEmployee(row.properties.model.id)">
                          {{ $t('RESTORE_EMPLOYEE') }}
                        </button>
                      </template>
                    </template>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <el-divider/>
          <div class="row">
            <div class="col-2 col-xl-1">
              <ui-select
                name="bottom_pagination"
                v-model="perPage"
                :clearable="false"
                :options="perPageOptions"
                @change="changePerPage"
              />
            </div>
            <div v-if="logs.last_page > 1" class="col-auto">
              <el-pagination
                background
                layout="prev, pager, next"
                @size-change="changeFilter"
                @current-change="goToPage"
                @prev-click="prevPage"
                @next-click="nextPage"
                :current-page="page"
                :page-size="perPage"
                :page-sizes="perPageOptions"
                :total="logs.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'CompanyLogs',
  mixins: [momentMixin],
  data () {
    return {
      from: moment().add(-1, 'day').startOf('day').format(this.backendDateTimeFormat),
      to: moment().startOf('day').format(this.backendDateTimeFormat),
      page: 1,
      perPage: 100,
      logs: {
        last_page: 1,
        data: []
      },
      logName: null,
      causer: null,
      action: null,
      actionOptions: [
        {
          id: 'created',
          name: this.$t('log_created')
        },
        {
          id: 'updated',
          name: this.$t('log_updated')
        },
        {
          id: 'deleted',
          name: this.$t('log_deleted')
        },
        {
          id: 'restored',
          name: this.$t('log_restored')
        }
      ],
      perPageOptions: [
        {
          id: 50,
          name: '50'
        },
        {
          id: 100,
          name: '100'
        },
        {
          id: 500,
          name: '500'
        }
      ],
      formattedOld: '',
      formattedNew: '',
      companyUsers: {},
      employeesByUserId: {},
      filteredCompanyUsers: [],
      projects: {},
      schedules: {},
      templates: {},
      modules: {},
      loggerList: [
        'schedule_logger',
        'project_logger',
        'project_marks_logger',
        'task_logger',
        'task_status_logger',
        'client_fields_logger',
        'checklist_logger',
        'checklist_item_logger',
        'clients_logger',
        'employee_positions_logger',
        'position_logger',
        'shift_templates_logger',
        'location_logger',
        'employee_notes__logger',
        'shift_activity_logger',
        'shift_activity_template_logger',
        'shift_activity_statuses_logger'
      ]
    }
  },
  created () {
    this.init()
    this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then((response) => {
      this.companyUsers = response
      let filteredCompanyUsers = []
      const employeesByUserId = {}
      for (const user in response) {
        // console.log(response[user])
        employeesByUserId[response[user].user_id] = response[user]
        response[user].name = response[user].full_name
        filteredCompanyUsers.push(response[user])
      }
      filteredCompanyUsers = filteredCompanyUsers.sort((a, b) => {
        if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) return 1
        if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) return -1
        return 0
      })

      this.employeesByUserId = employeesByUserId
      this.filteredCompanyUsers = filteredCompanyUsers
    })
    this.$store.dispatch('getProjectsByCompany', this.$store.getters.companyId).then((response) => {
      this.createProjects(response)
    })
    this.$store.dispatch('getSchedules', this.$store.getters.companyId).then((response) => {
      this.createSchedules(response)
    })
    this.$store.dispatch('getTemplatesByCompany', this.$store.getters.companyId).then((response) => {
      this.createTemplates(response)
    })
    this.$store.dispatch('getModulesAllAvailable', this.$store.getters.companyId).then((response) => {
      this.createModules(response)
    })
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    logNameFilterOptions () {
      if (this.workMode === 'field_service') {
        return [
          'company_logger',
          'invites_logger',
          'user_profile_updated_logger',
          'employee_logger',
          'dismiss_employee_logger',
          'task_logger',
          'task_status_logger',
          'task_logs_logger',
          'tasks_marks_logger',
          'checklist_logger',
          'client_fields_logger',
          'checklist_item_logger',
          'service_area_logger',
          'clients_logger',
          'client_address_logger',
          'client_field_logger',
          'todo_logger'
        ]
      } else {
        return [
          'company_logger',
          'company_modules_logger',
          'employee_logger',
          'user_profile_updated_logger',
          'project_logger',
          'schedule_logger',
          'shift_logger',
          'break_slot_logger',
          'project_marks_logger',
          'shift_mass_logger',
          'bonus_penalty_logger',
          'project_user_logger',
          'attendance_logger',
          'employee_positions_logger',
          'position_logger',
          'company_files_logger',
          'user_request_messages_logger',
          'dismiss_employee_logger',
          'schedule_users_logger',
          'shift_templates_logger',
          'location_logger',
          'employee_notes__logger',
          'invites_logger',
          'shift_copy_logger',
          'vacations_transaction_logger',
          'vacations_statistic_logger',
          'vacations_employee_settings_logger',
          'shift_activity_logger',
          'shift_activity_statuses_logger',
          'shift_activity_template_logger',
          'shift_activity_logs_logger',
          'shift_activity_mass_logger'
        ]
      }
    },
    logNameFilterComputedOptions () {
      const options = this.logNameFilterOptions.map(item => {
        return { id: item, name: this.$t(item) }
      })
      return options.sort((a, b) => {
        if (this.$t(a.id).toLowerCase() > this.$t(b.id).toLowerCase()) return 1
        if (this.$t(a.id).toLowerCase() < this.$t(b.id).toLowerCase()) return -1
        return 0
      })
    }
  },
  methods: {
    init () {
      const from = this.fromZoneToZone(this.from, this.$store.getters.profileTimeZone, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(this.to, this.$store.getters.profileTimeZone, 'UTC').add(1, 'day').format(this.backendDateTimeFormat)
      this.$store.dispatch('getCompanyLogs', {
        workMode: this.workMode,
        companyId: this.companyId,
        from: from,
        to: to,
        page: this.page,
        perPage: this.perPage,
        logName: this.logName,
        causer: this.causer,
        action: this.action
      }).then(logs => {
        const filtered = logs.data.filter(item => {
          if (item.properties.attributes && item.properties.old) {
            if (Object.keys(item.properties.attributes).length === Object.keys(item.properties.old).length &&
              Object.keys(item.properties.attributes).length === 1 &&
              Object.keys(item.properties.attributes)[0] === 'updated_at') {
              return false
            }
          }
          return true
        })

        this.logs = logs
        this.logs.data = filtered
      })
    },
    translateAction (description) {
      if (description === 'created') {
        return this.$t('LOG_ACTION_CREATED')
      }
      if (description === 'updated') {
        return this.$t('LOG_ACTION_UPDATED')
      }
      if (description === 'deleted') {
        return this.$t('LOG_ACTION_DELETED')
      }
      if (description === 'restored') {
        return this.$t('LOG_ACTION_RESTORED')
      }
      return ''
    },
    createProjects (response) {
      const projects = {}
      response.forEach(project => {
        projects[project.id] = project
      })
      this.projects = projects
      // console.log(this.projects)
    },
    createSchedules (response) {
      const schedules = {}
      response.forEach(schedule => {
        schedules[schedule.id] = schedule
      })
      this.schedules = schedules
    },
    createTemplates (response) {
      const templates = {}
      response.forEach(template => {
        templates[template.id] = template
      })
      this.templates = templates
      // console.log(this.templates)
    },
    createModules (response) {
      const modules = {}
      response.forEach(module => {
        modules[module.id] = module
      })
      this.modules = modules
    },
    prevPage () {
      if (this.page > 1) {
        this.page--
        this.init()
      }
    },
    nextPage () {
      if (this.page < this.logs.last_page) {
        this.page++
        this.init()
      }
    },
    goToPage (page) {
      this.page = page
      this.init()
    },
    changeType (val) {
      // console.log('changeType', val)
      this.logName = val
      this.page = 1
      this.init()
    },
    changeAction (val) {
      // console.log('changeAction', val)
      this.action = val
      this.page = 1
      this.init()
    },
    changeCauser (val) {
      // console.log('changeCauser', val)
      this.causer = val
      this.page = 1
      this.init()
    },
    changePerPage (val) {
      this.perPage = val
      this.page = 1
      this.init()
    },
    changeFilter (val) {
      this.page = 1
      this.init()
    },
    changeDate (type, val) {
      if (type === 'from') {
        this.from = val
        if (moment(this.from).isAfter(moment(this.to))) {
          this.to = this.from
        }
      } else {
        this.to = val
        if (moment(this.to).isBefore(moment(this.from))) {
          this.from = this.to
        }
      }
      this.init()
    },
    jsonDiff (obj1, obj2, type) {
      const ret = {}
      for (const i in obj2) {
        if (i) {
          if (i === 'marks') {
            continue
          }
          if (i !== 'updated_at') {
            if (i === 'marks_names') {
              if (type === 'new') {
                ret[this.$t(i)] = obj2[i]
              } else {
                ret[this.$t(i)] = obj1[i]
              }
            } else if (typeof obj1[i] === 'object' && obj1[i] !== null) {
              ret[this.$t(i)] = this.jsonDiff(obj1[i], obj2[i], type)
            } else {
              if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
                if (type === 'new') {
                  if (moment(obj2[i], this.backendDateTimeFormat, true).isValid()) {
                    obj2[i] = this.fromZoneToZone(obj2[i], 'UTC', this.$store.getters.profileTimeZone)
                      .format(this.localeDateTimeFormat)
                  }
                  ret[this.$t(i)] = obj2[i]
                  // ret[this.$t(i)] = Object.prototype.toString.call(obj2[i]) === '[object String]' ? this.$t(obj2[i]) : obj2[i]
                } else {
                  if (moment(obj1[i], this.backendDateTimeFormat, true).isValid()) {
                    obj1[i] = this.fromZoneToZone(obj1[i], 'UTC', this.$store.getters.profileTimeZone)
                      .format(this.localeDateTimeFormat)
                  }
                  ret[this.$t(i)] = obj1[i]
                  // ret[this.$t(i)] = Object.prototype.toString.call(obj1[i]) === '[object String]' ? this.$t(obj1[i]) : obj1[i]
                }
              }
            }
          }
        }
      }
      // console.log(ret)
      return ret
    },
    formatDiff (row, modifier) {
      let string = JSON.stringify(this.jsonDiff(row.properties.old, row.properties.attributes, modifier), null, 2)
      string = string.replaceAll('{', '<div class="ml-2">')
      string = string.replaceAll('}', '</div>')
      string = string.replaceAll('"', '')
      string = string.replaceAll(']', '')
      string = string.replaceAll('[', '<br>')
      string = string.replaceAll(',', '<br>')
      if (modifier === 'old') {
        this.formattedOld = string
      } else {
        this.formattedNew = string
      }
      // console.log(string)
      return string
    },
    clearLogType () {
      if (this.logName) {
        this.logName = null
        this.init()
      }
    },
    clearAction () {
      if (this.action) {
        this.action = null
        this.init()
      }
    },
    clearCauser () {
      if (this.causer) {
        this.causer = null
        this.init()
      }
    },
    restoreShift (shiftId) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('restoreShift', shiftId).then(() => {
          this.init()
        }).catch(() => {
          this.toastError(this.$t('SHIFT_NOT_FOUND'))
        })
      }).catch(() => {})
    },
    restoreSchedule (scheduleId) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('restoreSchedule', scheduleId).then(() => {
          this.$store.dispatch('clearSchedulesCache')
          this.$store.dispatch('getSchedules', this.$store.getters.companyId)
          this.init()
        }).catch(() => {
          this.toastError(this.$t('SCHEDULE_NOT_FOUND'))
        })
      }).catch(() => {})
    },
    restoreEmployee (employeeIdId) {
      this.$confirm('', this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
        cancelButtonText: this.$i18n?.t('No, cancel it!!'),
        type: 'warning',
        center: true
      }).then((result) => {
        this.$store.dispatch('restoreEmployee', [this.companyId, employeeIdId]).then(() => {
          this.init()
        }).catch(() => {
          this.toastError(this.$t('EMPLOYEE_NOT_FOUND'))
        })
      }).catch(() => {})
    },
    isProjectActive (model) {
      return !!this.projects[model.project_id]
    }
  }
}
</script>

<style scoped>

</style>
