import { HTTP } from '@/api/http_utils'

const promises = {}
const lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_APP_CATEGORIES_LIST = 'UPDATE_APP_CATEGORIES_LIST'

const state = {
  appCategoriesIndex: {}
}

const getters = {
  appCategories: state => Object.values(state.appCategoriesIndex).sort((a, b) => {
    return a.priority > b.priority ? 1 : -1
  })
}

const mutations = {
  [UPDATE_APP_CATEGORIES_LIST] (state, payload) {
    Object.values(state.appCategoriesIndex).forEach((item) => {
      delete state.appCategoriesIndex[item.id]
    })
    payload.forEach(item => {
      state.appCategoriesIndex[item.id] = item
    })
  }
}

const actions = {
  getApps ({ commit }) {
    return new Promise(resolve => {
      HTTP.get('/app').then(response => {
        resolve(response)
      })
    })
  },
  getApp ({ commit }, appId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`app/${appId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getAppCategories ({ commit }) {
    const promiseKey = 'app/categories'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.appCategoriesIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('app/categories').then(categories => {
        commit(UPDATE_APP_CATEGORIES_LIST, categories)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(categories)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getDevApps ({ commit }, appId) {
    return new Promise((resolve, reject) => {
      HTTP.get('developer/app').then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDevApp ({ commit }, appId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`developer/app/${appId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCompanyApps ({ commit, rootState }) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + companyId + '/app').then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getCompanyApp ({ commit, rootState }, appId) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/app/${appId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateCompanyAppFields ({ commit, rootState }, [appId, data]) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/app/${appId}/fields`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteCompanyApp ({ commit, rootState }, appId) {
    const companyId = rootState.company.companyId
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/app/${appId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
