<template>
  <modal-component :id="'ClickAddPromoModal'" :modalClass="'modal-xm'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t(title) }}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observe">
          <div class="row">
            <div class="col-lg-12">
              <div class="pb-2">
                <ui-text-input
                  :label="$t('Code')"
                  id="code"
                  name="code"
                  v-model="code"
                  :validation="{required: true, min: 3, max: 50}"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <button
          @click="save"
          class="btn btn-success btn-circle shadow-sm"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </template>

  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'
import TasksAccessMixin from '@/mixins/TasksAccessMixin'

export default {
  name: 'AddPromoModal',
  mixins: [errorMixin, momentMixin, TasksAccessMixin],
  components: {
    ModalComponent
  },
  data () {
    return {
      code: '',
      promoId: null
    }
  },
  props: { title: String, modalData: Object },
  created () {
    if (this.modalData) {
      this.code = this.modalData.code
      this.promoId = this.modalData.id
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    })
  },
  methods: {
    closeModal () {
      document.getElementById('ClickAddPromoModal').click()
      this.$emit('closePromoModal')
    },
    save () {
      this.$refs.observe.validate().then(isValid => {
        if (isValid) {
          const payload = {
            code: this.code
          }
          if (this.promoId) {
            this.$store.dispatch('updateCompanyPromo', [this.promoId, payload]).then((response) => {
              this.$notify({
                type: 'success',
                message: this.$t('Save completed'),
                position: this.notificationPosition
              })
              this.closeModal()
            })
          } else {
            this.$store.dispatch('storeCompanyPromo', payload).then((response) => {
              this.$notify({
                type: 'success',
                message: this.$t('Save completed'),
                position: this.notificationPosition
              })
              this.closeModal()
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
