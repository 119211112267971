<template>
  <div>
    <div class="row mb-2">
      <div class="col title-text">
        {{ $t('FILTERS') }}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-multi-select
            name="projects"
            :label="$t('PROJECTS_FILTER')"
            v-model="filters.projects"
            :options="projects"
            :clearable="true"
            :key-name="'id'"
            :label-name="'name'"
            :value-name="'id'"
            @change="onProjectChange"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-multi-select
            name="schedules"
            :label="$t('SCHEDULES_FILTER')"
            v-model="filters.schedules"
            :options="schedules"
            :clearable="true"
            :key-name="'id'"
            :label-name="'name'"
            :value-name="'id'"
            @change="onScheduleChange"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-multi-select
            name="locations"
            :label="$t('LOCATIONS_FILTER')"
            v-model="filters.locations"
            :options="locations"
            :clearable="true"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-multi-select
            name="employees"
            :label="$t('EMPLOYEES_FILTER')"
            v-model="filters.employees"
            :options="employees"
            :clearable="true"
            :key-name="'id'"
            :label-name="'full_name'"
            :value-name="'id'"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-multi-select
            name="positions"
            :label="$t('POSITIONS_FILTER')"
            v-model="filters.positions"
            :options="positions"
            :clearable="true"
            :key-name="'id'"
            :label-name="'title'"
            :value-name="'id'"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-tags-select
            name="marks"
            :label="$t('MARKS_FILTER')"
            v-model="filters.marks"
            :options="marks"
            :clearable="true"
            :key-name="'id'"
            :label-name="'name'"
            :value-name="'id'"
        >
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <ui-tags-select
          name="marks"
          :label="$t('STATUSES_FILTER')"
          v-model="filters.statuses"
          :options="statusesOptions"
          :clearable="true"
          :key-name="'id'"
          :label-name="'value'"
          :value-name="'id'"
        >
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.value }}
          </template>
        </ui-tags-select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <button class="btn btn-primary btn-sm rounded-pill shadow-sm" type="button" @click="applyFilter">
          <i class="icon-search" />
        </button>
      </div>
      <div class="col-auto">
        <button class="btn btn-secondary btn-sm rounded-pill shadow-sm" type="button" @click="clearFilter">
          <span class="small">{{$t('Clear')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import mixinStatusColors from '@/mixins/mixinStatusColors'

export default {
  name: 'ActivityDrawerFilters',
  mixins: [mixinStatusColors],
  data () {
    return {
      filters: {
        projects: [],
        schedules: [],
        employees: [],
        locations: [],
        positions: [],
        marks: [],
        statuses: []
      },
      statusesOptions: [
        {
          id: 'late',
          value: this.$t('STATUS_LATE'),
          color: ''
        },
        {
          id: 'absent',
          value: this.$t('STATUS_ABSENT'),
          color: ''
        },
        {
          id: 'started',
          value: this.$t('STATUS_STARTED'),
          color: ''
        },
        {
          id: 'finished',
          value: this.$t('STATUS_FINISHED'),
          color: ''
        }
      ]
    }
  },
  created () {
    this.statusesOptions.forEach(option => {
      option.color = this.statusColors[option.id]
    })
  },
  computed: {
    projects () {
      // console.log('projects', this.$store.getters.projects)
      return this.$store.getters.projects.map(item => {
        return {
          id: item.id,
          name: item.name,
          users: item.users ? [...new Set([...item.users].concat([...item.managers]))] : [],
          schedules: this.$store.getters.schedules.filter(schedule => schedule.project_id === item.id).map(schedule => schedule.id)
        }
      })
    },
    schedules () {
      const schedules = this.$store.getters.schedules
      // console.log(schedules)
      if (this.filters.projects.length > 0) {
        const filtered = []
        this.projects.forEach(project => {
          // console.log(project)
          if (this.filters.projects.includes(project.id)) {
            filtered.push(project.id)
          }
        })
        return schedules.filter(schedule => filtered.includes(schedule.project_id))
      }
      return schedules
    },
    employees () {
      const employees = this.$store.getters.employees
      if (this.filters.projects.length > 0) {
        let filtered = []
        this.projects.forEach(project => {
          if (this.filters.projects.includes(project.id)) {
            filtered = filtered.concat(project.users)
          }
        })
        return employees.filter(item => filtered.includes(item.id))
      }
      return employees
    },
    locations () {
      return this.$store.getters.locations.filter(location => +location.hide === 0)
    },
    positions () {
      return this.$store.getters.positions
    },
    marks () {
      return this.$store.getters.companyMarks
    }
  },
  methods: {
    onProjectChange () {
      this.filters.schedules = this.filters.schedules.filter(schedule => {
        let result = false
        this.filters.projects.forEach(projectId => {
          const projectSchedules = this.$store.getters.schedules.filter(schedule => schedule.project_id === projectId).map(schedule => schedule.id)
          if (projectSchedules.includes(schedule)) {
            result = true
          }
        })
        return result
      })
      this.filters.employees = []
    },
    onScheduleChange () {
      this.filters.employees = []
    },
    applyFilter () {
      this.$emit('applyFilter', this.filters)
    },
    clearFilter () {
      this.filters.schedules = []
      this.filters.employees = []
      this.filters.projects = []
      this.filters.locations = []
      this.filters.positions = []
      this.filters.marks = []
      this.filters.statuses = []
      this.applyFilter()
    }
  }
}
</script>
<style scoped>

</style>
