<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('VACATIONS_OVERUSE_TITLE') }}</span>
      </div>
    </div>
    <Form ref="observer" v-slot="{ meta }">
      <div class="row mt-2">
        <div class="col-lg-6 d-flex">
          <div>
            <ui-number-input
              :label="$t('VACATIONS_ALLOWED_OVERUSE')"
              :hint="$t('VACATIONS_ALLOWED_OVERUSE_HINT')"
              name="vacations_overuse"
              :min="0"
              :precision="0"
              :step="1"
              v-model="vacations_overuse"
              :validation="{required: true, decimal: true, min_value: 0, max_value: 365}"
            />
          </div>
        </div>
        <div class="col-auto d-flex justify-content-center align-items-end">
          <div>
            <el-button
              size="large"
              type="primary"
              round
              @click="save"
              :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
            >
              {{ $t('SAVE_WEB') }}
            </el-button>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'VacationsOveruse',
  mixins: [errorMixin],

  data () {
    return {
      vacations_overuse: 0
    }
  },
  props: {
    employeeId: Number,
    userInfo: Object
  },
  created () {
    this.vacations_overuse = this.userInfo.vacations_overuse
  },
  methods: {
    async save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const payload = {
            vacations_overuse: this.vacations_overuse
          }
          if (this.isEmployeeHasPermission('get-employee-admin')) {
            this.patchAsAdmin(payload)
          } else if (this.isEmployeeHasPermission('get related projects full data')) {
            this.patchAsManager(payload)
          }
        }
      })
    },
    patchAsManager (data) {
      this.projectId = this.$route.params.projectId
      if (!this.projectId) {
        this.projectId = localStorage.getItem('currentProject')
      }
      this.$store.dispatch('patchProjectEmployee', [this.projectId, this.userInfo.id, data])
        .then(response => {
          this.toastSuccess(this.$i18n?.t('VACATIONS_OVERUSE_UPDATED_SUCCESS'))
        })
        .catch(() => {
          this.toastError(this.$i18n?.t('VACATIONS_OVERUSE_UPDATED_FAILURE'))
        })
    },
    patchAsAdmin (data) {
      this.$store.dispatch('patchEmployee', [this.$store.getters.companyId, this.userInfo.id, data])
        .then(response => {
          this.toastSuccess(this.$i18n?.t('VACATIONS_OVERUSE_UPDATED_SUCCESS'))
        })
        .catch(() => {
          this.toastError(this.$i18n?.t('VACATIONS_OVERUSE_UPDATED_FAILURE'))
        })
    }
  }
}
</script>

<style scoped>

</style>
