<template>
  <div>
    <div class="row ml-0 pl-0 col-12">
      <div class="name-color font-weight-bold fs-16">{{ $t('Move to user') }}</div>
    </div>
    <div class="mt-3">
      <div>
        <div class="d-flex align-items-center">
          {{ $t('User') }}
          <span class="visible-md-inline visible-lg-inline ml-1">
            <avatar
              class="rounded-circle d-inline-block font-weight-normal"
              :width="35"
              :borderRadius="50"
              :placeholder="false"
              :userId="request.profile_id"
              :mini="true"
              :cache="false"
              :userName="userName">
            </avatar>
          </span>
          <span class="ml-1 font-weight-bold">{{userName}}</span>
          <span class="mx-1" v-if="employee.identifier"> ({{employee.identifier}})</span>
          <span class="mx-1">{{ $t('wants to take a shift from the Free Board') }}</span>
          <template v-if="checkOvertime.length > 0 && checkOvertime[0].is_overtime">
            <span class="text-warning small mx-2" v-if="checkOvertime[0].week_overtime">
              {{ $t('WEEK_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].week_overtime * 100) / 100 }}
            </span>
            <span class="text-warning small mx-2" v-if="checkOvertime[0].month_overtime">
              {{ $t('MONTH_OVERTIME_WARNING')}} {{ Math.round(checkOvertime[0].month_overtime * 100) / 100 }}
            </span>
          </template>
        </div>
        <ShiftTitle :request="request" />
        <div class="mt-3">
          <span>{{ $t('Shift begins at')}}</span>
          <span class="font-weight-bold ml-1">{{ toTimeZone(request.date_from).format(localeDateTimeFormat) }}</span>
        </div>
        <div class="mt-3">
          <span>{{ $t('Shift ends at')}}</span>
          <span class="font-weight-bold ml-1">{{ toTimeZone(request.date_to).format(localeDateTimeFormat) }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-3 ml-0 pl-0">
      <div>
        <span>{{ $t('Created') }}</span>
        <span class="ml-1">{{convertDate(request.created_at, false, true)}}</span>
      </div>
    </div>
    <ShiftLocation v-if="request.shift" :location="request.shift.location" />
    <ShiftBreaks v-if="request.shift" :breaks="request.shift.breaks" />
    <ShiftMarks v-if="request.marks" :marks="request.marks" />
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from './mixinRequest'
import Avatar from '../../CommonComponents/Avatar'
import ShiftBreaks from '@/components/Requests/ViewComponents/ShiftBreaks'
import ShiftLocation from '@/components/Requests/ViewComponents/ShiftLocation'
import ShiftMarks from '@/components/Requests/ViewComponents/ShiftMarks'
import ShiftTitle from '@/components/Requests/ViewComponents/ShiftTitle'
export default {
  name: 'MoveToUser',
  props: ['request', 'checkOvertime', 'employee'],
  components: { ShiftTitle, ShiftMarks, ShiftLocation, ShiftBreaks, Avatar },
  mixins: [momentMixin, requestMixin],
  computed: {
    userName () {
      return this.request.employee_name
    }
  }
}
</script>

<style scoped>

</style>
