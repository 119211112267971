<template>
  <modal-component
    :id="'AddEmployeeModal'"
    :modalClass="'modal-lg'"
    data-cy="add-employees-component"
    :closeModalId="'modalEmployeeCloseButton'"
    @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{$t('Add employee')}}</h5>
    </template>
    <template #body>
    <div>
      <Form ref="observer" as="div">
        <div class="row mb-2" style="font-size: 14px">
          <div class="col-lg-6">
            <ui-text-input
              id="demo_add_user_first_name"
              :name="'first_name-' + 0"
              v-model="firstName"
              :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
              :label="$t('First name')"
              :data-cy="'employee-fname'"
            />
          </div>
          <div class="col-lg-6">
            <ui-text-input
              id="demo_add_user_last_name"
              :name="'last_name-' + 0"
              v-model="lastName"
              :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
              :label="$t('Last name')"
              :data-cy="'employee-lname'"
            />
          </div>
        </div>
        <div class="row mb-2" style="font-size: 14px">
          <div class="col-lg-6">
            <ui-tel-input
              ref="tel_input"
              :name="'phone-' + 0"
              :id="'registerInputPhone'"
              :defaultCountry="defaultCountry"
              v-model="phone"
              :label="$t('Phone Number (optional)')"
              @isPhoneValid="phoneValidate"
            />
          </div>
          <div class="col-lg-6">
            <ui-text-input
              id="demo_add_user_email"
              :name="'email-' + 0"
              v-model="email"
              :validation="{ required: false, email: true }"
              :label="$t('E-mail (optional)')"
            />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12 col-lg-6 align-self-end">
            <ui-text-input
              name="identifier"
              v-model="identifier"
              :validation="{ max: 60, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/ }"
              :label="$t('EMPLOYEE_IDENTIFIER')"
              :hint="$t('EMPLOYEE_IDENTIFIER_TOOLTIP')"
            />
          </div>
          <div v-if="workMode === 'work'" class="col-12 col-lg-6 align-self-end">
            <ui-number-input
              name="job_part"
              v-model="job_part"
              :validation="{required: true, decimal: 2, min_value: 0}"
              :label="$t('EMPLOYEE_JOB_PART')"
              :hint="$t('EMPLOYEE_JOB_PART_TOOLTIP')"
            />
          </div>
        </div>
        <template v-if="workMode !== 'field_service'">
          <div class="mb-2">
            <ui-select
              name="projectId"
              id="demo_add_user_project"
              v-model="projectId"
              :clearable="true"
              :label="$t('Select project')"
              :hint="$t('PSELECT_ROJECTT_HINT')"
              :options="projectChoices"
              key-name="value"
              label-name="label"
              value-name="value"
              @change="projectChange"
            />
          </div>
          <div class="mb-2">
            <ui-select
              name="scheduleId"
              id="demo_add_user_schedule"
              v-model="scheduleId"
              :clearable="true"
              :label="$t('Select schedule')"
              :hint="$t('SELECT_SCHEDULE_HINT')"
              :options="scheduleChoices"
              key-name="value"
              label-name="label"
              value-name="value"
              @change="scheduleChange"
            />
          </div>
        </template>
        <div class="row mb-2">
          <div class="col-lg-6">
            <ui-date-picker
              name="start_date"
              :clearable="false"
              :label="$t('Add users starting from the date')"
              :hint="$t('ADD_USERS_STARTING_FROM_THE_DATE_HINT')"
              :validation="{required: true}"
              data-vv-validate-on="change"
              v-model="addDate"
            />
          </div>
          <div v-if="workMode === 'work'" class="col-lg-6">
            <ui-select
              name="positions"
              id="demo_add_user_positions"
              v-model="positions"
              :clearable="true"
              :label="$t('Positions')"
              :hint="$t('POSITIONS_HINT')"
              :options="options"
              key-name="id"
              label-name="title"
              value-name="id"
            />
          </div>
        </div>
        <div class="mb-2">
          <ui-checkbox
            name="invite"
            v-model="invite"
            :disabled="!isConfirmed || !enableSendInvite || !invitationAllowed"
            :label="$t('Send invite')"
            :hint="$t('SEND_INVITE_INFO')"
          />
          <el-alert
            v-if="!invitationAllowed"
            show-icon
            center
            type="warning">
            <template #title>
              <template v-if="isOwner">
                <div>{{$t('INVITATION_NOT_ALLOWED_WARNING')}}</div>
                <router-link :to="`/c/${companyId}#payment`">
                  <span @click="() => closeModal()">{{ $t('BALANCE_LINK') }}</span>
                </router-link>
              </template>
              <template v-else>
                <div>{{$t('INVITATION_NOT_ALLOWED_WARNING_USER')}}</div>
              </template>
            </template>
          </el-alert>
          <el-alert
            v-if="!isConfirmed"
            show-icon
            center
            type="warning">
            <template #title>
              <div>{{$t('UNCONFIRMED_PHONE_WARNING')}}</div>
              <router-link :to="`/profile#general`">
                <span @click="() => closeModal()">{{ $t('CONFIRM_PHONE_LINK') }}</span>
              </router-link>
            </template>
          </el-alert>
        </div>
      </Form>
    </div>
    </template>
    <template #footer>
    <div>
      <button
        id="demo_add_user_add_and_close"
        data-cy="add-and-close-button"
        class="btn btn-outline-primary rounded-pill shadow-sm"
        aria-label="Close"
        @click="save(true)">
        {{ $t('Add and close') }}
      </button>
      <button
        id="demo_add_user_save"
        class="btn btn-success rounded-pill shadow-sm ml-2"
        data-cy="add-button"
        aria-label="Close"
        @click="save(false)">
        {{ $t('Add') }}
      </button>
    </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment'
import momentMixin from '../../mixins/mixinMoment'
import errorMixin from '../../mixins/mixinApiErrors'

export default {
  name: 'AddEmployeeComponent',
  components: {
    ModalComponent
  },
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      firstName: '',
      lastName: '',
      identifier: null,
      phone: '',
      addDate: moment().format(this.backendDateTimeFormat),
      email: null,
      positions: null,
      job_part: 100,
      options: [],
      isPhoneValid: true,
      projectChoices: [],
      scheduleChoices: [],
      addTypesChoices: [
        {
          value: 'without_shifts',
          label: this.$t('Without shifts')
        },
        {
          value: 'by_availability',
          label: this.$i18n?.t('By user availability')
        }
      ],
      projectId: null,
      scheduleId: null,
      invite: false,
      addType: 'without_shifts',
      addTypeIsBlocked: false,
      schedules: []
    }
  },

  props: {
    projectDefaultId: Number
  },

  created () {
    // console.log(123, this.workMode)
    if (this.companyId) {
      this.init()
    }
  },

  computed: {
    company () {
      return this.$store.getters.company
    },
    defaultCountry () {
      return this.$store.getters.company.address
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    isConfirmed () {
      return this.$store.getters.Profile.phone !== null
    },
    invitationAllowed () {
      return this.$store.getters.company.invitation_allowed
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    enableSendInvite () {
      return (this.email) || (this.phone && this.isPhoneValid)
    },
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('create-employee')
    }
  },

  watch: {
    // phone () {
    //   this.errors.remove('phone_response')
    // },
    // email () {
    //   this.errors.remove('email_response')
    // },
    companyId () {
      this.init()
    },
    projectDefaultId () {
      this.init()
    },
    enableSendInvite () {
      this.invite = false
    },
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
    }
  },

  methods: {
    init () {
      if (this.accessDenied) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.$store.dispatch('getPositions', this.companyId).then((response) => {
        this.options = response
      })
      if (this.workMode === 'work') {
        this.$store.dispatch('getProjectsByCompany', this.companyId).then(response => {
          response.forEach(project => {
            if (this.isEmployeeHasPermission('get-employee-admin') ||
              (this.isEmployeeHasPermission('get related projects full data') &&
                project.role === 'manager')) {
              this.projectChoices.push({
                value: project.id,
                label: project.name
              })
            }
          })
          if (this.projectChoices.length > 1) {
            if (this.projectDefaultId) {
              this.projectId = this.projectDefaultId
            } else {
              this.projectId = this.projectChoices[1].value
            }
            this.$store.dispatch('getSchedulesByProject', [this.projectId]).then((schedules) => {
              this.schedules = schedules
              schedules.forEach(schedule => {
                this.scheduleChoices.push({
                  value: schedule.id,
                  label: schedule.name
                })
              })
              if (this.scheduleChoices.length > 0) {
                this.scheduleId = this.scheduleChoices[0].value
              }
            })
          }
        })
      } else {
        this.isPhoneValid = true
      }
    },
    projectChange (projectId) {
      this.scheduleId = null
      this.scheduleChoices = []
      if (projectId !== null && projectId !== undefined) {
        this.$store.dispatch('getSchedulesByProject', [projectId]).then((schedules) => {
          this.schedules = schedules
          schedules.forEach(schedule => {
            this.scheduleChoices.push({
              value: schedule.id,
              label: schedule.name
            })
          })
        })
      }
    },
    scheduleChange (scheduleId) {
      this.addType = 'without_shifts'
      let currentSchedule = null
      this.schedules.forEach(schedule => {
        if (scheduleId === schedule.id) currentSchedule = schedule
      })
      if (currentSchedule) {
        this.addTypeIsBlocked = currentSchedule.schedule_type !== 'by_availability'
      }
    },
    parsePhoneNumber (number) {
      number = number.substr(1)
      number = number.replace(/\s/g, '')
      return number
    },
    manuallyCloseModal () {
      document.getElementById('modalEmployeeCloseButton').click()
      this.$emit('AddEmployeeModal')
    },
    closeModal () {
      this.$emit('AddEmployeeModal')
      this.clearState(true)
      this.isPhoneValid = true
      document.getElementById('AddEmployeeModal').click()
    },
    save (close) {
      this.$refs.observer.validate().then(value => {
        if (value.valid) {
          const data = [
            {
              first_name: this.firstName,
              last_name: this.lastName,
              phone: this.isPhoneValid ? this.parsePhoneNumber(this.phone) : '',
              email: this.email ? this.email : '',
              project_id: this.projectId ? this.projectId : null,
              invite: this.invite,
              identifier: this.identifier,
              job_part: this.job_part
            }
          ]
          this.$store.dispatch('addEmployees', [this.$store.getters.companyId, data]).then(response => {
            this.$emit('addEmployees')
            this.$eventBus.emit('checkSteps')
            if (response.errors.length > 0) {
              if (response.errors[0].phone) {
                this.errors.add({
                  field: 'phone_response',
                  msg: 'PHONE_ALREADY_EXIST'
                })
              }
              if (response.errors[0].email) {
                this.errors.add({
                  field: 'email_response',
                  msg: 'EMAIL_ALREADY_EXIST'
                })
              }
              return
            }
            const employeeId = response[0].id
            if (this.scheduleId) {
              const userId = response[0].id
              const data = {
                users: [userId],
                add_type: this.addType,
                date_from: moment(this.addDate).format(this.backendDateTimeFormat),
                preview: false
              }
              this.$store.dispatch('addUsers', [this.scheduleId, data]).then((response) => {
                this.toastSuccess(this.$i18n?.t('User created and added to schedule'))
                this.updatePositions(employeeId)
                this.$refs.observer.resetForm()
                this.$refs.tel_input.clearInvalid()
                if (close) {
                  this.closeModal()
                }
              }).catch((error) => {
                this.toastError(this.$t(error.response.data.message))
              })
            } else {
              let title = ''
              if (this.projectId) {
                title = this.$t('User created and added to project')
              } else {
                title = this.$t('User created')
              }
              this.toastSuccess(title)
              this.updatePositions(employeeId)
              if (close) {
                this.closeModal()
              }
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },
    updatePositions (employeeId) {
      if (this.positions) {
        this.$store.dispatch('attachPositions', [
          this.companyId,
          employeeId,
          {
            positions_id: this.positions,
            schedule_id: this.scheduleId,
            date: moment(this.addDate).format(this.backendDateTimeFormat)
          }
        ])
          .then((response) => {
            this.$eventBus.emit('refetchResources')
            this.clearState()
          })
      } else {
        this.$eventBus.emit('refetchResources')
        this.clearState()
      }
    },
    phoneValidate (isValid) {
      this.isPhoneValid = isValid === undefined ? false : isValid
    },
    clearState (closeModal = false) {
      this.firstName = ''
      this.lastName = ''
      this.phone = ''
      this.addDate = moment().format(this.backendDateTimeFormat)
      this.email = null
      this.invite = false
      this.positions = null
      this.addType = 'without_shifts'
      if (closeModal) {
        this.projectId = null
        this.scheduleId = null
        this.scheduleChoices = [
          {
            value: null,
            label: this.$t('None')
          }
        ]
      }
      this.$refs.observer.resetForm.apply()
    }
  }
}
</script>

<style scoped>

</style>
