import en from './en.json'
import ru from './ru.json'
import uk from './uk.json'
import de from './de.json'
import he from './he.json'
import es from './es.json'
import pl from './pl.json'
import el from './el.json'

export default {
  en,
  ru,
  uk,
  de,
  es,
  he,
  pl,
  el
}
