<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t("LOCATIONS_TITLE") }}</span>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col text-right">
            <button
              class="btn btn-primary btn-circle shadow-sm m-1"
              @click="goToCreateLocation"
              type="submit">
              {{ $t('ADD_LOCATION') }}
            </button>
          </div>
        </div>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('Title') }}</th>
            <th>{{ $t('Color') }}</th>
            <th>{{ $t('Address') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="location in locations" :key="location.id">
            <td>
              {{ location.id }}
            </td>
            <td>
              {{ location.title }}
            </td>
            <td>
              <span class="project-color" v-bind:style="{ backgroundColor: location.color }"></span>
            </td>
            <td>
              {{ location.address }}
            </td>
            <td class="text-right">
              <i class="icon-pencil btn-icon text-primary mx-1"
                 @click="editLocation(location.id)"></i>
              <i class="icon-trash btn-icon text-danger mx-1"
                 @click="deleteLocation(location.id)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Locations',

  created () {
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      // this.locations = this.$store.getters.locations
    }).catch(() => {
      // TODO: missing catch
    })
  },

  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    locations () {
      return this.$store.getters.locations.filter(l => !l.hide)
    }
  },

  methods: {
    goToCreateLocation () {
      this.$router.push(`/c/${this.companyId}/location`)
    },

    editLocation (id) {
      // console.log(id)
      this.$router.push(`/c/${this.companyId}/location/${id}`)
    },

    deleteLocation (id) {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteLocation', [this.companyId, id]).then(() => {
          this.toastSuccess(this.$t('Location successfully deleted'))
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        //
      })
    }
  }

}
</script>

<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
