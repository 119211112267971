<template>
    <modal-component
    :id="'AddUser'"
    data-cy="add-employees-component"
    @closeModal="closeModal">
      <template #header>
        <h5 class="modal-title">{{ $t('Add employee')}}</h5>
      </template>
      <template #body>
        <Form ref="observer" as="div">
          <div class="row">
            <div class="col">
              <ui-text-input
                name="first_name"
                :label="$t('First name')"
                :placeholder="$t('First name')"
                v-model="firstName"
                :validation="{required: true, max: 128}"
              />
            </div>
            <div class="col">
              <ui-text-input
                name="last_name"
                :label="$t('Last name')"
                :placeholder="$t('Last name')"
                v-model="lastName"
                :validation="{required: true, max: 128}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ui-select
                name="schedule_id"
                :label="$t('Select a schedule')"
                v-model="scheduleId"
                class="w-100"
                :options="schedulesList"
                :validation="{required: true}"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ui-date-picker
                name="start_date"
                :label="$t('Add users starting from the date')"
                :hint="$t('ADD_USERS_STARTING_FROM_THE_DATE_HINT')"
                v-model="addDate"
                :validation="{required: true}"
                data-cy="employee-lname"
              />
              <span class="mt-2">{{ $t('Users will work starting from a selected date')}}</span>
            </div>
          </div>
        </Form>
      </template>
      <template #footer>
        <div>
          <button
            class="btn btn-success rounded-pill shadow-sm"
            data-cy="add-user-button"
            @click="addUser">
            {{ $t('Add') }}
          </button>
        </div>
      </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin],
  name: 'AddUserModal',

  data () {
    return {
      firstName: null,
      lastName: null,
      identifier: null,
      scheduleId: null,
      addDate: moment().format(this.backendDateTimeFormat)
    }
  },

  props: {
    projectId: Number
  },

  computed: {
    ...mapGetters({
      currentProjectId: 'currentProjectId'
      // schedulesList: 'schedules'
    }),
    schedulesList () {
      const schedules = this.$store.getters.schedules
      return schedules.filter(schedule => {
        return +schedule.project_id === +this.currentProjectId
      })
    }
  },

  created () {
    this.scheduleId = this.schedulesList[0].id
  },

  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      // requestAnimationFrame(() => {
      //   this.$refs.observer.reset()
      // })
      this.$emit('AddUser')
    },

    addUser () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          const data = [
            {
              first_name: this.firstName,
              last_name: this.lastName,
              phone: '',
              email: '',
              project_id: this.currentProjectId,
              identifier: this.identifier
            }
          ]
          this.$store.dispatch('addEmployees', [this.$store.getters.companyId, data]).then(response => {
            this.$eventBus.emit('checkSteps')
            const userId = response[0].id
            const formData = {
              users: [userId],
              add_type: 'without_shifts',
              date_from: moment(this.addDate).format(this.backendDateTimeFormat),
              preview: false
            }
            this.$store.dispatch('addUsers', [this.scheduleId, formData]).then(() => {
              document.getElementById('AddUser').click()
              this.closeModal()
              this.$emit('refetchResources')
            }).catch((err) => {
              this.toastError(this.$i18n?.t(err.response.data.message))
            })
          }).catch((err) => {
            this.toastError(this.$i18n?.t(err.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
