<template>
  <span>
    <el-checkbox-button
      :name="name"
      v-model="innerValue"
      @input="$emit('update:modelValue', innerValue)"
      @change="$emit('update:modelValue', innerValue)"
      @blur="$emit('update:modelValue', innerValue)"
      :data-cy="dataCy ? dataCy : null"
      :disabled="!!disabled"
      :checked="!!checked"
      :border="border"
      :class="{
        'el-checkbox-big': size === 'big'
      }"
    >
      <template v-if="label">
        <div :class="{'col-form-label': false}">
          {{ label }}
          <ui-hint v-if="hint" :content="hint"/>
          <slot></slot>
        </div>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </el-checkbox-button>
  </span>
</template>

<script>
export default {
  name: 'checkbox-button',
  emits: ['update:modelValue'],

  props: {
    name: String,
    value: [Boolean, Array],
    modelValue: [Boolean, Array],
    placeholder: String,
    disabled: Boolean,
    checked: Boolean,
    label: String,
    hint: String,
    size: { type: String, default: 'default' },
    centered: { type: Boolean, default: false },
    dataCy: String,
    border: { type: Boolean, default: false }
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }

}
</script>
<style scoped lang="scss">
.el-checkbox-big {
  :deep(&) .el-checkbox__inner {
    width: 28px;
    height: 28px;
    &::after {
      border: 3px solid #FFF;
      border-left: 0;
      border-top: 0;
      height: 12px;
      left: 10px;
      top: 4px;
    }
  }
}
</style>
