<template>
  <div>
    <div class="row mb-2 mx-auto">
          <span class="title-text">
            {{ $t("SHIFT_ACTIVITY_TEMPLATES_MAIN_TITLE") }}
            <ui-hint :content="$t('SHIFT_ACTIVITY_TEMPLATES_MAIN_TITLE_HINT')" />
          </span>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div>
          <tab-navigation-component :tabs="tabs" />
        </div>
        <div class="row my-2">
          <div class="col title-text">
            {{ $t('SHIFT_ACTIVITY_TEMPLATES') }}
          </div>
          <div class="col-auto my-auto">
            <router-link :to="`/c/${companyId}/shift-activity-template`">
              <button
                  class="btn btn-primary btn-sm btn-circle shadow-sm mx-1"
              >
                {{ $t('CREATE_SHIFT_ACTIVITY_TEMPLATE') }}
              </button>
            </router-link>
          </div>
        </div>
        <el-table
            class="table text-break"
            header-row-class-name="thead-light"
            header-cell-class-name="text-break"
            :data="activityTemplates || []"
            style="width: 100%">
          <el-table-column
              :label="$t('title')"
              cell-class-name="text-break w-100"
              label-class-name="text-break"
              prop="title">
          </el-table-column>
          <el-table-column
              :label="$t('Color')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="color"
          >
            <template #default="scope">
              <span class="project-color" :style="{ backgroundColor: scope.row.color }"></span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Time')"
              cell-class-name="text-break"
              label-class-name="text-break"
          >
            <template #default="scope">
              <span v-if="scope.row.time_from && scope.row.time_to">{{scope.row.time_from}} - {{scope.row.time_to}}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Project')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="project_id"
          >
            <template #default="scope">
              <span v-if="scope.row.project_id">{{ projectName(scope.row.project_id) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Schedule')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="schedule_id"
          >
            <template #default="scope">
              <span v-if="scope.row.schedule_id">{{ scheduleName(scope.row.schedule_id) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Location')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="location_id"
          >
            <template #default="scope">
              <span v-if="scope.row.location_id">{{ locationName(scope.row.location_id) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Position')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="position_id"
          >
            <template #default="scope">
              <span v-if="scope.row.position_id">{{ positionName(scope.row.position_id) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Skills')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="skills"
          >
            <template #default="scope">
              <span v-if="scope.row.skills && scope.row.skills.length > 0">{{ skillsName(scope.row.skills) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              :label="$t('Marks')"
              cell-class-name="text-break"
              label-class-name="text-break"
              prop="marks"
          >
            <template #default="scope">
              <span v-if="scope.row.marks && scope.row.marks.length > 0">{{ marksName(scope.row.marks) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              :label="$t('Operations')"
              width="120">
            <template #default="scope">
              <router-link :to="`/c/${companyId}/shift-activity-template/${scope.row.id}`">
                <i class="icon-pencil text-primary btn-icon mx-1" />
              </router-link>
              <i class="icon-trash text-danger btn-icon mx-1" @click="drop(scope.row.id)" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ActivityTemplates',
  mixins: [ToastMixin],
  components: { TabNavigationComponent },
  data () {
    return {
      tabs: []
    }
  },
  created () {
    this.tabs = [
      {
        link: `/c/${this.companyId}/shift-activities`,
        title: 'Shift activities calendar',
        isActive: false
      },
      {
        link: `/c/${this.companyId}/shifts-activity-templates`,
        title: 'Shift activity templates',
        isActive: true
      }
    ]
    this.$store.dispatch('getProjectsByCompany', this.companyId)
    this.$store.dispatch('getSchedules', this.companyId)
    this.$store.dispatch('getActivityTemplates', this.companyId).catch(err => {
      this.toastError(this.$i18n?.t(err.response.data.message))
    })
    this.$store.dispatch('getLocations', this.companyId)
    this.$store.dispatch('getPositions', this.companyId)
    this.$store.dispatch('getSkills', this.companyId)
    this.$store.dispatch('getMarksByCompany', this.companyId)
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    activityTemplates () {
      return [...this.$store.getters.activityTemplates].sort((a, b) => {
        if (a.title > b.title) {
          return 1
        }
        if (a.title < b.title) {
          return -1
        }
        return 0
      })
    }
  },
  methods: {
    projectName (projectId) {
      return this.$store.getters.projectsIndexed[projectId] ? this.$store.getters.projectsIndexed[projectId].name : this.$t('THE_PROJECT_WAS_DELETED')
    },
    scheduleName (scheduleId) {
      return this.$store.getters.schedulesIndexed[scheduleId] ? this.$store.getters.schedulesIndexed[scheduleId].name : this.$t('THE_SCHEDULE_WAS_DELETED')
    },
    locationName (locationId) {
      return this.$store.getters.locations.filter(i => +i.id === +locationId).length > 0 ? this.$store.getters.locations.filter(i => +i.id === +locationId)[0].title : this.$t('THE_LOCATION_WAS_DELETED')
    },
    positionName (positionId) {
      return this.$store.getters.positionById(positionId) ? this.$store.getters.positionById(positionId).title : this.$t('THE_POSITION_WAS_DELETED')
    },
    marksName (marks) {
      const names = []
      marks.forEach(markId => {
        const mark = this.$store.getters.companyMarks.filter(item => +item.id === +markId)[0]
        if (mark) {
          names.push(mark.name)
        }
      })
      return names.join(', ')
    },
    skillsName (skills) {
      const names = []
      skills.forEach(skillId => {
        const skill = this.$store.getters.skills.filter(item => +item.id === +skillId)
        // console.log('skill', { ...skill })
        if (skill && skill[0]) {
          names.push(skill[0].title)
        }
      })
      return names.join(', ')
    },
    drop (id) {
      this.$confirm(this.$t('This will permanently delete the template. Continue?'), this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.$store.dispatch('deleteActivityTemplates', [this.companyId, id]).then(_ => {
          this.toastSuccess(this.$t('Delete completed'))
        }).catch((e) => {
          this.toastError(this.$i18n?.t(e.response.data.message))
        })
      }).catch(() => {
        this.toastWarning(this.$t('Delete canceled'))
      })
    }
  }
}
</script>
<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
