import { createI18n } from 'vue-i18n'
import index from './store/index'
import axios from 'axios'
import { setLocale } from '@vee-validate/i18n'

export function getCurrentLanguage () {
  return process.env.VUE_APP_I18N_LOCALE || 'en'
  /*
  if (this.$store.state.lang) {
    return this.$store.state.lang
  } else {
    return this.$store.getters.locale
  }
  */
}

export const loadedLanguages = [] // our default language that is preloaded

function getDirection (lang) {
  if (['he', 'ar'].includes(lang)) {
    return 'rtl'
  }
  return 'ltr'
}

export function setI18nLanguage (i18n, lang) {
  setLocale(lang)
  if (i18n.mode === 'legacy') {
    i18n.global.locale = lang
  } else {
    i18n.global.locale.value = lang
  }
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  document.querySelector('html').setAttribute('dir', getDirection(lang))
  document.querySelector('body').setAttribute('dir', getDirection(lang))

  return lang
}

export function loadLanguageAsync (lang) {
  if (!lang) {
    lang = getCurrentLanguage()
  }
  if (!loadedLanguages.includes(lang)) {
    loadedLanguages.push(lang)
    return axios.get(process.env.VUE_APP_ROOT_API + 'languages/' + lang + '/translations').then(response => {
      i18n.global.setLocaleMessage(lang, response.data)
      return setI18nLanguage(i18n, lang)
    }).catch(() => {
      loadedLanguages.splice(-1, 1)
    })
  }

  return Promise.resolve(setI18nLanguage(i18n, lang))
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  warnHtmlInMessage: 'off',
  messages: {}
})

if (localStorage.getItem('api_key')) {
  i18n.missing = function (lang, missingPhrase) {
    if (process.env.VUE_APP_I18_SEND_MISSING === 'true' && loadedLanguages.includes(lang)) {
      const responseData = {
        lang: lang,
        missing_phrase: missingPhrase,
        error_type: 'missing_translation'
      }
      index.dispatch('setLog', responseData).then({})
    }
  }
}

export { i18n }
