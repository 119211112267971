<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('NEW_TASKS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
            <template v-if="newTasks.length > 0">
              <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
                <div v-for="item in newTasks" :key="item.id  + '__new'" class="mb-2">
                  <div class="font-weight-bold">
                    {{item.title}}
                  </div>
                  <div v-if="item.employee" class="small">
                    <span class="font-weight-bold">{{$t('Employee name')}}:</span> {{item.employee?.full_name}}
                  </div>
                  <div v-if="item.client" class="small">
                    <span class="font-weight-bold">{{$t('Client name')}}:</span> {{ item.client?.name }}
                  </div>
                  <div class="small">
                    <span class="font-weight-bold">{{ $t('Scheduled time') }}:</span> {{ this.scheduledTimeToShow(item.scheduled_time) }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center h-100">
                <div class="my-5">
                  {{$t('NO_NEW_TASKS')}}
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('ACTIVE_TASKS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
            <template v-if="activeTasks.length > 0">
              <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
                <div v-for="item in activeTasks" :key="item.id  + '__active'" class="mb-2">
                  <div class="font-weight-bold">
                    {{item.title}}
                  </div>
                  <div v-if="item.employee" class="small">
                    <span class="font-weight-bold">{{$t('Employee name')}}:</span> {{item.employee?.full_name}}
                  </div>
                  <div v-if="item.client" class="small">
                    <span class="font-weight-bold">{{$t('Client name')}}:</span> {{ item.client?.name }}
                  </div>
                  <div class="small">
                    <span class="font-weight-bold">{{ $t('Scheduled time') }}:</span> {{ this.scheduledTimeToShow(item.scheduled_time) }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center h-100">
                <div class="my-5">
                  {{$t('NO_ACTIVE_TASKS')}}
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('COMPLETED_TASKS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
            <template v-if="completedTasks.length > 0">
              <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
                <div v-for="item in completedTasks" :key="item.id  + '__completed'" class="mb-2">
                  <div class="font-weight-bold">
                    {{item.title}}
                  </div>
                  <div v-if="item.employee" class="small">
                    <span class="font-weight-bold">{{$t('Employee name')}}:</span> {{item.employee?.full_name}}
                  </div>
                  <div v-if="item.client" class="small">
                    <span class="font-weight-bold">{{$t('Client name')}}:</span> {{ item.client?.name }}
                  </div>
                  <div class="small">
                    <span class="font-weight-bold">{{ $t('Scheduled time') }}:</span> {{ this.scheduledTimeToShow(item.scheduled_time) }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center h-100">
                <div class="my-5">
                  {{$t('NO_COMPLETED_TASKS')}}
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card m-1" style="height: 300px!important;">
        <div class="card-body p-2">
          <div class="row">
            <div class="font-weight-bold col mt-2">{{$t('PROBLEMATIC_TASKS')}}</div>
            <div class="col-auto mt-2">
              <i @click="init" class="icon-refresh-cw btn-icon text-primary mx-2"></i>
            </div>
          </div>
            <template v-if="problematicTasks.length > 0">
              <div class="overflow-auto mt-2 pt-2" style="max-height: 220px!important;">
                <div v-for="item in problematicTasks" :key="item.id  + '__completed'" class="mb-2">
                  <div class="font-weight-bold">
                    {{item.title}}
                  </div>
                  <div v-if="item.employee" class="small">
                    <span class="font-weight-bold">{{$t('Employee name')}}:</span> {{item.employee?.full_name}}
                  </div>
                  <div v-if="item.client" class="small">
                    <span class="font-weight-bold">{{$t('Client name')}}:</span> {{ item.client?.name }}
                  </div>
                  <div class="small">
                    <span class="font-weight-bold">{{ $t('Scheduled time') }}:</span> {{ this.scheduledTimeToShow(item.scheduled_time) }}
                  </div>
                  <div class="small">
                    <span class="font-weight-bold">{{ $t('Was started') }}:</span> {{ this.scheduledTimeToShow(item.logs[0].created_at) }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center h-100">
                <div class="my-5">
                  {{$t('NO_PROBLEMATIC_TASKS')}}
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'TasksStatistic',
  mixins: [momentMixin],
  data () {
    return {}
  },
  props: {
    newTasks: Array,
    activeTasks: Array,
    completedTasks: Array,
    problematicTasks: Array
  },
  computed: {
    ...mapGetters({
      profileTimeZone: 'profileTimeZone'
    })
  },
  methods: {
    init () {
      this.$emit('init')
    },
    scheduledTimeToShow (time) {
      if (!time) {
        return this.$t('NO_SCHEDULED_TIME')
      }
      return this.fromZoneToZone(time, 'UTC', this.profileTimeZone).format(this.localeDateTimeFormat)
    }
  }
}
</script>
<style scoped>

</style>
