<template>
  <ui-input-label v-if="label" :label="label" :hint="hint"/>
  <el-date-picker
    type="datetime"
    v-model="innerValue"
    @change="(val) => $emit('change', val)"
    class="w-100"
    size="large"
    :teleported="false"
    :format="dateFormat"
    :picker-options="pickerSettings"
    :clearable="!!clearable"
    :disabled="!!disabled"
    :class="{'is-invalid': isInvalid}"
    :placeholder="placeholder"
  />
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ errorMessage }"
    :rules="validation"
    as="div"
  >
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1" />
  </Field>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'datetime-picker',
  mixins: [momentMixin],
  emits: ['update:modelValue', 'change'],
  data () {
    return {
      isInvalid: false,
      observer: null
    }
  },
  props: {
    name: String,
    validation: [String, Object],
    value: [Date, String, Object],
    modelValue: [Date, String, Object],
    clearable: Boolean,
    disabled: Boolean,
    label: String,
    hint: String,
    pickerOptions: Object,
    placeholder: String
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    dateFormat () {
      return this.localeDateTimeFormatElementUi
    },
    pickerSettings () {
      if (this.pickerOptions) {
        return this.pickerOptions
      }
      if (this.$store.getters.company && this.$store.getters.company.salary && this.$store.getters.company.salary.week_start) {
        return { firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1 }
      }
      return { firstDayOfWeek: 1 }
    }
  },
  mounted () {
    this.handleObserver()
  },
  beforeUnmount () {
    this.observer.disconnect()
  },
  methods: {
    handleObserver () {
      this.observer = new MutationObserver((mutations, observer) => {
        // console.log(mutations, observer)
        const parent = document.getElementsByName(this.name)
        if (parent.length > 0) {
          const el = parent[0].getElementsByClassName('el-form-item__error')
          this.isInvalid = el.length !== 0
        }
      })
      const node = document.getElementsByName(this.name)[0]
      if (node) {
        this.observer.observe(node, {
          subtree: true,
          attributes: true
        })
      } else {
        setTimeout(() => {
          // console.log('up')
          this.handleObserver()
        }, 1000)
      }
    }
  }

}
</script>

<style scoped lang="scss">

</style>
