<template>
  <el-tooltip :show-after="openDelay" :hide-after="hideDelay" effect="light" placement="top" class="w-100">
    <template #content>
      <div>
        {{this.name}} {{this.start.format(this.localeDateTimeFormat)}} - {{this.end.format(this.localeDateTimeFormat)}}
      </div>
      <div>
        {{this.description}}
      </div>
    </template>
    <div class="fc-content py-1 text-center" style="cursor: pointer" data-toggle="modal" data-target="#AddHoliday">
      <i class="icon-star" style="font-size: 1.8em"></i>
    </div>
  </el-tooltip>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'HolidayComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 1000,
      hideDelay: 4000,
      allow_shifts: false,
      allow_timeoff: false,
      name: null,
      description: null,
      start: moment(),
      end: moment()
    }
  },
  props: { event: Object },
  mounted () {
    this.name = this.event.extendedProps.name
    this.description = this.event.extendedProps.description
    const start = new Date(this.event.start)
    this.start = moment.parseZone(start)
    const end = new Date(this.event.end)
    this.end = moment.parseZone(end)
  }
}
</script>

<style scoped>

</style>
