<template>
  <el-tooltip
    :show-after="1000"
    :hide-after="4000"
    effect="light"
    placement="top"
    class="w-100">
    <template #content>
      <div class="fc-content p-1 text-truncate font-weight-bold"
           style="cursor: pointer"
           data-toggle="modal"
           :data-target="showModal ? '#UserRequestCalendarModal' : ''">
        {{event.extendedProps.requestDuration === 'all_day' ? '' : `${event.extendedProps.startTimeRequest} - ${event.extendedProps.endTimeRequest}`}}
        {{translate.t(type)}} <span v-if="event.extendedProps.reasonData">({{ event.extendedProps.reasonData.title }})</span>
        <span v-if="event.extendedProps.isPaid === 1" class="mx-1">{{translate.t('Paid')}} </span>
        <span v-else class="mx-1">{{translate.t('Unpaid')}}</span>
        ({{event.extendedProps.requestDuration === 'all_day' ? `${translate.t('All day')} ${event.extendedProps.startDate} - ${endDate}` : translate.t('Part of a day')}})
      </div>
    </template>
    <div class="fc-content py-1 text-truncate font-weight-bold"
         style="cursor: pointer"
         data-toggle="modal"
         :data-target="showModal ? '#UserRequestCalendarModal' : ''">
      <template v-if="viewType === 'resourceTimeMonth' && monthDisplay === 'compact'">
        <div class="text-center">
          <template v-if="type === 'Sick day'">
            <i class="icon-device_thermostat text-white" style="font-size: 18px"></i>
          </template>
          <template v-if="type === 'Vacation'">
            <i class="icon-wb_sunny text-white" style="font-size: 18px"></i>
          </template>
          <template v-if="type === 'Holiday'">
            <i class="icon-celebration text-white" style="font-size: 18px"></i>
          </template>
        </div>
      </template>
      <template v-else>
        {{event.extendedProps.requestDuration === 'all_day' ? '' : `${event.extendedProps.startTimeRequest} - ${event.extendedProps.endTimeRequest}`}}
        {{translate.t(type)}} <span v-if="event.extendedProps.reasonData">({{ event.extendedProps.reasonData.title }})</span>
        <span v-if="event.extendedProps.isPaid === 1" class="mx-1">{{translate.t('Paid')}}</span>
        <span v-else class="mx-1">{{translate.t('Unpaid')}}</span>
        ({{event.extendedProps.requestDuration === 'all_day' ? `${translate.t('All day')} ${event.extendedProps.startDate} - ${endDate}` : translate.t('Part of a day')}})
      </template>
    </div>
  </el-tooltip>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'
// import moment from 'moment'

export default {
  name: 'UserRequestComponent',
  mixins: [momentMixin],
  props: { event: Object, tz: String, translate: Object, monthDisplay: String, view: Object },
  computed: {
    viewType () {
      return this.view.type
    },
    type () {
      if (this.event.extendedProps.reason === 'sick') {
        return 'Sick day'
      }
      if (this.event.extendedProps.reason === 'vacation') {
        return 'Vacation'
      }
      return 'Holiday'
    },
    endDate () {
      // console.log(1, this.event.extendedProps.endDate)
      // console.log(2, moment(this.event.extendedProps.endDate, this.localeDateFormat).add(-1, 'day').format(this.localeDateFormat))
      return moment(this.event.extendedProps.endDate, this.localeDateFormat).add(-1, 'day').format(this.localeDateFormat)
    },
    projectsWhereUserIsManager () {
      const projects = []
      this.$store.getters.projectsWhereUserIsManager.forEach(item => {
        projects.push(item.id)
      })
      return projects
    },
    projectId () {
      return this.$store.getters.currentProjectId
    },
    showModal () {
      if (this.isEmployeeHasPermission('get-requests-admin')) {
        return true
      }
      if (this.$store.getters.currentCompanyUserId === this.event.extendedProps.employeeId) {
        return true
      }
      if (this.isEmployeeHasPermission('get-requests-manager') &&
        this.projectsWhereUserIsManager.includes(this.projectId)) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
