import { HTTP } from '@/api/http_utils'

const UPDATE_HOLIDAYS_LIST = 'UPDATE_HOLIDAYS_LIST'
const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY'
const DELETE_HOLIDAY = 'DELETE_HOLIDAY'

const state = {
  holidaysIndexed: {}
}

let promises = {}

const getters = {
  holidays: state => Object.values(state.holidaysIndexed),
  holidaysIndexed: state => state.holidaysIndexed
}

const mutations = {
  [UPDATE_HOLIDAYS_LIST] (state, data) {
    const holidaysById = {}
    data.forEach(item => {
      holidaysById[item.id] = item
    })
    state.holidaysIndexed = holidaysById
  },
  [UPDATE_HOLIDAY] (state, payload) {
    state.holidaysIndexed[payload.id] = payload
  },
  [DELETE_HOLIDAY] (state, holidayId) {
    delete state.holidaysIndexed[holidayId]
  }
}

const actions = {
  clearHolidaysCache () {
    promises = {}
  },
  updateHolidaysList ({ commit }, payload) {
    commit(UPDATE_HOLIDAYS_LIST, payload)
  },
  getHolidays ({ commit }, [companyId, queryHolidays]) {
    const promiseKey = `/companies/${companyId}/holidays${queryHolidays}`

    if (promises[promiseKey]) {
      console.error('repeating requests holidays')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/holidays${queryHolidays}`).then(response => {
        commit(UPDATE_HOLIDAYS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getHoliday ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/holidays/${id}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createHoliday ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/holidays`, payload).then(response => {
        commit(UPDATE_HOLIDAY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editHoliday ({ commit }, [companyId, holidayId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/holidays/${holidayId}`, payload).then(response => {
        commit(UPDATE_HOLIDAY, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteHoliday ({ commit }, [companyId, holidayId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/holidays/${holidayId}`).then(response => {
        commit(DELETE_HOLIDAY, holidayId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
