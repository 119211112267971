export default {
  data () {
    return {
      fcWeek: {
        sun: 0,
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6
      },
      apiWeek: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
    }
  }
}
