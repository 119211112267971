<template>
  <modal-component :id="'ClickEventModal'" @closeModal="closeModal" :modalClass="'modal-xl'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Shift actions')}}</h5>
    </template>
    <template #body>
      <div>
        <template v-if="warning">
          <div class="text-center">
            <h5 class="text-center">{{ $t('Are you sure?') }}</h5>
            <h6 class="text-center text-muted">{{ $t('You want to take action towards a shift that has already started or ended.') }}</h6>
            <button class="btn btn-outline-primary mx-1" data-dismiss="modal" aria-label="Close" @click="closeModal">{{$t('Cancel')}}</button>
            <button class="btn btn-success mx-1" @click="warningToggle">{{$t('Ok')}}</button>
          </div>
        </template>
        <template v-else>
          <shift-request-warning-component
            :shiftInfo="shiftInfo"
            :shiftRequest="shiftRequest"
            :exchangeRequest="exchangeRequest"
            :weekOvertime="weekOvertime"
            :weekOvertimeEmployeeToExchange="weekOvertimeEmployeeToExchange"
          />
          <div class="row">
            <div class="col-sm-4 text-truncate">
              <div class="row">
                <div class="nav nav-tabs col-12" id="v-pills-tab" :class="{
                'flex-column': !isMobile,
                'text-center': isMobile,
                'pb-2': !isMobile
              }">
                  <a class="nav-link p-1 bg-modal"
                     v-if="isEmployeeHasPermission('create-shift-admin') ||
                    rolesByProjects[currentProjectId] === 'manager'"
                     id="v-pills-shift-tab"
                     data-toggle="pill"
                     @click="setActiveTab('v-pills-shift-tab')"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile,
                     'active': isEmployeeHasPermission('create-shift')
                   }"
                     href="#v-pills-shift"
                     role="tab"
                     aria-controls="v-pills-shift"
                     aria-selected="true">
                  <span>
                    <i class="icon-edit btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('SHIFT_SHOW_EVENT_MODAL')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     id="v-pills-action-tab"
                     @click="setActiveTab('v-pills-action-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile,
                     'active': !isEmployeeHasPermission('create-shift-admin') &&
                      (rolesByProjects[currentProjectId] === 'user')
                   }"
                     href="#v-pills-action"
                     role="tab"
                     aria-controls="v-pills-action"
                     aria-selected="false">
                  <span>
                    <i class="icon-sliders btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('SHIFT_ACTION_EVENT_MODAL')}}
                  </span>
                  </a>
                  <a v-if="!isRoleUser || shift.extendedProps.employee_id === currentCompanyUserId" class="nav-link p-1 bg-modal"
                     id="v-pills-breaks-tab"
                     @click="setActiveTab('v-pills-breaks-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile,
                     'active': false
                   }"
                     href="#v-pills-breaks"
                     role="tab"
                     aria-controls="v-pills-action"
                     aria-selected="false">
                  <span>
                    <i class="icon-coffee btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('BREAK_ACTION_EVENT_MODAL')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     v-if="showTasks"
                     id="v-pills-tasks-tab"
                     @click="setActiveTab('v-pills-tasks-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile,
                     'active': false
                   }"
                     href="#v-pills-tasks"
                     role="tab"
                     aria-controls="v-pills-tasks"
                     aria-selected="false">
                  <span>
                    <i class="icon-clipboard btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('SHIFT_TASKS_EVENT_MODAL')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     id="v-pills-bonus-tab"
                     @click="setActiveTab('v-pills-bonus-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile,
                     'active': false
                   }"
                     href="#v-pills-bonus"
                     role="tab"
                     aria-controls="v-pills-bonus"
                     aria-selected="false">
                  <span>
                    <i class="icon-smile btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('SHIFT_BONUS_MODAL')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     v-if="(isEmployeeHasPermission('create-shift-admin') ||
                    rolesByProjects[currentProjectId] === 'manager')"
                     id="v-pills-log-tab"
                     @click="setActiveTab('v-pills-log-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile
                   }"
                     href="#v-pills-log"
                     role="tab"
                     aria-controls="v-pills-log"
                     aria-selected="false">
                  <span>
                    <i class="icon-align-justify btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('LOGS_EVENT_MODAL')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     v-if="(isEmployeeHasPermission('create-shift-admin') ||
                    rolesByProjects[currentProjectId] === 'manager')"
                     id="v-pills-log-tab"
                     @click="setActiveTab('v-pills-timesheet-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile
                   }"
                     href="#v-pills-timesheet"
                     role="tab"
                     aria-controls="v-pills-log"
                     aria-selected="false">
                  <span>
                    <i class="icon-clock btn-icon mx-1"></i>
                  </span>
                    <span v-if="!isMobile">
                    {{$t('TIMESHEET_LOGS')}}
                  </span>
                  </a>
                  <a class="nav-link p-1 bg-modal"
                     v-if="(this.isEmployeeHasPermission('view-geolocation'))"
                     id="v-pills-log-tab"
                     @click="setActiveTab('v-pills-geolocation-tab')"
                     data-toggle="pill"
                     :class="{
                     'w-25': isMobile,
                     'tabs-mobile': isMobile,
                     'tabs-desktop': !isMobile
                   }"
                     href="#v-pills-geolocation"
                     role="tab"
                     aria-controls="v-pills-log"
                     aria-selected="false">
                  <span>
                    <i class="icon-map-pin btn-icon mx-1" />
                  </span>
                    <span v-if="!isMobile">
                    {{$t('GEOLOCATION_MODAL')}}
                  </span>
                  </a>
                </div>
                <div v-if="isEmployeeHasPermission('create-shift-admin') ||
               rolesByProjects[currentProjectId] === 'manager' ||
                showSalary" class="mt-2 p-0 col-12 order-first order-sm-last">
                  <shift-statistic-component
                    :isMobile="isMobile"
                    :shiftInfo="shiftInfo"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-8" :class="{'mt-3': isMobile}" style="background-color: #f7f7f7">
              <div class="tab-content" id="v-pills-tabContent">
                <div
                  class="tab-pane fade show"
                  :class="{
                     'active': isEmployeeHasPermission('update-shift-admin') ||
                      rolesByProjects[currentProjectId] === 'manager'
                   }"
                  id="v-pills-shift"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <shift-edit-component
                    v-if="shiftInfo.id"
                    :shiftInfo="shiftInfo"
                    :shift="shift"
                    :shiftRequest="shiftRequest"
                    :schedulesById="schedulesById"
                    :locations="locations"
                    :currentProjectData="currentProjectData"
                    :nameToExchange="nameToExchange"
                    :nameFromExchange="nameFromExchange"
                    :weekOvertimeEmployeeToExchange="weekOvertimeEmployeeToExchange"
                    :weekOvertime="weekOvertime"
                    :closeModal="closeModal"
                    @updateOvertimeCheck="updateOvertimeCheck()"
                  />
                </div>
                <div
                  class="tab-pane fade show"
                  :class="{
                     'active': false
                   }"
                  id="v-pills-bonus"
                  role="tabpanel"
                  aria-labelledby="v-pills-bonus-tab"
                >
                  <bonus-penalty-component
                    v-if="shiftInfo.id && activeTab === 'v-pills-bonus-tab'"
                    @updateBonuses="updateBonuses"
                    :isEditable="isEmployeeHasPermission('get-bonuses-by-company-admin') ||
                   rolesByProjects[currentProjectId] === 'manager'"
                    :shift="shift"
                    :shiftInfo="shiftInfo">
                  </bonus-penalty-component>
                </div>
                <div
                  class="tab-pane fade show"
                  :class="{
                     'active': false
                   }"
                  id="v-pills-breaks"
                  role="tabpanel"
                  aria-labelledby="v-pills-breaks-tab"
                >
                  <add-breaks-component
                    v-if="shiftInfo.id"
                    :shiftInfo="shiftInfo"
                  />
                </div>
                <div
                  class="tab-pane mt-2"
                  :class="{
                     'active': !isEmployeeHasPermission('update-shift-admin') &&
                      (rolesByProjects[currentProjectId] === 'user')
                   }"
                  id="v-pills-action"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <shift-action-component
                    :shiftInfo="shiftInfo"
                    :shift="shift"
                    :schedulesById="schedulesById"
                    :locations="locations"
                    :currentProjectData="currentProjectData"
                    :closeModal="closeModal"
                    :shiftRequest="shiftRequest"
                    @updateOvertimeCheck="updateOvertimeCheck()"
                  />
                </div>
                <div
                  class="tab-pane mt-2"
                  :class="{
                     'active': false
                   }"
                  id="v-pills-tasks"
                  role="tabpanel"
                  aria-labelledby="v-pills-tasks-tab"
                >
                  <TasksComponent
                    v-if="activeTab === 'v-pills-tasks-tab'"
                    :shiftDuration="shiftDuration"
                    :shiftId="+shift.id"
                    :shiftInfo="shiftInfo"
                    @updateTask="updateTask"
                    @activeTasks="activeTasks"
                  />
                </div>
                <div
                  class="tab-pane fade mt-2"
                  v-if="(isEmployeeHasPermission('update-shift-admin') ||
                 rolesByProjects[currentProjectId] === 'manager')"
                  id="v-pills-log"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab">
                  <shift-log-component
                    v-if="activeTab === 'v-pills-log-tab'"
                    :shift="shift"
                  />
                </div>
                <div
                  class="tab-pane fade mt-2"
                  v-if="(isEmployeeHasPermission('update-shift-admin') ||
                 rolesByProjects[currentProjectId] === 'manager')"
                  id="v-pills-timesheet"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab">
                  <TimesheetLogComponent
                    v-if="activeTab === 'v-pills-timesheet-tab'"
                    :shift="shiftInfo"
                  />
                </div>
                <div
                  class="tab-pane fade mt-2"
                  v-if="this.isEmployeeHasPermission('view-geolocation')"
                  id="v-pills-geolocation"
                  role="tabpanel"
                  aria-labelledby="v-pills-geolocation">
                  <EmployeeGeolocationComponent
                    v-if="activeTab === 'v-pills-geolocation-tab'"
                    :color="shiftInfo.color"
                    :geolocations="geolocations"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #footer>
      <div></div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastMixin from '@/mixins/ToastMixin'
import momentMixin from '@/mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import mixinColors from '@/mixins/mixinColors'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import BonusPenaltyComponent from '@/components/Schedule/CompanyScheduleComponents/Components/BonusPenaltyComponent'
import AddBreaksComponent from '../Components/AddBreaksComponent'
import TasksComponent from '@/components/Schedule/CompanyScheduleComponents/Components/TasksComponent'
import ShiftActionComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftActionComponent'
import ShiftEditComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftEditComponent'
import ShiftLogComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftLogComponent'
import ShiftRequestWarningComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftRequestWarningComponent'
import ShiftStatisticComponent from '@/components/Schedule/CompanyScheduleComponents/Components/ShiftStatisticComponent'
import EmployeeGeolocationComponent from '@/components/Schedule/CompanyScheduleComponents/Components/EmployeeGeolocationComponent'
import TimesheetLogComponent from '@/components/Schedule/CompanyScheduleComponents/Components/TimesheetLogComponent.vue'

export default {
  name: 'ClickEventModal',
  mixins: [
    ToastMixin,
    momentMixin,
    mixinColorsMarks,
    mixinColors,
    errorMixin,
    mixinWindowWidthWatch
  ],

  components: {
    TimesheetLogComponent,
    EmployeeGeolocationComponent,
    ShiftStatisticComponent,
    ShiftRequestWarningComponent,
    ShiftLogComponent,
    ShiftEditComponent,
    ShiftActionComponent,
    TasksComponent,
    AddBreaksComponent,
    BonusPenaltyComponent,
    ModalComponent: ModalComponent
  },
  data () {
    return {
      activeTab: 'v-pills-shift-tab',
      shiftInfo: {},
      startDateTime: null,
      endDateTime: null,
      exchangeRequest: {
        name: null,
        from: null,
        to: null,
        status: null
      },
      shiftDuration: 0,
      weekOvertime: {
        is_overtime: false,
        warning: null,
        week_overtime: 0,
        week_overtime_control_type: 'allow_overtime',
        week_quota: 40,
        week_working_hours: 0
      },
      weekOvertimeEmployeeToExchange: {
        is_overtime: false,
        warning: null,
        week_overtime: 0,
        week_overtime_control_type: 'allow_overtime',
        week_quota: 40,
        week_working_hours: 0
      },
      nameToExchange: '',
      nameFromExchange: '',
      shiftRequest: null,
      locations: [],
      showWarning: true,
      geolocations: null
    }
  },
  props: {
    shift: Object,
    currentProjectData: Object,
    schedulesById: Object
  },
  created () {
    // console.log(2, this.shift.extendedProps.employee_id, this.currentCompanyUserId)
    this.shiftRequest = null
    this.startDateTime = this.shift.start
    this.endDateTime = this.shift.end
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.locations = response
        .filter(item => +item.id === (this.shift.extendedProps.location && +this.shift.extendedProps.location.id) || !item.hide)
        .map(location => {
          return { id: location.id, name: location.title }
        })
      // console.log(this.locations)
    })
    this.$store.dispatch('getShift', this.shift.id).then(shift => {
      this.activeTab = this.isEmployeeHasPermission('get-shifts-admin') ||
      this.rolesByProjects[this.currentProjectId] === 'manager'
        ? 'v-pills-shift-tab'
        : 'v-pills-action-tab'
      this.shiftDuration = shift.duration
      this.shiftInfo = shift
      this.geolocations = shift.geolocations
      this.shiftInfo.rate = this.shiftInfo.rate ? Math.round((this.shiftInfo.rate) * 100) / 100 : 0
      this.updateOvertimeCheck()
      this.exchangeRequest = {
        name: null,
        from: null,
        to: null,
        status: null
      }
      if (this.shiftInfo.request_id) {
        this.$store.dispatch('getRequest', [this.$store.getters.companyId, this.shiftInfo.request_id]).then(response => {
          this.shiftRequest = response
          // console.log(123, response)
          if (response.subtype === 'exchange') {
            this.nameToExchange = response.shift.employee.full_name
            this.nameFromExchange = response.shift_from.employee.full_name
            this.updateExchangeOvertimeCheck(response.shift_from, response.shift)
            const name = +response.data.shift_id === +this.shift.id ? response.data.user_from_name : response.data.user_name
            const dateFrom = +response.data.shift_id === +this.shift.id
              ? this.fromZoneToZone(response.shift_from.time_from, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
              : this.fromZoneToZone(response.shift.time_from, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
            const dateTo = +response.data.shift_id === +this.shift.id
              ? this.fromZoneToZone(response.shift_from.time_to, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
              : this.fromZoneToZone(response.shift.time_to, 'UTC', this.$store.getters.actualTZ).format(this.localeDateTimeFormat)
            this.exchangeRequest = {
              name: name,
              status: response.status,
              from: dateFrom,
              to: dateTo
            }
          }
        }).catch(() => {})
      }
    }).catch(e => {})
  },
  computed: {
    showTasks () {
      const isManagerInCurrentProject = this.rolesByProjects[this.currentProjectId] === 'manager'
      const employeePositions = this.$store.getters.employeeById(this.$store.getters.company.employee_id)?.positions
      const isEmployeeHasAllowedPosition = employeePositions
        ? employeePositions.filter(({ id }) => this.taskSettings?.positions_list?.includes(id)).length > 0
        : false

      if (this.isEmployeeHasPermission('get-tasks-admin')) {
        return true
      }

      if (isManagerInCurrentProject && ['gg_user', 'gg_manager'].includes(this.taskSettings?.min_role)) {
        return true
      }

      return !!(this.isEmployeeHasPermission('get-tasks-user') &&
        this.taskSettings?.min_role === 'gg_user' &&
        (this.taskSettings?.positions_list.length === 0 || isEmployeeHasAllowedPosition))
    },
    rolesByProjects () {
      return this.$store.getters.rolesByProjects || {}
    },
    isRoleUser () {
      return !this.isEmployeeHasPermission('get-shifts-admin') &&
        !(this.rolesByProjects[this.currentProjectId] === 'manager')
    },
    breakTime () {
      return this.shiftInfo.break_time
    },
    warning () {
      let value = false
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 8) { // базовый модуль
          if (!Array.isArray(module.settings)) {
            value = this.shift.isInPast && !!module.settings.should_warning_shift_in_past
          } else {
            value = this.shift.isInPast
          }
        }
      })
      return value && this.showWarning
    },
    companyId () {
      return this.$store.getters.companyId
    },
    showSalary () {
      // console.log(45, this.$store.getters.company)
      if (this.$store.getters.company && this.$store.getters.company.salary) {
        return this.$store.getters.company.salary.show_salary_to_users
      }
      return false
    },
    ...mapGetters({
      currentProjectId: 'currentProjectId',
      preloadUsers: 'employeesIndexed',
      projectMarks: 'projectMarks',
      ModulesByCompany: 'ModulesByCompany',
      currentCompanyUserId: 'currentCompanyUserId'
    })
  },
  methods: {
    updateTask (selectedTasks) {
      this.shiftInfo.tasks = selectedTasks
    },
    setActiveTab (tab) {
      this.activeTab = tab
    },
    activeTasks () {
      document.getElementById('ClickEventModal').click()
      this.closeModal()
    },
    updateBonuses (bonuses) {
      this.shiftInfo.bonuses = bonuses
      this.$store.commit('SHIFT_LOG', null)
    },
    updateExchangeOvertimeCheck (shiftFrom, shiftTo) {
      const query = `shift_id=${shiftTo.id}&employee_id=${shiftTo.employee_id}&time_from=${shiftFrom.time_from}&time_to=${shiftFrom.time_to}&break_time=${+shiftFrom.break_time}`
      this.$store.dispatch('isWeekOvertime', [+shiftFrom.schedule_id, query]).then(result => {
        this.weekOvertimeEmployeeToExchange = result
      })
    },
    updateOvertimeCheck () {
      // console.log(this.shift, this.shiftInfo)
      if (this.startDateTime > this.endDateTime) {
        this.endDateTime = this.startDateTime
      }
      this.$store.dispatch('isWeekOvertime', this.weekOvertimeCheckParams()).then(result => {
        this.weekOvertime = result
        // console.log(result)
      })
    },
    weekOvertimeCheckParams () {
      const from = this.fromZoneToZone(this.startDateTime, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(this.endDateTime, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const query = `employee_id=${this.shiftInfo.employee_id}&time_from=${from}&time_to=${to}&shift_id=${+this.shiftInfo.id}&break_time=${+this.breakTime}`
      return [+this.shift.extendedProps.schedule_id, query]
    },
    closeModal () {
      // console.log(1)
      this.$emit('closeClickEventModal', this.shift)
      this.$store.commit('SHIFT_LOG', null)
      this.$store.commit('SHIFT_BONUS', null)
      this.$store.commit('TASKS_TO_ATTACH', null)
    },
    warningToggle () {
      this.showWarning = !this.showWarning
    }
  }
}
</script>
<style lang="scss">
  .el-select__tags {
    overflow: hidden;
  }
</style>
<style scoped lang="scss">
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
     width: auto;
  }
  .el-checkbox__label {
    display: none;
  }
  .tabs-mobile {
    margin-bottom: -1px!important;
    border-bottom-width: 0px!important;
  }
  .tabs-desktop {
    border-bottom-left-radius: 7px!important;
  }
  .btn-fix-width {
    width: 100px;
  }
  .bg-modal.active {
    background-color: rgb(247,247,247)!important;
  }
  tr { background-color: #fff; cursor: pointer;}
  //tr td:first-child { border-top-left-radius: 10px; }
  //tr td:last-child { border-top-right-radius: 10px; }
  //tr td:first-child { border-bottom-left-radius: 10px; }
  //tr td:last-child { border-bottom-right-radius: 10px; }
</style>
