<template>
  <div />
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'
export default {
  name: 'VisibilityObserver',
  mixins: [inViewport],
  watch: {
    'inViewport.now' (view) {
      if (view === true) {
        this.$emit('visible')
      }
    }
  }
}
</script>

<style scoped>

</style>
