<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, handleChange }"
    :rules="validation" as="div"
  >
    <el-radio
      :name="name"
      :class="containerClass"
      :model-value="value"
      @update:model-value="handleChange"
      :disabled="!!disabled"
      :value="setValue"
      :data-cy="dataCy ? dataCy : null"
    >
      <slot>
        <span class="text-wrap">
          {{ label }} <ui-hint v-if="hint" :content="hint"/>
        </span>
      </slot>
    </el-radio>
  </Field>
</template>

<script>
export default {
  name: 'radio',
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: [String, Object],
    value: [Boolean, Number, String],
    modelValue: [Boolean, Number, String],
    setValue: [Boolean, Number, String],
    placeholder: String,
    disabled: Boolean,
    label: String,
    hint: String,
    containerClass: String,
    dataCy: String
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }

}
</script>
