<template>
  <modal-component :id="'ChangePassword'" :modalClass="'modal-lg'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Change password')}}</h5>
    </template>
    <template #body>
      <div>
      <Form ref="observer" as="div">
        <div class="form-group">
          <ui-text-input
            id="password_old"
            :type="'password'"
            :name="'password_old'"
            v-model="oldPassword"
            :validation="{required: true, min: 8, password: true}"
            :label="$t('Old password')"
          />
        </div>
        <div class="form-group">
          <ui-text-input
            id="password"
            :type="'password'"
            :name="'password'"
            v-model="password"
            :validation="{required: true, min: 8, password: true}"
            :label="$t('Password')"
          />
        </div>
        <div class="form-group">
          <ui-text-input
            id="password_confirmation"
            :type="'password'"
            :name="'password_confirmation'"
            v-model="passwordConfirm"
            :validation="{required: true, min: 8, password: true}"
            :label="$t('Confirm password')"
          />
        </div>
      </Form>
    </div>
    </template>
    <template #footer>
    <div>
      <button class="btn btn-success btn-circle" type="button" @click="changePassword" :disabled="disabled">
        {{ $t('Change') }}
      </button>
    </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'ChangePassword',
  mixins: [errorMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      disabled: false
    }
  },
  methods: {
    changePassword () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          const passwordData = {
            password_old: this.oldPassword,
            password: this.password,
            password_confirmation: this.passwordConfirm
          }
          this.$store.dispatch('changePassword', passwordData).then(() => {
            this.$parent.$emit('closeChangePasswordModal')
            this.toastSuccess(this.$t('Password was changed'))
            document.getElementById('ChangePassword').click()
            this.closeModal()
          }).catch(() => {})
        }
      })
    },
    closeModal: function () {
      this.$emit('closeChangePasswordModal')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
