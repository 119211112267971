<template>
  <Form ref="observer" as="div">
    <div class="row my-2">
      <div class="col-12 col-lg-6 align-self-end">
        <div class="row">
          <div class="col-12">
            <ui-date-picker
              :label="$t('EMPLOYEE_NOTES')"
              :hint="$t('EMPLOYEE_NOTES_TOOLTIP')"
              :pickerType="'daterange'"
              :name="'range'"
              v-model="period"
              :validation="{required: true}"
              :clearable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div :class="!isModal ? 'col-12 col-md-6 align-self-end' : 'col-12 align-self-end'">
        <ui-select
          name="showTo"
          v-model="showTo"
          :clearable="false"
          :label="$t('SHOT_TO_NOTES_TITLE')"
          :hint="$t('SHOW_TO_NOTES_HINT')"
          :options="showToChoices"
          :key-name="'value'"
          :label-name="'label'"
          :value-name="'value'"
        />
      </div>
    </div>
    <div class="row my-2">
      <div :class="!isModal ? 'col-12 col-md-6 align-self-end mt-2' : 'col-12 align-self-end mt-2'">
        <ui-textarea-input
          name="content"
          v-model="content"
          :validation="{required: true, max: 300}"
          :rows="3"
          :placeholder="$t('Please input')"
        />
      </div>
    </div>
    <div class="row my-2">
      <div class="col-12 align-self-end mt-2">
        <button class="btn btn-success btn-circle shadow-sm"
                aria-label="Close"
                type="button"
                @click="save">
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'NoteFormComponent',
  mixins: [momentMixin],

  data () {
    return {
      period: [moment().startOf('month'), moment().endOf('month')],
      noteFrom: moment().startOf('month'),
      noteTo: moment().endOf('month'),
      content: '',
      showTo: 'manager',
      noteId: null,
      showToChoices: [
        {
          value: 'manager',
          label: this.$t('SHOW_MANAGER_SELECT')
        },
        {
          value: 'employee',
          label: this.$t('SHOW_EMPLOYEE_SELECT')
        },
        {
          value: 'all',
          label: this.$t('SHOW_ALL_SELECT')
        }
      ]
    }
  },

  props: {
    employeeId: Number,
    note: Object,
    isModal: Boolean
  },

  created () {
    this.init()
  },

  computed: {
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    tz () {
      return this.$store.getters.profileTimeZone
    },
    companyId () {
      return this.$store.getters.companyId
    },
    pickerOptions () {
      return {
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      }
    }
  },
  watch: {
    note: {
      deep: true,
      handler: function (after, before) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      if (this.note) {
        this.period = [
          moment(this.note.start, this.localeDateTimeFormat),
          moment(this.note.end, this.localeDateTimeFormat).add(-1, 'day')
        ]
        this.content = this.note.content
        this.noteId = this.note.id
        this.showTo = this.note.show_to
      }
    },
    save () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (!this.noteId) {
            this.handleSave()
          } else {
            this.handleEdit()
          }
        }
      })
    },

    handleEdit () {
      this.$store.dispatch('updateNote', [this.companyId, this.noteId, {
        employeeId: this.employeeId,
        start: this.fromZoneToZone(this.period[0], this.tz, 'UTC').format(this.backendDateTimeFormat),
        end: this.fromZoneToZone(this.period[1], this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
        content: this.content,
        show_to: this.showTo
      }]).then(response => {
        response.start = this.fromZoneToZone(response.start, 'UTC', this.tz).format(this.localeDateTimeFormat)
        response.end = this.fromZoneToZone(response.end, 'UTC', this.tz).format(this.localeDateTimeFormat)
        this.period = [
          moment().startOf('month'),
          moment().endOf('month')
        ]
        this.content = ''
        this.$emit('noteCreated', response)
      })
    },

    handleSave () {
      this.$store.dispatch('createNote', [this.companyId, {
        employeeId: this.employeeId,
        start: this.fromZoneToZone(this.period[0], this.tz, 'UTC').format(this.backendDateTimeFormat),
        end: this.fromZoneToZone(this.period[1], this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
        content: this.content,
        show_to: this.showTo
      }]).then(response => {
        response.start = this.fromZoneToZone(response.start, 'UTC', this.tz).format(this.localeDateTimeFormat)
        response.end = this.fromZoneToZone(response.end, 'UTC', this.tz).format(this.localeDateTimeFormat)
        this.period = [
          moment().startOf('month'),
          moment().endOf('month')
        ]
        this.content = ''
        this.$refs.observer.resetForm()
        this.$emit('noteCreated', response)
      })
    }
  }
}
</script>

<style scoped>

</style>
