<template>
  <div>
    <div class="row pb-2">
      <div class="col-lg-12">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}#balance`">
              <span class="breadcrumb-item">{{ $t('Edit company profile')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t('Subscribes') }}</span>
          </li>
        </ol>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <el-alert
          v-if="unpaidInvoice"
          :title="$t('subscription_unpaid_invoice_alert')"
          type="warning"
          effect="dark"
          :closable="false"
          show-icon
          class="mb-2"
        >
        </el-alert>
        <div class="card border-color-light-green radius-all">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <ui-select
                  id="companyWorkers"
                  v-model="cardNumber"
                  name="card_number"
                  :validation="{required: true}"
                  :clearable="false"
                  :filterable="false"
                  :placeholder="$t('Choose a card')"
                  :label="$t('Choose a card')"
                  :options="cards"
                  :key-name="'key'"
                  :label-name="'value'"
                  :value-name="'key'"
                />
              </div>
              <div class="col-auto d-flex align-items-end">
                <i class="icon-credit-card h4" />
              </div>
            </div>
          </div>
          <div v-if="!showForm" class="card-footer text-muted bg-transparent border-0 text-center">
            <button :disabled="disabled" type="submit" name="save" class="btn btn-success rounded-pill shadow-sm" @click="submit">Ok</button>
          </div>
        </div>
      </div>
      <div class="col-lg-4"></div>
    </div>
    <div v-if="showForm" class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <credit-card></credit-card>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </div>
</template>

<script>
import CreditCard from './CompanySettingsComponents/FormComponents/CreditCard'
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'Subscribes',
  components: { CreditCard },
  mixins: [errorMixin],
  data () {
    return {
      cardNumber: '',
      showForm: false,
      unpaidInvoice: false,
      cards: [
        { key: 'addCard', value: this.$i18n?.t('Add card') }
      ]
    }
  },
  created () {
    this.$eventBus.on('addCard', this.addCard)
    if (this.companyId) {
      this.getCards()
      // check unpaid invoices
      this.$store.dispatch('getInvoices', { payload: '?status=created' }).then(response => {
        if (response && response.length > 0) {
          this.unpaidInvoice = true
        } else {
          this.unpaidInvoice = false
        }
      })
    }
  },
  computed: {
    disabled () {
      return this.cardNumber === '' || this.cardNumber === 'addCard'
    },
    companyId () {
      return this.$store.getters.companyId
    }
  },
  beforeUnmount () {
    this.$eventBus.off('addCard')
  },
  watch: {
    cardNumber (value) {
      this.showForm = value === 'addCard'
    },
    companyId () {
      this.getCards()
    }
  },
  methods: {
    submit () {
      const data = {
        company_id: this.$store.getters.companyId,
        card_id: this.cardNumber
      }
      this.$store.dispatch('setSubscription', data).then(response => {
        this.$store.dispatch('clearCompanyStore')
        this.$store.dispatch('getCompany', this.companyId)
        this.toastSuccess(this.$i18n?.t('Subscription is enable'))
        this.$router.push('/c/' + this.$store.getters.companyId + '#balance')
      }).catch(error => {
        this.toastError(this.$t(error.response.data.message))
      })
    },
    getCards () {
      this.showForm = false
      this.$store.dispatch('getCards').then(response => {
        if (response) {
          response.forEach(card => {
            this.cards.unshift({
              key: card.id,
              value: card.brand + ' ****' + card.last4 + ' (' + card.exp_month + '/' + card.exp_year + ')'
            })
          })
        }
      })
    },
    addCard (card) {
      this.cards.unshift({
        key: card.id,
        value: card.brand + ' ****' + card.last4 + ' (' + card.exp_month + '/' + card.exp_year + ')'
      })
      this.cardNumber = card.id
      this.showForm = false
    }
  }
}
</script>

<style scoped>
  .border-color-light-green {
    border-color: #32c5d2;
  }
</style>
