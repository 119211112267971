<template>
  <modal-component
    :id="'BuyProjectModal'"
    @closeModal="closeModal"
    :modalClass="'modal-dummy'"
    :modalBodyClass="'dummy-popup'"
    :hideFooter="true"
    :hideHeader="true">
    <template #body>
    <div>
      <dummy-payment-required
        @activate="activate"
        :dummyInfo="moduleData"
        :isModal="true"
        >
      </dummy-payment-required>
    </div>
    </template>
  </modal-component>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'

export default {
  name: 'BuyProjectModal',
  components: {
    ModalComponent: ModalComponent,
    DummyPaymentRequired: DummyPaymentRequired
  },
  data () {
    return {
      moduleData: {}
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getModulesAllAvailable', this.companyId).then(response => {
        const projectModule = response.filter(module => {
          return module.modules[0] === process.env.VUE_APP_PROJECT_MODULE_PERMISSION
        })[0]
        if (this.locale !== 'en') {
          projectModule.short_description = projectModule.locales[this.locale].short_description
          projectModule.name = projectModule.locales[this.locale].name
        }
        this.moduleData = this.setupDummyData(projectModule)
      })
    },
    closeModal () {
      this.$parent.$emit('closeBuyProjectModal')
    },
    activate () {
      this.$store.dispatch('clearCompanyStore').then(() => {
        this.$store.dispatch('getCompany', this.companyId).then(() => {
          this.$parent.$emit('closeBuyProjectModal')
          this.toastSuccess(this.$i18n?.t('Project successfully activated'))
        }).then(() => {
          this.$router.push(`/c/${this.companyId}/project`)
        })
      })
    },
    setupDummyData (module) {
      return {
        title: module.locales[this.locale] ? module.locales[this.locale].name : module.name,
        trial: module.trial_period,
        price: +module.price,
        perUser: +module.user_price,
        isActive: module.is_active,
        id: +module.id,
        description: module.locales[this.locale] ? module.locales[this.locale].description : module.description,
        short_description: module.locales[this.locale] ? module.locales[this.locale].short_description : module.short_description,
        helpCenter: module.help_center,
        features: module.features,
        images: module.images
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
