<template>
  <div class="row justify-content-center">
    <div class="col-6 text-center pt-4">
        <p>
          {{ $t('email_unsubscribe_token') }}
        </p>
        <p>
          <router-link :to="`/login?lang=${lang}`" class="">{{ $t('Sign In') }}</router-link>
        </p>
    </div>
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/i18n'
export default {
  name: 'Unsubscribe',
  data () {
    return {
      languages: ['ru', 'en', 'he', 'uk'],
      lang: 'en'
    }
  },
  watch: {
    $route (to, from) {
      if (this.languages.includes(this.$route.query.lang)) {
        this.lang = this.$route.query.lang
      }
    }
  },
  created () {
    if (this.languages.includes(this.$route.query.lang)) {
      this.lang = this.$route.query.lang
    }
    this.setLanguage()
  },
  beforeMount () {
    this.$store.dispatch('unsubscribe', this.$route.params.hash).then(() => {
      if (localStorage.getItem('api_key')) {
        this.$store.dispatch('dropProfile').then(() => {
          this.$store.dispatch('getProfile').then(() => {
            this.$store.dispatch('getUserInfo').then(() => {
            })
          })
        })
      }
    })
  },
  methods: {
    isAuthorized () {
      return !!this.$store.getters.profileId
    },
    setLanguage () {
      loadLanguageAsync(this.lang)
    }
  }
}
</script>

<style scoped>

</style>
