<template>
  <div>
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="required" />
    <el-upload
      ref="fileUpload"
      class="w-100"
      name="file"
      :disabled="disabled"
      :action="upload.url"
      :headers="upload.headers"
      :data="upload.payload"
      :accept="accept"
      :multiple="multiple"
      :limit="limit"
      :drag="slotFile"
      :show-file-list="showFileList"
      :auto-upload="autoUpload"
      :on-change="getFile"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-error="onError"
      :on-success="onSuccess"
      :on-remove="cleanFile"
      :on-exceed="limitFile">
      <template v-if="slotFile">
        <el-icon class="el-icon--upload"><i class="icon-upload btn-icon" /></el-icon>
        <div class="el-upload__text">
          {{ $t('Drop file here or click to upload') }}
        </div>
      </template>
      <template v-else>
        <button
          :disabled="!multiple && file"
          class="btn btn-outline-primary rounded-pill shadow-sm"
        >
          {{ buttonText ? buttonText : $t('Choose file') }}
        </button>
      </template>
      <template v-if="slotFile" #tip>
        <div class="el-upload__tip">
          {{ $t('files with a size less than ') + fileLimit + ' ' + $t('kb') }}
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'upload-file',

  props: {
    name: String,
    apiErrors: Array,
    upload: Object,
    label: String,
    hint: String,
    fileLimit: { type: Number, default: 102400 },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    limit: { type: Number, default: 1 },
    accept: { type: String, default: '*' },
    showFileList: { type: Boolean, default: true },
    autoUpload: { type: Boolean, default: false },
    buttonText: String,
    slotFile: { type: Boolean, default: false },
    beforeUpload: { type: Function, default: null }
  },

  data () {
    return {
      size: 0,
      file: null,
      fileList: []
    }
  },
  emits: ['uploadRef', 'onSuccess', 'onError', 'file'],
  mounted () {
    this.$emit('uploadRef', this.$refs.fileUpload)
  },
  methods: {
    onSuccess () {
      this.cleanFile()
      this.$emit('onSuccess')
    },
    onError ({ error }) {
      this.$emit('onError', error)
    },
    getFile (file) {
      // console.log(file)
      if (file.size / 1000 > this.fileLimit) {
        this.errors.add({
          field: 'file',
          msg: 'THE_FILE_IS_TOO_BIG'
        })
      }
      this.size = file.size
      this.file = file.raw
      this.getBase64(this.file)
      this.$emit('file', {
        file: this.file,
        size: this.size
      })
    },
    cleanFile () {
      this.file = null
      this.fileList = []
      this.size = 0
    },
    limitFile (file) {
      this.fileList = []
      this.fileList.push(file[0])
    },
    getBase64 (file) {
      this.base = ''
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const self = this
      reader.onload = function () {
        self.base = reader.result
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
