<template>
  <modal-component :id="'AddShiftFreeBoard'" @closeModal="closeModal">
    <template v-slot:header>
      <h5 class="modal-title" id="">{{ $t('Add Shift')}}</h5>
    </template>
    <template v-slot:body>
      <div>
        <Form ref="observer" as="div">
          <div class="row mb-2">
            <div class="col">
              <div class="font-weight-bold">
                {{schedule.name}}
              </div>
              <div class="small">
                {{blockedDateFrom.format(localeDateFormat)}} - {{blockedDateTo.clone().add(-1, 'day').format(localeDateFormat)}}
              </div>
            </div>
          </div>
          <div
            class="accordion"
            id="accordionDate"
          >
            <el-divider />
            <div
              @click="changeCollapse('period')"
              id="headingOne"
              type="button"
              data-toggle="collapse"
              data-target="#period"
              aria-expanded="true"
              aria-controls="period"
            >
              <div class="row">
                <div class="col">
                  <h6 class="mb-0">
                    {{$t('Select a date to which you want to add shifts')}}
                    <ui-hint :content="$t('DATES_HINT')"/>
                  </h6>
                </div>
                <div class="col-auto">
                  <i class="icon-chevron-right btn-icon text-primary mx-1" />
                </div>
              </div>
            </div>

            <div
              id="period"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionDate"
            >
              <div class="row mb-2">
                <div class="col-md-6">
                  <ui-date-picker
                    :label="$t('Shift date from')"
                    name="date_from"
                    v-model="shiftDateFrom"
                    :value-format="dateFormat"
                    :validation="{required: true}"
                    :clearable="false"
                  />
                </div>
                <div class="col-md-6">
                  <ui-date-picker
                    :label="$t('Shift date to')"
                    name="date_to"
                    v-model="shiftDateTo"
                    :value-format="dateFormat"
                    :validation="{required: true}"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <SelectWeekDayComponent @daysChanged="onChangeDays" />
                </div>
              </div>
            </div>
            <el-divider />
            <div
              @click="changeCollapse('dates')"
              id="headingTwo"
              type="button"
              data-toggle="collapse"
              data-target="#dates"
              aria-expanded="true"
              aria-controls="dates"
            >
              <div class="row">
                <div class="col">
                  <h6 class="mb-0">
                    {{$t('MULTIPLE_DAYS_SELECT_HEADER')}}
                    <ui-hint :content="$t('MULTIPLE_DAYS_SELECT_HEADER_HINT')"/>
                  </h6>
                </div>
                <div class="col-auto">
                  <i class="icon-chevron-right btn-icon text-primary mx-1" />
                </div>
              </div>
            </div>
            <div id="dates" class="collapse mb-2" aria-labelledby="headingTwo" data-parent="#accordionDate">
              <MultipleDaysPickerComponent @onChange="onChangeDates" />
            </div>
            <el-divider />
          </div>
          <div v-if="shiftModalType === 'templateView'" class="row my-2">
            <div class="col-md-12">
              <label>{{ $t('SELECT_USERS_LABEL') }}</label>
              <ui-multi-select
                name="users"
                v-model="userIds"
                class="w-100"
                :options="userList"
                :validation="{required: true}"
              />
            </div>
          </div>
          <ui-form-row
            v-if="shiftModalType === 'employeesView' || (shiftModalType === 'templateView' && templateId === 0)"
            :label="$t('Select a shift')" :hint="$t('SELECT_TEMPLATE_HINT')">
            <ui-group-select
              name="template"
              v-model="scheduleTemplateStr"
              :options="selectOptions"
            />
          </ui-form-row>
          <div class="row">
            <div class="col-12">
              <ui-color-picker
                name="color"
                v-model="color"
                :label="$t('Choose a color:')"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-12 text-left">
              <label class="control-label">{{ $t("Shift period") }} {{ duration }} {{ getHrs() }} {{ projectTimeZone }}</label>
            </div>
          </div>
          <div class="row align-items-baseline">
            <div class="col-md-6">
              <ui-time-picker
                :label="$t('From')"
                :name="'start_time'"
                :placeholder="$t('From')"
                :validation="{required: true}"
                :clearable="false"
                :value-format="backendTimeFormat"
                v-model="timeFrom"
                :picker-options="{start:'00:00',step:'00:05',end:'24:00'}"
              />
            </div>
            <div class="col-md-6">
              <ui-time-picker
                :label="$t('To')"
                :name="'time_to'"
                :placeholder="$t('From')"
                :validation="{required: true}"
                :clearable="false"
                :value-format="backendTimeFormat"
                v-model="timeTo"
                :picker-options="{start:'00:00',step:'00:05',end:'24:00'}"
              />
            </div>
            <div class="col-md-6">
              <ui-number-input
                :label="$t('BREAK_MIN')"
                :placeholder="$t('Please input')"
                v-model="breakTime"
                name="break_time"
                :precision="0"
                :step="1"
                @change="$emit('updateOvertimeCheck')"
                :disabled="template && !!template.break_time"
                :validation="{required: true, integer: true, max_value: durationMinutes}"
              />
            </div>
          </div>
          <ui-form-row :label="$t('Select positions for the shift')" :hint="$t('POSITION_HINT')">
            <ui-tags-select
              name="marks"
              v-model="marks"
              :clearable="true"
              :options="projectMarks">
              <template v-slot:default="slotProps">
                <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                {{ slotProps.option.name }}
              </template>
            </ui-tags-select>
          </ui-form-row>
          <ui-form-row :label="$t('SHIFT_LOCATION_SELECT')" :hint="$t('SHIFT_LOCATION_SELECT_HINT')">
            <ui-select
              name="location"
              v-model="locationId"
              :clearable="true"
              class="w-100"
              :options="locations"
            />
          </ui-form-row>
          <ui-form-row v-if="userId === 0" :label="$t('Enter the number of shifts you want to add')">
            <ui-number-input
              :precision="0"
              :step="1"
              :min="0"
              name="shift_number"
              v-model="shiftNumber"
              class="w-50"
              :validation="{required: true, integer: true, min_value: 1, max_value: shiftNumberMax}"
            />
          </ui-form-row>
          <div class="row">
            <div class="col-12">
              <ui-textarea-input
                name="note"
                :label="$t('SHIFT_NOTE_LABEL')"
                :hint="$t('SHIFT_NOTE_LABEL_HINT')"
                v-model="note"
                :validation="{required: false, max: 256}"
                :rows="1"
                :placeholder="$t('SHIFT_NOTE')"
              />
            </div>
            <div class="col-12 mt-2">
              <ui-checkbox
                class="mt-auto"
                name="rate"
                :disabled="!note"
                v-model="hide_note"
              >
                {{ $t('SHIFT_NOTE_HIDE_LABEL') }}
                <ui-hint :content="$t('SHIFT_NOTE_HIDE_LABEL_HINT')" />
              </ui-checkbox>
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <button
          class="btn btn-success rounded-pill shadow-sm"
          @click="addShift">
          {{ $t('Add shift') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import ToastMixin from '@/mixins/ToastMixin'
import errorMixin from '@/mixins/mixinApiErrors'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import SelectWeekDayComponent from '@/components/CommonComponents/Pickers/SelectWeekDayComponent'
import MultipleDaysPickerComponent from '@/components/CommonComponents/Pickers/MultipleDaysPickerComponent'

export default {
  name: 'AddShiftFreeBoard',
  mixins: [momentMixin, errorMixin, mixinColorsMarks, ToastMixin],
  components: {
    SelectWeekDayComponent,
    ModalComponent: ModalComponent,
    MultipleDaysPickerComponent
  },

  data () {
    return {
      activeCollapse: 'period',
      prevCollapse: 'dates',
      shiftDateFrom: moment().format(this.localeDateFormatElementUi),
      shiftDateTo: moment().format(this.localeDateFormatElementUi),
      scheduleTemplateStr: '',
      timeFrom: '08:00',
      timeTo: '17:00',
      breakTime: 0,
      scheduleId: '',
      shiftNumber: 1,
      shiftNumberMax: 1,
      shifts: [],
      marks: [],
      note: '',
      hide_note: true,
      addShiftTemplateChoices: {},
      selectOptions: [],
      pickerOptions: {
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      },
      pickerOptionsFrom: {
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      },
      weekOvertime: {
        is_overtime: false,
        warning: null,
        week_overtime: 0,
        week_overtime_control_type: 'allow_overtime',
        week_quota: 40,
        week_working_hours: 0
      },
      locations: [],
      locationId: null,
      days: [0, 1, 2, 3, 4, 5, 6],
      dates: [],
      userList: [],
      userIds: [],
      color: '#0000FF'
    }
  },

  props: {
    shiftAddDate: Object,
    shiftAddEndDate: Object,
    userId: Number,
    userMarks: Object,
    users: Object,
    schedules: Array,
    templateId: Number,
    shiftModalType: String
  },
  created () {
    this.timeFrom = this.timeFromZoneToZone('08:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)
    this.timeTo = this.timeFromZoneToZone('17:00', 'UTC', 'UTC', this.localeTimeFormatElementUi)

    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.locations = response.filter(item => !item.hide).map(location => {
        return { id: location.id, name: location.title }
      })
    })
    this.shiftDateFrom = this.shiftAddDate.format(this.localeDateFormat)
    this.shiftDateTo = this.shiftAddEndDate.format(this.localeDateFormat)
    const filteredTemplates = {}
    Object.keys(this.templatesByProject).forEach(key => {
      if (!this.templatesByProject[key][3]) {
        filteredTemplates[key] = this.templatesByProject[key]
      }
    })
    this.shiftNumberMax = 0
    Object.keys(this.users).forEach((key) => {
      this.shiftNumberMax += this.users[key].length
    })
    this.shiftNumberMax *= 2
    if (+this.userId === 0) {
      this.addShiftTemplateChoices = filteredTemplates
    } else {
      const userInSchedulesArray = []
      Object.keys(this.users).forEach((key) => {
        if (this.users[key].indexOf(this.userId) > -1) {
          userInSchedulesArray.push(key)
        }
      })
      Object.keys(filteredTemplates).forEach((key) => {
        const scheduleIdArray = key.split('__sp__')
        if (userInSchedulesArray.indexOf(scheduleIdArray[0]) > -1) {
          this.addShiftTemplateChoices[key] = filteredTemplates[key]
        }
      })
    }
    if (Object.keys(this.addShiftTemplateChoices).length > 0) {
      this.scheduleTemplateStr = Object.keys(this.addShiftTemplateChoices)[0]
      this.addMarks()
      if (this.template) {
        this.timeFrom = this.template.time_from
        this.timeTo = this.template.time_to
        this.breakTime = this.template.break_time
        this.marks = this.template.marks
        this.locationId = this.template.location_id === 0 ? null : this.template.location_id
      }
    }
    const selectOptions = {}
    Object.keys(this.addShiftTemplateChoices).forEach((key) => {
      if (!selectOptions[this.addShiftTemplateChoices[key][0]]) {
        selectOptions[this.addShiftTemplateChoices[key][0]] = []
      }
      selectOptions[this.addShiftTemplateChoices[key][0]].push({
        id: key,
        name: `${this.$i18n?.t('Shift template')}: ${this.addShiftTemplateChoices[key][1]}`
      })
    })
    Object.keys(selectOptions).forEach((key) => {
      this.selectOptions.push({
        label: `${this.$i18n?.t('Schedule')}: ${key}`,
        options: selectOptions[key]
      })
    })
    // console.log(this.selectOptions)
    this.updateSchedulesList()
  },
  mounted () {
    this.$eventBus.emit('addShiftModalMounted')
  },
  computed: {
    dateTimeFormat () {
      return this.localeDateTimeFormat
    },
    dateFormat () {
      return this.localeDateFormatElementUi
    },
    timeFormat () {
      return this.localeTimeFormatElementUi
    },
    templatesByProject () {
      return this.$store.getters.templatesByProject
    },
    schedule () {
      if (!this.scheduleTemplateStr) {
        return null
      }
      if (this.shiftModalType === 'templateView' && this.templateId > 0) {
        const template = this.$store.getters.templateById(this.templateId)
        return this.$store.getters.scheduleById(template.schedule_id)
      }
      const scheduleId = +this.scheduleTemplateStr.split('__sp__')[0]
      return this.$store.getters.scheduleById(scheduleId)
    },
    template () {
      if (!this.scheduleTemplateStr) {
        return null
      }
      if (this.shiftModalType === 'templateView' && this.templateId > 0) {
        return this.$store.getters.templateById(this.templateId)
      }
      const templateId = +this.scheduleTemplateStr.split('__sp__')[1]
      return this.$store.getters.templateById(templateId)
    },
    durationMinutes () {
      return this.duration * 60
    },
    duration () {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + this.timeTo).diff(moment(moment().format('YYYY-MM-DD') + ' ' + this.timeFrom), 'hours', true)
      value = Math.round(value * 100) / 100

      if (value === 0) {
        return 24
      }

      if (value <= 0) {
        return Math.round((24 + value) * 100) / 100
      }
      return Math.round(value * 100) / 100
    },
    blockedDateFrom () {
      return this.fromZoneToZone(this.schedule.date_from, 'UTC', this.$store.getters.actualTZ)
    },
    blockedDateTo () {
      return this.fromZoneToZone(this.schedule.date_to, 'UTC', this.$store.getters.actualTZ)
    },
    companyId () {
      return this.$store.getters.companyId
    },
    isFormValid () {
      // todo
      return true
      // return Object.keys(this.fields).some(key => this.fields[key].invalid)
    },
    shiftsInPast () {
      if (!this.$store.getters.warningInPast) {
        return false
      }
      if (this.timeFrom && this.timeTo) {
        const dateTime = moment([this.shiftDateFrom, this.timeFrom].join(' '), this.localeDateTimeFormat)
        const shiftStart = this.fromZoneToZone(dateTime, this.$store.getters.actualTZ, 'UTC')
        return shiftStart < moment.utc()
      }
      return false
    },
    ...mapGetters({
      preloadUsers: 'employeesIndexed',
      currentProjectId: 'currentProjectId',
      projectMarks: 'projectMarks'
    }),
    projectTimeZone () {
      const project = this.$store.getters.projectById(this.currentProjectId)
      const projectTz = moment(this.shiftDateFrom, this.localeDateFormat).tz(project.time_zone).format('Z')
      return this.$i18n?.t('PROJECT_TIMEZONE') + ' ' + projectTz
    }
  },
  watch: {
    scheduleTemplateStr () {
      this.templateChange()
    },
    activeCollapse (val, prev) {
      if (!val) {
        this.$nextTick(() => {
          this.activeCollapse = prev === 'period' ? 'dates' : 'period'
        })
      }
    },
    shiftAddDate () {
      this.shiftDateFrom = this.shiftAddDate.format(this.localeDateFormat)
    },
    shiftAddEndDate () {
      this.shiftDateTo = this.shiftAddEndDate.format(this.localeDateFormat)
    },
    marks (items) {
      if (Array.isArray(items)) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.currentProjectId]).then((mark) => {
              this.marks[newIndex] = mark.id
            }).catch(() => {
              this.marks.splice(newIndex, 1)
              this.toastError(this.$i18n?.t('The name has already been taken.'))
            })
          }
        })
      }
    },
    scheduleId (scheduleId) {
      this.userIds = []
      this.userList = []
      this.users[scheduleId].forEach(userId => {
        this.userList.push({
          id: userId,
          name: this.preloadUsers[userId].last_name + ' ' + this.preloadUsers[userId].first_name
        })
      })
      this.userList.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
    },
    template (value) {
      this.color = value ? value.color : '#0000FF'
    }
  },
  methods: {
    updateSchedulesList () {
      if (this.shiftModalType === 'templateView') {
        this.userIds = []
        this.schedulesChoices = []
        if (this.templateId > 0) {
          const template = this.$store.getters.templatesIndexed[this.templateId]
          this.scheduleId = template.schedule_id
          this.timeFrom = this.timeFromZoneToZone(template.time_from, 'UTC', 'UTC', this.localeTimeFormatElementUi)
          this.timeTo = this.timeFromZoneToZone(template.time_to, 'UTC', 'UTC', this.localeTimeFormatElementUi)
          this.breakTime = template.break_time
        }
      }
    },
    changeCollapse (val) {
      this.activeCollapse = val
      setTimeout(() => {
        const el = document.getElementById(val)
        if (!el.classList.contains('show')) {
          const val2 = val === 'period' ? 'dates' : 'period'
          const el2 = document.getElementById(val2)
          el2.classList.add('show')
        }
      }, 500)
    },
    onChangeDates (dates) {
      this.dates = dates
    },
    onChangeDays (days) {
      this.days = days
    },
    updateOvertimeCheck () {
      this.$store.dispatch('isWeekOvertime', this.weekOvertimeCheckParams()).then(result => {
        this.weekOvertime = result
      })
    },
    getHrs () {
      let value = moment(moment().format('YYYY-MM-DD') + ' ' + this.timeTo).diff(moment(moment().format('YYYY-MM-DD') + ' ' + this.timeFrom), 'hours', true)
      if (value === 0) {
        value = 24
      }

      if (value <= 0) {
        value = (24 + value)
      }

      return value ? `(${Math.floor(value)} ${this.$t('h')} ${Math.round(value * 60 % 60)} ${this.$t('m')})` : ''
    },
    weekOvertimeCheckParams () {
      const from = this.fromZoneToZone(this.shiftDateFrom, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const to = this.fromZoneToZone(this.shiftDateTo, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat)
      const query = `employee_id=${this.userId}&time_from=${from}&time_to=${to}&break_time=${+this.breakTime}`
      return [+this.shift.extendedProps.schedule_id, query]
    },
    changeDate () {
      if (moment(this.shiftDateFrom, this.localeDateFormat).isAfter(moment(this.shiftDateTo, this.localeDateFormat))) {
        this.shiftDateTo = this.shiftDateFrom
      }
    },
    changeDateEnd () {
      if (moment(this.shiftDateFrom, this.localeDateFormat).isAfter(moment(this.shiftDateTo, this.localeDateFormat))) {
        this.shiftDateFrom = this.shiftDateTo
      }
    },
    closeModal () {
      document.getElementById('AddShiftFreeBoard').click()
      this.$emit('closeAddShiftModal')
    },
    addMarks () {
      this.scheduleId = this.schedule.id
      if (this.scheduleId in this.userMarks && this.userId in this.userMarks[this.scheduleId]) {
        this.marks = this.userMarks[this.scheduleId][this.userId]
      } else {
        this.marks = []
      }
    },

    templateChange () {
      this.addMarks()
      if (this.template) {
        // console.log(this.template)
        this.timeFrom = this.template.time_from
        this.timeTo = this.template.time_to
        this.breakTime = this.template.break_time
        this.marks = this.template.marks
        this.locationId = this.template.location_id === 0 ? null : this.template.location_id
      } else {
        this.locationId = null
      }
      this.shiftNumber = 1
    },

    addShiftAction () {
      const formData = {
        schedule_id: this.schedule.id,
        template_id: this.shiftModalType === 'templateView' ? this.templateId : this.template && this.template.id,
        user_ids: this.shiftModalType === 'employeesView' ? [this.userId] : this.userIds,
        date_from: moment(this.shiftDateFrom, this.localeDateFormat).format(this.backendDateFormat),
        date_to: moment(this.shiftDateTo, this.localeDateFormat).format(this.backendDateFormat),
        break_time: this.breakTime ? +this.breakTime : 0,
        marks: this.marks,
        shifts_number: +this.shiftNumber,
        location_id: this.locationId,
        days: this.days,
        dates: this.dates.map(date => date.id),
        type: this.activeCollapse,
        note: this.note,
        hide_note: this.hide_note,
        time_zone: this.$store.getters.actualTZ,
        color: this.color,
        time_from: moment(this.timeFrom, this.localeTimeFormatElementUi).format(this.backendTimeFormat),
        time_to: moment(this.timeTo, this.localeTimeFormatElementUi).format(this.backendTimeFormat)
      }

      let title = this.$i18n?.t('Shift(s) successfully added')
      let text = ''
      this.$store.dispatch('createShift', formData).then((response) => {
        this.marks = []
        title = response.shifts.length + ' ' + title
        if (response.errors > 0) {
          response.errors_details.forEach(error => {
            text += `<div class="text-danger">${this.$t(error.error_description)}: ${error.shift_data}</div>`
          })
        } else {
          text = ''
        }
        let type = 'success'
        if (response.errors > 0 && response.shifts.length < 1) {
          type = 'error'
        }
        if (response.errors > 0 && response.shifts.length > 0) {
          type = 'warning'
        }
        this.toastCustom({
          title: title,
          message: text,
          type: type,
          dangerouslyUseHTMLString: true
        })
        if (response.shifts && response.shifts.length) {
          this.$eventBus.emit('addShiftsToUsers', response.shifts)
        }
        this.$eventBus.emit('checkNotify')
      }).finally(() => {
        this.closeModal()
      })
    },

    addShift () {
      this.$refs.observer.validate().then(({ valid }) => {
        if (valid) {
          if (this.shiftsInPast) {
            const body = document.getElementsByTagName('body')
            body[0].setAttribute('style', 'padding-right: 0px;')
            this.$confirm(this.$i18n?.t('You want to add a shift to a passed time period.'), this.$i18n?.t('Are you sure?'), {
              confirmButtonText: this.$i18n?.t('Yes, I am sure!!'),
              cancelButtonText: this.$i18n?.t('No, cancel it!!'),
              type: 'warning',
              center: true
            }).then(() => {
              this.addShiftAction()
            }).catch(() => {})
          } else {
            this.addShiftAction()
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .checkbox-wrapper :deep(.el-checkbox) {
    margin: 0;
  }
  .el-checkbox :deep(.el-checkbox__inner) {
    width: 30px;
    height: 30px;
  }
  .el-checkbox :deep(.el-checkbox__inner::after) {
    border: 3px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 14px;
    left: 11px;
    top: 4px;
  }
</style>
