import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer, Cache } from 'axios-extensions'
import index from '@/store/index'
// import companies from '@/store/modules/companies'
import { app } from '@/main'

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

export const CacheLoc = new Cache({ ttl: 1000 * 60 * 60 * 24, max: 1000 })

export const axiosCachedImage = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: true, defaultCache: CacheLoc }), { threshold: 2 * 1000 }),
  responseType: 'arraybuffer'
})

export const axiosBasic = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

const apiKey = localStorage.getItem('api_key')
const tokenType = localStorage.getItem('token_type')
if (apiKey !== undefined && tokenType !== undefined) {
  axiosBasic.defaults.headers.common.Authorization = tokenType + ' ' + apiKey
  axiosCachedImage.defaults.headers.common.Authorization = tokenType + ' ' + apiKey
}

/**
 *  Блок для предварительной обработки запросов/ответов
*/
let numberOfAjaxCAllPending = 0
axiosCachedImage.interceptors.request.use(function (config) {
  numberOfAjaxCAllPending++
  app.config.globalProperties.$eventBus.emit('loading', true)
  return setStartTime(config)
}, function (error) {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  return Promise.reject(error)
})

axiosCachedImage.interceptors.response.use((response) => {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  response = setEndTime(response)
  if (response.duration > parseInt(process.env.VUE_APP_LOG_RESPONSE_DELAY) && response.config.url !== process.env.VUE_APP_ROOT_API + 'logs') {
    sendLogData(response, 'http_slow_query')
  }
  return response
}, function (error) {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  if (error.response.status >= parseInt(process.env.VUE_APP_LOG_LEVEL) && error.response.request.responseURL !== process.env.VUE_APP_ROOT_API + 'logs') {
    error.response = setEndTime(error.response)
    sendLogData(error.response, 'http_bad_request')
    index.dispatch('getErrors', {}).then()
    index.dispatch('getErrors', error.response.data).then()
  }
  return Promise.reject(error)
})

axiosBasic.interceptors.request.use(function (config) {
  numberOfAjaxCAllPending++
  app.config.globalProperties.$eventBus.emit('loading', true)
  return setStartTime(config)
}, function (error) {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  return Promise.reject(error)
})

axiosBasic.interceptors.response.use((response) => {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  response = setEndTime(response)
  if (response.duration > parseInt(process.env.VUE_APP_LOG_RESPONSE_DELAY) && response.request.responseURL !== process.env.VUE_APP_ROOT_API + 'logs') {
    sendLogData(response, 'http_slow_query')
  }
  return response
}, function (error) {
  numberOfAjaxCAllPending--
  if (numberOfAjaxCAllPending === 0) {
    app.config.globalProperties.$eventBus.emit('loading', false)
  }
  if (error.response.status >= 400) {
    if (error.response?.data?.error_message) {
      error.response.data.message = error.response.data.error_message
      delete error.response.data.error_message
    }
  }

  if (process.env.NODE_ENV !== 'developments' &&
    error.response.status >= parseInt(process.env.VUE_APP_LOG_LEVEL) &&
    error.response.request.responseURL !== process.env.VUE_APP_ROOT_API + 'logs'
  ) {
    error.response = setEndTime(error.response)
    sendLogData(error.response, 'http_bad_request')
    // index.dispatch('getErrors', {}).then()
    // index.dispatch('getErrors', error.response.data).then()
  }
  if (error.response.status === 401) {
    const url = error.response.config.url
    if (!url.includes('/invites/confirm_action/auth')) {
      index.dispatch('getErrors', {}).then()
      index.dispatch('getErrors', error.response.data).then()
      index.dispatch('logout').then()
    }
  }
  if (error.response.status === 402) {
    // console.log(1, error)
    index.dispatch('getErrors', {}).then()
    index.dispatch('getErrors', error.response.data).then()
    index.dispatch('paymentRequired').then()
    // index.dispatch('logout').then()
  }
  if (error.response.status === 400) {
    // index.dispatch('getErrors', {}).then()
    // index.dispatch('getErrors', error.response.data).then()
  }
  // toDo если обрабатывать 404, нужно написать исключение для подтверждения приглашения в компанию
  // if (error.response.status === 404) {
  //   index.dispatch('getErrors', {}).then()
  //   index.dispatch('getErrors', error.response.data).then()
  // }
  return Promise.reject(error)
})

/**
 * Добавляем в request время отправки
 */
function setStartTime (config) {
  config.metadata = { startTime: new Date() }
  return config
}

/**
 * Добавляем в response время получения и рассчитываем продолжительность запроса
*/
function setEndTime (response) {
  response.config.metadata.endTime = new Date()
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime
  return response
}

/**
 * Логирование медленных запросов и ошибок
 */
function sendLogData (response, errorType) {
  const data = {
    api_endpoint: response.config.url,
    request_time: response.duration,
    error_type: errorType,
    request_status: response.status,
    app_route: window.location.pathname,
    params: window.location.search.toString(),
    user_agent: navigator.userAgent,
    method: response.config.method
  }
  index.dispatch('setLog', data)
}

export const HTTP = {
  get: (url, payload) => {
    // блокируем лоадер для запросов, уходящих поллингом
    const confirmation = /\/companies\/[0-9]+\/shifts\/confirmation/g
    const attendance = /\/companies\/[0-9]+\/attendance/g
    const invoices = /\/companies\/[0-9]+\/invoices\/unpaided/g

    if (!url.match(confirmation) && !url.match(attendance) && !url.match(invoices)) {
      // app.config.globalProperties.$eventBus.emit('loading', true)
    }
    const responseType = payload && 'responseType' in payload
    if (responseType) {
      axiosBasic.defaults.responseType = payload.responseType
    }
    return axiosBasic.get(url, payload).then(response => {
      // app.config.globalProperties.$eventBus.emit('loading', false)
      // index.dispatch('getErrors', {})
      if (responseType) {
        axiosBasic.defaults.responseType = 'json'
      }
      return response.data
    }).catch(error => {
      // console.log(2, error)
      index.dispatch('getErrors', error.response.data)
      // app.config.globalProperties.$eventBus.emit('loading', false)
      if (responseType) {
        axiosBasic.defaults.responseType = 'json'
      }
      return Promise.reject(error)
    })
  },
  post: (url, payload, config = null) => {
    // app.config.globalProperties.$eventBus.emit('loading', true)
    return axiosBasic.post(url, payload, config).then(response => {
      // app.config.globalProperties.$eventBus.emit('loading', false)
      // index.dispatch('getErrors', {})
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return Promise.reject(error)
    })
  },
  put: (url, payload) => {
    // app.config.globalProperties.$eventBus.emit('loading', true)
    return axiosBasic.put(url, payload).then(response => {
      // index.dispatch('getErrors', {})
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return response.data
    }).catch(error => {
      index.dispatch('getErrors', error.response.data)
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return Promise.reject(error)
    })
  },
  patch: (url, payload) => {
    // app.config.globalProperties.$eventBus.emit('loading', true)
    return axiosBasic.patch(url, payload).then(response => {
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return response.data
    }).catch(error => {
      // console.log(1, error.response.data)
      if (error && error.response && error.response.data) {
        index.dispatch('getErrors', error.response.data)
      }
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return Promise.reject(error)
    })
  },
  delete: (url, payload) => {
    // app.config.globalProperties.$eventBus.emit('loading', true)
    return axiosBasic.delete(url, payload).then(response => {
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return response.data
    }).catch(error => {
      // console.log(2, error.response.data)
      index.dispatch('getErrors', error.response.data)
      // app.config.globalProperties.$eventBus.emit('loading', false)
      return Promise.reject(error)
    })
  }
}

/**
 * Функция для кеширования запросов: проверяет стор на наличие данных и в случае успеха возвращает данные из стора
 * без запроса к серверу, иначе отправляет http запрос на сервер
 *
 * @param url String
 * @param config Object || null
 * @param stateModule String store module name
 * @param stateField String store getter name
 * @returns {*}
 */

export const cacheRequest = function (url, config, stateModule, stateField) {
  if (index.state[stateModule][stateField] === null) {
    return HTTP.get(url, config)
  }
  if (typeof index.state[stateModule][stateField] === 'object' && Object.keys(index.state[stateModule][stateField]).length < 1) {
    return HTTP.get(url, config)
  }
  if (Array.isArray(index.state[stateModule][stateField]) && index.state[stateModule][stateField].length < 1) {
    return HTTP.get(url, config)
  }
  return new Promise(resolve => {
    resolve(index.state[stateModule][stateField])
  })
}
