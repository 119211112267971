export const scheduleDataTemplate = {
  schedule_type: 'by_pattern',
  pattern_type: '2/2',
  name: 'My first schedule',
  color: '#4e93e9',
  date_from: '2022-11-01 00:00:00',
  date_to: '2022-11-30 00:00:00',
  company_id: null,
  project_id: null,
  work_days: [0, 1, 2, 3, 4, 5, 6],
  rules: [],
  users: [],
  preview: false,
  rate: 0,
  week_overtime_control_type: 'confirm_overtime',
  working_hours: true,
  break_between_shifts: 0,
  minimum_breaks_in_row: 0,
  maximum_breaks_in_row: 0,
  visible_everyone: false,
  visible_everyone_type: 'all',
  shift_exchange: false,
  shift_exchange_confirmation: false,
  allow_put_on_freeboard: false,
  allow_put_on_freeboard_confirmation: false,
  allow_take_from_freeboard: false,
  allow_take_from_freeboard_confirmation: false,
  allow_exchange_break_in_diff_templates: false,
  shifts_templates: [
    {
      id: null,
      name: 'Shift 1',
      break_time: 60,
      color: '#958d19',
      by_days: false,
      night_shift: false,
      allow_break: false,
      workplacesTotal: 1,
      workplaces: [1, 1, 1, 1, 1, 1, 1],
      breaks: [],
      users: [],
      time_from: '09:00',
      time_to: '18:00',
      marks: []
    }
  ],
  generated_id: null,
  week_days_off: 0,
  out_schedule_shifts_action: 'ignore'
}
