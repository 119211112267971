<template>
  <div>
    <div class="small">
      {{ resource.extendedProps.position }}
    </div>
    <span class="h-auto">
      <span
          class="font-weight-bold"
          :style="{ 'color': resource.extendedProps.color ? resource.extendedProps.color : false }">
        {{ resource.title }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ResourceItem',
  props: {
    resource: Object
  }
}
</script>

<style scoped>

</style>
