export const coffeePositions = ['Baker', 'Barista', 'Waiter', 'Confectioner', 'Cashier', 'Manager', 'Administrator', 'Chef', 'Cook Assistant', 'Buyer']
export const retail = ['Store administrator', 'Storekeeper', 'Cafe manager', 'Accountant', 'Manager', 'Packer', 'Secretary', 'Sales Representative', 'Assistant']
export const healthcare = ['Dentist', 'Nurse', 'Paramedic', 'Therapist', 'Doctor', 'Veterinarian', 'Pharmacist', 'Ophthalmologist', 'Orthodontist', 'Surgeon']
export const fitness = ['Hairdresser', 'Cosmetologist', 'Masseur', 'Eyelash Extension Master', 'Nutritionist', 'Makeup Artist', 'Dermatologist', 'Manicurist', 'Colorist', 'Barber']
export const entertainment = ['Tour guide', 'Producer', 'Dancer', 'Animators', 'Singer', 'Illusionist', 'Presenter', 'Event organizer', 'DJ']
export const nonProfit = ['Office Manager', 'Account Representative', 'Nonprofit Administrator', 'Accountant', 'Executive Director', 'Social Worker', 'Political Analyst', 'Recruiter', 'Human Resources Representative', 'Project Manager']
export const other = ['Driver', 'Foreman', 'Trainer', 'Volunteer', 'Supervisor', 'Operations manager', 'Receptionist', 'Coordinator', 'Expeditor']
export const callCenter = ['Incoming line operator', 'Outgoing line operator', 'Supervisor', 'Training manager', 'Quality specialist', 'Director', 'Operator', 'Chat operator']
export const bank = ['Banker', 'Financial analyst', 'Credit expert', 'Auditor', 'Accountant', 'Cashier', 'Manifold', 'Analyst', 'Head of department', 'Security guard']
export const cleaning = ['Cleaning manager', 'Cleaner', 'Cleaning woman', 'Dishwasher', 'Administrator']
export const shop = ['Salesman', 'Administrator', 'Cashier', 'Manager', 'Purchaser', 'Guard', 'Advisor']
export const hotel = ['Administrators', 'Hotel manager', 'Housemaid', 'Cook', 'Waiter', 'Security guard', 'Porter', 'Technician', 'Barman', 'Animator', 'Hostess']
export const security = ['Shift manager', 'Guard', 'Bodyguard', 'Driver']
export const delivery = ['Courier', 'Administrator', 'Goods receiver', 'Postman', 'Carrier']
export const construction = ['Secretary', 'Locksmith', 'Collector of furniture', 'Electrician', 'Plumber', 'Electronics repair']
