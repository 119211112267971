<template>
  <modal-component :id="'ForecastingLoadDemandModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Forecast for the period')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer">
          <div class="row">
            <div class="col-6">
              <ui-date-picker
                :label="$t('Select forecast period')"
                :picker-type="'daterange'"
                :name="'range'"
                v-model="range"
                :start-placeholder="$t('Start date')"
                :end-placeholder="$t('End date')"
                :validation="{required: true}"
                :clearable="false"
              />
            </div>
            <div class="col-6">
              <ui-select
                :label=" $t('STEP_VALUE')"
                :hint="$t('STEP_VALUE_HINT')"
                name="actionType"
                v-model="step"
                :clearable="false"
                :filterable="false"
                class="w-100"
                :options="stepList"
                :key-name="'value'"
                :label-name="'label'"
                :value-name="'value'"
                :disabled="true"
                :validation="{required: true}"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <div class="font-weight-bold">
                {{ $t('REPEATED_DATES_ACTION')}} <ui-hint :content="$t('REPEATED_DATES_ACTION_HINT')"/>
              </div>
              <div class="mt-2">
                <ui-radio
                  name="replace"
                  :label="$t('Replace')"
                  v-model="repeatedDatesAction"
                  :set-value="'replace'"
                />
                <ui-radio
                  name="skip"
                  :label="$t('Skip')"
                  v-model="repeatedDatesAction"
                  :set-value="'skip'"
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import momentMixin from '../../../../mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '../../../../mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'CreateLoadDemandModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      range: [moment().startOf('isoWeek'), moment().endOf('isoWeek').add(1, 'minute')],
      step: 60,
      stepList: [
        {
          label: this.$t('60 min'),
          value: 60
        }
        // add steps here
      ],
      repeatedDatesAction: 'replace'
    }
  },
  props: {
    project_tz: String,
    scheduleId: Number,
    doNotSave: {
      type: Boolean,
      default: false
    }
  },
  created () {
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('close')
    },
    save () {
      const payload = {
        date_from: this.fromZoneToZone(this.range[0], this.tz, 'UTC').format(this.backendDateTimeFormat),
        date_to: this.fromZoneToZone(this.range[1], this.tz, 'UTC').add(1, 'day').format(this.backendDateTimeFormat),
        repeated_dates_action: this.repeatedDatesAction,
        preview: false
      }

      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          if (!this.doNotSave) {
            this.$store.dispatch('createForecasting', [this.scheduleId, payload]).then(response => {
              this.toastSuccess(this.$i18n?.t('Forecast successfully created!'))
              this.$emit('reload')
              this.closeModal()
            }).catch(e => {
              this.toastError(this.$i18n?.t(e.message))
            })
          } else {
            this.$emit('reload', payload)
            this.closeModal()
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
