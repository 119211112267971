<template>
  <div>
    <div class="row pb-2">
      <div class="col-lg-12">
        <div class="text-primary text-uppercase font-weight-bold">{{ $t('Webhooks') }}</div>
        <div v-for="(hook, index) in webhooks" :key="index" class="card my-2">
          <webhook-form-component
            :hook="hook"
            :appId="appId"
          />
        </div>
        <div class="text-right">
          <button
            v-if="this.showAddButton"
            class="btn btn-sm btn-primary rounded-pill mx-1"
            role="button"
            @click="addHook">
            <i class="icon-plus icon-default text-white"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebhookFormComponent from '@/components/Developer/Components/WebhookFormComponent'
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColors from '@/mixins/mixinColors'

export default {
  name: 'Authorization',
  mixins: [errorMixin, mixinColors],
  components: {
    WebhookFormComponent
  },
  data () {
    return {
      webhooks: [],
      appId: parseInt(this.$route.params.appId)
    }
  },
  props: {
    app: Object
  },
  mounted () {
    this.$eventBus.on('updateDeveloperWebhooks', this.getWebhooks)
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    showAddButton () {
      if (!this.isEmployeeHasPermission('create webhook')) {
        return false
      }
      return true
    },
    webhookEvents () {
      return this.$store.getters.webhookEvents
    }
  },
  created () {
    this.getWebhooks()
    this.$store.dispatch('getWebhookEventsList', this.companyId)
  },
  methods: {
    getWebhooks () {
      console.log('updateDeveloperWebhooks', 'getWebhooks')
      this.$store.dispatch('getDeveloperAppWebhooks', [this.companyId, this.appId]).then(response => {
        this.webhooks = response
      })
    },
    updateHooksList () {
      this.webhooks.forEach(item => {
        if (!item.events) {
          item.events = {}
        }
        this.webhookEvents.forEach(model => {
          if (!item.events[model]) {
            item.events[model] = {
              event_model: model,
              event_type: {
                created: false,
                updated: false,
                deleted: false
              }
            }
          }
        })
      })
    },
    addHook () {
      this.webhooks.push({
        is_active: false,
        secret: null,
        callback_url: null,
        events: {}
      })
      this.updateHooksList()
    }
  }
}
</script>

<style scoped>

</style>
