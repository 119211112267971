<template>
  <div class="mt-2">
    <span class="mt-2 name-color font-weight-bold">
      {{$t('Project')}}: {{request.data.project_name}}
    </span>
    <span class="mt-2 mx-2 name-color font-weight-bold">
      {{$t('Schedule')}}: {{request.data.schedule_name}}
    </span>
    <span class="project-color" :style="{ backgroundColor: request.data.schedule_color ? request.data.schedule_color : color }"></span>
    <span class="mt-2 name-color font-weight-bold ml-2">
      {{$t('Shift')}}: {{ templateName }}
    </span>
    <span class="project-color mx-2" :style="{ backgroundColor: color }"></span>
  </div>
</template>

<script>
export default {
  name: 'ShiftTitle',
  props: {
    request: Object
  },
  data () {
    return {
      color: null,
      templateName: ''
    }
  },
  created () {
    this.color = this.request.data.shift_color
    if (this.request.subtype === 'create_shift') {
      if (this.request.data.template_id === 0) {
        this.templateName = this.$t('Custom template')
      } else {
        this.templateName = this.request.template ? this.request.template.name : this.$t('Custom template')
      }
    } else {
      this.templateName = this.request.template ? this.request.template.name : this.$t('Custom template')
    }

    // console.log(this.request)
  }
}
</script>

<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
