import { HTTP } from '@/api/http_utils'

const UPDATE_EMPLOYEES_GEOLOCATION = 'UPDATE_EMPLOYEES_GEOLOCATION'
const UPDATE_EMPLOYEES_GEOLOCATION_FILTER = 'UPDATE_EMPLOYEES_GEOLOCATION_FILTER'
const UPDATE_GEOLOCATION_FILTER = 'UPDATE_GEOLOCATION_FILTER'

const state = {
  employees_geolocation: {},
  geolocation_filters: {
    project_id: null,
    period: []
  },
  display_filters: {
    employees_filter: [],
    only_last_point: true
  }
}

const promises = {}

const getters = {
  employeesGeolocation: state => Object.values(state.employees_geolocation),
  employeesGeolocationIndexed: state => state.employees_geolocation,
  geolocationFilters: state => state.geolocation_filters,
  displayFilters: state => state.display_filters
}

const mutations = {
  [UPDATE_EMPLOYEES_GEOLOCATION] (state, data) {
    const employeesGeolocationById = {}
    data.forEach(item => {
      employeesGeolocationById[item.id] = item
    })
    state.employees_geolocation = employeesGeolocationById
  },
  [UPDATE_GEOLOCATION_FILTER] (state, payload) {
    state.geolocation_filters = payload
  },
  [UPDATE_EMPLOYEES_GEOLOCATION_FILTER] (state, data) {
    state.display_filters = {
      employees_filter: data.employees,
      only_last_point: data.onlyLastPoint
    }
  }
}

const actions = {
  updateGeolocationFilter ({ commit }, filter) {
    commit(UPDATE_GEOLOCATION_FILTER, filter)
  },
  updateGeolocationEmployeeFilter ({ commit }, filter) {
    commit(UPDATE_EMPLOYEES_GEOLOCATION_FILTER, filter)
  },
  getEmployeesLocation ({ commit }, [companyId, queryGeolocation]) {
    const promiseKey = `/companies/${companyId}/employees-location${queryGeolocation}`

    if (promises[promiseKey]) {
      console.error('repeating requests employees-location')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/employees-location${queryGeolocation}`).then(response => {
        commit(UPDATE_EMPLOYEES_GEOLOCATION, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
