<template>
  <div>
    <div id="breakAccordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            {{ $t('BREAK_MODULE_SETTINGS') }}
            <ui-hint v-if="moduleInfo" :content="moduleInfo"/>
          </h5>
        </div>
        <div
          id="collapseBreakSettings"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#breakAccordion">
          <div class="card-body">
            <modal-component
              :id="'BreakSettingsModal'"
              @closeModal="closeModal"
              :modalClass="'modal-dummy'"
              :modalBodyClass="'dummy-popup'"
              :hideFooter="true"
              :hideHeader="true"
              >
              <template #body>
                <dummy-payment-required
                  @activate="activate"
                  :dummyInfo="dummyInfo"
                  :isModal="true">
                </dummy-payment-required>
              </template>
            </modal-component>
            <Form ref="observer" as="div" class="form-row">
              <div class="col-auto"
                   @click="handleActivation"
                   :data-toggle="!isModuleActive ? 'modal' : null"
                   :data-target="!isModuleActive ? '#BreakSettingsModal' : null">
                <div class="form-row">
                  <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('BREAK_ACTIONS_TIME') }}
                    </span>
                    <ui-hint :content="$t('BREAK_ACTIONS_TIME_HINT')" />
                  </el-divider>
                  <div class="col-lg-12 mb-1">
                    <ui-number-input
                      name="break_actions_time"
                      :label="$t('BREAK_ACTIONS_TIME_SET_MINUTES')"
                      v-model="settings.break_actions_time"
                      :validation="{required: true, integer: true, min: 0}"
                      :precision="0"
                      :step="1"
                      :min="0"
                      class="w-50"
                      :disabled="!isModuleActive"
                    />
                  </div>
                  <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('BREAK_WITHOUT_PLANNING') }}
                      <ui-hint :content="$t('BREAK_WITHOUT_PLANNING_HINT')" />
                    </span>
                  </el-divider>
                  <div class="font-weight-bold text-warning mt-2">{{ $t('NEED_ATTENDANCE_MODULE') }}</div>
                  <div class="col-lg-12 mt-2 mb-1">
                    <ui-checkbox
                      name="settings.allow_unplanning_break"
                      v-model="settings.allow_unplanning_break"
                      :disabled="!isModuleActive"
                      :label="$t('ALLOW_BREAK_WITHOUT_PLANNING')"
                    />
                  </div>
                  <div class="mx-2">
                    <div class="col-lg-12 mb-1">
                      <ui-checkbox
                        name="settings.is_limited_unplanning_break"
                        v-model="settings.is_limited_unplanning_break"
                        :disabled="!isModuleActive || !settings.allow_unplanning_break"
                        :label="$t('IS_LIMITED_UNPLANNING_BREAK')"
                      />
                    </div>
                    <div class="col-lg-12 mb-1">
                      <ui-number-input
                        :disabled="!isModuleActive || !settings.allow_unplanning_break || !settings.is_limited_unplanning_break"
                        name="unplanning_break_limit"
                        :label="$t('UNPLANNING_BREAK_LIMIT')"
                        v-model="settings.unplanning_break_limit"
                        :validation="{required: true, integer: true, min_value: 1}"
                        :precision="0"
                        :step="1"
                        :min="1"
                        class="w-50"
                      />
                    </div>
                    <div class="col-lg-12 my-2">
                      <ui-checkbox
                        name="settings.billable"
                        v-model="settings.billable"
                        :disabled="!isModuleActive || !settings.allow_unplanning_break"
                        :label="$t('BREAK_BILLABLE_BY_DEFAULT')"
                        :hint="$t('BREAK_BILLABLE_BY_DEFAULT_HINT')"
                      />
                    </div>
                    <div class="col-lg-12 mb-1">
                      <ui-number-input
                        :disabled="!isModuleActive || !settings.allow_unplanning_break || settings.allow_dynamic_break"
                        name="default_length"
                        :label="$t('BREAK_DEFAULT_LENGTH')"
                        :hint="$t('BREAK_DEFAULT_LENGTH_HINT')"
                        v-model="settings.default_length"
                        :validation="{required: true, integer: true, min_value: 0}"
                        :precision="0"
                        :step="1"
                        :min="0"
                        class="w-50"
                      />
                    </div>
                    <div class="col-lg-12 my-2">
                      <ui-checkbox
                        name="settings.allow_dynamic_break"
                        v-model="settings.allow_dynamic_break"
                        :disabled="!isModuleActive || !settings.allow_unplanning_break"
                        :label="$t('allow_dynamic_break')"
                        :hint="$t('DYNAMIC_BREAK_HINT')"
                      />
                    </div>
                    <div
                      class="col-lg-12"
                      v-if="settings.allow_dynamic_break"
                      :disabled="!isModuleActive || !settings.allow_dynamic_break">
                      <div
                        v-for="(item, item_key) in settings.dynamic_breaks"
                        :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}"
                        class="p-1 rounded"
                        :key="item_key">
                        <BreakItem
                          :item="item"
                          :index="item_key"
                          :showItemConfig="true"
                          :disabledActions="!isModuleActive || !settings.allow_dynamic_break || !settings.allow_unplanning_break"
                          @deleteItem="deleteItem(item_key)"
                          @validateResult="validateResult">
                        </BreakItem>
                      </div>
                    </div>
                    <div class="col-lg-12 pt-2">
                      <button
                        class="btn btn-success btn-sm rounded-pill btn-fix-width shadow-sm mx-auto"
                        aria-label="Close"
                        :disabled="!isModuleActive || !settings.allow_dynamic_break || !settings.allow_unplanning_break"
                        @click="addItem">
                        {{ $t('Add item') }}
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-2 mb-1">
                    <el-divider content-position="left">
                    <span class="text-primary text-uppercase font-weight-bold">
                      {{ $t('ACTION_WITH_UNCLOSED_BREAKS') }}
                    </span>
                      <ui-hint :content="$t('ACTION_WITH_UNCLOSED_BREAKS_HINT')" />
                    </el-divider>
                    <label class="control-label font-weight-bold">{{ $t('BREAK_ACTION_AFTER_END_TIME') }}</label>
                    <ui-radio
                      name="settings.action_after_end_time"
                      :label="$t('Send Message')"
                      v-model="settings.action_after_end_time"
                      :set-value="'send_message'"
                      :disabled="!isModuleActive"
                    />
                    <ui-radio
                      name="settings.action_after_end_time"
                      :label="$t('Close')"
                      v-model="settings.action_after_end_time"
                      :set-value="'close'"
                      :disabled="!isModuleActive"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-lg-12 form-group text-center pt-2">
        <button
          type="submit"
          name="submit"
          class="btn btn-success rounded-pill shadow-sm"
          @click="submitForm"
          :disabled="!isModuleActive"
        >
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BreakItem from '@/components/Company/ModulesPage/BreakItem'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'SettingsBreaks',
  components: {
    ModalComponent,
    DummyPaymentRequired,
    BreakItem
  },
  mixins: [mixinDummy],
  data () {
    return {
      isModuleActive: false,
      dummyInfo: {},
      dummy: false,
      module: null,
      settings: {
        allow_dynamic_break: false,
        dynamic_breaks: [
          {
            shift_time: 0,
            break_time: 0
          }
        ],
        allow_unplanning_break: false,
        billable: false,
        action_after_end_time: 'send_message',
        default_length: 60,
        break_actions_time: 60,
        is_limited_unplanning_break: false,
        unplanning_break_limit: 5
      },
      moduleInfo: null
    }
  },
  created () {
    this.init()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      ModulesByCompany: 'ModulesByCompany'
    })
  },
  methods: {
    validateResult (value) {
      this.counter++
      if (!value) this.validateErrors++
    },
    addItem () {
      this.settings.dynamic_breaks.push({
        shift_time: 0,
        break_time: 0
      })
    },
    deleteItem (itemKey) {
      this.settings.dynamic_breaks.splice(itemKey, 1)
    },
    async activate () {
      this.init()
      this.$eventBus.emit('reloadDummy', 'breaks')
    },
    init () {
      this.ModulesByCompany.forEach((module) => {
        if (+module.module_id === 23) {
          this.module = module
          this.moduleInfo = this.module.module.locales && this.module.module.locales[this.locale]
            ? this.module.module.locales[this.locale].description
            : this.module.module.description
          if (!Array.isArray(this.module.settings)) {
            this.settings.allow_unplanning_break = module.settings.allow_unplanning_break ? module.settings.allow_unplanning_break : false
            this.settings.billable = module.settings.billable ? module.settings.billable : false
            this.settings.action_after_end_time = module.settings.action_after_end_time ? module.settings.action_after_end_time : 'send_message'
            this.settings.default_length = module.settings.default_length ? parseInt(module.settings.default_length) : 60
            this.settings.break_actions_time = module.settings.break_actions_time ? parseInt(module.settings.break_actions_time) : 60
            this.settings.unplanning_break_limit = module.settings.unplanning_break_limit ? parseInt(module.settings.unplanning_break_limit) : 5
            this.settings.is_limited_unplanning_break = module.settings.is_limited_unplanning_break ? module.settings.is_limited_unplanning_break : false
            this.settings.allow_dynamic_break = module.settings.allow_dynamic_break ? module.settings.allow_dynamic_break : false
            this.settings.dynamic_breaks = module.settings.dynamic_breaks && module.settings.dynamic_breaks.length > 0
              ? module.settings.dynamic_breaks
              : [{ shift_time: 0, break_time: 0 }]
          }
        }
      })

      this.$store.dispatch('isModuleActive', [this.$store.getters.companyId, 24]).then((e) => {
        // console.log(e)
        this.isModuleActive = true
        this.$emit('breaksActivated')
      }).catch(err => {
        this.isModuleActive = false
        this.dummyInfo = this.setupDummyData(err.response.data)
        this.moduleInfo = err.response.data.module.locales && err.response.data.module.locales[this.locale]
          ? err.response.data.module.locales[this.locale].description
          : err.response.data.module.description
      })
    },
    closeModal () {
      this.dummy = false
    },
    handleActivation () {
      this.dummy = true
    },
    async submitForm () {
      try {
        const result = await this.$refs.observer.validate()
        if (result.valid) {
          await this.$store.dispatch('updateModuleSettings', [
            this.$store.getters.companyId,
            this.module.id,
            {
              settings: this.settings
            }
          ])
          this.$emit('success')
        }
      } catch (error) {
        console.log(error)
        this.$emit('error')
      }
    }
  }
}
</script>

<style scoped>

</style>
