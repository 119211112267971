<template>
  <div class="row my-1 d-flex align-items-start">
    <div class="col-3 my-1">
      <ui-text-input
        :label="$t('DEVELOPER_APP_FIELD_NAME')"
        :hint="$t('DEVELOPER_APP_FIELD_NAME_HINT')"
        :name="index + '__field_name'"
        v-model="itemState.field_name"
        :validation="{required: true, min: 3, max: 255, regex: /^[a-z0-9A-Z_-]{3,255}$/}"
        :placeholder="$t('Please input')"
      />
    </div>
    <div class="col-3 my-1">
      <ui-text-input
        :label="$t('DEVELOPER_APP_FIELD_TITLE')"
        :hint="$t('DEVELOPER_APP_FIELD_TITLE_HINT')"
        :name="index + '__field_title'"
        v-model="itemState.field_title"
        :validation="{required: true, max: 255, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
        :placeholder="$t('Please input')"
      />
    </div>
    <div class="col-3 my-1">
      <ui-select
        :name="index + '__types'"
        :label="$t('DEVELOPER_APP_FIELD_TYPE')"
        v-model="itemState.field_type"
        :clearable="false"
        :filterable="false"
        class="w-100"
        :options="typeChoices"
        :key-name="'id'"
        :label-name="'name'"
        :value-name="'id'"
        @change="typeChange"
      />
    </div>
    <div class="col-2 my-1">
      <label class="control-label font-weight-bold">{{$t('DEVELOPER_APP_FIELD_OPTIONS')}}</label>
      <div v-if="['select', 'multi_select'].includes(itemState.field_type)">
        <el-tag
          :key="option"
          :name="index + '__options'"
          v-for="option in itemState.options"
          closable
          class="my-1"
          effect="plain"
          :disable-transitions="false"
          @close="handleClose(option)">
          {{option}}
        </el-tag>
        <template v-if="inputVisible">
          <Form ref="opObserve" as="div">
            <div class="form-inline">
              <ui-text-input
                :name="index + '__multiselect_item_name'"
                v-model="inputValue"
                class="w-50"
                :validation="{required: true, min: 1, max: 60, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
                :placeholder="$t('Please input')"
                ref="saveTagInput"
                @keyup="handleInputConfirm"
              />
              <i class="icon-check btn-icon text-success mx-1 my-auto"
                 @click="handleInputConfirm"
                 role="button">
              </i>
              <i class="icon-x btn-icon text-danger mx-1 my-auto"
                 @click="handleInputCancel"
                 role="button">
              </i>
            </div>
          </Form>
        </template>
        <el-button v-else class="my-1" size="small" @click="showInput">{{$t('ITEM_NEW_OPTION')}}</el-button>
      </div>
    </div>
    <div class="col-auto my-1 text-center">
      <el-tooltip
        :content="$t('CHECKLIST_ITEM_REQUIRED')"
        placement="top"
        effect="light">
        <div class="pb-1">
          <el-icon :size="18"><Flag /></el-icon>
        </div>
      </el-tooltip>
      <div>
        <ui-checkbox
          :name="index + '__required'"
          v-model="itemState.required"
        />
      </div>
    </div>
    <div class="col-auto m-1 d-flex text-center">
      <i class="icon-trash btn-icon text-danger my-auto"
          @click="deleteItem()"
          role="button">
      </i>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'

export default {
  name: 'FieldItem',
  mixins: [errorMixin],
  data () {
    return {
      inputVisible: false,
      inputValue: '',
      typeChoices: [
        {
          id: 'text',
          name: this.$t('text')
        },
        {
          id: 'number',
          name: this.$t('number')
        },
        {
          id: 'checkbox',
          name: this.$t('checkbox')
        },
        {
          id: 'select',
          name: this.$t('select')
        },
        {
          id: 'multi_select',
          name: this.$t('multi-select')
        },
        {
          id: 'date_range_picker',
          name: this.$t('date-range')
        },
        {
          id: 'date_picker',
          name: this.$t('date-picker')
        },
        {
          id: 'secret_key',
          name: this.$t('secret key')
        }
      ],
      itemState: {}
    }
  },
  props: {
    item: Object,
    validateItem: Boolean,
    index: Number
  },
  created () {
    this.itemState = this.item
  },
  methods: {
    deleteItem () {
      this.$emit('deleteItem')
    },
    handleClose (tag) {
      this.itemState.options.splice(this.itemState.options.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
    },
    handleInputConfirm () {
      this.$refs.opObserve.validate('multiselect_item_name').then(result => {
        if (result.valid) {
          const inputValue = this.inputValue
          if (inputValue) {
            if (!this.itemState.options) this.itemState.options = []
            this.itemState.options.push(inputValue)
          }
          this.inputVisible = false
          this.inputValue = ''
        }
      })
    },
    handleInputCancel () {
      this.inputVisible = false
      this.inputValue = ''
      // this.errors.clear()
    },
    typeChange (type) {
      if (!['select', 'multi_select'].includes(type) || !this.itemState.options) {
        this.itemState.options = []
      }
    }
  }
}
</script>

<style scoped>
  .el-tag {
    margin-left: 3px;
    margin-right: 3px;
  }
  .el-tag + .el-button {
    margin-left: 3px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
