<template>
  <div>
    <h3 class="mb-2">{{ $t('Add and invite employees') }}</h3>
    <p class="mb-4">{{ $t('EMPLOYEES_STEP_DESCRIPTION') }}</p>
    <form>
      <div class="form-row">
        <div class="col">
          <label>{{ $t('First name') }}</label>
        </div>
        <div class="col">
          <label>{{ $t('Last name') }}</label>
        </div>
        <div class="col">
          <label>{{ $t('E-mail (optional)') }}</label>
        </div>
        <div class="col">
          <label>{{ $t('Select position') }}</label>
        </div>
        <div class="col-0" style="width: 40px;"></div>
      </div>

      <div class="form-inputs-wrapper">
        <div class="form-row mb-2" v-for="(employee, index) in mutatedEmployees" :key="index">
          <div class="col">
            <ui-text-input
              :name="index + 'first_name'"
              v-model="employee.first_name"
              :validation="{required: false}"
              :placeholder="$t('First name')"
              :disabled="index === 0"
            />
          </div>
          <div class="col">
            <ui-text-input
              :name="index + 'last_name'"
              v-model="employee.last_name"
              :validation="{required: false}"
              :placeholder="$t('Last name')"
              :disabled="index === 0"
            />
          </div>
          <div class="col">
            <ui-text-input
              :name="index + 'email'"
              v-model="employee.email"
              :validation="{email: true}"
              :placeholder="$t('E-mail')"
              :disabled="index === 0"
              @change="employee.email_check = false"
            />
          </div>
          <div class="col">
            <ui-select
              :name="index + '__position'"
              v-model="employee.position"
              :options="selectedPositions"
              :placeholder="$t('Select position')"
              :clearable="true"
            />
          </div>
          <div class="col-0 d-flex justify-content-center align-items-center" style="width: 40px;">
            <i v-if="index !== 0" role="button" class="icon-x btn-icon p-2" @click="removeEmployee(index)"></i>
          </div>
        </div>
      </div>
    </form>
    <button class="btn btn-outline-primary" type="button" @click="addEmployee">{{ $t('Add more employees') }}</button>
  </div>
</template>

<script>
export default {
  name: 'EmployeesStep',
  props: {
    selectedPositions: {
      type: Array,
      required: true
    },
    employees: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      mutatedEmployees: this.employees,
      employeeTemp: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        position: '',
        employee_id: null,
        project_id: null,
        identifier: null,
        invite: false,
        email_check: false
      },
      employeesCount: 2
    }
  },
  created () {
    this.pushEmployeesToArr()
  },
  watch: {
    mutatedEmployees () {
      this.$emit('refreshEmployees', this.mutatedEmployees)
    }
  },
  methods: {
    addEmployee () {
      this.mutatedEmployees.push({
        ...this.employeeTemp
      })
    },
    removeEmployee (indexToDelete) {
      this.mutatedEmployees = this.mutatedEmployees.filter((_, index) => index !== indexToDelete)
    },
    pushEmployeesToArr () {
      for (let step = 0; step < this.employeesCount; step++) {
        this.mutatedEmployees.push({ ...this.employeeTemp })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
