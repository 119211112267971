<template>
  <div>
    <div class="font-weight-bold mb-2 mt-2">
      {{ $t('BONUS_PENALTI_IMPORT_MATCH_ACTION_DESCRIPTION') }}
    </div>
    <table class="table table-hover">
      <thead v-if="filePreview">
      <tr>
        <th v-for="(item, key) in filePreview[0]" :key="key">
          <ui-select
            :validation="{required: false}"
            :name="key.toString()"
            v-model="fileRows[key]"
            :options="select_options"
            :key-name="'index'"
            :label-name="'name'"
            :value-name="'index'"
            :clearable="true"
            @change="changeSelect"
            :disabled-option-callback="checkUsedOptions"
          />
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, row_key) in filePreview" :key="row_key">
        <td v-for="(item, key) in row" :key="key">
          {{ item }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="col">
      <ui-checkbox
        name="skip_first_row"
        v-model="skip_first_row"
        @change="changeSkipFirstRow"
      >
        {{ $t('Skip first row') }}
      </ui-checkbox>
    </div>
    <div class="col">
      <span>{{ $t('bonus_penalty_help_info') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MatchTableAction',
  data () {
    return {
      fileRows: {},
      skip_first_row: false,
      select_options: [
        { name: this.$i18n?.t('E-mail'), index: 'email_index' },
        { name: this.$i18n?.t('Phone number') + ' *', index: 'phone_index' },
        { name: this.$i18n?.t('Date') + ' *', index: 'date_index' },
        { name: this.$i18n?.t('Time from') + ' *', index: 'time_from_index' },
        { name: this.$i18n?.t('Time to') + ' *', index: 'time_to_index' },
        { name: this.$i18n?.t('Bonus/Penalty') + ' *', index: 'bonus_penalty_index' }
      ]
    }
  },
  props: {
    filePreview: Array,
    rows: {
      type: Object,
      default: function () {
        return { }
      }
    }
  },
  created () {
    this.fileRows = this.rows
  },
  methods: {
    checkUsedOptions ({ index }) {
      if (Object.values(this.fileRows).indexOf(index) !== -1) {
        return true
      }
      return false
    },
    changeSelect () {
      this.$emit('setFileRows', this.fileRows)
    },
    changeSkipFirstRow () {
      this.$emit('skipFirstRow', this.skip_first_row)
    }
  }
}
</script>
