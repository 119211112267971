// Auth
export const AUTH = 'AUTH'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
// Profile
export const SET_PROFILE = 'SET_PROFILE'
export const DROP_PROFILE = 'DROP_PROFILE'
export const SET_EMAIL_CONFIRMATION = 'SET_EMAIL_CONFIRMATION'
export const SET_USER_COUNTRY = 'SET_USER_COUNTRY'
export const SET_USER_CITY = 'SET_USER_CITY'
export const SET_USER_STATE = 'SET_USER_STATE'
export const GET_USER_AVAILABILITY = 'GET_USER_AVAILABILITY'
export const CHANGE_AVATAR = 'CHANGE_AVATAR'
// Utils
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_LANGUAGES = 'GET_LANGUAGES'
export const GET_PROFESSIONS = 'GET_PROFESSIONS'
export const GET_EDUCATIONS = 'GET_EDUCATIONS'
export const GET_EXPERIENCE = 'GET_EXPERIENCE'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_CITIES = 'GET_CITIES'
export const GET_CITY = 'GET_CITY'
export const GET_COUNTRY_NAMES = 'GET_COUNTRY_NAMES'
export const GET_SUBDIVISION_NAMES = 'GET_SUBDIVISION_NAMES'
export const GET_CITY_NAMES = 'GET_CITY_NAMES'
export const GET_USER_INFO_BY_ID = 'GET_USER_INFO_BY_ID'
export const GET_INVITE = 'GET_INVITE'
export const GET_ALL_ZONES = 'GET_ALL_ZONES'
export const GET_COUNTRY_ZONES = 'GET_COUNTRY_ZONES'
export const GET_FILES = 'GET_FILES'
export const GET_COMPANY_FILES = 'GET_COMPANY_FILES'
export const GET_AVATAR = 'GET_AVATAR'
export const SET_AVATAR_HASH = 'SET_AVATAR_HASH'
export const SET_COMPANY_AVATAR_HASH = 'SET_COMPANY_AVATAR_HASH'

// Company
export const GET_COMPANY = 'GET_COMPANY'
// export const GET_COMPANY_FIELDS = 'GET_COMPANY_FIELDS'
export const GET_COMPANY_LIST = 'GET_COMPANY_LIST'
export const GET_COMPANY_INVITED_USERS = 'GET_COMPANY_INVITED_USERS'
export const CLEAR_COMPANY = 'CLEAR_COMPANY'
export const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED'
export const COMPANY_PROJECT_LIMIT = 'COMPANY_PROJECT_LIMIT'

// Projects
export const SET_PROJECT_ROLE = 'SET_PROJECT_ROLE'
export const PROJECT_LIMIT = 'PROJECT_LIMIT'

// Errors
export const API_ERRORS = 'API_ERRORS'
// Requests
export const GET_REQUEST = 'GET_REQUEST'
export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUESTS_ARCHIVE = 'GET_REQUESTS_ARCHIVE'
// Alerts
export const GET_ALERTS = 'GET_ALERTS'
// Shedules
// Stats
export const GET_STATS = 'GET_STATS'
export const SET_GENERATION_INFO = 'SET_GENERATION_INFO'
// Payments
export const GET_BILLING_ADDRESS = 'GET_BILLING_ADDRESS'
// Modules
export const GET_MODULES_BY_COMPANY = 'GET_MODULES_BY_COMPANY'
export const SET_MODULES_BY_COMPANY = 'SET_MODULES_BY_COMPANY'
// дата последнего показа модалки со ссылками на мобильные приложения
export const LAST_SEEN_DATE_MOBILE = 'LAST_SEEN_DATE_MOBILE'

export const COMPANY_SCHEDULES = 'COMPANY_SCHEDULES'
export const COMPANY_TEMPLATES = 'COMPANY_TEMPLATES'

export const EMPLOYEE_PERMISSIONS = 'EMPLOYEE_PERMISSIONS'
export const IS_MANAGER = 'IS_MANAGER'
