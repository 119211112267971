export default {
  data () {
    return {
      colorsChoices: [
        '#4e93e9',
        '#da6f5b',
        '#9762bf',
        '#74b65f',
        '#820049',
        '#d7569c',
        '#e28b23',
        '#71553a',
        '#6f6f6f',
        '#958d19',
        '#005392',
        '#961800',
        '#00accb',
        '#527048',
        '#4d2c5e',
        '#008FFF',
        '#003BFF',
        '#0900FF',
        '#5600FF',
        '#330096',
        '#955FFF',
        '#CF00FF',
        '#8700A7',
        '#E058FF',
        '#FF00BE',
        '#B20084',
        '#FF46D0',
        '#FF005E',
        '#BE0046',
        '#FF498C',
        '#FF0100',
        '#AF0000',
        '#CD3237',
        '#932427',
        '#D96468',
        '#C639B0',
        '#89287A',
        '#D671C6',
        '#853EC1',
        '#5C2B85',
        '#A46ED1',
        '#3A3DC5',
        '#272985',
        '#6668D2',
        '#309ACF',
        '#226C92',
        '#4FA9D6',
        '#5B92A4',
        '#3F6572',
        '#80AAB9',
        '#71619E',
        '#4C426B',
        '#8A7DAF',
        '#9F609C',
        '#764774',
        '#B382B1',
        '#A45B60',
        '#794346',
        '#B87D81'
      ]
    }
  }
}
