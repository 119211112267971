<template>
  <div>
    <div class="row p-1">
      <div class="col">
        <ui-multi-select
          :label="$t('Select individual users')"
          v-model="filters.usersMultiplySelect"
          :filterable="true"
          name="filter_users_multiply_select"
          :options="usersMultiplySelectChoices"
          :key-name="'key'"
          :label-name="'name'"
          :value-name="'id'"
          :multiple-limit="usersShow"
        />
        <ui-multi-select
          :label="$t('SELECT_POSITIONS')"
          name="filter_positions_multiply_select"
          v-model="filters.usersPositions"
          :filterable="true"
          :options="positions"
          :key-name="'key'"
          :label-name="'name'"
          :value-name="'id'"
          :multiple-limit="usersShow"
          :disabled="positions.length < 1"
        />
        <ui-multi-select
          :label="$t('SELECT_SCHEDULES')"
          name="filter_schedules_multiply_select"
          v-model="filters.schedulesMultiplySelect"
          :filterable="true"
          :options="schedulesMultiplySelectChoices"
          :key-name="'key'"
          :label-name="'name'"
          :value-name="'id'"
          :disabled="schedulesMultiplySelectChoices.length < 1"
        />
        <ui-multi-select
          name="SELECT_LOCATIONS"
          :label="$t('SELECT_LOCATIONS')"
          v-model="filters.byLocations"
          :filterable="true"
          :options="locations"
          :key-name="'id'"
          :label-name="'title'"
          :value-name="'id'"
          :disabled="locations.length < 1"
        />
        <ui-tags-select
          name="SELECT_EMPLOYEE_MARKS"
          :label="$t('SELECT_EMPLOYEE_MARKS')"
          v-model="filters.byEmployeeMarks"
          :filterable="true"
          :options="projectMarks"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
          :disabled="projectMarks.length < 1"
        >
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
        <ui-tags-select
          name="SELECT_SHIFT_MARKS"
          :label="$t('SELECT_SHIFT_MARKS')"
          v-model="filters.byShiftMarks"
          :filterable="true"
          :options="projectMarks"
          :key-name="'id'"
          :label-name="'name'"
          :value-name="'id'"
          :disabled="projectMarks.length < 1"
        >
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.name }}
          </template>
        </ui-tags-select>
        <ui-tags-select
          name="SELECT_SHIFT_STATUSES"
          :label="$t('SELECT_SHIFT_STATUSES')"
          v-model="filters.byStatuses"
          :filterable="true"
          :options="statusChoices"
          :key-name="'id'"
          :label-name="'value'"
          :value-name="'id'"
        >
          <template v-slot:default="slotProps">
            <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
            {{ slotProps.option.value }}
          </template>
        </ui-tags-select>
        <div class="mt-2 d-flex justify-content-between">
          <button class="btn btn-primary btn-sm rounded-pill shadow-sm" type="button" @click="applyFilter">
            <i class="icon-search" />
          </button>
          <button class="btn btn-secondary btn-sm rounded-pill shadow-sm" type="button" @click="showAllUsers">
            {{ $t('Show all') }}
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import mixinStatusColors from '@/mixins/mixinStatusColors'

export default {
  mixins: [mixinStatusColors],
  name: 'FullCalendarFilter',
  data () {
    return {
      filters: {
        usersMultiplySelect: [],
        byEmployeeMarks: [],
        byShiftMarks: [],
        byLocations: [],
        usersPositions: [],
        schedulesMultiplySelect: [],
        byStatuses: []
      },
      marksType: 'byUser',
      usersMultiplySelectChoices: [],
      schedulesMultiplySelectChoices: [],
      sortedUsersResources: [],
      statusChoices: [
        {
          id: 'late',
          value: this.$t('STATUS_LATE'),
          color: ''
        },
        {
          id: 'early',
          value: this.$t('STATUS_EARLY'),
          color: ''
        },
        {
          id: 'absent',
          value: this.$t('STATUS_ABSENT'),
          color: ''
        },
        {
          id: 'started',
          value: this.$t('STATUS_STARTED'),
          color: ''
        },
        {
          id: 'finished',
          value: this.$t('STATUS_FINISHED'),
          color: ''
        }
      ],
      role: this.$store.getters.companyRole,
      userFilterType: 'user'
    }
  },

  props: {
    usersShow: Number,
    currentProject: Number,
    positions: Array
  },
  created () {
    this.statusChoices.forEach(option => {
      option.color = this.statusColors[option.id]
    })
  },
  computed: {
    ...mapGetters({
      projectMarks: 'projectMarks'
    }),
    companyId () {
      return this.$store.getters.companyId
    },
    locations () {
      return this.$store.getters.locations.filter(l => !l.hide)
    },
    resources () {
      return this.$store.getters.fc_resources
    },
    schedulesByProject () {
      return this.$store.getters.schedulesInfo
    }
  },

  watch: {
    resources () {
      this.listenResources()
    },
    sortedUsersResources () {
      this.init()
    },
    currentProject () {
      this.showAllUsers()
      this.init()
    },
    companyId () {
      this.init()
    }
  },
  methods: {
    listenResources () {
      this.sortedUsersResources = JSON.parse(JSON.stringify(this.resources))
      this.init()
    },
    init () {
      this.$store.dispatch('getLocations', this.companyId).then(response => {
        // this.locations = this.$store.getters.locations
      }).catch(() => {
        // TODO: missing catch
      })
      this.schedulesMultiplySelectChoices = []
      this.usersMultiplySelectChoices = []
      const usersMultiplySelectChoices = []
      const sortedUsersResources = JSON.parse(JSON.stringify(this.sortedUsersResources))
      sortedUsersResources.forEach(value => {
        if (value.id !== 0 && value.id !== 'info_event' && value.id !== 'holidays') {
          usersMultiplySelectChoices.push({ id: value.id, name: value.nameWithIdentifier })
        }
      })
      this.usersMultiplySelectChoices = usersMultiplySelectChoices.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
      }).sort((a, b) => {
        if (a.name === undefined || b.name === undefined) {
          return 0
        }
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
      const schedulesMultiplySelectChoices = []
      if (this.schedulesByProject) {
        Object.keys(this.schedulesByProject).forEach(key => {
          schedulesMultiplySelectChoices.push({
            id: key,
            name: this.schedulesByProject[key]
          })
        })
      }
      this.schedulesMultiplySelectChoices = schedulesMultiplySelectChoices
    },
    /**
     * Предупреждаем пользователя о длительности загрузки данных для всех пользователей
     */
    showAllUsers () {
      this.filters.usersMultiplySelect = []
      this.filters.usersPositions = []
      this.filters.byEmployeeMarks = []
      this.filters.byShiftMarks = []
      this.filters.byLocations = []
      this.filters.schedulesMultiplySelect = []
      this.filters.byStatuses = []
      this.applyFilter()
    },
    /**
     * Обрабатываем изменение фильтров на форме
     * @param filter - принимает значение фильтра который используется
     */
    applyFilter () {
      this.$store.dispatch('updateFilters', {
        values: this.filters
      })
      // todo это костыль, чтобы обновлялась статистика после наложения фильтров, исправить на компанишедуле не получилось, нужно будет исправить
      setTimeout(() => {
        this.$store.dispatch('updateFilters', {
          values: this.filters
        })
      }, 500)
    }
  }
}
</script>

<style lang="css" scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
  .el-select :deep(.el-select__tags-text) {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
</style>
