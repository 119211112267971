<template>
  <ui-tooltip
      :show-after="openDelay"
      :hide-after="hideDelay"
      tooltip-class="w-100"
  >
    <template #hint>
      <div>
        <div>{{tooltip}}</div>
        <template v-if="event.client_id">
          <div>{{ event.client.name }}</div>
          <div>{{ event.client.address }}</div>
        </template>
      </div>
    </template>
    <template #inner-html>
      <div class="fc-content py-1 text-truncate rounded"
           data-toggle="modal"
           data-target="#ClickTaskModal"
           :style="{
           backgroundColor: color,
           cursor: 'pointer'
         }"
           :data-event="dataSet">
        <div style="position: relative">
          <div style="color: white">
            <span class="ml-1 overflow-hidden">{{ event.title }}</span>
          </div>
          <div class="ml-1" v-if="event.client_id" style="color: white">
            <div>{{ event.client.name }}</div>
            <div>{{ event.client.address }}</div>
          </div>
          <div class="ml-1" v-if="event.marks.length > 0">
            <ui-tooltip v-for="mark in event.marks" :key="mark">
              <template #hint>
                {{taskMarksIndexed[mark].name}}
              </template>
              <template #inner-html>
                <span
                  class="project-color mr-1"
                  :style="{ backgroundColor: taskMarksIndexed[mark].color }"
                />
              </template>
            </ui-tooltip>
          </div>
        </div>
      </div>
    </template>
  </ui-tooltip>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'BacklogTaskComponent',
  mixins: [momentMixin],
  data () {
    return {
      openDelay: 1000,
      hideDelay: 4000,
      tags: [],
      markTemplate: '',
      tooltipEnable: true
    }
  },
  props: {
    event: Object,
    view: Object
  },
  computed: {
    taskMarksIndexed () {
      return this.$store.getters.taskMarksIndexed
    },
    dataSet () {
      return JSON.stringify({
        id: this.event.id,
        title: this.event.title,
        displayEventTime: false,
        start: this.view.activeStart,
        end: this.view.activeStart,
        resourceId: this.event.employee_id,
        color: this.color,
        item: this.event,
        startEditable: true,
        durationEditable: false
      })
    },
    color () {
      let color = '#00accb'
      if (this.event.logs && this.event.logs.length > 0) {
        color = this.event.logs[this.event.logs.length - 1].status.color
      }
      return color
    },
    viewType () {
      return this.view.type
    },
    start () {
      const start = new Date(this.event.start)
      return moment.parseZone(start)
    },
    end () {
      const end = new Date(this.event.end)
      return moment.parseZone(end)
    },
    durationHrs () {
      const duration = moment.duration(this.end.diff(this.start)).asHours()
      return Math.round(duration * 100) / 100
    },
    tooltip () {
      return this.event.title
    },
    /** Проверяем, есть ли у шифта переход между датами (00:00 - 00:00 считается одним днем, если длительность шифта = 1 день) */
    isSameDay () {
      return moment(this.start.format(this.backendDateFormat), this.backendDateFormat).isSame(moment(this.end.format(this.backendDateFormat), this.backendDateFormat), 'day') ||
        (this.start.format(this.backendTimeFormat) === this.end.format(this.backendTimeFormat) &&
          this.start.format(this.backendTimeFormat) === '00:00' &&
          this.start.diff(this.end, 'days') === -1)
    },
    isTwoDays () {
      // console.log(123, this.lang)
      return (this.view.type === 'resourceTimeMonth' || this.view.type === 'resourceTimelineWeek') &&
        !this.isSameDay
    }
  },
  methods: {
    setView (view) {
      // eslint-disable-next-line vue/no-mutating-props
      this.view = view
    }
  }
}
</script>

<style scoped>
.confirmed {
  z-index: 100;
  position: absolute; /* Абсолютное позиционирование */
  top: -8px; /* Положение от верхнего края */
  right: 1px; /* Положение от правого края */
}
.red-circle {
  background: red;
  border: 1px solid white;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
/*.project-color {*/
/*width: 20px;*/
/*height: 20px;*/
/*display: inline-block;*/
/*border-radius: 25px;*/
/*vertical-align: middle;*/
/*}*/
</style>
