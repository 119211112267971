<template>
  <div class="row">
    <div v-if="!isEdit" class="col">
      <div class="my-1">
        {{$t('Shift')}}: {{ convertTime(taskState.shift.time_from) }} - {{ convertTime(taskState.shift.time_to) }}
      </div>
      <div class="my-1">
        {{$t('Employee')}}: {{employeeName}}
      </div>
      <div class="my-1">
        {{ $t("Scheduled time") }}: {{ taskState.scheduled_time ? convertTime(taskItem.scheduled_time) : $t('NO_SCHEDULED_TIME_SET') }}
      </div>
      <div class="my-1">
        <el-tooltip
          v-for="mark in taskState.marks"
          :key="mark"
          :content="taskMarksIndexed[mark].name"
          placement="top" effect="light">
          <span class="project-color mr-2"
                :style="{ backgroundColor: taskMarksIndexed[mark].color }"
          />
        </el-tooltip>
      </div>
    </div>
    <div v-if="isEdit" class="col-12">
      <Form ref="subtaskObserver" as="div">
        <SubtaskItemComponent
            @itemUpdated="itemUpdated"
            :task="taskItem"
        />
      </Form>
    </div>
    <div v-if="!isParentInfo" class="col-auto my-auto text-right" :class="{
      'col-auto': !isEdit,
      'col-12': isEdit
    }">
      <i v-if="isEdit"
        class="icon-check btn-icon text-success mx-1"
        @click="handleSave">
      </i>
      <i
        class="btn-icon text-primary mx-1"
        :class="isEdit ? 'icon-x' : 'icon-pencil'"
        @click="changeIsEdit">
      </i>
      <i
        class="icon-trash btn-icon text-danger mx-1"
        @click="handleDelete">
      </i>
    </div>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import SubtaskItemComponent from '@/components/Tasks/Task/Components/SubtaskItemComponent'
import moment from 'moment'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'SubtaskInfoComponent',
  components: { SubtaskItemComponent },
  mixins: [momentMixin, ToastMixin],
  data () {
    return {
      isEdit: false,
      taskItem: {
        task_id: null,
        shift_id: null,
        company_id: null,
        schedule_id: null,
        scheduled_time: moment().format(this.localeDateTimeFormat),
        lead_time: 0,
        color: null,
        name: '',
        time_from: moment().format(this.localeDateTimeFormat),
        time_to: moment().format(this.localeDateTimeFormat),
        calculatedLeadTime: moment().format(this.localeDateTimeFormat),
        tasksCount: 0,
        selected: true,
        marks: []
      },
      taskState: {}
    }
  },
  props: { task: Object, tz: String, isParentInfo: Boolean },
  created () {
    this.taskState = this.task
    this.taskItem = {
      task_id: this.taskState.id,
      shift_id: this.taskState.shift_id,
      company_id: this.taskState.company_id,
      schedule_id: this.taskState.schedule_id,
      scheduled_time: this.taskState.scheduled_time,
      lead_time: this.taskState.lead_time,
      color: this.taskState.shift.color,
      name: this.taskState.shift.employee.full_name,
      time_from: this.taskState.shift.time_from,
      time_to: this.taskState.shift.time_to,
      calculatedLeadTime: 0,
      tasksCount: 0,
      selected: true,
      marks: this.taskState.marks
    }
  },
  computed: {
    taskMarksIndexed () {
      return this.$store.getters.taskMarksIndexed
    },
    employeeName () {
      return this.taskItem.name ? this.taskItem.name : this.$t('FREE_BOARD_EMPLOYEE_TITLE')
    }
  },
  methods: {
    changeIsEdit () {
      this.isEdit = !this.isEdit
    },
    handleDelete () {
      this.$emit('handleDelete', this.taskState.id)
    },
    convertTime (time) {
      try {
        return this.fromZoneToZone(time, 'UTC', this.tz).format(this.localeDateTimeFormat)
      } catch (e) {
        // console.log(e)
      }
    },
    itemUpdated (item) {
      const taskSchedTime = moment(this.taskState.scheduled_time).format(this.backendDateTimeFormat)
      const itemSchedTime = this.fromZoneToZone(item.scheduled_time, this.tz, 'UTC').format(this.backendDateTimeFormat)

      if ((this.taskState.scheduled_time || item.scheduled_time) && taskSchedTime !== itemSchedTime) {
        this.taskState.scheduled_time = itemSchedTime
      }

      this.taskState.lead_time = item.lead_time
      this.taskState.marks = item.marks
      this.taskState.shift_id = item.shift_id
      this.taskState.shift.employee.full_name = item.name
    },
    handleSave () {
      this.$refs.subtaskObserver.validate().then(result => {
        if (result.valid) {
          const payload = Object.assign({}, this.taskState)
          this.$store.dispatch('updateTask', [this.companyId, this.taskState.id, payload])
            .then((response) => {
              this.taskItem = {
                task_id: response.id,
                shift_id: response.shift_id,
                company_id: response.company_id,
                schedule_id: response.schedule_id,
                scheduled_time: response.scheduled_time,
                lead_time: response.lead_time,
                color: response.shift.color,
                name: response.shift.employee.full_name,
                time_from: response.shift.time_from,
                time_to: response.shift.time_to,
                calculatedLeadTime: 0,
                tasksCount: 0,
                selected: true,
                marks: response.marks
              }
              this.toastSuccess(this.$t('Task updated'))
              this.$emit('taskUpdated')
              this.isEdit = false
            })
            .catch(() => {})
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
.project-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 25px;
  vertical-align: middle;
}
</style>
