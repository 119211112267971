<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage, handleChange }"
    :rules="validation"
    as="div"
  >
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required"/>
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
      <el-date-picker
        :model-value="value"
        v-bind="field"
        @update:model-value="handleChange"
        @change:model-value="handleChange"
        @blur:model-value="handleChange"
        class="w-100"
        size="large"
        type="month"
        :placeholder="placeholder"
        :teleported="false"
      />
    </el-form-item>
  </Field>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'month-picker',
  mixins: [momentMixin],
  emits: ['update:modelValue'],

  props: {
    name: String,
    validation: Object,
    value: [String, Object, Date, Array],
    modelValue: [String, Object, Date, Array],
    clearable: Boolean,
    disabled: Boolean,
    label: String,
    hint: String,
    placeholder: String
  },
  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        console.log(23234, value)
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
