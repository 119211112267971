<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/projects`">
              <span class="breadcrumb-item">{{ $t('Projects') }}</span>
            </router-link>
          </li>
          <li v-if="this.$route.params['projectId']" class="breadcrumb-item title-text active" aria-current="page">
            {{ this.$i18n?.t('Edit project') }}
            <ui-hint :content="$t('PROJECT_HINTS')"/>
          </li>
          <li v-if="!this.$route.params['projectId']" class="breadcrumb-item title-text active" aria-current="page">
            {{ this.$i18n?.t('Create project') }}
            <ui-hint :content="$t('PROJECT_HINTS')"/>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <Form ref="observer" v-slot="{ meta }" as="div">
          <div class="row">
            <div class="col-md-3">
              <ui-text-input
                name="name"
                :label="$t('Project name')"
                v-model="project.name"
                :validation="{required: true, min: 3, max: 128, regex: /[?$<>{}*^=\p{L}\d]/u}"
              />
            </div>
            <div class="col-md-3">
              <ui-color-picker
                name="color"
                v-model="project.color"
                :label="$t('Choose a color:')"
              />
            </div>
            <div class="col-md-6">
              <ui-select
                name="timeZone"
                :label="$t('In what Time Zone will users work on your project?')"
                :hint="$t('PROJECT_TZ_HINT')"
                v-model="project.timeZone"
                :disabled="disabled"
                :filterable="true"
                class="w-100"
                :options="allZones"
              />
              <div v-show="disabled" class="text-info small mt-2">{{ $t('PROJECT_WITH_SCHEDULES_INFO') }}</div>
            </div>
          </div>
          <div class="row mt-2">
            <div v-if="salaryForManager" class="col-md-6 mt-2">
              <div class="">
                <ui-number-input
                  name="rate"
                  :label="$t('Basic pay rate for users working on the project') + ' ' + currency + ' ' + $t('per hour')"
                  :hint="$t('BASIC_PAY_RATE_HINTS')"
                  v-model="project.rate"
                  :validation="{required: true, decimal: 2}"
                />
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <ui-select
                name="location"
                :label="$t('BASE_LOCATION_SELECT')"
                :hint="$t('BASE_LOCATION_SELECT_HINT')"
                v-model="project.default_location_id"
                :clearable="true"
                :filterable="true"
                class="w-100"
                :options="filteredLocations"
              />
            </div>
          </div>
          <div class="row mb-2 mt-2">
            <div class="col-md-6 mt-2">
              <div class="row">
                <div class="col">
                  <label class="control-label font-weight-bold">{{ $t('Users') }} ({{ project.users.length }})</label>
                </div>
                <div class="col">
                  <a href="#" class="control-label font-weight-bold"
                     :class="lang === 'he' ? 'float-left' : 'float-right'"
                     style="cursor: pointer" @click="selectAll('user')">{{ $t('Select all') }}</a>
                </div>
              </div>
              <ui-multi-select
                name="users"
                v-model="project.users"
                class="w-100"
                :filterable="true"
                :options="usersChoices"
              />
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                <ui-multi-select
                  name="managers"
                  :label="$t('Managers') + ' (' + project.managers.length + ')'"
                  :hint="$t('PROJECT_MANAGERS_HINT')"
                  v-model="project.managers"
                  class="w-100"
                  :filterable="true"
                  :options="usersChoices"
                  :disabled="!isEmployeeHasPermission('create project')"
                />
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div class="row">
                <div class="col">
                  <label class="control-label font-weight-bold">{{ $t('Viewers') }} ({{ project.viewers.length }})</label>
                </div>
                <div class="col">
                  <a href="#" class="control-label font-weight-bold"
                    :class="lang === 'he' ? 'float-left' : 'float-right'"
                    style="cursor: pointer" @click="selectAll('viewer')">{{ $t('Select all') }}</a>
                </div>
              </div>
              <ui-multi-select
                name="viewers"
                v-model="project.viewers"
                class="w-100"
                :filterable="true"
                :options="usersChoices"
              />
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                <ui-checkbox
                  name="shifts_visible_everyone"
                  v-model="project.shifts_visible_everyone"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_project_shifts_visible_everyone') }}
                    <ui-hint :content="$t('key_project_shifts_visible_everyone_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
              <div class="mb-1">
                <ui-checkbox
                  name="shifts_exchange"
                  :disabled="!project.shifts_visible_everyone"
                  v-model="project.shifts_exchange"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_project_shifts_exchange') }}
                    <ui-hint :content="$t('key_project_shifts_exchange_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
              <div class="mb-2">
                <ui-checkbox
                  name="shifts_exchange_confirmation"
                  class="ml-3"
                  :disabled="!project.shifts_exchange"
                  v-model="project.shifts_exchange_confirmation"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_project_shifts_exchange_confirmation') }}
                    <ui-hint :content="$t('key_project_shifts_exchange_confirmation_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
              <div class="mb-1">
                <ui-checkbox
                  name="shifts_take_freeboard"
                  :disabled="!project.shifts_visible_everyone"
                  v-model="project.shifts_take_freeboard"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_project_shifts_take_freeboard') }}
                    <ui-hint :content="$t('key_project_shifts_take_freeboard_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
              <div class="mb-2">
                <ui-checkbox
                  name="shifts_take_freeboard_confirmation"
                  class="ml-3"
                  :disabled="!project.shifts_take_freeboard"
                  v-model="project.shifts_take_freeboard_confirmation"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_project_shifts_take_freeboard_confirmation') }}
                    <ui-hint :content="$t('key_project_shifts_take_freeboard_confirmation_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
              <div class="mb-1">
                <ui-checkbox
                  name="block_change_in_past_for_manager"
                    :disabled="!isEmployeeHasPermission('update-shift-admin')"
                    v-model="project.block_change_in_past_for_manager"
                >
                  <div class="d-flex align-items-end">
                    {{ $t('key_block_change_in_past_for_manager') }}
                    <ui-hint :content="$t('key_block_change_in_past_for_manager_HINT')" />
                  </div>
                </ui-checkbox>
              </div>
            </div>
          </div>
          <div class="py-2">
            <div class="row justify-content-center">
              <button
                class="btn btn-success btn-circle shadow-sm"
                :class="{'disabled': !meta.valid}" :disabled="!meta.valid"
                type="button"
                @click="saveProject">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import ToastMixin from '@/mixins/ToastMixin'

export default {
  name: 'Project',
  mixins: [errorMixin, ToastMixin],

  beforeRouteLeave (to, from, next) {
    if (this.isProjectChanged) {
      this.$confirm(this.$i18n?.t('Do you want to save the changes you\'ve made?'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('SAVE_WEB'),
        cancelButtonText: this.$i18n?.t('Cancel'),
        type: 'warning',
        center: true
      }).then(() => {
        this.saveProject(next)
      }).catch(() => {
        next()
      })
    } else {
      next()
    }
  },

  data () {
    return {
      buttonText: this.$i18n?.t('Create schedule'),
      currency: '',
      isProjectChanged: false,
      project: {
        name: '',
        color: '#8A2BE2',
        // возможно по умолчанию тайм зона пользователя или компании
        timeZone: this.$store.getters.profileTimeZone,
        managers: [],
        users: [],
        viewers: [],
        rate: undefined,
        default_location_id: null,
        shifts_visible_everyone: false,
        shifts_exchange: false,
        shifts_exchange_confirmation: false,
        shifts_take_freeboard: false,
        shifts_take_freeboard_confirmation: false,
        block_change_in_past_for_manager: false
      },
      projectCopy: {
        name: '',
        color: '#8A2BE2',
        // возможно по умолчанию тайм зона пользователя или компании
        timeZone: this.$store.getters.profileTimeZone,
        managers: [],
        users: [],
        rate: undefined
      },
      disabled: false
    }
  },

  watch: {
    companyId () {
      this.init()
    },
    project: {
      handler (project) {
        if (!project.shifts_visible_everyone) {
          this.project.shifts_exchange_confirmation = false
          this.project.shifts_exchange = false
          this.project.shifts_take_freeboard = false
          this.project.shifts_take_freeboard_confirmation = false
        }

        if (!project.shifts_exchange) {
          this.project.shifts_exchange_confirmation = false
        }

        if (!project.shifts_take_freeboard) {
          this.project.shifts_take_freeboard_confirmation = false
        }
        this.isProjectChanged = !isEqual(project, this.projectCopy)
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      preloadUsers: 'employeesIndexed',
      projectsLimit: 'projectsLimit',
      locations: 'locations',
      allZones: 'allZones'
    }),
    salaryForManager () {
      if (this.isEmployeeHasPermission('create project')) {
        return true
      }
      return this.$store.state.company.company.salary.show_salary_to_managers === undefined ? true : this.$store.state.company.company.salary.show_salary_to_managers
    },
    lang () {
      return this.$store.getters.locale
    },
    filteredLocations () {
      return this.locations
        .filter(item => +item.id === +this.project.default_location_id || !item.hide)
        .map(location => {
          return { id: location.id, name: location.title }
        })
    },
    usersChoices () {
      const options = []
      Object.keys(this.preloadUsers).forEach((id) => {
        options.push({ id: +id, name: this.getFirstLastName(id) })
      })

      return options.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })
    }
  },

  created () {
    if (this.companyId) {
      this.init()
    }
  },
  methods: {
    init () {
      if (this.$route.params.projectId) {
        if (!(this.isEmployeeHasPermission('get projects by company') ||
          this.isEmployeeHasPermission('get related projects full data'))) {
          this.accessErrorRedirect('You don\'t have permission for this action')
        }
      } else {
        if (!this.isEmployeeHasPermission('create project')) {
          this.accessErrorRedirect('You don\'t have permission for this action')
        } else if (this.projectsLimit < 1) {
          this.accessErrorRedirect('You can no longer create projects as you have reached the limit')
        }
      }
      if (!this.$route.params.projectId) {
        this.$store.dispatch('getCompany', this.companyId).then((response) => {
          if (response) {
            if ('salary' in response && !this.project.rate) {
              this.project.rate = response.salary.basic_rate
              this.rate = response.salary.basic_rate
            }
            this.currency = response.salary.currency
          }
        })
      }
      if (this.$route.params.projectId) {
        this.$store.dispatch('getSchedulesByProject', [this.$route.params.projectId]).then((response) => {
          this.disabled = response.length > 0
        })
        this.editProject()
      }
      this.$store.dispatch('getLocations', this.companyId)
      this.$store.dispatch('getTimeZones')
      this.$store.dispatch('getEmployeesByCompany', this.companyId)
    },

    selectAll (role = 'user' | 'viewer') {
      const self = this
      if (role === 'user') {
        this.project.users = []
        this.usersChoices.forEach(function (item) {
          self.project.users.push(item.id)
        })
      } else {
        this.project.viewers = []
        this.usersChoices.forEach(function (item) {
          self.project.viewers.push(item.id)
        })
      }
    },

    editProject () {
      this.$store.dispatch('getProject', this.$route.params.projectId).then((project) => {
        this.project.name = project.name
        this.project.color = project.color
        this.project.shifts_visible_everyone = project.shifts_visible_everyone
        this.project.shifts_exchange = project.shifts_exchange
        this.project.shifts_exchange_confirmation = project.shifts_exchange_confirmation
        this.project.shifts_take_freeboard = project.shifts_take_freeboard
        this.project.shifts_take_freeboard_confirmation = project.shifts_take_freeboard_confirmation
        this.project.timeZone = project.time_zone
        this.project.rate = project.rate * 1
        this.currency = this.$store.getters.currency
        this.project.managers = project.managers
        this.project.viewers = project.viewers ? project.viewers : []
        this.project.users = project.users
        this.project.default_location_id = project.default_location_id
        this.project.block_change_in_past_for_manager = project.block_change_in_past_for_manager
        this.projectCopy = Object.assign({}, this.project)

        // this.$refs.observer.setValues(this.project)
      })
    },

    getFirstLastName (id) {
      return this.preloadUsers[id].full_name
    },

    saveProject () {
      const projectData = {
        name: this.project.name,
        color: this.project.color,
        time_zone: this.project.timeZone,
        rate: this.project.rate * 1,
        managers: this.project.managers,
        users: this.project.users,
        viewers: this.project.viewers,
        shifts_visible_everyone: this.project.shifts_visible_everyone,
        shifts_exchange: this.project.shifts_exchange,
        shifts_exchange_confirmation: this.project.shifts_exchange_confirmation,
        shifts_take_freeboard: this.project.shifts_take_freeboard,
        shifts_take_freeboard_confirmation: this.project.shifts_take_freeboard_confirmation,
        default_location_id: this.project.default_location_id,
        block_change_in_past_for_manager: this.project.block_change_in_past_for_manager
      }

      this.$refs.observer.validate().then(isValid => {
        if (isValid) {
          if (this.$route.params.projectId) {
            this.$store.dispatch('editProject', [this.$route.params.projectId, projectData]).then(() => {
              this.projectCopy = Object.assign({}, this.project)
              this.isProjectChanged = false
              this.toastSuccess(this.$i18n?.t('Project successfully updated'))
            }).catch((err) => {
              this.toastError(this.$i18n?.t(err.response.data.message))
            })
          } else {
            projectData.company_id = this.$store.getters.companyId
            this.$store.dispatch('createProject', projectData).then(project => {
              localStorage.setItem('currentProject', project.id)
              this.$store.dispatch('updateProjectsLimit', +this.$store.getters.projectsLimit - 1).then(() => {
                this.projectCopy = Object.assign({}, this.project)
                this.isProjectChanged = false
                this.toastSuccess(this.$i18n?.t('Project successfully created'))
                this.$router.push({
                  name: 'Schedules'
                })
                // костыль, иначе выкидывает со страницы календаря, (код не видит ref scheduleCalendar, хз почему)
                if (this.$store.getters.projects.length === 1) {
                  location.reload()
                }
              })
            }).catch((err) => {
              this.toastError(this.$i18n?.t(err.response.data.message))
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
