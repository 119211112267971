<template>
  <div class="row mt-2">
    <div class="col-lg-12" id="days">
      <div class="my-1">
        <span class="font-weight-bold">{{ $t('SELECT_DAYS') }}</span>
        <ui-hint :content="$t(hint)" />
      </div>
      <div class="row">
        <div v-for="(day, index) in days" class="col-auto" :key="index">
          <ui-checkbox
            :name="index + '__d'"
            v-model="day.selected"
            :disabled="day.disabled"
            @change="onChangeDays"
          >
            <span @click="onChangeDays" class="text-primary font-weight-bold">
             {{ day.short }}
            </span>
          </ui-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectWeekDayComponent',
  data () {
    return {
      days: [
        {
          long: this.$i18n?.t('Monday'),
          short: this.$i18n?.t('Mon'),
          rRule: 'MO',
          selected: true,
          disabled: false,
          index: 0
        },
        {
          long: this.$i18n?.t('Tuesday'),
          short: this.$i18n?.t('Tue'),
          rRule: 'TU',
          selected: true,
          disabled: false,
          index: 1
        },
        {
          long: this.$i18n?.t('Wednesday'),
          short: this.$i18n?.t('Wed'),
          rRule: 'WE',
          selected: true,
          disabled: false,
          index: 2
        },
        {
          long: this.$i18n?.t('Thursday'),
          short: this.$i18n?.t('Thu'),
          rRule: 'TH',
          selected: true,
          disabled: false,
          index: 3
        },
        {
          long: this.$i18n?.t('Friday'),
          short: this.$i18n?.t('Fri'),
          rRule: 'FR',
          selected: true,
          disabled: false,
          index: 4
        },
        {
          long: this.$i18n?.t('Saturday'),
          short: this.$i18n?.t('Sat'),
          rRule: 'SA',
          selected: true,
          disabled: false,
          index: 5
        },
        {
          long: this.$i18n?.t('Sunday'),
          short: this.$i18n?.t('Sun'),
          rRule: 'SU',
          selected: true,
          disabled: false,
          index: 6
        }
      ],
      hint: 'SELECT_DAYS_HINT'
    }
  },
  props: {
    selection: Array,
    newHint: String
  },
  watch: {
    selection () {
      if (this.selection) {
        this.days.forEach(day => {
          day.selected = this.selection.includes(day.rRule)
        })
      }
    }
  },
  created () {
    const weekPart = this.days.splice(this.weekStart)
    this.days = weekPart.concat(this.days)
    if (this.newHint) {
      this.hint = this.newHint
    }
    if (this.selection) {
      this.days.forEach(day => {
        day.selected = this.selection.includes(day.rRule)
      })
    }
  },
  computed: {
    weekStart () {
      if (!this.$store.getters.company || !this.$store.getters.company.salary) {
        return 0
      }
      return this.$store.getters.company.salary.week_start
    }
  },
  methods: {
    onChangeDays () {
      const selected = []
      const rRule = []
      this.days.forEach(day => {
        if (day.selected) {
          selected.push(day.index)
          rRule.push(day.rRule)
        }
      })
      this.$emit('daysChanged', selected)
      this.$emit('rruleChanged', rRule)
    }
  }
}
</script>

<style scoped>
#days :deep(.el-checkbox) {
  display: block !important;
}
#days :deep(.el-checkbox) > .el-checkbox__label {
  display: block !important;
  margin-left: 0px;
  padding-left: 0px;
}
#days :deep(.el-checkbox) > .el-checkbox__input {
  display: block !important;
}
</style>
