import { HTTP } from '@/api/http_utils'

const state = {
  shiftBonus: null
}

const getters = {
  shiftBonus: state => state.shiftBonus
}
const SHIFT_BONUS = 'SHIFT_BONUS'
const mutations = {
  [SHIFT_BONUS] (state, data) {
    state.shiftBonus = data
  }
}

const actions = {
  getBonusesByCompany ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/bonuses?start=${payload.start}&end=${payload.end}&export=${payload.export}`, { responseType: payload.export ? 'blob' : 'json' }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getBonusByShift ({ commit }, [companyId, shiftId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/shifts/${shiftId}/bonuses`).then(response => {
        commit(SHIFT_BONUS, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createBonus ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/bonuses`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateBonus ({ commit }, [companyId, bonusId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/bonuses/${bonusId}`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteBonus ({ commit }, [companyId, bonusId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/bonuses/${bonusId}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  bonusPenaltyImportMatchFile ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.post('/companies/' + companyId + '/bonuses/import/match', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        resolve(response)
      })
    })
  },
  bonusPenaltyBatchStore ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.post('/companies/' + companyId + '/bonuses/batch/store', data).then(response => {
        resolve(response)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
