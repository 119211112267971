<template>
  <div id="loader" class="spinner-modal" :style="{display: display}">
    <div class="spinner-modal-content text-center">
      <sync-loader
        v-show="loader"
        :loading="loader"
        :color="'#32c5d2'"
        :size="'15px'">
      </sync-loader>
      <div class="text-primary font-weight-bold title-text mt-3">{{$t(label)}}</div>
    </div>
  </div>
</template>

<script>
import SyncLoader from 'vue-spinner/src/SyncLoader.vue'

export default {
  name: 'Loader',
  components: { SyncLoader: SyncLoader },
  data () {
    return {
      loader: false,
      display: 'none',
      label: ''
    }
  },
  mounted () {
    this.$eventBus.on('loading', this.toggleLoader)
    this.$eventBus.on('setLabel', this.setLabel)
  },
  methods: {
    toggleLoader (value) {
      if (!value) this.label = ''
      this.loader = value
      this.display = value ? 'block' : 'none'
    },
    setLabel (value) {
      this.label = value
    }
  }
}
</script>

<style scoped>
  .spinner-modal {
    position: fixed; /* Stay in place */
    z-index: 1060; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(255,255,255); /* Fallback color */
    background-color: rgba(255,255,255,0.8); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .spinner-modal-content {
    margin: 25% auto; /* 15% from the top and centered */
    padding: 20px;
    border: none;
    width: 80%; /* Could be more or less, depending on screen size */
  }
</style>
