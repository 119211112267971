<template>
  <div>
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <div class="container-fluid p-0" v-else-if="loaded">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th class="align-middle text-center table-header">
              {{$t('Name')}}
            </th>
            <th class="align-middle text-center table-header">
              {{$t('Date')}}
            </th>
            <th class="align-middle text-center table-header">
              {{$t('Amount')}}
            </th>
            <th class="align-middle text-center table-header">
              {{$t('Type')}}
            </th>
            <th class="align-middle text-center table-header">
              {{$t('Comment')}}
            </th>
            <th class="align-middle text-center table-header">
              {{ $t('bonus').charAt(0).toUpperCase() + $t('bonus').slice(1) }}
            </th>
            <th class="align-middle text-center table-header">
              {{ $t('penalty').charAt(0).toUpperCase() + $t('penalty').slice(1) }}
            </th>
            <th class="align-middle text-center table-header">
              {{ $t('advance').charAt(0).toUpperCase() + $t('advance').slice(1) }}
            </th>
            <th class="align-middle text-center table-header">
              {{$t('Total')}}
            </th>
          </tr>
          </thead>
          <tbody>
            <template v-if="Object.keys(rows).length > 0">
              <template v-for="employee in rows">
                <tr v-for="(bonus, index) in employee" :key="bonus.id">
                  <td v-if="index === 0" :rowspan="employee.length" class="align-middle">
                    <div>{{bonus.name}}</div>
                    <div class="small">
                      {{positionsByUsers[bonus['employeeId']] ? positionsByUsers[bonus['employeeId']].positionName : ''}}
                    </div>
                  </td>
                  <td class="align-middle">
                    <div>
                      {{bonus.dateFrom}} - {{bonus.dateTo}}
                    </div>
                    <div>
                      {{bonus.templateName}}
                    </div>
                  </td>
                  <td class="align-middle">{{bonus.amount}}</td>
                  <td class="align-middle">{{$t(bonus.type)}}</td>
                  <td class="align-middle">{{bonus.comment}}</td>
                  <td v-if="index === 0" :rowspan="employee.length" class="text-center font-weight-bold align-middle">
                    <div class="">{{ calculateBonus(employee) }}</div>
                  </td>
                  <td v-if="index === 0" :rowspan="employee.length" class="text-center font-weight-bold align-middle">
                    <div class="">{{ calculatePenalty(employee) }}</div>
                  </td>
                  <td v-if="index === 0" :rowspan="employee.length" class="text-center font-weight-bold align-middle">
                    <div class="">{{ calculateAdvance(employee) }}</div>
                  </td>
                  <td v-if="index === 0" :rowspan="employee.length" class="text-center font-weight-bold align-middle">
                    <div class="">{{ calculateBonus(employee) - calculatePenalty(employee) - calculateAdvance(employee) }}</div>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="6" class="text-center">
                  {{ $t('NO_DATA_AVAILABLE') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import errorMixin from '../../../mixins/mixinApiErrors'
import moment from 'moment'
import momentMixin from '../../../mixins/mixinMoment'
import DummyPaymentRequired from '../../CommonComponents/DummyPaymentRequired'
import { mapGetters } from 'vuex'
import mixinDummy from '@/mixins/mixinDummy'

export default {
  name: 'BonusPenalty',
  mixins: [errorMixin, momentMixin, mixinDummy],
  components: { DummyPaymentRequired: DummyPaymentRequired },
  data () {
    return {
      loaded: false,
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      tableData: {},
      rows: {}
    }
  },
  props: ['period', 'search', 'selectedMarks', 'marksByUsers', 'selectedPositions', 'positionsByUsers'],
  beforeMount () {
    this.getTableData()
  },
  computed: {
    ...mapGetters({
      locale: 'locale',
      profile: 'Profile'
    })
  },
  watch: {
    period: function () {
      this.getTableData()
    },
    search: function () {
      this.filteredData()
    },
    selectedMarks: function () {
      this.filteredByMarks()
    },
    selectedPositions () {
      this.filteredByPositions()
    }
  },
  beforeUnmount () {
    this.$eventBus.off('exportBonusPenaltyReport')
  },
  mounted () {
    this.$eventBus.on('exportBonusPenaltyReport', this.exportReport)
  },
  methods: {
    getTableDataActivated () {
      this.dummy = false
      this.getTableData()
    },
    exportReport () {
      this.getTableData(1)
    },
    getTableData (exportReport = 0) {
      this.loaded = false

      const payload = {
        start: this.toUTC(moment(this.period[0], this.backendDateFormat).format(this.localeDateTimeFormat)).format(this.backendDateTimeFormat),
        end: this.toUTC(moment(this.period[1], this.backendDateFormat).format(this.localeDateTimeFormat)).add(1, 'days').format(this.backendDateTimeFormat),
        export: exportReport
      }
      this.$store.dispatch('getBonusesByCompany', [this.$store.getters.companyId, payload]).then((response) => {
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
        if (exportReport) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_bonus_penalty_')}_${moment(payload.start).format(this.backendDateFormat)}_${moment(payload.end).format(this.backendDateFormat)}.xlsx`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.setTable(response)
        }
      }).catch((error) => {
        if (error.response.status === 500) {
          this.toastError(this.$t('500_ERROR_MESSAGE'))
          return
        }
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          // this.$router.push('dashboard')
          this.toastError(this.$t(error.response.data.message))
        }
      })
    },
    setTable (response) {
      this.tableData = {}
      response.forEach(item => {
        if (!this.tableData[item.employee.id]) {
          this.tableData[item.employee.id] = []
        }
        this.tableData[item.employee.id].push({
          id: item.id,
          employeeId: item.employee.id,
          name: item.employee.full_name,
          amount: item.amount,
          comment: item.comment,
          type: item.type,
          templateName: item.shift.template ? item.shift.template.name : this.$t('CUSTOM_TEMPLATE'),
          dateFrom: this.fromZoneToZone(item.shift.time_from, 'UTC', this.profile.time_zone).format(this.localeDateTimeFormat),
          dateTo: this.fromZoneToZone(item.shift.time_to, 'UTC', this.profile.time_zone).format(this.localeDateTimeFormat)
        })
      })
      Object.keys(this.tableData).forEach(key => {
        this.tableData[key].sort((item1, item2) => {
          return item1.dateFrom > item2.dateFrom ? 1 : -1
        })
      })
      const keys = Object.keys(this.tableData).sort((key1, key2) => {
        return this.tableData[key1][0].name > this.tableData[key2][0].name ? 1 : -1
      })
      const tableData = {}
      keys.forEach(key => {
        tableData['a_' + key] = this.tableData[key]
      })
      this.tableData = Object.assign({}, tableData)
      this.rows = Object.assign({}, this.tableData)
      this.filteredByPositions()
      this.filteredByMarks()
    },
    filteredByPositions () {
      if (this.selectedPositions.length === 0) {
        this.rows = Object.assign({}, this.tableData)
        return true
      }
      const rows = {}
      Object.keys(this.tableData).forEach(id => {
        const employeeId = parseInt(id.replace('a_', ''))
        if (this.positionsByUsers[employeeId] && this.selectedPositions.includes(this.positionsByUsers[employeeId].positionId)) {
          rows[id] = this.tableData[id]
        }
      })
      this.rows = rows
    },
    filteredByMarks () {
      const selectedMarks = this.selectedMarks
      const marksByUsers = this.marksByUsers
      if (selectedMarks.length === 0 && marksByUsers) {
        this.rows = Object.assign({}, this.tableData)
        return true
      }
      this.rows = {}
      Object.keys(this.tableData).forEach(employeeId => {
        const userId = parseInt(employeeId.replace('a_', ''))
        let row = []
        let haveMarks = false
        selectedMarks.forEach(selectMark => {
          const splitData = selectMark.split(':')
          const scheduleId = parseInt(splitData[0])
          const markId = parseInt(splitData[1])
          if (marksByUsers[userId] && scheduleId in marksByUsers[userId] && marksByUsers[userId][scheduleId].indexOf(markId) !== -1) {
            haveMarks = true
          }
        })

        if (haveMarks) {
          row = this.tableData[employeeId]
        }
        if (row.length > 0) {
          this.rows[employeeId] = row
        }
      })
    },
    filteredData () {
      if (this.search === '') {
        this.rows = Object.assign({}, this.tableData)
        return true
      }
      this.rows = {}
      Object.keys(this.tableData).forEach(employeeId => {
        const row = this.tableData[employeeId].filter(bonus => {
          return (bonus.name.toLowerCase()).indexOf(this.search.toString().toLowerCase()) > -1
        })
        if (row.length > 0) {
          this.rows[employeeId] = row
        }
      })
    },
    calculateBonus (employee) {
      let sum = 0
      employee.forEach(item => {
        if (item.type === 'bonus') sum += item.amount
      })
      return Math.round(sum * 100) / 100
    },
    calculatePenalty (employee) {
      let sum = 0
      employee.forEach(item => {
        if (item.type === 'penalty') sum += item.amount
      })
      return Math.round(sum * 100) / 100
    },
    calculateAdvance (employee) {
      let sum = 0
      employee.forEach(item => {
        if (item.type === 'advance') sum += item.amount
      })
      return Math.round(sum * 100) / 100
    }
  }
}
</script>

<style scoped>

</style>
