<template>
  <div class="card m-1">
    <div class="card-body p-2">
      <div class="row my-1 px-1">
        <div class="col-auto my-1 mx-auto d-flex">
          <span class="icon-git-pull-request text-primary align-self-center mr-2" style="font-size: 30px"></span>
          <span class="font-weight-bold align-self-center">
            {{$t('Employees requests')}}:
          </span>
        </div>
        <div class="col-lg m-1 p-1 d-flex rounded" style="background-color: #ccf1f7">
          <span class="align-self-center font-weight-bold text-left">
            {{$t('Shifts exchange requests')}}
          </span>
          <span class="badge badge-pill font-weight-bold align-self-center ml-auto" :class="requests.exchange > 0 ? 'badge-warning' : 'badge-light'">
            {{requests.exchange}}
          </span>
        </div>
        <div class="col-lg d-flex m-1 p-1 rounded" style="background-color: #ccf1f7">
          <span class="align-self-center font-weight-bold text-left">
            {{$t('Time-off requests')}}
          </span>
          <span class="badge badge-pill font-weight-bold align-self-center ml-auto" :class="requests.time_off > 0 ? 'badge-warning' : 'badge-light'">
            {{requests.time_off}}
          </span>
        </div>
        <div class="col-lg d-flex m-1 p-1 rounded" style="background-color: #ccf1f7">
          <span class="align-self-center font-weight-bold text-left">
            {{$t('Take free shift requests')}}
          </span>
          <span class="badge badge-pill font-weight-bold align-self-center ml-auto" :class="requests.move_to_user > 0 ? 'badge-warning' : 'badge-light'">
            {{requests.move_to_user}}
          </span>
        </div>
        <div class="col-auto m-1 px-1 d-flex mx-auto">
          <router-link class="btn btn-md btn-primary rounded-pill shadow-sm" :to="`/c/${companyId}/requests`">
            {{ $t('Check requests') }} <span class="icon-chevron-right"></span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestsStatistic',
  data () {
    return {
      requests: {
        time_off: 0,
        move_to_user: 0,
        exchange: 0
      }
    }
  },
  created () {
    this.$store.dispatch('getRequestsStatistic', this.companyId).then(result => {
      this.requests = result
    })
  }
}
</script>

<style scoped>

</style>
