<template>
    <div
      data-cy="schedule-type"
      class="row">
      <div class="col-lg-12 pb-4">
        <span class="text-primary text-uppercase font-weight-bold">{{ $t('Select schedule type') }}</span>
      </div>
      <div class="col-lg-12">
        <div>
          <ui-radio
            name="simple"
            v-model="scheduleType"
            :set-value="'simple'"
            data-cy="schedule-simple"
          >
            <span class="text-wrap d-inline-block" for="simple">
              <span class="align-top" data-cy="schedule-simple-label">{{ $t("Simple") }}</span>
              <a class="text-secondary mx-2" :href="linkSimple" target="_blank" rel="noopener noreferrer">
                <i class="icon-help-circle" style="font-size: 16px" />
              </a>
            </span>
          </ui-radio>
          <div class="small border-bottom ml-3">
            {{ $t("Creates schedules with a fixed number of working hours, lunch breaks, work days and days off. For example, it can be used to generate a standard 9-5 timetable for selected number of employees.") }}
          </div>
          <ui-radio
            name="by_availability"
            class="mt-2"
            id="by_availability"
            v-model="scheduleType"
            :set-value="'by_availability'"
          >
            <span class="text-wrap d-inline-block" for="by_availability">
              <span class="align-top">{{ $t("By availability") }}</span>
              <a class="text-secondary mx-2" :href="linkAvailability" target="_blank" rel="noopener noreferrer">
                <i class="icon-help-circle" style="font-size: 16px" />
              </a>
            </span>
          </ui-radio>
          <div class="ml-3">
            <span style="background-color: #fff3cd;">
              {{ $t("WARNING_BY_AVAILABILITY_TYPE_MASTER") }}
            </span>
          </div>
          <div class="small border-bottom ml-3">
             {{ $t("Generates schedules by checking employee availability settings. Shifts are assigned to employees with preferred working hours, which allows generating schedules with more accuracy and avoiding downtime.") }}
          </div>
          <ui-radio
            name="advanced"
            class="mt-2"
            data-cy="schedule-advanced"
            id="advanced"
            v-model="scheduleType"
            :set-value="'advanced'"
          >
            <span class="text-wrap d-inline-block" for="advanced">
              <span class="align-top" data-cy="schedule-advanced-label">{{ $t("Advanced") }}</span>
              <a class="text-secondary mx-2" :href="linkAdvanced" target="_blank" rel="noopener noreferrer">
                <i class="icon-help-circle" style="font-size: 16px" />
              </a>
            </span>
          </ui-radio>
          <div class="small border-bottom ml-3">
            {{ $t("Offers schedule generation that allows creating a number of work shifts with a different number of workplaces for each of them. Ideal solution for enterprises that require their daily work to be divided into shifts.") }}
          </div>
          <ui-radio
            name="by_pattern"
            class="mt-2"
            data-cy="schedule-by_pattern"
            id="by_pattern"
            v-model="scheduleType"
            :set-value="'by_pattern'"
          >
            <span class="text-wrap d-inline-block" for="by_pattern">
              <span class="align-top" data-cy="schedule-by_pattern-label">{{ $t("By template") }}</span>
              <a class="text-secondary mx-2" :href="linkTemplate" target="_blank" rel="noopener noreferrer">
                <i class="icon-help-circle" style="font-size: 16px" />
              </a>
            </span>
          </ui-radio>
          <div class="small border-bottom ml-3">
            {{ $t("Creates schedules based on a selected pattern of shift arrangement. For example, 2 work days and 2 days off.") }}
          </div>
          <ui-radio
            name="manually"
            class="mt-2"
            id="manually"
            v-model="scheduleType"
            :set-value="'manually'"
          >
            <span class="text-wrap d-inline-block" for="manually">
              <span class="align-top">{{ $t("Empty schedule") }}</span>
              <a class="text-secondary mx-2" :href="linkManually" target="_blank" rel="noopener noreferrer">
                <i class="icon-help-circle" style="font-size: 16px" />
              </a>
            </span>
          </ui-radio>
          <div class="small ml-3">
             {{ $t("Creates empty schedule.") }}
          </div>
        </div>
      </div>
      <div class="col-12 py-2">
        <button
          id="demo_master_prev_step"
          class="btn btn-outline-primary rounded-pill shadow-sm m-1"
          @click="prev">
          {{ $t('Previous')}}
        </button>
        <button
          id="demo_master_next_step"
          data-cy="next-step"
          class="btn btn-primary rounded-pill shadow-sm m-1"
          @click="next">
          {{ $t('Next')}}
        </button>
      </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ScheduleType',
  data () {
    return {
      scheduleType: 'simple'
    }
  },
  created () {
    if (this.type) {
      this.scheduleType = this.type
    }
  },
  props: { type: String },
  computed: {
    ...mapGetters({
      locale: 'locale'
    }),
    lang () {
      return this.locale === 'uk' ? 'en' : this.locale
    },
    linkLang () {
      return this.lang === 'ru' ? 'ru' : 'en'
    },
    linkSimple () {
      const link = this.linkLang === 'ru' ? 'базовое' : 'basic'
      return `https://help.shifton.com/${this.linkLang}/schedule#${link}`
    },
    linkAvailability () {
      const link = this.linkLang === 'ru' ? 'по-доступности' : 'by-availability'
      return `https://help.shifton.com/${this.linkLang}/schedule#${link}`
    },
    linkAdvanced () {
      const link = this.linkLang === 'ru' ? 'продвинутый' : 'advanced'
      return `https://help.shifton.com/${this.linkLang}/schedule#${link}`
    },
    linkTemplate () {
      const link = this.linkLang === 'ru' ? 'предустановленный' : 'preset'
      return `https://help.shifton.com/${this.linkLang}/schedule#${link}`
    },
    linkManually () {
      const link = this.linkLang === 'ru' ? 'пустое' : 'empty'
      return `https://help.shifton.com/${this.linkLang}/schedule#${link}`
    }
  },
  methods: {
    next () {
      // console.log(this.scheduleType)
      this.$emit('next', {
        step: 'scheduleType',
        data: this.scheduleType
      })
    },
    prev () {
      this.$emit('prev', {
        step: 'scheduleType',
        data: this.scheduleType
      })
    }
  }
}
</script>

<style scoped>

</style>
