<template>
  <div class="col-lg-12">
    <div class="col-lg-12 pb-2">
      <h3>{{ $t('Action logs') }}</h3>
    </div>
    <div class="col-lg-12">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="text-left">{{ $t('Title') }}</th>
          <th class="text-left">{{ $t('Action time') }}</th>
          <th class="text-center">{{ $t('Status duration') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(log, index) in task.logs" :key="index">
          <td>
            {{log.status.title}}
          </td>
          <td>
            {{ toTimeZone(log.created_at).format(localeDateTimeFormat) }}
          </td>
          <td class="text-center">
            {{ hrsForHuman(+log.duration) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'LogTaskComponent',
  mixins: [errorMixin, momentMixin],
  props: {
    task: Object
  },
  created () {
    // console.log(this.task.logs)
  },
  methods: {
    hrsForHuman (hours) {
      // return hours ? Math.floor(hours) + this.$t('h') + Math.round(hours * 60 % 60) + this.$t('m') : ''
      return hours ? Math.floor(hours) + ':' + Math.round(hours * 60 % 60) : ''
    }
  }
}
</script>

<style scoped>

</style>
