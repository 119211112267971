import { HTTP } from '@/api/http_utils'
import moment from 'moment'

const UPDATE_SHIFTS_LIST = 'UPDATE_SHIFTS_LIST'
const UPDATE_SHIFT = 'UPDATE_SHIFT'
const DELETE_SHIFT = 'DELETE_SHIFT'

const state = {
  shiftsIndexed: {}
}

const promises = {}

const getters = {
  shifts: state => Object.values(state.shiftsIndexed),
  shiftsIndexed: state => state.shiftsIndexed
}

const mutations = {
  [UPDATE_SHIFTS_LIST] (state, shifts) {
    const shiftsById = {}
    shifts.forEach(shift => {
      shiftsById[shift.id] = shift
    })
    state.shiftsIndexed = shiftsById
  },
  [UPDATE_SHIFT] (state, shift) {
    state.shiftsIndexed[shift.id] = shift
  },
  [DELETE_SHIFT] (state, shiftId) {
    delete state.shiftsIndexed[shiftId]
  }
}

const actions = {
  updateShiftInList ({ commit, dispatch }, shift) {
    commit(UPDATE_SHIFT, shift)
    dispatch('updateShouldUpdateWeekStatistic', true)
  },
  deleteShiftFromList ({ commit, dispatch }, shiftId) {
    commit(DELETE_SHIFT, shiftId)
    dispatch('updateShouldUpdateWeekStatistic', true)
  },
  updateShiftsList ({ commit }, shifts) {
    commit(UPDATE_SHIFTS_LIST, shifts)
  },
  getShifts ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.get('/shifts?' + data).then(shifts => {
        resolve(shifts)
      })
    })
  },

  getShift ({ commit, dispatch }, shiftId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/shifts/${shiftId}`).then(shift => {
        commit(UPDATE_SHIFT, shift)
        if (shift.breaks) {
          shift.breaks.forEach(breakItem => {
            dispatch('updateBreak', breakItem)
          })
        }
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(shift)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getShiftsByProject ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.get('/shifts/users?' + data).then(shifts => {
        resolve(shifts)
      })
    })
  },

  getShiftsByProjectId ({ commit }, [projectId, data]) {
    const promiseKey = '/projects/' + projectId + '/shifts?' + data

    if (promises[promiseKey]) {
      console.error('repeating requests shifts by id')
      return promises[promiseKey]
    }

    const promise = new Promise(resolve => {
      HTTP.get('/projects/' + projectId + '/shifts?' + data).then(shifts => {
        commit(UPDATE_SHIFTS_LIST, shifts)
        resolve(shifts)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getShiftsByCompany ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/shifts?' + data).then(shifts => {
        resolve(shifts)
      })
    })
  },

  updateShifts ({ commit }, [projectId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/projects/${projectId}/update_shifts`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getUnconfirmedShifts ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/shifts/confirmation`).then(shifts => {
        resolve(shifts)
      }).catch(err => {
        reject(err)
      })
    })
  },

  markShiftsAsConfirmed ({ commit }, { companyId, shifts }) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/shifts/confirmation`, shifts).then(shifts => {
        resolve(shifts)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteShift ({ commit, dispatch }, shiftId) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/shifts/${shiftId}`).then(response => {
        commit(DELETE_SHIFT, shiftId)
        dispatch('deleteFilteredShift', shiftId)
        dispatch('deleteFcEvent', shiftId)
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createShift ({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      HTTP.post('/shifts', data).then(response => {
        response.shifts.forEach(shift => {
          if (shift.breaks && shift.breaks.length > 0) {
            let duration = 0
            shift.breaks.forEach(breakItem => {
              if (!breakItem.billable) {
                duration += moment.duration(moment(breakItem.time_to).diff(moment(breakItem.time_from))).asMinutes()
              }
              dispatch('updateBreak', breakItem)
            })
            shift.break_time = duration
          }
          commit(UPDATE_SHIFT, shift)
          if (+shift.employee_id > 0) {
            dispatch('updateFilteredShift', shift)
          }
          if (+shift.template_id === 0) {
            dispatch('removeCustomTemplatesToHide', shift.schedule_id + '__sp__0')
          }
        })
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  searchShifts ({ commit }, data) {
    return new Promise(resolve => {
      HTTP.get('/shifts' + data).then(response => {
        resolve(response)
      })
    })
  },
  distributeFreeBoard ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/schedules/distribute`, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  patchShift ({ commit, dispatch }, [shiftId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/shifts/' + shiftId, data).then(response => {
        if (+response.employee_id === 0) {
          dispatch('deleteFilteredShift', response)
        } else {
          dispatch('updateFilteredShift', response)
        }
        commit(UPDATE_SHIFT, response)
        if (response.breaks) {
          response.breaks.forEach(breakItem => {
            dispatch('updateBreak', breakItem)
          })
        }
        dispatch('updateShouldUpdateWeekStatistic', true)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  patchFreeboard ({ commit, dispatch }, [projectId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/projects/' + projectId + '/shifts/freeboard_update', data).then(response => {
        response.shifts.forEach(shift => {
          commit(UPDATE_SHIFT, shift)
          if (shift.breaks) {
            shift.breaks.forEach(breakItem => {
              dispatch('updateBreak', breakItem)
            })
          }
        })
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  patchShiftEmployee ({ commit }, [shiftId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/shifts/${shiftId}/employee_update`, data).then(response => {
        commit(UPDATE_SHIFT, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  splitShift ({ commit }, [shiftId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/shifts/' + shiftId + '/split', data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  restoreShift ({ commit }, shiftId) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/shifts/${shiftId}/restore`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  employeesToDrop ({ commit }, shiftId) {
    return new Promise((resolve, reject) => {
      HTTP.get('/shifts/' + shiftId + '/todrop').then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  shiftsToSwap ({ commit }, [shiftId]) {
    return new Promise((resolve, reject) => {
      HTTP.get('/shifts/' + shiftId + '/toswap').then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  initiateSwap ({ commit }, [companyId, shiftFrom, shiftIds]) {
    return new Promise((resolve, reject) => {
      const data = {
        subtype: 'exchange',
        shift_from: shiftFrom,
        shift_ids: shiftIds
      }
      HTTP.post(`/companies/${companyId}/requests`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  initiateDrop ({ commit }, [companyId, shiftId, toEmployeeIds]) {
    return new Promise((resolve, reject) => {
      const data = {
        subtype: 'drop_to_employee',
        shift_id: shiftId,
        to_employee_ids: toEmployeeIds
      }
      HTTP.post(`/companies/${companyId}/requests`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  initiateShiftChange ({ commit }, [companyId, shiftId, dateFrom, dateTo, message]) {
    return new Promise((resolve, reject) => {
      const data = {
        subtype: 'change_shift',
        shift_id: shiftId,
        date_from: dateFrom,
        date_to: dateTo,
        message: message
      }
      HTTP.post(`/companies/${companyId}/requests`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  breaksCreateRequest ({ commit }, [companyId, shiftId, payload]) {
    return new Promise((resolve, reject) => {
      const data = {
        subtype: 'create_break',
        shift_id: shiftId,
        breaks: payload.breaks
      }
      HTTP.post(`/companies/${companyId}/requests`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteShiftsFromFreeBoard ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/shifts/clean_freeboard`, data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  copyShifts ({ commit }, [projectId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/projects/' + projectId + '/shifts', data).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
