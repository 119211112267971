<template>
  <modal-component :id="'RemoveUserModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('FIRE_MODAL_TITLE')}}</h5>
    </template>
    <template #body>
      <div>
        <div class="text-center mb-2">
          <span class="icon-user-x display-4"></span>
        </div>
        <div class="text-center">
          <label>
            {{ $t('FIRE_MODAL_TITLE') }} <span class="font-weight-bold"> {{ dismissUser.full_name }}</span>
          </label>
          <label>
            {{ $t('revokes the access of this employee to the current Company from the specified date.') }}
          </label>
          <label>
            {{ $t('The employee will no longer be able to log into this Company on any device but any historical records will be retained.') }}
          </label>
          <Form ref="observer" as="div" class="col">
            <ui-date-picker
              name="start_date"
              :clearable="false"
              :label="$t('Dismiss date')"
              :hint="$t('DISMISS_DATE_HINT')"
              :validation="{required: false}"
              data-vv-validate-on="change"
              v-model="dateFrom"
              :disabledDate="disabledDates"
            />
          </Form>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <div class="text-center">
          <button
            type="button"
            class="btn btn-danger rounded-pill shadow-sm mx-2"
            data-cy="dismiss-user-button"
            aria-label="Close" @click="deleteUserPermanently">
            <span aria-hidden="true">{{ $t('Remove')}}</span>
          </button>
          <button type="button"
                  class="btn btn-warning rounded-pill shadow-sm mx-2"
                  aria-label="Close"
                  @click="deleteUser">
            <span aria-hidden="true">{{ $t('Dismiss')}}</span>
          </button>
          <button type="button"
                  class="btn btn-outline-secondary rounded-pill shadow-sm mx-2"
                  data-cy="cancel-button"
                  aria-label="Close"
                  @click="closeModal">
            <span aria-hidden="true">{{ $t('Cancel')}}</span>
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment-timezone'

export default {
  name: 'DismissUserModal',
  mixins: [momentMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      dateFrom: null
    }
  },
  props: {
    dismissUser: null
  },
  methods: {
    closeModal () {
      document.getElementById('RemoveUserModal').click()
      this.$emit('closeDismissUserModal')
    },
    disabledDates (date) {
      let dataFrom = moment()
      dataFrom = dataFrom.subtract(1, 'days')
      return date.getTime() < dataFrom
    },
    deleteUserPermanently () {
      this.$refs.observer.validate().then(value => {
        if (value.valid) {
          this.$store.dispatch('delUser', [this.$store.getters.companyId, this.dismissUser.id]).then(response => {
            this.$eventBus.emit('DelUserResult', this.dismissUser.id)
            this.toastSuccess(this.$t('User has been successfully removed from your company'))
            this.$eventBus.emit('checkSteps')
            this.closeModal()
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },
    deleteUser () {
      this.$refs.observer.validate().then(value => {
        if (value.valid) {
          if (!this.dateFrom) {
            this.toastError(this.$t('Date is required'))
            return
          }
          const dateFrom = this.dateFrom ? this.toUTC(this.dateFrom).format(this.backendDateTimeFormat) : null
          this.$store.dispatch('dismissUser', [this.$store.getters.companyId, this.dismissUser.id, dateFrom]).then(response => {
            this.$eventBus.emit('dismissUserResult', [this.dismissUser.id, response])
            this.toastSuccess(this.$t('User has been successfully dismiss from your company'))
            this.closeModal()
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
