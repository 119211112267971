import { HTTP } from '@/api/http_utils'

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  getProjectDoubleAccounts ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + id + '/double_accounts').then(doubleAccounts => {
        if (doubleAccounts) {
          resolve(doubleAccounts)
        }
      })
    })
  },

  setProjectDoubleAccounts ({ commit }, [id, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post('/projects/' + id + '/double_accounts', data).then(doubleAccounts => {
        if (doubleAccounts) {
          resolve(doubleAccounts)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },

  getDoubleAccountsFavoriteUsers ({ commit }, id) {
    return new Promise(resolve => {
      HTTP.get('/projects/' + id + '/favorite_users').then(favoriteUsers => {
        if (favoriteUsers) {
          resolve(favoriteUsers)
        }
      })
    })
  },

  setDoubleAccountsFavoriteUsers ({ commit }, [projectId, data]) {
    return new Promise(resolve => {
      HTTP.post('/projects/' + projectId + '/favorite_users', data).then(favoriteUsers => {
        if (favoriteUsers) {
          resolve(favoriteUsers)
        }
      })
    })
  },

  patchDoubleAccount ({ commit }, [projectId, daId, data]) {
    return new Promise(resolve => {
      HTTP.patch('/projects/' + projectId + '/double_accounts/' + daId, data).then(favoriteUsers => {
        if (favoriteUsers) {
          resolve(favoriteUsers)
        }
      })
    })
  },

  deleteDoubleAccountsForUsers ({ commit }, [projectId, daId]) {
    return new Promise(resolve => {
      HTTP.delete('/projects/' + projectId + '/double_accounts/' + daId).then(response => {
        resolve(response)
      })
    })
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
