<template>
  <VDatePicker
    mode='date'
    @update:modelValue="dateChange"
    :attributes="attributes"
    :locale="locale"
    :popover="{ placement: 'bottom' }"
    v-model='selectedDate'>
    <template #default="{ togglePopover }">
      <button @click="togglePopover()" class="fc-calendarButton-button-pick"></button>
    </template>
  </VDatePicker>
</template>

<script>
export default {
  name: 'DatePickerComponent',
  data () {
    return {
      mode: 'single',
      selectedDate: new Date(),
      attributes: [
        {
          highlight: {
            color: 'green',
            fillMode: 'light'
          },
          dates: new Date()
        }
      ]
    }
  },
  props: {
    locale: String
  },
  mounted () {
    this.$eventBus.on('updateCurrentDate', (value) => { this.selectedDate = value })
  },
  methods: {
    dateChange (value) {
      // console.log(45, value)
      if (value) {
        this.$eventBus.emit('goToDate', value)
      }
    }
  }
}
</script>
<style>
  .vc-popover-content-wrapper {
    z-index: 10000!important;
  }
</style>
<style scoped>
  .fc-calendarButton-button-pick {
    padding: 0!important;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center!important;
    width: 40px!important;
    height: 30px!important;
    border: none!important;
    background:url('../../../../public/static/images/App/calendar-dark.png') center no-repeat!important;
    /*background-size: contain!important;*/
  }
</style>
