<template>
  <div v-if="loaded">
    <div class="card card-small">
      <Form ref="observer" as="div" class="card-body">
        <div class="row pb-2">
          <div class="col-lg-12">
            <div class="errors" v-for="(error, index) in apiNonFieldErrors" :key="index">{{error}}</div>
            <div class="text-primary text-uppercase font-weight-bold">{{ $t('Billing address') }}</div>
            <div class="font-weight-bold">{{ $t('Financial data used for invoice generation.') }}</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 form-group">
            <ui-text-input
              name="business_name"
              v-model="company.companyName"
              :validation="{required: true, max: 128, min: 3, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
              :label="$t('Company name')"
            />
          </div>
          <div class="col-lg-6 form-group">
            <ui-text-input
              name="vat_number"
              v-model="company.vat_number"
              :validation="{required: false, max: 128, min: 3}"
              :label="$t('VAT Number')"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 form-group">
            <ui-text-input
              name="first_name"
              v-model="company.user.firstName"
              :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
              :label="$t('First name')"
            />
          </div>
          <div class="col-lg-6 form-group">
            <ui-text-input
              name="last_name"
              v-model="company.user.lastName"
              :validation="{required: true, max: 128, regex: /^(?!.*(\$|<|>|\{|}|\*|\^|=)).*/}"
              :label="$t('Last name')"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-6 form-group">
            <ui-text-input
              name="email"
              v-model="company.contacts.email"
              :validation="{required: false, email: true}"
              :label="$t('Email')"
            />
          </div>
          <div class="form-group col-lg-6">
            <div style="margin-top: 3px">
              <ui-tel-input
                name="phone"
                v-model="company.contacts.phone"
                :validation="{ max: 20 }"
                :label="$t('Phone number')"
              />
            </div>
          </div>
        </div>
        <location
          ref="loc"
          :classes="'col-md-6'"
          :countryId="region.selected.country.value"
          :cityId="region.selected.city.value"
          :stateId="region.selected.state.value"
          :zipCode="region.selected.postCode.value"
          :address="company.address.mainAddress"
          :additionalAddress="company.address.additionalAddress"
          :showAddress="true"
          :showAdditionalAddress="false"
          :required-fields-setting="{zipCode: true, country: true, city: true, state: true, address: true, additionalAddress: true}"
          @addressChange="addressUpdate">
        </location>
        <div class="form-row">
          <div class="col-lg-12 form-group text-center">
            <button
              type="submit"
              name="submit"
              class="btn rounded-pill shadow-sm"
              v-bind:class="[newbie ? 'btn-primary' : 'btn-success']"
              @click="submitForm">{{ newbie ? $t('Next') : $t('SAVE_WEB') }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import errorMixin from '../../../mixins/mixinApiErrors'
import Location from '@/components/CommonComponents/Location'

export default {
  name: 'BillingAddress',
  mixins: [errorMixin],
  components: { Location },
  data () {
    return {
      company: {
        companyName: '',
        vat_number: '',
        user: {
          firstName: '',
          lastName: ''
        },
        contacts: {
          email: '',
          phone: ''
        },
        address: {
          mainAddress: '',
          additionalAddress: '',
          country: '',
          city: '',
          state: '',
          postCode: ''
        }
      },
      region: {
        selected: {
          country: {
            value: '',
            required: true
          },
          city: {
            value: '',
            required: true
          },
          state: {
            value: '',
            required: true
          },
          postCode: {
            value: '',
            required: true
          }
        },
        cloneRegion: {},
        cloneCompany: {}
      },
      method: 'setBillingAddress',
      infoId: null,
      loaded: false
    }
  },
  props: ['newbie'],
  created () {
    this.$store.dispatch('getBillingAddresses', this.$store.getters.companyId).then(response => {
      if (response && response.length) {
        const address = response[0]
        this.method = 'patchBillingAddress'
        this.infoId = address.id
        this.company.companyName = address.business_name
        this.company.vat_number = address.vat_number
        this.company.user.firstName = address.first_name
        this.company.user.lastName = address.last_name
        this.company.contacts.email = address.email
        this.company.contacts.phone = address.phone ? '+' + address.phone : ''
        this.company.address.mainAddress = address.address
        this.company.address.additionalAddress = address.additional_address
        this.region.selected.country.value = address.country
        this.region.selected.city.value = address.city
        this.region.selected.state.value = address.state
        this.region.selected.postCode.value = address.postal_code
      }
      this.cloneCompany = JSON.parse(JSON.stringify(this.company))
      this.loaded = true
    })
  },
  computed: {
    email () {
      return this.company.contacts.email
    },
    phone () {
      return this.company.contacts.phone
    }
  },
  watch: {
    email () {
      this.company.contacts.email = this.company.contacts.email ? this.company.contacts.email.trim() : ''
    },
    phone () {
      this.company.contacts.phone = this.company.contacts.phone ? this.company.contacts.phone.trim() : ''
    }
  },
  methods: {
    parsePhoneNumber (number) {
      number = number.substr(1)
      number = number.replace(/\s/g, '')
      return number
    },
    addressUpdate (newAddress) {
      this.company.address.mainAddress = newAddress.address
      this.company.address.additionalAddress = newAddress.additionalAddress
      this.region.selected.country.value = newAddress.countryId
      this.region.selected.city.value = newAddress.cityId
      this.region.selected.state.value = newAddress.stateId
      this.region.selected.postCode.value = newAddress.zipCode
    },
    async submitForm () {
      const isAddressValid = await this.$refs.loc.isAddressValid()
      this.$refs.observer.validate().then(result => {
        if (result.valid && isAddressValid) {
          const data = {
            business_name: this.company.companyName,
            first_name: this.company.user.firstName,
            last_name: this.company.user.lastName,
            email: this.company.contacts.email,
            phone: this.parsePhoneNumber(this.company.contacts.phone),
            address: this.company.address.mainAddress,
            additional_address: this.company.address.additionalAddress,
            country: this.region.selected.country.value,
            state: this.region.selected.state.value,
            city: this.region.selected.city.value,
            postal_code: this.region.selected.postCode.value,
            company_id: this.$store.getters.companyId,
            vat_number: this.company.vat_number
          }
          this.$store.dispatch(this.method, [this.$store.getters.companyId, this.infoId, data]).then(address => {
            if (address) {
              this.toastSuccess(this.$t('Billing address successfully saved'))

              if (this.newbie) this.$eventBus.emit('setStep', 5)
            } else {
              this.toastError(this.$t('Oops! Something went wrong!'))
            }
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        } else {
          if (this.newbie &&
            JSON.stringify(this.company) === JSON.stringify(this.cloneCompany) &&
            JSON.stringify(this.region) === JSON.stringify(this.cloneRegion)) {
            this.$eventBus.emit('setStep', 5)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
