<template>
  <el-tooltip
    v-if="eventData"
    :show-after="2000"
    effect="light"
    placement="top"
    class="w-100">
    <template #content>
      <div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('EVENT_TYPE')}}: </span>
          <span class="font-weight-bold">{{$t(eventData.event_type)}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('STARTS_AT')}}: </span>
          <span class="font-weight-bold">{{moment(eventData.dtstart).format(localeDateFormat)}}</span>
        </div>
        <div v-if="eventData.note" class="my-1">
          <span class="font-weight-bold text-primary">{{$t('EVENT_TITLE')}}: </span>
          <span class="font-weight-bold">{{eventData.note}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('TIME_FROM')}}: </span>
          <span class="font-weight-bold">{{from}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('TIME_TO')}}: </span>
          <span class="font-weight-bold">{{to}}</span>
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('IS_FULL_DAY')}}: </span>
          <i v-if="eventData.duration === 1440" class="icon-check-circle text-success font-weight-bold" />
          <i v-if="eventData.duration !== 1440" class="icon-minus-circle text-warning font-weight-bold" />
        </div>
        <div class="my-1">
          <span class="font-weight-bold text-primary">{{$t('IS_RECURRING')}}: </span>
          <i v-if="eventData.is_recurring" class="icon-check-circle text-success font-weight-bold" />
          <i v-if="!eventData.is_recurring" class="icon-minus-circle text-warning font-weight-bold" />
        </div>
        <template v-if="eventData.is_recurring">
          <div class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_FREQUENCY')}}: </span>
            <span class="font-weight-bold">{{$t(eventData.freq)}}</span>
          </div>
          <div v-if="eventData.byweekday.length > 0" class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_WEEKDAYS')}}: </span>
            <span v-for="day in eventData.byweekday" :key="day" class="font-weight-bold">{{$t(day)}} </span>
          </div>
          <div v-if="eventData.until" class="my-1">
            <span class="font-weight-bold text-primary">{{$t('EVENT_UNTIL')}}: </span>
            <span class="font-weight-bold">{{moment(eventData.until).format(localeDateFormat)}}</span>
          </div>
        </template>
      </div>
    </template>
    <div class="fc-content py-1 text-truncate text-left" style="cursor: pointer">
      <span class="fc-time ml-1 text-nowrap text-truncate">{{ from }} - {{ to }}</span>
      <span class="ml-1 overflow-hidden">{{ event.title }}</span>
    </div>
  </el-tooltip>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment-timezone'

export default {
  name: 'AvailabilityEvent',
  mixins: [momentMixin],
  data () {
    return {
      moment: moment
    }
  },
  props: {
    event: Object
  },
  computed: {
    from () {
      return moment(this.event.start).format(this.localeTimeFormat)
    },
    to () {
      return moment(this.event.end).format(this.localeTimeFormat)
    },
    eventData () {
      return this.$store.getters.availabilityIndexed[this.event.id]
    }
  }
}
</script>

<style scoped>

</style>
