<template>
  <full-calendar ref="dashboardCalendar" :options="calendarOptions">
    <template v-slot:eventContent='arg'>
      <template v-if="arg.event.extendedProps.is_shift">
        <template v-if="arg.event.extendedProps.is_double_account">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t(arg.event.extendedProps.double_account_title)"
            placement="top">
            <i class="icon-hands-and-gestures btn-icon mx-1"></i>
          </el-tooltip>
        </template>
        <b>{{ formatTime(arg.event.start) }} - {{ formatTime(arg.event.end) }}</b>
        <i>{{ arg.event.title }}</i>
        <template v-if="arg.event.extendedProps.location">
          <el-tooltip
            effect="light"
            placement="bottom">
            <template #content>
              <div>
                <div>{{ arg.event.extendedProps.location.title }}</div>
                <div>{{ arg.event.extendedProps.location.address }}</div>
              </div>
            </template>
            <i class="icon-map-pin mx-1"></i>
          </el-tooltip>
        </template>
        <template v-for="tag in arg.event.extendedProps.tags" :key="arg.event.id + '__' + tag.id">
          <el-tooltip
            effect="light"
            placement="top">
            <template #content>
              <div>
                {{ tag.name }}
              </div>
            </template>
            <div class="mr-1" :style="{background: tag.color}" style="width: 15px; height: 15px; display: inline-block; border-radius: 25px; vertical-align: middle;"></div>
          </el-tooltip>
        </template>
      </template>
      <template v-if="arg.event.extendedProps.is_request">
        <el-tooltip
          :open-delay="1000"
          :hide-after="4000"
          effect="light"
          placement="top"
          class="w-100">
          <template #content>
            <div class="fc-content p-1 text-truncate font-weight-bold"
                 style="cursor: pointer"
                 data-toggle="modal"
                 data-target="#UserRequestCalendarModal">
              {{arg.event.extendedProps.requestDuration === 'all_day' ? '' : `${arg.event.extendedProps.startTimeRequest} - ${arg.event.extendedProps.endTimeRequest}`}}
              {{$t(arg.event.extendedProps.type)}}
              <span v-if="arg.event.extendedProps.isPaid === 1" class="mx-1">{{$t('Paid')}}</span>
              <span v-else class="mx-1">{{$t('Unpaid')}}</span>
              ({{arg.event.extendedProps.requestDuration === 'all_day' ? `${$t('All day')} ${arg.event.extendedProps.startDate} - ${arg.event.extendedProps.endDate}` : $t('Part of a day')}})
            </div>
          </template>
          <div class="" style="cursor: pointer">
            {{arg.event.extendedProps.requestDuration === 'all_day' ? '' : `${arg.event.extendedProps.startTimeRequest} - ${arg.event.extendedProps.endTimeRequest}`}}
            {{$t(arg.event.extendedProps.type)}}
            <span v-if="arg.event.extendedProps.isPaid === 1" class="mx-1">{{$t('Paid')}}</span>
            <span v-else class="mx-1">{{$t('Unpaid')}}</span>
            ({{arg.event.extendedProps.requestDuration === 'all_day' ? `${$t('All day')} ${arg.event.extendedProps.startDate} - ${arg.event.extendedProps.endDate}` : $t('Part of a day')}})
          </div>
        </el-tooltip>
      </template>
    </template>
  </full-calendar>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import listViewPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import ruLocale from '@fullcalendar/core/locales/ru'
import heLocale from '@fullcalendar/core/locales/he'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import deLocale from '@fullcalendar/core/locales/de'

export default {
  name: 'DashboardCalendar',
  components: { FullCalendar },
  mixins: [momentMixin, mixinFCWeek, mixinWindowWidthWatch],

  data () {
    return {
      timeZone: 'UTC',
      events: [],
      requests: [],
      companies: [],
      // marks: [],
      // view: {},
      calendarApi: null,
      calendarOptions: {
        plugins: [dayGridPlugin, bootstrapPlugin, listViewPlugin],
        themeSystem: 'bootstrap',
        initialView: this.viewType,
        locale: 'en',
        locales: [heLocale, ruLocale, deLocale, plLocale, esLocale, elLocale],
        nextDayThreshold: '24:00:00',
        buttonText: {
          prev: '<',
          next: '>'
        },
        views: {
          dayGridMonth: {
            fixedWeekCount: false,
            displayEventTime: true,
            displayEventEnd: true
          },
          listMonth: {
            displayEventTime: true,
            displayEventEnd: true,
            contentHeight: 380
          }
        },
        eventTimeFormat: { // формат времени, которое отображается на событии
          hour: 'numeric',
          hour12: this.is12hourFormat,
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        },
        eventClassNames: ['rounded', 'p-1'],
        eventDisplay: 'block',
        events: (fetchInfo, successCallback, failureCallback) => {
          this.createEvents(fetchInfo.startStr, fetchInfo.endStr).then(result => {
            let events = result[0]
            events = events.concat(result[1])
            successCallback(events)
          })
        },
        windowResize: (info) => {
          if (this.windowWidth >= 576 && info.view.type !== 'dayGridMonth') {
            this.calendarApi.changeView('dayGridMonth')
          }
          if (this.windowWidth < 576 && info.view.type !== 'listMonth') {
            this.calendarApi.changeView('listMonth')
          }
        }
      }
    }
  },

  props: {
    schedules: Array,
    viewType: String
  },

  computed: {
    ...mapGetters({
      companyId: 'companyId',
      // employeeId: 'currentCompanyUserId',
      marks: 'companyMarks'
    }),
    firstDay () {
      return this.fcWeek[this.apiWeek[this.$store.getters.company.salary.week_start]]
    },
    roleDashboard () {
      let role = 'noRole'
      if (this.$store.getters.companyRole !== 'null') {
        role = this.$store.getters.companyRole
      }
      return role
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    employeeId () {
      return +this.$store.getters.currentCompanyUserId
    }
  },

  created () {
    this.$store.dispatch('getCompanies').then(response => {
      this.companies = response
    })
    this.$store.dispatch('getMarksByCompany')
  },

  mounted () {
    this.calendarApi = this.$refs.dashboardCalendar.getApi()
    this.view = this.calendarApi.view
    this.calendarApi.setOption('direction', this.direction)
    this.calendarApi.setOption('firstDay', this.firstDay)
    this.calendarApi.setOption('locale', this.lang)
  },

  methods: {
    formatTime (val) {
      return moment(val).format(this.localeTimeFormat)
    },

    createEvents (startStr, endStr) {
      let params = ''
      this.events = []
      this.requests = []
      // console.log(params)
      let dateStart, dateEnd
      dateStart = moment.utc(startStr).format(this.backendDateTimeFormat)
      dateEnd = moment.utc(endStr).format(this.backendDateTimeFormat)
      params += 'start=' + dateStart + '&' + 'end=' + dateEnd
      const queryHolidays = '?start=' + dateStart + '&end=' + dateEnd
      let shifts = []
      let requests = []
      const tz = this.$store.getters.profileTimeZone
      shifts = new Promise(resolve => {
        this.$store.dispatch('getShifts', params).then(shifts => {
          // console.log(1, shifts)
          if (shifts) {
            shifts.forEach(shift => {
              let title = ''
              if (this.companies.length > 1) {
                title = this.companies.filter(item => item.id === shift.company_id)[0].name
              }
              this.events.push({
                id: shift.id,
                title: title,
                displayEventTime: true,
                start: this.fromZoneToZone(shift.time_from, 'UTC', tz).format(this.backendDateTimeFormat).replace(' ', 'T'),
                end: this.fromZoneToZone(shift.time_to, 'UTC', tz).format(this.backendDateTimeFormat).replace(' ', 'T'),
                allDay: false,
                backgroundColor: shift.color,
                borderColor: shift.color,
                editable: false,
                is_double_account: shift.is_double_account,
                double_account_title: shift.double_account_id,
                location: shift.location,
                marks: shift.marks,
                tags: this.updateTags(shift.marks),
                is_shift: true
              })
            })
            resolve(this.events)
          } else {
            resolve(this.events)
          }
        })
      })
      requests = new Promise(resolve => {
        this.$store.dispatch('getRequestsForCalendar', [this.$store.getters.companyId, queryHolidays]).then((requests) => {
          if (requests) {
            requests.forEach(request => {
              if (request.subtype === 'time_off' && this.employeeId === +request.created_by) {
                let title = ''
                if (this.companies.length > 1) {
                  title = this.companies.filter(item => item.id === request.company_id)[0].name
                }
                const startTime = this.fromZoneToZone(request.date_from, 'UTC', tz)
                  .format(this.backendDateTimeFormat)
                const endTime = this.fromZoneToZone(request.date_to, 'UTC', tz)
                  .format(this.backendDateTimeFormat)
                const event = {
                  id: `user_request__sp__${request.id}`,
                  title: title,
                  start: startTime,
                  end: endTime,
                  displayEventTime: false,
                  editable: false,
                  eventDurationEditable: false,
                  eventResourceEditable: false,
                  color: '#7d7a79',
                  isUserRequest: true,
                  requestDuration: request.duration,
                  startTimeRequest: this.fromZoneToZone(request.date_from, 'UTC', tz).format(this.localeTimeFormat),
                  endTimeRequest: this.fromZoneToZone(request.date_to, 'UTC', tz).format(this.localeTimeFormat),
                  startDate: this.fromZoneToZone(request.date_from, 'UTC', tz).format(this.localeDateFormat),
                  endDate: this.fromZoneToZone(request.date_to, 'UTC', tz).format(this.localeDateFormat),
                  employeeName: request.employee_name,
                  isPaid: request.is_paid,
                  subtype: request.subtype,
                  requestId: request.id,
                  employeeId: request.created_by,
                  reason: request.type_time_off,
                  createdAt: this.fromZoneToZone(request.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateTimeFormat),
                  is_request: true
                }
                let type = 'Holiday'
                // console.log(55, request)
                if (request.type_time_off === 'sick') {
                  type = 'Sick day'
                }
                if (request.type_time_off === 'vacation') {
                  type = 'Vacation'
                }
                event.type = type
                this.requests.push(event)
              }
            })
            resolve(this.requests)
          } else {
            resolve(this.requests)
          }
        })
      })
      return Promise.all([shifts, requests])
    },

    updateTags (markIds) {
      const tags = []
      if (Array.isArray(markIds)) {
        for (const value of markIds) {
          for (const mark of this.marks) {
            if (mark.id === value) tags.push(mark)
          }
        }
      }
      return tags
    }
  }
}
</script>

<style scoped>

</style>
