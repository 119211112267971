<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/developer#webhooks`">
              <span class="breadcrumb-item">{{ $t('FOR_DEVELOPERS_HEADER') }}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            {{ this.$i18n?.t("WEBHOOK_LOGS") }}
          </li>
        </ol>
      </nav>
    </div>
    <div>
      <div class="row">
        <div class="col-6">
          <div class="card card-small">
            <div class="card-body">
              <el-pagination
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                v-model:current-page="pagination.currentPage"
                :page-size="25"
                :total="pagination.total"
              />
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">{{ $t('CALLBACK_URL') }}</th>
                  <th scope="col">{{ $t('EVENT_MODEL') }}</th>
                  <th scope="col">{{ $t('ACTION_TYPE') }}</th>
                  <th scope="col">{{ $t('RESPONSE_STATUS') }}</th>
                  <th scope="col">{{ $t('ATTEMPT_NUMBER') }}</th>
                  <th scope="col">{{ $t('UPDADED_AT') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in list" :key="row.id" style="cursor:pointer;" @click="details(index)" :class="{'bg-light': selectedIndex === index}">
                  <td style ="word-break:break-all;">{{ row.callback_url }}</td>
                  <td class="text-center">{{ $t(row.payload.event_model) }}</td>
                  <td class="text-center">{{ row.payload.event_action === 'updated' ? 'PATCH' : row.payload.event_action === 'created' ? 'POST' : row.payload.event_action === 'deleted' ? 'DELETE' : '' }}</td>
                  <td class="text-center">{{ row.response_status }}</td>
                  <td class="text-center">{{ row.attempt }}</td>
                  <td class="text-center">{{ formatDate(row.updated_at) }}</td>
                </tr>
                </tbody>
              </table>
              <el-pagination
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                v-model:current-page="pagination.currentPage"
                :page-size="25"
                :total="pagination.total"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div v-if="detail" class="card card-small">
            <div class="card-body">
              <div class="text-primary text-uppercase font-weight-bold mb-2">
                {{ $t('PAYLOAD_DETAILS') }}
              </div>
              <div class="font-weight-bold mb-2">
                <span class="mx-1">{{ $t('log id') }}: {{detail.log_id}}</span>
                <span class="mx-1">{{ $t('webhook id') }}: {{detail.webhook_id}}</span>
              </div>
              <template v-if="detail.event_action !== 'deleted' && detail.event_action !== 'created'">
                <div class="text-primary text-uppercase font-weight-bold mb-2">
                  {{ $t('OLD_VALUE') }}
                </div>
                <div>
                  <pre style="font-weight: bold;">
                    {{pretty(detail.old)}}
                  </pre>
                </div>
                <div class="text-primary text-uppercase font-weight-bold mb-2">
                  {{ $t('NEW_VALUE') }}
                </div>
                <div>
                  <pre style="font-weight: bold;">
                    {{pretty(detail.attributes)}}
                  </pre>
                </div>
              </template>
              <div class="text-primary text-uppercase font-weight-bold mb-2">
                {{ $t('UPDATED_MODEL') }}
              </div>
              <div>
                <pre style="font-weight: bold;">
                  {{pretty(detail.model)}}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'WebhookLog',
  mixins: [momentMixin],
  data () {
    return {
      list: [],
      detail: null,
      selectedIndex: null,
      pagination: {
        currentPage: this.$route.query.page ? +this.$route.query.page : 1,
        total: 0
      }
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  created () {
    this.init()
  },
  methods: {
    pretty (value) {
      return JSON.stringify(value, null, 2)
    },
    init () {
      if (!this.isEmployeeHasPermission('get webhook')) {
        this.$router.push(`/c/${this.companyId}/dashboard`)
      }
      const page = '?page=' + this.pagination.currentPage
      this.$store.dispatch('getWebhookLog', [this.companyId, this.$route.params.webhookId, page]).then((response) => {
        // console.log(response)
        this.list = response.data
        this.pagination.currentPage = response.current_page
        this.pagination.total = response.total
        if (response.current_page > response.last_page) {
          this.$router.replace({ query: { page: response.last_page } })
        }
      })
    },
    handleCurrentChange (val) {
      this.$router.replace({ query: { page: val } })
    },
    formatDate (val) {
      return moment(val).format(this.backendDateTimeFormat)
    },
    details (index) {
      this.detail = this.list[index].payload
      this.selectedIndex = index
    }
  }
}
</script>

<style scoped>

</style>
