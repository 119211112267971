import { HTTP } from '@/api/http_utils'

const UPDATE_WEBHOOKS_LIST = 'UPDATE_WEBHOOKS_LIST'
const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK'
const DELETE_WEBHOOK = 'DELETE_WEBHOOK'
const UPDATE_WEBHOOK_EVENTS_LIST = 'UPDATE_WEBHOOK_EVENTS_LIST'
const UPDATE_DEVELOPER_APPS_LIST = 'UPDATE_DEVELOPER_APPS_LIST'
const UPDATE_DEVELOPER_APP = 'UPDATE_DEVELOPER_APP'
const DELETE_DEVELOPER_APP = 'DELETE_DEVELOPER_APP'

const state = {
  webhooksIndexed: {},
  webhookEvents: [],
  appsIndexed: {}
}

const getters = {
  webhooksIndexed: state => state.webhooksIndexed,
  webhooks: state => Object.values(state.webhooksIndexed),
  webhookEvents: state => state.webhookEvents,
  appsIndexed: state => state.appsIndexed,
  apps: state => Object.values(state.appsIndexed)
}

const mutations = {
  [UPDATE_WEBHOOKS_LIST] (state, payload) {
    const values = {}
    payload.forEach(item => {
      values[item.id] = item
    })
    state.webhooksIndexed = values
  },
  [UPDATE_WEBHOOK] (state, payload) {
    state.webhooksIndexed[payload.id] = payload
  },
  [DELETE_WEBHOOK] (state, webhookId) {
    delete state.webhooksIndexed[webhookId]
  },
  [UPDATE_WEBHOOK_EVENTS_LIST] (state, payload) {
    state.webhookEvents = payload
  },
  [UPDATE_DEVELOPER_APPS_LIST] (state, payload) {
    const values = {}
    payload.forEach(item => {
      values[item.id] = item
    })
    state.appsIndexed = values
  },
  [UPDATE_DEVELOPER_APP] (state, payload) {
    state.appsIndexed[payload.id] = payload
  },
  [DELETE_DEVELOPER_APP] (state, appId) {
    delete state.appsIndexed[appId]
  }
}

const actions = {
  getWebhooks ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/webhooks`).then(response => {
        commit(UPDATE_WEBHOOKS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  setWebhook ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/webhooks`, data).then(response => {
        commit(UPDATE_WEBHOOK, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateWebhook ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/webhooks/${data.id}`, data).then(response => {
        commit(UPDATE_WEBHOOK, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteWebhook ({ commit }, [companyId, webhookId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/webhooks/${webhookId}`).then(response => {
        commit(DELETE_WEBHOOK, webhookId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getWebhookLog ({ commit }, [companyId, webhookId, page]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/webhooks/${webhookId}/log${page}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getWebhookEventsList ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/webhook-events-list`).then(response => {
        commit(UPDATE_WEBHOOK_EVENTS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDeveloperApps ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/developer/app`).then(response => {
        commit(UPDATE_DEVELOPER_APPS_LIST, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDeveloperApp ({ commit }, [companyId, appId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/developer/app/${appId}`).then(response => {
        commit(UPDATE_DEVELOPER_APP, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  createDeveloperApp ({ commit }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/developer/app`, data).then(response => {
        commit(UPDATE_DEVELOPER_APP, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  installDeveloperApp ({ commit }, [companyId, appId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/developer/app/${appId}/install`, data).then(response => {
        commit(UPDATE_DEVELOPER_APP, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  updateDeveloperApp ({ commit }, [companyId, appId, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/developer/app/${appId}`, data).then(response => {
        commit(UPDATE_DEVELOPER_APP, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  deleteDeveloperApp ({ commit }, [companyId, appId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/developer/app/${appId}`).then(response => {
        commit(DELETE_DEVELOPER_APP, appId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getDeveloperAppWebhooks ({ commit }, [companyId, appId]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/developer/app/${appId}/webhooks`).then(response => {
        // commit(UPDATE_DEVLOPER_APP_WEBHOOKS, appId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
