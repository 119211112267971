<template>
  <div v-if="dummy">
    <dummy-payment-required
      @activate="loadData()"
      :dummyInfo="dummyInfo"
    />
  </div>
  <div v-else-if="loaded">
    <div class="row mb-2 mx-auto">
      <span class="title-text">{{ $t("_ALL_COMPANY_FILES_TITLE") }}</span>
      <div class="ml-auto">
        <ui-text-input
          name="search"
          v-model="search"
          :placeholder="$t('Search')"
          autofocus
          :is-search="true"
        />
      </div>
    </div>

    <div class="mt-2">
      <div class="card card-small" v-if="paginationConfig.paginated && preloadUsers">
        <div class="card-body">
          <pagination :rows="paginationConfig.rows" :class="'mx-0'" v-on:updatePaginated="updatePaginated" />
          <div class="row">
            <div class="col-lg-12 table-responsive">
              <table class="table table-hover table-sortable">
                <thead>
                  <tr>
                    <th class="text-left">
                      <ui-checkbox
                        name="selectAllCheckboxes"
                        :value="selectAllCheckboxes"
                        @change="toggleAllCheckboxes" />
                    </th>
                    <th class="text-left">{{ $t("File name") }}</th>
                    <th class="text-left">{{ $t("Uploaded by") }}</th>
                    <th class="text-left">{{ $t("Date created") }}</th>
                    <th class="text-left">{{ $t("Type") }}</th>
                    <th class="text-left">{{ $t("Size") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in paginationConfig.paginated" :key="file.id">
                    <td>
                      <ui-checkbox-group v-model="selectedFiles">
                        <ui-checkbox
                          :name="'files__' + file.id"
                          :custom-value="file.file.id"
                        />
                      </ui-checkbox-group>
                    </td>
                    <td class="align-middle">{{ file.file.fullname }}</td>
                    <td>{{ file.uploader_name }}</td>
                    <td class="align-middle">{{createdDate(file)}} {{createdTime(file)}}</td>
                    <td class="align-middle">{{getFileType(file.file)}}</td>
                    <td class="align-middle">{{getFileSize(file.file.size)}}</td>
                    <td class="d-flex justify-content-end">
                      <i class="icon-download btn-icon mx-1" @click="handleDownloadObject(file.file)"></i>
                      <i class="icon-trash btn-icon text-danger mx-1" @click="tryRemoveFileFromCompany(file.file)"></i>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="4">
                    <button class="btn btn-outline-danger rounded-pill" @click="removeFiles()">{{ $t("Remove selected") }}</button>
                    </th>
                    <th class="text-right bold">{{$t("Total:")}}</th>
                    <th>{{ getTotalFileSize(files) }}</th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import paginationMixin from '../CommonComponents/Pagination/mixinPagination'
import Pagination from '../CommonComponents/Pagination/Pagination'
import momentMixin from '@/mixins/mixinMoment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import mixinDummy from '@/mixins/mixinDummy'
import moment from 'moment'

export default {
  name: 'CompanyFiles',
  mixins: [paginationMixin, momentMixin, mixinDummy],
  components: {
    pagination: Pagination,
    DummyPaymentRequired: DummyPaymentRequired
  },
  data () {
    return {
      files: [],
      selectedFiles: [],
      search: '',
      preloadUsers: {},
      dummy: false,
      loaded: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      }
    }
  },
  created () {
    if (this.companyId) {
      this.loadData()
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    selectAllCheckboxes () {
      for (const i in this.paginationConfig.paginated) {
        if (!this.selectedFiles.includes(this.paginationConfig.paginated[i].file.id)) {
          return false
        }
      }
      return true
    },
    ...mapGetters({
      locale: 'locale'
    })
  },
  watch: {
    companyId () {
      this.loadData()
    },
    search: function () {
      this.filterFiles()
    },
    files: function () {
      this.filterFiles()
    },
    selectedFiles: function () {
      // console.log(this.selectedFiles)
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('isModuleActive', [this.companyId, process.env.VUE_APP_COMPANY_FILES_ID]).then(resolve => {
        this.dummy = false
      }).catch(error => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.$router.push('dashboard')
        }
      })
      this.$store.dispatch('getEmployeesByCompany', this.$store.getters.companyId).then((response) => {
        this.preloadUsers = response
        this.$store.dispatch('getCompanyFiles', this.companyId).then(files => {
          if (files) {
            this.files = files
            this.loaded = true
          }
        })
      })
    },
    filterFiles () {
      this.paginationConfig.rows = this.files.filter((file, index) => {
        const needle = this.search.toString().toLowerCase()
        if (needle === '') {
          return true
        }
        if (file.file.fullname.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
          return true
        }

        return false
      })
    },
    createdDate (file) {
      return this.fromZoneToZone(file.created_at, 'UTC', this.$store.getters.profileTimeZone).format(this.localeDateFormat)
    },
    createdTime (file) {
      return moment(file.created_at).format(this.localeTimeFormat)
    },
    getFileType (file) {
      const fileNameInfo = file.fullname.split(/\.(?=[^.]+$)/)
      return fileNameInfo && fileNameInfo[1] && fileNameInfo[1].toUpperCase()
    },
    getFileSize (size) {
      if (size <= 1024 ** 2) {
        return (size / 1024).toFixed(2) + this.$t(' KB')
      }
      if (size <= 1024 ** 3) {
        return (size / 1024 ** 2).toFixed(2) + this.$t(' MB')
      } else {
        return (size / 1024 ** 3).toFixed(2) + this.$t(' GB')
      }
    },
    getTotalFileSize (files) {
      let size = 0
      files.forEach(file => {
        size += file.file.size
        return size
      })
      return this.getFileSize(size)
    },
    handleDownloadObject (file) {
      window.open(file.url, '_blank')
    },
    tryRemoveFileFromCompany (file) {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        this.removeFileFromCompany(file)
      }).catch(() => {})
    },
    removeFileFromCompany (file) {
      this.$store.dispatch('delCompanyFile', [this.companyId, { file_id: file.id }]).then(response => {
        this.$store.dispatch('getCompanyFiles', this.companyId).then(files => {
          this.files = files
        })
      })
    },
    toggleAllCheckboxes () {
      if (!this.selectAllCheckboxes) {
        for (const i in this.paginationConfig.paginated) {
          this.selectedFiles.push(this.paginationConfig.paginated[i].file.id)
        }
      } else {
        this.selectedFiles = []
      }
    },
    removeFiles () {
      this.$confirm(this.$i18n?.t('You won\'t be able to revert this!'), this.$i18n?.t('Are you sure?'), {
        confirmButtonText: this.$i18n?.t('Yes'),
        cancelButtonText: this.$i18n?.t('No'),
        type: 'warning',
        center: true
      }).then(() => {
        const filtered = this.paginationConfig.paginated
        for (const i in filtered) {
          if (this.selectedFiles.includes(filtered[i].file.id)) {
            this.removeFileFromCompany(filtered[i].file)
          }
        }
        this.selectedFiles = []
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
