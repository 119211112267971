<template>
  <el-tooltip class="item" effect="light" :content="tooltip" placement="top">
    <i class="icon-star-f font-weight-bold text-primary"
       @click="click" data-toggle="modal"
       data-target="#AddHoliday"
       style="font-size: 1.3em; cursor: pointer; color: #53a8dd !important;">
    </i>
  </el-tooltip>
</template>

<script>
export default {
  name: 'StarHolidayIcon',
  props: {
    tooltip: String
  },
  methods: {
    click () {
      this.$eventBus.emit('addHoliday')
    }
  }
}
</script>

<style scoped>
  .icon-star-f:before {
    content: "\e965";
  }
</style>
