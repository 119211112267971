<template>
  <modal-component :id="'UserRequestCalendarModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title" id="">{{ $t('Time off request') }}</h5>
    </template>
    <template #body>
      <div>
        <div>
          {{$t('Created at')}}: {{userRequest.createdAt}}
        </div>
        <div>
          {{$t('Type')}}: {{$t(reason)}}<span v-if="userRequest.reasonData"> {{ userRequest.reasonData.title }}</span> ({{$t(paidType)}}, {{duration}})
        </div>
        <div v-if="userRequest.requestDuration === 'part_day'">
          {{$t('Period')}}: {{$t('part of a day')}}
        </div>
        <div v-else>
          {{$t('Period')}}: {{$t('all day')}}
        </div>
        <div>
          {{$t('From')}}: {{userRequest.startDate}} {{isFullDayDisplayRequest ? '' : userRequest.startTimeRequest}}
          {{$t('To')}}: {{isFullDayDisplayRequest ? endDate() : userRequest.endDate}} {{isFullDayDisplayRequest ? '' : userRequest.endTimeRequest}}
        </div>
        <div class="form-group mt-2">
          <Form ref="observe">
            <ui-text-input
              :label="$t('STATE_A_REASON_ON_CANCEL')"
              :key="'reasonInput'"
              :name="'reasonInput'"
              v-model="reasonInput"
              :placeholder="$t('State a reason')"
              :validation="{required: true, min: 3}"
            />
          </Form>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <router-link :to="`/c/${companyId}/request/${userRequest.requestId}}`" class="btn btn-outline-primary rounded-pill shadow-sm" data-dismiss="modal" aria-label="Close" @click="view">
          {{ $t('View request') }}
        </router-link>
        <button class="btn btn-danger rounded-pill shadow-sm ml-2" @click="cancelRequest">
          {{ $t('Cancel request') }}
        </button>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'

export default {
  name: 'ClickUserRequestModal',
  mixins: [momentMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      reasonInput: '',
      moment: moment
    }
  },
  props: { userRequest: Object, t: Object },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    reason () {
      if (this.userRequest.reason === 'sick') {
        return 'Sick day'
      }
      if (this.userRequest.reason === 'vacation') {
        return 'Vacation'
      }
      if (this.userRequest.reason === 'holiday') {
        return 'Holiday'
      }
      return null
    },
    paidType () {
      if (this.userRequest.isPaid === 1) {
        return 'Paid'
      }
      return 'Unpaid'
    },
    duration () {
      if (this.userRequest.duration === 'part_day') {
        return this.$i18n?.t('hours') + ': ' + moment.duration(moment(this.userRequest.endTimeRequest, this.localeTimeFormat)
          .diff(moment(this.userRequest.startTimeRequest, this.localeTimeFormat)))
          .asHours()
      }
      return this.$i18n?.t('days') + ': ' + Math.round(moment.duration(moment(this.userRequest.endDate, this.localeDateFormat)
        .diff(moment(this.userRequest.startDate, this.localeDateFormat)))
        .asDays() * 100) / 100
    },
    isFullDayDisplayRequest () {
      if (this.userRequest.duration === 'all_day') {
        const from = this.toTimeZone(this.userRequest.date_from)
        const to = this.toTimeZone(this.userRequest.date_to)
        const diff = to.diff(from, 'days')
        if (!(diff >= 1 && to.format(this.backendTimeFormat) === '00:00')) {
          return false
        }

        return true
      }

      return false
    }
  },
  methods: {
    endDate () {
      return this.fromZoneToZone(this.userRequest.to, 'UTC', this.$store.getters.profileTimeZone)
        .add(-1, 'day')
        .format(this.localeDateFormat)
    },
    view () {
      this.closeModal()
    },
    cancelRequest () {
      this.$refs.observe.validate().then(result => {
        if (result.valid) {
          const data = {
            subtype: this.userRequest.subtype,
            move_shifts_action: 'skip',
            status: 'declined',
            message: this.reasonInput
          }
          this.$store.dispatch('editStatus', [this.companyId, this.userRequest.requestId, data]).then(response => {
            this.toastSuccess(this.$i18n?.t('REQUEST_CANCELED_NOTIFICATION'))

            this.$eventBus.emit('refetchEvents')
            document.getElementById('UserRequestCalendarModal').click()
            this.closeModal()
          })
        }
      })
    },
    closeModal () {
      this.$emit('closeUserRequestModal')
    }
  }
}
</script>

<style scoped>

</style>
