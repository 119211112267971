<template>
  <el-tooltip class="item" effect="light" :content="tooltip" placement="top">
    <i class="icon-link font-weight-bold text-primary"
       data-toggle="modal"
       data-target="#DistributeFreeBoardModal"
       @click="click"
       style="font-size: 1.3em; cursor: pointer">
    </i>
  </el-tooltip>
</template>

<script>
export default {
  name: 'DistributeShiftsIcon',
  props: {
    tooltip: String
  },
  methods: {
    click () {
      this.$eventBus.emit('distributeFreeBoard')
    }
  }
}
</script>

<style scoped>

</style>
