<template>
    <div v-if="projects.length > 0">
      <add-user-to-schedule-modal
        v-if="showAddUserToScheduleModal"
        :userId="userId"
        v-on:closeAddUserToScheduleModal="() => {
          this.showAddUserToScheduleModal = false
          this.currentProject = {}
          this.userSchedules = []
        }"
        :userSchedules="userSchedules"
        :project="currentProject">
      </add-user-to-schedule-modal>
      <remove-user-from-schedule-modal
        v-if="showRemoveUserFromScheduleModal"
        :userId="userId"
        :scheduleId="currentSchedule"
        v-on:closeRemoveUserFromScheduleModal="() => {
          this.showRemoveUserFromScheduleModal = false
          this.currentSchedule = null
        }">
      </remove-user-from-schedule-modal>
      <template v-for="project in projects" :key="project.id">
        <user-project-info-component
          v-on:init="init"
          :userId="userId"
          :project="project">
        </user-project-info-component>
      </template>
    </div>
</template>

<script>
import UserProjectInfoComponent from './UserProjectInfoComponent'
import AddUserToScheduleModal from '../UserModals/AddUserToScheduleModal'
import RemoveUserFromScheduleModal from '@/components/User/user/UserModals/RemoveUserFromScheduleModal'

export default {
  name: 'UserProjectsInfo',
  components: { RemoveUserFromScheduleModal, AddUserToScheduleModal, UserProjectInfoComponent },
  props: { userInfo: Object, userId: Number },
  data () {
    return {
      projects: [],
      currentProject: {},
      userSchedules: [],
      currentSchedule: null,
      showAddUserToScheduleModal: false,
      showRemoveUserFromScheduleModal: false
    }
  },
  created () {
    // console.log(this.userInfo)
    this.$eventBus.on('updateProjectsList', this.init)
    this.$eventBus.on('showAddUserToScheduleModal', ([project, schedules]) => {
      // console.log(123, project, schedules)
      this.currentProject = project
      this.userSchedules = schedules
      this.showAddUserToScheduleModal = true
    })
    this.$eventBus.on('showRemoveUserFromScheduleModal', (scheduleId) => {
      // console.log(123, project, schedules)
      this.currentSchedule = scheduleId
      this.showRemoveUserFromScheduleModal = true
    })
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    init () {
      this.projects = []
      if (!this.isEmployeeHasPermission('get project data')) {
        this.accessErrorRedirect('You don\'t have permission for this action')
      }
      this.$store.dispatch('getProjectsByCompany', this.companyId).then(response => {
        response.forEach(project => {
          const cloned = Object.assign({}, project)
          if (this.isEmployeeHasPermission('delete-employee') ||
            (this.isEmployeeHasPermission('get related projects full data') &&
              project.role === 'manager')) {
            if (cloned.managers.includes(this.userId) || cloned.users.includes(this.userId)) {
              if (cloned.managers.includes(this.userId) && cloned.users.includes(this.userId)) {
                cloned.role = 'manager_user'
              } else if (cloned.managers.includes(this.userId)) {
                cloned.role = 'manager'
              } else {
                cloned.role = 'user'
              }
              this.projects.push(cloned)
            }
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
