<template>
  <div class="card radius-all" style="border-color: #00b7f1">
    <Form ref="observer" as="div" class="card-body text-dark">
      <div class="form-row mb-3">
        <div class="col-lg-12">
          <ui-masked-input
            :label="$t('Enter card number')"
            name="cardNumber"
            v-model="newCard.cardNumber"
            :validation="{ required: true, min: 19 }"
            prepend-icon="icon-credit-card"
            mask="0000 0000 0000 0000"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <ui-input-label :label="$t('Card expire date')" />
        </div>
        <div class="col"></div>
        <div class="col">
          <ui-input-label :label="$t('CCV/CVC')" />
        </div>
      </div>
      <div class="form-row mb-3">
        <div class="col">
          <ui-select
            v-model="newCard.expire.month"
            :validation="{required: true}"
            name="expire_month"
            :clearable="false"
            :filterable="false"
            :placeholder="$t('MM')"
            :options="months"
          />
        </div>
        <div class="col">
          <ui-select
            v-model="newCard.expire.year"
            :validation="{required: true}"
            name="expire_year"
            :clearable="false"
            :filterable="false"
            :placeholder="$t('YY')"
            :options="years"
          />
        </div>
        <div class="col">
          <ui-masked-input
            name="cvc"
            v-model="newCard.CVC"
            mask="0000"
            :validation="{required: true, integer: true, min: 3, max: 4}"
          />
        </div>
      </div>
      <div class="form-row mb-0">
        <div class="col-md-12 text-center py-2">
          <button type="submit" name="save" class="btn btn-success rounded-pill shadow-sm" @click="submitForm">{{ $t('SAVE_WEB') }}</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'CreditCard',
  mixins: [errorMixin],

  data () {
    return {
      newCard: {
        cardNumber: '',
        expire: {
          month: '',
          year: ''
        },
        CVC: ''
      },
      months: [
        {
          id: '01',
          name: '01'
        },
        {
          id: '02',
          name: '02'
        },
        {
          id: '03',
          name: '03'
        },
        {
          id: '04',
          name: '04'
        },
        {
          id: '05',
          name: '05'
        },
        {
          id: '06',
          name: '06'
        },
        {
          id: '07',
          name: '07'
        },
        {
          id: '08',
          name: '08'
        },
        {
          id: '09',
          name: '09'
        },
        {
          id: '10',
          name: '10'
        },
        {
          id: '11',
          name: '11'
        },
        {
          id: '12',
          name: '12'
        }
      ],
      years: []
    }
  },
  created () {
    for (let i = 0; i <= 11; i++) {
      this.years.push({
        id: moment().add(i, 'years').format('YYYY'),
        name: moment().add(i, 'years').format('YYYY')
      })
    }
  },
  methods: {
    submitForm () {
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          console.log(123, this.newCard)
          const str = this.newCard.cardNumber.replace(/ /g, '')
          const data = {
            exp_month: parseInt(this.newCard.expire.month),
            exp_year: parseInt(this.newCard.expire.year),
            number: str,
            cvc: this.newCard.CVC
          }
          this.$store.dispatch('setCard', data).then(response => {
            if (response) {
              this.$store.dispatch('clearCompanyStore')
              this.$store.dispatch('getCompany', this.companyId)
              this.newCard = { cardNumber: '', expire: { month: '', year: '' }, CVC: '' }
              this.toastSuccess(this.$t('Card successfully added'))

              this.$eventBus.emit('addCard', response)
            } else {
              this.toastError(this.$t('Oops! Something went wrong!'))
            }
          }).catch((err) => {
            this.toastError(this.$t(err.response.data.message))
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .el-select {
    width: 48%;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ced4da;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ced4da;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #ced4da;
  }
</style>
