<template>
  <el-radio-group
    v-bind="$attrs"
    size="default"
  >
    <slot></slot>
  </el-radio-group>
</template>

<script>
export default {
  name: 'radioGroup'
}
</script>
<style scoped lang="scss">
</style>
