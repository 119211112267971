<template>
  <modal-component
    :id="'AddUserToScheduleModal'"
    :closeModalId="'AddUserToScheduleModalClose'"
    @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('Add employee to schedule')}}</h5>
    </template>
    <template #body>
      <Form ref="observe">
        <template v-if="unconfirmedProceed === null">
          <div class="mt-3">
            <ui-select
              name="schedule"
              :label="$t('Schedule')"
              v-model="scheduleId"
              class="w-100"
              :options="schedulesChoices"
              :validation="{required: true}"
              :key-name="'id'"
              :value-name="'id'"
              :label-name="'name'"
            />
            <div class="mt-2">{{ $t('Select schedule') }}</div>
          </div>
          <div class="mt-3">
            <ui-date-picker
              :label="$t('Add employee starting from the date')"
              :name="'start_date'"
              v-model="fromDate"
              :validation="{required: true}"
              :clearable="false"
              :disabled-date="disabledDays"
            />
            <div class="mt-2">{{ $t('Employee will work starting from a selected date')}}</div>
          </div>
          <div class="mt-3" v-if="addTypeChoices.length > 1">
            <ui-select
              name="addType"
              :label="$t('Addition type')"
              v-model="addType"
              class="w-100"
              :options="addTypeChoices"
              :validation="{required: false}"
              :key-name="'key'"
              :value-name="'id'"
              :label-name="'name'"
            />
            <div class="mt-2 text-center">{{ $t('_ADD_USER_ADD_TYPE_DESC') }}</div>
          </div>
        </template>
        <template v-else>
          <div class="card card-small">
            <div class="card-body text-center p-3">
              <h4 class="text-success font-weight-bold m-3">
                {{ $t('Greetings from ShiftON!') }}
              </h4>
              <h5 class="text-success font-weight-bold m-3">
                {{ $t('Confirm your phone number to be able to invite employees.') }}
              </h5>
              <div>
                <router-link @click="closeModal()" to="/profile">
                  <button class="btn btn-success rounded-pill">
                    {{ $t('Profile') }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </template>
      </Form>
    </template>
    <template #footer>
      <div>
        <button type="button" :disabled="unconfirmedProceed === null ? false : true" class="btn btn-primary rounded-pill shadow-sm" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import momentMixin from '@/mixins/mixinMoment'
import errorMixin from '@/mixins/mixinApiErrors'
import moment from 'moment'

export default {
  name: 'AddUserToScheduleModal',
  mixins: [errorMixin, momentMixin],
  components: {
    ModalComponent: ModalComponent
  },
  data () {
    return {
      scheduleId: null,
      addType: 'without_shifts',
      fromDate: null,
      schedulesChoices: [],
      addTypeChoices: [
        {
          id: 'without_shifts',
          name: this.$i18n?.t('Add without shifts')
        }
        // {
        //   id: 0,
        //   name: this.$i18n?.t('From free board')
        // }
      ],
      dateFormat: this.localeDateFormatElementUi,
      selectedSchedule: null
    }
  },
  props: { project: Object, userId: Number, userSchedules: Array },
  created () {
    // console.log(this.companyId)
    if (this.companyId) {
      this.init()
    }
  },
  watch: {
    companyId () {
      this.init()
    },
    scheduleId () {
      this.scheduleChange()
    }
  },
  computed: {
    unconfirmedProceed () {
      return localStorage.getItem('unconfirmedProceed')
    }
  },
  methods: {
    disabledDays (date) {
      if (!this.selectedSchedule) {
        return null
      }
      const project = this.$store.getters.projectById(this.selectedSchedule.project_id)
      const from = this.toSelectedTimeZone(this.selectedSchedule.date_from, project.time_zone).format(this.backendDateFormat)
      const to = this.toSelectedTimeZone(this.selectedSchedule.date_to, project.time_zone).format(this.backendDateFormat)
      return moment.utc(date).isBefore(from) || moment.utc(date).isAfter(to)
    },
    init () {
      // console.log(1)
      this.$store.dispatch('getSchedulesByProject', [this.project.id]).then((schedules) => {
        this.schedulesChoices = schedules.filter(({ id: id1 }) => !this.userSchedules.some(({ scheduleId: id2 }) => id2 === id1))
      })
    },
    scheduleChange () {
      this.$store.dispatch('getScheduleById', this.scheduleId).then(schedule => {
        if (schedule && schedule.schedule_type === 'by_availability') {
          this.addTypeChoices.push({
            id: 'by_availability',
            name: this.$i18n?.t('By user availability')
          })
        } else if (this.addTypeChoices.length > 2) {
          this.addUsersChoices.pop()
        }
        // this.fromDate = schedule.date_from
        this.selectedSchedule = schedule
      })
    },
    closeModal () {
      // document.getElementById('AddUserToScheduleModalClose').click()
      this.$emit('closeAddUserToScheduleModal')
    },
    save () {
      this.$refs.observe.validate().then(({ valid }) => {
        if (valid) {
          const formData = {
            users: [this.userId],
            add_type: this.addType,
            date_from: moment(this.fromDate).format(this.backendDateTimeFormat),
            preview: true
          }

          this.$store.dispatch('addUsers', [this.scheduleId, formData]).then((response) => {
            this.init()
            this.toastSuccess(this.$t('Schedule was successfully changed'))
            document.getElementById('AddUserToScheduleModalClose').click()
            this.closeModal()
            this.$eventBus.emit('updateSchedulesList')
          }).catch(e => {
            this.toastError(this.$i18n?.t(e.response.data.message))
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
