<template>
  <div>
    <h3 class="mb-2">{{ $t('Select or create positions') }}</h3>
    <p class="mb-4">{{ $t('POSITIONS_STEP_DESCRIPTION') }}</p>
    <el-checkbox-group
      name="mutableSelectedPositions"
      v-model="mutableSelectedPositions"
    >
      <el-checkbox-button
        :name="index + '__position'"
        v-for="(position, index) in positions"
        :value="positions[index]"
        :key="position"
        class="mr-2
">
        {{ position }}
      </el-checkbox-button>
    </el-checkbox-group>
    <Form>
      <div class="positions-wrapper">
        <ui-text-input
          name="newPosition"
          v-model="newPosition"
          :placeholder="$t('Position title')"
        />
        <el-button
          type="primary"
          plain
          class="btn btn-outline-primary"
          size="large"
          @click="addNewPosition"
          :disabled="newPosition.length === 0"
        >
          {{ $t('Add new position') }}
        </el-button>
      </div>
      <div v-show="positionError" class="text-danger small">{{ $t('This position already exists') }}</div>
    </Form>
  </div>
</template>

<script>
import {
  coffeePositions,
  retail,
  healthcare,
  fitness,
  entertainment,
  nonProfit,
  other,
  callCenter,
  bank,
  cleaning,
  shop,
  hotel,
  security,
  delivery,
  construction
} from './data-positions'

export default {
  name: 'PositionsStep',
  props: {
    selectedPositions: {
      type: Array,
      required: true
    },
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      newPosition: '',
      mutableSelectedPositions: this.selectedPositions,
      positions: [],
      positionError: false
    }
  },
  created () {
    this.selectPositionsByIndustry()
  },
  watch: {
    mutableSelectedPositions () {
      console.log(2, this.mutableSelectedPositions)
      this.$emit('refreshSelectedPositions', this.mutableSelectedPositions)
    },
    newPosition () {
      this.positionError = false
    }
  },
  methods: {
    setTranslatedPositions (positions) {
      positions.forEach((position) => {
        this.positions.push(this.$t(position))
      })
    },
    addNewPosition () {
      if (this.newPosition) {
        if (this.positions.includes(this.newPosition)) {
          this.positionError = true
        } else {
          this.positions.push(this.newPosition)
          this.newPosition = ''
        }
      }
    },
    selectPositionsByIndustry () {
      switch (this.company.industry) {
        case 'Coffee Shop/Bakery':
          this.setTranslatedPositions(coffeePositions)
          break
        case 'Fitness/Beauty/Personal Care':
          this.setTranslatedPositions(fitness)
          break
        case 'Healthcare':
          this.setTranslatedPositions(healthcare)
          break
        case 'Leisure/Entertainment':
          this.setTranslatedPositions(entertainment)
          break
        case 'Retail':
          this.setTranslatedPositions(retail)
          break
        case 'Non-profit':
          this.setTranslatedPositions(nonProfit)
          break
        case 'Other':
          this.setTranslatedPositions(other)
          break
        case 'Call center':
          this.setTranslatedPositions(callCenter)
          break
        case 'Bank':
          this.setTranslatedPositions(bank)
          break
        case 'Cleaning company':
          this.setTranslatedPositions(cleaning)
          break
        case 'Shop':
          this.setTranslatedPositions(shop)
          break
        case 'Hotel':
          this.setTranslatedPositions(hotel)
          break
        case 'Security service':
          this.setTranslatedPositions(security)
          break
        case 'Delivery service':
          this.setTranslatedPositions(delivery)
          break
        case 'Construction and repair':
          this.setTranslatedPositions(construction)
          break
        default:
          throw new Error('No such industry')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.positions-wrapper {
  display: flex;
  max-width: 400px;
  gap: 10px;
  & input {
    max-width: 200px;
  }
}
</style>
