<template>
  <modal-component :id="'UpdateLoadStatisticModal'" @closeModal="closeModal" :modalClass="'modal-lg'">
    <template #header>
      <h5 class="modal-title" id="InvitesModalTitle">{{ $t('Update load statistic')}}</h5>
    </template>
    <template #body>
      <div>
        <Form ref="observer">
          <div class="row">
            <h6 class="col-12">
              <div class="font-weight-bold mb-2">
                {{ $t('Date') }} {{fromZoneToZone(loadItem.date, 'UTC', project_tz).format(localeDateFormat)}}
              </div>
            </h6>
          </div>
          <div class="row">
            <div class="col-6">
              <ui-select
                :label=" $t('STEP_VALUE')"
                :hint="$t('STEP_VALUE_HINT')"
                name="actionType"
                v-model="step"
                :clearable="false"
                :filterable="false"
                class="w-100"
                :options="stepList"
                :key-name="'value'"
                :label-name="'label'"
                :value-name="'value'"
                :disabled="true"
                :validation="{required: true}"
                @change="onStepChange"
              />
            </div>
            <div class="col-6">
            </div>
          </div>
          <div class="row mt-2">
            <div v-for="(value, index) in demand_values" class="col-3 mb-2" :key="index">
              <ui-number-input
                :name="index + '__demand_value'"
                :label="labels[index]"
                key="load-input"
                :controls="false"
                :step="1"
                :precision="0"
                :min="0"
                v-model="demand_values[index]"
                :validation="{required: true, integer: true, min_value: 0}"
              />
            </div>
          </div>
        </Form>
      </div>
    </template>
    <template #footer>
      <div>
        <button type="button" class="btn btn-success rounded-pill shadow-sm" aria-label="Close" @click="save">
          <span aria-hidden="true">{{ $t('SAVE_WEB')}}</span>
        </button>
      </div>
    </template>
  </modal-component>
</template>
<script>
import momentMixin from '../../../../mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import errorMixin from '../../../../mixins/mixinApiErrors'

export default {
  name: 'UpdateLoadDemandModal',
  components: {
    ModalComponent: ModalComponent
  },
  mixins: [momentMixin, errorMixin],
  data () {
    return {
      step: 60,
      demand_values: '',
      stepList: [
        {
          label: this.$t('60 min'),
          value: 60
        },
        {
          label: this.$t('30 min'),
          value: 30
        }
        // add steps here
      ],
      repeatedDatesAction: 'replace'
    }
  },
  props: {
    loadItem: Object,
    project_tz: String,
    doNotUpdate: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.step = this.loadItem.step
    this.demand_values = this.loadItem.demand_values
  },
  computed: {
    labels () {
      const labelsLength = (60 / this.step) * 24
      const step = 24 / labelsLength
      const labels = []
      for (let i = 0; i < 24; i += step) {
        labels.push(i.toString())
      }
      return labels
    }
  },
  methods: {
    onStepChange () {
      const demandValuesLength = (60 / this.step) * 24
      this.demand_values = Array.apply(null, Array(demandValuesLength)).map(() => { return 0 })
    },
    closeModal () {
      document.getElementById('modalCloseButton').click()
      this.$emit('close')
    },
    save () {
      const payload = {
        demand_values: this.demand_values.map(val => +val),
        step: this.step
      }
      if (this.step === 60 && payload.demand_values.length !== 24) {
        this.$validator.errors.add({
          field: 'demand_values',
          msg: 'Incorrect number of demand values'
        })
        return
      }
      this.$refs.observer.validate().then(result => {
        if (result.valid) {
          if (!this.doNotUpdate) {
            this.$store.dispatch('updateLoadDemand', [this.loadItem.schedule_id, this.loadItem.id, payload]).then(response => {
              this.toastSuccess(this.$i18n?.t('Update completed!'))
              this.$emit('reload')
              this.closeModal()
            }).catch(e => {
              this.toastError(this.$i18n?.t(e.message))
            })
          } else {
            payload.repeated_dates_action = 'replace'
            payload.date = this.fromZoneToZone(this.loadItem.date, 'UTC', this.project_tz).format(this.backendDateTimeFormat)
            this.$emit('reload', payload)
            this.closeModal()
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
