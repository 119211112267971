<template>
    <div class="p-3">
        <h1 class="title">{{ $t('DEV_OVERVIEW_HEADING') }}</h1>
        <p class="text mb-3">{{ $t('DEV_OVERVIEW_TEXT') }}</p>
        <div class="mb-2">
            <p class="mb-1">{{ $t('DEV_NEW_DOCS_TEXT') }}</p>
            <a href="https://api2.shifton.com/openapi/#/" target="_blank"><button type="button" class="btn btn-primary">{{ $t('DEV_NEW_DOCS') }}</button></a>
        </div>
        <div class="mb-2">
            <p class="mb-1">{{ $t('DEV_OLD_DOCS_TEXT') }}</p>
            <a href="https://api2.shifton.com/docs/#/en/v1/" target="_blank"><button type="button" class="btn btn-secondary">{{ $t('DEV_OLD_DOCS') }}</button></a>
        </div>
    </div>
</template>

<script>
</script>

<style scoped>
p {
    margin: 0;
    padding: 0;
}

.title {
    font-weight: 600;
    font-size: 28px;
}

.text {
    font-size: 20px;
    color: #57606a;
}

</style>
