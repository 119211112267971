<template>
  <div class="card card-small">
    <div class="card-body">
      <ShiftActivityModal
          v-if="showShiftActivityModal"
          :shiftActivityModalData="shiftActivityModalData"
          @closeShiftActivityModal="closeShiftActivityModal"
          @updateActivity="updateEvents"
          :title="$t('SHIFT_ACTIVITY_MODAL')"
          :initStart="initStart"
          :initEnd="initEnd"
      />
      <ShiftActivityMassActionModal
          v-if="showMassActionActivityModal"
          @closeShiftActivityModal="closeShiftActivityModal"
          @updateActivity="updateEvents"
          :title="$t('SHIFT_ACTIVITY_MASS_ACTION_MODAL')"
          :initStart="initStart"
          :initEnd="initEnd"
      />
      <ShiftActivityDistributeModal
          v-if="showDistributeActivityModal"
          @closeShiftActivityModal="closeShiftActivityModal"
          @updateActivity="updateEvents"
          :title="$t('SHIFT_ACTIVITY_DISTRIBUTE_MODAL')"
          :initStart="initStart"
          :initEnd="initEnd"
      />
      <div v-if="isEmployeeHasPermission('get-shift-activities-admin') || isEmployeeHasPermission('get-shift-activities-manager')">
        <tab-navigation-component v-if="tabs.length > 0" :tabs="tabs" />
      </div>
      <div class="row my-2">
        <div class="col title-text">
          {{ $t('SHIFT_ACTIVITIES_CALENDAR') }}
        </div>
        <div class="col-auto" style="min-width: 240px">
          <ui-select
              name="show_mode"
              v-model="showMode"
              class="cell-width"
              :options="showModeChoice"
              :validation="{required: false}"
              :key-name="'id'"
              :value-name="'id'"
              :label-name="'name'"
              @change="changeShowMode"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="drawer" class="col-lg-2">
          <div class="card mb-2">
            <div class="card-body">
              <activity-drawer-filters
                  @applyFilter="applyFilter"
              />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="my-2">
                <i class="icon-menu btn-icon mx-1" @click="handleDrawer" :class="drawer ? 'text-primary' : 'text-dark'" />
                <template v-if="isEmployeeHasPermission('get-shift-activities-admin') || isEmployeeHasPermission('get-shift-activities-manager')">
                  <button
                      @click="createActivity"
                      class="btn btn-primary btn-sm btn-circle shadow-sm mx-1"
                      data-toggle="modal"
                      data-target="#ClickShiftActivityModal">
                    {{ $t('CREATE_SHIFT_ACTIVITY') }}
                  </button>
                  <button
                      @click="massUpdateActivity"
                      class="btn btn-primary btn-sm btn-circle shadow-sm mx-1"
                      data-toggle="modal"
                      data-target="#ClickMassActionActivity">
                    {{ $t('UPDATE_SHIFT_ACTIVITIES') }}
                  </button>
                  <button
                      @click="distributeActivity"
                      class="btn btn-primary btn-sm btn-circle shadow-sm mx-1"
                      data-toggle="modal"
                      data-target="#ClickDistributeActivity">
                    {{ $t('DISTRIBUTE_SHIFT_ACTIVITIES') }}
                  </button>
                </template>
              </div>
              <div ref="sizing">
                <full-calendar v-if="calendarOptions" ref="activityCalendar" :options="calendarOptions">
                  <template v-slot:resourceLabelContent='arg'>
                    <ResourceItem
                        :resource="arg.resource"
                        :lang="locale"
                    />
                  </template>
                  <template v-slot:eventContent='arg'>
                    <template v-if="arg.event.display === 'background'">
                      <ShiftActivityBackgroundItem
                          :event="arg.event"
                          :lang="locale"
                      />
                    </template>
                    <template v-else>
                      <ShiftActivityItem
                          class="font-weight-bold"
                          :event="arg.event"
                          :locale="locale"
                      />
                    </template>
                  </template>
                </full-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import errorMixin from '@/mixins/mixinApiErrors'
import ToastMixin from '@/mixins/ToastMixin'
import ShiftActivityItem from '@/components/ShiftActivity/Components/ShiftActivityItem.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interaction from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import ruLocale from '@fullcalendar/core/locales/ru'
import heLocale from '@fullcalendar/core/locales/he'
import ukLocale from '@fullcalendar/core/locales/uk'
import deLocale from '@fullcalendar/core/locales/de'
import plLocale from '@fullcalendar/core/locales/pl'
import esLocale from '@fullcalendar/core/locales/es'
import elLocale from '@fullcalendar/core/locales/el'
import moment from 'moment-timezone'
import mixinWindowWidthWatch from '@/mixins/mixinWindowWidthWatch'
import DatePickerComponent from '@/components/CommonComponents/Fullcalendar/DatePickerComponent.vue'
import momentMixin from '@/mixins/mixinMoment'
import mixinFCWeek from '@/mixins/mixinFCWeek'
import ShiftActivityBackgroundItem from '@/components/ShiftActivity/Components/ShiftActivityBackgroundItem.vue'
import ResourceItem from '@/components/ShiftActivity/Components/ResourceItem.vue'
import TabNavigationComponent from '@/components/CommonComponents/TabNavigationComponent.vue'
import ShiftActivityModal from '@/components/ShiftActivity/Modals/ShiftActivityModal.vue'
import ActivityDrawerFilters from '@/components/ShiftActivity/Components/ActivityDrawerFilters.vue'
import ShiftActivityMassActionModal from '@/components/ShiftActivity/Modals/ShiftActivityMassActionModal.vue'
import ShiftActivityDistributeModal from '@/components/ShiftActivity/Modals/ShiftActivityDistributeModal.vue'
import { mount } from '@/utils/mount'
import { app } from '@/main'

const FREE_BOARD_RESOURCE_ID = 0

export default {
  name: 'ActivityCalendar',
  components: {
    ShiftActivityDistributeModal,
    ShiftActivityMassActionModal,
    ActivityDrawerFilters,
    ShiftActivityModal,
    TabNavigationComponent,
    ResourceItem,
    ShiftActivityBackgroundItem,
    ShiftActivityItem,
    FullCalendar
  },
  mixins: [errorMixin, ToastMixin, mixinWindowWidthWatch, momentMixin, mixinFCWeek],
  data () {
    return {
      drawer: true,
      calendarOptions: null,
      employees: null,
      calendarApi: null,
      view: {},
      taskCalendarDefaultView: 'resourceTimelineWeek',
      tabs: [],
      showShiftActivityModal: false,
      showMassActionActivityModal: false,
      showDistributeActivityModal: false,
      shiftActivityModalData: {
        id: null,
        title: null,
        template_id: null,
        time_from: '09:00',
        time_to: '17:00',
        date_from: null,
        date_to: null,
        location_id: null,
        position_id: null,
        project_id: null,
        schedule_id: null,
        marks: [],
        skills: [],
        count: 1,
        note: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: [],
        type: 'period',
        color: '#4e93e9',
        time_zone: null
      },
      slotWidth3days: 10,
      freeBoard: [],
      primarySortOrder: 'name',
      filters: {
        projects: [],
        schedules: [],
        employees: [],
        locations: [],
        positions: [],
        marks: [],
        statuses: []
      },
      allowedUsers: [],
      showMode: 'all',
      showModeChoice: [
        {
          name: this.$t('ALL_USERS'),
          id: 'all'
        },
        {
          name: this.$t('USERS_WITH_SHIFTS'),
          id: 'shifts'
        }
      ],
      initStart: moment().startOf('isoWeek'),
      initEnd: moment().endOf('isoWeek'),
      projectsWhereEmployeeIsManager: [],
      events: [],
      resources: []
    }
  },
  created () {
    if (this.companyId) {
      this.init()
      this.tabs = [
        {
          link: `/c/${this.companyId}/shift-activities`,
          title: 'Shift activities calendar',
          isActive: true
        },
        {
          link: `/c/${this.companyId}/shifts-activity-templates`,
          title: 'Shift activity templates',
          isActive: false
        }
      ]
    }
  },
  mounted () {
    this.$eventBus.on('goToDate', this.goToDate)
    this.$eventBus.on('updateActivityStatus', this.updateEvents)
  },
  beforeUnmount () {
    this.$eventBus.off('goToDate')
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale'
    }),
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    header () {
      if (this.windowWidth >= 576) {
        return {
          start: 'title',
          center: 'prev,calendarButton,next',
          end: 'today resourceTimelineDay,resourceTimeline3days,resourceTimelineWeek,resourceTimeMonth'
        }
      }
      return {
        start: 'title',
        center: 'prev,calendarButton,next',
        end: 'today'
      }
    },
    views () {
      if (this.windowWidth >= 576) {
        return {
          resourceTimelineDay: {
            slotLabelFormat: { // формат времени лейбла временной сетки
              hour: 'numeric',
              hour12: this.is12hourFormat,
              omitZeroMinute: false,
              meridiem: 'short'
            },
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            slotDuration: '00:10:00',
            slotMinWidth: 5,
            displayEventTime: true,
            displayEventEnd: true,
            titleFormat: { // will produce something like "Tuesday, September 18, 2018"
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          },
          resourceTimeline3days: {
            type: 'resourceTimelineMonth',
            slotLabelInterval: '24:00',
            buttonText: this.$t('3 days'),
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            // dateIncrement: '24:00',
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { days: 3 },
            slotDuration: { hours: 1 },
            slotMinWidth: this.slotWidth3days
          },
          resourceTimelineWeek: {
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { week: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00'
            // slotWidth: this.slotWidth
          },
          resourceTimeMonth: {
            type: 'resourceTimelineMonth',
            slotLabelFormat: [{ // формат времени лейбла временной сетки
              // weekday: 'narrow',
              weekday: 'short',
              day: 'numeric'
            }],
            eventTimeFormat: { // формат времени, которое отображается на событии
              hour: 'numeric',
              hour12: this.is12hourFormat,
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short'
            },
            editable: true,
            displayEventTime: true,
            displayEventEnd: true,
            duration: { month: 1 },
            slotDuration: { days: 1 },
            snapDuration: '24:00',
            slotMinWidth: 105
          }
        }
      }
      return {
        resourceTimelineDay: {
          slotLabelFormat: [{ // формат времени лейбла временной сетки
            weekday: 'short',
            day: 'numeric'
          }],
          eventTimeFormat: { // формат времени, которое отображается на событии
            hour: 'numeric',
            hour12: this.is12hourFormat,
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          },
          editable: true,
          displayEventTime: true,
          displayEventEnd: true,
          duration: { day: 1 },
          slotDuration: { days: 1 },
          snapDuration: '24:00',
          titleFormat: { // will produce something like "Tuesday, September 18, 2018"
            month: 'numeric',
            year: 'numeric',
            day: 'numeric',
            weekday: 'short'
          }
        }
      }
    },
    handleResourceColumnWidth () {
      if (this.windowWidth >= 576) {
        return '275px'
      }
      return '60%'
    },
    firstDay () {
      return this.fcWeek[this.apiWeek[this.weekStart]]
    },
    tz () {
      return this.$store.getters.profileTimeZone
    },
    currentEmployeeId  () {
      return this.$store.getters.company.employee_id
    },
    projects () {
      return this.$store.getters.projectsIndexed
    },
    schedules () {
      return this.$store.getters.schedulesIndexed
    },
    indexedMarks () {
      const indexedMarks = {}
      this.$store.getters.companyMarks.forEach(mark => {
        indexedMarks[mark.id] = mark
      })
      return indexedMarks
    },
    locationsIndexed () {
      return this.$store.getters.locationsIndexed
    },
    positionsIndexed () {
      return this.$store.getters.positionsIndexed
    }
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    init () {
      this.shiftActivityModalData.time_zone = this.tz
      const templates = this.$store.dispatch('getActivityTemplates', this.companyId)
      const projects = this.$store.dispatch('getProjectsByCompany', this.companyId)
      const schedules = this.$store.dispatch('getSchedules', this.companyId)
      const locations = this.$store.dispatch('getLocations', this.companyId)
      const positions = this.$store.dispatch('getPositions', this.companyId)
      const marks = this.$store.dispatch('getMarksByCompany', this.companyId)
      Promise.all([templates, projects, schedules, locations, positions, marks]).then(val => {
        this.projectsWhereEmployeeIsManager = val[1].filter(item => item.managers).map(item => item.id)
        this.getEmployeesByCompany()
      })
    },
    getEmployeesByCompany () {
      this.$store.dispatch('getEmployeesByCompany', this.companyId).then((employees) => {
        this.employees = employees
        this.setCalendarOptions()
        this.$nextTick(_ => {
          this.calendarApi = this.$refs.activityCalendar.getApi()
        })
      })
    },
    setCalendarOptions () {
      this.calendarOptions = {
        schedulerLicenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
        plugins: [interaction, resourceTimelinePlugin, dayGridPlugin, bootstrapPlugin],
        aspectRatio: 1.5,
        themeSystem: 'bootstrap',
        selectable: true,
        droppable: true,
        editable: true,
        headerToolbar: this.header,
        stickyHeaderDates: true,
        stickyFooterScrollbar: false,
        firstDay: this.firstDay,
        height: 'auto',
        // nowIndicator: true,
        refetchResourcesOnNavigate: false,
        nowIndicatorDidMount: () => {
        },
        customButtons: {
          calendarButton: {
            text: ' '
          }
        },
        buttonText: {
          prev: '<',
          next: '>'
        },
        initialView: this.taskCalendarDefaultView,
        locales: [ruLocale, heLocale, ukLocale, deLocale, plLocale, esLocale, elLocale],
        locale: this.locale,
        direction: this.direction,
        dayMaxEventRows: false,
        lazyFetching: false,
        views: this.views,
        viewClassNames: (arg) => {
          this.view = {}
          this.view = arg.view
          if (arg.view.type === 'resourceTimeMonth') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        slotLabelClassNames: () => {
          if (this.view.type === 'resourceTimeMonth') {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'normal'
                el.style.fontSize = '0.6em'
              })
            }, 1000)
          } else {
            setTimeout(() => {
              const elements = document.getElementsByClassName('fc-timeline-slot-cushion')
              Array.from(elements).forEach((el) => {
                el.style.fontWeight = 'bold'
                el.style.fontSize = '1em'
              })
            }, 1000)
          }
        },
        scrollTime: '00:00:00',
        nextDayThreshold: '24:00:00',
        resourceAreaWidth: this.handleResourceColumnWidth,
        resourceAreaColumns: [
          {
            labelText: '',
            field: 'title'
          }
        ],
        loading: (isLoading) => {
        },
        resourceOrder: 'type,sortOrder,title',
        resourceLaneClassNames: (el) => {
          if (el.resource.id === '0' || el.resource.id === this.currentEmployeeId.toString()) {
            return 'bg-resource-color'
          }
        },
        resourceLabelClassNames: (el) => {
          if (el.resource.id === '0' || el.resource.id === this.currentEmployeeId.toString()) {
            return 'bg-resource-color'
          }
        },
        resources: (fetchInfo, successCallback, failureCallback) => {
          this.createResources().then(resources => {
            // console.log(resources)
            successCallback(this.applyResourceFilter(resources))
          }).catch(e => {
            failureCallback(e.message)
          })
        },
        // use events, till we find any reason to use sources below
        events: (fetchInfo, successCallback, failureCallback) => {
          this.getEvents(fetchInfo).then(events => {
            this.setEvents(events).then(response => {
              this.events = this.applyActivityFilter(response)
              successCallback(this.events)
            })
          })
        },
        viewDidMount: (info) => {
          // монтируем датапикер на кнопку в хедере календаря
          const { el } = mount(DatePickerComponent,
            {
              props: {
                locale: this.$store.getters.locale
              },
              app: app
            }
          )
          let btn = document.querySelector('.fc-calendarButton-button')
          if (btn) {
            btn.replaceWith(el)
          }
          if (this.isMobile) {
            btn = document.querySelector('.fc-today-button')
            btn.classList.add('btn-sm')
            btn.classList.add('mx-1')
            btn = document.querySelector('.fc-prev-button')
            btn.classList.add('btn-sm')
            btn = document.querySelector('.fc-next-button')
            btn.classList.add('btn-sm')
          }
        },
        datesSet: (info) => {
          this.view = {} // todo костыль для активанции вотчера при первой загрузке календаря (вотчер не видит изменения the.view пока не переключишься между вьюхами), проверить после обновления версии календаря
          this.view = info.view
          this.initStart = moment(info.start)
          this.initEnd = moment(info.end).add(-1, 'day')
          this.$eventBus.emit('updateCurrentDate', this.view.currentStart)
        },
        dateClick: (dateClickInfo) => {
          // console.log(456, dateClickInfo)
        },
        eventClick: (eventClickInfo) => {
          if (this.isEmployeeHasPermission('update-shift-activity')) {
            this.updateActivity(eventClickInfo.event)
          }
        },
        eventDrop: (info) => {
          const activity = info.event
          const payload = { ...activity.extendedProps.item }
          payload.time_from = this.fromZoneToZone(activity.start, this.tz, 'UTC').format(this.backendDateTimeFormat)
          payload.time_to = this.fromZoneToZone(activity.end, this.tz, 'UTC').format(this.backendDateTimeFormat)
          payload.marks = activity.extendedProps.item.marks.map(item => item.id)
          if (info.newResource && +info.newResource.id === 0) { // если перетаскиваем на фриборд с сотрудника
            payload.shift_id = null
            this.handleActivityDrop(payload, info)
          } else if (!info.newResource) { // если перетаскиваем внутри одного ресурса
            const resource = activity.getResources()[0]
            if (+resource.id === 0) { // если перетаскиваем внутри фриборда
              this.handleActivityDrop(payload, info)
            } else { // если перетаскиваем внутри сотрудника
              const events = resource.getEvents()
              this.filterAllowedEvents(activity, events, payload, info)
            }
          } else { // если перетаскиваем на другого сотрудника
            const events = info.newResource.getEvents()
            this.filterAllowedEvents(activity, events, payload, info)
          }
        }
      }
    },
    filterAllowedEvents (activity, events, payload, info) {
      events = events.filter(event => {
        if (event.display === 'background') {
          // если дьюти попадает внутрь шифта
          if (moment(activity.start).isSameOrAfter(moment(event.start)) && moment(activity.end).isSameOrBefore(moment(event.end))) {
            return true
          }
        }
        return false
      })
      if (events.length === 0) {
        this.toastError(this.$i18n?.t('NO_SHIFTS_TO_ASSIGN'))
        info.revert()
      } else {
        payload.shift_id = +events[0].extendedProps.item.id
        this.handleActivityDrop(payload, info)
      }
    },
    handleActivityDrop (payload, info) {
      this.$store.dispatch('patchShiftActivities', [this.companyId, payload]).then(_ => {
        this.calendarApi.refetchEvents()
      }).catch(error => {
        this.toastError(this.$i18n?.t(error.response.data.message))
        info.revert()
      })
    },
    createResources () {
      return new Promise((resolve, reject) => {
        this.resources = []
        let resourceFreeBoard, userResources
        let resources = []
        /** Фриборд (для всех пользователей) */
        resourceFreeBoard = this.resourceFreeBoard()
        resources = resources.concat(resourceFreeBoard)
        /** Users */
        userResources = this.resourceEmployee()
        resources = resources.concat(userResources)
        this.resources = resources
        this.filteredResources = [...resources]
        // todo apply filters
        resolve(this.filteredResources)
      })
    },
    /** Создания ресурса биржи для всех пользователей */
    resourceFreeBoard () {
      return [{
        id: FREE_BOARD_RESOURCE_ID,
        title: this.$i18n?.t('FREE_SHIFTS_ACTIVITY'),
        control: null,
        type: 3
      }]
    },
    resourceEmployee () {
      let resource, resources, resourcesArray
      this.userSchedule = {}
      resources = {}
      resourcesArray = []
      Object.keys(this.employees).forEach((id, index) => {
        const user = this.employees[id]
        if (this.condition(user)) {
          const position = []
          user.positions.sort((a, b) => {
            if (a.priority > b.priority) {
              return 1
            }
            return -1
          })
          user.positions.forEach(item => {
            position.push(item.title)
          })
          resource = {
            id: user.id,
            title: user.full_name,
            control: null,
            deleted: false,
            type: 7,
            identifier: user.identifier ? user.identifier : '',
            position: position.join(', '),
            positionsId: user.positions.map(position => position.id),
            priority: user.positions.length > 0 ? user.positions[0].priority : 90000000
          }
          resources[user.id] = resource
        }
      })
      Object.keys(resources).forEach(key => {
        resourcesArray.push(resources[key])
      })
      resourcesArray = resourcesArray.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === pos
      })
      resourcesArray.sort(this.sortResources)
      if (this.primarySortOrder === 'identifier') {
        const firstArray = resourcesArray.filter(item => item.identifier !== null &&
            item.identifier !== '' &&
            item.identifier !== 'undefined')
        const secondArray = resourcesArray.filter(item => item.identifier === null ||
            item.identifier === '' ||
            item.identifier === 'undefined')
        resourcesArray = firstArray.concat(secondArray)
      }
      resourcesArray.forEach((res, index) => {
        res.sortOrder = index
      })
      return resourcesArray
    },
    sortResources (a, b) {
      if (this.primarySortOrder === 'name') {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'position') {
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        return 0
      }
      if (this.primarySortOrder === 'identifier') {
        if (a.identifier > b.identifier) {
          return 1
        }
        if (a.identifier < b.identifier) {
          return -1
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        }
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1
        }
        if (+a.priority > +b.priority) {
          return 1
        }
        if (+a.priority < +b.priority) {
          return -1
        }
        return 0
      }
    },
    condition (user) {
      if (this.showMode === 'all') {
        return true
      }
      // todo apply filters
      return true
    },
    getEvents ({ start, end }) {
      return new Promise((resolve, reject) => {
        const dateFrom = this.toUTC(moment(start, this.backendDateFormat)).format(this.backendDateTimeFormat)
        const dateTo = this.toUTC(moment(end, this.backendDateFormat)).add(1, 'days').format(this.backendDateTimeFormat)
        const activityQuery = `?date_from=${dateFrom}&date_to=${dateTo}`
        const shiftsQuery = `start=${dateFrom}&end=${dateTo}`
        const responseActivity = this.$store.dispatch('getShiftActivities', [this.companyId, activityQuery])
        const responseShifts = this.$store.dispatch('getShiftsByCompany', [this.companyId, shiftsQuery])
        Promise.all([responseActivity, responseShifts]).then(resp => {
          this.loaded = true
          const activities = resp[0]
          const shifts = resp[1]
          resolve({
            activities: activities,
            shifts: shifts
          })
        }).catch(_ => {
        })
      })
    },
    setEvents (events) {
      return new Promise((resolve, reject) => {
        const scheduledEvents = []
        const freeboardEvents = []
        this.freeBoard = []
        events.activities.forEach(activity => {
          const activityFormatted = this.setActivity(activity)
          if (activityFormatted.freeBoardKey) {
            if (!freeboardEvents[activityFormatted.freeBoardKey]) {
              freeboardEvents[activityFormatted.freeBoardKey] = []
            }
            freeboardEvents[activityFormatted.freeBoardKey].push(activityFormatted)
          } else {
            scheduledEvents.push(activityFormatted)
          }
        })
        events.shifts.forEach(shift => {
          if (shift.employee_id) {
            scheduledEvents.push(this.setShift(shift))
          }
        })
        this.freeBoard = freeboardEvents
        Object.keys(freeboardEvents).forEach(key => {
          const event = Object.assign({}, freeboardEvents[key][0])
          event.id = key
          event.counter = freeboardEvents[key].length
          scheduledEvents.push(event)
        })
        resolve(scheduledEvents)
      })
    },
    setShift (shift) {
      const startTime = this.fromZoneToZone(shift.time_from, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const endTime = this.fromZoneToZone(shift.time_to, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      // console.log(event)
      return {
        id: shift.id,
        title: '',
        displayEventTime: true,
        start: startTime,
        end: endTime,
        resourceId: shift.employee_id,
        backgroundColor: '#FDF5E6',
        item: shift,
        startEditable: false,
        durationEditable: false,
        display: 'background'
      }
    },
    setActivity (activity) {
      let startTime, endTime
      startTime = this.fromZoneToZone(activity.time_from, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      endTime = this.fromZoneToZone(activity.time_to, 'UTC', this.$store.getters.profileTimeZone).format(this.backendDateTimeFormat)
      const durationHrs = Math.round(moment.duration(moment(endTime).diff(moment(startTime))).asHours() * 100) / 100
      const title = activity.title
      const color = activity.color ? activity.color : '#00accb'
      let status
      // console.log(activity)
      if (activity.logs && activity.logs.length > 0) {
        if (activity.time_spents.length === 1) {
          status = 'finished'
        } else {
          status = 'started'
        }
      }
      if (moment().tz(this.$store.getters.profileTimeZone).isAfter(this.fromZoneToZone(activity.time_from, 'UTC', this.$store.getters.profileTimeZone)) &&
        activity.employee_id > 0 &&
        activity.logs.length === 0 &&
        activity.time_spents.length === 0) {
        status = 'late'
      }
      if (moment().tz(this.$store.getters.profileTimeZone).isAfter(this.fromZoneToZone(activity.time_to, 'UTC', this.$store.getters.profileTimeZone)) &&
        activity.employee_id > 0 &&
        activity.logs.length === 0 &&
        activity.time_spents.length === 0) {
        status = 'absent'
      }
      activity.filteredMarks = activity.marks.map(markId => {
        return this.indexedMarks[markId]
      }).filter(mark => !!mark)
      activity.project = activity.project_id && this.projects[activity.project_id] ? this.projects[activity.project_id].name : null
      activity.schedule = activity.schedule_id && this.schedules[activity.schedule_id] ? this.schedules[activity.schedule_id].name : null
      activity.location = activity.location_id && this.locationsIndexed[activity.location_id] ? this.locationsIndexed[activity.location_id].title : null
      activity.position = activity.position_id && this.positionsIndexed[activity.position_id] ? this.positionsIndexed[activity.position_id].title : null

      const event = {
        id: activity.id,
        title: title,
        displayEventTime: true,
        start: startTime,
        end: endTime,
        realStart: startTime,
        realEnd: endTime,
        resourceId: activity.employee_id,
        color: color,
        item: activity,
        startEditable: true,
        durationEditable: false,
        durationHrs: durationHrs,
        status: status
      }
      const marks = activity.marks.join('__')
      if (activity.employee_id === 0) {
        event.freeBoardKey = activity.title + '__' + activity.schedule_id + '__' + activity.template_id + '__' + startTime + '__' + endTime + '__' + color + '__' + activity.location_id + '__' + activity.position_id + '__marks__' + marks
      }

      return event
    },
    createActivity () {
      this.shiftActivityModalData = {
        id: null,
        title: null,
        template_id: null,
        time_from: '09:00',
        time_to: '17:00',
        date_from: moment().startOf('week'),
        date_to: moment().endOf('week'),
        location_id: null,
        position_id: null,
        project_id: null,
        schedule_id: null,
        marks: [],
        count: 1,
        note: null,
        days: [0, 1, 2, 3, 4, 5, 6],
        dates: [],
        type: 'period',
        color: '#4e93e9',
        time_zone: this.tz
      }
      this.showShiftActivityModal = true
    },
    massUpdateActivity () {
      this.showMassActionActivityModal = true
    },
    distributeActivity () {
      this.showDistributeActivityModal = true
    },
    updateActivity (activity) {
      this.shiftActivityModalData = activity.extendedProps.item
      this.showShiftActivityModal = true
    },
    handleDrawer () {
      this.drawer = !this.drawer
      this.$nextTick(() => {
        this.calendarApi.updateSize()
      })
    },
    closeShiftActivityModal () {
      this.showShiftActivityModal = false
      this.showMassActionActivityModal = false
      this.showDistributeActivityModal = false
    },
    updateEvents () {
      this.calendarApi.refetchEvents()
    },
    goToDate (value) {
      // console.log(value, this.view)
      if (this.calendarApi && !moment(value).isSame(moment(this.view.currentStart))) this.calendarApi.gotoDate(value)
    },
    applyFilter (filter) {
      this.filters = filter
      this.allowedUsers = []
      let allowedProjectUsers = []
      let allowedScheduleUsers = []
      let intersection = []
      if (this.filters.projects.length > 0) {
        this.filters.projects.forEach(projectId => {
          const project = this.projects[projectId]
          if (project.users) {
            const users = [...new Set(project.users.concat(project.managers))]
            allowedProjectUsers = [...new Set(allowedProjectUsers.concat(users))]
          } else {
            Object.keys(this.schedules).forEach(id => {
              if (this.schedules[id].project_id === projectId) {
                const ids = this.schedules[id].users.map(item => item.employee_id)
                allowedProjectUsers = [...new Set(allowedProjectUsers.concat(ids))]
              }
            })
          }
        })
      }
      if (this.filters.schedules.length > 0) {
        this.filters.schedules.forEach(scheduleId => {
          const schedule = this.schedules[scheduleId]
          const users = schedule.users.map(user => user.employee_id)
          allowedScheduleUsers = [...new Set(allowedScheduleUsers.concat(users))]
        })
      }
      if (this.filters.schedules.length === 0 || this.filters.projects.length === 0) {
        intersection = [...new Set(allowedProjectUsers.concat(allowedScheduleUsers))]
      } else {
        intersection = ((allowedProjectUsers, allowedScheduleUsers) => {
          const setAllowedProjectUsers = new Set(allowedProjectUsers)
          return allowedScheduleUsers.filter(value => setAllowedProjectUsers.has(value))
        })(allowedProjectUsers, allowedScheduleUsers)
      }
      this.allowedUsers = intersection
      this.calendarApi.refetchResources()
      this.calendarApi.refetchEvents()
      this.calendarApi.updateSize()
    },
    applyResourceFilter (resources) {
      let allowedResources = [0]
      if (this.showMode === 'shifts') {
        this.events.forEach(event => {
          allowedResources.push(event.resourceId)
        })
        allowedResources = [...new Set(allowedResources)]
      }
      return resources.filter(resource => {
        if (+resource.id === 0) {
          return true
        }
        if (this.filters.employees.length > 0 && !this.filters.employees.includes(resource.id)) {
          return false
        }
        if ((this.filters.projects.length > 0 || this.filters.schedules.length > 0) && !this.allowedUsers.includes(resource.id)) {
          return false
        }
        if (this.filters.positions.length > 0) {
          let response = false
          this.filters.positions.forEach(positionId => {
            if (resource.positionsId.includes(positionId)) {
              response = true
            }
          })
          return response
        }
        if (this.showMode === 'shifts') {
          return allowedResources.includes(resource.id)
        }
        return true
      })
    },
    applyActivityFilter (activities) {
      return activities.filter(item => {
        if (item.display === 'background') {
          return true
        }
        if (this.filters.projects.length > 0 && !this.filters.projects.includes(item.item.project_id)) {
          return false
        }
        if (this.filters.schedules.length > 0 && !this.filters.schedules.includes(item.item.schedule_id)) {
          return false
        }
        if (this.filters.locations.length > 0 && !this.filters.locations.includes(item.item.location_id)) {
          return false
        }
        if (this.filters.marks.length > 0) {
          let resp = false
          item.item.marks.forEach(mark => {
            if (this.filters.marks.includes(mark.id)) {
              resp = true
            }
          })
          return resp
        }
        if (this.filters.statuses.length > 0) {
          return this.filters.statuses.includes(item.status)
        }
        return true
      })
    },
    changeShowMode () {
      this.calendarApi.refetchResources(false)
    }
  }
}
</script>
<style>
</style>
