<template>
  <div>
    <h4 class="mb-2">{{ $t('Select preferable features') }}</h4>
    <p class="mb-4">{{ $t('MODULES_STEP_DESCRIPTION') }}</p>
    <div class="container">
      <div v-for="(module, key) in modules" :key="module.name" class="row">
        <div class="col-4 my-1">
          <el-checkbox-group
            v-model="selectedModules"
            size="default"
            class="modules-title"
            >
            <div :class="{'border-primary': selectedModules.includes(module.name) }" class="pt-1 px-1 border">
              <el-checkbox
                :name="key"
                :value="module.name"
                :custom-class="'w-100'"
                :class="{'is-checked': isFree(module)}"
                :disabled="isFree(module)"
                :checked="isFree(module)"
              >
                <i :class="module.icon" class="mr-1 modules-icon"/>
                <span>{{ $t(module.title) }}</span>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div class="col my-1">
          <span>
            {{ $t(module.description) }}
            <a v-if="isFree(module)" :href="`https://shifton.com/${locale}/free-plan`" target="_blank">&nbsp;{{$t('Read more')}}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModulesStep',
  props: {
    refreshSelectedModules: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      modules: {
        basic: {
          title: 'BASIC_SCHEDULING',
          ids: [],
          name: 'basic',
          icon: 'icon-calendar',
          description: 'BASIC_SCHEDULING_WIZARD_DESCRIPTION'
        },
        advanced: {
          title: 'ADVANCED_SCHEDULING',
          ids: [5, 14],
          name: 'advanced',
          icon: 'icon-calendar',
          description: 'SCHEDULING_WIZARD_DESCRIPTION'
        },
        attendance: {
          title: 'Attendance',
          ids: [24],
          name: 'attendance',
          icon: 'icon-clock',
          description: 'ATTENDANCE_WIZARD_DESCRIPTION'
        },
        location: {
          title: 'Work location control',
          ids: [24, 25],
          name: 'location',
          icon: 'icon-map-pin',
          description: 'WORK_LOCATION_CONTROL_WIZARD_DESCRIPTION'
        },
        tasks: {
          title: 'Tasks',
          ids: [20],
          name: 'tasks',
          icon: 'icon-clipboard',
          description: 'TASK_WIZARD_DESCRIPTION'
        }
      },
      selectedModules: []
    }
  },
  watch: {
    selectedModules () {
      const modules = []
      this.selectedModules.forEach(moduleKey => {
        const module = this.modules[moduleKey]
        modules.push(module.ids)
      })
      this.refreshSelectedModules([...new Set(modules.flat())])
    }
  },
  computed: {
    ...mapGetters([
      'locale'
    ])
  },
  methods: {
    isFree (module) {
      return module.title === 'BASIC_SCHEDULING'
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-checkbox),
:deep(.el-checkbox) .el-checkbox__label {
  display: flex;
  align-items: center;
}

.modules-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.modules-icon {
  font-size: 20px;
}

.modules-description {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.modules-description-item {
  height: 36px;
  display: flex;
  align-items: center;
}
</style>
