import { HTTP } from '@/api/http_utils'

const UPDATE_LOAD_STATISTIC = 'UPDATE_LOAD_STATISTIC'

const state = {
  loadStatistic: []
}

const promises = {}

const getters = {
  loadStatistic: state => state.loadStatistic
}

const mutations = {
  [UPDATE_LOAD_STATISTIC] (state, data) {
    state.loadStatistic = data
  }
}

const actions = {
  uploadLoadStatistic ({ commit }, [scheduleId, payload]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand/upload`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }
    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload, config).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  previewDemandFile ({ commit }, [companyId, payload]) {
    const promiseKey = `/companies/${companyId}/demand/preview`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' }, data: payload }

    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload, config).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getTemplatesByDemand ({ commit }, [companyId, payload]) {
    const promiseKey = `/companies/${companyId}/demand/get_templates`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }

    let promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  updateLoadDemand ({ commit }, [scheduleId, id, payload]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand/${id}`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.patch(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  createLoadDemand ({ commit }, [scheduleId, payload]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  getLoadStatistic ({ commit }, [scheduleId, query]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand?${query}`

    if (promises[promiseKey]) {
      console.error('repeating requests loads')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(promiseKey).then(response => {
        commit(UPDATE_LOAD_STATISTIC, response)
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  deleteLoadStatistic ({ commit }, [scheduleId, id]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand/${id}`

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.delete(promiseKey).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  batchDeleteLoadDemand ({ commit }, [scheduleId, payload]) {
    const promiseKey = `/schedule/${scheduleId}/load-demand/delete`

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const promise = new Promise((resolve, reject) => {
      HTTP.post(promiseKey, payload).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
