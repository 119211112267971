
<template>
  <div>
    <div class="row">
      <div class="col">
        <div><i class="icon-check-square text-warning font-weight-bold mr-1"></i>
          <span>+{{phoneNumber}}</span></div>
        <label for="confirm-code">{{ $t("Confirmation code") }}</label>
        <div class="d-flex align-items-center">
          <Form ref="codeObserve" as="div">
            <ui-text-input
              id="confirm-code"
              name="confirm"
              :validation="{required: true}"
              v-model="code"
            />
          </Form>
          <div>
            <button
              v-if="confirmed === false"
              :class="{'grey': isCounting}"
              :disabled="isCounting"
              style="border: none; background: none;"
              @click="phoneConfirmation(phoneNumber, 'resend')"
            >
              <i
                class="icon-repeat btn-icon text-warning mx-1"
                :class="{'not-allowed': isCounting}"
              >
              </i>
            </button>
            <a @click="confirmPhone">
              <i class="icon-check btn-icon text-primary mx-1"></i>
            </a>
            <a @click="confirmationFinish">
              <i class="icon-x btn-icon text-danger mx-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isCounting">
      <span>{{$t('Resend confirmation code after')}} </span>
      <span>{{countDown}}</span>
      <span> {{$t('sec')}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PhoneConfirmationCode',
  data () {
    return {
      code: ''
    }
  },
  props: {
    phoneNumber: String,
    confirmed: Boolean,
    isCounting: Boolean,
    countDown: Number
  },
  methods: {
    phoneConfirmation (phoneNumber, action) {
      this.$emit('phoneConfirmation', {
        phoneNumber: phoneNumber,
        action: action
      })
    },
    confirmPhone () {
      this.$refs.codeObserve.validate().then(result => {
        if (result.valid) {
          this.$emit('confirmPhone', {
            result: true,
            code: this.code
          })
        } else {
          this.$emit('confirmPhone', {
            result: false,
            code: this.code
          })
        }
      })
    },
    confirmationFinish () {
      this.$emit('confirmationFinish')
    }
  }
}
</script>
<style scoped>

</style>
