<template>
  <div v-if="userRequest">
    <el-collapse>
      <el-collapse-item name="1">
        <template v-slot:title>
          <ui-input-label :label="$t('DISPLAY_VACATIONS_GRAPH_TITLE')" />
        </template>
        <div v-if="dummy" class="dummy-container">
          <dummy-payment-required
            v-if="dummy"
            @activate="activate()"
            :dummyInfo="dummyInfo">
          </dummy-payment-required>
        </div>
        <div v-if="!dummy">
          <div class="row px-2">
            <div class="col">
              <ui-group-select
                name="display"
                v-model="display"
                :clearable="false"
                :placeholder="$t('Select')"
                :label="$t('DISPLAY_VACATIONS_GRAPH_SELECT')"
                :hint="$t('DISPLAY_VACATIONS_GRAPH_HINT')"
                :options="options"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
            <div class="col px-1">
              <ui-multi-select
                name="allowedTypes"
                class="my-1"
                :label="$t('TIME_OFF_TYPES')"
                :hint="$t('TIME_OFF_TYPES_HINT')"
                v-model="allowedTypes"
                :options="timeOffOptions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :clearable="true"
              />
            </div>
            <div class="col px-1">
              <ui-multi-select
                name="positions"
                @change="onPositionsChange"
                class="my-1"
                :label="$t('POSITIONS')"
                :hint="$t('POSITIONS_HINT')"
                v-model="positions"
                :options="positionsOptions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :clearable="true"
              />
            </div>
            <div class="col px-1">
              <ui-multi-select
                name="marks"
                @change="onMarksChange"
                class="my-1"
                :disabled="marksOptions.length === 0"
                :label="$t('MARKS')"
                :hint="$t('MARKS_GRAPH_HINT')"
                v-model="marks"
                :options="marksOptions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :clearable="true"
              />
            </div>
            <div class="col px-1">
              <ui-multi-select
                name="skills"
                @change="onSkillsChange"
                class="my-1"
                :disabled="skillsOptions.length === 0"
                :label="$t('SKILLS')"
                :hint="$t('SKILLS_GRAPH_HINT')"
                v-model="skills"
                :options="skillsOptions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :clearable="true"
              />
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-12 graph-container">
              <canvas ref="myChart" class="canvas-style" aria-label="Chart" role="img" />
            </div>
            <div class="col-12">
              <ui-input-label :label="$t('CURRENT_EMPLOYEE_TIME_OFFS')" :hint="$t('CURRENT_EMPLOYEE_TIME_OFFS_HINT')" />
              <el-table
                :data="employeeRequests"
                stripe
              >
                <el-table-column
                  prop="date_from"
                  :label="$t('From')"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="date_to"
                  :label="$t('To')"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="duration_time"
                  :label="$t('DURATION_DAYS')"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="type_time_off"
                  :label="$t('TYPE_TIME_OFF')"
                  :filters="[
                    { text: $t('Sick day'), value: $t('sick') },
                    { text: $t('vacation'), value: $t('vacation') },
                    { text: $t('holiday'), value: $t('holiday') }
                    ]"
                  :filter-method="filterTag"
                  filter-placement="bottom-end"
                >
                  <template v-slot="scope">
                    <el-tag
                      :type="scope.row.type_time_off === $t('sick') ? 'danger' : scope.row.type_time_off === $t('vacation') ? 'success' : 'info'"
                      disable-transitions>
                      <span class="font-weight-bold">{{scope.row.type_time_off}}</span>
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import requestMixin from '@/components/Requests/RequestComponents/mixinRequest'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired.vue'
import mixinDummy from '@/mixins/mixinDummy'
import { Chart, registerables } from 'chart.js'
import moment from 'moment'
import mixinColors from '@/mixins/mixinColors'
Chart.register(...registerables)

export default {
  name: 'GraphVacation',
  mixins: [momentMixin, requestMixin, mixinDummy, mixinColors],
  components: { DummyPaymentRequired },
  data () {
    return {
      dummy: false,
      dummyInfo: {
        title: '', trial: '', price: '', perUser: '', id: '', description: '', isActive: true, helpCenter: null
      },
      myChart: null,
      employeeRequests: [],
      options: [
        {
          label: this.$t('VACATIONS_BY_COMPANY_LABEL'),
          options: [{
            id: 'all',
            name: this.$t('ALL_BY_COMPANY')
          }]
        }
      ],
      display: 'all',
      dataset: [],
      filteredDataset: [],
      allowedTypes: [],
      timeOffOptions: [],
      labels: [],
      positions: [],
      positionsOptions: [],
      positionTimeOut: null,
      projectIdForMArks: null,
      schedules: [],
      marks: [],
      marksOptions: [],
      marksTimeOut: null,
      skills: [],
      skillsOptions: [],
      skillsTimeOut: null
    }
  },
  props: {
    userRequest: Object
  },
  created () {
    this.$store.dispatch('getPositions', this.companyId).then((positions) => {
      const opt = []
      positions.forEach(position => {
        opt.push({
          id: 'position_' + position.id,
          name: position.title
        })
      })
      this.positionsOptions = opt
    })
    this.$store.dispatch('getSkills', this.companyId).then((skills) => {
      const opt = []
      skills.forEach(skill => {
        opt.push({
          id: 'skill_' + skill.id,
          name: skill.title
        })
      })
      this.skillsOptions = opt
    })
    this.$store.dispatch('getProjectsByCompany', this.$store.getters.companyId).then(() => {
      const opt = []
      this.projects.forEach(project => {
        opt.push({
          id: 'project_' + project.id,
          name: project.name
        })
      })
      if (this.$t) {
        this.options.push({
          label: this.$t('VACATIONS_BY_PROJECTS'),
          options: opt
        })
      }
    })
    this.$store.dispatch('getCompanySchedules', false).then((schedules) => {
      this.schedules = schedules
      const opt = []
      schedules.forEach(schedule => {
        opt.push({
          id: 'schedule_' + schedule.id,
          name: schedule.name
        })
      })
      if (this.$t) {
        this.options.push({
          label: this.$t('VACATIONS_BY_SCHEDULES'),
          options: opt
        })
      }
    })
    this.init()
  },
  computed: {
    rolesByProjects () {
      return this.$store.getters.rolesByProjects || {}
    },
    direction () {
      return this.$store.getters.locale === 'he' ? 'rtl' : 'ltr'
    },
    projects () {
      return this.$store.getters.projects
    },
    locale () {
      return this.$store.getters.locale
    },
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  watch: {
    projectIdForMArks () {
      if (this.projectIdForMArks) {
        this.$store.dispatch('getMarksByProject', [this.projectIdForMArks, true]).then(marks => {
          const opt = []
          marks.forEach(mark => {
            opt.push({
              id: 'mark_' + mark.id,
              name: mark.name
            })
          })
          this.marksOptions = opt
        })
      }
    },
    filteredDataset () {
      this.$nextTick(() => {
        if (this.filteredDataset) {
          if (this.myChart) {
            this.myChart.destroy()
          }
          this.handleChart()
        }
      })
    },
    allowedTypes (val) {
      if (val.length > 0) {
        this.filteredDataset = this.dataset.filter(item => this.allowedTypes.includes(item.time_off))
      } else {
        this.filteredDataset = this.dataset
      }
    },
    display () {
      this.onDisplayChange()
    }
  },
  methods: {
    filterTag (value, row) {
      return row.type_time_off === value
    },
    init () {
      let id
      let type
      const positions = []
      const marks = []
      const skills = []
      if (this.display !== 'all') {
        [type, id] = this.display.split('_')
      }
      if (this.positions.length > 0) {
        this.positions.forEach(position => {
          positions.push(position.split('_')[1])
        })
      }
      if (this.marks.length > 0) {
        this.marks.forEach(mark => {
          marks.push(mark.split('_')[1])
        })
      }
      if (this.skills.length > 0) {
        this.skills.forEach(skill => {
          skills.push(skill.split('_')[1])
        })
      }
      this.$store.dispatch('getVacationsEmployeesStatistic', [
        this.companyId,
        this.userRequest.date_from,
        this.userRequest.date_to,
        id,
        type + '_id',
        this.userRequest.created_by,
        positions,
        marks,
        skills
      ]).then((response) => {
        this.dataset = []
        this.timeOffOptions = []
        let keys = []
        // создаем заготовку матрицы [дата => количество отгулов], куда потом будут добавляться данные по количеству таймоффов,
        // с разбивкой по датам
        const data = {}
        const from = moment(this.userRequest.date_from, this.backendDateTimeFormat)
        const to = moment(this.userRequest.date_to, this.backendDateTimeFormat)
        do {
          data[from.format(this.backendDateTimeFormat)] = 0
          from.add(1, 'day')
        } while (from.isBefore(to))
        // <- матрица создана
        // весь дальнейший вздрочь происходит из-за того, что есть кастомные типы таймоффов
        // поэтому нельзя вручную разобрать данные по ключам - отпуски, праздники, больничные
        // приходится все перебирать циклами
        let labels = []
        Object.keys(response.statistic).forEach((key, index) => {
          // собираем список типов таймоффов
          keys.push(key)
          // клонируем заготовку для каждого типа таймоффа и заполняем данными
          const cloned = JSON.parse(JSON.stringify(data))
          Object.keys(response.statistic[key]).forEach(date => {
            labels.push(date)
            cloned[date] = response.statistic[key][date]
          })
          const color = this.colorsChoices[index] ? this.colorsChoices[index] : this.colorsChoices[0]
          this.dataset.push({
            time_off: key,
            label: this.$t(key).charAt(0).toUpperCase() + this.$t(key).slice(1),
            data: Object.values(cloned),
            backgroundColor: [
              color
            ],
            borderColor: [
              color
            ],
            borderWidth: 1
          })
        })
        labels = [...new Set(labels)]
        this.labels = labels
        // список с типами таймоффов фильтруем на уникальность и создаем опции для селекта в фильтре по типам таймоффов
        keys = [...new Set(keys)]
        keys.forEach(key => {
          this.timeOffOptions.push({
            id: key,
            name: this.$t(key).charAt(0).toUpperCase() + this.$t(key).slice(1)
          })
        })
        this.timeOffOptions.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        })
        // применяем фильтр по типам отгулов
        if (this.allowedTypes.length === 0) {
          this.filteredDataset = this.dataset
        } else {
          this.filteredDataset = this.dataset.filter(item => this.allowedTypes.includes(item.time_off))
        }
        // подготавливаем данные для таблицы, которая находится под графиком
        this.employeeRequests = response.employee_requests
        this.employeeRequests.forEach(item => {
          item.date_from = moment(item.date_from).format(this.localeDateTimeFormat)
          item.date_to = moment(item.date_to).format(this.localeDateTimeFormat)
          item.type_time_off = this.$t(item.type_time_off)
          item.duration_time = item.duration_time ? Math.round(item.duration_time / (60 * 24) * 100) / 100 : item.duration_time
        })
      }).catch(error => {
        if (error.response?.data.module) {
          this.dummy = true
          this.dummyInfo = this.setupDummyData(error.response.data)
        }
      })
    },
    activate () {
      this.dummy = false
      this.init()
    },
    handleChart () {
      this.myChart = new Chart(this.$refs.myChart, {
        type: 'bar',
        data: {
          labels: this.labels.map(item => {
            const date = this.fromZoneToZone(item, 'UTC', this.tz).format(this.localeDateTimeFormatShort).split(' ')
            if (date[1] === '00:00') {
              return date[0]
            } else {
              return date.join(' ')
            }
          }),
          datasets: this.filteredDataset
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                display: true,
                callback: function (value, index, ticks) {
                  return Number.isInteger(value) ? value : null
                }
              },
              stacked: true
            },
            x: {
              reverse: this.direction === 'rtl',
              ticks: {
                display: true
              },
              stacked: true
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              reverse: this.direction === 'rtl',
              labels: {}
            },
            tooltip: {
              enabled: true,
              intersect: false,
              textDirection: this.direction,
              rtl: this.direction === 'rtl',
              callbacks: {
                title: (context) => {
                  return context[0].label
                }
              }
            }
          },
          layout: {
            padding: 0
          }
        }
      })
    },
    onDisplayChange () {
      if (this.display === 'all') {
        this.projectIdForMArks = null
        this.marks = []
        this.marksOptions = []
      }
      const [key, id] = this.display.split('_')
      if (key === 'project') {
        this.projectIdForMArks = +id
      }
      if (key === 'schedule') {
        this.projectIdForMArks = this.schedules.filter(schedule => schedule.id === +id)[0].project_id
      }
      this.init()
    },
    onPositionsChange () {
      clearTimeout(this.positionTimeOut)
      this.positionTimeOut = setTimeout(() => {
        this.init()
      }, 1000)
    },
    onMarksChange () {
      clearTimeout(this.marksTimeOut)
      this.marksTimeOut = setTimeout(() => {
        this.init()
      }, 1000)
    },
    onSkillsChange () {
      clearTimeout(this.skillsTimeOut)
      this.skillsTimeOut = setTimeout(() => {
        this.init()
      }, 1000)
    }
  }
}
</script>

<style scoped>
.dummy-container {
  max-width: 800px;
}
.graph-container {
  height: 100%;
  max-height: 600px;
}
</style>
