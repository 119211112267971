<template>
  <div>
    <h1 class="title-text">{{ $t('COMPANY_MODULES') }}</h1>
    <div class="row pb-3">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 px-1">
            <div class="card card-small">
              <div class="card-body">
                <div
                  class="nav flex-column nav-pills d-md-block"
                  id="tabs-vertical"
                  role="tablist"
                  aria-orientation="vertical"
                  :class="{'d-none': !showTabs}"
                  >
                  <el-divider>{{$t('MODULES_LIST')}}</el-divider>
                  <a
                    :href="'#' +  mainTab.slug"
                    class="nav-link pl-3 pr-1 rounded"
                    :class="{ active: activeTab === mainTab.slug, 'hide': !isEmployeeHasPermission('delete-company') && mainTab.slug === 'general' }"
                    data-toggle="pill" role="tab"
                    :aria-controls="'v-pills-' + mainTab.slug" aria-selected="true"
                    @click="activateTab(mainTab.slug, mainTab.title)"
                  >
                    <span
                      class="module-status"
                      :class="{ 'module-status-none': mainTab.slug === 'general', 'module-status-active': mainTab.isActive }"
                    ></span>
                    {{ $t(mainTab.title) }}
                  </a>
                  <el-divider>{{$t('MODULES_SETTINGS')}}</el-divider>
                  <a
                    v-for="item in tabsData" :key="item.slug"
                    :href="'#' +  item.slug"
                    class="nav-link pl-3 pr-1 rounded"
                    :class="{ active: activeTab === item.slug, 'hide': !isEmployeeHasPermission('delete-company') && item.slug === 'general' }"
                    data-toggle="pill" role="tab"
                    :aria-controls="'v-pills-' + item.slug" aria-selected="true"
                    @click="activateTab(item.slug, item.title)"
                  >
                    <span
                      class="module-status"
                      :class="{ 'module-status-none': item.slug === 'general', 'module-status-active': item.isActive }"
                    ></span>
                    {{ $t(item.title) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="card card-small">
              <div class="card-body">
                <div class="tab-content">
                  <template v-if="modulesByCompany.length > 0">
                    <ModulesList
                      v-if="activeTab === 'general' && loaded === true && isEmployeeHasPermission('delete-company')"
                    />
                    <SettingsBasic
                      v-if="activeTab === 'settings-basic' && loaded === true"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsBreaks
                      v-if="activeTab === 'settings-breaks' && loaded === true"
                      @breaksActivated="setActiveBreaks"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsWorkLocationControl
                      v-if="activeTab === 'settings-work-location-control' && loaded === true"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsAttendance
                      v-if="activeTab === 'settings-attendance' && loaded === true"
                      :breaksIsActive="breaksIsActive"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsTasks
                      v-if="activeTab === 'settings-tasks' && loaded === true"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsTimeOff
                      v-if="activeTab === 'settings-time-off' && loaded === true"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsVacations
                      v-if="activeTab === 'settings-vacations' && loaded === true"
                      @success="displaySuccessMessage"
                      @error="displayErrorMessage"
                    />

                    <SettingsEmergencyShifts
                        v-if="activeTab === 'settings-emergency-shifts' && loaded === true"
                        @success="displaySuccessMessage"
                        @error="displayErrorMessage"
                    />

                    <SettingsActivity
                        v-if="activeTab === 'settings-activity' && loaded === true"
                        @success="displaySuccessMessage"
                        @error="displayErrorMessage"
                    />

                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModulesList from '@/components/Company/ModulesPage/ModulesList'
import SettingsBasic from '@/components/Company/ModulesPage/SettingsBasic'
import SettingsBreaks from '@/components/Company/ModulesPage/SettingsBreaks'
import SettingsActivity from '@/components/Company/ModulesPage/SettingsActivity'
import SettingsWorkLocationControl from '@/components/Company/ModulesPage/SettingsWorkLocationControl'
import SettingsAttendance from '@/components/Company/ModulesPage/SettingsAttendance'
import SettingsTasks from '@/components/Company/ModulesPage/SettingsTasks'
import SettingsTimeOff from '@/components/Company/ModulesPage/SettingsTimeOff'
import SettingsVacations from '@/components/Company/ModulesPage/SettingsVacations.vue'
import SettingsEmergencyShifts from '@/components/Company/ModulesPage/SettingsEmergencyShifts.vue'

export default {
  name: 'ModulesPage',
  components: {
    SettingsEmergencyShifts,
    SettingsVacations,
    ModulesList,
    SettingsActivity,
    SettingsBasic,
    SettingsBreaks,
    SettingsWorkLocationControl,
    SettingsAttendance,
    SettingsTasks,
    SettingsTimeOff
  },
  data () {
    return {
      disabled: false,
      activeTab: this.isEmployeeHasPermission('delete-company') ? 'general' : 'settings-basic',
      showTabs: true,
      loaded: false,
      buttonTitle: 'Please Select',
      companyModulesIds: [],
      slugsList: [
        'general',
        'settings-basic',
        'settings-breaks',
        'settings-work-location-control',
        'settings-attendance',
        'settings-tasks',
        'settings-time-off',
        'settings-vacations',
        'settings-emergency-shifts',
        'settings-activity'
      ],
      mainTab: {
        slug: 'general',
        title: 'Modules'
      },
      tabsData: [],
      breaksIsActive: false
    }
  },
  created () {
    if (this.workMode === 'work') {
      this.tabsData = [
        {
          slug: 'settings-basic',
          title: 'SETTINGS_BASIC',
          isActive: this.checkModuleActivation(8),
          moduleId: 8
        },
        {
          slug: 'settings-breaks',
          title: 'SETTINGS_BREAKS',
          isActive: this.checkModuleActivation(23),
          moduleId: 23
        },
        {
          slug: 'settings-work-location-control',
          title: 'SETTINGS_TRACKING',
          isActive: this.checkModuleActivation(25),
          moduleId: 25
        },
        {
          slug: 'settings-attendance',
          title: 'SETTINGS_ATTENDANCE',
          isActive: this.checkModuleActivation(24),
          moduleId: 24
        },

        {
          slug: 'settings-time-off',
          title: 'SETTINGS_TIME_OFF',
          isActive: this.checkModuleActivation(5),
          moduleId: 5
        },
        {
          slug: 'settings-vacations',
          title: 'SETTINGS_VACATIONS',
          isActive: this.checkModuleActivation(26),
          moduleId: 26
        },
        {
          slug: 'settings-emergency-shifts',
          title: 'SETTINGS_EMERGENCY_SHIFTS',
          isActive: this.checkModuleActivation(27),
          moduleId: 27
        },
        {
          slug: 'settings-activity',
          title: 'SETTINGS_ACTIVITY',
          isActive: this.checkModuleActivation(28),
          moduleId: 28
        }
      ]
    } else {
      this.tabsData = [
        {
          slug: 'settings-basic',
          title: 'SETTINGS_BASIC',
          isActive: this.checkModuleActivation(8),
          moduleId: 8
        },
        {
          slug: 'settings-tasks',
          title: 'SETTINGS_TASKS',
          isActive: this.checkModuleActivation(20),
          moduleId: 20
        }
      ]
    }
    if (this.companyId) {
      this.$store.dispatch('getModulesByCompany')
    }
    this.init()
  },
  mounted () {
    if (this.slugsList.includes(this.$route.hash.slice(1))) {
      this.activateTab(this.$route.hash.slice(1))
    }
    if (this.accessDenied) {
      this.accessErrorRedirect('Only company owner can access this page')
    }
  },
  watch: {
    companyModules () {
      this.companyModulesIds = this.companyModules
      this.tabsData = this.tabsData.map(item => {
        return { ...item, isActive: this.checkModuleActivation(item?.moduleId) }
      })
    },
    accessDenied (value) {
      if (value) {
        this.accessErrorRedirect('Only company owner can access this page')
      }
    },
    companyId (value) {
      if (value) {
        this.$store.dispatch('getModulesByCompany')
      }
    }
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    },
    accessDenied () {
      return this.$store.getters.employeePermissions && !this.isEmployeeHasPermission('get-company-admin')
    },
    companyModules () {
      return this.$store.getters.companyModulesIds
    },
    modulesByCompany () {
      return this.$store.getters.ModulesByCompany
    }
  },
  methods: {
    init () {
      if (this.$route.params.id) {
        this.getCompany(this.$route.params.id)
      }
    },
    activateTab (slug, title) {
      this.activeTab = slug
      this.buttonTitle = title
      window.location.hash = slug
      this.showTabs = false
    },
    async getCompany (companyId) {
      await this.$store.dispatch('getCompany', companyId)
      this.loaded = true
    },
    displaySuccessMessage () {
      this.toastSuccess(this.$i18n?.t('Settings successfully saved'))
    },
    displayErrorMessage () {
      this.toastError(this.$i18n?.t('SETTINGS_UPDATE_ERROR'))
    },
    setActiveBreaks () {
      this.breaksIsActive = true
    },
    checkModuleActivation (moduleId) {
      this.companyModulesIds = this.$store.getters.companyModulesIds

      if (this.companyModulesIds.includes(moduleId)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.module-status {
  display: inline-flex;
  width: 10px;
  height: 10px;
  background-color: grey;
  margin-right: 10px;
  border-radius: 50%;
  margin-left: -10px
}
.module-status-none {
  background-color: transparent;
}
.module-status-active {
  background-color: #13a45d;
}
.hide {
  display: none;
}
</style>
