<template>
  <div>
    <div class="my-2 text-primary text-uppercase font-weight-bold">{{ $t('SUB_EMPLOYEE_ATTACH_TITLE') }}</div>
    <div v-for="(item, index) in selected" :key="'sbtsk__' + index" class="my-3">
      <SubtaskItemCreateComponent
        :scheduleId="scheduleId"
        :subtaskIndex="index"
        :tz="tz"
        :initItem="item"
        :parentShift="parentShift"
        :clientId="clientId"
        @childrenUpdated="childrenUpdated"
        @childrenDeleted="childrenDeleted"
      />
    </div>
    <div>
      <el-button @click="addSubItem" type="primary" icon="Plus" />
    </div>
  </div>
</template>

<script>
import SubtaskItemCreateComponent from '@/components/Tasks/Task/Components/SubtaskItemCreateComponent'
import momentMixin from '@/mixins/mixinMoment'
import moment from 'moment'
export default {
  name: 'SubtaskCreateComponent',
  mixins: [momentMixin],
  components: { SubtaskItemCreateComponent },
  data () {
    return {
      selected: []
    }
  },
  props: { scheduleId: Number, tz: String, clientId: Number, leadTime: Number, scheduledTime: String, parentShift: Number },
  created () {
  },
  watch: {
    leadTime () {
      this.selected.forEach(item => {
        item.lead_time = this.leadTime
      })
    }
  },
  methods: {
    childrenUpdated (val, index) {
      this.selected[index] = { ...val }
      if (this.parentShift && this.selected[index].scheduled_time) {
        this.selected[index].scheduled_time = moment(this.selected[index].scheduled_time).format(this.backendDateTimeFormat)
      }
      this.$store.dispatch('setTemporarySubtasks', this.selected)
    },
    childrenDeleted (index) {
      this.selected.splice(index, 1)
      this.$store.dispatch('setTemporarySubtasks', this.selected)
    },
    addSubItem () {
      this.selected.push({
        scheduled_time: this.scheduledTime,
        lead_time: this.leadTime,
        shift_id: null
      })
    }
  }
}
</script>

<style scoped>

</style>
