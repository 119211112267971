<template>
  <div>
    <note-form-component
      @noteCreated="addNote"
      :employeeId="employeeId" />
    <template v-if="notes.length > 0">
      <div class="control-label font-weight-bold mb-2 mt-4">
        {{ $t('EMPLOYEE_NOTES_LIST') }}
      </div>
      <div class="row" :class="isModal ? 'overflow-auto' : ''" :style="isModal ? { 'max-height': '300px' } : null">
        <div class="col-12">
          <table class="table table-hover table-striped">
            <thead>
            <tr>
              <th scope="col" class="text-left">
                {{ $t('From') }}
              </th>
              <th scope="col" class="text-left">
                {{ $t('To') }}
              </th>
              <th scope="col" class="text-left">
                {{ $t('SHOW_TO') }}
              </th>
              <th scope="col" class="text-left">
                {{ $t('Content') }}
              </th>
              <th scope="col" class="text-left"></th>
            </tr>
            </thead>
            <tbody>
            <template  v-for="note in notes" :key="note.id">
              <NoteTableRow
                :note="note"
                @deleted="deleteNote"
                @edited="noteEdited"
              />
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import momentMixin from '@/mixins/mixinMoment'
import NoteFormComponent from '@/components/CommonComponents/NoteFormComponent'
import moment from 'moment'
import NoteTableRow from '@/components/User/user/UserComponents/NoteTableRow.vue'

export default {
  name: 'Notes',
  components: { NoteTableRow, NoteFormComponent },
  mixins: [momentMixin],
  data () {
    return {
      notes: []
    }
  },
  props: { employeeId: Number, projectId: Number, isModal: Boolean },
  created () {
    this.init()
  },
  computed: {
    tz () {
      return this.$store.getters.profileTimeZone
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getNotesByEmployee', [this.companyId, this.employeeId]).then(response => {
        response.forEach(note => {
          note.start = this.fromZoneToZone(note.start, 'UTC', this.tz).format(this.localeDateTimeFormat)
          note.end = this.fromZoneToZone(note.end, 'UTC', this.tz).format(this.localeDateTimeFormat)
        })
        this.notes = response
      })
    },
    addNote (note) {
      this.notes.push(note)
      this.notes.sort((a, b) => {
        if (moment(a.start, this.localeDateTimeFormat).isAfter(moment(b.start, this.localeDateTimeFormat))) return 1
        return -1
      })
    },
    deleteNote (id) {
      this.notes = this.notes.filter(note => +note.id !== +id)
    },
    noteEdited (note) {
      if (note) {
        this.notes.forEach(item => {
          if (item.id === note.id) {
            item.start = note.start
            item.end = note.end
            item.content = note.content
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
