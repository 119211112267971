<template>
  <Field
    :name="name"
    v-model="innerValue"
    v-slot="{ value, field, errorMessage }"
    :rules="validation"
    as="div"
  >
    <ui-input-label v-if="label" :label="label" :hint="hint" :required="validation && !!validation.required" />
    <el-form-item :error="errorMessage" :required="validation && !!validation.required" class="p-0 mt-0 mb-1">
      <el-input
        type="textarea"
        :id="inputId"
        :model-value="value"
        v-bind="field"
        :rows="rows"
        :placeholder="placeholder"
        :disabled="!!disabled"
        :data-cy="dataCy ? dataCy : null"
        ref="refUiInput"
        :validate-event="false"
      />
    </el-form-item>
  </Field>
</template>

<script>
export default {
  name: 'textarea-input',
  emits: ['update:modelValue'],

  props: {
    inputId: String,
    name: String,
    validation: [String, Object],
    value: String,
    modelValue: String,
    placeholder: String,
    rows: { type: Number, default: 2 },
    disabled: Boolean,
    label: String,
    hint: String,
    dataCy: String,
    apiErrors: Array
  },

  computed: {
    innerValue: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }

}
</script>
<style lang="scss">
.el-textarea__inner {
  border-radius: 10px;
  padding: 10px 11px;
}
</style>
