import { HTTP } from '@/api/http_utils'

let promises = {}
let lastSuccessful = {}
const CACHE_TIME = 1000 * 60 // 1 minute

const UPDATE_SKILLS_LIST = 'UPDATE_SKILLS_LIST'
const UPDATE_SKILL = 'UPDATE_SKILL'
const DELETE_SKILL = 'DELETE_SKILL'

const state = {
  skillsIndex: {}
}

const getters = {
  skills: state => Object.values(state.skillsIndex),
  skillsIndexed: state => state.skillsIndex,
  skillsCount: state => {
    return Object.values(state.skillsIndex).length
  },
  skillById: (state) => (id) => {
    return state.skillsIndex[id]
  }
}

const mutations = {
  [UPDATE_SKILLS_LIST] (state, payload) {
    Object.values(state.skillsIndex).forEach((item) => {
      delete state.skillsIndex[item.id]
    })
    payload.forEach(item => {
      state.skillsIndex[item.id] = item
    })
  },
  [UPDATE_SKILL] (state, skill) {
    state.skillsIndex[skill.id] = skill
  },
  [DELETE_SKILL] (state, skillId) {
    delete state.skillsIndex[skillId]
  }
}

const actions = {
  clearSkillsCache () {
    promises = {}
    lastSuccessful = {}
  },
  // CRUD
  getSkills ({ commit }, companyId) {
    const promiseKey = '/companies/' + companyId + '/skills'

    if (promises[promiseKey]) {
      return promises[promiseKey]
    }

    const now = (new Date()).getTime()
    if ((now - lastSuccessful[promiseKey]) < CACHE_TIME) {
      return Object.values(state.skillsIndex)
    }

    const promise = new Promise(resolve => {
      HTTP.get('/companies/' + companyId + '/skills').then(skills => {
        commit(UPDATE_SKILLS_LIST, skills)
        lastSuccessful[promiseKey] = (new Date()).getTime()
        resolve(skills)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },

  getSkill ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyId}/skills/${id}`).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  createSkill ({ commit }, [companyId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/skills`, payload).then(skill => {
        commit(UPDATE_SKILL, skill)
        resolve(skill)
      }).catch(err => {
        reject(err)
      })
    })
  },

  editSkill ({ commit }, [companyId, skillId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/companies/${companyId}/skills/${skillId}`, payload).then(skill => {
        commit(UPDATE_SKILL, skill)
        resolve(skill)
      }).catch(err => {
        reject(err)
      })
    })
  },

  deleteSkill ({ commit }, [companyId, skillId]) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/companies/${companyId}/skills/${skillId}`).then(response => {
        commit(DELETE_SKILL, skillId)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },

  attachSkills ({ commit }, [companyId, employeeId, payload]) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/companies/${companyId}/employees/${employeeId}/skills`, payload).then(response => {
        // console.log(response)
        commit('UPDATE_EMPLOYEE', response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
