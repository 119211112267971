<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text">
            <router-link :to="`/c/${companyId}/shifts-activity-templates`">
              <span class="breadcrumb-item">{{ $t('SHIFT_ACTIVITY_TEMPLATES')}}</span>
            </router-link>
          </li>
          <li class="breadcrumb-item title-text active" aria-current="page">
            {{ template.id ? $t("SHIFT_ACTIVITY_TEMPLATE_EDIT") : $t("SHIFT_ACTIVITY_TEMPLATE_CREATE") }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6">
            <ActivityTemplateForm
                v-if="loaded"
                :activityTemplateData="template"
                @success="onSuccess"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActivityTemplateForm from '@/components/ShiftActivity/Components/ActivityTemplateForm.vue'
import { mapGetters } from 'vuex'

export default {
  components: { ActivityTemplateForm },
  name: 'ActivityTemplateFormView',
  data () {
    return {
      templateId: null,
      template: {
        id: null,
        title: null,
        project_id: null,
        schedule_id: null,
        time_from: null,
        time_to: null,
        location_id: null,
        position_id: null,
        skills: [],
        marks: [],
        color: '#4e93e9'
      },
      loaded: false
    }
  },
  created () {
    this.templateId = +this.$route.params.templateId
    const locations = this.$store.dispatch('getLocations', this.companyId)
    const positions = this.$store.dispatch('getPositions', this.companyId)
    const skills = this.$store.dispatch('getSkills', this.companyId)
    const marks = this.$store.dispatch('getMarksByCompany', this.companyId)
    const templates = this.$store.dispatch('getActivityTemplates', this.companyId)
    Promise.all([locations, positions, marks, templates, skills]).then(_ => {
      if (this.templateId) {
        const template = this.$store.getters.activityTemplates?.filter(t => t.id === this.templateId)[0]
        if (template) {
          this.template = { ...template }
        }
      }
      this.loaded = true
    })
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },
  methods: {
    onSuccess () {
      this.$router.back()
    }
  }
}
</script>
<style scoped>

</style>
