<template>
  <div></div>
</template>

<script>
import inViewport from 'vue-in-viewport-mixin'

export default {
  name: 'VisibilityObserver',
  mixins: [inViewport],
  props: { id: String },
  created () {
    // if (this.$parent.$el.offsetHeight)
    // console.log(this.$parent.$el.offsetHeight)
  },
  watch: {
    'inViewport.now': function (view) {
      if (view === true) {
        this.$eventBus.emit('loadNextResource')
      }
    }
  },
  methods: {
    // переопределяем метод из миксина, для обработки parent undefined
    updateInViewport: function updateInViewport (_ref) {
      const _ref2 = _slicedToArray(_ref, 1)
      const _ref2$ = _ref2[0]
      const target = _ref2$.boundingClientRect
      const root = _ref2$.rootBounds

      if (root && target) {
        // Get the maximum threshold ratio, which is less than 1 when the
        // element is taller than the viewport.
        this.inViewport.maxThreshold = Math.min(1, root.height / target.height) // Check if some part of the target is in the root box.  The isIntersecting
        // property from the IntersectionObserver was not used because it reports
        // the case where a box is immediately offscreen as intersecting, even
        // though no aprt of it is visible.

        this.inViewport.now = target.top <= root.bottom && target.bottom > root.top // Calculate above and below.  The +1 on the bottom check co-incides with
        // the default root-margin which has a -1 on the bottom margin.

        this.inViewport.above = target.top < root.top
        this.inViewport.below = target.bottom > root.bottom + 1 // Determine whether fully in viewport. The rules are different based on
        // whether the target is taller than the viewport.

        this.inViewport.fully = target.height > root.height ? target.top <= root.top && target.bottom >= root.bottom + 1 : !this.inViewport.above && !this.inViewport.below
        if (this.inViewportOnce && this.inViewport.now) {
          // If set to update "once", remove listeners if in viewport
          return this.removeInViewportHandlers()
        }
      } else {
        this.inViewport.now = false
        this.inViewport.above = false
        this.inViewport.below = false
        this.inViewport.fully = false
      }
    }
  }
}
function _slicedToArray (arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest() }
function _nonIterableRest () { throw new TypeError('Invalid attempt to destructure non-iterable instance') }

// eslint-disable-next-line no-undef-init,no-unsafe-finally,no-var
function _iterableToArrayLimit (arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break } } catch (err) { _d = true; _e = err } finally { try { if (!_n && _i.return != null) _i.return() } finally { if (_d) throw _e } } return _arr }
function _arrayWithHoles (arr) { if (Array.isArray(arr)) return arr }
</script>

<style scoped>

</style>
