<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body">
        <h5 class="text-uppercase">
          <span class="pull-right">
            Invoice #{{ invoice.number }}
          </span>
        </h5>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <div class="pull-left">
              <address>
                <p class="text-muted">
                  <b class="text-uppercase">Shifton inc</b><br>
                  3736 SW 30TH AVE<br>
                  FT Lauderdale, FL 33312
                </p>
              </address>
            </div>
            <div class="pull-right text-right" v-if="billingAddress && billingAddress.first_name && billingAddress.last_name">
              <address>
                <div class="text-muted">
                  To,
                  <p>{{billingAddress.first_name}} {{billingAddress.last_name}}</p>
                  <p><b>{{billingAddress.business_name}}</b></p>
                  <p><b>{{billingAddress.vat_number}}</b></p>
                  <p v-if="billingAddress.address || billingAddress.additional_address">
                    <span v-if="billingAddress.address">{{billingAddress.address}}</span>
                    <span v-if="billingAddress.address && billingAddress.additional_address">,</span>
                    <span v-if="billingAddress.additional_address"> {{billingAddress.additional_address}}</span>
                  </p>
                  <p v-if="billingAddress.city"><span v-if="billingAddress.city">{{ billingAddress.city }}</span></p>
                  <p>{{billingAddress.country}}, {{billingAddress.postal_code}}</p>
                  <p>+{{billingAddress.phone}}</p>
                  <p>{{billingAddress.email}}</p>
                </div>
              </address>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3 offset-9">
            <hr>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td class="text-right">Invioce date</td>
                  <td>{{ createdDate() }}</td>
                </tr>
                <tr>
                  <td class="text-right">Due date:</td>
                  <td>{{ paymentDate() }}</td>
                </tr>
                <tr>
                  <td class="text-right">Status :</td>
                  <td>{{ invoiceStatus(invoice.status) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive m-t-40" style="clear: both;">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Price per unit</th>
                    <th class="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(mod, key) in invoice.invoices_data" :key="key">
                    <tr class="font-weight-bold">
                      <td rowspan="3">{{ key + 1 }}.</td>
                      <td>{{ mod.module_name }}</td>
                      <td class="text-center">{{ mod.count }}</td>
                      <td class="text-center">${{ parseFloat(mod.price).toFixed(2) }}</td>
                      <td class="text-right">${{ parseFloat(mod.count * mod.price).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="text-muted pl-10">+ ${{ parseFloat(mod.employee_price).toFixed(2) }} per user</td>
                      <td class="text-center text-muted">{{ mod.employees }}</td>
                      <td class="text-center text-muted">${{ parseFloat(mod.employee_price).toFixed(2) }}</td>
                      <td class="text-right text-muted">${{ parseFloat(mod.employees * mod.employee_price).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="text-muted pl-10">+ ${{ parseFloat(mod.unit_price).toFixed(4) }} per unit</td>
                      <td class="text-center text-muted">{{ mod.unit_count }}</td>
                      <td class="text-center text-muted">${{ parseFloat(mod.unit_price).toFixed(4) }}</td>
                      <td class="text-right text-muted">${{ parseFloat(mod.unit_count * mod.unit_price).toFixed(4) }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-md-12">
              <div class="pull-right m-t-30 text-right">
                <h3><b>Total : </b> ${{ parseFloat(invoice.amount).toFixed(2) }}</h3>
              </div>
              <div class="text-right">
                <button id="print" @click="print" class="btn btn-primary btn-outline" type="button">
                  <span>
                    <i class="fa fa-print"></i>Print
                  </span>
                </button>
              </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import errorMixin from '../../../../mixins/mixinApiErrors'
import momentMixin from '../../../../mixins/mixinMoment'
import { mapGetters } from 'vuex'

export default {
  name: 'Invoices',
  mixins: [errorMixin, momentMixin],
  components: {},
  data () {
    return {
      term: 5,
      invoice: {},
      billingAddress: {},
      availableModules: {}
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId'
    })
  },
  watch: {
    companyId () {
      this.init()
    }
  },
  methods: {
    init () {
      this.$store.dispatch('getInvoiceById', this.$route.params.invoice_id).then(response => {
        this.invoice = response
        this.billingAddress = response.billing_information
        this.getCoutnry()
        this.getCity()
      })
    },
    createdDate () {
      return moment(this.invoice.charge_date).format(this.localeMonthYearFormat)
    },
    paymentDate () {
      return moment(this.invoice.charge_date).add(1, 'Month').add(this.term - 1, 'Days').format(this.localeDateFormat)
    },
    print () {
      window.print()
    },
    invoiceStatus (status) {
      switch (status) {
        case 'created':
          return 'Awaiting payment'
        case 'succeeded':
          return 'Paid'
        case 'cancelled':
          return 'Cancelled'
        default:
          return 'Refunded'
      }
    },
    getCity () {
      if (this.billingAddress && this.billingAddress.country && this.billingAddress.city) {
        this.$store.dispatch('getCityById', [this.billingAddress.city, 'en']).then(response => {
          // console.log(response)
          if (response) {
            this.billingAddress.city = response.name
          }
        }).catch({})
      }
    },
    getCoutnry () {
      if (this.billingAddress && this.billingAddress.country) {
        this.$store.dispatch('getCountries').then(response => {
          if (response) {
            // console.log(response)
            Object.keys(response).forEach(key => {
              if (key.toLowerCase() === this.billingAddress.country) {
                this.billingAddress.country = response[key].name
              }
            })
            // response.forEach(country => {
            //   if (country.code === this.billingAddress.country) {
            //     this.billingAddress.country = country.name
            //   }
            // })
          }
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
