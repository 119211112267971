<template>
  <div class="card shadow-none">
    <div class="card-header font-weight-bold">
      <div class="row d-flex align-items-center pl-2">
        {{ $t("Invoices").toUpperCase() }}
        <ui-month-range-picker
          name="from"
          class="pl-2"
          v-model="monthRangeValue"
          :clearable="false"
          />
        <span class="align-text-top">
          <i class="icon-download btn-icon text-primary mx-1" type="button" @click="handleExport('default')"></i>
        </span>
      </div>
    </div>
    <div class="card-body p-1 table-responsive">
      <table v-if="paginationConfig.paginated.length > 0" class="table table-hover">
        <thead>
        <tr>
          <th class="text-center">{{ $t("Invoice") }} ID</th>
          <th class="text-center">{{ $t("CHARGE_DATE") }}</th>
          <th class="text-center">{{ $t("Due date") }}</th>
          <th class="text-center">{{ $t("CREATE_DATE") }}</th>
          <th class="text-center">{{ $t("Price") }}</th>
          <th class="text-center">{{ $t("PAID_INVOICE_TITLE") }}</th>
          <th class="text-center">{{ $t("Remainder to be paid") }}</th>
          <th class="text-center">{{ $t("Status") }}</th>
          <th class="text-center">{{ $t("Print") }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(invoice, key) in paginationConfig.paginated" :key="key">
          <td class="text-left text-nowrap">
            <router-link
              :to="`/c/${companyId}/invoice/${invoice.id}`"
              class="btn btn-link">
              {{ invoice.id }}
            </router-link>
          </td>
          <td class="text-center">{{ chargeDate(invoice) }}</td>
          <td class="text-center">{{ paymentDate(invoice) }}</td>
          <td class="text-center">{{ createdDate(invoice) }}</td>
          <td class="text-center">{{ parseFloat(invoice.amount).toFixed(2) }}</td>
          <td class="text-center">{{ parseFloat(invoice.paid).toFixed(2) }}</td>
          <td class="text-center">{{ parseFloat(invoice.amount - invoice.paid).toFixed(2) }}</td>
          <td class="text-center"> {{ $t(getInvoiceStatus(invoice)) }}</td>
          <td class="text-center"><i class="icon-list font-weight-bold"></i></td>
        </tr>
        </tbody>
      </table>
      <div v-else class="text-center w-100 d-flex font-weight-bold">
        <div class="m-auto">{{ $t('Nothing to show') }}</div>
      </div>
    </div>
    <div v-show="paginationConfig.paginated.length > 0" class="card-footer bg-transparent pb-0">
      <pagination :rows="paginationConfig.rows" :customClasses="'justify-content-center'" :pageSize="pageSize" v-on:updatePaginated="updatePaginated"></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../CommonComponents/Pagination/Pagination'
import paginationMixin from '../../../CommonComponents/Pagination/mixinPagination'
import errorMixin from '../../../../mixins/mixinApiErrors'
import momentMixin from '../../../../mixins/mixinMoment'
import moment from 'moment'

export default {
  name: 'Invoices',
  mixins: [paginationMixin, errorMixin, momentMixin],
  components: {
    Pagination: Pagination
  },
  data () {
    return {
      pageSize: 6,
      monthRangeValue: [],
      rows: []
    }
  },
  created () {
    this.paginationConfig.rows = []
    this.$store.dispatch('getInvoices').then(response => {
      if (response) {
        this.paginationConfig.rows = response
        this.rows = response
        this.monthRangeValue = [moment(response.at(-1).charge_date), moment(response.at(0).charge_date)]
      }
    })
  },
  computed: {
    companyId () {
      return this.$store.getters.companyId
    }
  },
  methods: {
    getInvoiceStatus (invoice) {
      switch (invoice.status) {
        case 'succeeded':
          return 'PAID_STATUS_INVOICE'
        case 'cancelled':
          return 'CANCELLED_STATUS_INVOICE'
        default:
          return 'UNPAID_STATUS_INVOICE'
      }
    },
    createdDate (invoice) {
      return moment(invoice.created_at).format(this.localeDateFormat)
    },
    chargeDate (invoice) {
      return moment(invoice.charge_date).format(this.localeMonthYearFormat)
    },
    paymentDate (invoice) {
      return moment(invoice.charge_date).add(1, 'Month').add(4, 'Days').format(this.localeDateFormat)
    },
    handleExport () {
      const startDate = moment(this.monthRangeValue[0]).format(this.backendMonthYearFormat)
      const endDate = moment(this.monthRangeValue[1]).format(this.backendMonthYearFormat)
      const payLoad = `?export=1&date_from=${startDate}&date_to=${endDate}`

      this.$store.dispatch('getInvoices', { payload: payLoad, blob: true }).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.$i18n?.t('export_invoices')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        }
      })
    }
  },
  watch: {
    monthRangeValue () {
      const startDate = moment(this.monthRangeValue[0])
      const endDate = moment(this.monthRangeValue[1])
      this.paginationConfig.rows = this.rows.filter(item => moment(item.charge_date).isBetween(startDate, endDate, 'days', true))
    }
  }
}
</script>

<style scoped>

</style>
