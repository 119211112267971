<template>
  <modal-component :id="'ShiftMassActionModal'" @closeModal="closeModal">
    <template #header>
      <h5 class="modal-title">{{ $t('SHIFT_MASS_ACTIONS_MODAL')}}</h5>
    </template>
    <template #body>
      <Form ref="observer" as="div">
        <div v-show="step === 1">
          <div class="row">
            <div class="col">
              <ui-group-select
                :label="$t('SELECT_TEMPLATES')"
                :hint="$t('SELECT_TEMPLATES_HINT')"
                name="templates"
                v-model="selectedTemplates"
                :options="templateOptions"
                @change="makeUserChoices"
                :multiple="true"
              />
            </div>
          </div>
          <dates-picker-component
            :dateToLimit="null"
            :dateFromLimit="null"
            :shiftAddDate="shiftAddDate"
            :shiftAddEndDate="shiftAddEndDate"
            :hint="$t('MASS_ACTION_PERIOD_HINT')"
            @onChangeDays="onChangeDays"
            @onChangeDates="onChangeDates"
            @onChangeActiveCollapse="onChangeActiveCollapse"
            @onChangeShiftDateFrom="onChangeShiftDateFrom"
            @onChangeShiftDateTo="onChangeShiftDateTo"
          />
          <ui-multi-select
            name="users"
            :label="$t('USERS_TO_FILTER')"
            :hint="$t('USERS_TO_FILTER_HINT')"
            v-model="selectedUsers"
            :clearable="true"
            class="w-100"
            :options="usersChoices"
            :disabled="withFreeboard === 'only_freeboard'"
            keyName="key"
            valueName="id"
            labelName="name"
          />
          <ui-radio
            name="NO_FREEBOARD"
            class="mt-3"
            :label="$t('NO_FREEBOARD')"
            :hint="$t('NO_FREEBOARD_HINT')"
            v-model="withFreeboard"
            :set-value="'no_freeboard'"
          />
          <ui-radio
            name="WITH_FREEBOARD"
            :label="$t('WITH_FREEBOARD')"
            :hint="$t('WITH_FREEBOARD_HINT')"
            v-model="withFreeboard"
            :set-value="'with_freeboard'"
          />
          <ui-radio
            name="ONLY_FREEBOARD"
            :label="$t('ONLY_FREEBOARD')"
            :hint="$t('ONLY_FREEBOARD_HINT')"
            v-model="withFreeboard"
            :set-value="'only_freeboard'"
          />
        </div>
        <div v-show="step === 2">
          <div class="row">
            <div class="col">
              <ui-select
                name="action"
                :label="$t('SELECT_SHIFT_ACTIONS')"
                :hint="$t('SELECT_SHIFT_ACTIONS_HINT')"
                v-model="action"
                class="w-100"
                :options="actionChoices"
                :validation="{required: false}"
                :key-name="'id'"
                :value-name="'id'"
                :label-name="'name'"
              />
            </div>
          </div>
          <div v-if="action === 'add_mark' || action === 'remove_mark'" class="row">
            <div class="col">
              <ui-tags-select
                :label="$t('SELECT_POSITIONS_TO_UPDATE')"
                :hint="$t('SELECT_POSITIONS_TO_UPDATE_HINT')"
                name="positions"
                v-model="marks"
                :clearable="true"
                :filterable="true"
                :placeholder="$t('Select')"
                :options="projectMarks"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
                :allow-create="true"
              >
                <template v-slot:default="slotProps">
                  <div  class="project-color mr-2" v-bind:style="{ backgroundColor: slotProps.option.color }"></div>
                  {{ slotProps.option.name }}
                </template>
              </ui-tags-select>
            </div>
          </div>
          <div v-if="action === 'set_location'" class="row">
            <div class="col">
              <ui-select
                :label="$t('SHIFT_LOCATION_SELECT')"
                :hint="$t('SHIFT_LOCATION_SELECT_HINT')"
                :name="'location'"
                v-model="location"
                :clearable="false"
                :placeholder="$t('Select')"
                :options="locations"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
          </div>
          <div v-if="action === 'update_notes'" class="row">
            <div class="col">
              <div class="mb-2">
                <ui-textarea-input
                  name="note"
                  :label="$t('SHIFT_NOTE_LABEL')"
                  :hint="$t('SHIFT_NOTE_LABEL_HINT')"
                  v-model="note"
                  :validation="{required: false, max: 256}"
                  :rows="1"
                  :placeholder="$t('SHIFT_NOTE')"
                />
              </div>
              <div>
                <ui-checkbox
                  class="mt-auto"
                  name="rate"
                  :disabled="!note"
                  v-model="hide_note"
                >
                  {{ $t('SHIFT_NOTE_HIDE_LABEL') }}
                  <ui-hint :content="$t('SHIFT_NOTE_HIDE_LABEL_HINT')" />
                </ui-checkbox>
              </div>
            </div>
          </div>
          <Form v-if="action === 'set_bonuses'" ref="observer" as="div" class="row">
            <div class="col-lg-12">
              <ui-select
                :label="$t('INSERT_POSITION')"
                :hint="$t('INSERT_POSITION_HINT')"
                :name="'bonuses'"
                v-model="position"
                :clearable="false"
                :disabled="isDisabled"
                :placeholder="$t('Select')"
                :options="positions"
                :key-name="'id'"
                :label-name="'name'"
                :value-name="'id'"
              />
            </div>
            <div class="col-lg-6">
              <ui-number-input
                name="amount"
                :label="$t('INSERT_AMOUNT')"
                :hint="$t('INSERT_AMOUNT_HINT')"
                v-model="amount"
                :disabled="isDisabled"
                :precision="2"
                :step="0.1"
                :validation="{required: true, decimal: 2, min: 0.1}"
              />
            </div>
            <div class="col-lg-6">
              <ui-number-input
                name="percent"
                :label="$t('INSERT_PERCENT')"
                :hint="$t('INSERT_PERCENT_HINT')"
                v-model="percent"
                :disabled="isDisabled"
                :precision="2"
                :step="0.1"
                :validation="{required: true, decimal: 2, min: 0}"
              />
            </div>
          </Form>
          <div v-if="action === 'set_bonuses'" class="row">
            <div class="col-lg-12">
              <ui-textarea-input
                name="comment"
                :label="$t('BONUS_PENALTY_COMMENT')"
                :disabled="isDisabled"
                :rows="2"
                v-model="comment"
              />
            </div>
            <div v-if="isDisabled" class="col-lg-12 mt-2">
              <div class="text-danger">
                {{$t('WARNING_ABOUT_DATES')}}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </template>
    <template #footer>
      <div v-if="step === 1" class="row">
        <div class="col p-1">
          <button class="btn btn-sm btn-danger rounded-pill shadow-sm" @click="handleDelete">
            {{ $t('Delete') }}
          </button>
        </div>
        <div class="col p-1">
          <button class="btn btn-sm btn-primary rounded-pill shadow-sm text-nowrap" @click="handleMove">
            {{ $t('MOVE_TO_FREEBOARD') }}
          </button>
        </div>
        <div class="col p-1">
          <button class="btn btn-sm btn-success rounded-pill shadow-sm" @click="step = 2">
            {{ $t('Edit') }}
          </button>
        </div>
      </div>
      <div v-if="step === 2" class="row">
        <div class="col p-1">
          <button class="btn btn-sm btn-success rounded-pill shadow-sm" @click="step = 1">
            {{ $t('Back') }}
          </button>
        </div>
        <div class="col p-1">
          <button class="btn btn-sm btn-success rounded-pill shadow-sm" :disabled="isDisabled" @click="handleEdit">
            {{ $t('UPDATE_SHIFTS') }}
          </button>
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import momentMixin from '@/mixins/mixinMoment'
import ModalComponent from '@/components/CommonComponents/ModalComponent'
import mixinColorsMarks from '@/mixins/mixinColorsMarks'
import DatesPickerComponent from '@/components/Schedule/CompanyScheduleComponents/Components/DatesPickerComponent'

export default {
  name: 'MassActionShiftModal',
  mixins: [momentMixin, mixinColorsMarks],
  components: {
    DatesPickerComponent,
    ModalComponent: ModalComponent
  },
  data () {
    return {
      step: 1,
      shiftDateFrom: moment().format(this.localeDateFormat),
      shiftDateTo: moment().format(this.localeDateFormat),
      selectedTemplates: [],
      selectedUsers: [],
      action: null,
      marks: [],
      location: null,
      // locations: [],
      usersChoices: [],
      templateOptions: [],
      pickerOptions: {
        // disabledDate: (date) => {
        //   return moment(this.shiftDateFrom, this.localeDateFormat).isAfter(moment(date)) ||
        //     this.blockedDateFrom.isSameOrAfter(moment(date)) ||
        //     moment(date).isSameOrAfter(this.blockedDateTo)
        // },
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
      },
      pickerOptionsFrom: {
        firstDayOfWeek: +this.$store.getters.company.salary.week_start + 1
        // disabledDate: (date) => {
        //   return this.blockedDateFrom.isSameOrAfter(moment(date)) ||
        //     moment(date).isSameOrAfter(this.blockedDateTo)
        // }
      },
      actionChoices: [
        {
          id: 'add_mark',
          name: this.$t('ADD_MARKS_ACTION')
        },
        {
          id: 'remove_mark',
          name: this.$t('REMOVE_MARKS_ACTION')
        },
        {
          id: 'set_location',
          name: this.$t('SET_LOCATION_ACTION')
        },
        {
          id: 'delete_location',
          name: this.$t('DELETE_LOCATION_ACTION')
        },
        {
          id: 'set_bonuses',
          name: this.$t('SET_BONUSES_ACTION')
        },
        {
          id: 'recalculate_wages',
          name: this.$t('RECALCULATE_WAGES_ACTION')
        },
        {
          id: 'update_notes',
          name: this.$t('UPDATE_NOTES_ACTION')
        }
      ],
      positions: [],
      position: null,
      amount: 0,
      percent: 0,
      comment: '',
      positionId: null,
      shiftAddDate: moment(),
      shiftAddEndDate: moment(),
      days: [0, 1, 2, 3, 4, 5, 6],
      dates: [],
      activeCollapse: 'period',
      withFreeboard: 'no_freeboard',
      note: null,
      hide_note: true
    }
  },
  props: {
    projectId: Number,
    templatesByProject: Object,
    users: Object,
    start: Date,
    end: Date,
    filteredResources: Array,
    filteredTemplates: Array,
    filter: Object
  },
  created () { // greaterOrEqualZero
    // console.log(23, this.start, this.end)
    this.shiftAddDate = moment(this.start)
    this.shiftAddEndDate = moment(this.end).add(-1, 'day')
    // console.log(this.shiftAddDate)
    // console.log(this.shiftAddEndDate)
    this.$eventBus.on('initMassAction', this.init)
    this.$store.dispatch('getPositions', this.companyId).then(response => {
      this.positions = response.map(position => {
        return { id: position.id, name: position.title }
      })
      this.positions.unshift({
        id: null,
        name: ''
      })
      // console.log(this.positions)
    })
    this.$store.dispatch('getLocations', this.companyId).then(response => {
      this.location = this.locations[0] ? this.locations[0].id : null
    })
    this.init()
    // this.changeDate()
  },

  computed: {
    locations () {
      return this.$store.getters.locations.filter(item => !item.hide).map(location => {
        return { id: location.id, name: location.title }
      })
    },
    customTemplatesToHide () {
      return this.$store.getters.customTemplatesToHide
    },
    isDisabled () {
      if (this.action === 'set_bonuses') {
        return !moment(this.shiftDateFrom, this.localeDateFormat).isSame(moment(this.shiftDateTo, this.localeDateFormat))
      }
      return false
    },
    ...mapGetters({
      preloadUsers: 'employeesIndexed',
      projectMarks: 'projectMarks'
    })
  },

  watch: {
    marks (items) {
      // console.log(123, items)
      if (Array.isArray(items)) {
        items.forEach((item, index) => {
          if (typeof item === 'string') {
            const tag = {
              name: item,
              enabled: true
            }
            const usedColors = []
            this.projectMarks.forEach(mark => {
              usedColors.push(mark.color)
            })
            const colorDiff = this.colorsChoicesMarks.filter(x => !usedColors.includes(x))
            if (colorDiff.length > 0) {
              tag.color = colorDiff[0]
            } else {
              tag.color = this.colorsChoicesMarks[Math.floor(Math.random() * this.colorsChoicesMarks.length)]
            }
            const newIndex = index
            this.$store.dispatch('createMark', [tag, this.projectId]).then((mark) => {
              this.marks[newIndex] = mark.id
            })
          }
        })
      }
    }
  },
  methods: {
    init () {
      // console.log('init', this.filter)
      this.makeTemplateChoices()
      this.makeUserChoices()
      this.shiftDateFrom = moment(this.start).format(this.localeDateFormat)
      this.shiftDateTo = moment(this.end).add(-1, 'day').format(this.localeDateFormat)
    },
    onChangeDates (dates) {
      this.dates = dates
    },
    onChangeDays (days) {
      this.days = days
    },
    onChangeActiveCollapse (val) {
      this.activeCollapse = val
    },
    onChangeShiftDateFrom (val) {
      // console.log('from', val)
      this.shiftDateFrom = val
    },
    onChangeShiftDateTo (val) {
      // console.log('to', val)
      this.shiftDateTo = val
    },
    closeModal () {
      this.$emit('ShiftMassAction')
    },
    makeUserChoices () {
      this.usersChoices = []
      this.selectedUsers = []
      let users = []
      if (this.selectedTemplates.length > 0) {
        this.selectedTemplates.forEach(id => {
          const scheduleId = id.split('__sp__')[0]
          users = users.concat(this.users[scheduleId])
        })
        users = [...new Set(users)]
      } else {
        Object.keys(this.templatesByProject).forEach(id => {
          // console.log(id)
          const scheduleId = id.split('__sp__')[0]
          users = users.concat(this.users[scheduleId])
          // console.log(1, users)
        })
        users = [...new Set(users)]
      }
      // console.log(users)
      const filteredResources = this.filteredResources.slice(3)
      const filteredIds = []
      filteredResources.forEach(resource => {
        filteredIds.push(resource.id)
      })
      // проверяем что применены какие-либо фильтры, и если применены, добавляем в селект юзеров отфильтрованные ресурсы
      let appliedFilters = 0
      Object.values(this.filter.values).forEach(filter => {
        if (filter.length > 0) {
          appliedFilters++
        }
      })
      users.forEach(id => {
        if (this.preloadUsers[+id]) {
          this.usersChoices.push({ id: id, name: this.preloadUsers[+id].full_name })
          if (filteredIds.includes(id) && appliedFilters > 0) {
            this.selectedUsers.push(id)
          }
        }
      })
      this.usersChoices.sort((a, b) => {
        if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) return -1
        if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) return 1
        return 0
      })
    },
    makeTemplateChoices () {
      this.selectedTemplates = []
      const filteredTemplates = {}
      this.templateOptions = []
      const templates = [...new Set(this.filteredTemplates)]
      // console.log(this.customTemplatesToHide)
      Object.keys(this.templatesByProject).forEach(key => {
        if (!this.templatesByProject[key][3]) {
          filteredTemplates[key] = this.templatesByProject[key]
          // console.log(this.filteredTemplates, this.templatesByProject)
          if (templates.length !== Object.keys(this.templatesByProject).length) {
            if (this.filteredTemplates.includes(key) && !this.customTemplatesToHide.includes(key)) {
              this.selectedTemplates.push(key)
            }
          }
        }
      })
      const templateOptions = {}
      Object.keys(filteredTemplates).forEach((key) => {
        // console.log(key)
        if (!this.customTemplatesToHide.includes(key)) {
          if (!templateOptions[filteredTemplates[key][0]]) {
            templateOptions[filteredTemplates[key][0]] = []
          }
          templateOptions[filteredTemplates[key][0]].push({
            id: key,
            name: `${this.$i18n?.t('Shift template')}: ${filteredTemplates[key][1]}`
          })
        }
      })
      Object.keys(templateOptions).forEach((key) => {
        this.templateOptions.push({
          label: `${this.$i18n?.t('Schedule')}: ${key}`,
          options: templateOptions[key]
        })
      })
      // console.log(this.templateOptions)
    },
    handleMove () {
      this.action = 'move_to_freeboard'
      this.handleEdit()
    },
    handleDelete () {
      this.action = 'delete'
      this.handleEdit()
    },
    handleEdit () {
      this.$refs.observer.validate().then(value => {
        if (this.action === 'set_bonuses' && !value.valid) {
          return false
        } else {
          const start = moment(this.shiftDateFrom, this.backendDateTimeFormat).format(this.backendDateTimeFormat)
          const end = moment(this.shiftDateTo, this.backendDateTimeFormat).add(1, 'day').format(this.backendDateTimeFormat)
          const templates = []
          this.selectedTemplates.forEach(templateId => {
            const templ = templateId.split('__sp__')
            templates.push({
              schedule: +templ[0],
              template: +templ[1]
            })
          })
          let action = this.action
          let location = this.location ?? null
          if (action === 'delete_location') {
            action = 'set_location'
            location = null
          }
          let note = null
          if (action === 'update_notes') {
            note = {
              message: this.note,
              hide_note: this.hide_note
            }
          }
          const payload = {
            action: action,
            is_count: true,
            start: this.fromZoneToZone(start, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
            end: this.fromZoneToZone(end, this.$store.getters.actualTZ, 'UTC').format(this.backendDateTimeFormat),
            employees: this.selectedUsers,
            templates: templates,
            marks: this.marks,
            location: location,
            position: this.position,
            amount: this.amount,
            percent: this.percent,
            comment: this.comment,
            type: this.activeCollapse,
            days: this.days,
            dates: this.dates.map(date => date.id),
            with_freeboard: this.withFreeboard,
            note: note
          }
          this.$store.dispatch('updateShifts', [this.projectId, payload]).then((response) => {
            let message = '<div>' + this.$t('COUNT_SHIFTS_TO_UPDATE') + ' ' + response.updated + '</div>'
            if (this.action === 'delete') {
              message = '<div>' + this.$t('COUNT_SHIFTS_TO_DELETE') + ' ' + response.updated + '</div>'
            }
            if (response.unchanged_shifts && response.unchanged_shifts > 0) {
              message += '<div>' + this.$t('UNCHANGED_SHIFTS') + ' ' + response.unchanged_shifts + '</div>'
            }
            if (this.action === 'set_bonuses') {
              message = '<div>' + this.$t('COUNT_SHIFTS_TO_UPDATE') + ' ' + response.updated + '</div><div>' + this.$t('BONUS_UMOUNT') + ' ' + Math.round(response.amount * 100) / 100 + '</div>'
            }
            this.$confirm(message, this.$t('Are you sure?'), {
              confirmButtonText: this.$t('Yes, I am sure!!'),
              cancelButtonText: this.$t('No, cancel it!!'),
              dangerouslyUseHTMLString: true,
              type: 'warning',
              center: true
            }).then((result) => {
              payload.is_count = false
              this.$store.dispatch('updateShifts', [this.projectId, payload]).then((response) => {
                if (this.action === 'delete' || this.action === 'move_to_freeboard') {
                  this.action = null
                }
                this.$eventBus.emit('refetchEvents')
                this.toastSuccess(this.$i18n?.t('Shifts successfully updated'))
                document.getElementById('ShiftMassActionModal').click()
                this.closeModal()
              })
            }).catch(() => {
              if (this.action === 'delete' || this.action === 'move_to_freeboard') {
                this.action = null
              }
            })
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
