<template>
  <Form ref="observe" as="div">
    <div class="row mb-2 mx-auto">
      <span class="text-primary text-uppercase font-weight-bold">
        {{ $t("Fields") }}
      </span>
    </div>
    <div class="row">
      <div class="col-lg-12 my-2">
        <ui-text-input
          :label="$t('Settings url')"
          :hint="$t('APPLICATION_FIELDS_SETTINGS_URL_HINT')"
          id="fields_url"
          class="w-50"
          name="settings_url"
          v-model="appState.settings_url"
          :validation="{url: true}"
          :placeholder="$t('Please input')"
        />
      </div>
      <div class="col-lg-12 my-2">
        <ui-text-input
          :label="$t('Redirect url')"
          :hint="$t('APPLICATION_REDIRECT_URL_HINT')"
          id="redirect_url"
          class="w-50"
          name="redirect_url"
          v-model="appState.redirect_url"
          :validation="{url: true}"
          :placeholder="$t('Please input')"
        />
      </div>
      <div class="col-lg-12 my-2">
        <ui-text-input
          :label="$t('APPLICATION_DISABLE_ACTION_URL')"
          :hint="$t('APPLICATION_DISABLE_ACTION_URL_HINT')"
          id="disable_action_url"
          class="w-50"
          name="disable_action_url"
          v-model="appState.disable_action_url"
          :validation="{url: true}"
          :placeholder="$t('Please input')"
        />
      </div>
      <div class="col-lg-12 my-2">
        <ui-text-input
          :label="$t('Settings secret')"
          :hint="$t('APPLICATION_FIELDS_SETTINGS_SECRET_HINT')"
          id="settings_secret"
          class="w-50"
          name="settings_secret"
          v-model="appState.settings_secret"
          :disabled="!(appState.redirect_url || appState.settings_url)"
          :validation="{required: appState.redirect_url || appState.settings_url, min: 10, max: 125}"
          :placeholder="$t('Please input')"
        />
      </div>
      <div class="col-lg-12 my-2">
        <ui-checkbox
          name="activate_after_redirect"
          v-model="appState.activate_after_redirect"
          :disabled="!appState.redirect_url"
          :label="$t('ACTIVATE_APPLICATION_AFTER_REDIRECT')"
          :hint="$t('ACTIVATE_APPLICATION_AFTER_REDIRECT_HINT')"
        />
      </div>
    </div>
    <div class="col-lg-12 my-3">
      <div v-for="(item, item_key) in appState.fields" :style="{background: !(item_key % 2) ? '#e9ecef' : '#fff'}" class="p-1 rounded" :key="item_key">
        <field-item
          :index="item_key"
          :item="item"
          :validateItem="validateItem"
          @deleteItem="deleteItem(item_key)">
        </field-item>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-sm d-flex">
        <button :disabled="!appState.settings_url || !appState.settings_secret" class="btn btn-outline-primary rounded-pill shadow-sm m-1" aria-label="Close" @click="addItem">
          <i class="icon-plus"></i>
        </button>
        <button class="btn btn-success rounded-pill shadow-sm my-1 ml-auto" aria-label="Close" @click="save">
          {{ $t('SAVE_WEB') }}
        </button>
      </div>
    </div>
  </Form>
</template>

<script>
import errorMixin from '@/mixins/mixinApiErrors'
import mixinColors from '@/mixins/mixinColors'
import ToastMixin from '@/mixins/ToastMixin'
import FieldItem from '@/components/Developer/Components/FieldItem'

export default {
  name: 'Fields',
  mixins: [errorMixin, mixinColors, ToastMixin],
  components: { FieldItem },
  data () {
    return {
      appId: this.$route.params.appId,
      validateItem: false,
      appState: {}
    }
  },
  props: {
    app: Object
  },
  created () {
    this.appState = this.app
  },
  computed: {
    companyId () {
      return this.$store.getters.company.id
    }
  },
  methods: {
    deleteItem (itemKey) {
      this.appState.fields.splice(+itemKey, 1)
    },
    addItem () {
      this.appState.fields.push({
        id: null,
        field_name: null,
        field_title: null,
        field_type: 'text',
        required: true,
        options: null
      })
    },
    save () {
      this.$refs.observe.validate().then(isValid => {
        if (isValid.valid) {
          this.handleSave()
        }
      })
    },
    handleSave () {
      const payload = {
        settings_url: this.appState.settings_url,
        redirect_url: this.appState.redirect_url,
        disable_action_url: this.appState.disable_action_url,
        settings_secret: this.appState.settings_secret,
        activate_after_redirect: this.appState.activate_after_redirect,
        fields: this.appState.fields
      }

      this.$store.dispatch('updateDeveloperApp', [this.companyId, this.appId, payload]).then((response) => {
        this.toastSuccess(this.$i18n?.t('App was updated'))
      }).catch((error) => {
        const errorMessage = error.response.data.message
        if (error.response.status === 400 && errorMessage) {
          this.toastError(this.$i18n?.t(errorMessage))
        }
      })
    }
  }
}
</script>
