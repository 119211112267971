import Moment from 'moment'
import { extendMoment } from 'moment-range'
import momentMixin from '@/mixins/mixinMoment'

const moment = extendMoment(Moment)

export default class TimeLineStatistic {
  constructor (viewType = 'resourceTimelineWeek',
    tz = 'UTC',
    segmentType = 'hours',
    segments = 24,
    segmentStep = 1, format = 'HH:mm') {
    this.viewType = viewType
    this.tz = tz
    this.segments = segments
    this.segmentType = segmentType
    this.segmentStep = segmentStep
    this.format = format
  }

  presence (currentStart, events, type) {
    return new Promise((resolve) => {
      if (this.viewType === 'resourceTimelineDay') {
        const clonedEvents = []
        // console.log('start')
        const val = []
        const start = Moment(currentStart)
        const end = Moment(currentStart).add(this.segmentStep, this.segmentType)
        events.forEach(event => {
          const cloned = Object.assign({}, event)
          cloned.time_from = momentMixin.methods.fromZoneToZone(cloned.time_from, 'UTC', this.tz).format(momentMixin.computed.backendDateTimeFormat())
          cloned.time_to = momentMixin.methods.fromZoneToZone(cloned.time_to, 'UTC', this.tz).format(momentMixin.computed.backendDateTimeFormat())
          clonedEvents.push(cloned)
        })
        for (let i = 1; i <= this.segments; i++) {
          const range1 = moment.range(start, end)
          let count = 0
          clonedEvents.forEach(item => {
            if (type === 'shifts' && item.employee_id < 1) {
              return
            }
            if (type === 'breaks' && (item.shift_id < 1 || item.employee_id < 1)) {
              return
            }
            const range2 = moment.range(Moment(item.time_from), Moment(item.time_to))
            if (range1.overlaps(range2)) {
              count++
            }
          })
          val.push(count)
          start.add(this.segmentStep, this.segmentType)
          end.add(this.segmentStep, this.segmentType)
        }
        // console.log('end', val)
        resolve(val)
      }
      resolve([])
    })
  }

  labels (currentStart) {
    if (this.viewType === 'resourceTimelineDay') {
      const val = [Moment(currentStart).format(this.format)]
      // console.log(this.segmentStep, this.segments, this.segmentStep, this.segmentType)
      for (let i = 1; i <= this.segments; i++) {
        // console.log(i)
        const end = Moment(currentStart).add(i * this.segmentStep, this.segmentType).format(this.format)
        val.push(end)
      }
      // console.log(val)
      return val
    }
    return []
  }
}
