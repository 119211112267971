<template>
  <div>
    <div class="row mb-2 mx-auto">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item title-text active" aria-current="page">
            <span class="title-text">{{ $t("SKILLS_TITLE") }}</span>
            <ui-hint :content="$t('SKILLS_TITLE_TOOLTIP')" />
          </li>
        </ol>
      </nav>
    </div>
    <div class="card card-small">
      <div class="card-body">
        <Form ref="observe" v-slot="{ meta }">
          <div class="row form-group">
            <div class="col-lg-auto">
              <ui-text-input
                :label="$t('Title')"
                id="title"
                name="title"
                v-model='newSkill.title'
                :validation="{required: true}"
              />
            </div>
            <div class="col-lg-auto my-auto">
            <span class="form-group mt-4">
              <ui-color-picker
                name="color"
                :label="$t('Choose a color:')"
                v-model="newSkill.color"
                :colors="colorsChoicesMarks"
              />
            </span>
            </div>
            <div class="col-lg-auto mt-4">
              <button class="btn btn-success btn-circle shadow-sm" :class="{'disabled': !meta.valid}" :disabled="!meta.valid" type="button" @click="save">
                {{ $t('SAVE_WEB') }}
              </button>
            </div>
          </div>
        </Form>
        <el-divider></el-divider>
        <div class="row">
          <div class="col-lg-12" v-if="skills.length > 0">
            <span class="text-uppercase font-weight-bold text-primary">{{ $t('SKILLS_LIST_TITLE') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 table-responsive">
            <Form ref="edit">
              <table class="table table-hover">
                <thead>
                <tr>
                  <td>{{$t('title')}}</td>
                  <td>{{$t('color')}}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(skill, index) in skills" v-bind:key="index">
                  <td>
                    <div class="my-auto">
                      <span v-if="skill.id !== inEdit.id">{{skill.title}}</span>
                      <ui-text-input
                        v-if="skill.id === inEdit.id"
                        :name="index + '_skill_title'"
                        v-model='inEdit.title'
                        :validation="{required: true}"
                      />
                    </div>
                  </td>
                  <td>
                    <span v-if="skill.id !== inEdit.id" class="project-color" v-bind:style="{ backgroundColor: skill.color }"></span>
                    <div v-if="skill.id === inEdit.id" class="form__input border-vue-swatches">
                      <ui-color-picker
                        :name="index + '_skill_color'"
                        v-model="inEdit.color"
                        :colors="colorsChoicesMarks"
                      />
                    </div>
                  </td>
                  <td class="">
                    <div class="d-flex justify-content-end">
                      <a v-if="skill.id !== inEdit.id" @click="editSkill(index)">
                        <i class="icon-pencil btn-icon text-primary mx-2"></i>
                      </a>
                      <a v-if="skill.id !== inEdit.id" @click="deleteSkill(skill.id)">
                        <i class="icon-trash btn-icon text-danger"></i>
                      </a>
                      <div class="mt-1">
                        <i
                          v-if="skill.id === inEdit.id"
                          class="icon-check btn-icon text-success"
                          @click="saveEdited(inEdit)" :title="$t('SAVE_WEB')"
                        ></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </Form>
          </div>
          <div class="col-lg-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/mixins/mixinColorsMarks'
import mixinApiErrors from '@/mixins/mixinApiErrors'
import moment from 'moment-timezone'
import momentMixin from '@/mixins/mixinMoment'

export default {
  mixins: [colors, mixinApiErrors, momentMixin],
  name: 'Skills',

  data () {
    return {
      createStub: {
        title: '',
        color: '#bbbbbb'
      },
      newSkill: {
        title: '',
        color: '#bbbbbb'
      },
      editStub: {
        id: null
      },
      inEdit: {
        id: null
      },
      from: moment().startOf('day').format(this.backendDateTimeFormat)
    }
  },

  created () {
    this.$store.dispatch('getSkills', this.companyId).then(response => {
      //
    }).catch(() => {
      // TODO: missing catch
    })
  },

  computed: {
    companyId () {
      return this.$store.getters.companyId
    },
    skills () {
      return this.$store.getters.skills
    },
    salarySettings () {
      return this.$store.getters.company.salary
    }
  },

  methods: {
    save () {
      this.$refs.observe.validate().then(isValid => {
        if (isValid.valid) {
          this.$store.dispatch('createSkill', [this.companyId, this.newSkill]).then((response) => {
            this.newSkill = Object.assign({}, this.createStub)
            this.$refs.observe.resetForm()
            this.toastSuccess(this.$t('Skill successfully created'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },
    saveEdited (skill) {
      this.$refs.edit.validate().then(isValid => {
        if (isValid) {
          this.$store.dispatch('editSkill', [this.companyId, skill.id, skill]).then((response) => {
            this.inEdit = Object.assign({}, this.editStub)
            this.toastSuccess(this.$t('Skill successfully edited'))
          }).catch((error) => {
            this.toastError(this.$t(error.response.data.message))
          })
        }
      })
    },
    editSkill (index) {
      this.inEdit = Object.assign({}, this.skills[index])
    },
    deleteSkill (skillId) {
      const message = this.$t('SKILL_DELETE_WARNING')
      this.$confirm(message, this.$t('Warning'), {
        confirmButtonText: this.$t('Ok'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('deleteSkill', [this.companyId, skillId]).then(() => {
          this.toastSuccess(this.$t('Delete completed'))
          this.$store.dispatch('getEmployeesByCompanyNoCached', this.companyId)
        }).catch((error) => {
          this.toastError(this.$t(error.response.data.message))
        })
      }).catch(() => {
        //
      })
    }
  }

}
</script>

<style scoped>
  .project-color {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }
</style>
