<template>
  <div id="dashboard-field-service" class="container-fluid p-0">
    <dummy-payment-required
      v-if="dummy"
      @activate="getTableDataActivated()"
      :dummyInfo="dummyInfo">
    </dummy-payment-required>
    <template v-if="!dummy">
      <tasks-statistic
        v-if="showTaskStatistic"
        :new-tasks="newTasks"
        :active-tasks="activeTasks"
        :completed-tasks="completedTasks"
        :problematic-tasks="problematicTasks"
        @init="init"
      />
      <template v-if="!showTaskStatistic">
        <div class="card m-1" style="height: 300px!important;">
          <div class="card-body p-2 h-100 align-content-center">
            <div class="text-center my-auto">{{$t('NO_ENOUGH_RIGHTS_FOR_TASKS')}}</div>
          </div>
        </div>
      </template>
      <to-do-statistic />
    </template>
  </div>
</template>
<script>
import TasksAccessMixin from '@/mixins/TasksAccessMixin'
import mixinDummy from '@/mixins/mixinDummy'
import momentMixin from '@/mixins/mixinMoment'
import { mapGetters } from 'vuex'
import moment from 'moment/moment'
import DummyPaymentRequired from '@/components/CommonComponents/DummyPaymentRequired'
import TasksStatistic from '@/components/Dashboard/DashboardComponents/DashboardFieldService/TasksStatistic'
import ToDoStatistic from '@/components/Dashboard/DashboardComponents/DashboardFieldService/ToDoStatistic'
export default {
  name: 'DashboardFieldService',
  components: { ToDoStatistic, TasksStatistic, DummyPaymentRequired },
  mixins: [TasksAccessMixin, momentMixin, mixinDummy],
  data () {
    return {
      dummy: false,
      dummyInfo: '',
      newTasks: [],
      activeTasks: [],
      completedTasks: [],
      problematicTasks: [],
      showTaskStatistic: true
    }
  },
  created () {
    if (this.companyId) {
      this.init()
    }
  },
  computed: {
    ...mapGetters({
      companyId: 'companyId',
      locale: 'locale',
      company: 'company'
    }),
    actualTZ () {
      return this.$store.getters.actualTZ
    }
  },
  watch: {
    companyId () {
      if (this.companyId) {
        this.init()
      }
    }
  },
  methods: {
    init () {
      this.newTasks = []
      this.activeTasks = []
      this.completedTasks = []
      this.problematicTasks = []
      const data = {
        status: 'all',
        hide_subtasks: true,
        date_from: this.toUTC(moment().add(-1, 'days').startOf('day')).format(this.backendDateTimeFormat),
        date_to: this.toUTC(moment().add(1, 'days').endOf('day')).format(this.backendDateTimeFormat)
      }
      const payload = '?' + this.stringifyObject(data)
      this.$store.dispatch('getTasks', [this.companyId, payload]).then((response) => {
        this.showTaskStatistic = true
        response.forEach(task => {
          if (task.logs.length === 0 || task.logs[task.logs.length - 1].status.is_first) {
            this.newTasks.push(task)
          } else if (
            task.scheduled_time &&
            task.logs &&
            moment(task.logs[0].created_at).isAfter(moment(task.scheduled_time))
          ) {
            this.problematicTasks.push(task)
          } else if (task.logs[task.logs.length - 1].status.is_final) {
            this.completedTasks.push(task)
          } else {
            this.activeTasks.push(task)
          }
        })
        this.loaded = true
        this.dummy = false
        this.$eventBus.emit('dummy', false)
      }).catch((error) => {
        if (error.response.data.module) {
          this.dummy = true
          this.$eventBus.emit('dummy', true)
          this.dummyInfo = this.setupDummyData(error.response.data)
        } else {
          this.showTaskStatistic = false
        }
      })
    },
    stringifyObject (objData, joinSymbol = '&') {
      const options = []
      for (const optionKey in objData) {
        if (!this.isUndefined(objData[optionKey])) {
          options.push((optionKey + '=' + (objData[optionKey])))
        }
      }
      return options.join(joinSymbol)
    },
    isUndefined (value) {
      return typeof value === 'undefined'
    },
    getTableDataActivated () {
      this.dummy = false
      this.init()
    }
  }
}
</script>
<style scoped>

</style>
