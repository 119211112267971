import { HTTP } from '@/api/http_utils'
import {
  GET_REQUEST,
  GET_REQUESTS
} from '../mutation-types.js'
import moment from 'moment'

const UPDATE_FC_REQUESTS = 'UPDATE_FC_REQUESTS'

const state = {
  userRequest: {},
  requests: {
    last_page: 1,
    data: []
  },
  archive: [],
  fcRequests: []
}

const promises = {}

const getters = {
  userRequest: state => state.userRequest,
  requests: state => state.requests,
  fcRequests: state => state.fcRequests
}

const mutations = {
  [GET_REQUEST] (state, userRequest) {
    state.userRequest = userRequest
  },
  [GET_REQUESTS] (state, requests) {
    state.requests = requests
  },
  [UPDATE_FC_REQUESTS] (state, requests) {
    state.fcRequests = requests
  }
}

const actions = {
  updateFcRequests ({ commit }, payload) {
    commit(UPDATE_FC_REQUESTS, payload)
  },
  getRequest ({ commit }, [companyId, id]) {
    return new Promise((resolve, reject) => {
      HTTP.get('/companies/' + companyId + '/requests/' + id).then(userRequest => {
        commit(GET_REQUEST, userRequest)
        resolve(userRequest)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getRequests ({ commit }, payload) {
    const query = []
    Object.keys(payload).forEach(key => {
      query.push(`${key}=${payload[key]}`)
    })
    return new Promise(resolve => {
      HTTP.get('/companies/' + payload.companyId + `/requests?${query.join('&')}`).then(requests => {
        commit(GET_REQUESTS, requests)
        resolve(requests)
      })
    })
  },
  getVacationsEmployeesStatistic ({ commit }, [companyID, from, to, id, type, employeeId, positions, marks, skills]) {
    // type in project_id, schedule_id
    return new Promise((resolve, reject) => {
      let url = `/companies/${companyID}/vacations-employees-statistic?from=${from}&to=${to}`
      if (id && type) {
        url = url + `&${type}=${id}`
      }
      if (employeeId) {
        url = url + `&with_employee_requests=${employeeId}`
      }
      if (positions.length > 0) {
        url = url + `&positions=${positions}`
      }
      if (marks.length > 0) {
        url = url + `&marks=${marks}`
      }
      if (skills.length > 0) {
        url = url + `&skills=${skills}`
      }
      HTTP.get(url).then(requests => {
        resolve(requests)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getRequestsForCalendar ({ commit }, [companyID, queryRequests]) {
    const promiseKey = `/companies/${companyID}/calendar/requests${queryRequests}`

    if (promises[promiseKey]) {
      console.error('repeating requests calendar requests')
      return promises[promiseKey]
    }
    const promise = new Promise((resolve, reject) => {
      HTTP.get(`/companies/${companyID}/calendar/requests${queryRequests}`).then(requests => {
        commit(UPDATE_FC_REQUESTS, requests)
        resolve(requests)
      }).catch(err => {
        reject(err)
      }).finally(() => {
        delete promises[promiseKey]
      })
    })

    promises[promiseKey] = promise

    return promise
  },
  setRequest ({ commit, dispatch }, [companyId, data]) {
    return new Promise((resolve, reject) => {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } }
      HTTP.post('/companies/' + companyId + '/requests', data, config).then(userRequest => {
        if (userRequest) {
          if (data.subtype === 'create_shift' && userRequest.status === 'confirmed') {
            const shift = userRequest.shift
            if (shift.breaks.length > 0) {
              let duration = 0
              shift.breaks.forEach(breakItem => {
                duration += moment.duration(moment(breakItem.time_to).diff(moment(breakItem.time_from))).asMinutes()
                dispatch('updateBreak', breakItem)
              })
              shift.break_time = duration
            }
            dispatch('updateShiftInList', shift)
            dispatch('updateFilteredShift', shift)
          }
          commit(GET_REQUEST, userRequest)
        }
        resolve(userRequest)
      }).catch(err => {
        reject(err)
      })
    })
  },
  editStatus ({ commit }, [companyId, id, data]) {
    return new Promise((resolve, reject) => {
      HTTP.patch('/companies/' + companyId + '/requests/' + id, data).then(userRequest => {
        commit(GET_REQUEST, userRequest)
        resolve(userRequest)
      }).catch(err => {
        reject(err)
      })
    })
  },
  requestChat ({ commit }, [companyId, id, data]) {
    return new Promise(resolve => {
      HTTP.post('/companies/' + companyId + '/requests/' + id + '/messages', data).then(userRequest => {
        if (userRequest) {
          commit(GET_REQUEST, userRequest)
          resolve(userRequest)
        }
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
