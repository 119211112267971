import { HTTP } from '@/api/http_utils'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  setPayPalLink ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.post(`companies/${companyId}/payments/paypal/create`, data).then(link => {
        resolve(link)
      })
    })
  },
  setPayPalSuccess ({ commit }, [companyId, data]) {
    return new Promise(resolve => {
      HTTP.post(`companies/${companyId}/payments/paypal/return`, data).then(response => {
        resolve(response)
      })
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
