<template>
  <div class="mb-2" v-if="editor">
    <div class="p-1">
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">-->
<!--        {{ $t('paragraph') }}-->
<!--      </el-button>-->
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        {{ $t('Header') }} 1
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        {{ $t('Header') }} 2
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        {{ $t('Header') }} 3
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        {{ $t('Header') }} 4
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        {{ $t('Header') }} 5
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        {{ $t('Header') }} 6
      </el-button>
    </div>
    <div class="p-1">
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <i class="icon-list2" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <i class="icon-list-numbered" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        <i class="icon-code" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleBold().run()" :disabled="!editor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <i class="icon-bold" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleItalic().run()" :disabled="!editor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <i class="icon-italic" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
        <i class="icon-paragraph-left" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
        <i class="icon-paragraph-center" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
        <i class="icon-paragraph-right" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
        <i class="icon-paragraph-justify" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleStrike().run()" :disabled="!editor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <i class="icon-strikethrough" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
        <i class="icon-undo2" />
      </el-button>
      <el-button type="primary" plain size="small" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
        <i class="icon-redo2" />
      </el-button>
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">-->
<!--        {{ $t('blockquote') }}-->
<!--      </el-button>-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().setHorizontalRule().run()">-->
<!--        {{ $t('horizontal rule') }}-->
<!--      </el-button>-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().setHardBreak().run()">-->
<!--        {{ $t('hard break') }}-->
<!--      </el-button>-->
    </div>
<!--    <div class="p-1">-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().toggleCode().run()" :disabled="!editor.can().chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">-->
<!--        {{ $t('code') }}-->
<!--      </el-button>-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().unsetAllMarks().run()">-->
<!--        clear marks-->
<!--      </el-button>-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().clearNodes().run()">-->
<!--        clear nodes-->
<!--      </el-button>-->
<!--    </div>-->
<!--    <div class="p-1">-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">-->
<!--        {{ $t('undo') }}-->
<!--      </el-button>-->
<!--      <el-button type="primary" plain size="small" @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">-->
<!--        {{ $t('redo') }}-->
<!--      </el-button>-->
<!--    </div>-->
  </div>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { TextAlign } from '@tiptap/extension-text-align'

export default {
  components: {
    EditorContent
  },
  data () {
    return {
      editor: null
    }
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        })
      ],
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      }
    })
  },
  beforeUnmount () {
    this.editor.destroy()
  },
  watch: {
    modelValue (value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    }
  }
}
</script>
<style lang="scss">
/* Basic editor styles */
.tiptap:focus {
  border-color: #4d90fe;
}
.tiptap {
  border: solid;
  background-color: #e0f5ff;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  outline: none;
  padding: 0.5em;
  > * + * {
    margin-top: 0.5em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
//.ProseMirrorFocused {
//  border-color: #53a8dd!important;
//}
</style>
