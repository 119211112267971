<template>
  <div>
    <schedule-settings
      v-if="step === 2"
      :settings="settings"
      :project="project"
      :type="'simple'"
      :shiftsControl="shiftsControl"
      :schedule="schedule"
      @next="next"
      @prev="prev">
    </schedule-settings>

    <employees
      v-if="step === 3"
      :selectedUsers="employees"
      :type="'simple'"
      :project="project"
      @save="save"
      @prev="prev">
    </employees>
  </div>
</template>

<script>
import ScheduleSettings from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/ScheduleSettings'
import Employees from '@/components/Schedule/CreateScheduleComponents/CommonMasterComponents/Employees'

export default {
  name: 'ScheduleSimple',
  components: {
    ScheduleSettings: ScheduleSettings,
    Employees: Employees
  },
  props: {
    step: Number,
    settings: Object,
    employees: Object,
    workingTime: Object,
    project: Number,
    schedule: Object,
    shiftsControl: Object
  },
  methods: {
    next (value) {
      this.$emit('next', {
        step: value.step,
        data: value.data
      })
    },
    prev (value) {
      this.$emit('prev', {
        step: value.step,
        data: value.data
      })
    },
    save (value) {
      this.$emit('save', {
        step: value.step,
        data: value.data
      })
    }
  }
}
</script>

<style scoped>

</style>
